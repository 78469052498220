import React from 'react';

import {useFilters} from '../../hooks/filter';
import {FILTERS} from './filters';
import Form from '../Form/Form';
import {useDispatch, useSelector} from 'react-redux';
import {selectFilter} from '../../modules/filters/selectors';
import {filterActions} from '../../modules/filters/actions';

const FilterBase = ({elements, filterKey}: {elements: IFilterConfig; filterKey: FILTERS}) => {
  const dispatch = useDispatch();
  const [filterData, setFilterData, showFilter] = useFilters(filterKey);
  const filters = useSelector(selectFilter);
  return (
    <div className="filter-base full-width">
      <Form
        key={filterKey}
        isFilter={true}
        isFilterReset={true}
        onChange={(e) => {
          setFilterData({[e.target.name]: e.target.value});
        }}
        values={{...elements.initialValues, ...filterData}}
        initialValues={{...elements.initialValues, ...filterData}}
        fields={elements.fields}
        onSubmit={(data) => {
          setFilterData(data);
        }}
        resetFilters={() => {
          dispatch(filterActions.setFilterValue({key: filterKey, value: {}}));
          showFilter(true);
        }}
        onCancel={() => showFilter(false)}
        submitButtonText="Submit"
      />
    </div>
  );
};

export default FilterBase;
