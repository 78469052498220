import DataTable from '../Table/Table';
import React, {ReactElement, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useStorlessFetch} from '../../hooks/fetch';
import {useDispatch} from 'react-redux';
import {modalActions} from '../../modules/modal/actions';
import {MODALS} from '../Modal/ModalContents';
import {IErrorLog} from '../../types/ErrorLog/IErrorLog';
import {appActions} from '../../modules/app/actions';
import {DataTableItemType} from '../Table/interfaces/IHeaderAccessor';
import {Button} from '@material-ui/core';

interface IProps {
  entityId: string;
  onDeleteCallBack: (data?: any) => void;
}

export default function ErrorLogsTable({entityId, onDeleteCallBack}: IProps): ReactElement {
  const [deleteItemRes, deleteItem] = useStorlessFetch('error_logs_delete');
  const {t} = useTranslation();
  const dispatch = useDispatch();

  const onDelete = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, item: IErrorLog): void => {
    e.preventDefault();

    dispatch(
      modalActions.addChild(MODALS.CONFIRM_DIALOG, {
        title: t('general.warning'),
        content: t('general.areYouSure'),
        onYes: () => {
          deleteItem({id: item._id});
          dispatch(modalActions.closeModal());
        },
        onNo: () => dispatch(modalActions.closeModal()),
      }),
    );
  };

  const onDetailsClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, item: IErrorLog): void => {
    e.preventDefault();
    e.stopPropagation();

    dispatch(
      modalActions.addChild(MODALS.CONFIRM_DIALOG, {
        title: t('general.details'),
        content: <pre>{JSON.stringify(item.errorObject || {}, null, 2)}</pre>,
        onOk: () => dispatch(modalActions.closeModal()),
      }),
    );
  };

  useEffect(() => {
    if (deleteItemRes?.data) {
      dispatch(appActions.showSnackBar({text: t('general.submitFormText'), options: {severity: 'success'}}));
      onDeleteCallBack();
    } else if (deleteItemRes?.error) {
      dispatch(appActions.showSnackBar({text: deleteItemRes.error.name, options: {severity: 'error'}}));
    }

    return () => {
      deleteItemRes.data = null;
      deleteItemRes.error = null;
    };
  }, [deleteItemRes]);

  return (
    <DataTable
      className="orders__table"
      headers={[
        {
          kind: 'accessor',
          name: t('errorLogs.date'),
          accessor: 'createdAt',
          sortable: true,
          type: DataTableItemType.DATE,
        },
        {
          kind: 'accessor',
          name: t('errorLogs.description'),
          accessor: 'description',
          sortable: true,
        },
        {
          kind: 'accessor',
          name: '',
          accessor: '_id',
          type: DataTableItemType.CALLBACK,
          callback: (item: IErrorLog) => {
            return (
              <>
                <Button variant="contained" color="primary" className="blue-button" onClick={(e) => onDelete(e, item)}>
                  {t('general.delete')}
                </Button>

                <Button
                  variant="contained"
                  color="primary"
                  className="yellow-button"
                  onClick={(e) => onDetailsClick(e, item)}
                >
                  {t('general.details')}
                </Button>
              </>
            );
          },
        },
      ]}
      endpoint={'error_logs'}
      title={t('general.errorLogs')}
      defaultSort={{key: 'createdAt', value: -1}}
      options={{entityId}}
      refreshData={deleteItemRes}
    />
  );
}
