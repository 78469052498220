import {IconButton} from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import React, {useContext} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {push} from 'connected-react-router';
import {selectLeftDrawer} from '../../modules/modal/selectors';
import {MODALS} from '../Modal/ModalContents';
import {useTranslation} from 'react-i18next';
import {Brightness2, Brightness5, Menu} from '@material-ui/icons';
import * as showMenu from '../../actions/showMenuAction';
import {Link} from 'react-router-dom';
import {APP_ROUTES} from '../../routers/routes';
import ScreenRecorder from '../VideoRecorder/VideoRecorder';
import LanguageSelector from '../LanguageSelector';
import {ThemeContext} from '../..';
import {isDarkMode} from '../../lib/helpers';

const HeaderBar = () => {
  const {toggleTheme} = useContext(ThemeContext);
  const dispatch = useDispatch();
  const leftDrawerData = useSelector(selectLeftDrawer);
  const {t} = useTranslation('translation');
  const languages = [
    {name: 'English', code: 'en', imageSrc: '/images/united-kingdom.webp'},
    {name: 'Deutsch', code: 'de', imageSrc: '/images/germany.webp'},
  ];

  const handleToggleTheme = () => {
    if (toggleTheme) {
      toggleTheme();
    }
  };

  const dark = isDarkMode();

  return (
    <div className="header">
      <AppBar position="static">
        <Toolbar>
          <IconButton
            edge="start"
            className="header__menuButton"
            color="inherit"
            aria-label="menu"
            onClick={(e) => {
              e.stopPropagation();
              leftDrawerData.modalKey === MODALS.NONE ? '' : '';
              dispatch(showMenu.showMenuAction());
            }}
          >
            <Menu />
          </IconButton>
          <div style={{width: '230px', textAlign: 'center'}}>
            <img
              className="header__logo"
              src="/images/dda_logo.webp"
              alt="druckdichaus-logo"
              onClick={() => {
                dispatch(push('/'));
              }}
            />
          </div>

          <div className="header__title">
            <div className="header__links__wrapper">
              <Link to={APP_ROUTES.ORDERS} className="header__link">
                <img src="/icons/list.webp" alt={t('general.orders')} title={t('general.orders')} />
              </Link>
              <Link to={APP_ROUTES.ORDERS_READY_FOR_SHIPPING} className="header__link">
                <img
                  src="/icons/delivery-truck (2).webp"
                  alt={t('general.readyForShipping')}
                  title={t('general.readyForShipping')}
                />
              </Link>
              <Link to={APP_ROUTES.PRIME_ORDERS_READY_FOR_SHIPPING} className="header__link">
                <img
                  height={32}
                  src="/images/ready-for-shipping-prime.webp"
                  alt={t('general.primeReadyForShipping')}
                  title={t('general.primeReadyForShipping')}
                />
              </Link>
              <Link to={APP_ROUTES.PRODUCTS} className="header__link">
                <img src="/icons/shapes.webp" alt={t('general.products')} title={t('general.products')} />
              </Link>
              <Link to={APP_ROUTES.SUPPLIER_ORDERS} className="header__link">
                <img src="/icons/bulleted.webp" alt={t('general.supplierOrders')} title={t('general.supplierOrders')} />
              </Link>
              <Link to={APP_ROUTES.CUSTOMERS} className="header__link">
                <img src="/icons/customer.webp" alt={t('general.customers')} title={t('general.customers')} />
              </Link>
              <Link to={APP_ROUTES.SYNC_STATUSES} className="header__link">
                <img src="/icons/reload.webp" alt={t('general.syncStatuses')} title={t('general.syncStatuses')} />
              </Link>
            </div>
            <ScreenRecorder />
          </div>

          <LanguageSelector languages={languages} />
          <IconButton onClick={toggleTheme} color="inherit" style={{color: dark ? 'white' : 'white'}}>
            {dark ? <Brightness5 /> : <Brightness2 />}
          </IconButton>
        </Toolbar>
      </AppBar>
    </div>
  );
};
export default HeaderBar;
