import {
  Button,
  FormControl,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@material-ui/core';
import React, {useEffect, useState} from 'react';
import Form, {IField} from '../Form/Form';
import {useTranslation} from 'react-i18next';
import {IProduct} from '../../types/Product/IProduct';
import {useStorlessFetch} from '../../hooks/fetch';
import AutocompleteComponent from '../Autocomplete/Autocomplete';
import {DeleteForever} from '@material-ui/icons';

const AddMultiboxProductModal = (props: IAddMultiboxModalProps) => {
  const {title, isLoading, onCancel, product, passedMultiBoxProducts, setMultiBox} = props;
  const {t} = useTranslation();
  const [getProductResponse, getProduct] = useStorlessFetch('product_details');

  const [multiBoxProducts, setMultiBoxProducts] = useState<any[]>([]);
  const [addedProduct, setAddedProduct] = useState<any>();
  const [addedProductQuantity, setAddedProductQuantity] = useState<number>(1);

  const fields: IField[] = [];
  const initialValues: any[] = [];

  const onProductChange = (productId: any) => {
    setAddedProduct(productId);
  };

  const handleSubmit = (values: any) => {
    const multiBoxProductsData = multiBoxProducts.map((p: any) => {
      return {
        productId: p.productId,
        numberOfProducts: p.numberOfProducts,
      };
    });

    const data = {
      productId: product?._id,
      multiBoxProducts: multiBoxProductsData,
    };

    setMultiBox(data);
  };

  const onRemoveMultiBoxItem = (productId: string) => () => {
    const newMultiBoxProducts = multiBoxProducts.filter((p) => {
      return p.productId !== productId;
    });
    setMultiBoxProducts(newMultiBoxProducts);
  };

  const onAddProduct = () => {
    if (!addedProduct || !addedProductQuantity) {
      return;
    }
    getProduct({id: addedProduct});
    const newMultiBoxProducts = [
      ...multiBoxProducts,
      {_id: addedProduct, title: addedProduct.title, numberOfProducts: addedProductQuantity},
    ];
    setMultiBoxProducts(newMultiBoxProducts);
    setAddedProduct('');
    setAddedProductQuantity(1);
  };

  useEffect(() => {
    if (getProductResponse?.data) {
      const product = getProductResponse.data;

      const newMultiBoxProducts = multiBoxProducts.map((p: any) =>
        p._id === product._id
          ? {
              numberOfProducts: p.numberOfProducts,
              productId: product._id,
              averagePrice: product.averagePrice,
              title: product.title,
            }
          : p,
      );

      setMultiBoxProducts(newMultiBoxProducts);
    }
  }, [getProductResponse?.data]);

  useEffect(() => {
    if (passedMultiBoxProducts) {
      setMultiBoxProducts(passedMultiBoxProducts);
    }
  }, [passedMultiBoxProducts]);

  return (
    <>
      <Typography className="modal-title">{title}</Typography>
      <br />
      <TableContainer className="table-scroll" style={{width: '480px'}}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{t('productDetails.product')}</TableCell>
              <TableCell>{t('productDetails.quantity')}</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {multiBoxProducts &&
              multiBoxProducts.length > 0 &&
              multiBoxProducts.map((product: any) => (
                <TableRow key={product.productId}>
                  <TableCell>{product.title}</TableCell>
                  <TableCell>{product.numberOfProducts}</TableCell>
                  <TableCell>
                    <IconButton style={{padding: '5px'}} onClick={onRemoveMultiBoxItem(product.productId)}>
                      <DeleteForever className="red" />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <br />
      <br />
      <div style={{padding: '12px'}}>{t('productDetails.addProductToMultiBox')}:</div>
      <form style={{padding: '10px'}}>
        <FormControl variant="outlined" style={{marginBottom: '12px'}}>
          <AutocompleteComponent
            id="product"
            placeholder={t('productDetails.product')}
            label={t('productDetails.product')}
            defaultValue={addedProduct}
            onChange={onProductChange}
            endpoint="product_autocomplete_list"
            filters={{_id: {$nin: [product?._id]} as any}}
            required
          />
        </FormControl>
        <FormControl variant="outlined">
          <TextField
            id="quantity"
            label={t('supplierOrderDetails.quantity')}
            type="number"
            variant="outlined"
            required
            value={addedProductQuantity}
            onChange={(e: any) => setAddedProductQuantity(+e.target.value)}
          />
        </FormControl>

        <Button
          type="submit"
          onClick={(e: any) => {
            e.preventDefault();
            onAddProduct();
          }}
        >
          {t('productDetails.addProduct')}
        </Button>
      </form>
      <Form
        initialValues={initialValues}
        fields={fields}
        submitButtonText={t('general.submit')}
        cancelButtonText={t('general.cancel')}
        onSubmit={handleSubmit}
        onCancel={onCancel}
        isLoading={isLoading}
      ></Form>
    </>
  );
};

export interface IAddMultiboxModalProps {
  title: string;
  onSubmit: (values: any) => void;
  onCancel: () => void;
  isLoading?: boolean;
  product?: IProduct;
  passedMultiBoxProducts: any[];
  setMultiBox: any;
}

export default AddMultiboxProductModal;
