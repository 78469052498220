import {Button, IconButton} from '@material-ui/core';
import {Edit, Clear} from '@material-ui/icons';
import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {MODALS} from '../../components/Modal/ModalContents';
import DataTable from '../../components/Table/Table';
import {useFetch} from '../../hooks/fetch';
import {modalActions} from '../../modules/modal/actions';
import {DataTableItemType} from '../../components/Table/interfaces/IHeaderAccessor';

interface IActionsProps {
  doCountryPut: (param: any) => void;
  doCountryDelete: (param: any) => void;
  fetchData: () => void;
  id: string;
}

export const CountryActions: React.FC<IActionsProps> = (props: IActionsProps) => {
  const {doCountryPut, doCountryDelete, fetchData, id} = props;
  const dispatch = useDispatch();
  const {t} = useTranslation();

  const handleCancelModal = () => {
    dispatch(modalActions.closeModal());
  };

  const handleDeleteItem = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    event.preventDefault();

    dispatch(
      modalActions.addModal(MODALS.CONFIRM_DIALOG, {
        title: t('countries.modalTitle') || '',
        content: t('countries.deleteConfirmation'),
        onYes: deleteButtonHandler,
        onNo: handleCancelModal,
      }),
    );
  };

  const editButtonHandler = async (values: any) => {
    const data = {...values, id};

    await doCountryPut(data);
    handleCancelModal();
    await fetchData();
  };

  const deleteButtonHandler = async () => {
    await doCountryDelete({id});
    handleCancelModal();
    await fetchData();
  };

  const handleEditItem = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    event.preventDefault();

    dispatch(
      modalActions.addModal(MODALS.EDIT_COUNTRY_SHIPPING_PROVIDER_DETAILS, {
        title: t('countries.editTitle') || '',
        onSubmit: editButtonHandler,
        onCancel: handleCancelModal,
        id,
      }),
    );
  };

  return (
    <div>
      <IconButton onClick={handleEditItem}>
        <Edit fontSize="small" style={{fill: 'gray'}}></Edit>
      </IconButton>
      <IconButton onClick={handleDeleteItem}>
        <Clear fontSize="small" style={{fill: 'red'}}></Clear>
      </IconButton>
    </div>
  );
};

const CountryShippingProviders = () => {
  const dispatch = useDispatch();
  const [orderProblem, doOrderProblemPost] = useFetch('country_shipping_providers_post');
  const [countryPut, doCountryPut] = useFetch('country_shipping_providers_put');
  const [countryDelete, doCountryDelete] = useFetch('country_shipping_providers_delete');
  const [countryTable, refreshCountryTable] = useState(false);
  const {t} = useTranslation();

  const handleCancelModal = () => {
    dispatch(modalActions.closeModal());
  };

  const countrySubmitHandler = async (values: any) => {
    const data = {...values};
    if (!data.isInEurope) data.isInEurope = false;
    await doOrderProblemPost(data);
    handleCancelModal();
    refreshCountryTable(true);
  };

  const handleAddCountryBtnClick = async () => {
    refreshCountryTable(false);
    dispatch(
      modalActions.addModal(MODALS.EDIT_COUNTRY_SHIPPING_PROVIDER_DETAILS, {
        title: t('countries.btnAddCountry') || '',
        onCancel: handleCancelModal,
        onSubmit: countrySubmitHandler,
      }),
    );
  };

  return (
    <div className="country-shipping-providers">
      <DataTable
        className="country-shipping-providers__table"
        headers={[
          {
            kind: 'accessor',
            name: t('countries.country'),
            accessor: 'country',
            sortable: true,
          },
          {
            kind: 'accessor',
            name: t('countries.countryCode'),
            accessor: 'countryCode',
            sortable: true,
          },
          {
            kind: 'accessor',
            name: t('countries.countryCode3'),
            accessor: 'countryCode3',
            sortable: true,
          },
          {
            kind: 'accessor',
            name: t('countries.shippingProvider'),
            accessor: 'shippingProvider',
            sortable: true,
          },
          {
            kind: 'accessor',
            name: t('countries.isInEurope'),
            accessor: 'isInEurope',
            sortable: true,
            type: DataTableItemType.BOOLEAN,
          },
          {
            kind: 'accessor',
            name: t('countries.hasNonEuropeanRegions'),
            accessor: 'hasNonEuropeanRegions',
            sortable: true,
            type: DataTableItemType.BOOLEAN,
          },
          {
            kind: 'accessor',
            name: t('countries.tax'),
            accessor: 'tax',
            sortable: true,
          },
          {
            kind: 'accessor',
            name: t('countries.shippingFee'),
            accessor: 'shippingFee',
            sortable: true,
            type: 'number',
          },
          {
            kind: 'accessor',
            name: t('countries.amazonShippingFee'),
            accessor: 'amazonShippingFee',
            sortable: true,
            type: 'number',
          },
          {
            kind: 'accessor',
            name: t('countries.bookingAccount'),
            accessor: 'bookingAccount',
            sortable: true,
          },
          {
            kind: 'accessor',
            name: t('countries.dhlShippingFee'),
            accessor: 'dhlCost',
            sortable: true,
            type: 'number',
          },
          {
            kind: 'accessor',
            name: t('countries.upsShippingFee'),
            accessor: 'upsCost',
            sortable: true,
            type: 'number',
          },
          {
            kind: 'accessor',
            name: t('countries.glsShippingFee'),
            accessor: 'glsCost',
            sortable: true,
            type: 'number',
          },
          {
            kind: 'component',
            name: t('general.actions'),
            component: CountryActions,
            props: {
              doCountryPut,
              doCountryDelete,
            },
            onFetchProps: {},
          },
        ]}
        endpoint={'country_shipping_providers'}
        refreshData={countryTable}
        title={t('countries.screenTitle')}
        actionButtons={
          <Button variant="contained" className="blue-button" onClick={handleAddCountryBtnClick}>
            {t('countries.btnAddCountry')}
          </Button>
        }
      />
    </div>
  );
};

export default CountryShippingProviders;
