import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import DataTable from '../../components/Table/Table';
import {ProductStatus} from '../../types/Product/ProductStatus.enum';
import {InventoryStockUpdate} from '../../components/Product/InventoryStockUpdate';
import PdfInventoryListButton from '../../components/Product/PdfInventoryListButton';

const InventoryProducts = () => {
  const {t} = useTranslation();
  const [triggerRefreshTable, setTriggerRefreshTable] = useState<any>(null);

  return (
    <div className="products">
      <DataTable
        className="products__table"
        headers={[
          {
            kind: 'accessor',
            name: t('products.productId'),
            accessor: 'productId',
            sortable: true,
            type: 'number',
          },
          {
            kind: 'accessor',
            name: t('products.title'),
            accessor: 'title',
            sortable: true,
          },
          {
            kind: 'accessor',
            name: t('newProductForm.stockDDA'),
            accessor: 'stockDDA',
            sortable: true,
          },
          {
            kind: 'component',
            name: t('inventoryProducts.countedStock'),
            component: InventoryStockUpdate,
            props: {setTriggerRefreshTable: (productId: string) => setTriggerRefreshTable(productId)},
            onFetchProps: {conditions: {deletedAt: {$eq: null}}},
          },
        ]}
        endpoint={'products_inventory_list'}
        target={'products'}
        options={{'shopProps.status': {$in: [ProductStatus.ACTIVE, ProductStatus.EXPIRED]}}}
        refreshData={triggerRefreshTable}
        defaultSort={{key: 'productId', value: 1}}
        title={t('inventoryProducts.screenTitle')}
        actionButtons={<PdfInventoryListButton />}
      />
    </div>
  );
};

export default InventoryProducts;
