import {Grid} from '@material-ui/core';
import React, {ReactElement} from 'react';
import {useTranslation} from 'react-i18next';
import {IProduct} from '../../types/Product/IProduct';

const ProductRequirementsInfo = (props: IProductRequirementsInfoProps): ReactElement => {
  const {t} = useTranslation();
  const {product} = props;

  return (
    <>
      <Grid container sm={12} xs={12} className="product-info" style={{border: 'none', boxShadow: 'none'}}>
        <Grid item sm={6} xs={12} className="product-info-item">
          <div className="product-info-label">{t('productDetails.manualRequirements')}</div>
          <div className="product-info-text">{product?.requirements?.manualRequirements}</div>
        </Grid>
        <Grid item sm={6} xs={12} className="product-info-item">
          <div className="product-info-label">{t('productDetails.minimumStockRequirements')}</div>
          <div className="product-info-text">{product?.requirements?.minimumStockRequirements}</div>
        </Grid>
        <Grid item sm={6} xs={12} className="product-info-item">
          <div className="product-info-label">{t('productDetails.multiBoxRequirements')}</div>
          <div className="product-info-text">{product?.requirements?.multiBoxRequirements}</div>
        </Grid>
        <Grid item sm={6} xs={12} className="product-info-item">
          <div className="product-info-label">{t('productDetails.orderRequirements')}</div>
          <div className="product-info-text">{product?.requirements?.orderRequirements}</div>
        </Grid>
        <Grid item sm={6} xs={12} className="product-info-item">
          <div className="product-info-label">{t('productDetails.pendingCustomerOrderQuantity')}</div>
          <div className="product-info-text">{product?.requirements?.pendingCustomerOrderQuantity}</div>
        </Grid>
        <Grid item sm={6} xs={12} className="product-info-item">
          <div className="product-info-label">{t('productDetails.pendingSupplierOrderQuantity')}</div>
          <div className="product-info-text">{product?.requirements?.pendingSupplierOrderQuantity}</div>
        </Grid>
        <Grid item sm={6} xs={12} className="product-info-item">
          <div className="product-info-label">{t('productDetails.stockDDA')}</div>
          <div className="product-info-text">{product?.stockDDA}</div>
        </Grid>
        <Grid item sm={6} xs={12} className="product-info-item">
          <div className="product-info-label">{t('productDetails.minimumStock')}</div>
          <div className="product-info-text">{product?.productInformation.minimumStock}</div>
        </Grid>
        <Grid item sm={6} xs={12} className="product-info-item">
          <div className="product-info-label">{t('productDetails.minimumStockReach')}</div>
          <div className="product-info-text">{product?.productInformation.minimumStockReach}</div>
        </Grid>
        <Grid item sm={6} xs={12} className="product-info-item">
          <div className="product-info-label">{t('productDetails.minimumStockOrder')}</div>
          <div className="product-info-text">{product?.productInformation.minimumStockOrder}</div>
        </Grid>
      </Grid>
    </>
  );
};

interface IProductRequirementsInfoProps {
  product: IProduct;
}

export default ProductRequirementsInfo;
