import {Typography} from '@material-ui/core';
import React, {ReactElement} from 'react';
import Form from '../Form/Form';
import {useTranslation} from 'react-i18next';
import * as Yup from 'yup';

const AddComentToProductModal = (props: IAddCommentToProductModalProps): ReactElement => {
  const {onSubmit, onCancel} = props;
  const {t} = useTranslation();

  const generateInitialValues = (fields: any[]) => {
    let defaults = {};
    fields.forEach((field) => {
      defaults = {...defaults, [field.fieldKey]: ''};
    });
    return defaults;
  };

  const fields: any = [
    {
      label: t('productDetails.comment'),
      placeholder: t('productDetails.comment'),
      fieldKey: 'comment',
      type: 'input',
      fieldKeyType: 'text',
      required: true,
    },
  ];

  const initialValues = generateInitialValues(fields);

  const validationSchema = Yup.object().shape({
    comment: Yup.string().required(t('validation.required')),
  });

  return (
    <>
      <Typography className="modal-title">{t('supplierOrderItems.addButton')}</Typography>
      <Form
        initialValues={initialValues}
        fields={fields}
        submitButtonText={t('general.submit')}
        cancelButtonText={t('general.cancel')}
        onSubmit={onSubmit}
        onCancel={onCancel}
        validationSchema={validationSchema}
      ></Form>
    </>
  );
};

export interface IAddCommentToProductModalProps {
  onSubmit: (values: any) => void;
  onCancel: () => void;
}

export default AddComentToProductModal;
