import React from 'react';
import {useTranslation} from 'react-i18next';
import DataTable from '../../components/Table/Table';
import {DataTableItemType} from '../../components/Table/interfaces/IHeaderAccessor';

export default function PricingItems() {
  const {t} = useTranslation();

  return (
    <div className="expired-products">
      <DataTable
        className="expired-products__table"
        headers={[
          {
            kind: 'accessor',
            name: t('pricingItems.sku'),
            accessor: 'sku',
            sortable: true,
          },
          {
            kind: 'accessor',
            name: t('pricingItems.market'),
            accessor: 'market',
            sortable: true,
          },
          {
            kind: 'accessor',
            name: t('pricingItems.amazonFeePercentage'),
            accessor: 'amazonFeePercentage',
            sortable: false,
            type: 'number',
          },
          {
            kind: 'accessor',
            name: t('pricingItems.amazonEstimatedFeeStatus'),
            accessor: 'amazonEstimatedFeeStatus',
            sortable: false,
          },
          {
            kind: 'accessor',
            name: t('pricingItems.amazonShippingFee'),
            accessor: 'amazonShippingFee',
            sortable: false,
            type: 'number',
          },
          {
            kind: 'accessor',
            name: t('pricingItems.amazonShippingSkuStatus'),
            accessor: 'amazonShippingSkuStatus',
            sortable: false,
          },
          {
            kind: 'accessor',
            name: t('pricingItems.amazonUpdated'),
            accessor: 'amazonUpdated',
            sortable: true,
            type: DataTableItemType.DATE,
          },
        ]}
        endpoint={'pricing_items_list'}
        title={t('pricingItems.screenTitle')}
      />
    </div>
  );
}
