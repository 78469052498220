import {Button} from '@material-ui/core';
import React, {ReactElement, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {useFetch} from '../../hooks/fetch';
import {modalActions} from '../../modules/modal/actions';
import {MODALS} from '../Modal/ModalContents';
import {appActions} from '../../modules/app/actions';

const AddCommentToProduct = (props: IAddSupplierOrderButtonProps): ReactElement => {
  const {productId, refresh} = props;
  const [addCommentResponse, addComment] = useFetch<any>('add_comment_to_product');
  const dispatch = useDispatch();
  const {t} = useTranslation();

  const handleCancelModal = () => {
    dispatch(modalActions.closeModal());
  };

  const handleSubmitModal = async (values: any) => {
    const data = {...values, id: productId};

    handleCancelModal();
    addComment(data);
  };

  const handleClick = async () => {
    dispatch(
      modalActions.addModal(MODALS.ADD_COMMENT_TO_PRODUCT, {
        onSubmit: handleSubmitModal,
        onCancel: handleCancelModal,
      }),
    );
  };

  useEffect(() => {
    if (addCommentResponse?.error) {
      dispatch(appActions.showSnackBar({text: addCommentResponse.error.name, options: {severity: 'error'}}));
    } else if (addCommentResponse?.data) {
      dispatch(appActions.showSnackBar({text: t('general.success'), options: {severity: 'success'}}));
      refresh(Math.random());
    }

    return () => {
      addCommentResponse.data = null;
      addCommentResponse.error = null;
      addCommentResponse.loaded = null;
    };
  }, [addCommentResponse]);

  return (
    <Button variant="contained" className="blue-button" onClick={handleClick}>
      {t('productDetails.addCommentButton')}
    </Button>
  );
};

interface IAddSupplierOrderButtonProps {
  productId?: string;
  refresh?: any;
}

export default AddCommentToProduct;
