export enum ScreenType {
  LIST = 'LIST',
  UPCOMING = 'UPCOMING',
  SERIAL = 'SERIAL',
  COMPLETED = 'COMPLETED',
  SEARCH = 'SEARCH',
}

export interface ITodoScreen {
  id: number;
  name: string;
  title: string;
  showInHeader?: boolean;
  type: ScreenType;
}
