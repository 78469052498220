import {List, ListItem, ListItemIcon, ListItemText} from '@material-ui/core';
import {Done, Error} from '@material-ui/icons';
import React, {useEffect, useState} from 'react';
import {useFetch} from '../../hooks/fetch';

const ServiceStatus = () => {
  const [syncConnectionStatus, getSyncConnectionStatus] = useFetch<any>('sync_connection_status');
  const [amazonConnectionStatus, getAmazonConnectionStatus] = useFetch<any>('orders_amazon_connection_status');
  const [shopwareConnectionStatus, getShopwareConnectionStatus] = useFetch<any>('shopware_connection_status');
  const [bufferQueueInfo, getBufferQueueInfo] = useFetch<any>('buffer_queue_info');

  const [connectionStatuses, setConnectionStatuses] = useState({
    amazonConnectionStatus: false,
    syncConnectionStatus: false,
    shopwareConnectionStatus: false,
    dhlConnectionStatus: false,
  });

  useEffect(() => {
    getAmazonConnectionStatus();
    getSyncConnectionStatus();
    getShopwareConnectionStatus();
    getBufferQueueInfo();
  }, []);

  useEffect(() => {
    if (amazonConnectionStatus.data && amazonConnectionStatus.data.StatusCode === 200) {
      setConnectionStatuses({...connectionStatuses, amazonConnectionStatus: true});
    } else {
      setConnectionStatuses({...connectionStatuses, amazonConnectionStatus: false});
    }
  }, [amazonConnectionStatus]);

  useEffect(() => {
    if (syncConnectionStatus.data && syncConnectionStatus.data === 'ok') {
      setConnectionStatuses({...connectionStatuses, syncConnectionStatus: true});
    } else {
      setConnectionStatuses({...connectionStatuses, syncConnectionStatus: false});
    }
  }, [syncConnectionStatus]);

  useEffect(() => {
    if (shopwareConnectionStatus.data && shopwareConnectionStatus.data?.success) {
      setConnectionStatuses({...connectionStatuses, shopwareConnectionStatus: true});
    } else {
      setConnectionStatuses({...connectionStatuses, shopwareConnectionStatus: false});
    }
  }, [shopwareConnectionStatus]);

  return (
    <div style={{margin: 20}} className="sync-statuses">
      <h3>
        <b>Service Status</b>
      </h3>

      <List>
        <ListItem
          style={{
            backgroundColor: connectionStatuses.syncConnectionStatus ? 'green' : 'red',
            color: 'white',
            borderBottom: '1px solid white',
          }}
        >
          <ListItemText primary="DDA Sync Connection" />
          <ListItemIcon style={{color: 'white'}}>
            {connectionStatuses.syncConnectionStatus ? <Done /> : <Error />}
          </ListItemIcon>
        </ListItem>
        <ListItem
          style={{
            backgroundColor: connectionStatuses.amazonConnectionStatus ? 'green' : 'red',
            color: 'white',
            borderBottom: '1px solid white',
          }}
        >
          <ListItemText primary="Amazon Connection" />
          <ListItemIcon style={{color: 'white'}}>
            {connectionStatuses.amazonConnectionStatus ? <Done /> : <Error />}
          </ListItemIcon>
        </ListItem>
        <ListItem
          style={{
            backgroundColor: connectionStatuses.shopwareConnectionStatus ? 'green' : 'red',
            color: 'white',
            borderBottom: '1px solid white',
          }}
        >
          <ListItemText primary="Shopware Connection" />
          <ListItemIcon style={{color: 'white'}}>
            {connectionStatuses.shopwareConnectionStatus ? <Done /> : <Error />}
          </ListItemIcon>
        </ListItem>
      </List>

      {bufferQueueInfo?.data?.length ? (
        <>
          <h3>
            <b>Buffer Queue Info</b>
          </h3>
          <List>
            {bufferQueueInfo?.data
              ?.filter((x: any) => x !== null)
              .map((item: any) => (
                <ListItem key={item?.name}>
                  <b>{item?.name}: </b> {item?.status}
                </ListItem>
              ))}
          </List>
        </>
      ) : null}
    </div>
  );
};

export default ServiceStatus;
