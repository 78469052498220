import {Grid, Card, TextField, MenuItem, Checkbox} from '@material-ui/core';
import React, {useContext, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {EasyBillVatOption} from '../../../../types/Orders/EasyBillVatOption';
import {ShopType} from '../../../../types/Orders/ShopType';
import {SHOPWARE_PAYMENT_TYPE} from '../../../../types/Orders/ShopwarePaymentType';
import {ShippingProviderType} from '../../../../types/Shipment/ShippingProviderType';
import CreateOrderContext from '../../../../screens/Orders/Context/CreateOrderContext';
import {useFetch} from '../../../../hooks/fetch';

const OrderInfo = () => {
  const [countryTaxes, getCountryTaxes] = useFetch<any>('countries_taxes');
  const {order, updateOrderState} = useContext(CreateOrderContext);
  const {t} = useTranslation();

  useEffect(() => {
    getCountryTaxes();
  }, []);

  return (
    <Grid item xs={12} sm={6} md={4}>
      <Card className="custom-card">
        <div className="title">{t('orderDetails.screenTitle')}</div>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField
              variant="outlined"
              className="input"
              size="small"
              select
              value={order.paymentMethodName}
              label={t('orderDetails.paymentMethodName')}
              InputLabelProps={{shrink: true}}
              onChange={(event) => {
                updateOrderState(event.target.value, 'paymentMethodName');
              }}
              required
            >
              {Object.values(SHOPWARE_PAYMENT_TYPE)
                .filter((item) => {
                  return [
                    SHOPWARE_PAYMENT_TYPE.Rechnung,
                    SHOPWARE_PAYMENT_TYPE.PAYMENT_IN_ADVANCE,
                    SHOPWARE_PAYMENT_TYPE.SEPA,
                  ].includes(item);
                })
                .map((item) => {
                  return (
                    <MenuItem key={item} value={item}>
                      {item}
                    </MenuItem>
                  );
                })}
            </TextField>
          </Grid>
          {!order.isPickupOrder && (
            <Grid item xs={12} sm={6}>
              <TextField
                variant="outlined"
                className="input"
                size="small"
                select
                value={order.shippingType}
                label={t('orderDetails.shippingType')}
                InputLabelProps={{shrink: true}}
                onChange={(event) => {
                  updateOrderState(event.target.value, 'shippingType');
                }}
                required
              >
                {Object.values(ShippingProviderType).map((item) => {
                  return (
                    <MenuItem key={item} value={item}>
                      {item}
                    </MenuItem>
                  );
                })}
              </TextField>
            </Grid>
          )}
          <Grid item xs={12} sm={6}>
            {t('orderDetails.isPickupOrder')}
            <Checkbox
              color="primary"
              checked={order.isPickupOrder}
              onChange={(event) => {
                updateOrderState(event.target.checked, 'isPickupOrder');
              }}
            ></Checkbox>
          </Grid>
          <Grid item xs={6}>
            <TextField
              variant="outlined"
              className="input"
              size="small"
              value={order.vatPercentage}
              type={'number'}
              InputProps={{inputProps: {min: 0, step: 1}}}
              label={t('orderDetails.vatPercentage')}
              InputLabelProps={{shrink: true}}
              onChange={(event) => {
                updateOrderState(+event.target.value, 'vatPercentage');
              }}
              required
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              variant="outlined"
              className="input"
              size="small"
              select
              label={t('orderDetails.vatOption')}
              value={order.easyBillInfo?.vatOption}
              onChange={(event) => {
                const vatOption = event.target.value;
                updateOrderState(vatOption, 'vatOption', 'easyBillInfo');

                if (vatOption === EasyBillVatOption.IG || vatOption === EasyBillVatOption.AL) {
                  updateOrderState(0, 'vatPercentage');
                } else if (vatOption === EasyBillVatOption.NULL) {
                  const countryCode = order.shippingAddress.countryCode || '';
                  const countryTax = countryTaxes?.data?.[countryCode];
                  updateOrderState(countryTax || 0, 'vatPercentage');
                }
              }}
              required
            >
              {Object.values(EasyBillVatOption).map((item) => {
                return (
                  <MenuItem key={item} value={item}>
                    {item}
                  </MenuItem>
                );
              })}
            </TextField>
          </Grid>
          <Grid item xs={6}>
            <TextField
              variant="outlined"
              className="input"
              size="small"
              value={order.shippingCost}
              type={'number'}
              defaultValue={0}
              InputProps={{inputProps: {min: 0, step: 0.01}}}
              label={t('orderDetails.shippingCost')}
              InputLabelProps={{shrink: true}}
              onChange={(event) => {
                updateOrderState(+event.target.value, 'shippingCost');
              }}
              required
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              variant="outlined"
              className="input"
              size="small"
              select
              label={t('orderDetails.pricingShop')}
              value={order.pricingShop}
              onChange={(event) => {
                updateOrderState(event.target.value, 'pricingShop');
                if (
                  [ShopType.DRUCKDICHAUS, ShopType.SUPPLIES_PARTNER, ShopType.DDA_PORTAL].includes(
                    event.target.value as ShopType,
                  )
                ) {
                  updateOrderState(ShopType.DDA_PORTAL, 'shopName');
                } else {
                  updateOrderState(event.target.value, 'shopName');
                }
              }}
              required
            >
              {Object.entries(ShopType)
                .filter((x) =>
                  [
                    ShopType.DRUCKDICHAUS,
                    ShopType.SUPPLIES_PARTNER,
                    ShopType.OTTO,
                    ShopType.CHECK24,
                    ShopType.METRO,
                    ShopType.EBAY,
                    ShopType.GALAXUS,
                    ShopType.KAUFLAND,
                    ShopType.CONRAD,
                    ShopType.FNAC,
                  ].includes(x[1]),
                )
                .map((item) => (
                  <MenuItem key={item[0]} value={item[1]}>
                    {item[1]}
                  </MenuItem>
                ))}
            </TextField>
          </Grid>
          <Grid item xs={6}>
            <TextField
              variant="outlined"
              className="input"
              size="small"
              value={order.shopwareCustomFields?.internalOrderNumber}
              label={t('orderDetails.internalOrderNumber')}
              InputLabelProps={{shrink: true}}
              onChange={(event) => {
                updateOrderState(event.target.value, 'internalOrderNumber', 'shopwareCustomFields');
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              variant="outlined"
              className="input"
              size="small"
              value={order.taxId}
              label={t('customerDetails.salesTaxId')}
              InputLabelProps={{shrink: true}}
              onChange={(event) => {
                updateOrderState(event.target.value, 'taxId');
              }}
            />
          </Grid>
        </Grid>
      </Card>
    </Grid>
  );
};

export default OrderInfo;
