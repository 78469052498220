import {Button} from '@material-ui/core';
import React, {ReactElement, useContext} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {modalActions} from '../../../modules/modal/actions';
import OrderScanningContext from '../../../screens/Orders/Context/OrderScanningContext';
import {MODALS} from '../../Modal/ModalContents';

const PickupOrderButton = (): ReactElement => {
  const {order, setOrderMarkedAsPickup, pushTrackingNumber} = useContext(OrderScanningContext);
  const dispatch = useDispatch();
  const {t} = useTranslation();

  const handleOrderAsPickup = () => {
    setOrderMarkedAsPickup(true);
    pushTrackingNumber({id: order._id, isPickupOrder: true});
    dispatch(modalActions.closeModal(MODALS.CONFIRM_DIALOG));
  };

  const onButtonClick = () => {
    dispatch(
      modalActions.addChild(MODALS.CONFIRM_DIALOG, {
        title: t('general.areYouSure'),
        content: t('scanner.pickupOrderAlertMessage'),
        onYes: handleOrderAsPickup,
        onNo: () => dispatch(modalActions.closeModal(MODALS.CONFIRM_DIALOG)),
      }),
    );
  };

  return (
    <Button variant="contained" className="blue-button" onClick={onButtonClick}>
      {t('scanner.pickupOrderAlertMessage')}
    </Button>
  );
};

export default PickupOrderButton;
