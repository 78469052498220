import React, {useEffect, useState} from 'react';
import useDataFetcher from '../../hooks/dataFetcher';
import {useTranslation} from 'react-i18next';
import {Button, Grid, Select} from '@material-ui/core';
import {useFetch} from '../../hooks/fetch';
import {appActions} from '../../modules/app/actions';
import {useDispatch} from 'react-redux';

interface ITodoReceiverProps {
  todoReceivers: any[];
}

const TodoSelect = (props: any) => {
  const {todoReceiver, users} = props;
  const {t} = useTranslation();
  const [selectedUser, setSelectedUser] = useState(todoReceiver.userId);
  const [saveTodoReceiverResponse, saveTodoReceiver] = useFetch<any>('save_todo_receiver');
  const onChange = (value: string) => {
    setSelectedUser(value);
  };
  const dispatch = useDispatch();

  const save = () => {
    saveTodoReceiver({name: todoReceiver.name, userId: selectedUser});
  };

  useEffect(() => {
    if (saveTodoReceiverResponse.data) {
      dispatch(appActions.showSnackBar({text: t('general.success'), options: {severity: 'success'}}));
    }
    return () => {
      saveTodoReceiverResponse.data = null;
      saveTodoReceiverResponse.error = null;
      saveTodoReceiverResponse.loaded = null;
    };
  }, [saveTodoReceiverResponse]);

  return (
    <div style={{marginTop: '10px', display: 'flex'}}>
      <Select value={selectedUser} onChange={(e: any) => onChange(e.target.value)}>
        {users.map((user: any) => (
          <option key={user.id} value={user.id}>
            {user.username}
          </option>
        ))}
      </Select>
      <Button variant="contained" color="primary" style={{marginLeft: '15px'}} onClick={() => save()}>
        {t('general.save')}
      </Button>
    </div>
  );
};

const TodoReceivers = (props: ITodoReceiverProps) => {
  const {todoReceivers} = props;
  const {t} = useTranslation();
  const {data: users} = useDataFetcher('get_users') as {data: any[]};

  return (
    <>
      {todoReceivers.map((todoReceiver) => (
        <Grid item xs={6} key={todoReceiver.name} style={{padding: '10px'}}>
          <div>
            <div>{t(`customFields.todoReceivers.${todoReceiver.name}`)}</div>
            <div>
              <TodoSelect todoReceiver={todoReceiver} users={users} />
            </div>
          </div>
        </Grid>
      ))}
    </>
  );
};

export default TodoReceivers;
