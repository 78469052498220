import {USER_ROLE} from '../types/User/UserRole';
import useUser from './user';

const useIsAdmin = () => {
  const user = useUser();

  const userRoles = user?.roles || [];
  return userRoles.includes(USER_ROLE.ADMIN);
};

export default useIsAdmin;
