import Button from '@material-ui/core/Button/Button';
import React, {useEffect, useState} from 'react';
import {useFetch} from '../../../hooks/fetch';
import {useDispatch} from 'react-redux';
import {modalActions} from '../../../modules/modal/actions';
import {MODALS} from '../../../components/Modal/ModalContents';
import _ from 'lodash';
import {appActions} from '../../../modules/app/actions';
import {useTranslation} from 'react-i18next';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import WorkIcon from '@material-ui/icons/Work';
import ListIcon from '@material-ui/icons/List';
import {IDhlConfig} from '../../../types/Shipment/interfaces/IDhlConfig';
import {Settings} from '@material-ui/icons';

const DhlConfigDetails = () => {
  const [dhlConfigDetailsResponse, getDhlConfigDetails] = useFetch<any>('feature_configuration');
  const [dhlConfig, setDhlConfig] = useState<IDhlConfig>(null as any);
  const [dhlConfigPut, doDhlConfigPut] = useFetch<any>('feature_configuration_put');
  const dispatch = useDispatch();
  const {t} = useTranslation();

  useEffect(() => {
    getDhlConfigDetails({featureName: 'dhlIntegrationConfig'});
  }, []);

  useEffect(() => {
    if (dhlConfigDetailsResponse?.data && !dhlConfigDetailsResponse.error) {
      setDhlConfig(dhlConfigDetailsResponse?.data);
    } else if (dhlConfigDetailsResponse?.error) {
      dispatch(appActions.showSnackBar({text: dhlConfigDetailsResponse.error.name, options: {severity: 'error'}}));
    }

    return () => {
      dhlConfigDetailsResponse.data = null;
      dhlConfigDetailsResponse.error = null;
      dhlConfigDetailsResponse.loaded = null;
    };
  }, [dhlConfigDetailsResponse]);

  useEffect(() => {
    if (dhlConfigPut?.data && !dhlConfigPut.error) {
      dispatch(appActions.showSnackBar({text: t('dhlConfiguration.configUpdated'), options: {severity: 'success'}}));
      getDhlConfigDetails({featureName: 'dhlIntegrationConfig'});
    } else if (dhlConfigPut?.error) {
      dispatch(appActions.showSnackBar({text: dhlConfigPut.error.name, options: {severity: 'error'}}));
    }

    return () => {
      dhlConfigPut.data = null;
      dhlConfigPut.error = null;
      dhlConfigPut.loaded = null;
    };
  }, [dhlConfigPut]);

  const handleCancelModal = () => {
    dispatch(modalActions.closeModal());
  };

  const handleSubmitModal = async (values: any) => {
    const data = {id: dhlConfig._id, ...values};
    doDhlConfigPut(data);
    handleCancelModal();
  };

  const handleClick = async () => {
    dispatch(
      modalActions.addModal(MODALS.EDIT_DHL_CONFIG, {
        title: t('dhlConfiguration.screenTitle') || '',
        onSubmit: handleSubmitModal,
        onCancel: handleCancelModal,
        data: dhlConfig,
      }),
    );
  };

  return dhlConfig ? (
    <div className="customer-details">
      <div className="center-content">
        <div className="customer-details__header">
          <p>{t('dhlConfiguration.screenTitle')}</p>
          <Button variant="contained" className="blue-button" onClick={handleClick}>
            {t('general.edit')}
          </Button>
        </div>
        <div className="customer-details__data-container">
          <div className="cards-info-ups">
            <div className="customer-details__data-container__title">
              <LocalShippingIcon style={{fontSize: 25}} />
              <p>{t('dhlConfiguration.shipperInfo')}</p>
            </div>
            <div className="customer-details__data-container__body">
              {Object.keys(dhlConfig.data.shipper ?? {}).map((key) => {
                if (!_.isObject((dhlConfig.data.shipper as any)[key]) && (dhlConfig.data.shipper as any)[key]) {
                  return (
                    <div key={key} className="customer-details__data-container__field">
                      <p>
                        {t(`dhlConfiguration.${key}`)
                          .split(/(?=[A-Z])/)
                          .join(' ')
                          .toUpperCase()}
                      </p>
                      <p>{(dhlConfig.data.shipper as any)[key]}</p>
                    </div>
                  );
                }
              })}
            </div>
          </div>
          <div className="cards-info-ups">
            <div className="customer-details__data-container__title">
              <Settings style={{fontSize: 25}} />
              <p>{t('dhlConfiguration.connection')}</p>
            </div>

            <div className="customer-details__data-container__body">
              <>
                {Object.keys(_.omit(dhlConfig.data.connectionConfig, ['trackingUrl'] ?? {})).map((key) => {
                  if (
                    !_.isObject((dhlConfig.data.connectionConfig as any)[key]) &&
                    (dhlConfig.data.connectionConfig as any)[key]
                  ) {
                    return (
                      <div key={key} className="customer-details__data-container__field">
                        <p>
                          {key
                            .split(/(?=[A-Z])/)
                            .join(' ')
                            .toUpperCase()}
                        </p>
                        <p>{(dhlConfig.data.connectionConfig as any)[key]}</p>
                      </div>
                    );
                  }
                })}
              </>
            </div>
          </div>
          <div className="cards-info-ups">
            <div className="customer-details__data-container__title">
              <ListIcon style={{fontSize: 25}} />
              <p>{t('dhlConfiguration.dhlProduct')}</p>
            </div>
            <div className="customer-details__data-container__body">
              <div className="customer-details__data-container__field">
                <p>{t('dhlConfiguration.product')}</p>
                <p>{dhlConfig.data.product}</p>
              </div>

              {/* <div className="customer-details__data-container__field">
                <p>{t('dhlConfiguration.europeProduct')}</p>
                <p>{dhlConfig.data.europeProduct}</p>
              </div> */}

              <div className="customer-details__data-container__field">
                <p>{t('dhlConfiguration.internationalProduct')}</p>
                <p>{dhlConfig.data.internationalProduct}</p>
              </div>
            </div>
          </div>

          <div className="cards-info-ups">
            <div className="customer-details__data-container__title">
              <WorkIcon style={{fontSize: 25}} />
              <p>{t('dhlConfiguration.dhlUnitOfMeasurement')}</p>
            </div>
            <div className="customer-details__data-container__body">
              <div className="customer-details__data-container__field">
                <p>{t('dhlConfiguration.unit')}</p>
                <p>{dhlConfig.data.details.weight.uom}</p>
              </div>
              <div className="customer-details__data-container__field">
                <p>{t('dhlConfiguration.weight')}</p>
                <p>{dhlConfig.data.details.weight.value}</p>
              </div>
            </div>
          </div>

          <div className="cards-info-ups">
            <div className="customer-details__data-container__title">
              <LocalShippingIcon style={{fontSize: 25}} />
              <p>{t('dhlConfiguration.trackingDaysThreshold')}</p>
            </div>
            <div className="customer-details__data-container__body">
              <div className="customer-details__data-container__field">
                <p>{t('dhlConfiguration.trackingDaysThreshold')}</p>
                <p>{dhlConfig.data.trackingDaysThreshold}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : null;
};

export default DhlConfigDetails;
