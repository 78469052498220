import React, {useState} from 'react';
import {Accordion, AccordionDetails, AccordionSummary, Grid, IconButton, Typography} from '@material-ui/core';
import {ExpandMore, ExpandLess, Done} from '@material-ui/icons';
import {useTranslation} from 'react-i18next';
import {ICustomer} from '../../types/Customer/ICustomer';
import {ICustomField} from '../../types/CustomFields/CustomField';
import {CustomField} from '../Product/CustomField';

interface IProps {
  customer: ICustomer;
  customFields: ICustomField[];
}

const CustomerSideInfo = ({customer, customFields}: IProps) => {
  const [expanded, setExpanded] = useState([true, true, true, true]);
  const {t} = useTranslation();

  const handleChange = (index: number) => {
    setExpanded((prevExpanded) => {
      const newExpanded = [...prevExpanded];
      newExpanded[index] = !newExpanded[index];
      return newExpanded;
    });
  };

  const expandIcon = (shouldExpand: any) => {
    return shouldExpand ? (
      <IconButton className="accordion-icon">
        <ExpandLess />
      </IconButton>
    ) : (
      <IconButton className="accordion-icon">
        <ExpandMore />
      </IconButton>
    );
  };

  return customer ? (
    <div className="accordion-container" style={{marginTop: '15px'}}>
      <Accordion elevation={0} expanded={expanded[0]} onChange={() => handleChange(0)}>
        <AccordionSummary className="accordion-summary">
          <Typography className="accordion-title">{t('customerDetails.customerInfo')}</Typography>
          {expandIcon(expanded[0])}
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={0} alignItems="center">
            <Grid container>
              <Grid item xs={12} sm={6}>
                <div className="order-side-image">
                  {customer?.logo ? <img height={100} width={155} src={`${customer?.logo}`} /> : null}
                </div>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Grid container direction="column">
                  <Grid item>
                    <div className="order-side-label">{t('customerDetails.customerName')} </div>
                    <div className="order-side-customer-name">{customer?.customerName || '-'}</div>
                  </Grid>
                  <Grid item>
                    <div className="order-side-label">{t('customerDetails.customerNumber')} </div>
                    <div className="order-side-customer-name">{customer?.customerNumber || '-'}</div>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} sm={6} className="order-info-item">
              <div className="order-side-label">{t('customerDetails.salutation')} </div>
              <div className="order-side-text">{customer?.salutation || '-'}</div>
            </Grid>
            <Grid item xs={12} sm={6} className="order-info-item">
              <div className="order-side-label">{t('customerDetails.title')} </div>
              <div className="order-side-text">{customer?.title || '-'}</div>
            </Grid>
            <Grid item xs={12} sm={6} className="order-info-item">
              <div className="order-side-label">{t('customerDetails.firstName')} </div>
              <div className="order-side-text">{customer?.firstName || '-'}</div>
            </Grid>
            <Grid item xs={12} sm={6} className="order-info-item">
              <div className="order-side-label">{t('customerDetails.lastName')} </div>
              <div className="order-side-text">{customer?.lastName || '-'}</div>
            </Grid>
            <Grid item xs={12} sm={6} className="order-info-item">
              <div className="order-side-label">{t('customerDetails.companyName')} </div>
              <div className="order-side-text">{customer?.company || '-'}</div>
            </Grid>
            <Grid item xs={12} sm={6} className="order-info-item">
              <div className="order-side-label">{t('customerDetails.address')} </div>
              <div className="order-side-text">{customer?.address || '-'}</div>
            </Grid>
            <Grid item xs={12} sm={6} className="order-info-item">
              <div className="order-side-label">{t('customerDetails.addressSupplement')} </div>
              <div className="order-side-text">{customer?.addressSupplement || '-'}</div>
            </Grid>
            <Grid item xs={12} sm={6} className="order-info-item">
              <div className="order-side-label">{t('customerDetails.postalCode')} </div>
              <div className="order-side-text">{customer?.postalCode || '-'}</div>
            </Grid>
            <Grid item xs={12} sm={6} className="order-info-item">
              <div className="order-side-label">{t('customerDetails.city')} </div>
              <div className="order-side-text">{customer?.city || '-'}</div>
            </Grid>
            <Grid item xs={12} sm={6} className="order-info-item">
              <div className="order-side-label">{t('orderDetails.countryCode')} </div>
              <div className="order-side-text">{customer?.country || '-'}</div>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>

      <Accordion elevation={0} expanded={expanded[1]} onChange={() => handleChange(1)}>
        <AccordionSummary className="accordion-summary">
          <Typography className="accordion-title">{t('general.contactInfo')}</Typography>
          {expandIcon(expanded[1])}
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={0} alignItems="flex-start">
            <Grid item xs={12} sm={6} className="order-info-item">
              <div className="order-side-label">{t('customerDetails.email')} </div>
              <div className="order-side-text">{customer?.email || '-'}</div>
            </Grid>
            <Grid item xs={12} sm={6} className="order-info-item">
              <div className="order-side-label">{t('customerDetails.invoiceEmail')} </div>
              <div className="order-side-text">{customer?.invoiceEmail || '-'}</div>
            </Grid>
            <Grid item xs={12} sm={6} className="order-info-item">
              <div className="order-side-label">{t('customerDetails.sepaEmail')} </div>
              <div className="order-side-text">{customer?.sepaDetails?.email || '-'}</div>
            </Grid>
            <Grid item xs={12} sm={6} className="order-info-item">
              <div className="order-side-label">{t('customerDetails.otherEmails')} </div>
              {customer?.otherEmails?.map((email) => {
                return (
                  <div key={email} className="order-side-text">
                    {email || '-'}
                  </div>
                );
              })}
            </Grid>
            <Grid item xs={12} sm={6} className="order-info-item">
              <div className="order-side-label">{t('customerDetails.phone')} </div>
              <div className="order-side-text">{customer?.phone || '-'}</div>
            </Grid>
            <Grid item xs={12} sm={6} className="order-info-item">
              <div className="order-side-label">{t('customerDetails.fax')} </div>
              <div className="order-side-text">{customer?.dealerCompanyDetails?.contactDetails?.fax || '-'}</div>
            </Grid>
            <Grid item xs={12} sm={6} className="order-info-item">
              <div className="order-side-label">{t('customerDetails.web')} </div>
              <div className="order-side-text">{customer?.dealerCompanyDetails?.contactDetails?.web || '-'}</div>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>

      <Accordion elevation={0} expanded={expanded[2]} onChange={() => handleChange(2)}>
        <AccordionSummary className="accordion-summary">
          <Typography className="accordion-title">{t('general.paymentInfo')}</Typography>
          {expandIcon(expanded[2])}
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={0} alignItems="center">
            <Grid item xs={12} sm={6} className="order-info-item">
              <div className="order-side-label">{t('customerDetails.paymentMethodName')} </div>
              <div className="order-side-text">{customer?.paymentMethodName || '-'}</div>
            </Grid>
            <Grid item xs={12} sm={6} className="order-info-item">
              <div className="order-side-label">{t('customerDetails.bankDetailsLabel')} </div>
              <div className="order-side-text">{customer?.dealerCompanyDetails?.bankDetails?.label || '-'}</div>
            </Grid>
            <Grid item xs={12} sm={6} className="order-info-item">
              <div className="order-side-label">{t('customerDetails.iban')} </div>
              <div className="order-side-text">{customer?.dealerCompanyDetails?.bankDetails?.iban || '-'}</div>
            </Grid>
            <Grid item xs={12} sm={6} className="order-info-item">
              <div className="order-side-label">{t('customerDetails.swiftCode')} </div>
              <div className="order-side-text">{customer?.dealerCompanyDetails?.bankDetails?.swiftCode || '-'}</div>
            </Grid>
            <Grid item xs={12} sm={6} className="order-info-item">
              <div className="order-side-label">{t('customerDetails.sepaName')} </div>
              <div className="order-side-text">{customer?.sepaName || '-'}</div>
            </Grid>
            <Grid item xs={12} sm={6} className="order-info-item">
              <div className="order-side-label">{t('customerDetails.sepaIban')} </div>
              <div className="order-side-text">{customer?.sepaDetails?.iban || '-'}</div>
            </Grid>
            <Grid item xs={12} sm={6} className="order-info-item">
              <div className="order-side-label">{t('customerDetails.clientSignatureDate')} </div>
              <div className="order-side-text">{customer?.sepaDetails?.clientSignatureDate || '-'}</div>
            </Grid>
            <Grid item xs={12} sm={6} className="order-info-item">
              <div className="order-side-label">{t('customerDetails.clientNumber')} </div>
              <div className="order-side-text">{customer?.sepaDetails?.clientNumber || '-'}</div>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>

      <Accordion elevation={0} expanded={expanded[3]} onChange={() => handleChange(3)}>
        <AccordionSummary className="accordion-summary">
          <Typography className="accordion-title">{t('general.moreInfo')}</Typography>
          {expandIcon(expanded[3])}
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={0} alignItems="center">
            <Grid item xs={12} sm={6} className="order-info-item">
              <div className="order-side-label">{t('customerDetails.localCourt')} </div>
              <div className="order-side-text">{customer?.dealerCompanyDetails?.bankDetails?.localCourt || '-'}</div>
            </Grid>
            <Grid item xs={12} sm={6} className="order-info-item">
              <div className="order-side-label">{t('customerDetails.managingDirector')} </div>
              <div className="order-side-text">
                {customer?.dealerCompanyDetails?.additionalDetails?.managingDirector || '-'}
              </div>
            </Grid>
            <Grid item xs={12} sm={6} className="order-info-item">
              <div className="order-side-label">{t('customerDetails.salesTaxId')} </div>
              <div className="order-side-text">{customer?.federalTaxId || '-'}</div>
            </Grid>
            <Grid item xs={12} sm={6} className="order-info-item">
              <div className="order-side-label">{t('customerDetails.taxNumber')} </div>
              <div className="order-side-text">{customer?.commercialRegisterNumber || '-'}</div>
            </Grid>
            <Grid item xs={12} sm={6} className="order-info-item">
              <div className="order-side-label">{t('customerDetails.excludeForPaymentReminders')} </div>
              <div className="order-side-text">
                {customer?.excludeForPaymentReminders ? <Done className="green" /> : '-'}
              </div>
            </Grid>
            {customFields?.map((field: ICustomField) => {
              return (
                <Grid item xs={12} sm={6} className="order-info-item" key={field.fieldName}>
                  <div className="order-side-label">{field.fieldName} </div>
                  <div className="order-side-text">
                    <CustomField type={field.type} value={customer?.customFields?.[field.fieldName] ?? ''} />
                  </div>
                </Grid>
              );
            })}
          </Grid>
        </AccordionDetails>
      </Accordion>
    </div>
  ) : (
    <></>
  );
};

export default CustomerSideInfo;
