import React from 'react';
import {useTranslation} from 'react-i18next';
import DataTable from '../../components/Table/Table';
import {DataTableItemType} from '../../components/Table/interfaces/IHeaderAccessor';

const OpenSupplierOrders = () => {
  const {t} = useTranslation();

  return (
    <div className="orders">
      <DataTable
        className="orders__table"
        headers={[
          {
            kind: 'accessor',
            name: t('openSupplierOrders.dueDate'),
            accessor: 'dueDate',
            type: DataTableItemType.DATE,
          },
          {
            kind: 'accessor',
            name: t('openSupplierOrders.supplierOrderNumbers'),
            accessor: 'supplierOrderNumbers',
          },
          {
            kind: 'accessor',
            name: t('openSupplierOrders.totalNetSum'),
            accessor: 'totalNetSum',
            format: 'price',
          },
          {
            kind: 'accessor',
            name: t('openSupplierOrders.totalGrossSum'),
            accessor: 'totalGrossSum',
            format: 'price',
          },
        ]}
        endpoint={'open_supplier_orders'}
        defaultSort={{key: 'createdAt', value: -1}}
        title={t('openSupplierOrders.screenTitle')}
      />
    </div>
  );
};

export default OpenSupplierOrders;
