import React, {useState, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useStorlessFetch} from '../../../hooks/fetch';
import {Typography, CircularProgress, List, ListItem, ListItemText, Grid, TextField, Button} from '@material-ui/core';
import AutocompleteComponent from '../../../components/Autocomplete/Autocomplete';
import NotificationHelper from '../../../lib/notificationHelper';

export interface IEBayCategory {
  id: string;
  name: string;
}

export interface IEBayRequiredField {
  name: string;
  dataType: string;
  cardinality: string;
}

const EbayCategories = () => {
  const [pricingFactorsData, getPricingFactorsData] = useStorlessFetch('ebay_pricing_factors');
  const [categories, setCategories] = useState<IEBayCategory[]>([]);
  const [selectedCategoryId, setSelectedCategoryId] = useState<string | null>('');
  const [requiredFields, setRequiredFields] = useState<IEBayRequiredField[]>([]);
  const [fieldsData, getRequiredFields] = useStorlessFetch('ebay_required_fields');
  const {t} = useTranslation();

  useEffect(() => {
    getPricingFactorsData();
  }, []);

  useEffect(() => {
    if (pricingFactorsData?.data?.eBayCategories) {
      setCategories(pricingFactorsData.data.eBayCategories || []);
    }
  }, [pricingFactorsData]);

  const handleAutocompleteChange = (id: string) => {
    setSelectedCategoryId(id);
  };

  const handleManualCategoryChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedCategoryId(event.target.value);
  };

  const handleSubmit = async () => {
    setSelectedCategoryId(selectedCategoryId);
    await getRequiredFields({id: selectedCategoryId});
  };

  useEffect(() => {
    if (fieldsData?.data) {
      setRequiredFields(fieldsData.data);
    } else {
      setRequiredFields([]);
    }

    if (fieldsData?.error) {
      NotificationHelper.showError(t('general.error'));
    }

    return () => {
      fieldsData.data = null;
      fieldsData.error = null;
    };
  }, [fieldsData]);

  return (
    <div className="product-details">
      <div className="product-info">
        <Grid container spacing={2} alignItems="center" style={{padding: '10px'}}>
          <Grid item xs={12} sm={4}>
            <AutocompleteComponent
              id="category"
              placeholder={t('pricing.categoryId')}
              label={t('pricing.categoryId')}
              defaultValue={null as any}
              onChange={handleAutocompleteChange}
              endpoint="ebay_category_autocomplete_list"
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              label={t('Enter Category ID')}
              variant="outlined"
              value={selectedCategoryId}
              onChange={handleManualCategoryChange}
              fullWidth
              size="small"
              defaultValue={null as any}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Button variant="contained" className="blue-button" onClick={handleSubmit}>
              {t('Submit')}
            </Button>
          </Grid>
        </Grid>
      </div>

      <div className="product-info">
        {fieldsData?.loading ? (
          <CircularProgress />
        ) : (
          <>
            {requiredFields.length > 0 ? (
              <div style={{padding: '10px'}}>
                <Typography variant="h6">{t('Required Fields for Category')}</Typography>
                <List>
                  {requiredFields.map((field) => (
                    <ListItem key={field.name}>
                      <ListItemText primary={`${field.name} (${field.dataType}, ${field.cardinality})`} />
                    </ListItem>
                  ))}
                </List>
              </div>
            ) : (
              selectedCategoryId && (
                <Typography variant="body1" style={{margin: '20px 20px 0px 10px'}}>
                  {t('No required fields for this category')}
                </Typography>
              )
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default EbayCategories;
