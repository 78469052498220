import React from 'react';
import DataTable from '../../components/Table/Table';
import {useTranslation} from 'react-i18next';

const ProductsStockComparision = () => {
  const {t} = useTranslation();
  return (
    <div className="products">
      <DataTable
        className="products__table"
        headers={[
          {
            kind: 'accessor',
            name: t('products.productId'),
            accessor: 'productId',
            sortable: true,
            type: 'number',
          },
          {
            kind: 'accessor',
            name: t('products.title'),
            accessor: 'title',
            sortable: true,
          },
          {
            kind: 'accessor',
            name: t('products.combinedSupplierStock'),
            accessor: 'todayStockQuantity',
            type: 'number',
          },
          {
            kind: 'accessor',
            name: t('productDetails.previousDaysAverage'),
            accessor: 'productsForComparisonAverage',
            type: 'number',
          },
          {
            kind: 'accessor',
            name: t('productDetails.ratio'),
            accessor: 'ratio',
            type: 'number',
          },
          {
            kind: 'accessor',
            name: t('products.productType'),
            accessor: 'productInformation.productType',
          },
          {
            kind: 'accessor',
            name: t('products.manufacturer'),
            accessor: 'productInformation.manufacturer',
          },
          {
            kind: 'accessor',
            name: t('products.shopwareId'),
            accessor: 'shopwareId',
          },
        ]}
        endpoint={'products_stock_comparision'}
        target={'products'}
        options={{}}
        defaultSort={{key: 'productId', value: 1}}
        title={t('general.productsStockComparision')}
      />
    </div>
  );
};

export default ProductsStockComparision;
