import {Typography} from '@material-ui/core';
import React, {ReactElement} from 'react';
import Form from '../Form/Form';
import {useTranslation} from 'react-i18next';
import * as Yup from 'yup';

const AddSupplierOrderItemModal = (props: IAddSupplierOrderItem): ReactElement => {
  const {onSubmit, onCancel, productId, supplierId, defaultValues, isLoading} = props;
  const {t} = useTranslation();

  const generateInitialValues = (fields: any[]) => {
    let defaults = {};
    fields.forEach((field) => {
      defaults = {...defaults, [field.fieldKey]: defaultValues ? defaultValues[field.fieldKey] ?? '' : ''};
    });
    return defaults;
  };

  const fields: any = [
    {
      label: t('supplierOrderDetails.price'),
      placeholder: t('supplierOrderDetails.price'),
      fieldKey: 'price',
      type: 'input',
      fieldKeyType: 'number',
      required: true,
    },
    {
      label: t('supplierOrderDetails.quantity'),
      placeholder: t('supplierOrderDetails.quantity'),
      fieldKey: 'quantity',
      type: 'input',
      fieldKeyType: 'number',
      required: true,
    },
  ];

  if (!supplierId) {
    fields.splice(0, 0, {
      label: t('supplierOrderDetails.supplier'),
      placeholder: t('supplierOrderDetails.supplier'),
      fieldKey: 'supplier',
      type: 'autocomplete',
      endpoint: 'supplier_autocomplete_list',
      options: [],
      required: true,
    });
  }

  if (!productId) {
    const index = supplierId ? 0 : 1;

    fields.splice(index, 0, {
      label: t('supplierOrderDetails.product'),
      placeholder: t('supplierOrderDetails.product'),
      fieldKey: 'product',
      type: 'autocomplete',
      endpoint: 'product_autocomplete_list',
      required: true,
      options: [],
    });
  }

  const initialValues = generateInitialValues(fields);

  const validationSchema = Yup.object().shape({
    supplier: !supplierId ? Yup.string().required('Required').nullable() : Yup.string().optional(),
    product: !productId ? Yup.string().required('Required').nullable() : Yup.string().optional(),
    price: Yup.number().required('Required').nullable(),
    quantity: Yup.number().required('Required').nullable(),
  });

  return (
    <>
      <Typography className="modal-title">{t('supplierOrderItems.addButton')}</Typography>
      <Form
        initialValues={initialValues}
        fields={fields}
        submitButtonText={t('general.submit')}
        cancelButtonText={t('general.cancel')}
        onSubmit={onSubmit}
        onCancel={onCancel}
        validationSchema={validationSchema}
        isLoading={isLoading}
      ></Form>
    </>
  );
};

export interface IAddSupplierOrderItem {
  onSubmit: (values: any) => void;
  onCancel: () => void;
  productId?: string;
  supplierId?: string;
  defaultValues?: any;
  isLoading?: boolean;
}

export default AddSupplierOrderItemModal;
