import {ISnackBarData} from '../../components/SnackBar/SnackBarBase';
import {ActionsUnion, createAction} from '../../lib/actions';
import {LoginState} from './saga';

enum APP {
  INIT = 'APP_INIT',
  SET_LOGIN_STATE = 'SET_LOGIN_STATE',
  DO_GLOBAL_LOADER = 'DO_GLOBAL_LOADER',
  LOGOUT = 'LOGOUT',
  SHOW_SNACK_BAR = 'SHOW_SNACK_BAR',
  SYNC_START = 'SYNC_START',
  SYNC_UPDATE = 'SYNC_UPDATE',
  RESET_SYNC = 'RESET_SYNC',
}

const appActions = {
  init: () => createAction(APP.INIT),
  setLoginState: (loginState: LoginState) => createAction(APP.SET_LOGIN_STATE, loginState),
  doLogout: () => createAction(APP.LOGOUT),
  doLoader: (data: boolean) => createAction(APP.DO_GLOBAL_LOADER, data),
  showSnackBar: (data: ISnackBarData) => createAction(APP.SHOW_SNACK_BAR, data),
  startSync: (data: any[]) => createAction(APP.SYNC_START, data),
  onSyncUpdate: (data: any) => createAction(APP.SYNC_UPDATE, data),
  resetSync: () => createAction(APP.RESET_SYNC),
};

export type AppAction = ActionsUnion<typeof appActions>;
export {APP, appActions};
