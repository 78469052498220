import {Button} from '@material-ui/core';
import moment from 'moment';
import React, {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {useStorlessFetch} from '../../hooks/fetch';
import {modalActions} from '../../modules/modal/actions';
import {RunSync, SyncStatus} from '../../screens/SyncStatuses/SyncStatus';
import {SyncTypes} from '../../screens/SyncStatuses/SyncStatuses';
import {MODALS} from '../Modal/ModalContents';
import EditIcon from '@material-ui/icons/Edit';
export interface ISupplierSyncStatus {
  syncName: string;
}

const SupplierSyncStatus = ({syncName}: ISupplierSyncStatus) => {
  const [syncStatus, getSyncStatus] = useStorlessFetch('sync_statuses');
  const [runSync, doRunSync] = useStorlessFetch('run_sync');
  const {t} = useTranslation();

  useEffect(() => {
    getSyncStatus({conditions: {syncName}});
  }, [runSync]);

  const syncStatusData = syncStatus.data?.data[0];

  return (
    <>
      <div>
        <h3>{t('syncStatus.syncName')}: </h3>
        <p>{syncStatusData?.syncName}</p>
      </div>

      <div>
        <h3>{t('syncStatus.status')}: </h3>
        <SyncStatus row={{status: syncStatusData?.status}} />
      </div>

      <div>
        <h3>{t('syncStatus.startedAt')}: </h3>
        <p>{moment(syncStatusData?.startedAt).format('DD. MMM YYYY HH:mm:ss')}</p>
      </div>
      <div>
        <h3>{t('syncStatus.finishedAt')}: </h3>
        <p>{moment(syncStatusData?.finishedAt).format('DD. MMM YYYY HH:mm:ss')}</p>
      </div>
      <div>
        <h3>Error: </h3>
        <p>{syncStatusData?.error ?? 'N/A'}</p>
      </div>
      <RunSync row={{syncName: SyncTypes.SUPPLIER_ARTICLES, supplierName: syncStatusData?.syncName}} />
    </>
  );
};

export const ShowSupplierSyncStatus = (props: any) => {
  const [syncStatus, getSyncStatus] = useStorlessFetch('sync_statuses');
  const syncStatusData = syncStatus.data?.data[0];

  useEffect(() => {
    getSyncStatus({conditions: {syncName: props.row.name}});
  }, []);

  return <SyncStatus row={{status: syncStatusData?.status}} />;
};

export const ShowSupplierLastSyncUpdatedDate = (props: any) => {
  const [syncStatus, getSyncStatus] = useStorlessFetch('sync_statuses');
  const syncStatusData: any = syncStatus.data?.data[0];

  useEffect(() => {
    getSyncStatus({conditions: {syncName: props.row.name}});
  }, []);

  return (
    <>
      {syncStatusData?.updatedFileDate ? moment(syncStatusData?.updatedFileDate).format('DD. MMM YYYY HH:mm:ss') : '-'}
    </>
  );
};

export const UpdateSupplier = (props: any) => {
  const dispatch = useDispatch();
  return (
    <Button onClick={() => dispatch(modalActions.addModal(MODALS.NEW_SUPPLIER, {supplierId: props.row._id}))}>
      <EditIcon style={{fontSize: 20}} />
    </Button>
  );
};
export default SupplierSyncStatus;
