import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import DataTable from '../../components/Table/Table';
import UnfinishedSupplierOrderActions from '../../components/SupplierOrder/UnfinishedSupplierOrderActions';

const UnfinishedSupplierOrders = () => {
  const {t} = useTranslation();
  const [triggerRefreshTable, setTriggerRefreshTable] = useState<any>(null);

  return (
    <div className="supplier-articles">
      <DataTable
        className="new-supplier__table"
        headers={[
          {
            kind: 'accessor',
            name: t('products.productId'),
            accessor: 'productId',
            sortable: true,
            type: 'number',
          },
          {
            kind: 'accessor',
            name: t('products.title'),
            accessor: 'title',
            sortable: true,
          },
          {
            kind: 'component',
            name: t(''),
            component: UnfinishedSupplierOrderActions,
            props: {setTriggerRefreshTable: (productId: string) => setTriggerRefreshTable(productId)},
            onFetchProps: {},
          },
        ]}
        endpoint={'unfinished_supplier_order_products'}
        options={{}}
        defaultSort={{key: 'productId', value: 1}}
        numberOfItemsPerPage={50}
        refreshData={triggerRefreshTable}
        target={`products`}
        selectRowClickId={'_id'}
        title={t('general.unfinishedSupplierOrders')}
      />
    </div>
  );
};

export default UnfinishedSupplierOrders;
