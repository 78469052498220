import React, {useEffect} from 'react';

import {appActions} from '../modules/app/actions';
import {useDispatch} from 'react-redux';

interface IProps {
  children?: any;
}
const Splash = ({children}: IProps) => {
  const dispatch = useDispatch();
  useEffect(() => {
    const loadingTimer = setTimeout(() => {
      dispatch(appActions.init());
    }, 100);
    return () => clearTimeout(loadingTimer);
  }, []);
  return <div>{children}</div>;
};

export default Splash;
