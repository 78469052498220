import _ from 'lodash';
import {useDispatch, useSelector} from 'react-redux';
import {FILTERS, FiltersConfig} from '../components/Filters/filters';
import {MODALS} from '../components/Modal/ModalContents';
import {filterActions} from '../modules/filters/actions';
import {modalActions} from '../modules/modal/actions';
function checkDataValues(data: any) {
  Object.keys(data).forEach((key) => {
    if (data[key] === 'all') {
      delete data[key];
    }
  });
  return data;
}
export const useFilters = (filterKey: FILTERS) => {
  const dispatch = useDispatch();
  const data =
    useSelector((state) => _.get(state, 'filter.filters').find((filter: any) => filter.key === filterKey))?.value ??
    _.get(FiltersConfig, filterKey)?.initialValues ??
    {};
  const onChange = (inputValue: any) => {
    dispatch(filterActions.setFilterValue({key: filterKey, value: {...data, ...inputValue}}));
  };
  const showFilter = (show: boolean) =>
    show
      ? dispatch(modalActions.addRightDrawer(MODALS.FILTER, {elements: _.get(FiltersConfig, filterKey), filterKey}))
      : dispatch(modalActions.closeRightDrawer());
  const canShow = filterKey !== FILTERS.NONE;
  return [checkDataValues(data), onChange, showFilter, canShow];
};
