import React, {ReactElement} from 'react';
import {IOrder} from '../../../types/Orders/IOrder';

const ShippingAddressInfo = (props: IShippingAddressInfo): ReactElement => {
  const {order, paddingLeft} = props;

  return (
    <p style={{whiteSpace: 'pre-line', paddingLeft: paddingLeft}}>
      {order.shippingAddress?.customerName + '\n'}
      {order.shippingAddress?.addressLine2 ? order.shippingAddress?.addressLine2 + '\n' : ''}
      {order.shippingAddress?.deliveryInstruction ? order.shippingAddress?.deliveryInstruction + '\n' : ''}
      {order.shippingAddress?.addressLine1 ? order.shippingAddress?.addressLine1 + '\n' : ''}
      {order.shippingAddress?.zip} {order.shippingAddress?.city + '\n'}
      {order.shippingAddress?.countryCode}
    </p>
  );
};

interface IShippingAddressInfo {
  order: IOrder;
  paddingLeft: string;
}

export default ShippingAddressInfo;
