import {Button} from '@material-ui/core';
import React, {ReactElement, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {modalActions} from '../../modules/modal/actions';
import {MODALS} from '../Modal/ModalContents';
import {useFetch} from '../../hooks/fetch';

const AddProductToCustomListButton = (props: IAddProductToCustomListButton): ReactElement => {
  const dispatch = useDispatch();
  const {t} = useTranslation();

  const [addProdutResponse, addProduct] = useFetch('add_custom_list_product');

  const handleCancelModal = () => {
    dispatch(modalActions.closeModal());
  };

  const handleSubmitModal = async (values: any) => {
    addProduct({name: props.name, productId: values.product});
    handleCancelModal();
  };

  const handleClick = async () => {
    dispatch(
      modalActions.addModal(MODALS.ADD_PRODUCT_TO_CUSTOM_LIST, {
        onSubmit: handleSubmitModal,
        onCancel: handleCancelModal,
      }),
    );
  };

  useEffect(() => {
    props.refresh();
  }, [addProdutResponse?.data]);

  return (
    <Button variant="contained" className="blue-button" onClick={handleClick}>
      {t('supplierOrderItems.addButton')}
    </Button>
  );
};

export interface IAddProductToCustomListButton {
  name: string;
  refresh: () => void;
}

export default AddProductToCustomListButton;
