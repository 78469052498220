import React, {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useFetch} from '../../hooks/fetch';
import {ICustomReadyForShippingList} from '../../types/Orders/ICustomReadyForShippingList';
import {Button, IconButton, TextField} from '@material-ui/core';
import {APP_ROUTES} from '../../routers/routes';
import {Link} from 'react-router-dom';
import {Settings} from '@material-ui/icons';
import {useDispatch} from 'react-redux';
import {MODALS} from '../../components/Modal/ModalContents';
import {modalActions} from '../../modules/modal/actions';

const Orders = () => {
  const [lists, setLists] = React.useState<ICustomReadyForShippingList[]>([]);
  const [dynamicProductsList, setDynamicProductsList] = React.useState<any[]>([]);
  const [listName, setListName] = React.useState<string>('');
  const [customListsResponse, getCustomLists] = useFetch<any>('get_custom_ready_for_shipping_lists');
  const [createCustomListResponse, createCustomList] = useFetch<any>('create_custom_list');
  const [deleteCustomListResponse, deleteCustomList] = useFetch<any>('delete_custom_list');
  const [customProductListsResponse, getCustomProductLists] = useFetch<any>(
    'get_custom_ready_for_shipping_product_lists',
  );
  const dispatch = useDispatch();
  const {t} = useTranslation();

  useEffect(() => {
    getCustomLists();
    getCustomProductLists();
  }, []);

  useEffect(() => {
    setLists(customListsResponse.data);
  }, [customListsResponse]);

  useEffect(() => {
    setDynamicProductsList(customProductListsResponse.data);
  }, [customProductListsResponse]);

  const handleDeleteList = (listName: string) => {
    deleteCustomList({name: listName});
    dispatch(modalActions.closeModal());
  };

  const createList = (listName: string) => {
    if (listName === '') {
      return;
    }
    createCustomList({name: listName});
  };

  const deleteList = (listName: string) => {
    dispatch(
      modalActions.addModal(MODALS.CONFIRM_DIALOG, {
        title: t('general.areYouSure'),
        content: t('general.areYouSure'),
        onYes: () => handleDeleteList(listName),
        onNo: () => dispatch(modalActions.closeModal()),
      }),
    );
  };

  useEffect(() => {
    if (createCustomListResponse.data || deleteCustomListResponse.data) {
      getCustomLists();
      setListName('');
    }
    return () => {
      createCustomListResponse.data = null;
      createCustomListResponse.error = null;
      createCustomListResponse.loaded = null;
      deleteCustomListResponse.data = null;
      deleteCustomListResponse.error = null;
      deleteCustomListResponse.loaded = null;
    };
  }, [createCustomListResponse?.data, deleteCustomListResponse?.data]);

  return (
    <div className="orders">
      <div className="orders__ready-for-shipping-lists">
        <Link to={APP_ROUTES.PRIME_ORDERS_READY_FOR_SHIPPING}>Prime</Link>
        <Link to={APP_ROUTES.EBAY_ORDERS_READY_FOR_SHIPPING}>eBay</Link>
        <Link to={APP_ROUTES.DDA_ORDERS_READY_FOR_SHIPPING}>Druckdichaus</Link>
        <Link to={APP_ROUTES.SP_ORDERS_READY_FOR_SHIPPING}>Supplies partner</Link>
        <Link to={APP_ROUTES.DHL_ORDERS_READY_FOR_SHIPPING}>DHL</Link>
        <Link to={APP_ROUTES.GLS_ORDERS_READY_FOR_SHIPPING}>GLS</Link>
        <Link to={APP_ROUTES.UPS_ORDERS_READY_FOR_SHIPPING}>UPS</Link>
      </div>

      <div style={{display: 'flex'}}>
        <div style={{marginRight: '20px'}}>
          <h2>{t('customReadyForShipping.customLists')}</h2>
          <form className="flex" style={{maxWidth: '450px', alignItems: 'baseline'}}>
            <TextField
              variant="outlined"
              className="input"
              size="small"
              value={listName}
              style={{marginRight: '20px'}}
              label={t('orders.listName')}
              InputLabelProps={{shrink: true}}
              onChange={(event: any) => {
                setListName(event.target.value);
              }}
              required
            ></TextField>
            <Button variant="contained" className="blue-button" onClick={() => createList(listName)}>
              {t('general.submit')}
            </Button>
          </form>
          {lists &&
            lists.map((list) => (
              <div key={list.name} className="flex" style={{width: 450, padding: '3px 0', alignItems: 'center'}}>
                <Link to={`${APP_ROUTES.ORDERS_READY_FOR_SHIPPING}/${list.name}`} className="flex-1 custom-list-link">
                  <b>{list.name}</b>
                </Link>
                <Link
                  to={`${APP_ROUTES.CUSTOM_LISTS_READY_FOR_SHIPPING_OVERVIEW}/${list.name}`}
                  style={{marginRight: '10px'}}
                >
                  <IconButton style={{marginBottom: 0}}>
                    <Settings />
                  </IconButton>
                </Link>
                <Button variant="contained" className="yellow-button" onClick={() => deleteList(list.name)}>
                  {t('general.delete')}
                </Button>
              </div>
            ))}
        </div>
        <div>
          <h2>{t('customReadyForShipping.dynamicProductLists')}</h2>
          {dynamicProductsList &&
            dynamicProductsList.map((product) => (
              <div key={product.id} className="flex" style={{width: 450, padding: '6px 0', alignItems: 'center'}}>
                <Link
                  to={`${APP_ROUTES.ORDERS_READY_FOR_SHIPPING}/product/${product.id}`}
                  className="flex-1"
                  style={{textDecoration: 'none', color: '#1476b4'}}
                >
                  <b>{product.name}</b>
                </Link>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default Orders;
