import {Typography} from '@material-ui/core';
import React from 'react';
import Form from '../../Form/Form';
import _ from 'lodash';
import {useTranslation} from 'react-i18next';

export interface IEditUpsConfig {
  title: string;
  onSubmit: (values: any) => void;
  onCancel: () => void;
  data?: any;
}

const generateInitialValues = (fields: any[], data: any) => {
  if (data) {
    return data;
  } else {
    let defaults = {};
    fields.forEach((field) => {
      defaults = {...defaults, [field.fieldKey]: ''};
    });
    return defaults;
  }
};

const EditUpsConfig = (props: IEditUpsConfig) => {
  const {title, onSubmit, onCancel, data} = props;
  const {t} = useTranslation();

  const fields = [
    {
      label: t('upsConfiguration.name'),
      placeholder: t('upsConfiguration.name'),
      fieldKey: 'data.shipper.name',
      type: 'input',
    },
    {
      label: t('upsConfiguration.shipperNumber'),
      placeholder: t('upsConfiguration.shipperNumber'),
      fieldKey: 'data.shipper.shipperNumber',
      type: 'input',
    },
    {
      label: t('upsConfiguration.attentionName'),
      placeholder: t('upsConfiguration.attentionName'),
      fieldKey: 'data.shipper.AttentionName',
      type: 'input',
    },
    {
      label: t('upsConfiguration.phone'),
      placeholder: t('upsConfiguration.phone'),
      fieldKey: 'data.shipper.Phone.Number',
      type: 'input',
    },
    {
      label: t('upsConfiguration.addressLine'),
      placeholder: t('upsConfiguration.addressLine'),
      fieldKey: 'data.shipper.address.addressLine',
      type: 'input',
    },
    {
      label: t('upsConfiguration.city'),
      placeholder: t('upsConfiguration.city'),
      fieldKey: 'data.shipper.address.city',
      type: 'input',
    },
    {
      label: t('upsConfiguration.postalCode'),
      placeholder: t('upsConfiguration.postalCode'),
      fieldKey: 'data.shipper.address.postalCode',
      type: 'input',
    },
    {
      label: t('upsConfiguration.countryCode'),
      placeholder: t('upsConfiguration.countryCode'),
      fieldKey: 'data.shipper.address.countryCode',
      type: 'input',
    },
    {
      label: t('upsConfiguration.serviceCode'),
      placeholder: t('upsConfiguration.serviceCode'),
      fieldKey: 'data.service.code',
      type: 'input',
    },
    {
      label: t('upsConfiguration.serviceDescription'),
      placeholder: t('upsConfiguration.serviceDescription'),
      fieldKey: 'data.service.description',
      type: 'input',
    },
    {
      label: t('upsConfiguration.unit'),
      placeholder: t('upsConfiguration.unit'),
      fieldKey: 'data.packageWeight.unitOfMeasurement.code',
      type: 'input',
    },
    {
      label: t('upsConfiguration.packageWeight'),
      placeholder: t('upsConfiguration.packageWeight'),
      fieldKey: 'data.packageWeight.weight',
      type: 'input',
    },
    {
      label: t('upsConfiguration.url'),
      placeholder: t('upsConfiguration.url'),
      fieldKey: 'data.connectionConfig.url',
      type: 'input',
    },
    {
      label: t('upsConfiguration.clientId'),
      placeholder: t('upsConfiguration.clientId'),
      fieldKey: 'data.connectionConfig.clientId',
      type: 'input',
    },
    {
      label: t('upsConfiguration.clientSecret'),
      placeholder: t('upsConfiguration.clientSecret'),
      fieldKey: 'data.connectionConfig.clientSecret',
      type: 'input',
    },
  ];

  const initialValues = generateInitialValues(fields, data);

  return (
    <>
      <Typography className="modal-title">{title}</Typography>
      <Form
        initialValues={initialValues}
        fields={fields}
        submitButtonText={t('general.submit')}
        cancelButtonText={t('general.cancel')}
        onSubmit={onSubmit}
        onCancel={onCancel}
      ></Form>
    </>
  );
};

export default EditUpsConfig;
