import {Button} from '@material-ui/core';
import React, {ReactElement, useContext, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {useFetch} from '../../../hooks/fetch';
import {appActions} from '../../../modules/app/actions';
import OrderDetailsContext from '../../../screens/Orders/Context/OrderDetailsContext';

const ShipmentSyncButton = (): ReactElement => {
  const {order, getOrderDetails} = useContext(OrderDetailsContext);
  const [shippingLabelResponse, createShippingLabel] = useFetch<any>('shipping_create_label');
  const dispatch = useDispatch();
  const {t} = useTranslation();

  const shipmentSyncHandler = async () => {
    const data = {id: order._id, ...order};
    createShippingLabel(data);
  };

  useEffect(() => {
    if (shippingLabelResponse?.data && !shippingLabelResponse.error && !shippingLabelResponse?.data?.error) {
      dispatch(appActions.showSnackBar({text: t('orderDetails.shippingLabelCreated'), options: {severity: 'success'}}));
      getOrderDetails({id: order._id});
    } else if (shippingLabelResponse.error || shippingLabelResponse?.data?.error) {
      const errorMessage = shippingLabelResponse.data?.error?.detail
        ? shippingLabelResponse.data?.error?.detail
        : shippingLabelResponse.data?.error || t('orderDetails.dataValidationFailed');

      dispatch(appActions.showSnackBar({text: errorMessage, options: {severity: 'error'}}));
      getOrderDetails({id: order._id});
    }
    return () => {
      shippingLabelResponse.loaded = null;
      shippingLabelResponse.data = null;
      shippingLabelResponse.error = null;
    };
  }, [shippingLabelResponse]);

  return !order.trackingNumber ? (
    <Button variant="contained" className="blue-button" onClick={shipmentSyncHandler}>
      {t('orderDetails.shipmentSync')}
    </Button>
  ) : (
    <></>
  );
};

export default ShipmentSyncButton;
