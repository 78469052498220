import {Typography} from '@material-ui/core';
import React, {useEffect, useState} from 'react';
import Form from '../../Form/Form';
import * as Yup from 'yup';
import {useFetch} from '../../../hooks/fetch';
import {useTranslation} from 'react-i18next';

export interface IAddOrderProblem {
  title: string;
  onSubmit: (values: any) => void;
  onCancel: () => void;
  orderProblemId?: any;
}

const generateInitialValues = (fields: any[], orderProblemObject: any) => {
  if (orderProblemObject) {
    return orderProblemObject;
  } else {
    let defaults = {};
    fields.forEach((field) => {
      defaults = {...defaults, [field.fieldKey]: ''};
    });
    return {
      ...defaults,
      shippingDate: new Date().toISOString(),
    };
  }
};

const AddOrderProblem = (props: IAddOrderProblem) => {
  const {title, onCancel, onSubmit, orderProblemId} = props;
  const [orderProblemDetails, getOrderProblemDetails] = useFetch('order_problem_details');
  const [orderProblemTypes, getOrderProblemTypes] = useFetch('order_problem_types_get');
  const [problemTypes, setProblemTypes] = useState(null);
  const [initialValues, setInitialValues] = useState(null);
  const {t} = useTranslation();

  const problemLocations = [
    {label: 'Customer', value: 'Customer'},
    {label: 'Supplier', value: 'Supplier'},
    {label: 'Shipping provider', value: 'Shipping Provider'},
  ];

  const problemStatuses = [
    {label: 'Open', value: 'Open'},
    {label: 'In Progress', value: 'In Progress'},
    {label: 'Reported', value: 'Reported'},
    {label: 'Closed', value: 'Closed'},
  ];

  useEffect(() => {
    if (orderProblemId) {
      getOrderProblemDetails({id: orderProblemId});
    } else {
      const data = generateInitialValues(fields, null);
      setInitialValues(data);
    }

    return () => {
      orderProblemDetails.data = null;
      orderProblemDetails.loaded = null;
      orderProblemDetails.error = null;
    };
  }, [orderProblemId]);

  useEffect(() => {
    getOrderProblemTypes();

    return () => {
      orderProblemTypes.data = null;
      orderProblemTypes.loaded = null;
      orderProblemTypes.error = null;
    };
  }, []);

  useEffect(() => {
    if (orderProblemTypes.loaded && !orderProblemTypes.error) {
      const data = orderProblemTypes.data.map((item: any) => {
        return {label: item.name, value: item._id};
      });
      setProblemTypes(data);
    }

    return () => {
      orderProblemTypes.data = null;
      orderProblemTypes.loaded = null;
      orderProblemTypes.error = null;
    };
  }, [orderProblemTypes]);

  useEffect(() => {
    if (orderProblemDetails.loaded && !orderProblemDetails.error) {
      const data = generateInitialValues(fields, orderProblemDetails?.data);
      setInitialValues(data);
    }

    return () => {
      orderProblemDetails.data = null;
      orderProblemDetails.loaded = null;
      orderProblemDetails.error = null;
    };
  }, [orderProblemDetails]);

  const orderProblemSchema = Yup.object().shape({
    orderProblemType: Yup.string().required('Required').nullable(),
    location: Yup.string().required('Required').nullable(),
    status: Yup.string().required('Required').nullable(),
    dateNow: Yup.date().default(() => new Date()),
  });

  const fields = [
    {
      label: t('orderProblems.location'),
      placeholder: 'Location',
      fieldKey: 'location',
      type: 'select',
      options: problemLocations,
      required: true,
    },
    {
      label: t('orderProblems.orderProblemType'),
      placeholder: t('orderProblems.orderProblemType'),
      fieldKey: 'orderProblemType',
      type: 'select',
      options: problemTypes,
      required: true,
    },
    {
      label: t('orderProblems.status'),
      placeholder: t('orderProblems.status'),
      fieldKey: 'status',
      type: 'select',
      options: problemStatuses,
      required: true,
    },
    {
      label: t('orderProblems.reminderDate'),
      placeholder: t('orderProblems.reminderDate'),
      fieldKey: 'reminderDate',
      type: 'dateTime',
    },
    {
      label: t('orderProblems.comment'),
      placeholder: t('orderProblems.comment'),
      fieldKey: 'comment',
      type: 'input',
    },
  ];

  return (
    <>
      <Typography className="modal-title">{title}</Typography>
      {problemTypes && initialValues ? (
        <Form
          initialValues={initialValues}
          fields={fields}
          submitButtonText={t('general.submit')}
          cancelButtonText={t('general.cancel')}
          onSubmit={onSubmit}
          onCancel={onCancel}
          validationSchema={orderProblemSchema}
        ></Form>
      ) : null}
    </>
  );
};

export default AddOrderProblem;
