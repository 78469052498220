// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "\n", "",{"version":3,"sources":[],"names":[],"mappings":"","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"blackColor": "#000",
	"whiteColor": "#fff",
	"primaryColor": "#1565c0",
	"primaryDarkColor": "#0d47a1",
	"primaryLightColor": "#1976d2",
	"primaryTextColor": "#212121",
	"darkPrimaryColor": "#d4d4d8",
	"darkPrimaryDarkColor": "#bef264",
	"darkPrimaryLightColor": "#e4e4e7",
	"darkPrimaryTextColor": "#eab308",
	"secondaryColor": "#9e9e9e",
	"secondaryDarkColor": "#707070",
	"secondaryLightColor": "#cfcfcf",
	"secondaryTextColor": "#757575",
	"darkSecondaryColor": "#cfcfcf",
	"darkSecondaryDarkColor": "#707070",
	"darkSecondaryLightColor": "#e0e0e0",
	"darkSecondaryTextColor": "#ffffff",
	"errorColor": "#d32f2f",
	"errorDarkColor": "#f44336",
	"errorLightColor": "#ffcdd2",
	"darkErrorColor": "#f44336",
	"darkErrorDarkColor": "#d32f2f",
	"darkErrorLightColor": "#ffcdd2",
	"accentColor": "#7c4dff",
	"deviderColor": "#bdbdbd",
	"darkAccentColor": "#7c4dff",
	"darkDeviderColor": "#bdbdbd",
	"disabledColor": "rgba(0, 0, 0, 0.5)",
	"hintColor": "rgba(0, 0, 0, 0.5)",
	"darkDisabledColor": "rgba(255, 255, 255, 0.5)",
	"darkHintColor": "rgba(255, 255, 255, 0.5)",
	"fontSizeBody": "16px",
	"fontSizeTitle": "30px",
	"fontSizeTitleSmall": "22px",
	"fontSizeSubtitle": "24px",
	"fontSizeSubtitleSmall": "14px",
	"darkFirstBackground": "#09090b",
	"darkSecondBackground": "#27272a",
	"darkThirdBackground": "#18181b",
	"darkTextColor": "#a9a9a9",
	"darkPrimaryButtonBackground": "#0284c7",
	"successColor": "green",
	"darkSuccessColor": "green"
};
export default ___CSS_LOADER_EXPORT___;
