import {Button} from '@material-ui/core';
import React, {ReactElement, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {useFetch} from '../../../hooks/fetch';
import {appActions} from '../../../modules/app/actions';
import {modalActions} from '../../../modules/modal/actions';
import {IOrderProblem} from '../../../types/OrderProblems/IOrderProblem';
import {ProblemOrderType} from '../../../types/OrderProblems/ProblemOrderType';
import {MODALS} from '../../Modal/ModalContents';

const SupplierOrderAddProblemButton = (props: ISupplierOrderAddProblemButtonProps): ReactElement => {
  const [orderProblem, doOrderProblemPost] = useFetch('order_problem_post');
  const dispatch = useDispatch();
  const {t} = useTranslation();
  const {supplierOrderId, refreshOrderProblemTable} = props;
  const handleCancelModal = () => dispatch(modalActions.closeModal());

  const orderProblemSubmitHandler = async (values: any) => {
    const orderProblem: IOrderProblem = {...values};
    orderProblem.order = supplierOrderId;
    orderProblem.orderNumber = orderProblem.orderNumber?.toString();
    orderProblem.orderType = ProblemOrderType.supplierOrder;
    doOrderProblemPost(orderProblem);
  };

  const handleProblemClick = async () => {
    refreshOrderProblemTable(false);
    dispatch(
      modalActions.addModal(MODALS.ADD_ORDER_PROBLEM, {
        title: t('orderDetails.addProblem') || '',
        onCancel: handleCancelModal,
        onSubmit: orderProblemSubmitHandler,
      }),
    );
  };

  useEffect(() => {
    if (orderProblem?.data) {
      handleCancelModal();
      refreshOrderProblemTable(true);
      dispatch(appActions.showSnackBar({text: t('general.submitFormText'), options: {severity: 'success'}}));
    } else if (orderProblem?.error) {
      dispatch(appActions.showSnackBar({text: orderProblem.error.name, options: {severity: 'error'}}));
    }

    return () => {
      orderProblem.data = null;
      orderProblem.error = null;
    };
  }, [orderProblem]);

  return (
    <Button variant="contained" className="blue-button" onClick={handleProblemClick}>
      {t('orderDetails.addProblem')}
    </Button>
  );
};
interface ISupplierOrderAddProblemButtonProps {
  supplierOrderId: any;
  refreshOrderProblemTable: (data: any) => void;
}

export default SupplierOrderAddProblemButton;
