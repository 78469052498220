export enum ShopType {
  SUPPLIES_PARTNER = 'www.supplies-partner.de',
  DRUCKDICHAUS = 'www.druckdichaus.de',
  DDA_PORTAL = 'DDA Portal',
  AMAZON = 'Amazon',
  OTTO = 'OTTO',
  CHECK24 = 'Check 24',
  METRO = 'Metro',
  EBAY = 'ebay',
  GALAXUS = 'Galaxus',
  KAUFLAND = 'Kaufland',
  CONRAD = 'Conrad',
  FNAC = 'Fnac',
}
