import React, {useContext, useEffect, useState} from 'react';
import {IOrder} from '../../types/Orders/IOrder';
import useIsAdmin from '../../hooks/isAdmin';
import {Button, MenuItem, Select, TextField} from '@material-ui/core';
import {useTranslation} from 'react-i18next';
import {OrderStatusType} from '../../types/Orders/OrderStatusType';
import {useFetch} from '../../hooks/fetch';
import {appActions} from '../../modules/app/actions';
import {useDispatch} from 'react-redux';
import OrderDetailsContext from '../../screens/Orders/Context/OrderDetailsContext';

const EditOrderStatus = (props: IEditOrderStatusProps) => {
  const {order, setOrder} = useContext(OrderDetailsContext);
  const [updateOrderResponse, updateOrder] = useFetch<any>('order_status_patch');
  const [status, setStatus] = useState(order?.status);
  const [originalStatus, setOriginalStatus] = useState(order?.status);
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const isAdmin = useIsAdmin();
  useEffect(() => {
    if (updateOrderResponse.data) {
      dispatch(appActions.showSnackBar({text: t('general.success'), options: {severity: 'success'}}));
    } else if (updateOrderResponse.error) {
      dispatch(appActions.showSnackBar({text: t('general.error'), options: {severity: 'error'}}));
    }
    return () => {
      updateOrderResponse.data = null;
      updateOrderResponse.error = null;
    };
  }, [updateOrderResponse]);

  const updateOrderStatus = () => {
    updateOrder({id: order._id, status});
    setOriginalStatus(status);
  };

  return (
    <div className="order-info-text">
      {isAdmin ? (
        <span>
          <Select
            id="selectPaymentMethod"
            value={order.status}
            onChange={(event) => {
              setOrder({...order, status: event.target.value as any});
              setStatus(event.target.value as any);
            }}
            style={{marginRight: '5px'}}
          >
            {Object.values(OrderStatusType).map((item: any) => (
              <MenuItem key={item} value={item}>
                {item}
              </MenuItem>
            ))}
          </Select>
          {originalStatus !== status && (
            <Button variant="contained" className="order-info-button" onClick={updateOrderStatus}>
              {t('general.save')}
            </Button>
          )}
        </span>
      ) : (
        order?.status
      )}
    </div>
  );
};

export interface IEditOrderStatusProps {
  order: IOrder;
}

export default EditOrderStatus;
