import React from 'react';
import {Table, TableBody, TableCell, TableRow} from '@material-ui/core';
import {useTranslation} from 'react-i18next';

export enum OpenInvoiceStatus {
  NOT_DUE = 'NOT_DUE',
  DUE = 'DUE',
  REMINDED = 'REMINDED',
  FOR_WARNING = 'FOR_WARNING',
  CRITICAL = 'CRITICAL',
}

export interface StatusReport {
  invoices: any;
  total: number;
  status: OpenInvoiceStatus;
}

const OpenInvoiceActions: React.FC<any> = (props: any) => {
  const {row} = props;
  const {t} = useTranslation();

  const getRowByStatus = (status: string): StatusReport => {
    return row.invoices.find((report: StatusReport) => report.status === status);
  };

  const getRowInvoices = (status: any): string => {
    return getRowByStatus(status)
      .invoices.map((invoice: any) => invoice.invoiceNumber)
      .join(', ');
  };

  const getRowTotalAmount = (status: any): string => {
    return getRowByStatus(status).total.toFixed(2).toString();
  };

  return (
    <div style={{maxWidth: '800px'}}>
      <Table>
        <TableBody>
          {Object.keys(OpenInvoiceStatus).map((key) => (
            <TableRow key={key}>
              <TableCell style={{width: '15%'}}>{t(`invoiceStatus.${key}`)}</TableCell>
              <TableCell style={{width: '10%'}}>{getRowTotalAmount(key)}</TableCell>
              <TableCell>{getRowInvoices(key)}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
};

export default OpenInvoiceActions;
