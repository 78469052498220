import {Typography} from '@material-ui/core';
import React, {useEffect, useState} from 'react';
import Form from '../Form/Form';
import _ from 'lodash';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {USER_ROLE} from '../../types/User/UserRole';

export interface IEditUserProps {
  row?: {email: string; username: string; _id: string};
  title: string;
  onSubmit: (values: any) => void;
  onCancel: () => void;
}

const generateInitialValues = (fields: any[], dataObject: any) => {
  if (dataObject) {
    return dataObject;
  } else {
    let defaults = {};
    fields.forEach((field) => {
      defaults = {...defaults, [field.fieldKey]: ''};
    });
    return defaults;
  }
};

const EditUser = (props: IEditUserProps) => {
  const {title, onSubmit, onCancel, row: user} = props;
  const [initialValues, setInitialValues] = useState(null);
  const dispatch = useDispatch();
  const {t} = useTranslation();

  useEffect(() => {
    const data = generateInitialValues(fields, user);
    setInitialValues(data);
  }, [user]);

  const fields = [
    {
      label: t('users.username'),
      placeholder: t('users.username'),
      fieldKey: 'username',
      type: 'input',
    },
    {
      label: t('users.email'),
      placeholder: t('users.email'),
      fieldKey: 'email',
      fieldKeyType: 'email',
      type: 'input',
    },
    {
      label: t('users.userRoles'),
      placeholder: t('users.userRoles'),
      fieldKey: 'roles',
      type: 'multipleselection',
      options: Object.values(USER_ROLE).map((item) => {
        return {value: item, label: item};
      }),
    },
    {
      label: t('users.password'),
      placeholder: t('users.password'),
      fieldKey: 'password',
      fieldKeyType: 'password',
      type: 'input',
    },
  ];

  return (
    <>
      <Typography className="modal-title">{title}</Typography>
      {initialValues ? (
        <Form
          initialValues={initialValues}
          fields={fields}
          submitButtonText={t('general.submit')}
          cancelButtonText={t('general.cancel')}
          onSubmit={onSubmit}
          onCancel={onCancel}
        ></Form>
      ) : null}
    </>
  );
};

export default EditUser;
