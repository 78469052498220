import React, {useEffect, useState} from 'react';
import {appActions} from '../../modules/app/actions';
import {useStorlessFetch} from '../../hooks/fetch';
import {useDispatch} from 'react-redux';
import {Button, Grid, TextField} from '@material-ui/core';
import {useTranslation} from 'react-i18next';

const ChangePassword = () => {
  const [passwordState, setPasswordState] = useState({oldPassword: '', newPassword: '', confirmPassword: ''});
  const [changePasswordResponse, changePasswordRequest] = useStorlessFetch('change_password');
  const [is2FaConfiguredRes, is2FaConfigured] = useStorlessFetch('auth_2fa_code_generated');
  const [generate2faCodeRes, generate2faCode] = useStorlessFetch('auth_generate_2fa_code');
  const [qrCodeImage, setQrCodeImage] = useState<string>('');
  const dispatch = useDispatch();
  const {t} = useTranslation();

  const handleClick = () => {
    generate2faCode();
  };

  useEffect(() => {
    is2FaConfigured();
    setQrCodeImage('');
  }, []);

  useEffect(() => {
    if (generate2faCodeRes.data) {
      setQrCodeImage(generate2faCodeRes.data);
    }
  }, [generate2faCodeRes]);

  useEffect(() => {
    if (changePasswordResponse.error) {
      dispatch(appActions.showSnackBar({text: changePasswordResponse.error.name, options: {severity: 'error'}}));
    }

    if (changePasswordResponse.loaded && !changePasswordResponse.error) {
      dispatch(appActions.showSnackBar({text: 'Password Changed!', options: {severity: 'success'}}));
      setPasswordState({oldPassword: '', newPassword: '', confirmPassword: ''});
    }
  }, [changePasswordResponse]);

  const onSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    const regex = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;
    e.preventDefault();

    if (!regex.test(passwordState.newPassword)) {
      dispatch(appActions.showSnackBar({text: t('changePassword.passwordStrength'), options: {severity: 'error'}}));
      return;
    }

    if (passwordState.newPassword !== passwordState.confirmPassword) {
      dispatch(appActions.showSnackBar({text: t('changePassword.passwordDoesntMatch'), options: {severity: 'error'}}));
      return;
    }

    changePasswordRequest(passwordState);
  };

  const isChangePasswordDisabled =
    !passwordState.oldPassword.length || !passwordState.newPassword.length || !passwordState.confirmPassword.length;

  return (
    <div className="change-password">
      <Grid container>
        <Grid item xs={6} sm={6}>
          <div className="change-password-container" style={{textAlign: 'right'}}>
            <form onSubmit={onSubmit}>
              <div className="change-password-title">{t('changePassword.changePassword')}</div>
              <TextField
                label={t('changePassword.currentPassword')}
                value={passwordState.oldPassword}
                InputLabelProps={{shrink: true}}
                variant="outlined"
                className="input"
                type="password"
                size="small"
                onChange={(event) => {
                  setPasswordState((prevState) => {
                    return {...prevState, oldPassword: event.target.value};
                  });
                }}
              />
              <TextField
                label={t('changePassword.newPassword')}
                value={passwordState.newPassword}
                InputLabelProps={{shrink: true}}
                variant="outlined"
                className="input"
                type="password"
                size="small"
                onChange={(event) => {
                  setPasswordState((prevState) => {
                    return {...prevState, newPassword: event.target.value};
                  });
                }}
              />
              <TextField
                label={t('changePassword.confirmPassword')}
                value={passwordState.confirmPassword}
                InputLabelProps={{shrink: true}}
                variant="outlined"
                className="input"
                type="password"
                size="small"
                onChange={(event) => {
                  setPasswordState((prevState) => {
                    return {...prevState, confirmPassword: event.target.value};
                  });
                }}
              />

              <Button
                className={isChangePasswordDisabled ? 'inactive-button' : 'blue-button'}
                disabled={isChangePasswordDisabled}
                variant="contained"
                type="submit"
              >
                {t('changePassword.changePassword')}
              </Button>
            </form>
          </div>
        </Grid>
        <Grid item xs={6} sm={6}>
          <div className="change-password-container" style={{textAlign: 'right'}}>
            <div className="change-password-title">{t('changePassword.twoFactorAuthentication')}</div>
            <div className="qr-code-container">
              {!qrCodeImage ? (
                <div className="big-title" style={{textAlign: 'center'}}>
                  {is2FaConfiguredRes?.data || qrCodeImage
                    ? t('changePassword.alreadyConfigured')
                    : t('changePassword.notConfigured')}
                </div>
              ) : (
                <div>
                  <img src={qrCodeImage} />
                  <div> {t('login.scanQrCode')}</div>
                </div>
              )}
            </div>

            <Button variant="contained" className="blue-button" onClick={handleClick}>
              {is2FaConfiguredRes?.data || qrCodeImage ? t('login.reGenerate2Fa') : t('login.generate2Fa')}
            </Button>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};
export default ChangePassword;
