export enum ProductActionType {
  STOCK_INCREASED = 'STOCK_INCREASED',
  STOCK_DECREASED = 'STOCK_DECREASED',
  WRITE_DAILY_STOCK = 'WRITE_DAILY_STOCK',
  STOCK_INCREASED_MANUALLY = 'STOCK_INCREASED_MANUALLY',
  STOCK_DECREASED_MANUALLY = 'STOCK_DECREASED_MANUALLY',
  STOCK_INCREASED_RETURN = 'STOCK_INCREASED_RETURN',
  PRODUCT_ORDERED_FROM_SUPPLIER = 'PRODUCT_ORDERED_FROM_SUPPLIER',
  PRODUCT_UPDATED = 'PRODUCT_UPDATED',
  EBAY_PRODUCT_PUBLISHED = 'EBAY_PRODUCT_PUBLISHED',
  EBAY_PRODUCT_UNPUBLISHED = 'EBAY_PRODUCT_UNPUBLISHED',
}
