import {Button} from '@material-ui/core';
import JsBarcode from 'jsbarcode';
import jsPDF from 'jspdf';
import React from 'react';
import {Util} from '../../../modules/api/utils';
import {IScannedOrderItem} from '../../../types/OrderItems/IScannedOrderItem';

const addProductTitleToBarCodeLabel = (svg: SVGSVGElement, productTitle: string) => {
  const textContainer = document.createElementNS('http://www.w3.org/2000/svg', 'foreignObject');
  const text = document.createElement('div');
  const textContainerHeight = 60;
  text.textContent = productTitle;
  text.style.fontFamily = 'Calibri';
  text.style.fontSize = '15px';
  text.style.textAlign = 'center';
  text.style.lineHeight = '0.8';
  text.style.textAlign = 'center';
  text.style.display = 'flex';
  text.style.alignItems = 'center';
  text.style.justifyContent = 'center';
  text.style.paddingTop = '0px';
  text.style.height = `${textContainerHeight}px`;
  textContainer.appendChild(text);
  svg.appendChild(textContainer);

  const svgHeight = +(svg.getAttributeNS(null, 'height') as any)?.replace('px', '');
  const svgWidth = +(svg.getAttributeNS(null, 'width') as any)?.replace('px', '');
  textContainer.setAttributeNS(null, 'y', (svgHeight - textContainerHeight).toString());
  textContainer.setAttributeNS(null, 'x', '0');
  textContainer.setAttributeNS(null, 'height', `${textContainerHeight}`);
  textContainer.setAttributeNS(null, 'width', svgWidth.toString());
};

const generateBarCodeFromSvg = async (scannedProduct: any) => {
  const svg = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
  const barCodeLabelSize = {width: 51, height: 19}; // mm
  const doc = new jsPDF('l', 'mm', [barCodeLabelSize.width, barCodeLabelSize.height]);

  JsBarcode(svg, scannedProduct.ean, {marginBottom: 60, fontSize: 14, textMargin: 0, font: 'Calibri'});
  addProductTitleToBarCodeLabel(svg, scannedProduct.productTitle || '');

  const svgString = new XMLSerializer().serializeToString(svg);
  const image = await Util.svgString2Image(svgString, 842, 595, 'webp');

  if (image) {
    doc.addImage(image, 'JPEG', 0, 0, barCodeLabelSize.width, barCodeLabelSize.height);
    const blob = new Blob([doc.output('blob')], {type: 'application/pdf'});
    const blobUrl = URL.createObjectURL(blob);
    window.open(blobUrl)?.print();
  }
};

const BarcodeButton = (props: any) => {
  const {scannedProduct} = props;
  return (
    <Button
      variant="contained"
      className="blue-button"
      disabled={!scannedProduct.ean}
      onClick={() => generateBarCodeFromSvg(scannedProduct)}
      endIcon={<img src="/images/bar-code.webp" />}
    >
      Barcode
    </Button>
  );
};

export interface IBarcodeButtonProps {
  scannedProduct: IScannedOrderItem;
}

export default BarcodeButton;
