import {Table, TableHead, TableRow, TableCell, TableBody, TextField, IconButton, Grid, Button} from '@material-ui/core';
import {Clear, Info, NotInterested} from '@material-ui/icons';
import _ from 'lodash';
import React, {ReactElement, useContext, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {useFetch} from '../../hooks/fetch';
import eventBus from '../../lib/eventBus';
import {appActions} from '../../modules/app/actions';
import {modalActions} from '../../modules/modal/actions';
import CreateSupplierOrderContext from '../../screens/SupplierOrders/Context/CreateSupplierOrderContext';
import {ISupplierOrderItem, ISupplierOrderType} from '../../types/SupplierOrders/ISupplierOrderItem';
import {SupplierOrderItemStatus} from '../../types/SupplierOrders/SupplierOrderItemStatus';
import {SupplierOrderStatus} from '../../types/SupplierOrders/SupplierOrderStatus';
import {MODALS} from '../Modal/ModalContents';
import {ProductPicker, ProductPickType} from '../Order/CreatingOrders/ProductPicker';
import SupplierItemInfoDrawer from '../SupplierOrderItems/SupplierItemInfoDrawer';

const SupplierOrderItemsEditTable = (): ReactElement => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const [orderItemsResponse, getOrderItems] = useFetch<any>('supplier_order_items_by_order_id');
  const [updateOrderItemsResponse, updateOrderItems] = useFetch<any>('supplier_order_items_update_list');
  const [supplierOrderItemDelete, doSupplierOrderItemDelete] = useFetch<any>('supplier_order_items_delete');
  const {supplierOrderItem, state, setState, order, orderId} = useContext(CreateSupplierOrderContext);

  useEffect(() => {
    if (state.edit) {
      getOrderItems({id: orderId});
    }
  }, [state.edit, supplierOrderItem, supplierOrderItemDelete]);

  useEffect(() => {
    if (updateOrderItemsResponse?.data) {
      dispatch(appActions.showSnackBar({text: t('general.submitFormText'), options: {severity: 'success'}}));
      setState((prevState: any) => ({
        ...prevState,
        initialSupplierOrderItems: prevState.supplierOrderItems.filter(() => true),
        supplierOrderItemsToUpdate: [],
        isEdited: false,
      }));
    } else if (updateOrderItemsResponse?.error) {
      dispatch(appActions.showSnackBar({text: updateOrderItemsResponse.error.name, options: {severity: 'error'}}));
    }

    return () => {
      updateOrderItemsResponse.data = null;
      updateOrderItemsResponse.error = null;
    };
  }, [updateOrderItemsResponse]);

  useEffect(() => {
    if (supplierOrderItemDelete?.data) {
      setState((prevState: any) => {
        return {...prevState, toggleRefreshTable: !prevState.toggleRefreshTable, refreshOrderProblemTable: true};
      });
      dispatch(appActions.showSnackBar({text: t('general.submitFormText'), options: {severity: 'success'}}));
    } else if (supplierOrderItemDelete?.error) {
      dispatch(appActions.showSnackBar({text: supplierOrderItemDelete.error.name, options: {severity: 'error'}}));
    }

    return () => {
      supplierOrderItemDelete.data = null;
      supplierOrderItemDelete.error = null;
    };
  }, [supplierOrderItemDelete]);

  useEffect(() => {
    if (supplierOrderItem.data) {
      setState((prevState: any) => {
        return {...prevState, toggleRefreshTable: !prevState.toggleRefreshTable};
      });
    }
  }, [supplierOrderItem, supplierOrderItemDelete]);

  useEffect(() => {
    if (orderItemsResponse?.data) {
      setState((prevState: any) => ({
        ...prevState,
        initialSupplierOrderItems: orderItemsResponse.data.filter(() => true),
        supplierOrderItems: orderItemsResponse.data,
      }));
    }
  }, [orderItemsResponse]);

  const showDetailsForOrderItem = (supplierOrderItem: ISupplierOrderItem) => {
    eventBus.dispatch('openSupplierItemInfoDrawer', {supplierOrderItem: supplierOrderItem});
  };

  const handleSaveButton = (event: any) => {
    event.preventDefault();
    updateOrderItems({supplierOrderItems: state.supplierOrderItemsToUpdate});
  };

  const setStateForUpdatedOrderItem = (updatingIndex: number, orderItemIndex: number) => {
    state.supplierOrderItemsToUpdate[updatingIndex] = state.supplierOrderItems[orderItemIndex];

    setState((prevState: any) => ({
      ...prevState,
      supplierOrderItems: [...state.supplierOrderItems],
      supplierOrderItemsToUpdate:
        updatingIndex > -1
          ? [...state.supplierOrderItemsToUpdate]
          : [...state.supplierOrderItemsToUpdate, state.supplierOrderItems[orderItemIndex]],
      isEdited: true,
    }));
  };

  const setStateForOrderItemWithRevertedChanges = (updatingIndex: number) => {
    if (updatingIndex > -1) {
      state.supplierOrderItemsToUpdate.splice(updatingIndex, 1);
    }

    setState((prevState: any) => ({
      ...prevState,
      isEdited: state.supplierOrderItemsToUpdate.length > 0,
      supplierOrderItems: [...state.supplierOrderItems],
      supplierOrderItemsToUpdate: [...state.supplierOrderItemsToUpdate],
    }));
  };

  const setNewState = (orderItemIndex: number, orderItemId: string) => {
    const initialStateOfOrderItem = state.initialSupplierOrderItems.find((item) => item._id === orderItemId);
    const updatingIndex = state.supplierOrderItemsToUpdate.findIndex((item) => item._id === orderItemId);

    if (!_.isEqual(state.supplierOrderItems[orderItemIndex], initialStateOfOrderItem)) {
      setStateForUpdatedOrderItem(updatingIndex, orderItemIndex);
    } else {
      setStateForOrderItemWithRevertedChanges(updatingIndex);
    }
  };

  const updateField = (id: string, field: string, value: any) => {
    const orderItemIndex = state.supplierOrderItems.findIndex((item) => item._id === id);
    state.supplierOrderItems[orderItemIndex] = {
      ...state.supplierOrderItems[orderItemIndex],
      [`${field}`]: value,
    };

    setNewState(orderItemIndex, id);
  };

  const handleCancelModal = () => {
    dispatch(modalActions.closeModal());
  };

  const deleteButtonHandler = async (id: string) => {
    doSupplierOrderItemDelete({id});
    handleCancelModal();
  };

  const handleDeleteItem = async (event: React.MouseEvent<HTMLButtonElement>, id: string) => {
    event.stopPropagation();
    event.preventDefault();

    dispatch(
      modalActions.addModal(MODALS.CONFIRM_DIALOG, {
        title: t('general.deleteModalTitle') || '',
        content: t('general.deleteConfirmation'),
        onYes: () => deleteButtonHandler(id),
        onNo: handleCancelModal,
      }),
    );
  };

  return (
    <>
      {order.status === SupplierOrderStatus.ordered && (
        <ProductPicker productPickType={ProductPickType.SUPPLIER_ORDER} />
      )}

      <Table>
        <TableHead>
          <TableRow>
            <TableCell className="create-orders-cell-padding">Position</TableCell>
            <TableCell className="create-orders-cell-padding">{t('supplierOrderItems.status')}</TableCell>
            <TableCell className="create-orders-cell-padding">{t('supplierOrderItems.product')}</TableCell>
            <TableCell className="create-orders-cell-padding">{t('supplierOrderItems.externalArticleId')}</TableCell>
            <TableCell className="create-orders-cell-padding" style={{minWidth: '120px'}}>
              {t('supplierOrderItems.note')}
            </TableCell>
            <TableCell className="create-orders-cell-padding" style={{width: '80px'}}>
              {t('supplierOrderItems.quantity')}
            </TableCell>
            <TableCell className="create-orders-cell-padding" style={{width: '80px'}}>
              {t('supplierOrderItems.quantityDelivered')}
            </TableCell>
            <TableCell className="create-orders-cell-padding" style={{width: '100px'}}>
              {t('supplierOrderItems.price')}
            </TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {state.supplierOrderItems
            ?.sort((a, b) => (a.createdAt > b.createdAt ? 1 : -1))
            ?.map((orderItem: ISupplierOrderItem, index: number) => {
              const readOnly =
                ![ISupplierOrderType.SERVICE, ISupplierOrderType.CREDIT_NOTE].includes(orderItem.type) &&
                orderItem.status !== SupplierOrderItemStatus.ordered;

              return (
                <TableRow key={orderItem._id}>
                  <TableCell className="create-orders-cell-padding">{index + 1}.</TableCell>
                  <TableCell className="create-orders-cell-padding">{orderItem.status}</TableCell>
                  <TableCell className="create-orders-cell-padding">{orderItem.productTitle}</TableCell>
                  <TableCell className="create-orders-cell-padding">{orderItem.externalArticleId}</TableCell>
                  <TableCell className="create-orders-cell-padding">
                    <TextField
                      type="text"
                      value={orderItem.note}
                      onChange={(e) => updateField(orderItem._id, 'note', e.target.value)}
                    />
                  </TableCell>
                  <TableCell className="create-orders-cell-padding">
                    <TextField
                      type="number"
                      value={orderItem.quantity}
                      InputProps={{inputProps: {min: 1, readOnly: readOnly, disableUnderline: readOnly}}}
                      onChange={(e) => updateField(orderItem._id, 'quantity', +e.target.value)}
                    />
                  </TableCell>
                  <TableCell className="create-orders-cell-padding" style={{fontSize: 16}}>
                    {orderItem.quantityDelivered}
                  </TableCell>
                  <TableCell className="create-orders-cell-padding">
                    <TextField
                      type="number"
                      value={orderItem.price}
                      InputProps={{inputProps: {min: 0, step: 0.01, readOnly: readOnly, disableUnderline: readOnly}}}
                      onChange={(e) => updateField(orderItem._id, 'price', +e.target.value)}
                    />
                  </TableCell>
                  <TableCell className="create-orders-cell-padding" style={{display: 'inline-flex'}}>
                    <IconButton
                      disabled={orderItem.status !== SupplierOrderItemStatus.ordered}
                      onClick={(e) => handleDeleteItem(e, orderItem._id)}
                    >
                      <Clear
                        fontSize="small"
                        style={{fill: orderItem.status === SupplierOrderItemStatus.ordered ? 'red' : 'grey'}}
                      ></Clear>
                    </IconButton>
                    <IconButton style={{padding: '5px'}} onClick={() => showDetailsForOrderItem(orderItem)}>
                      <Info className="blue-700" />
                    </IconButton>
                    <IconButton
                      style={{padding: '5px', color: orderItem.ignoreForSuggestions ? 'red' : 'grey'}}
                      onClick={() =>
                        updateField(orderItem._id, 'ignoreForSuggestions', !orderItem.ignoreForSuggestions)
                      }
                    >
                      <NotInterested />
                    </IconButton>
                  </TableCell>
                </TableRow>
              );
            })}
        </TableBody>
      </Table>
      {state.edit && state.isEdited && (
        <Grid container spacing={2}>
          <Grid item xs={8}></Grid>
          <Grid item xs={4} style={{textAlign: 'right', marginTop: '10px'}}>
            <Button type="submit" variant="contained" className="blue-button" onClick={handleSaveButton}>
              {t('general.save')}
            </Button>
            &nbsp;&nbsp;
          </Grid>
        </Grid>
      )}
      {state.edit && <SupplierItemInfoDrawer />}
    </>
  );
};

export default SupplierOrderItemsEditTable;
