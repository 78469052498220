import React, {useContext, useState} from 'react';
import {Accordion, AccordionDetails, AccordionSummary, Grid, IconButton, Typography} from '@material-ui/core';
import {ExpandMore, ExpandLess} from '@material-ui/icons';
import {useTranslation} from 'react-i18next';
import OrderDetailsContext from '../../screens/Orders/Context/OrderDetailsContext';
import EasyBillInfo from './EasyBillInfo';
import DeliveryBillButton from './OrderActions/DeliveryBillButton';
import ShippingLabelButton from './OrderActions/ShippingLabelButton';
import {APP_ROUTES} from '../../routers/routes';
import {Link} from 'react-router-dom';

const OrderSideInfo = () => {
  const [expanded, setExpanded] = useState([true, true, true, true, true, true]);
  const {order} = useContext(OrderDetailsContext);
  const {customer, shippingAddress, billingAddress, shopwareCustomFields, paymentInfo} = order;
  const {t} = useTranslation();
  const handleChange = (index: number) => {
    setExpanded((prevExpanded) => {
      const newExpanded = [...prevExpanded];
      newExpanded[index] = !newExpanded[index];
      return newExpanded;
    });
  };

  const expandIcon = (shouldExpand: any) => {
    return shouldExpand ? (
      <IconButton className="accordion-icon">
        <ExpandLess />
      </IconButton>
    ) : (
      <IconButton className="accordion-icon">
        <ExpandMore />
      </IconButton>
    );
  };

  return order ? (
    <div className="accordion-container">
      <Accordion elevation={0} expanded={expanded[0]} onChange={() => handleChange(0)}>
        <AccordionSummary className="accordion-summary">
          <Typography className="accordion-title">{t('customerDetails.customerInfo')}</Typography>
          {expandIcon(expanded[0])}
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={0} alignItems="flex-start">
            <Grid container>
              <Grid item xs={12} sm={6}>
                <div className="order-side-image">
                  {customer?.logo ? <img height={100} width={155} src={`${customer?.logo}`} /> : null}
                </div>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Grid container direction="column">
                  <Grid item>
                    <div className="order-side-label">{t('customerDetails.customerName')} </div>
                    <Link className="link" to={`${APP_ROUTES.CUSTOMERS}/${customer?.id}`}>
                      <div className="order-side-customer-name">{customer?.customerName || '-'}</div>
                    </Link>
                  </Grid>
                  <Grid item>
                    <div className="order-side-label">{t('customerDetails.customerNumber')} </div>
                    <div className="order-side-customer-name">{customer?.customerNumber || '-'}</div>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} sm={6} className="order-info-item">
              <div className="order-side-label">{t('customerDetails.phone')} </div>
              <div className="order-side-text">{customer?.phone || '-'}</div>
            </Grid>
            <Grid item xs={12} sm={6} className="order-info-item">
              <div className="order-side-label">{t('customerDetails.email')} </div>
              <div className="order-side-text">{customer?.email || '-'}</div>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>

      <Accordion elevation={0} expanded={expanded[1]} onChange={() => handleChange(1)}>
        <AccordionSummary className="accordion-summary">
          <Typography className="accordion-title">{t('orderDetails.shippingAddress')}</Typography>
          {expandIcon(expanded[1])}
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={0} alignItems="flex-start">
            <Grid item xs={12} sm={12} className="order-info-item">
              <div className="order-side-label">{t('shippingAddress.customerName')} </div>
              <div className="order-side-text">{shippingAddress?.customerName || '-'}</div>
            </Grid>
            <Grid item xs={12} sm={12} className="order-info-item">
              <div className="order-side-label">{t('shippingAddress.addressLine2')} </div>
              <div className="order-side-text">{shippingAddress?.addressLine2 || '-'}</div>
            </Grid>
            <Grid item xs={12} sm={12} className="order-info-item">
              <div className="order-side-label">{t('shippingAddress.deliveryInstruction')} </div>
              <div className="order-side-text">{shippingAddress?.deliveryInstruction || '-'}</div>
            </Grid>
            <Grid item xs={12} sm={12} className="order-info-item">
              <div className="order-side-label">{t('shippingAddress.addressLine1')} </div>
              <div className="order-side-text">{shippingAddress?.addressLine1 || '-'}</div>
            </Grid>
            <Grid item xs={12} sm={6} className="order-info-item">
              <div className="order-side-label">{t('shippingAddress.zipCode')} </div>
              <div className="order-side-text">{shippingAddress?.zip || '-'}</div>
            </Grid>
            <Grid item xs={12} sm={6} className="order-info-item">
              <div className="order-side-label">{t('shippingAddress.city')} </div>
              <div className="order-side-text">{shippingAddress?.city || '-'}</div>
            </Grid>
            <Grid item xs={12} sm={6} className="order-info-item">
              <div className="order-side-label">{t('shippingAddress.countryCode')} </div>
              <div className="order-side-text">{shippingAddress?.countryCode || '-'}</div>
            </Grid>
            <Grid item xs={12} sm={6} className="order-info-item">
              <div className="order-side-label">{t('shippingAddress.stateCode')} </div>
              <div className="order-side-text">{shippingAddress?.stateCode || '-'}</div>
            </Grid>
            <Grid item xs={12} sm={6} className="order-info-item">
              <div className="order-side-label">{t('shippingAddress.phone')} </div>
              <div className="order-side-text">{shippingAddress?.phone || '-'}</div>
            </Grid>
            <Grid item xs={12} sm={6} className="order-info-item">
              <div className="order-side-label">{t('shippingAddress.email')} </div>
              <div className="order-side-text">{shippingAddress?.email || '-'}</div>
            </Grid>
            <Grid item xs={12} sm={6} className="order-info-item">
              <div className="order-side-label">{t('shippingAddress.postNumber')} </div>
              <div className="order-side-text">{shippingAddress?.postNumber || '-'}</div>
            </Grid>
            <Grid item xs={12} sm={6} className="order-info-item">
              <div className="order-side-label">{t('shippingAddress.lockerId')} </div>
              <div className="order-side-text">{shippingAddress?.lockerId || '-'}</div>
            </Grid>
            <Grid item xs={12} sm={6} className="order-info-item">
              <div className="order-side-label">{t('shippingAddress.retailId')} </div>
              <div className="order-side-text">{shippingAddress?.retailId || '-'}</div>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>

      <Accordion elevation={0} expanded={expanded[2]} onChange={() => handleChange(2)}>
        <AccordionSummary className="accordion-summary">
          <Typography className="accordion-title">{t('orderDetails.billingAddress')}</Typography>
          {expandIcon(expanded[2])}
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={0} alignItems="flex-start">
            <Grid item xs={12} sm={6} className="order-info-item">
              <div className="order-side-label">{t('shippingAddress.customerName')} </div>
              <div className="order-side-text">{billingAddress?.customerName || '-'}</div>
            </Grid>
            <Grid item xs={12} sm={6} className="order-info-item">
              <div className="order-side-label">{t('shippingAddress.zipCode')} </div>
              <div className="order-side-text">{billingAddress?.zip || '-'}</div>
            </Grid>
            <Grid item xs={12} sm={6} className="order-info-item">
              <div className="order-side-label">{t('shippingAddress.addressLine1')} </div>
              <div className="order-side-text">{billingAddress?.addressLine1 || '-'}</div>
            </Grid>
            <Grid item xs={12} sm={6} className="order-info-item">
              <div className="order-side-label">{t('shippingAddress.city')} </div>
              <div className="order-side-text">{billingAddress?.city || '-'}</div>
            </Grid>
            <Grid item xs={12} sm={6} className="order-info-item">
              <div className="order-side-label">{t('shippingAddress.addressLine2')} </div>
              <div className="order-side-text">{billingAddress?.addressLine2 || '-'}</div>
            </Grid>
            <Grid item xs={12} sm={6} className="order-info-item">
              <div className="order-side-label">{t('shippingAddress.countryCode')} </div>
              <div className="order-side-text">{billingAddress?.countryCode || '-'}</div>
            </Grid>
            <Grid item xs={12} sm={6} className="order-info-item">
              <div className="order-side-label">{t('shippingAddress.stateCode')} </div>
              <div className="order-side-text">{billingAddress?.stateCode || '-'}</div>
            </Grid>
            <Grid item xs={12} sm={6} className="order-info-item">
              <div className="order-side-label">{t('shippingAddress.phone')} </div>
              <div className="order-side-text">{billingAddress?.phone || '-'}</div>
            </Grid>
            <Grid item xs={12} sm={6} className="order-info-item">
              <div className="order-side-label">{t('shippingAddress.email')} </div>
              <div className="order-side-text">{billingAddress?.email || '-'}</div>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>

      {shopwareCustomFields && (
        <Accordion elevation={0} expanded={expanded[3]} onChange={() => handleChange(3)}>
          <AccordionSummary className="accordion-summary">
            <Typography className="accordion-title">{t('orderDetails.shopwareCustomFields')}</Typography>
            {expandIcon(expanded[3])}
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={0} alignItems="flex-start">
              <Grid item xs={12} sm={6} className="order-info-item">
                <div className="order-side-label">{t('orderDetails.destinationUrl')} </div>
                <div className="order-side-text">{shopwareCustomFields?.destinationUrl || '-'}</div>
              </Grid>

              <Grid item xs={12} sm={6} className="order-info-item">
                <div className="order-side-label">{t('orderDetails.googleCloudId')} </div>
                <div className="order-side-text">{shopwareCustomFields?.googleCloudId || '-'}</div>
              </Grid>

              <Grid item xs={12} sm={6} className="order-info-item">
                <div className="order-side-label">{t('orderDetails.internalOrderNumber')} </div>
                <div className="order-side-text">{shopwareCustomFields?.internalOrderNumber || '-'}</div>
              </Grid>

              <Grid item xs={12} sm={6} className="order-info-item">
                <div className="order-side-label">{t('orderDetails.paymentReference')} </div>
                <div className="order-side-text">{paymentInfo?.paymentReference || '-'}</div>
              </Grid>

              <Grid item xs={12} sm={6} className="order-info-item">
                <div className="order-side-label">{t('orderDetails.referrer')} </div>
                <div className="order-side-text">{shopwareCustomFields?.referrer || '-'}</div>
              </Grid>

              <Grid item xs={12} sm={6} className="order-info-item">
                <div className="order-side-label">{t('orderDetails.deliveryNote')} </div>
                <div className="order-side-text">{shopwareCustomFields?.deliveryNote || '-'}</div>
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      )}

      {order?.easyBillInfo?.customerCreated !== undefined && (
        <Accordion elevation={0} expanded={expanded[4]} onChange={() => handleChange(4)}>
          <AccordionSummary className="accordion-summary">
            <Typography className="accordion-title">{t('orderDetails.easyBillInfo')}</Typography>
            {expandIcon(expanded[4])}
          </AccordionSummary>
          <AccordionDetails>
            <EasyBillInfo></EasyBillInfo>
          </AccordionDetails>
        </Accordion>
      )}

      {order.parcels?.length ? (
        <Accordion elevation={0} expanded={expanded[5]} onChange={() => handleChange(5)}>
          <AccordionSummary className="accordion-summary">
            <Typography className="accordion-title">{t('orderDetails.parcels')}</Typography>
            {expandIcon(expanded[5])}
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={0} alignItems="flex-start">
              {order.parcels?.map((parcel, index) => {
                return (
                  <Grid key={parcel.id} item xs={12} sm={12}>
                    <div className="order-side-label">
                      {parcel.trackingNumber}
                      <DeliveryBillButton parcelIndex={index} />
                      <ShippingLabelButton parcelIndex={index} />
                    </div>
                  </Grid>
                );
              })}
            </Grid>
          </AccordionDetails>
        </Accordion>
      ) : null}
    </div>
  ) : (
    <></>
  );
};

export default OrderSideInfo;
