import {Grid, TextField} from '@material-ui/core';
import React, {ReactElement, useContext} from 'react';
import {useTranslation} from 'react-i18next';
import OrderScanningContext from '../../../screens/Orders/Context/OrderScanningContext';

const ProductQuantityScannedComponent = (): ReactElement => {
  const {product, orderItems, setOrderItems} = useContext(OrderScanningContext);
  const {t} = useTranslation();

  return product ? (
    <>
      <Grid item xs={12} sm={6}>
        <span>
          {t('scanner.quantityOrdered')}: {product.quantity || 0}
        </span>
      </Grid>
      <Grid item xs={12} sm={6}>
        <span>
          {' '}
          {t('scanner.quantityScanned') + ': '}
          {product.quantity <= 20 ? (
            product.quantityScanned
          ) : (
            <TextField
              type="number"
              variant="outlined"
              value={product.quantityScanned}
              InputProps={{
                inputProps: {
                  min: 0,
                  max: product.quantity,
                },
              }}
              onChange={(e) => {
                const quantity = +e.target.value;
                if (quantity >= 0 && quantity <= product.quantity) {
                  product.quantityScanned = quantity;
                  setOrderItems([...orderItems]);
                }
              }}
            ></TextField>
          )}
        </span>
      </Grid>
    </>
  ) : (
    <></>
  );
};

export default ProductQuantityScannedComponent;
