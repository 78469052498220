import React, {ComponentType, ReactElement} from 'react';
import {useTranslation} from 'react-i18next';
import useUser from '../hooks/user';
import {USER_ROLE} from '../types/User/UserRole';

interface IProps {
  userRole?: USER_ROLE;
}

const withRoleGuard = <P extends object>(
  allowedRoles: string[],
  WrappedComponent: ComponentType<P> | any,
  showText = true,
) => {
  const {t} = useTranslation();

  const WithRoleGuard: any = (props: IProps) => {
    const user: any = useUser();
    const userRoles = user?.roles || [];
    const hasPermission = allowedRoles.some((item) => userRoles.includes(item));

    if (!hasPermission && !showText) {
      return;
    }

    if (!hasPermission) {
      return (
        <div style={{marginLeft: 10}} className="big-title">
          {t('general.noAccess')}
        </div>
      );
    }

    return <WrappedComponent {...(props as P)} />;
  };

  return WithRoleGuard;
};

export default withRoleGuard;
