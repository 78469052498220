import {Button, FormControl, FormControlLabel, Grid, InputLabel, MenuItem, Select, Switch} from '@material-ui/core';
import React, {ReactElement, useContext, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {useFetch} from '../../hooks/fetch';
import {appActions} from '../../modules/app/actions';
import {modalActions} from '../../modules/modal/actions';
import {MODALS} from '../Modal/ModalContents';
import SupplierOrderItemsContext from './Context/SupplierOrderItemsContext';
import {ISupplier} from '../../types/Supplier/ISupplier';

const PlaceSupplierOrder = (props: IPlaceSupplierOrderProps): ReactElement => {
  const {pendingSupplierOrderItems, setOptions, selectedSupplier, setSelectedSupplier} = props;
  const [supplierOrder, postSupplierOrder] = useFetch<any>('supplier_order_post');
  const [supplierResponse, getSupplier] = useFetch<any>('get_supplier_by_id');
  const [supplier, setSupplier] = useState<ISupplier>(null as any);
  const [placeOrderStatus, setPlaceOrderStatus] = useState(false);
  const {getTotalPurchasePrice} = useContext(SupplierOrderItemsContext);
  const dispatch = useDispatch();
  const handleCancelModal = () => dispatch(modalActions.closeModal());
  const {t} = useTranslation();

  const handleButtonClick = async () => {
    if (supplier?.usingEdi) {
      dispatch(
        modalActions.addModal(MODALS.CONFIRM_DIALOG, {
          title: `${t('general.confirm')}?`,
          content: t('general.areYouSure'),
          onYes: () => handleSupplierOrderConfirm({supplierId: selectedSupplier}),
          onNo: handleCancelModal,
        }),
      );
    } else {
      dispatch(
        modalActions.addModal(MODALS.SEND_EMAIL_COMPONENT, {
          onSubmit: handleSupplierOrderConfirm,
          onCancel: handleCancelModal,
          createOrder: true,
          title: t('supplierOrderDetails.sendEmailButton'),
        }),
      );
    }
  };

  const handleSupplierOrderConfirm = (data: any) => {
    postSupplierOrder({...data, supplierId: selectedSupplier});
    getTotalPurchasePrice();
    handleCancelModal();
  };

  const handlePlaceOrderStatusChange = (event: any) => {
    setPlaceOrderStatus(event.target.checked);
    if (!event.target.checked) {
      setSelectedSupplier(null);
    }
  };

  useEffect(() => {
    if (supplierOrder?.error) {
      dispatch(appActions.showSnackBar({text: supplierOrder.error.name, options: {severity: 'error'}}));
    } else if (supplierOrder?.data) {
      dispatch(appActions.showSnackBar({text: t('supplierOrderItems.orderCreated'), options: {severity: 'success'}}));
      setPlaceOrderStatus(false);
      setSelectedSupplier(null);
    }

    return () => {
      supplierOrder.data = null;
      supplierOrder.error = null;
      supplierOrder.loaded = null;
    };
  }, [supplierOrder]);

  useEffect(() => {
    const options = selectedSupplier
      ? {supplier: selectedSupplier, status: 'ON_HOLD'}
      : {status: 'ON_HOLD', supplier: {$ne: null}};
    setOptions(options);
    if (selectedSupplier) {
      getSupplier({id: selectedSupplier});
    }
  }, [selectedSupplier]);

  useEffect(() => {
    if (supplierResponse?.data) {
      setSupplier(supplierResponse.data);
    }
  }, [supplierResponse]);

  return pendingSupplierOrderItems?.data?.length > 0 ? (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={3}>
          <FormControlLabel
            control={
              <Switch
                checked={placeOrderStatus}
                onChange={handlePlaceOrderStatusChange}
                name="placeOrderStatusSwitch"
                color="primary"
              />
            }
            label={t('supplierOrderItems.placeOrder')}
          />
        </Grid>
        {placeOrderStatus ? (
          <>
            <Grid item xs={12} sm={6}>
              <FormControl variant="outlined">
                <InputLabel id="selectSupplierLabel">{t('supplierOrderItems.supplier')}</InputLabel>
                <Select
                  id="selectSupplier"
                  onChange={(e: any) => {
                    setSelectedSupplier(e.target.value);
                  }}
                >
                  {pendingSupplierOrderItems?.data?.map((item: any) => {
                    {
                      return item ? (
                        <MenuItem key={item._id} value={item._id}>
                          {item.name}{' '}
                        </MenuItem>
                      ) : null;
                    }
                  })}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={3}>
              <Button
                variant="contained"
                className="blue-button"
                onClick={handleButtonClick}
                disabled={!selectedSupplier}
              >
                {t('general.confirm')}
              </Button>
            </Grid>
          </>
        ) : null}
      </Grid>
      <br />
    </>
  ) : (
    <></>
  );
};

interface IPlaceSupplierOrderProps {
  pendingSupplierOrderItems: any;
  setOptions: (data: any) => void;
  selectedSupplier: any;
  setSelectedSupplier: (data: any) => void;
}

export default PlaceSupplierOrder;
