import {Button} from '@material-ui/core';
import {Sync} from '@material-ui/icons';
import React, {ReactElement, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {useFetch} from '../../../hooks/fetch';
import {appActions} from '../../../modules/app/actions';
import {modalActions} from '../../../modules/modal/actions';
import {IProduct} from '../../../types/Product/IProduct';
import {MODALS} from '../../Modal/ModalContents';
import {ShopType} from '../../../types/Orders/ShopType';
import {ProductStatus} from '../../../types/Product/ProductStatus.enum';
import {IShopProductProp} from '../../../types/Product/IShopProductProp';

const PublishToEbayButton = (props: IProps): ReactElement => {
  const [publishProductResponse, publishProduct] = useFetch<any>('publish_product_to_ebay');
  const [unpublishProductResponse, unpublishProduct] = useFetch<any>('unpublish_from_ebay');
  const {product, getProductDetails} = props;
  const dispatch = useDispatch();
  const {t} = useTranslation();
  const [shopProp, setShopProps] = useState<IShopProductProp | undefined>(undefined);

  const handleCancelModal = () => dispatch(modalActions.closeModal());
  const handleSubmit = async () => {
    !product.eBayProps?.listingId ? publishProduct({id: product._id}) : unpublishProduct({id: product._id});
    handleCancelModal();
  };

  const handleSyncToShop = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    event.preventDefault();

    dispatch(
      modalActions.addModal(MODALS.ALERT_DIALOG, {
        title: t('general.areYouSure') || '',
        description: !product.eBayProps?.listingId
          ? t('productDetails.eBayPublishOfferMessage')
          : t('productDetails.eBayUnPublishOfferMessage'),
        onSubmit: handleSubmit,
        onClose: handleCancelModal,
        onOpen: true,
      }),
    );
  };

  useEffect(() => {
    if (product?.shopProps?.length) {
      setShopProps(product.shopProps.find((x: any) => x.shopName === ShopType.DRUCKDICHAUS));
    }

    return () => {
      setShopProps(undefined);
    };
  }, []);

  useEffect(() => {
    if (publishProductResponse.error || unpublishProductResponse.error) {
      dispatch(appActions.showSnackBar({text: t('activatedArticles.failedSyncMessage'), options: {severity: 'error'}}));
    }

    if (
      (publishProductResponse.loaded && !publishProductResponse.error) ||
      (unpublishProductResponse.loaded && !unpublishProductResponse.error)
    ) {
      getProductDetails({id: product._id});
      dispatch(appActions.showSnackBar({text: t('general.success'), options: {severity: 'success'}}));
    }

    return () => {
      publishProductResponse.data = null;
      publishProductResponse.loaded = null;
      publishProductResponse.error = null;
      unpublishProductResponse.data = null;
      unpublishProductResponse.loaded = null;
      unpublishProductResponse.error = null;
    };
  }, [publishProductResponse, unpublishProductResponse]);

  return shopProp?.status === ProductStatus.ACTIVE || product.eBayProps?.offerId ? (
    <Button
      startIcon={<Sync fontSize="small"></Sync>}
      variant="contained"
      className="blue-button"
      onClick={handleSyncToShop}
    >
      {!product.eBayProps?.listingId
        ? t('productDetails.publishOnEbayButton')
        : t('productDetails.unPublishOnEbayButton')}
    </Button>
  ) : (
    <></>
  );
};

interface IProps {
  getProductDetails: (data: any) => void;
  product: IProduct;
}

export default PublishToEbayButton;
