import {Typography} from '@material-ui/core';
import React, {useEffect, useState} from 'react';
import * as Yup from 'yup';
import {useTranslation} from 'react-i18next';
import Form from '../Form/Form';

export interface IDeliveryConfirmationModalProps {
  title: string;
  onSubmit: (values: any) => void;
  onCancel: () => void;
}

const generateInitialValues = () => {
  return {
    shippingDate: new Date().toISOString(),
  };
};

const DeliveryConfirmationModal = ({title, onCancel, onSubmit}: IDeliveryConfirmationModalProps) => {
  const [initialValues, setInitialValues] = useState<any>(null);
  const {t} = useTranslation();

  useEffect(() => {
    const data = generateInitialValues();
    setInitialValues(data);
  }, []);

  const schema = Yup.object().shape({
    shippingDate: Yup.date(),
  });

  const fields = [
    {
      label: t('orderDetails.shippedDate'),
      placeholder: t('orderDetails.shippedDate'),
      fieldKey: 'shippingDate',
      type: 'dateTime',
    },
  ];

  return (
    <>
      <Typography className="modal-title">{title}</Typography>
      {initialValues ? (
        <Form
          initialValues={initialValues}
          fields={fields}
          submitButtonText={t('general.submit')}
          cancelButtonText={t('general.cancel')}
          onSubmit={onSubmit}
          onCancel={onCancel}
          validationSchema={schema}
        ></Form>
      ) : null}
    </>
  );
};

export default DeliveryConfirmationModal;
