import React, {ReactElement, useContext, useEffect} from 'react';
import OrderDetailsContext from '../../../screens/Orders/Context/OrderDetailsContext';
import ButtonListContext from '../../ButtonList/ButtonListContext';
import {modalActions} from '../../../modules/modal/actions';
import {useStorlessFetch} from '../../../hooks/fetch';
import {MODALS} from '../../Modal/ModalContents';
import {useTranslation} from 'react-i18next';
import {Button} from '@material-ui/core';
import {useDispatch} from 'react-redux';
import {OrderType} from '../../../types/Orders/OrderType';
import {appActions} from '../../../modules/app/actions';

interface IProps {
  isListItem?: boolean;
}

const FetchOrderFromAmazonButton = ({isListItem}: IProps): ReactElement => {
  const [fetchOrderDataFromAmazonResponse, fetchOrderDataFromAmazon] = useStorlessFetch('fetch_order_from_amazon');
  const {hideButtonList} = useContext(ButtonListContext);
  const {order, getOrderDetails} = useContext(OrderDetailsContext);
  const {t} = useTranslation();
  const dispatch = useDispatch();

  const fetchOrderHandler = () => {
    dispatch(
      modalActions.addModal(MODALS.CONFIRM_DIALOG, {
        title: t('general.areYouSure'),
        content: t('orderDetails.fetchOrderDataFromAmazon'),
        onYes: () => {
          fetchOrderDataFromAmazon({orderNumber: order.orderNumber});
          dispatch(modalActions.closeModal());
        },
        onNo: () => dispatch(modalActions.closeModal()),
      }),
    );
  };

  useEffect(() => {
    if (fetchOrderDataFromAmazonResponse?.data) {
      getOrderDetails({id: order._id});
      dispatch(appActions.showSnackBar({text: t('general.submitFormText'), options: {severity: 'success'}}));
      hideButtonList && hideButtonList();
    }

    if (fetchOrderDataFromAmazonResponse?.error) {
      const errorMessage = fetchOrderDataFromAmazonResponse?.error?.name || 'Error';
      dispatch(appActions.showSnackBar({text: errorMessage, options: {severity: 'error'}}));
      dispatch(modalActions.closeModal());
    }
  }, [fetchOrderDataFromAmazonResponse]);

  return order.orderType === OrderType.Amazon ? (
    <Button
      variant={isListItem ? 'text' : 'contained'}
      className={isListItem ? 'button-list-item' : 'blue-button'}
      onClick={fetchOrderHandler}
    >
      {t('orderDetails.fetchOrderDataFromAmazon')}
    </Button>
  ) : (
    <></>
  );
};

export default FetchOrderFromAmazonButton;
