import {Typography} from '@material-ui/core';
import React, {ReactElement, useState} from 'react';
import Form from '../Form/Form';
import _ from 'lodash';
import {useTranslation} from 'react-i18next';
import {useFetch} from '../../hooks/fetch';
import * as Yup from 'yup';

const AddProductToCustomListModal = (props: IAddProductToCustomListModal): ReactElement => {
  const {onSubmit, onCancel} = props;
  const {t} = useTranslation();

  const fields: any = [
    {
      label: t('supplierOrderDetails.product'),
      placeholder: t('supplierOrderDetails.product'),
      fieldKey: 'product',
      type: 'autocomplete',
      required: true,
      options: [],
      endpoint: 'product_autocomplete_list',
    },
  ];

  const initialValues = {product: ''};

  const validationSchema = Yup.object().shape({
    product: Yup.string().required('Required').nullable(),
  });

  return (
    <>
      <Typography className="modal-title">{t('supplierOrderItems.addButton')}</Typography>
      <Form
        initialValues={initialValues}
        fields={fields}
        submitButtonText={t('general.submit')}
        cancelButtonText={t('general.cancel')}
        onSubmit={onSubmit}
        onCancel={onCancel}
        validationSchema={validationSchema}
      ></Form>
    </>
  );
};

export interface IAddProductToCustomListModal {
  onSubmit: (values: any) => void;
  onCancel: () => void;
}

export default AddProductToCustomListModal;
