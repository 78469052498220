import {ISnackBarData} from '../../components/SnackBar/SnackBarBase';
import {AppAction, APP} from './actions';

interface AppState {
  init: boolean;
  loginState: 0 | 1 | 2;
  globalLoader: boolean;
  snackBar: ISnackBarData;
  numOfSyncSuppliers: number;
  syncData: any[];
}

export const INITIAL_STATE: AppState = {
  init: false,
  loginState: 0,
  globalLoader: false,
  numOfSyncSuppliers: 0,
  syncData: [],
  snackBar: {text: ''},
};

const appReducer = (state = INITIAL_STATE, action: AppAction) => {
  switch (action.type) {
    case APP.INIT:
      return {
        ...state,
        init: true,
      };
    case APP.SET_LOGIN_STATE:
      return {
        ...state,
        loginState: action.payload,
      };
    case APP.DO_GLOBAL_LOADER:
      return {
        ...state,
        globalLoader: action.payload,
      };
    case APP.SHOW_SNACK_BAR:
      return {
        ...state,
        snackBar: action.payload,
      };
    case APP.SYNC_START:
      return {
        ...state,
        syncData: [...action.payload],
      };
    case APP.SYNC_UPDATE: {
      const syncData = state.syncData;
      const supplierIndex = syncData.findIndex((supplier) => supplier.supplierName === action.payload.supplierName);
      syncData[supplierIndex] = {...action.payload, loading: false};
      return {
        ...state,
        syncData,
        numOfSyncSuppliers: state.numOfSyncSuppliers + 1,
      };
    }
    case APP.RESET_SYNC: {
      return {
        ...state,
        syncData: [],
        numOfSyncSuppliers: 0,
      };
    }
    default:
      return state;
  }
};

export {appReducer};
