import {IconButton} from '@material-ui/core';
import {Check, Clear} from '@material-ui/icons';
import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {MODALS} from '../../components/Modal/ModalContents';
import DataTable from '../../components/Table/Table';
import {useFetch, useStorlessUpload} from '../../hooks/fetch';
import {modalActions} from '../../modules/modal/actions';
import {IProduct} from '../../types/Product/IProduct';

const PRODUCT_FORM_TITLE = 'Select a product';
const MODAL_TEXT_REMOVE_FROM_IGNORED = 'Are you sure you want to remove this item from the ignored list?';
const MODAL_TITLE = 'Confirm';

interface IActionsProps {
  doSupplierArticleDelete: (param: any) => void;
  fetchData: () => void;
  id: string;
}

const Actions: React.FC<IActionsProps> = (props: IActionsProps) => {
  const {doSupplierArticleDelete, fetchData, id} = props;
  const dispatch = useDispatch();
  const {t} = useTranslation();
  const handleCancelModal = () => {
    dispatch(modalActions.closeModal());
  };
  const [uploadFile, doUploadFile] = useStorlessUpload('upload_optimized');
  const [imageUrl, setImageUrl] = useState(null as any);
  const [product, setProduct] = useState<IProduct>(null as any);
  const [productPostResponse, doApproveSupplierArticle] = useFetch<any>('supplier_article_approve');

  const handleRemoveFromIgnored = async () => {
    dispatch(
      modalActions.addModal(MODALS.CONFIRM_DIALOG, {
        title: MODAL_TITLE,
        content: MODAL_TEXT_REMOVE_FROM_IGNORED,
        onYes: handleRemove,
        onNo: handleCancelModal,
      }),
    );
  };

  const handleRemove = async () => {
    await doSupplierArticleDelete({id});
    handleCancelModal();
    await fetchData();
  };

  const handleApproveArticle = async (values: any) => {
    const data = {...values, supplierArticle: [id]};
    setProduct(data);

    if (data.productInformation?.image) {
      doUploadFile({file: data.productInformation.image});
    } else {
      setImageUrl('');
    }
  };

  const handleCreateProductModal = () => {
    dispatch(
      modalActions.addModal(MODALS.CREATE_PRODUCT, {
        title: t('addNewArticle.screenTitle') || '',
        onSubmit: handleApproveArticle,
        onCancel: handleCancelModal,
      }),
    );
  };

  const handleApproved = () => {
    dispatch(
      modalActions.addModal(MODALS.SELECT_PRODUCT, {
        title: PRODUCT_FORM_TITLE,
        onSubmit: handleApproveArticle,
        onCancel: handleCancelModal,
        onCreateProduct: handleCreateProductModal,
      }),
    );
  };

  useEffect(() => {
    if (uploadFile.data) {
      setImageUrl(uploadFile.data);
    }

    return () => {
      uploadFile.data = null;
      uploadFile.loaded = null;
      uploadFile.error = null;
    };
  }, [uploadFile]);

  useEffect(() => {
    if (product) {
      doApproveSupplierArticle({
        ...product,
        productInformation: {...product.productInformation, image: imageUrl},
      });
      fetchData();
      handleCancelModal();
    }

    return () => {
      setImageUrl(null as any);
      setProduct(null as any);
    };
  }, [imageUrl]);

  return (
    <div>
      <IconButton onClick={handleApproved} title="Approve">
        <Check fontSize="small" style={{fill: 'green'}}></Check>
      </IconButton>
      <IconButton onClick={handleRemoveFromIgnored}>
        <Clear fontSize="small" style={{fill: 'red'}}></Clear>
      </IconButton>
    </div>
  );
};

const IgnoredSupplierArticle = () => {
  const [deleteResponse, doSupplierArticleDelete] = useFetch('supplier_articles_delete');
  const [productPostResponse, doApproveSupplierArticle] = useFetch('supplier_article_approve');
  const {t} = useTranslation();

  const options = {status: 'ignored'};
  return (
    <div className="supplier-articles">
      <DataTable
        className="supplier-articles__table"
        headers={[
          {
            kind: 'accessor',
            sortable: true,
            name: t('ignoredArticles.supplierName'),
            accessor: 'supplierName',
          },
          {
            kind: 'accessor',
            name: t('ignoredArticles.articleTitle'),
            accessor: 'title',
            sortable: true,
          },
          {
            kind: 'accessor',
            name: t('ignoredArticles.externalArticleId'),
            accessor: 'externalArticleId',
            sortable: true,
          },
          {
            kind: 'accessor',
            name: t('ignoredArticles.status'),
            accessor: 'status',
          },
          {
            kind: 'component',
            name: t('ignoredArticles.actions'),
            component: Actions,
            props: {doSupplierArticleDelete, doApproveSupplierArticle, productPostResponse},
            onFetchProps: {conditions: {status: 'ignored'}},
          },
        ]}
        endpoint={'supplier_articles'}
        options={options}
        title={t('ignoredArticles.screenTitle')}
      />
    </div>
  );
};

export default IgnoredSupplierArticle;
