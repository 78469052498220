import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {DataTableItemType} from '../../components/Table/interfaces/IHeaderAccessor';
import DataTable from '../../components/Table/Table';
import {ProductStatus} from '../../types/Product/ProductStatus.enum';
import {useSelection} from '../../hooks/selection';
import Button from '@material-ui/core/Button';
import withRoleGuard from '../../guards/Role-Guard';
import {USER_ROLE} from '../../types/User/UserRole';
import {useDispatch} from 'react-redux';
import {useStorlessFetch} from '../../hooks/fetch';
import {modalActions} from '../../modules/modal/actions';
import {appActions} from '../../modules/app/actions';
import {MODALS} from '../../components/Modal/ModalContents';

export default function ProductsByShopStatus() {
  const selectionKey = 'PRODUCTS_READY_TO_ACTIVATE';
  const [selectedValues, setSelectedValues] = useSelection(selectionKey);
  const [activatedResponse, activateProducts] = useStorlessFetch('update_product_status');
  const [refresh, setRefresh] = useState(false);

  const {t} = useTranslation();
  const dispatch = useDispatch();
  const productStatusOptions = ([{value: 'all', label: 'All'}] as any).concat(
    Object.values(ProductStatus)
      .filter((x) => x !== ProductStatus.DELETED)
      .map((item) => {
        return {value: item, label: item};
      }),
  );

  const onActivateProducts = async (selectedShops: string[]) => {
    await activateProducts({status: ProductStatus.ACTIVE, product: selectedValues, shopIds: selectedShops});
    setRefresh((prevState: boolean) => !prevState);
    dispatch(modalActions.closeModal());
  };

  const onActivateProductsHandler = () => {
    dispatch(
      modalActions.addModal(MODALS.ALERT_DIALOG, {
        title: t('products.confirmTitle') || '',
        description: t('products.confirmationMessage'),
        onSubmit: onActivateProducts,
        onClose: handleCancelModal,
        showShopSelection: true,
        onOpen: true,
      }),
    );
  };

  const handleCancelModal = () => dispatch(modalActions.closeModal());

  const ActivateButton = () => (
    <Button variant="contained" className="blue-button" onClick={onActivateProductsHandler}>
      {t('deactivatedProducts.btnActivate')}
    </Button>
  );

  const ActivateButtonWithGuard = withRoleGuard([USER_ROLE.ADMIN, USER_ROLE.STANDARD], ActivateButton, false);

  useEffect(() => {
    if (activatedResponse.error) {
      dispatch(appActions.showSnackBar({text: t('activatedArticles.failedSyncMessage'), options: {severity: 'error'}}));
    }

    if (activatedResponse.loaded && !activatedResponse.error) {
      dispatch(
        appActions.showSnackBar({text: t('activatedArticles.successSyncMessage'), options: {severity: 'success'}}),
      );
    }

    return () => {
      activatedResponse.data = null;
      activatedResponse.error = null;
      activatedResponse.loaded = null;
    };
  }, [activatedResponse]);

  return (
    <div className="products">
      <DataTable
        className="products__table"
        headers={[
          {
            kind: 'accessor',
            name: t('products.productId'),
            accessor: 'productId',
            sortable: true,
            type: 'number',
          },
          {
            kind: 'accessor',
            name: t('products.title'),
            accessor: 'title',
            sortable: true,
          },
          {
            kind: 'accessor',
            name: t('products.isReadyToReactivate'),
            accessor: 'isReadyToReactivate',
            sortable: true,
            type: DataTableItemType.BOOLEAN,
          },
          {
            kind: 'accessor',
            name: t('products.ddaStatus'),
            accessor: 'druckdichaus.status',
            sortable: true,
            type: DataTableItemType.SELECT,
            options: productStatusOptions,
          },
          {
            kind: 'accessor',
            name: t('products.ddaExcludeFromPricing'),
            accessor: 'druckdichaus.excludePricing',
            sortable: true,
            type: DataTableItemType.BOOLEAN,
          },
          {
            kind: 'accessor',
            name: t('products.spStatus'),
            accessor: 'suppliesPartner.status',
            sortable: true,
            type: DataTableItemType.SELECT,
            options: productStatusOptions,
          },
          {
            kind: 'accessor',
            name: t('products.spExcludeFromPricing'),
            accessor: 'suppliesPartner.excludePricing',
            sortable: true,
            type: DataTableItemType.BOOLEAN,
          },
          {
            kind: 'accessor',
            name: t('products.ebayStatus'),
            accessor: 'ebay.status',
            sortable: true,
            type: DataTableItemType.SELECT,
            options: productStatusOptions,
          },
          {
            kind: 'accessor',
            name: t('products.ebayExcludeFromPricing'),
            accessor: 'ebay.excludePricing',
            sortable: true,
            type: DataTableItemType.BOOLEAN,
          },
          {
            kind: 'accessor',
            name: t('products.amazonExcludeFromPricing'),
            accessor: 'amazon.excludePricing',
            sortable: true,
            type: DataTableItemType.BOOLEAN,
          },
          {
            kind: 'accessor',
            name: t('products.kauflandExcludeFromPricing'),
            accessor: 'kaufland.excludePricing',
            sortable: true,
            type: DataTableItemType.BOOLEAN,
          },
          {
            kind: 'accessor',
            name: t('products.galaxusExcludeFromPricing'),
            accessor: 'galaxus.excludePricing',
            sortable: true,
            type: DataTableItemType.BOOLEAN,
          },
          {
            kind: 'accessor',
            name: t('products.metroExcludeFromPricing'),
            accessor: 'metro.excludePricing',
            sortable: true,
            type: DataTableItemType.BOOLEAN,
          },
          {
            kind: 'accessor',
            name: t('products.fnacExcludeFromPricing'),
            accessor: 'fnac.excludePricing',
            sortable: true,
            type: DataTableItemType.BOOLEAN,
          },
        ]}
        canShowCheckbox={true}
        selectionKey={selectionKey}
        endpoint={'products_get_products_statuses_and_excluded_pricing'}
        target={'products'}
        defaultSort={{key: 'productId', value: 1}}
        title={t('general.productShopStatuses')}
        actionButtons={<ActivateButtonWithGuard />}
        refreshData={refresh}
      />
    </div>
  );
}
