import React from 'react';
import Form, {IField} from '../Form/Form';
import _ from 'lodash';
import {useTranslation} from 'react-i18next';
import * as Yup from 'yup';

export interface IAddServiceOrderProps {
  onSubmit: (values: any) => void;
  onCancel: () => void;
}

const generateInitialValues = (fields: any[]) => {
  let defaults = {};
  fields.forEach((field) => {
    defaults = {...defaults, [field.fieldKey]: ''};
  });
  return defaults;
};

const AddServiceOrder = (props: IAddServiceOrderProps) => {
  const {onSubmit, onCancel} = props;
  const {t} = useTranslation();

  const validationSchema = Yup.object().shape({
    supplier: Yup.string().required('supplier'),
    total: Yup.string().required('total'),
  });

  const fields: IField[] = [
    {
      label: t('supplierOrderDetails.supplier'),
      placeholder: t('supplierOrderDetails.supplier'),
      fieldKey: 'supplier',
      type: 'autocomplete',
      options: [],
      endpoint: 'supplier_autocomplete_list',
      required: true,
    },
    {
      label: t('supplierOrders.totalNet'),
      placeholder: t('supplierOrders.totalNet'),
      fieldKey: 'total',
      type: 'input',
      fieldKeyType: 'number',
      required: true,
    },
  ];
  const initialValues = generateInitialValues(fields);

  return (
    <Form
      title={t('supplierOrders.addServiceOrder')}
      initialValues={initialValues}
      fields={fields}
      submitButtonText={t('general.submit')}
      cancelButtonText={t('general.cancel')}
      onSubmit={onSubmit}
      onCancel={onCancel}
      validationSchema={validationSchema}
    ></Form>
  );
};

export default AddServiceOrder;
