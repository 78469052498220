import React from 'react';
import {useTranslation} from 'react-i18next';
import DataTable from '../../components/Table/Table';

export default function ProductsStockPerDay() {
  const {t} = useTranslation();

  return (
    <div className="products">
      <DataTable
        className="products__table"
        headers={[
          {
            kind: 'accessor',
            name: t('general.date'),
            accessor: '_id',
          },
          {
            kind: 'accessor',
            name: t('products.totalQuantity'),
            accessor: 'totalDailyQuantity',
            sortable: true,
          },
          {
            kind: 'accessor',
            name: t('products.totalPrice'),
            accessor: 'totalDailyPrice',
            sortable: true,
            format: 'price',
          },
        ]}
        endpoint={'products_stock_per_day'}
        title={t('general.productsStockPerDay')}
        numberOfItemsPerPage={50}
      />
    </div>
  );
}
