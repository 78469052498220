import {Typography} from '@material-ui/core';
import React from 'react';
import Form, {IField} from '../Form/Form';
import {useTranslation} from 'react-i18next';
import * as Yup from 'yup';

const EditOrderDueDateModal = (props: IEditOrderDueDateModalProps) => {
  const {title, isLoading, onSubmit, onCancel} = props;
  const {t} = useTranslation();

  const fields: IField[] = [
    {
      label: t('supplierOrderDetails.product'),
      placeholder: t('supplierOrderDetails.product'),
      fieldKey: 'dueDate',
      type: 'date',
      required: true,
    },
  ];

  const initialValues = {
    dueDate: props.dueDate,
  };

  const validationSchema = Yup.object().shape({
    dueDate: Yup.string().required('Required').nullable(),
  });

  return (
    <>
      <Typography className="modal-title">{title}</Typography>
      <Form
        initialValues={initialValues}
        fields={fields}
        submitButtonText={t('general.submit')}
        cancelButtonText={t('general.cancel')}
        onSubmit={onSubmit}
        onCancel={onCancel}
        validationSchema={validationSchema}
        isLoading={isLoading}
      ></Form>
    </>
  );
};

export interface IEditOrderDueDateModalProps {
  title: string;
  onSubmit: (values: any) => void;
  onCancel: () => void;
  isLoading?: boolean;
  dueDate?: any;
}

export default EditOrderDueDateModal;
