import {push} from 'connected-react-router';
import {put, takeEvery, take} from 'redux-saga/effects';
import {AuthHelper} from '../../lib/auth';
import config from '../../lib/config';
import {socketFactory} from '../../lib/socketFactory';
import {storage} from '../../lib/storage';
import {APP_ROUTES, AUTH_ROUTES} from '../../routers/routes';
import {API, apiActions} from '../api/actions';
import {api} from '../api/ApiFactory';
import {APP, appActions} from './actions';

export enum LoginState {
  LOGGED_OUT = 1,
  LOGGED_IN = 2,
}

export function* onAppInit() {
  const token = yield storage.getValue(config.tokenKey);
  if (token) {
    yield postLogin(token);
  } else {
    yield put(push(AUTH_ROUTES.LOGIN));
    yield put(appActions.setLoginState(LoginState.LOGGED_OUT));
  }
}

export function* watchAppInit() {
  yield takeEvery(APP.INIT, onAppInit);
}

export function* watchSuccessLogin() {
  yield takeEvery(`${API.FETCH_SUCCESS}VERIFY_TOKEN` as any, onLogin);
}

function* onLogin({payload}: {payload: any}) {
  storage.writeValue(config.tokenKey, payload);
  yield postLogin(payload);
}

function* postLogin(token: string) {
  api.setToken(token);

  const meActions = {success: `${API.FETCH_SUCCESS}ME`, failure: `${API.FETCH_FAILURE}ME`};
  const pathName = window.location.pathname;

  yield put(apiActions.fetch('me'));

  const me = yield take([meActions.success, meActions.failure]);
  if (me.type === meActions.success) {
    yield put(appActions.setLoginState(LoginState.LOGGED_IN));
    socketFactory.connect();

    if (AuthHelper.checkRouteExist(pathName, APP_ROUTES)) {
      yield put(push(pathName));
    } else {
      yield put(push(APP_ROUTES.HOME));
    }
  } else {
    yield put(push(AUTH_ROUTES.LOGIN));
  }
}

function* doLogout() {
  yield storage.writeValue(config.tokenKey, null as any);
  yield put(push(AUTH_ROUTES.LOGIN));
  yield put(appActions.setLoginState(LoginState.LOGGED_OUT));
}

function* watchLogout() {
  yield takeEvery(APP.LOGOUT as any, doLogout);
}
export default [watchAppInit, watchSuccessLogin, watchLogout];
