import {CircularProgress, Typography} from '@material-ui/core';
import React, {useState, useEffect} from 'react';
import Form, {IField} from '../Form/Form';
import {useTranslation} from 'react-i18next';
import * as Yup from 'yup';
import {useQuery} from 'react-query';
import {api} from '../../modules/api/ApiFactory';

const fetchSuppliers = async () => {
  const response = await api.fetch('suppliers_emails', {});
  return response.data;
};

const AddSupplierOrder = (props: IAddSupplierOrder) => {
  const {title, isLoading, onSubmit, onCancel} = props;
  const {t} = useTranslation();
  const {data: suppliers, isLoading: isSuppliersLoading, error} = useQuery('suppliers_emails', fetchSuppliers);
  const [fields, setFields] = useState<IField[]>([]);

  useEffect(() => {
    if (suppliers) {
      setFields([
        {
          label: t('supplierOrderDetails.supplier'),
          placeholder: t('supplierOrderDetails.supplier'),
          fieldKey: 'supplier',
          type: 'autocomplete',
          options: [],
          endpoint: 'supplier_autocomplete_list',
          required: true,
          onChange: handleSupplierInputChange,
        },
        {
          label: t('supplierOrderDetails.product'),
          placeholder: t('supplierOrderDetails.product'),
          fieldKey: 'product',
          type: 'autocomplete',
          endpoint: 'product_autocomplete_list',
          options: [],
          required: true,
        },
        {
          label: t('supplierOrderDetails.price'),
          placeholder: t('supplierOrderDetails.price'),
          fieldKey: 'price',
          type: 'input',
          fieldKeyType: 'number',
          required: true,
        },
        {
          label: t('supplierOrderDetails.quantity'),
          placeholder: t('supplierOrderDetails.quantity'),
          fieldKey: 'quantity',
          type: 'input',
          fieldKeyType: 'number',
          required: true,
        },
        {
          label: t('supplierOrderDetails.sendEmail'),
          placeholder: t('supplierOrderDetails.sendEmail'),
          fieldKey: 'sendEmail',
          type: 'checkbox',
          fieldKeyType: 'number',
        },
        {
          label: t('supplierOrderDetails.email'),
          placeholder: t('supplierOrderDetails.email'),
          fieldKey: 'email',
          fieldKeyType: 'email',
          type: 'input',
          required: false,
        },
        {
          label: t('supplierOrderDetails.customText'),
          placeholder: t('supplierOrderDetails.customText'),
          fieldKey: 'customText',
          type: 'multiline',
          required: false,
        },
      ]);
    }
  }, [suppliers, t]);

  const handleSupplierInputChange = (event: React.ChangeEvent<any>, value: any, setFieldValue: any) => {
    if (value) {
      const selectedSupplier = suppliers.find((supplier: any) => supplier._id === value);

      if (selectedSupplier) {
        setFieldValue('email', selectedSupplier.email);
      }
    }
  };

  const generateInitialValues = (fields: any[]) => {
    const defaults: any = {};
    fields.forEach((field) => {
      defaults[field.fieldKey] = '';
    });
    defaults.sendEmail = true;
    defaults.email = '';
    return defaults;
  };

  const initialValues = generateInitialValues(fields);

  const validationSchema = Yup.object().shape({
    supplier: Yup.string().required('Required').nullable(),
    product: Yup.string().required('Required').nullable(),
    price: Yup.number().required('Required').nullable(),
    quantity: Yup.number().required('Required').nullable(),
  });

  if (isSuppliersLoading) {
    return <CircularProgress />;
  }

  if (error) {
    return <div>Error loading suppliers</div>;
  }

  return (
    <>
      <Typography className="modal-title">{title}</Typography>
      <Form
        initialValues={initialValues}
        fields={fields}
        submitButtonText={t('general.submit')}
        cancelButtonText={t('general.cancel')}
        onSubmit={onSubmit}
        onCancel={onCancel}
        validationSchema={validationSchema}
        isLoading={isLoading}
      />
    </>
  );
};

export interface IAddSupplierOrder {
  title: string;
  onSubmit: (values: any) => void;
  onCancel: () => void;
  isLoading?: boolean;
}

export default AddSupplierOrder;
