import React, {ReactElement, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import DataTable from '../Table/Table';
import {Util} from '../../modules/api/utils';
import {OrderItemType} from '../../types/OrderItems/OrderItemType';
import {IOrder} from '../../types/Orders/IOrder';
import EditOrderItems from './EditOrderItems/EditOrderItems';
import {Button} from '@material-ui/core';
import {OrderType} from '../../types/Orders/OrderType';
import {OrderStatusType} from '../../types/Orders/OrderStatusType';
import {IProduct} from '../../types/Product/IProduct';
import {modalActions} from '../../modules/modal/actions';
import {MODALS} from '../Modal/ModalContents';
import {useDispatch} from 'react-redux';
import {isDarkMode} from '../../lib/helpers';

const OrderItemsTable = ({order}: IOrderItemsTableProps): ReactElement => {
  const {t} = useTranslation();
  const [currencyCode, setCurrencyCode] = useState('EUR');
  const [edit, setEdit] = useState(false);
  const dispatch = useDispatch();

  const isOrderEditable = () => {
    if (order.exchangeOrder) {
      return true;
    }
    if (order.orderType === OrderType.Amazon) {
      return false;
    }
    if (
      ![
        OrderStatusType.Pending,
        OrderStatusType.New,
        OrderStatusType.Offer,
        OrderStatusType.PendingOffer,
        OrderStatusType.OnHold,
      ].includes(order.status)
    ) {
      return false;
    }
    const orderItems = order.orderItems?.filter((orderItem) => {
      return [OrderItemType.product0, OrderItemType.product1].includes(orderItem.type);
    });

    // @ts-ignore
    const allItemsScanned = orderItems.every((orderItem) => {
      const scannedQuantity = orderItem.quantityScanned ?? 0;
      return scannedQuantity >= orderItem?.quantity;
    });

    if (allItemsScanned) {
      return false;
    }

    return true;
  };

  const onOrderEditing = (orderNumber: string) => {
    if (orderNumber === order?.orderNumber) {
      dispatch(
        modalActions.addChild(MODALS.CONFIRM_DIALOG, {
          title: t('general.warning'),
          content: t('general.orderIsBeingModified'),
          onOk: () => {
            dispatch(modalActions.closeModal());
            setEdit(false);
          },
          hideDefaultCloseButton: true,
        }),
      );
    }
  };

  useEffect(() => {
    setCurrencyCode(Util.getCurrencyCodeByCurrencyIso(order.currencyIso || 'EUR'));
  }, [order]);

  const getRowColor: ((item: any) => string) | undefined = (item) => {
    if (
      order.isTrackingNumberScanned ||
      [
        OrderStatusType.Canceled,
        OrderStatusType.Pending,
        OrderStatusType.PendingOffer,
        OrderStatusType.Offer,
        OrderStatusType.PartiallyReturned,
        OrderStatusType.Returned,
      ].includes(order.status)
    ) {
      return 'inherit';
    }

    const darkMode = isDarkMode();

    if (darkMode) {
      return item.product?.stockDDA >= item.quantity ? '#064e3b' : '#450a0a';
    }

    return item.product?.stockDDA >= item.quantity ? '#ecfdf5' : '#fee2e2';
  };

  return (
    <div className="order-details__table-wrapper">
      {isOrderEditable() && (
        <div style={{textTransform: 'uppercase', textAlign: 'right', marginBottom: '10px'}}>
          <span className="link" onClick={() => setEdit(!edit)}>
            {edit ? t('general.list') : t('general.edit')}
          </span>
        </div>
      )}
      {!edit && (
        <DataTable
          hideFilters={true}
          className="order-details__table"
          headers={[
            {
              kind: 'accessor',
              name: t('orderItems.name'),
              accessor: 'name',
              sortable: true,
            },
            {
              kind: 'accessor',
              name: t('orderItems.sku'),
              accessor: 'sku',
              sortable: true,
            },
            {
              kind: 'accessor',
              name: t('orderItems.quantity'),
              accessor: 'quantity',
              sortable: true,
            },
            {
              kind: 'accessor',
              name: t('orderItems.priceNet'),
              accessor: 'priceNet',
              sortable: true,
              format: 'price',
              currencyCode: currencyCode,
            },
            {
              kind: 'accessor',
              name: t('orderItems.vat'),
              accessor: 'vat',
              sortable: true,
            },
            {
              kind: 'accessor',
              name: t('orderItems.priceGross'),
              accessor: 'priceGross',
              sortable: true,
              format: 'price',
              currencyCode: currencyCode,
            },
            {
              kind: 'accessor',
              name: t('orderItems.discount'),
              accessor: 'discount',
              sortable: true,
            },
            {
              kind: 'accessor',
              name: t('orderItems.note'),
              accessor: 'note',
              sortable: true,
            },
            {
              kind: 'accessor',
              name: t('orderItems.quantityScanned'),
              accessor: 'quantityScanned',
              sortable: true,
            },
            {
              kind: 'accessor',
              name: t('orderItems.returnQuantityScanned'),
              accessor: 'returnQuantityScanned',
              sortable: true,
            },
            {
              kind: 'accessor',
              name: t('newProductForm.stockDDA'),
              accessor: 'product.stockDDA',
              sortable: false,
            },
            {
              kind: 'accessor',
              name: t('products.freeStock'),
              accessor: 'product.stockDDA',
              sortable: false,
              callback: (value: any) => {
                const product: IProduct = value?.product as any;
                if (product && product.requirements) {
                  const freeStock =
                    (product.stockDDA ?? 0) -
                    (product.requirements.pendingCustomerOrderQuantity ?? 0) -
                    (product.requirements.multiBoxRequirements ?? 0);
                  return freeStock < 0 ? 0 : freeStock;
                }
              },
            },
          ]}
          endpoint={'order_items'}
          options={{order: order._id, type: {$ne: OrderItemType.shipping}}}
          target={`products`}
          selectRowClickId={'product._id'}
          defaultSort={{key: 'type', value: 1}}
          getRowColor={getRowColor}
        />
      )}
      {edit && <EditOrderItems order={order} onOrderEditing={onOrderEditing} />}
    </div>
  );
};

interface IOrderItemsTableProps {
  order: IOrder;
  getOrderDetails: any;
}

export default OrderItemsTable;
