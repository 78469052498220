import {Button, TextField} from '@material-ui/core';
import React, {useEffect, useState, ReactElement} from 'react';
import {useTranslation} from 'react-i18next';
import {useFetch} from '../../hooks/fetch';
import {useDispatch} from 'react-redux';
import {appActions} from '../../modules/app/actions';
import {modalActions} from '../../modules/modal/actions';
import {MODALS} from '../../components/Modal/ModalContents';

const MergeCustomers = (): ReactElement => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const [fromCustomer, setFromCustomer] = useState('');
  const [toCustomer, setToCustomer] = useState('');
  const [mergeCustomersResponse, mergeCustomers] = useFetch<any>('merge_customers');

  useEffect(() => {
    if (mergeCustomersResponse?.data) {
      dispatch(appActions.showSnackBar({text: t('general.submitFormText'), options: {severity: 'success'}}));
    } else if (mergeCustomersResponse?.error) {
      dispatch(appActions.showSnackBar({text: mergeCustomersResponse.error.name, options: {severity: 'error'}}));
    }

    return () => {
      mergeCustomersResponse.data = null;
      mergeCustomersResponse.error = null;
      setFromCustomer('');
      setToCustomer('');
    };
  }, [mergeCustomersResponse]);

  const onSubmit = () => {
    mergeCustomers({from: fromCustomer, to: toCustomer});
    dispatch(modalActions.closeModal());
  };

  const mergeCustomersHandler = () => {
    dispatch(
      modalActions.addModal(MODALS.CONFIRM_DIALOG, {
        title: t('general.areYouSure'),
        content: t('mergeCustomers.areYouSure'),
        onYes: onSubmit,
        onNo: () => dispatch(modalActions.closeModal()),
      }),
    );
  };

  return (
    <div className="customers">
      <h3>
        <b>{t('mergeCustomers.screenTitle')}</b>
      </h3>
      <div className="card-info-customer-details">
        <div>
          <TextField
            variant="outlined"
            className="input"
            size="small"
            id="customerFrom"
            label={t('mergeCustomers.deletedCustomerNumber')}
            type="text"
            InputLabelProps={{
              shrink: true,
            }}
            style={{marginRight: 20, marginBottom: 20}}
            value={fromCustomer}
            onChange={(e) => {
              setFromCustomer(e.target.value);
            }}
          />
        </div>
        <div>
          <TextField
            variant="outlined"
            className="input"
            size="small"
            id="customerTo"
            label={t('mergeCustomers.remainingCustomerNumber')}
            type="text"
            InputLabelProps={{
              shrink: true,
            }}
            style={{marginRight: 20, marginBottom: 20}}
            value={toCustomer}
            onChange={(e) => {
              setToCustomer(e.target.value);
            }}
          />
        </div>
        <Button variant="contained" className="blue-button" onClick={mergeCustomersHandler}>
          {t('mergeCustomers.mergeButton')}
        </Button>
      </div>
    </div>
  );
};

export default MergeCustomers;
