import React, {createContext} from 'react';
import {ISupplierOrderItemsState} from '../../../components/SupplierOrder/SupplierOrderItemsTable';
import {ISupplierOrder} from '../../../types/SupplierOrders/ISupplierOrder';
import {ICustomField} from '../../../types/CustomFields/CustomField';

const CreateSupplierOrderContext = createContext({} as ICreateSupplierOrderContext);
export const CreateSupplierOrderProvider = CreateSupplierOrderContext.Provider;
export default CreateSupplierOrderContext;

interface ICreateSupplierOrderContext {
  order: ISupplierOrder;
  orderId: string;
  supplierOrderItem: any;
  supplierOrderDetails: any;
  postSupplierOrderItem: (data: any) => void;
  state: ISupplierOrderItemsState;
  setState: (data: any) => void;
  setSupplierOrder: (data: any) => void;
  supplierOrderItemDelete: any;
  doSupplierOrderItemDelete: (data: any) => void;
  moveToBacklogResponse: any;
  moveToBacklog: (data: any) => void;
  getSupplierOrderDetails: (data: any) => void;
  customFields: ICustomField[];
}
