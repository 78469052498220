import React from 'react';
import {useTranslation} from 'react-i18next';
import DataTable from '../../components/Table/Table';

const PaymentReminderList = () => {
  const {t} = useTranslation();

  return (
    <div className="orders">
      <DataTable
        headers={[
          {
            kind: 'accessor',
            name: t('customerDetails.customerName'),
            accessor: 'customer.customerName',
            sortable: false,
          },
          {
            kind: 'accessor',
            name: t('customerDetails.email'),
            accessor: 'customer.email',
            sortable: false,
          },
          {
            kind: 'accessor',
            name: t('customerDetails.easybillId'),
            accessor: 'customer.easybillId',
            sortable: false,
          },
          {
            kind: 'accessor',
            name: t('customerDetails.openInvoices'),
            accessor: 'invoices',
            sortable: false,
          },
        ]}
        endpoint={'customers_payment_reminder_list'}
        target={'customers'}
        defaultSort={{key: 'date', value: 1}}
        selectRowClickId={'customer._id'}
        numberOfItemsPerPage={100}
        title={t('general.paymentReminderList')}
      />
    </div>
  );
};

export default PaymentReminderList;
