import {ActionsUnion, createAction} from '../../lib/actions';

enum SELECTION {
  SELECTION_VALUES = 'SELECTION_VALUES',
}
const selectionActions = {
  selectionValues: (data: {key?: string; values: any[]}) => createAction(SELECTION.SELECTION_VALUES, data),
};

export type SelectionActions = ActionsUnion<typeof selectionActions>;
export {SELECTION, selectionActions};
