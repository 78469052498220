import {Button} from '@material-ui/core';
import React, {ReactElement, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {useFetch} from '../../hooks/fetch';
import {appActions} from '../../modules/app/actions';
import {modalActions} from '../../modules/modal/actions';
import {MODALS} from '../Modal/ModalContents';

const AddSupplierOrderItemButton = (props: IAddSupplierOrderButtonProps): ReactElement => {
  const {fetchSupplierOrderItems, productId, supplierId, supplierOrderId} = props;
  const [supplierOrderItem, postSupplierOrderItem] = useFetch<any>('supplier_order_item_create');
  const dispatch = useDispatch();
  const {t} = useTranslation();

  const handleCancelModal = () => {
    dispatch(modalActions.closeModal());
  };

  const handleSubmitModal = async (values: any) => {
    const data = {...values, ...(supplierOrderId && {supplierOrder: supplierOrderId})};
    data.product = productId ?? data.product;
    data.supplier = supplierId ?? data.supplier;

    handleCancelModal();
    postSupplierOrderItem(data);
  };

  const handleClick = async () => {
    dispatch(
      modalActions.addModal(MODALS.ADD_SUPPLIER_ORDER_ITEM, {
        onSubmit: handleSubmitModal,
        onCancel: handleCancelModal,
        productId: productId,
        supplierId: supplierId,
        isLoading: supplierOrderItem?.loading,
      }),
    );
  };

  useEffect(() => {
    if (supplierOrderItem?.error) {
      dispatch(appActions.showSnackBar({text: supplierOrderItem.error.name, options: {severity: 'error'}}));
    } else if (supplierOrderItem?.data) {
      fetchSupplierOrderItems();
      dispatch(appActions.showSnackBar({text: t('supplierOrderItems.orderCreated'), options: {severity: 'success'}}));
    }

    return () => {
      supplierOrderItem.data = null;
      supplierOrderItem.error = null;
      supplierOrderItem.loaded = null;
    };
  }, [supplierOrderItem]);

  return (
    <Button variant="contained" className="blue-button" onClick={handleClick}>
      {t('supplierOrderItems.addButton')}
    </Button>
  );
};

interface IAddSupplierOrderButtonProps {
  fetchSupplierOrderItems: () => void;
  productId?: string;
  supplierOrderId?: string;
  supplierId?: string;
}

export default AddSupplierOrderItemButton;
