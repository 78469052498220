import React from 'react';
import {useTranslation} from 'react-i18next';
import DataTable from '../components/Table/Table';
import {DataTableItemType} from '../components/Table/interfaces/IHeaderAccessor';

export default function SupplierComplaintScreen() {
  const {t} = useTranslation();

  return (
    <div className="orders__customer__return">
      <DataTable
        className="orders__table"
        headers={[
          {
            kind: 'accessor',
            name: t('supplierComplaints.supplierName'),
            accessor: 'supplierName',
            sortable: true,
          },
          {
            kind: 'accessor',
            name: t('supplierComplaints.number'),
            accessor: 'number',
            sortable: true,
            type: DataTableItemType.NUMBER,
          },
          {
            kind: 'accessor',
            name: t('supplierComplaints.createdAt'),
            accessor: 'createdAt',
            sortable: true,
            type: DataTableItemType.DATE,
          },
        ]}
        endpoint={'supplier_complaints_list'}
        target="supplier-complaints"
        defaultSort={{key: 'createdAt', value: -1}}
        title={t('general.supplierComplaints')}
      />
    </div>
  );
}
