import {Typography} from '@material-ui/core';
import React from 'react';
import Form from '../Form/Form';
import {useTranslation} from 'react-i18next';

export interface IEditAmazonConfig {
  title: string;
  onSubmit: (values: any) => void;
  onCancel: () => void;
  data?: any;
}

const generateInitialValues = (fields: any[], data: any) => {
  if (data) {
    const fields = {...data};
    return fields;
  } else {
    let defaults = {};
    fields.forEach((field) => {
      defaults = {...defaults, [field.fieldKey]: ''};
    });
    return defaults;
  }
};

export const EditAmazonConfig = (props: IEditAmazonConfig) => {
  const {title, onSubmit, onCancel, data} = props;
  const {t} = useTranslation();

  const fields = [
    {
      label: t('amazonPricing.minimumPriceMargin'),
      placeholder: t('amazonPricing.minimumPriceMargin'),
      fieldKey: 'minimumPriceMargin',
      type: 'input',
      fieldKeyType: 'number',
      step: 0.1,
    },
    {
      label: t('amazonPricing.maximumPriceMargin'),
      placeholder: t('amazonPricing.maximumPriceMargin'),
      fieldKey: 'maximumPriceMargin',
      type: 'input',
      fieldKeyType: 'number',
      step: 0.1,
    },
    {
      label: t('amazonPricing.wishPriceMargin'),
      placeholder: t('amazonPricing.wishPriceMargin'),
      fieldKey: 'wishPriceMargin',
      type: 'input',
      fieldKeyType: 'number',
      step: 0.1,
    },
  ];

  const initialValues = generateInitialValues(fields, data);

  return (
    <>
      <Typography className="modal-title">{title}</Typography>
      <Form
        initialValues={initialValues}
        fields={fields}
        submitButtonText={t('general.submit')}
        cancelButtonText={t('general.cancel')}
        onSubmit={onSubmit}
        onCancel={onCancel}
      ></Form>
    </>
  );
};

export default EditAmazonConfig;
