import React, {ReactElement} from 'react';
import {IProduct} from '../../types/Product/IProduct';
import {Grid} from '@material-ui/core';

const ProductSkus = (props: IProductSkusProps): ReactElement => {
  return (
    <div className="product-info" style={{border: 'none', boxShadow: 'none'}}>
      <Grid container style={{marginTop: '10px'}}>
        {props.product.skus?.map((sku: string, index: number) => {
          return (
            <Grid item xs={6} sm={2} key={index} className="product-info-item">
              <div className="product-info-label">SKU {index + 1}</div>
              <div className="product-info-text">{sku}</div>
            </Grid>
          );
        })}
      </Grid>
    </div>
  );
};

interface IProductSkusProps {
  product: IProduct;
}

export default ProductSkus;
