import React from 'react';
import i18n from '../../i18n';
import {SyncTypes} from '../../screens/SyncStatuses/SyncStatuses';
import {CustomerReturnStatus} from '../../types/CustomerReturn/CustomerReturnStatus';
import {OrderProblemLocationType} from '../../types/OrderProblems/OrderProblemLocationType';
import {OrderProblemStatusType} from '../../types/OrderProblems/OrderProblemStatusType';
import {OrderStatusType} from '../../types/Orders/OrderStatusType';
import {SupplierOrderItemStatus} from '../../types/SupplierOrders/SupplierOrderItemStatus';
import {SupplierOrderStatus} from '../../types/SupplierOrders/SupplierOrderStatus';
import {shippingProviderTypeFilter} from '../../types/Shipment/ShippingProviderType';

export enum FILTERS {
  NONE = 'NONE',
  SUPPLIERS = 'SUPPLIERS',
  TEST = 'TEST',
  SUPPLIER_ARTICLES = 'SUPPLIER_ARTICLES',
  NEW_SUPPLIER_ARTICLES = 'NEW_SUPPLIER_ARTICLES',
  ORDERS = 'ORDERS',
  ORDER_PROBLEMS = 'ORDER_PROBLEMS',
  PRODUCTS = 'PRODUCTS',
  CUSTOMERS = 'CUSTOMERS',
  COUNTRY_SHIPPING_PROVIDERS = 'COUNTRY_SHIPPING_PROVIDERS',
  SYNC_STATUS = 'SYNC_STATUS',
  SUPPLIER_ORDER_ITEMS = 'SUPPLIER_ORDER_ITEMS',
  CUSTOMER_RETURNS = 'CUSTOMER_RETURNS',
  ACTIVATED_SUPPLIER_ARTICLES = 'ACTIVATED_SUPPLIER_ARTICLES',
  ORDERS_READY_FOR_SHIPPING = 'ORDERS_READY_FOR_SHIPPING',
  SUPPLIER_ORDERS = 'SUPPLIER_ORDERS',
}

export const FiltersConfig = {
  [FILTERS.NONE]: {
    initialValues: {},
  },
  [FILTERS.SYNC_STATUS]: {
    fields: [
      {
        label: i18n.t('syncStatus.syncName'),
        placeholder: i18n.t('syncStatus.syncName'),
        fieldKey: 'syncName',
        type: 'select',
        options: [
          {value: 'all', label: 'All'},
          {value: SyncTypes.SUPPLIER_ARTICLES, label: i18n.t('general.supplierArticles')},
          {value: SyncTypes.JTL_ORDERS_SHOP1, label: i18n.t('general.orders')},
        ],
      },
      {
        label: i18n.t('syncStatus.title'),
        placeholder: i18n.t('syncStatus.title'),
        fieldKey: 'status',
        type: 'select',
        options: [
          {value: 'SUCCESS', label: i18n.t('syncStatus.success')},
          {value: 'ERROR', label: i18n.t('syncStatus.error')},
        ],
      },
    ],
  },
  [FILTERS.SUPPLIERS]: {
    fields: [
      {
        label: 'Name',
        placeholder: 'Name',
        fieldKey: 'name',
        type: 'input',
      },
      {
        label: 'Company Name',
        placeholder: 'Company Name',
        fieldKey: 'companyName',
        type: 'input',
      },
      {
        label: 'Email',
        placeholder: 'Email',
        fieldKey: 'email',
        type: 'input',
      },
    ],

    initialValues: {type: 'all'},
  },
  [FILTERS.SUPPLIER_ARTICLES]: {
    fields: [
      {
        label: 'Status',
        placeholder: 'Status',
        fieldKey: 'status',
        type: 'select',
        options: [
          {value: 'all', label: 'All'},
          {value: 'approved', label: 'Approved'},
          {value: 'new', label: 'New'},
          {value: 'ignored', label: 'Ignored'},
        ],
      },
    ],
    initialValues: {status: 'all'},
  },
  [FILTERS.NEW_SUPPLIER_ARTICLES]: {
    fields: [
      {
        label: 'Status',
        placeholder: 'Status',
        fieldKey: 'status',
        type: 'select',
        options: [
          {value: 'all', label: 'All'},
          {value: 'approved', label: 'Approved'},
          {value: 'new', label: 'New'},
          {value: 'ignored', label: 'Ignored'},
        ],
      },
      {
        label: 'Supplier',
        placeholder: 'Supplier',
        fieldKey: 'supplierName',
        type: 'input',
      },
      {
        label: 'Title',
        placeholder: 'Title',
        fieldKey: 'title',
        type: 'input',
      },
      {
        label: 'External Article Id',
        placeholder: 'External Article Id',
        fieldKey: 'externalArticleId',
        type: 'input',
      },
    ],
    initialValues: {status: 'new'},
  },
  [FILTERS.ACTIVATED_SUPPLIER_ARTICLES]: {
    fields: [
      {
        label: 'Supplier',
        placeholder: 'Supplier',
        fieldKey: 'supplierName',
        type: 'input',
      },
      {
        label: 'Product',
        placeholder: 'Product',
        fieldKey: 'productTitle',
        type: 'input',
      },
      {
        label: 'External Article Id',
        placeholder: 'External Article Id',
        fieldKey: 'externalArticleId',
        type: 'input',
      },
    ],
  },
  [FILTERS.ORDERS]: {
    fields: [
      {
        label: 'Order number',
        placeholder: 'Order number',
        fieldKey: 'orderNumber',
        type: 'input',
      },
      {
        label: 'Customer number',
        placeholder: 'Customer number',
        fieldKey: 'customerNumber',
        type: 'input',
      },
      {
        label: 'Customer name',
        placeholder: 'Customer name',
        fieldKey: 'shippingAddress.customerName',
        type: 'input',
      },
      {
        label: 'Shipping email',
        placeholder: 'Shipping email',
        fieldKey: 'shippingAddress.email',
        type: 'input',
      },
      {
        label: 'Status',
        placeholder: 'Status',
        fieldKey: 'status',
        type: 'multipleselection',
        options: ([{value: 'all', label: 'All'}] as any).concat(
          Object.values(OrderStatusType).map((item) => {
            return {value: item, label: item};
          }),
        ),
      },
      {
        label: 'Order type',
        placeholder: 'Order type',
        fieldKey: 'orderType',
        type: 'select',
        options: [
          {value: 'all', label: 'All'},
          {value: 'JTL', label: 'JTL'},
          {value: 'Amazon', label: 'Amazon'},
        ],
      },
      {
        label: 'Shipping type',
        placeholder: 'Shipping type',
        fieldKey: 'shippingType',
        type: 'select',
        options: [{value: 'all', label: 'All'}, ...shippingProviderTypeFilter],
      },
    ],

    initialValues: {type: 'all'},
  },
  [FILTERS.ORDER_PROBLEMS]: {
    fields: [
      {
        label: 'Order number',
        placeholder: 'Order number',
        fieldKey: 'orderNumber',
        type: 'input',
      },
      {
        label: 'Status',
        placeholder: 'Status',
        fieldKey: 'status',
        type: 'select',
        options: ([{value: 'all', label: 'All'}] as any).concat(
          Object.values(OrderProblemStatusType).map((item) => {
            return {value: item, label: item};
          }),
        ),
      },
      {
        label: 'Location',
        placeholder: 'Location',
        fieldKey: 'location',
        type: 'select',
        options: ([{value: 'all', label: 'All'}] as any).concat(
          Object.values(OrderProblemLocationType).map((item) => {
            return {value: item, label: item};
          }),
        ),
      },
      {
        label: 'Order problem type',
        placeholder: 'Order problem type',
        fieldKey: 'orderProblemTypeName',
        type: 'input',
      },
      {
        label: 'Customer name',
        placeholder: 'Customer name',
        fieldKey: 'customerName',
        type: 'input',
      },
      {
        label: 'Shipping type',
        placeholder: 'Shipping type',
        fieldKey: 'orderShippingType',
        type: 'select',
        options: [{value: 'all', label: 'All'}, ...shippingProviderTypeFilter],
      },
    ],

    initialValues: {},
  },
  [FILTERS.PRODUCTS]: {
    fields: [
      {
        label: 'Title',
        placeholder: 'Title',
        fieldKey: 'title',
        type: 'input',
      },
      {
        label: 'Manufacturer',
        placeholder: 'Manufacturer',
        fieldKey: 'manufacturer',
        type: 'input',
      },
      {
        label: 'Colour',
        placeholder: 'Colour',
        fieldKey: 'colour',
        type: 'input',
      },
    ],

    initialValues: {},
  },

  [FILTERS.CUSTOMERS]: {
    fields: [
      {
        label: 'Customer Number',
        placeholder: 'Customer Number',
        fieldKey: 'customerNumber',
        type: 'input',
      },
      {
        label: 'Customer type',
        placeholder: 'Customer type',
        fieldKey: 'customerType',
        type: 'select',
        options: [
          {value: 'all', label: 'All'},
          {value: 'JTL', label: 'JTL'},
          {value: 'Amazon', label: 'Amazon'},
        ],
      },
      {
        label: 'Name',
        placeholder: 'Name',
        fieldKey: 'customerName',
        type: 'input',
      },
      {
        label: 'Email',
        placeholder: 'Email',
        fieldKey: 'email',
        type: 'input',
      },
      {
        label: 'Address',
        placeholder: 'Address',
        fieldKey: 'address',
        type: 'input',
      },
      {
        label: 'Country',
        placeholder: 'Country',
        fieldKey: 'country',
        type: 'input',
      },
      {
        label: 'City',
        placeholder: 'City',
        fieldKey: 'city',
        type: 'input',
      },
      {
        label: 'Phone',
        placeholder: 'Phone',
        fieldKey: 'phone',
        type: 'input',
      },
    ],

    initialValues: {type: 'all'},
  },

  [FILTERS.COUNTRY_SHIPPING_PROVIDERS]: {
    fields: [
      {
        label: 'Shipping provider',
        placeholder: 'Shipping provider',
        fieldKey: 'shippingProvider',
        type: 'select',
        options: [{value: 'all', label: 'All'}, ...shippingProviderTypeFilter],
      },
    ],

    initialValues: {type: 'all'},
  },

  [FILTERS.SUPPLIER_ORDER_ITEMS]: {
    fields: [
      {
        label: 'Status',
        placeholder: 'Status',
        fieldKey: 'status',
        type: 'select',
        options: ([{value: 'all', label: 'All'}] as any).concat(
          Object.values(SupplierOrderItemStatus).map((item) => {
            return {value: item, label: item};
          }),
        ),
      },
      {
        label: 'Supplier',
        placeholder: 'Supplier',
        fieldKey: 'supplierName',
        type: 'input',
      },
      {
        label: 'Product',
        placeholder: 'Product',
        fieldKey: 'productTitle',
        type: 'input',
      },
      {
        label: 'Article ID',
        placeholder: 'Article ID',
        fieldKey: 'externalArticleId',
        type: 'input',
      },
    ],

    initialValues: {type: 'all'},
  },
  [FILTERS.CUSTOMER_RETURNS]: {
    fields: [
      {
        label: 'Status',
        placeholder: 'Status',
        fieldKey: 'status',
        type: 'select',
        options: ([{value: 'all', label: 'All'}] as any).concat(
          Object.values(CustomerReturnStatus).map((item) => {
            return {value: item, label: item};
          }),
        ),
      },
      {
        label: 'Order Number',
        placeholder: 'Order Number',
        fieldKey: 'orderNumber',
        type: 'input',
      },
      {
        label: 'Supplier',
        placeholder: 'Supplier',
        fieldKey: 'supplierName',
        type: 'input',
      },
      {
        label: 'External Article ID',
        placeholder: 'External Article ID',
        fieldKey: 'externalArticleId',
        type: 'input',
      },
      {
        label: 'Product',
        placeholder: 'Product',
        fieldKey: 'productTitle',
        type: 'input',
      },
      {
        label: 'Customer Number',
        placeholder: 'Customer Number',
        fieldKey: 'customerNumber',
        type: 'input',
      },
      {
        label: 'Customer',
        placeholder: 'Customer',
        fieldKey: 'customerName',
        type: 'input',
      },
    ],

    initialValues: {},
  },
  [FILTERS.ORDERS_READY_FOR_SHIPPING]: {
    fields: [
      {
        label: 'Order number',
        placeholder: 'Order number',
        fieldKey: 'orderNumber',
        type: 'input',
      },
      {
        label: 'Customer number',
        placeholder: 'Customer number',
        fieldKey: 'customerNumber',
        type: 'input',
      },
      {
        label: 'Customer name',
        placeholder: 'Customer name',
        fieldKey: 'shippingAddress.customerName',
        type: 'input',
      },
      {
        label: 'Shipping email',
        placeholder: 'Shipping email',
        fieldKey: 'shippingAddress.email',
        type: 'input',
      },
      {
        label: 'Order type',
        placeholder: 'Order type',
        fieldKey: 'orderType',
        type: 'select',
        options: [
          {value: 'all', label: 'All'},
          {value: 'JTL', label: 'JTL'},
          {value: 'Amazon', label: 'Amazon'},
        ],
      },
      {
        label: 'Shipping type',
        placeholder: 'Shipping type',
        fieldKey: 'shippingType',
        type: 'select',
        options: [{value: 'all', label: 'All'}, ...shippingProviderTypeFilter],
      },
    ],

    initialValues: {type: 'all'},
  },

  [FILTERS.SUPPLIER_ORDERS]: {
    fields: [
      {
        label: 'Status',
        placeholder: 'Status',
        fieldKey: 'status',
        type: 'select',
        options: ([{value: 'all', label: 'All'}] as any).concat(
          Object.values(SupplierOrderStatus).map((item) => {
            return {value: item, label: item};
          }),
        ),
      },
      {
        label: 'Order Number',
        placeholder: 'Order Number',
        fieldKey: 'orderNumber',
        type: 'input',
      },
      {
        label: 'Supplier',
        placeholder: 'Supplier',
        fieldKey: 'supplierName',
        type: 'input',
      },
    ],

    initialValues: {type: 'all'},
  },
};
