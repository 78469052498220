import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import DataTable from '../../components/Table/Table';
import moment from 'moment';

const UnfinishedSupplierOrderItems = () => {
  const {t} = useTranslation();
  const [triggerRefreshTable, setTriggerRefreshTable] = useState<any>(null);

  return (
    <div className="supplier-articles">
      <DataTable
        className="new-supplier__table"
        headers={[
          {
            kind: 'accessor',
            name: t('dashboard.suppliersName'),
            accessor: 'supplierName',
            sortable: true,
          },
          {
            kind: 'accessor',
            name: t('supplierOrderItems.orderNumber'),
            accessor: 'supplierOrderNumber',
            sortable: true,
            type: 'number',
          },
          {
            kind: 'accessor',
            name: t('supplierOrderItems.dateOrdered'),
            accessor: 'dateOrdered',
            sortable: true,
            type: 'date',
            callback: (value: any) => {
              const date = moment(value.dateOrdered);
              return `${date.format('DD.MM.YYYY')}`;
            },
          },
          {
            kind: 'accessor',
            name: t('products.title'),
            accessor: 'productTitle',
            sortable: true,
          },
          {
            kind: 'accessor',
            name: t('translation:supplierOrderItems.quantity'),
            accessor: 'quantity',
            sortable: false,
          },
          {
            kind: 'accessor',
            name: t('translation:supplierOrderItems.quantityDelivered'),
            accessor: 'quantityDelivered',
            sortable: false,
          },
          {
            kind: 'accessor',
            name: t('translation:supplierOrderItems.note'),
            accessor: 'note',
            sortable: false,
          },
        ]}
        endpoint={'unfinished_supplier_order_items'}
        options={{}}
        numberOfItemsPerPage={50}
        refreshData={triggerRefreshTable}
        title={t('general.supplierOrdersBacklogList')}
      />
    </div>
  );
};

export default UnfinishedSupplierOrderItems;
