import {Button, Grid} from '@material-ui/core';
import React, {ReactElement, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {useFetch} from '../../../hooks/fetch';
import {appActions} from '../../../modules/app/actions';

const UploadShippingLabelButton = (props: IUploadShippingLabelButtonProps): ReactElement => {
  const {t} = useTranslation();
  const [uploadedFile, setUploadedFile] = useState<any>(null);
  const [shippingLabelResponse, uploadShippingLabel] = useFetch('upload_shipping_label');
  const {orderId, getOrderDetails} = props;
  const dispatch = useDispatch();

  useEffect(() => {
    if (shippingLabelResponse?.data) {
      dispatch(
        appActions.showSnackBar({text: t('orderDetails.shippingLabelUploaded'), options: {severity: 'success'}}),
      );
      getOrderDetails({id: orderId});
    }

    if (shippingLabelResponse?.error) {
      dispatch(
        appActions.showSnackBar({text: t('orderDetails.shippingLabelUploadFailed'), options: {severity: 'error'}}),
      );
    }

    return () => {
      setUploadedFile(null);
      shippingLabelResponse.data = null;
      shippingLabelResponse.error = null;
    };
  }, [shippingLabelResponse]);

  const handleFileUpload = (event: any) => {
    setUploadedFile(event?.target?.files[0]);
    event.target.value = null;
  };

  const convertBase64 = (file: any) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);

      fileReader.onload = () => {
        const result: any = fileReader.result || '';
        resolve(result.split(';base64,')[1]);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const uploadImage = async () => {
    const file = uploadedFile;
    const base64 = await convertBase64(file);

    if (!base64) {
      dispatch(appActions.showSnackBar({text: t('orderDetails.invalidFile'), options: {severity: 'error'}}));
    }

    uploadShippingLabel({id: orderId, base64Pdf: base64});
  };

  return (
    <Grid item xs={12} sm={6} className="order-info-item">
      <span className="order-info-label">{t('orderDetails.shippingLabel')}</span>
      <span className="order-info-text">
        {!uploadedFile ? (
          <Button variant="contained" component="label" className="order-info-button">
            {t('general.chooseFile')}
            <input type="file" hidden id="file" name="file" accept=".pdf" onChange={handleFileUpload} />
          </Button>
        ) : (
          <Button variant="contained" className="upload-button" onClick={uploadImage}>
            {t('general.uploadFile')}
          </Button>
        )}
      </span>
    </Grid>
  );
};

interface IUploadShippingLabelButtonProps {
  orderId: any;
  getOrderDetails: (id: any) => void;
}

export default UploadShippingLabelButton;
