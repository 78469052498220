import {Typography} from '@material-ui/core';
import React, {useEffect, useMemo, useState} from 'react';
import Form, {IField} from '../../components/Form/Form';
import {useStorlessFetch} from '../../hooks/fetch';
import * as Yup from 'yup';
import {Link} from 'react-router-dom';
import {api} from '../../modules/api/ApiFactory';
import {appActions} from '../../modules/app/actions';
import {AUTH_ROUTES} from '../../routers/routes';
import {useDispatch} from 'react-redux';

const ForgotPasswordConfirmation = (props: any) => {
  const [forgotPasswordResponse, forgotPasswordRequest] = useStorlessFetch('forgot_password_confirmation');
  const [passwordChangedState, setPasswordChangedState] = useState(false);
  const token = new URLSearchParams(props.location.search).get('token') || '';
  const dispatch = useDispatch();
  api.setToken(token);

  const formikValidationSchema = Yup.object().shape({
    password: Yup.string()
      .required('No password provided.')
      .matches(
        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
        'The Password must have min 8 characters at least 1 letter, 1 number and 1 special character!',
      ),
    confirmPassword: Yup.string().oneOf([Yup.ref('password'), null], "Password doesn't match"),
  });
  const fields: IField[] = useMemo(
    () => [
      {
        label: 'Password',
        placeholder: 'Type your password',
        fieldKey: 'password',
        type: 'input',
        fieldKeyType: 'password',
        required: true,
      },
      {
        label: 'Confirm Password',
        placeholder: 'Re-type your Password',
        fieldKey: 'confirmPassword',
        type: 'input',
        fieldKeyType: 'password',
        required: true,
      },
    ],
    [],
  );

  const title = useMemo(
    () => (
      <div className="forgot-password-confirm__title-wrapper">
        <Typography variant="h4">Druck Dich Aus</Typography>
      </div>
    ),
    [],
  );
  let form = null;

  if (!passwordChangedState)
    form = (
      <Form
        initialValues={{}}
        submitButtonText="Change Password"
        fields={fields}
        onSubmit={forgotPasswordRequest}
        validationSchema={formikValidationSchema}
      />
    );
  else
    form = (
      <div className="forgot-password-confirm__info">
        <h2>Password has been changed!</h2>
        <Link to={AUTH_ROUTES.LOGIN}>Go to login page</Link>
      </div>
    );

  useEffect(() => {
    if (forgotPasswordResponse.error) {
      dispatch(appActions.showSnackBar({text: forgotPasswordResponse.error.name, options: {severity: 'error'}}));
    }

    if (forgotPasswordResponse.loaded && !forgotPasswordResponse.error) {
      dispatch(appActions.showSnackBar({text: 'Password Changed!', options: {severity: 'success'}}));
      setPasswordChangedState(true);
    }
  }, [forgotPasswordResponse]);

  return (
    <div className="forgot-password-confirm">
      {title}
      <div className={!passwordChangedState ? 'forgot-password-confirm__form-wrapper' : 'forgot-password-confirm_info'}>
        {form}
      </div>
    </div>
  );
};
export default ForgotPasswordConfirmation;
