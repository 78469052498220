import React, {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import DataTable from '../../components/Table/Table';

const ProductsWithoutMinimumMargin = () => {
  const dispatch = useDispatch();
  const {t} = useTranslation();

  return (
    <div className="deactivated-products">
      <DataTable
        className="deactivated-products__table"
        headers={[
          {
            kind: 'accessor',
            name: t('deactivatedProducts.productId'),
            accessor: 'productId',
            sortable: true,
            type: 'number',
          },
          {
            kind: 'accessor',
            name: t('deactivatedProducts.title'),
            accessor: 'title',
            sortable: true,
          },
          {
            kind: 'accessor',
            name: t('deactivatedProducts.manufacturer'),
            accessor: 'productInformation.manufacturer',
            sortable: true,
          },
          {
            kind: 'accessor',
            name: t('deactivatedProducts.colour'),
            accessor: 'productInformation.colour',
            sortable: true,
          },
        ]}
        target={'products'}
        endpoint={'products_list'}
        options={{'options.doNotCalculateAmazonMinimumMargin': true}}
        defaultSort={{key: 'productId', value: 1}}
        title={t('products.productsWithoutMinimumMargin')}
      />
    </div>
  );
};

export default ProductsWithoutMinimumMargin;
