import {TableSortLabel, TextField, TableHead, TableRow, TableCell} from '@material-ui/core';
import {ExpandLess, ExpandMore} from '@material-ui/icons';
import React, {ReactElement} from 'react';
import {useTranslation} from 'react-i18next';

const SupplierOrderItemsTableHeader = (props: ISupplierOrderItemsTableHeaderProps): ReactElement => {
  const {filter, setFilter, setSort, sort} = props;
  const {t} = useTranslation();

  const isColumnSorted = (key: string) => {
    return sort.key === key ? sort.value : null;
  };

  const onFilterChange = (e: any) => {
    if (e.target.value !== '') {
      const inputValue = e.target.type === 'number' ? +e.target.value : e.target.value;
      setFilter({...filter, [`${e.target.id}`]: inputValue});
    } else {
      const state: any = {...filter};
      delete state[e.target.id];
      setFilter(state);
    }
  };

  const handleSortChange = async (key: string) => {
    if (sort.key !== key) {
      setSort({key, value: 1});
    } else {
      setSort({key, value: sort.value === 1 ? -1 : 1});
    }
  };

  const headerCell = (headerKey: string, label: string, type: string) => (
    <>
      <TableSortLabel
        id={headerKey}
        hideSortIcon={true}
        className="sort-icons"
        onClick={() => {
          handleSortChange(headerKey);
        }}
      >
        {label}
        {isColumnSorted(headerKey) && (
          <>
            {sort.value === -1 ? (
              <ExpandLess fontSize="small" className={'active-sort less'} />
            ) : (
              <ExpandMore fontSize="small" className={'active-sort more'} />
            )}
          </>
        )}
      </TableSortLabel>
      <div>
        <TextField
          type={type === 'number' ? 'number' : 'text'}
          datatype={type}
          id={headerKey}
          onChange={onFilterChange}
        />
      </div>
    </>
  );

  return (
    <TableHead>
      <TableRow>
        <TableCell></TableCell>
        <TableCell>{headerCell('productTitle', t('supplierOrderItems.product'), 'text')}</TableCell>
        <TableCell>{headerCell('supplierName', t('supplierOrderItems.supplier'), 'text')}</TableCell>
        <TableCell>{headerCell('externalArticleId', t('supplierOrderItems.externalArticleId'), 'text')}</TableCell>
        <TableCell>{headerCell('quantity', t('supplierOrderItems.quantity'), 'number')}</TableCell>
        <TableCell>{headerCell('price', t('supplierOrderItems.price'), 'number')}</TableCell>
        <TableCell>{headerCell('price', t('supplierOrderItems.purchaseList'), 'number')}</TableCell>
        <TableCell></TableCell>
      </TableRow>
    </TableHead>
  );
};

interface ISupplierOrderItemsTableHeaderProps {
  sort: any;
  setSort: (data: any) => void;
  filter: any;
  setFilter: (data: any) => void;
}

export default SupplierOrderItemsTableHeader;
