import Button from '@material-ui/core/Button/Button';
import React, {useEffect, useState} from 'react';
import {useFetch} from '../../hooks/fetch';
import {useDispatch} from 'react-redux';
import {modalActions} from '../../modules/modal/actions';
import {MODALS} from '../../components/Modal/ModalContents';
import _ from 'lodash';
import {appActions} from '../../modules/app/actions';
import {useTranslation} from 'react-i18next';
import {ICompanyConfig} from '../../types/Company/ICompanyDetails';
import {Card, Grid} from '@material-ui/core';

const CompanyConfiguration = () => {
  const [configDetails, getConfigDetails] = useFetch<any>('feature_configuration');
  const [configPut, doConfigPut] = useFetch<any>('feature_configuration_put');
  const [companyConfig, setCompanyConfig] = useState<ICompanyConfig>(null as any);
  const dispatch = useDispatch();
  const {t} = useTranslation();

  useEffect(() => {
    getConfigDetails({featureName: 'companyConfig'});
  }, []);

  useEffect(() => {
    if (configDetails?.data) {
      setCompanyConfig(configDetails.data as any);
    }

    return () => {
      configDetails.data = null;
      configDetails.error = null;
      configDetails.loaded = null;
    };
  }, [configDetails]);

  useEffect(() => {
    if (configPut?.data && !configPut.error) {
      dispatch(appActions.showSnackBar({text: t('dhlConfiguration.configUpdated'), options: {severity: 'success'}}));
      getConfigDetails({featureName: 'companyConfig'});
    } else if (configPut?.error) {
      dispatch(appActions.showSnackBar({text: configPut.error.name, options: {severity: 'error'}}));
    }

    return () => {
      configPut.data = null;
      configPut.error = null;
      configPut.loaded = null;
    };
  }, [configPut]);

  const handleCancelModal = () => {
    dispatch(modalActions.closeModal());
  };

  const handleSubmitModal = async (values: any) => {
    const data: ICompanyConfig = {id: companyConfig._id, ...values};
    doConfigPut(data);
    handleCancelModal();
  };

  const handleClick = async () => {
    dispatch(
      modalActions.addModal(MODALS.EDIT_COMPANY_CONFIG, {
        title: t('general.edit') || '',
        onSubmit: handleSubmitModal,
        onCancel: handleCancelModal,
        data: companyConfig,
      }),
    );
  };

  return companyConfig?.data ? (
    <div>
      <div className="customer-details" style={{width: 'unset'}}>
        <div className="center-content">
          <div className="customer-details__header">
            <p>{companyConfig?.data?.companyName}</p>
            <Button variant="contained" className="blue-button" onClick={handleClick}>
              {t('general.edit')}
            </Button>
          </div>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={3}>
              <Card className="custom-card">
                <div className="title">{t('companyConfig.address')}</div>

                <div className="customer-details__data-container__field">
                  <h6>{t('companyConfig.street')}</h6>
                  <p>{companyConfig?.data?.addressDetails?.street}</p>
                </div>

                <div className="customer-details__data-container__field">
                  <h6>{t('companyConfig.city')}</h6>
                  <p>{companyConfig?.data?.addressDetails?.city}</p>
                </div>

                <div className="customer-details__data-container__field">
                  <h6>{t('companyConfig.country')}</h6>
                  <p>{companyConfig?.data?.addressDetails?.country}</p>
                </div>

                <div className="customer-details__data-container__field">
                  <h6>{t('companyConfig.zipCode')}</h6>
                  <p>{companyConfig?.data?.addressDetails?.zipCode}</p>
                </div>
              </Card>
            </Grid>

            <Grid item xs={12} sm={3}>
              <Card className="custom-card">
                <div className="title">{t('companyConfig.bankDetails')}</div>

                <div className="customer-details__data-container__field">
                  <h6>{t('companyConfig.iban')}</h6>
                  <p>{companyConfig?.data?.bankDetails?.iban}</p>
                </div>

                <div className="customer-details__data-container__field">
                  <h6>{t('companyConfig.swiftCode')}</h6>
                  <p>{companyConfig?.data?.bankDetails?.swiftCode}</p>
                </div>

                <div className="customer-details__data-container__field">
                  <h6>{t('companyConfig.localCourt')}</h6>
                  <p>{companyConfig?.data?.bankDetails?.localCourt}</p>
                </div>

                <div className="customer-details__data-container__field">
                  <h6>{t('companyConfig.label')}</h6>
                  <p>{companyConfig?.data?.bankDetails?.label}</p>
                </div>
              </Card>
            </Grid>

            <Grid item xs={12} sm={3}>
              <Card className="custom-card">
                <div className="title">{t('companyConfig.contact')}</div>

                <div className="customer-details__data-container__field">
                  <h6>{t('companyConfig.phone')}</h6>
                  <p>{companyConfig?.data?.contactDetails?.phone}</p>
                </div>

                <div className="customer-details__data-container__field">
                  <h6>{t('companyConfig.fax')}</h6>
                  <p>{companyConfig?.data?.contactDetails?.fax}</p>
                </div>

                <div className="customer-details__data-container__field">
                  <h6>{t('companyConfig.email')}</h6>
                  <p>{companyConfig?.data?.contactDetails?.email}</p>
                </div>

                <div className="customer-details__data-container__field">
                  <h6>{t('companyConfig.web')}</h6>
                  <p>{companyConfig?.data?.contactDetails?.web}</p>
                </div>
              </Card>
            </Grid>

            <Grid item xs={12} sm={3}>
              <Card className="custom-card">
                <div className="title">{t('companyConfig.additionalDetails')}</div>

                <div className="customer-details__data-container__field">
                  <h6>{t('companyConfig.managingDirector')}</h6>
                  <p>{companyConfig?.data?.additionalDetails?.managingDirector}</p>
                </div>

                <div className="customer-details__data-container__field">
                  <h6>{t('companyConfig.salesTaxId')}</h6>
                  <p>{companyConfig?.data?.additionalDetails?.salesTaxId}</p>
                </div>

                <div className="customer-details__data-container__field">
                  <h6>{t('companyConfig.taxNumber')}</h6>
                  <p>{companyConfig?.data?.additionalDetails?.taxNumber}</p>
                </div>
              </Card>
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  ) : null;
};

export default CompanyConfiguration;
