import {Button} from '@material-ui/core';
import {CropFree} from '@material-ui/icons';
import React, {ReactElement} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {modalActions} from '../../../modules/modal/actions';
import {ISupplierOrder} from '../../../types/SupplierOrders/ISupplierOrder';
import {SupplierOrderStatus} from '../../../types/SupplierOrders/SupplierOrderStatus';
import {MODALS} from '../../Modal/ModalContents';

const SupplierOrderScanButton = (props: ISupplierOrderScanButtonProps): ReactElement => {
  const dispatch = useDispatch();
  const {t} = useTranslation();
  const {supplierOrder, getSupplierOrderDetails} = props;

  const handleScanning = async () => {
    dispatch(
      modalActions.addModal(MODALS.SUPPLIER_ORDER_SCANNER_MODAL, {
        onScan: async () => {
          await getSupplierOrderDetails({id: supplierOrder._id});
        },
        supplierOrderId: supplierOrder._id,
        hideDefaultCloseButton: true,
      }),
    );
  };

  return ![SupplierOrderStatus.delivered, SupplierOrderStatus.cancelled].includes(supplierOrder.status) ? (
    <Button
      variant="contained"
      className="blue-button"
      onClick={handleScanning}
      endIcon={<CropFree fontSize="small"></CropFree>}
    >
      {t('scanner.scan')}
    </Button>
  ) : (
    <></>
  );
};

interface ISupplierOrderScanButtonProps {
  supplierOrder: ISupplierOrder;
  getSupplierOrderDetails: (data: any) => void;
}

export default SupplierOrderScanButton;
