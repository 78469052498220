import {Table, TableContainer} from '@material-ui/core';
import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useFetch, useStorlessFetch} from '../../hooks/fetch';
import SupplierOrderItemsTableHeader from '../../components/SupplierOrderItems/SupplierOrderItemsTableHeader';
import SupplierOrderItemsTableFooter from '../../components/SupplierOrderItems/SupplierOrderItemsTableFooter';
import PlaceSupplierOrder from '../../components/SupplierOrderItems/PlaceSupplierOrder';
import SupplierOrderItemsTableBody from '../../components/SupplierOrderItems/SupplierOrderItemsTableBody';
import AddSupplierOrderItemButton from '../../components/SupplierOrderItems/AddSupplierOrderItemButton';
import {SupplierOrderItemsProvider} from '../../components/SupplierOrderItems/Context/SupplierOrderItemsContext';

const SupplierOrderItems = () => {
  const [pendingSupplierOrderItems, getPendingSupplierOrderItems] = useFetch<any>('supplier_pending_order_items');
  const [supplierOrderItems, getSupplierOrderItems] = useStorlessFetch('supplier_order_items');
  const [totalPurchasePrice, getTotalPurchasePrice] = useFetch<any>('purchase_list_total');
  const [selectedSupplier, setSelectedSupplier] = useState(null as any);
  const {t} = useTranslation();
  const [options, setOptions] = useState({status: 'ON_HOLD', supplier: {$ne: null}});
  const [sort, setSort] = useState({key: 'createdAt', value: -1});
  const [filter, setFilter] = useState({} as any);
  const [page, setPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [totalPrice, setTotalPrice] = useState('');
  const defaultParameters = {
    page: page,
    itemsPerPage: itemsPerPage,
    sort: {[sort.key]: sort.value},
    conditions: {...options, ...filter},
  };

  useEffect(() => {
    if (totalPurchasePrice?.data !== null) {
      const price = totalPurchasePrice?.data?.totalPrice ?? 0;
      setTotalPrice(price.toFixed(2));
    }
  }, [totalPurchasePrice]);

  useEffect(() => {
    fetchSupplierOrderItems(defaultParameters);
    getTotalPurchasePrice({filter, options});
    return () => {
      pendingSupplierOrderItems.data = null;
      pendingSupplierOrderItems.error = null;
      pendingSupplierOrderItems.loaded = null;
    };
  }, [options, sort, filter]);

  useEffect(() => {
    if (supplierOrderItems.data) {
      getPendingSupplierOrderItems();
    }
  }, [supplierOrderItems]);

  const fetchSupplierOrderItems = (params: any) => {
    getSupplierOrderItems(params);
  };

  return (
    <div className="orders orders__supplier">
      <SupplierOrderItemsProvider
        value={{
          supplierOrderItems,
          getPendingSupplierOrderItems,
          getTotalPurchasePrice: () => {
            getTotalPurchasePrice({filter, options});
          },
        }}
      >
        <h3>
          <b>{t('supplierOrderItems.screenTitle')}</b>
        </h3>

        <div style={{display: 'flex'}}>
          <span style={{width: '80%'}}>
            <PlaceSupplierOrder
              pendingSupplierOrderItems={pendingSupplierOrderItems}
              setOptions={setOptions}
              selectedSupplier={selectedSupplier}
              setSelectedSupplier={setSelectedSupplier}
            />
          </span>
          <span style={{width: '20%', textAlign: 'right'}}>
            <AddSupplierOrderItemButton fetchSupplierOrderItems={() => fetchSupplierOrderItems(defaultParameters)} />
          </span>
        </div>

        <TableContainer className="table-scroll" style={{height: 'calc(100% - 110px)'}}>
          <Table>
            <SupplierOrderItemsTableHeader filter={filter} setFilter={setFilter} setSort={setSort} sort={sort} />
            <SupplierOrderItemsTableBody />
            <SupplierOrderItemsTableFooter
              fetchSupplierOrderItems={fetchSupplierOrderItems}
              itemsPerPage={itemsPerPage}
              options={{...options, ...filter}}
              page={page}
              setItemsPerPage={setItemsPerPage}
              setPage={setPage}
              sort={sort}
              supplierOrderItems={supplierOrderItems}
              totalPrice={totalPrice}
              selectedSupplier={selectedSupplier}
            />
          </Table>
        </TableContainer>
      </SupplierOrderItemsProvider>
    </div>
  );
};

export default SupplierOrderItems;
