import {Checkbox} from '@material-ui/core';
import React, {ReactElement, useContext} from 'react';
import {useTranslation} from 'react-i18next';
import OrderScanningContext from '../../../screens/Orders/Context/OrderScanningContext';

const DeliveryBillScannerCheckBox = (): ReactElement => {
  const {setBarcode, printDeliveryBill, setPrintDeliveryBill} = useContext(OrderScanningContext);
  const {t} = useTranslation();

  return (
    <span style={{marginRight: '30px'}}>
      <span>{t('scanner.printDeliveryBill')}</span>
      <Checkbox
        color="primary"
        checked={printDeliveryBill}
        onChange={(e) => {
          setPrintDeliveryBill(e.target.checked);
          const inputBarcode = document.getElementById('input-bar-code') as HTMLInputElement;
          if (inputBarcode) {
            inputBarcode.value = '';
            inputBarcode.focus();
            setBarcode('');
          }
        }}
      ></Checkbox>
    </span>
  );
};

export default DeliveryBillScannerCheckBox;
