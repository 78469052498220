import {createAction, ActionsUnion} from '../../lib/actions';
import {Endpoint} from './endpoints';

enum API {
  UPLOAD_START = 'UPLOAD_START',
  FETCH_START = 'FETCH_START_',
  FETCH_SUCCESS = 'FETCH_SUCCESS_',
  FETCH_FAILURE = 'FETCH_FAILURE_',
  REMOVE = 'REMOVE_',
}

const apiActions = {
  upload: (endpoint: Endpoint, data: any = {}) => {
    const actionType = `${API.UPLOAD_START}${endpoint.toUpperCase()}`;
    return createAction(actionType, data);
  },
  fetch: (endpoint: Endpoint, data: any = {}) => {
    const actionType = `${API.FETCH_START}${endpoint.toUpperCase()}`;
    return createAction(actionType, data);
  },
  fetchSuccess: (endpoint: Endpoint, data: any) => {
    const actionType = `${API.FETCH_SUCCESS}${endpoint.toUpperCase()}`;

    return createAction(actionType, data);
  },
  fetchFailure: (endpoint: Endpoint, data: any) => {
    const actionType = `${API.FETCH_FAILURE}${endpoint.toUpperCase()}`;
    return createAction(actionType, data);
  },
  remove: (endpoint: Endpoint) => {
    const actionType = `${API.REMOVE}${endpoint.toUpperCase()}`;
    return createAction(actionType, null);
  },
};

export type ApiAction = ActionsUnion<typeof apiActions>;
export {API, apiActions};
