import {Typography} from '@material-ui/core';
import React, {ReactElement} from 'react';
import Form from '../Form/Form';
import _ from 'lodash';
import {useTranslation} from 'react-i18next';
import * as Yup from 'yup';

const AddProductQuantityRequirementModal = (props: IAddProductQuantityRequirementModal): ReactElement => {
  const {onSubmit, onCancel} = props;
  const {t} = useTranslation();

  const generateInitialValues = (fields: any[]) => {
    let defaults = {};
    fields.forEach((field) => {
      defaults = {...defaults, [field.fieldKey]: ''};
    });
    return defaults;
  };

  const fields = [
    {
      label: t('supplierOrderDetails.quantity'),
      placeholder: t('supplierOrderDetails.quantity'),
      fieldKey: 'quantity',
      type: 'input',
      fieldKeyType: 'number',
      required: true,
    },
  ];

  const initialValues = generateInitialValues(fields);

  const validationSchema = Yup.object().shape({
    quantity: Yup.number().required('Required').nullable(),
  });

  return (
    <>
      <Typography className="modal-title">{t('productDetails.orderProductFromCheapestSupplier')}</Typography>
      <Form
        initialValues={initialValues}
        fields={fields}
        submitButtonText={t('general.submit')}
        cancelButtonText={t('general.cancel')}
        onSubmit={onSubmit}
        onCancel={onCancel}
        validationSchema={validationSchema}
      ></Form>
    </>
  );
};

export interface IAddProductQuantityRequirementModal {
  onSubmit: (values: any) => void;
  onCancel: () => void;
}

export default AddProductQuantityRequirementModal;
