import React from 'react';
import {ShippingProviderType} from '../../../types/Shipment/ShippingProviderType';

interface IProps {
  text: string;
  maxChars: number;
  shippingType?: string;
}

const getMaxValidCharNumber = (maxChars: number, shippingType: string | undefined) => {
  let maxValidChars = maxChars;

  if (shippingType === ShippingProviderType.DHL) {
    maxValidChars = 50;
  } else if (shippingType === ShippingProviderType.GLS) {
    maxValidChars = 40;
  } else {
    maxValidChars = maxChars;
  }

  return maxValidChars;
};

const DescriptionCounter = ({text, maxChars, shippingType}: IProps) => {
  const length = text?.replace(/(<([^>]+)>)/gi, '').length;
  const maxValidChars = getMaxValidCharNumber(maxChars, shippingType);

  return (
    <span
      className={length < maxValidChars ? '' : 'red'}
      style={{minWidth: '60px', marginLeft: '5px', whiteSpace: 'nowrap'}}
    >
      ({text?.replace(/(<([^>]+)>)/gi, '').length} / {maxValidChars})
    </span>
  );
};

export default DescriptionCounter;
