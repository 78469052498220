import React, {useState} from 'react';
import {Button, MenuItem, Select} from '@material-ui/core';
import {useTranslation} from 'react-i18next';
import DataTable from '../../components/Table/Table';
import {useDispatch} from 'react-redux';
import {DataTableItemType} from '../../components/Table/interfaces/IHeaderAccessor';
import {ShippingProviderType} from '../../types/Shipment/ShippingProviderType';
import {modalActions} from '../../modules/modal/actions';
import {MODALS} from '../../components/Modal/ModalContents';

const LabelScanner = () => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const [carrier, setCarrier] = useState('');
  const [refresh, setRefresh] = useState(false);

  const FilterButton = ({carrier, setCarrier}: any) => {
    const {t} = useTranslation();

    const carriers: string[] = Object.values(ShippingProviderType);

    return (
      <span style={{marginLeft: '20px'}}>
        {t('general.carrier')}:&nbsp;
        <Select
          value={carrier}
          onChange={(e) => setCarrier(e.target.value as string)}
          label={t('general.carrier')}
          style={{minWidth: '150px', marginLeft: '10px'}}
        >
          <MenuItem key="all" value="">
            {''}
          </MenuItem>
          {carriers.map((value) => (
            <MenuItem key={value} value={value}>
              {value}
            </MenuItem>
          ))}
        </Select>
      </span>
    );
  };

  const handleScanShippingLabel = () => {
    dispatch(
      modalActions.addModal(MODALS.LABEL_SCANNER, {
        onClose: () => {
          dispatch(modalActions.closeModal());
        },
        refresh: (value: boolean) => {
          setRefresh(value);
        },
        carrier,
      }),
    );
  };

  return (
    <div className="label-scanner">
      <div className="flex header">
        <FilterButton carrier={carrier} setCarrier={setCarrier} />
        {carrier && (
          <Button variant="contained" color="primary" style={{marginLeft: '20px'}} onClick={handleScanShippingLabel}>
            {t('general.scan')}
          </Button>
        )}
      </div>
      <div>
        <DataTable
          headers={[
            {
              kind: 'accessor',
              name: t('general.carrier'),
              accessor: 'carrier',
              sortable: true,
            },
            {
              kind: 'accessor',
              name: t('orderDetails.trackingNumber'),
              accessor: 'trackingNumber',
              sortable: true,
            },
            {
              kind: 'accessor',
              name: t('supplierOrders.scannedAt'),
              accessor: 'createdAt',
              sortable: true,
              type: DataTableItemType.DATE,
            },
          ]}
          title={t('general.scannedLabels')}
          endpoint={'scanned_labels_list'}
          defaultSort={{key: 'createdAt', value: -1}}
          refreshData={refresh}
        />
      </div>
    </div>
  );
};

export default LabelScanner;
