import React from 'react';
import _ from 'lodash';
import {useTranslation} from 'react-i18next';
import {DataTableItemType} from '../Table/interfaces/IHeaderAccessor';
import Form from '../Form/Form';
import {ISupplierComplaint} from '../../types/SupplierOrders/ISupplierComplaint';
import {ShippingProviderType} from '../../types/Shipment/ShippingProviderType';

export interface IEditSupplierComplaintProps {
  title: string;
  onSubmit: (values: any) => void;
  onCancel: () => void;
  orderObject?: ISupplierComplaint;
}

const generateInitialValues = (fields: any[], orderObject: any) => {
  if (orderObject) {
    const defaults = _.omit(orderObject, ['_id', '__v']);
    return defaults;
  } else {
    let defaults = {};
    fields.forEach((field) => {
      if (field.type === DataTableItemType.DATE) {
        defaults = {...defaults, [field.fieldKey]: null};
      } else {
        defaults = {...defaults, [field.fieldKey]: ''};
      }
    });
    return defaults;
  }
};

export default function EditSupplierComplaint({title, onSubmit, onCancel, orderObject}: any) {
  const {t} = useTranslation();
  const fields = [
    {
      label: t('supplierComplaints.shippingProvider'),
      placeholder: t('supplierComplaints.shippingProvider'),
      fieldKey: 'shippingInfo.shippingProvider',
      type: 'select',
      options: Object.values(ShippingProviderType).map((item) => {
        return {value: item, label: item};
      }),
    },
    {
      label: t('supplierComplaints.trackingNumber'),
      placeholder: t('supplierComplaints.trackingNumber'),
      fieldKey: 'shippingInfo.trackingNumber',
      type: 'input',
    },
    {
      label: t('supplierComplaints.invoiceNumber'),
      placeholder: t('supplierComplaints.invoiceNumber'),
      fieldKey: 'invoice.invoiceNumber',
      type: 'input',
    },
    {
      label: t('supplierComplaints.invoiceDate'),
      placeholder: t('supplierComplaints.invoiceDate'),
      fieldKey: 'invoice.date',
      type: DataTableItemType.DATE,
    },
    {
      label: t('supplierComplaints.paidAt'),
      placeholder: t('supplierComplaints.paidAt'),
      fieldKey: 'invoice.paidAt',
      type: DataTableItemType.DATE,
    },
    {
      label: t('supplierComplaints.note'),
      placeholder: t('supplierComplaints.note'),
      fieldKey: 'note',
      type: 'input',
    },
  ];
  const initialValues = generateInitialValues(fields, orderObject);

  return (
    <Form
      title={title}
      initialValues={initialValues}
      fields={fields}
      submitButtonText={t('general.submit')}
      cancelButtonText={t('general.cancel')}
      onSubmit={onSubmit}
      onCancel={onCancel}
    ></Form>
  );
}
