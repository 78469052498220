import Form from '../../components/Form/Form';
import React, {ReactElement, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useStorlessFetch} from '../../hooks/fetch';
import {IAmazonConfig} from '../../types/AmazonSubmission/IAmazonConfig';
import _ from 'lodash';
import {useDispatch} from 'react-redux';
import {appActions} from '../../modules/app/actions';

const AmazonStockSkus = (): ReactElement => {
  const [amazonConfigResponse, getAmazonConfig] = useStorlessFetch('feature_configuration');
  const [updateAmazonConfigResponse, updateAmazonConfig] = useStorlessFetch('feature_configuration_patch_put');
  const [amazonConfig, setAmazonConfig] = useState<IAmazonConfig>(null as any);
  const dispatch = useDispatch();
  const {t} = useTranslation();

  useEffect(() => {
    getAmazonConfig({featureName: 'amazonConfig'});
  }, []);

  useEffect(() => {
    if (amazonConfigResponse.data) {
      setAmazonConfig(amazonConfigResponse.data);
    }
  }, [amazonConfigResponse]);

  useEffect(() => {
    if (updateAmazonConfigResponse.data && !updateAmazonConfigResponse.error) {
      dispatch(appActions.showSnackBar({text: t('dhlConfiguration.configUpdated'), options: {severity: 'success'}}));
    } else if (updateAmazonConfigResponse.error) {
      dispatch(appActions.showSnackBar({text: updateAmazonConfigResponse.error.name, options: {severity: 'error'}}));
    }
  }, [updateAmazonConfigResponse]);

  const onSubmit = async (values: any) => {
    const data = {id: amazonConfig._id, 'data.stockSkus': values.data.stockSkus};
    updateAmazonConfig(data);
  };

  const fields = [
    {
      label: t('newProductForm.skus'),
      placeholder: t('newProductForm.skus'),
      fieldKey: 'data.stockSkus',
      type: 'chipsInput',
    },
  ];

  return amazonConfig ? (
    <div className="product-details">
      <div className="center-content">
        <Form
          title={t('general.amazonStockSkus')}
          initialValues={_.pick(amazonConfig, 'data.stockSkus')}
          fields={fields}
          submitButtonText={t('general.submit')}
          cancelButtonText={t('general.cancel')}
          onSubmit={onSubmit}
        ></Form>
      </div>
    </div>
  ) : (
    <></>
  );
};

export default AmazonStockSkus;
