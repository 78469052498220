import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import ScanningIndicator from '../../components/Order/ScanningIndicator';
import DataTable from '../../components/Table/Table';
import {ShopType} from '../../types/Orders/ShopType';
import {ShippingProviderType} from '../../types/Shipment/ShippingProviderType';
import {Button, Checkbox} from '@material-ui/core';
import {modalActions} from '../../modules/modal/actions';
import {MODALS} from '../../components/Modal/ModalContents';
import {useDispatch} from 'react-redux';
import {DataTableItemType} from '../../components/Table/interfaces/IHeaderAccessor';
import {useSelection} from '../../hooks/selection';
import {useStorlessFetch} from '../../hooks/fetch';
import {appActions} from '../../modules/app/actions';
import {history} from '../../lib/history';
import useUser from '../../hooks/user';

export interface IOrdersReadyForShipping {
  type?: 'Prime' | 'DDA' | 'SP';
}

const OrdersReadyForShipping = (props: IOrdersReadyForShipping) => {
  const user = useUser() as any;
  const featureName = user?.email ? `customConfig-${user?.email}` : undefined;
  const [setShippingProviderRes, setShippingProvider] = useStorlessFetch('set_shipping_provider');
  const [getConfigRes, getConfig] = useStorlessFetch('custom_config');
  const [updateConfigRes, updateConfig] = useStorlessFetch('feature_configuration_patch_put');
  const selectionKey = 'SELECTED_ORDER';
  const [selectedValues, setSelectedValues] = useSelection(selectionKey);
  const [jumpToNextOrder, setJumpToNextOrder] = useState(false);
  const {t} = useTranslation();
  const {type} = props;
  const types = {
    Prime: {
      options: {isPrime: true},
      label: 'Prime ',
    },
    DDA: {
      options: {shopName: ShopType.DRUCKDICHAUS},
      label: 'DDA ',
    },
    SP: {
      options: {shopName: ShopType.SUPPLIES_PARTNER},
      label: 'Supplies Partner ',
    },
    EBAY: {
      options: {shopName: ShopType.EBAY},
      label: 'eBay ',
    },
    DHL: {
      options: {shippingType: ShippingProviderType.DHL},
      label: 'DHL ',
    },
    GLS: {
      options: {shippingType: ShippingProviderType.GLS},
      label: 'GLS ',
    },
    UPS: {
      options: {shippingType: ShippingProviderType.UPS},
      label: 'UPS ',
    },
  };
  const activeType = type ? types[type] : null;
  const dispatch = useDispatch();

  const handleChangingShippingProvider = () => {
    dispatch(
      modalActions.addModal(MODALS.SHIPPING_PROVIDER_MODAL, {
        onCancel: () => {
          dispatch(modalActions.closeModal());
        },
        onSubmit: (fields: any) => {
          setShippingProvider({orders: selectedValues, shippingType: fields?.shippingType});
        },
      }),
    );
  };

  useEffect(() => {
    getConfig({featureName});
  }, []);

  useEffect(() => {
    if (getConfigRes.data) {
      setJumpToNextOrder(getConfigRes.data.data.printerConfig.jumpToNextOrder);
    }
  }, [getConfigRes]);

  useEffect(() => {
    if (setShippingProviderRes.data) {
      dispatch(appActions.showSnackBar({text: t('general.success'), options: {severity: 'success'}}));
      setSelectedValues([]);
    } else if (setShippingProviderRes?.data) {
      dispatch(
        appActions.showSnackBar({text: setShippingProviderRes?.error?.name || 'Error', options: {severity: 'error'}}),
      );
    }

    return () => {
      setShippingProviderRes.data = null;
      setShippingProviderRes.error = null;
    };
  }, [setShippingProviderRes]);

  useEffect(() => {
    if (updateConfigRes.data) {
      dispatch(appActions.showSnackBar({text: t('general.success'), options: {severity: 'success'}}));
    } else if (updateConfigRes.error) {
      dispatch(appActions.showSnackBar({text: updateConfigRes.error?.name, options: {severity: 'error'}}));
    }

    return () => {
      updateConfigRes.data = null;
      updateConfigRes.error = null;
    };
  }, [updateConfigRes]);

  const handleScanProduct = () => {
    dispatch(
      modalActions.addModal(MODALS.PRODUCT_SCANNER, {
        onClose: () => {
          dispatch(modalActions.closeModal());
        },
        history,
      }),
    );
  };

  return (
    <div className="orders">
      <DataTable
        className="orders__table"
        headers={[
          {
            kind: 'accessor',
            name: t('orders.orderNumber'),
            accessor: 'orderNumber',
            sortable: true,
          },
          {
            kind: 'accessor',
            name: t('productDetails.product'),
            accessor: 'firstOrderItemTitle',
            sortable: true,
          },
          {
            kind: 'accessor',
            name: t('orders.shippingType'),
            accessor: 'shippingType',
            sortable: true,
            type: DataTableItemType.SELECT,
            options: ([{value: 'all', label: 'All'}] as any).concat(
              Object.values(ShippingProviderType).map((item) => {
                return {value: item, label: item};
              }),
            ),
          },
          {
            kind: 'accessor',
            name: t('orders.shopName'),
            accessor: 'shopName',
            sortable: true,
            type: DataTableItemType.SELECT,
            options: ([{value: 'all', label: 'All'}] as any).concat(
              Object.values(ShopType).map((item) => {
                return {value: item, label: item};
              }),
            ),
          },
          {
            kind: 'accessor',
            name: t('orders.date'),
            accessor: 'date',
            sortable: true,
            type: DataTableItemType.DATE,
            timeZone: 'Europe/Berlin',
          },
          {
            kind: 'accessor',
            name: t('orderDetails.paymentMethodName'),
            accessor: 'paymentMethodName',
            sortable: true,
          },
          {
            kind: 'accessor',
            name: t('orderDetails.orderTotalSum'),
            accessor: 'orderTotalSum',
            sortable: true,
            type: 'number',
          },
          {
            kind: 'accessor',
            name: t('orders.customerNumber'),
            accessor: 'customerNumber',
            sortable: true,
          },
          {
            kind: 'accessor',
            name: t('orderDetails.customerName'),
            accessor: 'shippingAddress.customerName',
            sortable: true,
          },
          {
            kind: 'component',
            name: t('scanner.somebodyScanning'),
            component: ScanningIndicator,
          },
        ]}
        endpoint={'orders_ready_for_shipping'}
        target={'orders'}
        defaultSort={{key: 'date', value: 1}}
        queryParams={`scanOrder=1${type !== undefined ? `&type=${type}` : ''}`}
        options={activeType?.options || {}}
        title={`${activeType ? activeType.label : ''}  ${t('general.readyForShipping')}`}
        canShowCheckbox={true}
        selectionKey={selectionKey}
        refreshData={setShippingProviderRes}
        actionButtons={
          <>
            <Button className="blue-button" onClick={handleScanProduct}>
              {t('scanner.scanProduct')}
            </Button>
            <Button className="blue-button" onClick={handleChangingShippingProvider}>
              {t('orders.setShippingProvider')}
            </Button>
            <>
              <span className="order-info-label" style={{marginLeft: '10px'}}>
                {t('customConfig.jumpToNextOrder')}
              </span>
              <Checkbox
                color="primary"
                checked={jumpToNextOrder}
                onChange={(event) => {
                  setJumpToNextOrder(event.target.checked);
                  updateConfig({
                    id: getConfigRes?.data?._id,
                    'data.printerConfig.jumpToNextOrder': event.target.checked,
                  });
                }}
              ></Checkbox>
            </>
          </>
        }
      />
    </div>
  );
};

export default OrdersReadyForShipping;
