import {Button} from '@material-ui/core';
import React, {ReactElement, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {modalActions} from '../../modules/modal/actions';
import {MODALS} from '../Modal/ModalContents';
import {useFetch} from '../../hooks/fetch';
import {appActions} from '../../modules/app/actions';
import {history} from '../../lib/history';

const FinishManuallyCompletedOrderButton = (props: IFinishManuallyCompletedOrderButton): ReactElement => {
  const [finishOrderResponse, doFinishOrder] = useFetch<any>('finish_manually_completed_order');
  const {orderId} = props;
  const dispatch = useDispatch();
  const {t} = useTranslation();

  const handleCancelModal = () => {
    dispatch(modalActions.closeModal());
  };

  const handleSubmitModal = async (values: any) => {
    doFinishOrder({id: orderId, supplierId: values.supplier});
    handleCancelModal();
  };

  useEffect(() => {
    if (finishOrderResponse?.error) {
      dispatch(appActions.showSnackBar({text: finishOrderResponse.error.name, options: {severity: 'error'}}));
    } else if (finishOrderResponse?.data) {
      dispatch(appActions.showSnackBar({text: t('general.success'), options: {severity: 'success'}}));
      history.push('/manually-completed-orders');
    }

    return () => {
      finishOrderResponse.data = null;
      finishOrderResponse.error = null;
      finishOrderResponse.loaded = null;
    };
  }, [finishOrderResponse]);

  const handleClick = async () => {
    dispatch(
      modalActions.addModal(MODALS.FINISH_ORDER, {
        onSubmit: handleSubmitModal,
        onCancel: handleCancelModal,
      }),
    );
  };

  return (
    <Button variant="contained" className="blue-button" onClick={handleClick}>
      {t('orders.finishOrder')}
    </Button>
  );
};

interface IFinishManuallyCompletedOrderButton {
  orderId: string;
}

export default FinishManuallyCompletedOrderButton;
