import {ExpandLess, ExpandMore} from '@material-ui/icons';
import React from 'react';

const isColumnSorted = (key: string, sort: any) => {
  return sort.key === key ? sort.value : null;
};

interface IProps {
  accessor: string;
  sort: any;
}

const SortIcon = ({accessor, sort}: IProps) => {
  return (
    isColumnSorted(accessor, sort) && (
      <>
        {sort.value === -1 ? (
          <ExpandLess fontSize="small" className={'active-sort less'} />
        ) : (
          <ExpandMore fontSize="small" className={'active-sort more'} />
        )}
      </>
    )
  );
};

export default SortIcon;
