import React, {useEffect, useRef, useState} from 'react';
import {useQuery} from 'react-query';
import {Button, Grid, MenuItem, Select, TextField, Typography} from '@material-ui/core';
import {api} from '../../../modules/api/ApiFactory';
import {useStorlessFetch, useStorlessUpload} from '../../../hooks/fetch';
import {useTranslation} from 'react-i18next';
import DataTable from '../../../components/Table/Table';
import {DataTableItemType} from '../../../components/Table/interfaces/IHeaderAccessor';
import {useDispatch} from 'react-redux';
import {modalActions} from '../../../modules/modal/actions';
import {MODALS} from '../../../components/Modal/ModalContents';
import {appActions} from '../../../modules/app/actions';

const fetchManufacturers = async () => {
  const response = await api.fetch('product_manufacturers', {});
  return response.data;
};

export default function ManufacturerImages() {
  const {data: manufacturers = [], isLoading, error, refetch} = useQuery<string[]>('manufacturers', fetchManufacturers);
  const [deleteItemRes, deleteItem] = useStorlessFetch('manufacturer_image_delete');
  const [patchManufacturerImageRes, patchManufacturerImage] = useStorlessFetch('manufacturer_image_patch');
  const [selectedManufacturer, setSelectedManufacturer] = useState<string>('');
  const [image, setImage] = useState<File | null>(null);
  const [uploadFile, doUploadFile] = useStorlessUpload('manufacturer_image_upload');
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const {t} = useTranslation();
  const [refresh, setRefresh] = useState(false);
  const dispatch = useDispatch();

  const handleManufacturerChange = (event: React.ChangeEvent<{value: unknown}>) => {
    setSelectedManufacturer(event.target.value as string);
  };

  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      setImage(event.target.files[0]);
    }
  };

  const handleUpload = () => {
    if (image && selectedManufacturer) {
      const newName = `${selectedManufacturer.toLowerCase()}.${image.name.split('.').pop()}`;
      const fileForUpload = new File([image], newName, {type: image.type});
      doUploadFile({name: selectedManufacturer.toLowerCase(), file: fileForUpload});
      if (fileInputRef.current) {
        fileInputRef.current.value = '';
      }
    }
  };

  const onDelete = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, item: any): void => {
    e.preventDefault();

    dispatch(
      modalActions.addChild(MODALS.CONFIRM_DIALOG, {
        title: t('general.warning'),
        content: t('general.areYouSure'),
        onYes: () => {
          deleteItem({id: item._id});
          dispatch(modalActions.closeModal());
        },
        onNo: () => dispatch(modalActions.closeModal()),
      }),
    );
  };

  const handleCancelModal = () => {
    dispatch(modalActions.closeModal());
  };

  const handleSubmitModal = async (values: any) => {
    patchManufacturerImage(values);
    handleCancelModal();
  };

  const onEdit = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, item: any): void => {
    e.preventDefault();
    dispatch(
      modalActions.addModal(MODALS.MANUFACTURER_IMAGES, {
        item,
        onSubmit: handleSubmitModal,
        onCancel: handleCancelModal,
      }),
    );
  };

  useEffect(() => {
    setRefresh((prevState) => !prevState);
    return () => {
      patchManufacturerImageRes.data = null;
      patchManufacturerImageRes.error = null;
    };
  }, [patchManufacturerImageRes.data]);

  useEffect(() => {
    if (uploadFile.data) {
      setRefresh((prevState) => !prevState);
      setImage(null);
    }

    return () => {
      uploadFile.data = null;
      uploadFile.loaded = null;
      uploadFile.error = null;
    };
  }, [uploadFile]);

  useEffect(() => {
    if (deleteItemRes?.data) {
      setRefresh((prevState) => !prevState);
      refetch();
      dispatch(appActions.showSnackBar({text: t('general.submitFormText'), options: {severity: 'success'}}));
    } else if (deleteItemRes?.error) {
      dispatch(appActions.showSnackBar({text: deleteItemRes.error.name, options: {severity: 'error'}}));
    }

    return () => {
      deleteItemRes.data = null;
      deleteItemRes.error = null;
    };
  }, [deleteItemRes]);

  if (isLoading) {
    return <Typography>Loading...</Typography>;
  }

  if (error) {
    return <Typography>Error fetching manufacturers</Typography>;
  }

  return (
    <div className="product-details">
      <div className="product-info">
        <Grid container spacing={0} alignItems="center">
          <Grid item xs={6}>
            <div className="product-info-title">{t('general.manufacturerImages')} (eBay)</div>
          </Grid>
          <Grid
            item
            xs={6}
            alignContent="flex-end"
            alignItems="flex-end"
            style={{display: 'inline-block', textAlign: 'right', paddingRight: '7px'}}
          ></Grid>
          <Grid item xs={12} sm={6} className="product-info-item" style={{paddingRight: '10px'}}>
            <span className="product-info-label">{t('productDetails.manufacturer')}</span>

            <Select
              value={selectedManufacturer}
              onChange={handleManufacturerChange}
              style={{marginRight: '5px'}}
              fullWidth
              placeholder={t('productDetails.manufacturer')}
              label={t('productDetails.manufacturer')}
            >
              {manufacturers.map((manufacturer) => (
                <MenuItem key={manufacturer} value={manufacturer}>
                  {manufacturer}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item xs={12} sm={6} className="product-info-item" style={{paddingRight: '10px'}}>
            <span className="product-info-label">{t('customConfig.ddaOfferImage')}</span>

            <TextField onChange={handleImageChange} inputRef={fileInputRef} variant="standard" type="file" />
          </Grid>
          <Grid item xs={12} sm={6} className="product-info-item" style={{paddingRight: '10px'}}></Grid>
          <Grid item xs={12} sm={6} style={{paddingRight: '10px'}}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleUpload}
              className={!image ? 'inactive-button' : 'blue-button'}
              disabled={!image || !selectedManufacturer}
              style={{float: 'right'}}
            >
              {t('general.submit')}
            </Button>
          </Grid>
        </Grid>
      </div>

      <DataTable
        className="orders__table"
        headers={[
          {
            kind: 'accessor',
            name: t('productDetails.manufacturer'),
            accessor: 'manufacturer',
          },
          {
            kind: 'accessor',
            name: t('general.imageUrl'),
            accessor: 'imageUrl',
            type: DataTableItemType.LINK,
          },
          {
            kind: 'accessor',
            name: t('general.companyName'),
            accessor: 'companyName',
          },
          {
            kind: 'accessor',
            name: t('general.street'),
            accessor: 'street',
          },
          {
            kind: 'accessor',
            name: t('general.zip'),
            accessor: 'zip',
          },
          {
            kind: 'accessor',
            name: t('general.city'),
            accessor: 'city',
          },
          {
            kind: 'accessor',
            name: t('general.country'),
            accessor: 'country',
          },
          {
            kind: 'accessor',
            name: t('general.mail'),
            accessor: 'mail',
          },
          {
            kind: 'accessor',
            name: t('general.motherCompany'),
            accessor: 'motherCompany',
          },
          {
            kind: 'accessor',
            name: '',
            accessor: '_id',
            type: DataTableItemType.CALLBACK,
            callback: (item: any) => {
              return (
                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                  <Button variant="contained" color="primary" className="blue-button" onClick={(e) => onEdit(e, item)}>
                    {t('general.edit')}
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    className="blue-button"
                    onClick={(e) => onDelete(e, item)}
                  >
                    {t('general.delete')}
                  </Button>
                </div>
              );
            },
          },
        ]}
        endpoint={'product_manufacturers_list'}
        title={t('general.manufacturerImages')}
        defaultSort={{key: 'manufacturer', value: 1}}
        numberOfItemsPerPage={50}
        refreshData={refresh}
      />
    </div>
  );
}
