import React, {useEffect, useState} from 'react';
import DataTable from '../../components/Table/Table';
import _ from 'lodash';
import {useTranslation} from 'react-i18next';

const ProductsWithCalculatedPrice = () => {
  const {t} = useTranslation();
  return (
    <div className="products">
      <DataTable
        className="products__table"
        headers={[
          {
            kind: 'accessor',
            name: t('products.productId'),
            accessor: 'productId',
            sortable: true,
            type: 'number',
          },
          {
            kind: 'accessor',
            name: t('products.title'),
            accessor: 'title',
            sortable: true,
          },
          {
            kind: 'accessor',
            name: t('productDetails.calculatedPrice'),
            accessor: 'calculatedPrice',
            sortable: true,
            type: 'number',
            format: 'price',
          },
          {
            kind: 'accessor',
            name: t('products.productType'),
            accessor: 'productInformation.productType',
            sortable: true,
          },
          {
            kind: 'accessor',
            name: t('products.manufacturer'),
            accessor: 'productInformation.manufacturer',
            sortable: true,
          },
          {
            kind: 'accessor',
            name: t('products.shopwareId'),
            accessor: 'shopwareId',
            sortable: true,
          },
        ]}
        endpoint={'products_list'}
        target={'products'}
        options={{calculatedPrice: {$gt: 0}}}
        defaultSort={{key: 'productId', value: 1}}
        title={t('products.screenTitle')}
      />
    </div>
  );
};

export default ProductsWithCalculatedPrice;
