import _ from 'lodash';

import {applyMiddleware, combineReducers, compose, createStore} from 'redux';
import createSagaMiddleware from 'redux-saga';
import {appReducer} from '../modules/app/reducer';
import {root} from './saga';
import {routerMiddleware, connectRouter} from 'connected-react-router';
import {history} from './history';
import {apiReducer} from '../modules/api/reducer';
import {modalReducer} from '../modules/modal/reducer';
import {filterReducer} from '../modules/filters/reducer';
import {selectionReducer} from '../modules/selections/reducer';
import showMenu from '../reducers/showMenuReducer';

const composeEnhancers = _.get(window, '__REDUX_DEVTOOLS_EXTENSION_COMPOSE__', compose);

export const sagaMiddleware = createSagaMiddleware();

const createRootReducer = (history?: any) =>
  combineReducers({
    router: connectRouter(history),
    app: appReducer,
    api: apiReducer,
    modal: modalReducer,
    filter: filterReducer,
    selection: selectionReducer,
    showMenu: showMenu,
  });
export type State = typeof createRootReducer;
const routerMiddlewaree = routerMiddleware(history);
const middlewares = [routerMiddlewaree, sagaMiddleware];
const store = createStore(createRootReducer(history), composeEnhancers(applyMiddleware(...middlewares)));

sagaMiddleware.run(root);

export type AppState = ReturnType<typeof createRootReducer>;
export {store};
