export interface IOrderScreen {
  displayedFields: {
    orderNumber: boolean;
    status: boolean;
    orderType: boolean;
    shippingType: boolean;
    customerNumber: boolean;
    'shippingAddress.salutation': boolean;
    'shippingAddress.title': boolean;
    'shippingAddress.customerName': boolean;
    'shippingAddress.firstName': boolean;
    'shippingAddress.lastName': boolean;
    'shippingAddress.company': boolean;
    'shippingAddress.addressLine1': boolean;
    'shippingAddress.addressLine2': boolean;
    'shippingAddress.zip': boolean;
    'shippingAddress.city': boolean;
    'shippingAddress.phone': boolean;
    'shippingAddress.fax': boolean;
    'shippingAddress.email': boolean;
    'shippingAddress.deliveryInstruction': boolean;
    'shippingAddress.state': boolean;
    'shippingAddress.countryCode': boolean;
    'shippingAddress.stateCode': boolean;
    'shippingAddress.retailId': boolean;
    'shippingAddress.postNumber': boolean;
    'shippingAddress.lockerId': boolean;
    'billingAddress.salutation': boolean;
    'billingAddress.title': boolean;
    'billingAddress.customerName': boolean;
    'billingAddress.firstName': boolean;
    'billingAddress.lastName': boolean;
    'billingAddress.company': boolean;
    'billingAddress.addressLine1': boolean;
    'billingAddress.addressLine2': boolean;
    'billingAddress.zip': boolean;
    'billingAddress.city': boolean;
    'billingAddress.phone': boolean;
    'billingAddress.fax': boolean;
    'billingAddress.email': boolean;
    'billingAddress.deliveryInstruction': boolean;
    'billingAddress.state': boolean;
    'billingAddress.countryCode': boolean;
    'billingAddress.stateCode': boolean;
    'easyBillInfo.invoiceNumber': boolean;
    note: boolean;
    paymentMethodName: boolean;
    date: boolean;
    latestShipDate: boolean;
    earliestShipDate: boolean;
    taxId: boolean;
    isTrackingNumberPushed: boolean;
    isTrackingNumberScanned: boolean;
    trackingNumberPushedBeforeScanning: boolean;
    trackingNumber: boolean;
    'returnLabelInfo.trackingNumber': boolean;
    'secondLabelInfo.trackingNumber': boolean;
    comment: boolean;
    externalOrderNumber: boolean;
    amazonOrderId: boolean;
    isPaid: boolean;
    orderTotalNetSum: boolean;
    orderTotalSum: boolean;
    shippingCost: boolean;
    orderTotalPaid: boolean;
    isPrime: boolean;
    fulfillmentChannel: boolean;
    shopName: boolean;
    pricingShop: boolean;
    firstOrderItemTitle: boolean;
    isManuallyCompleted: boolean;
    isManuallyCompletedAndFinished: boolean;
    isPickupOrder: boolean;
    shippingMethodName: boolean;
    shippingTax: boolean;
    vatPercentage: boolean;
    'shopwareCustomFields.destinationUrl': boolean;
    'shopwareCustomFields.googleCloudId': boolean;
    'shopwareCustomFields.referrer': boolean;
    'shopwareCustomFields.internalOrderNumber': boolean;
    'shopwareCustomFields.deliveryNote': boolean;
    manualShippingCost: boolean;
    exchangeOrder: boolean;
    actualShippingCost: boolean;
  };
  displayedCustomFields: {
    [customField: string]: boolean;
  };
}

export const initOrderScreenConfig = () => {
  return {
    displayedFields: {
      orderNumber: true,
      status: true,
      'billingAddress.customerName': true,
      'billingAddress.email': true,
      customerNumber: true,
      note: false,
      paymentMethodName: true,
      date: true,
      latestShipDate: false,
      earliestShipDate: false,
      taxId: false,
      isTrackingNumberPushed: false,
      isTrackingNumberScanned: true,
      trackingNumberPushedBeforeScanning: false,
      trackingNumber: false,
      'returnLabelInfo.trackingNumber': false,
      'secondLabelInfo.trackingNumber': false,
      comment: false,
      externalOrderNumber: true,
      amazonOrderId: false,
      isPaid: true,
      orderTotalNetSum: false,
      orderTotalSum: true,
      shippingCost: false,
      orderTotalPaid: false,
      isPrime: false,
      fulfillmentChannel: false,
      shopName: true,
      pricingShop: false,
      firstOrderItemTitle: false,
      isManuallyCompleted: false,
      isManuallyCompletedAndFinished: false,
      isPickupOrder: false,
      shippingMethodName: false,
      shippingTax: false,
      vatPercentage: false,
      'shopwareCustomFields.destinationUrl': false,
      'shopwareCustomFields.googleCloudId': false,
      'shopwareCustomFields.referrer': false,
      'shopwareCustomFields.internalOrderNumber': false,
      'shopwareCustomFields.deliveryNote': false,
      manualShippingCost: false,
      exchangeOrder: false,
      actualShippingCost: false,
      orderType: false,
      shippingType: true,
      'shippingAddress.salutation': false,
      'shippingAddress.title': false,
      'shippingAddress.customerName': false,
      'shippingAddress.firstName': false,
      'shippingAddress.lastName': false,
      'shippingAddress.company': false,
      'shippingAddress.addressLine1': false,
      'shippingAddress.addressLine2': false,
      'shippingAddress.zip': false,
      'shippingAddress.city': false,
      'shippingAddress.phone': false,
      'shippingAddress.fax': false,
      'shippingAddress.email': false,
      'shippingAddress.deliveryInstruction': false,
      'shippingAddress.state': false,
      'shippingAddress.countryCode': false,
      'shippingAddress.stateCode': false,
      'shippingAddress.retailId': false,
      'shippingAddress.postNumber': false,
      'shippingAddress.lockerId': false,
      'billingAddress.salutation': false,
      'billingAddress.title': false,
      'billingAddress.firstName': false,
      'billingAddress.lastName': false,
      'billingAddress.company': false,
      'billingAddress.addressLine1': false,
      'billingAddress.addressLine2': false,
      'billingAddress.zip': false,
      'billingAddress.city': false,
      'billingAddress.phone': false,
      'billingAddress.fax': false,
      'billingAddress.deliveryInstruction': false,
      'billingAddress.state': false,
      'billingAddress.countryCode': false,
      'billingAddress.stateCode': false,
      'easyBillInfo.invoiceNumber': true,
    },
    displayedCustomFields: {},
  };
};
