import React from 'react';
import {Switch, Route} from 'react-router-dom';

import ForgotPassword from '../screens/Auth/ForgotPassword';
import ForgotPasswordConfirmation from '../screens/Auth/ForgotPasswordConfirmation';
import Login from '../screens/Auth/Login';
import {AUTH_ROUTES} from './routes';

const AuthRouter = () => {
  return (
    <Switch>
      <Route component={Login} path={AUTH_ROUTES.LOGIN} exact />
      <Route component={ForgotPassword} path={AUTH_ROUTES.FORGOT_PASSWORD} exact />
      <Route component={ForgotPasswordConfirmation} path={AUTH_ROUTES.FORGOT_PASSWORD_CONFIRMATION} exact />
    </Switch>
  );
};
export default AuthRouter;
