import React, {useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {useFetch} from '../../hooks/fetch';
import {appActions} from '../../modules/app/actions';
import {Button, IconButton, TableCell} from '@material-ui/core';
import {ISupplierArticle} from '../../types/SupplierArticle/ISupplierArticle';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import {Check, Clear} from '@material-ui/icons';
import _ from 'lodash';
import {modalActions} from '../../modules/modal/actions';
import {MODALS} from '../Modal/ModalContents';

const MatchingSupplierArticleActions: React.FC<IActionsProps> = (props: any) => {
  const [approveSupplierArticle, doApproveSupplierArticle] = useFetch<any>('supplier_article_approve');
  const [rejectSupplierArticle, doRejectSupplierArticle] = useFetch<any>('supplier_article_reject_matching');
  const {row, type} = props;
  const productId: number = row._id;
  const articles: ISupplierArticle[] = row.articles;
  const dispatch = useDispatch();
  const {t} = useTranslation();
  const [handledArticles, setHandledArticles] = useState<string[]>([]);
  const [rejectedId, setRejectedId] = useState<string>('');

  const handleApproveSupplierArticle = (supplierArticle: string) => {
    const data = {product_id: productId, supplierArticle: [supplierArticle], id: supplierArticle};
    setHandledArticles([...handledArticles, supplierArticle]);
    doApproveSupplierArticle(data);
    dispatch(modalActions.closeModal());
  };

  const handleRejectSupplierArticle = (supplierArticle: string) => {
    const data = {id: supplierArticle};
    setHandledArticles([...handledArticles, supplierArticle]);
    setRejectedId(supplierArticle);
    doRejectSupplierArticle(data);
    dispatch(modalActions.closeModal());
  };

  const approve = async (supplierArticle: string) => {
    dispatch(
      modalActions.addModal(MODALS.CONFIRM_DIALOG, {
        title: t('general.areYouSure'),
        content: t('general.areYouSure'),
        onYes: () => handleApproveSupplierArticle(supplierArticle),
        onNo: () => dispatch(modalActions.closeModal()),
      }),
    );
  };

  const reject = async (supplierArticle: string) => {
    dispatch(
      modalActions.addModal(MODALS.CONFIRM_DIALOG, {
        title: t('general.areYouSure'),
        content: t('general.areYouSure'),
        onYes: () => handleRejectSupplierArticle(supplierArticle),
        onNo: () => dispatch(modalActions.closeModal()),
      }),
    );
  };

  useEffect(() => {
    if (approveSupplierArticle?.error) {
      dispatch(appActions.showSnackBar({text: approveSupplierArticle.error.name, options: {severity: 'error'}}));
    } else if (approveSupplierArticle?.data) {
      dispatch(appActions.showSnackBar({text: t('general.success'), options: {severity: 'success'}}));
      dispatch(modalActions.closeModal());
      setHandledArticles([...handledArticles, ...approveSupplierArticle.data]);
    }
    if (rejectSupplierArticle?.error) {
      dispatch(appActions.showSnackBar({text: rejectSupplierArticle.error.name, options: {severity: 'error'}}));
    } else if (rejectSupplierArticle?.data) {
      dispatch(appActions.showSnackBar({text: t('general.success'), options: {severity: 'success'}}));
      dispatch(modalActions.closeModal());
      setHandledArticles([...handledArticles, rejectedId]);
    }

    return () => {
      approveSupplierArticle.data = null;
      approveSupplierArticle.error = null;
      approveSupplierArticle.loaded = null;
      rejectSupplierArticle.data = null;
      rejectSupplierArticle.error = null;
      rejectSupplierArticle.loaded = null;
    };
  }, [approveSupplierArticle, rejectSupplierArticle]);

  return (
    <div>
      <Table>
        <TableBody>
          {articles.map((article, index) => {
            return (
              <TableRow key={index}>
                <TableCell style={{width: '10%', borderBottom: 'none'}}>{article.supplierName}</TableCell>
                <TableCell style={{width: '30%', borderBottom: 'none'}}>{article.title}</TableCell>
                <TableCell style={{width: '15%', borderBottom: 'none'}}>
                  {type}: <span className="ean">{_.get(article, type)}</span>
                </TableCell>
                <TableCell style={{width: '15%', borderBottom: 'none'}}>
                  {t('supplierOrderItems.supplierArticle')}: {article.externalArticleId}
                </TableCell>
                {type === 'ean' && (
                  <TableCell style={{width: '10%', borderBottom: 'none'}}>
                    {t('suppliers.mpn')}: {article.mpn}
                  </TableCell>
                )}
                <TableCell style={{width: '20%', borderBottom: 'none'}}>
                  {!handledArticles.includes(article._id) && (
                    <>
                      <IconButton
                        onClick={(event) => {
                          event.preventDefault();
                          approve(article._id);
                        }}
                        title="Approve"
                      >
                        <Check fontSize="small" style={{fill: 'green'}} />
                      </IconButton>
                      <IconButton
                        onClick={(event) => {
                          event.preventDefault();
                          reject(article._id);
                        }}
                        title="Ignore forever"
                      >
                        <Clear fontSize="small" style={{fill: 'red'}} />
                      </IconButton>
                    </>
                  )}
                </TableCell>
              </TableRow>
            );
          })}
          {articles.length > 1 &&
            articles.every((article) => {
              return !handledArticles.includes(article._id);
            }) && (
              <TableRow style={{border: 'none'}}>
                <TableCell colSpan={4} style={{textAlign: 'right', border: 'none'}}>
                  <Button
                    onClick={(e) => {
                      e.preventDefault();
                      dispatch(
                        modalActions.addModal(MODALS.CONFIRM_DIALOG, {
                          title: '',
                          content: t('general.areYouSure'),
                          onYes: () => {
                            const data = {
                              product_id: productId,
                              supplierArticle: articles.map((article) => article._id),
                              id: articles[0]._id,
                            };
                            doApproveSupplierArticle(data);
                          },
                          onNo: () => dispatch(modalActions.closeModal()),
                        }),
                      );
                    }}
                  >
                    {t('general.aproveall')}
                  </Button>
                </TableCell>
              </TableRow>
            )}
        </TableBody>
      </Table>
    </div>
  );
};

interface IActionsProps {
  id: string;
  productId: number;
}

export default MatchingSupplierArticleActions;
