import React, {useEffect, useState} from 'react';
import {createRoot} from 'react-dom/client';
import './index.css';
import App from './App';
import {Provider} from 'react-redux';
import {store} from './lib/store';
import {ThemeProvider} from '@material-ui/core';
import {defaultTheme} from './styles/default/muiDefault';
import {darkTheme} from './styles/default/darkTheme';
import {storage} from './lib/storage';
import {TodoSocketListDto} from './types/Todo/TodoSocketList';
import moment from 'moment';
import {useUserId} from './hooks/userId';
import {fetchTodoInformation} from './hooks/todoSocketActions';
import {QueryClient, QueryClientProvider} from 'react-query';

const container: any = document.getElementById('root');
const root = createRoot(container);

export const ThemeContext = React.createContext<ThemeContextType>({
  toggleTheme: undefined,
  todoSocketLists: undefined,
  todoSocketInformation: undefined,
  setTodoSocketInformation: undefined,
  fetchTodoSocketInformation: undefined,
});

interface ThemeContextType {
  toggleTheme?: () => void;
  todoSocketLists?: string;
  todoSocketInformation?: any;
  setTodoSocketInformation?: any;
  fetchTodoSocketInformation?: any;
}

const RootComponent = () => {
  const {userId} = useUserId();
  const [theme, setTheme] = useState(localStorage.getItem('theme') || 'light');
  const [todoSocketInformation, setTodoSocketInformation] = useState(localStorage.getItem('todoSocket') as any);

  const onTodoUpdate = async (todoSocket: TodoSocketListDto) => {
    const updatedElement = {
      lists: todoSocket.lists,
      hasOverdueTasks: todoSocket.hasOverdueTasks,
      hasTasks: todoSocket.hasTasks,
      earliestDueTaskDate: todoSocket.earliestDueTaskDate,
    };

    window.dispatchEvent(new CustomEvent('todoSocketUpdate', {detail: JSON.stringify(updatedElement)}));
  };

  const toggleTheme = () => {
    const newTheme = theme === 'light' ? 'dark' : 'light';
    setTheme(newTheme);
    localStorage.setItem('theme', newTheme);
    document.body.className = newTheme;
  };

  useEffect(() => {
    document.body.className = theme;
  }, [theme]);

  useEffect(() => {
    const handleTodoSocketUpdate = (e: any) => {
      const currentTodoSocket = localStorage.getItem('todoSocket');
      if (e.detail !== currentTodoSocket) {
        storage.writeValue('todoSocket', e.detail);
        setTodoSocketInformation(e.detail);
      }
    };

    window.addEventListener('todoSocketUpdate', handleTodoSocketUpdate);

    return () => {
      window.removeEventListener('todoSocketUpdate', handleTodoSocketUpdate);
    };
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      const currentTodoSocket = localStorage.getItem('todoSocket');

      if (currentTodoSocket) {
        const todoSocketJSON: TodoSocketListDto = JSON.parse(currentTodoSocket);
        if (!todoSocketJSON.earliestDueTaskDate) return;

        const now = moment();

        if (now.isAfter(moment(todoSocketJSON.earliestDueTaskDate))) {
          fetchTodoInformation(userId, onTodoUpdate);
        }
      }
    }, 60000);

    return () => clearInterval(interval);
  }, [userId]);

  const appliedTheme = theme === 'light' ? defaultTheme : darkTheme;

  const fetchTodoSocketInformation = async (userId: string) => {
    fetchTodoInformation(userId, onTodoUpdate);
  };
  const queryClient = new QueryClient();

  return (
    <ThemeProvider theme={appliedTheme}>
      <ThemeContext.Provider
        value={{
          toggleTheme,
          todoSocketLists: todoSocketInformation,
          todoSocketInformation,
          setTodoSocketInformation,
          fetchTodoSocketInformation,
        }}
      >
        <Provider store={store}>
          <QueryClientProvider client={queryClient}>
            <App />
          </QueryClientProvider>
        </Provider>
      </ThemeContext.Provider>
    </ThemeProvider>
  );
};

root.render(<RootComponent />);
