import _ from 'lodash';
import React from 'react';
import {useSelector} from 'react-redux';

import {BaseModalState} from '../../modules/modal/reducer';
import {selectRightDrawer, selectModal, selectLeftDrawer} from '../../modules/modal/selectors';
import {ComponentWithCliclListener} from '../HigherOrder/higherOrder';
import DrawerBase from './DrawerBase';
import {ModalBase} from './ModalBase';
import {MODALS} from './ModalContents';

const Modal = () => {
  const modalData = useSelector(selectModal);
  const rightDrawerData = useSelector(selectRightDrawer);
  const leftDrawerData = useSelector(selectLeftDrawer);
  const modalChildren: BaseModalState[] = _.get(modalData, 'children', []);
  const DrawerWithListener = ComponentWithCliclListener(DrawerBase);

  return (
    <>
      <ModalBase modalData={modalData} />
      {modalChildren.map((child) => (
        <ModalBase key={child.modalKey} child modalData={child} />
      ))}
      {rightDrawerData.modalKey !== MODALS.NONE && (
        <DrawerWithListener modalData={rightDrawerData} orientation={'RIGHT'}></DrawerWithListener>
      )}
      {leftDrawerData.modalKey !== MODALS.NONE && <DrawerWithListener modalData={leftDrawerData} orientation="LEFT" />}
    </>
  );
};

export {Modal};
