import React from 'react';
import Form from '../Form/Form';
import _ from 'lodash';
import {useTranslation} from 'react-i18next';
import {ICustomFieldType} from '../../types/CustomFields/CustomField';
import * as Yup from 'yup';

export interface ICreateCustomFieldProps {
  title: string;
  onSubmit: (values: any) => void;
  onCancel: () => void;
  entityType: string;
}

const generateInitialValues = (fields: any[], entityType: string) => {
  let defaults = {};
  fields.forEach((field) => {
    defaults = {...defaults, [field.fieldKey]: ''};
  });
  return {...defaults, entityType};
};

const CreateCustomField = (props: ICreateCustomFieldProps) => {
  const {title, onSubmit, onCancel, entityType} = props;
  const {t} = useTranslation();

  const fields = [
    {
      label: t('customFields.fieldName'),
      placeholder: t('customFields.fieldName'),
      fieldKey: 'fieldName',
      type: 'input',
    },
    {
      label: t('customFields.fieldType'),
      placeholder: t('customFields.fieldType'),
      fieldKey: 'fieldType',
      type: 'select',
      options: Object.values(ICustomFieldType).map((item) => {
        return {value: item, label: t(`customFields.${item}`) || ''};
      }),
    },
  ];
  const initialValues = generateInitialValues(fields, entityType);
  const validationSchema = Yup.object().shape({
    fieldName: Yup.string().required('Required'),
    fieldType: Yup.string().required('Required'),
  });

  return (
    <Form
      title={title}
      initialValues={initialValues}
      fields={fields}
      submitButtonText={t('general.submit')}
      cancelButtonText={t('general.cancel')}
      onSubmit={onSubmit}
      onCancel={onCancel}
      validationSchema={validationSchema}
    ></Form>
  );
};

export default CreateCustomField;
