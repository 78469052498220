import _ from 'lodash';
import {useDispatch, useSelector} from 'react-redux';
import {selectionActions} from '../modules/selections/actions';

export const useSelection = (filterKey: any) => {
  const dispatch = useDispatch();
  const data =
    useSelector((state) => _.get(state, 'selection.selections').find((filter: any) => filter.key === filterKey))
      ?.values ?? [];
  const onChange = (inputValues: any[]) => {
    dispatch(selectionActions.selectionValues({key: filterKey, values: inputValues}));
  };
  return [data, onChange];
};
