import {Button, Input} from '@material-ui/core';
import React, {ReactElement, useContext, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {useStorlessFetch} from '../../hooks/fetch';
import {appActions} from '../../modules/app/actions';
import moment from 'moment';
import OrderScanningContext from '../../screens/Orders/Context/OrderScanningContext';

const ConfirmStockButton = (): ReactElement => {
  const {product} = useContext(OrderScanningContext);
  const dispatch = useDispatch();
  const [stock, setStock] = useState<number>(+product.stockDDA as number);
  const {t} = useTranslation();
  const [confirmStockResponse, confirmStock] = useStorlessFetch('confirm_stock_for_product');
  const [lastInventoryDate, setLastInventoryDate] = useState(moment(product.latestInventoryDate));

  const shouldShowConfirmStockButton = () => {
    const now = moment(new Date());
    const twoWeeksAgo = now.startOf('day').subtract(14, 'days');

    return lastInventoryDate.isBefore(twoWeeksAgo);
  };

  useEffect(() => {
    setLastInventoryDate(moment(product.latestInventoryDate));
    setStock(+product.stockDDA);
  }, [product.productId]);

  useEffect(() => {
    setLastInventoryDate(moment(product.latestInventoryDate));
  }, [product.latestInventoryDate]);

  useEffect(() => {
    if (confirmStockResponse.error) {
      dispatch(appActions.showSnackBar({text: t('activatedArticles.failedSyncMessage'), options: {severity: 'error'}}));
    }

    if (confirmStockResponse.data && confirmStockResponse.loaded && !confirmStockResponse.loading) {
      dispatch(appActions.showSnackBar({text: t('general.success'), options: {severity: 'success'}}));
      setLastInventoryDate(moment(new Date()));
    }

    return () => {
      confirmStockResponse.data = null;
      confirmStockResponse.loaded = null;
      confirmStockResponse.loading = null;
      confirmStockResponse.error = null;
    };
  }, [confirmStockResponse]);

  const confirmStockForProduct = async (withChanges = false) => {
    if (confirm(t('general.areYouSure'))) {
      const quantity = withChanges ? stock : product.stockDDA;
      confirmStock({id: product.id, quantity: quantity});
    }
  };

  return shouldShowConfirmStockButton() ? (
    <>
      <div>{t('productDetails.stockCorrect')}:</div>
      <div className="flex-center">
        <Button variant="contained" className="blue-button" onClick={() => confirmStockForProduct(false)}>
          {t('general.yes')}
        </Button>
        <span style={{marginLeft: '15px'}}>{(t('general.no'), t('inventoryProducts.actualStockIs'))}:</span>
        <Input
          style={{marginLeft: '15px', width: '50px'}}
          type="number"
          value={stock}
          onChange={(e) => {
            setStock(+e.target.value);
          }}
        />
        <Button variant="contained" className="blue-button" onClick={() => confirmStockForProduct(true)}>
          {t('inventoryProducts.confirmStock')}
        </Button>
      </div>
    </>
  ) : (
    <></>
  );
};

export default ConfirmStockButton;
