import React from 'react';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import {makeStyles} from '@material-ui/core/styles';

const Spinner = () => {
  const useStyles = makeStyles((theme) => ({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#5cabef',
    },
  }));

  const classes = useStyles();

  return (
    <>
      <Backdrop className={classes.backdrop} open style={{textAlign: 'center'}}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};

export default Spinner;
