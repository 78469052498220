import DataTable from '../Table/Table';
import React, {ReactElement} from 'react';
import {OrderStatusType} from '../../types/Orders/OrderStatusType';
import {ShippingProviderType} from '../../types/Shipment/ShippingProviderType';
import {useTranslation} from 'react-i18next';
import {ShopType} from '../../types/Orders/ShopType';
import {DataTableItemType} from '../Table/interfaces/IHeaderAccessor';

const ProductOrdersTable = (props: IProductOrdersTableProps): ReactElement => {
  const {t} = useTranslation();

  return (
    <DataTable
      className="orders__table"
      headers={[
        {
          kind: 'accessor',
          name: t('orders.orderNumber'),
          accessor: 'orderNumber',
          sortable: true,
        },
        {
          kind: 'accessor',
          name: t('orders.status'),
          accessor: 'status',
          sortable: true,
          type: DataTableItemType.MULTIPLE_SELECT,
          options: ([{value: 'all', label: 'All'}] as any).concat(
            Object.values(OrderStatusType).map((item) => {
              return {value: item, label: item};
            }),
          ),
        },
        {
          kind: 'accessor',
          name: t('orderDetails.isTrackingNumberScanned'),
          accessor: 'isTrackingNumberScanned',
          sortable: true,
          type: DataTableItemType.BOOLEAN,
        },
        {
          kind: 'accessor',
          name: t('orders.shippingType'),
          accessor: 'shippingType',
          sortable: true,
          type: DataTableItemType.SELECT,
          options: ([{value: 'all', label: 'All'}] as any).concat(
            Object.values(ShippingProviderType).map((item) => {
              return {value: item, label: item};
            }),
          ),
        },
        {
          kind: 'accessor',
          name: t('orders.shopName'),
          accessor: 'shopName',
          sortable: true,
          type: DataTableItemType.SELECT,
          options: ([{value: 'all', label: 'All'}] as any).concat(
            Object.values(ShopType).map((item) => {
              return {value: item, label: item};
            }),
          ),
        },
        {
          kind: 'accessor',
          name: t('orders.date'),
          accessor: 'date',
          sortable: true,
          type: DataTableItemType.DATE,
          timeZone: 'Europe/Berlin',
        },
        {
          kind: 'accessor',
          name: t('orderDetails.paymentMethodName'),
          accessor: 'paymentMethodName',
          sortable: true,
        },
        {
          kind: 'accessor',
          name: t('orderDetails.quantity'),
          accessor: 'quantity',
          sortable: true,
          type: 'number',
        },
        {
          kind: 'accessor',
          name: t('orderItems.priceNet'),
          accessor: 'priceNet',
          sortable: true,
          type: 'number',
        },
        {
          kind: 'accessor',
          name: t('orders.customerNumber'),
          accessor: 'customerNumber',
          sortable: true,
        },
        {
          kind: 'accessor',
          name: t('orderDetails.customerName'),
          accessor: 'billingAddress.customerName',
          optionalAccessor: 'shippingAddress.customerName',
          sortable: true,
        },
        {
          kind: 'accessor',
          name: t('orders.email'),
          accessor: 'billingAddress.email',
          optionalAccessor: 'shippingAddress.email',
          sortable: true,
        },
      ]}
      endpoint={'orders_by_product_id'}
      target={'orders'}
      options={{product: props.productId}}
      defaultSort={{key: 'date', value: -1}}
    />
  );
};

interface IProductOrdersTableProps {
  productId: string;
}

export default ProductOrdersTable;
