import io, {Socket} from 'socket.io-client';
import config from './config';

class SocketFactory {
  socket: Socket;

  constructor(address: string) {
    this.socket = io(address, {withCredentials: false} as any);
  }

  connect() {
    this.socket.connect();
  }

  listenOrderModifying(callback: (orderNumber: string) => any) {
    this.socket.on(EventsName.ORDER_MODIFYING, callback);
  }

  listenOnOrderStatusUpdate(callback: ({isActive, orderNumber}: any) => any) {
    this.socket.on(EventsName.ORDER_STATUS_UPDATE, callback);
  }

  listenStopOrderModifying(callback: (orderNumber: string) => any) {
    this.socket.on(EventsName.STOP_ORDER_MODIFYING, callback);
  }

  emitOrderModifying(orderNumber: string) {
    this.socket.emit(EventsName.ORDER_MODIFYING, orderNumber);
  }

  emitStopOrderModifying(orderNumber: string) {
    this.socket.emit(EventsName.STOP_ORDER_MODIFYING, orderNumber);
  }

  removeEventListener(eventName: string) {
    this.socket.removeListener(eventName);
  }

  emitOrderStatusUpdate(orderNumber: string) {
    this.socket.emit(EventsName.ORDER_STATUS_UPDATE, orderNumber);
  }

  emitFetchTodoInformation(userId: string) {
    this.socket.emit(EventsName.FETCH_TODO_INFORMATION, userId);
  }

  listenFetchTodoInformation(callback: (update: any) => any) {
    this.socket.on(EventsName.FETCH_TODO_INFORMATION, callback);
  }
}

export enum EventsName {
  ORDER_MODIFYING = 'ORDER_MODIFYING',
  STOP_ORDER_MODIFYING = 'STOP_ORDER_MODIFYING',
  ORDER_STATUS_UPDATE = 'ORDER_STATUS_UPDATE',
  FETCH_TODO_INFORMATION = 'FETCH_TODO_INFORMATION',
}

export const socketFactory = new SocketFactory(config.sockets);
