import React from 'react';
import Form from '../Form/Form';
import _ from 'lodash';
import {useTranslation} from 'react-i18next';
import {ShippingProviderType} from '../../types/Shipment/ShippingProviderType';

export interface IShippingProviderModalProps {
  onSubmit: (values: any) => void;
  onCancel: () => void;
}

const generateInitialValues = (fields: any[]) => {
  let defaults = {};
  fields.forEach((field) => {
    defaults = {...defaults, [field.fieldKey]: ''};
  });
  return defaults;
};

const ShippingProviderModal = (props: IShippingProviderModalProps) => {
  const {onSubmit, onCancel} = props;
  const {t} = useTranslation();

  const fields = [
    {
      label: t('orders.shippingType'),
      placeholder: t('orders.shippingType'),
      fieldKey: 'shippingType',
      type: 'select',
      options: ([{value: 'all', label: 'All'}] as any).concat(
        Object.values(ShippingProviderType)
          .map((item) => {
            return {value: item, label: item};
          })
          .filter((item) => item.value !== ShippingProviderType.GLS),
      ),
    },
  ];
  const initialValues = generateInitialValues(fields);

  return (
    <Form
      title={t('orders.setShippingProvider')}
      initialValues={initialValues}
      fields={fields}
      submitButtonText={t('general.submit')}
      cancelButtonText={t('general.cancel')}
      onSubmit={onSubmit}
      onCancel={onCancel}
    ></Form>
  );
};

export default ShippingProviderModal;
