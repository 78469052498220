import React, {ReactElement, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {SupplierOrderItemStatus} from '../../types/SupplierOrders/SupplierOrderItemStatus';
import AddSupplierOrderItemButton from '../SupplierOrderItems/AddSupplierOrderItemButton';
import DataTable from '../Table/Table';
import IncreaseManualNeedQuantityButton from './ProductActions/IncreaseManualNeedQuantityButton';
import RegenerateOrderSuggestionButton from './ProductActions/RegenerateOrderSuggestionButton';
import {DataTableItemType} from '../Table/interfaces/IHeaderAccessor';

const ProductSupplierOrders = (props: IProductSupplierOrdersProps): ReactElement => {
  const {t} = useTranslation();
  const [refreshTableToggle, setRefreshTableToggle] = useState(false);
  const {productId} = props;

  return (
    <DataTable
      className="products__table"
      headers={[
        {
          kind: 'accessor',
          name: t('supplierOrderItems.status'),
          accessor: 'status',
          sortable: true,
          type: DataTableItemType.MULTIPLE_SELECT,
          options: ([{value: 'all', label: 'All'}] as any).concat(
            Object.values(SupplierOrderItemStatus).map((item) => {
              return {value: item, label: item};
            }),
          ),
        },
        {
          kind: 'accessor',
          name: t('supplierOrderItems.orderNumber'),
          accessor: 'supplierOrderNumber',
          sortable: true,
          type: 'number',
        },
        {
          kind: 'accessor',
          name: t('supplierOrderItems.date'),
          accessor: 'dateOrdered',
          sortable: true,
          type: DataTableItemType.DATE,
        },
        {
          kind: 'accessor',
          name: t('supplierOrderItems.supplierName'),
          accessor: 'supplierName',
          sortable: true,
        },
        {
          kind: 'accessor',
          name: t('supplierOrderItems.externalArticleId'),
          accessor: 'externalArticleId',
          sortable: true,
        },
        {
          kind: 'accessor',
          name: t('supplierOrderItems.quantity'),
          accessor: 'quantity',
          sortable: true,
          type: 'number',
        },
        {
          kind: 'accessor',
          name: t('supplierOrderItems.quantityDelivered'),
          accessor: 'quantityDelivered',
          sortable: true,
          type: 'number',
        },
        {
          kind: 'accessor',
          name: t('supplierOrderItems.price'),
          accessor: 'price',
          sortable: true,
          format: 'price',
          type: 'number',
        },
      ]}
      options={{product: productId}}
      endpoint={'supplier_order_items'}
      defaultSort={{key: 'createdAt', value: -1}}
      target="supplierOrders"
      selectRowClickId="supplierOrder._id"
      refreshData={refreshTableToggle}
      actionButtons={
        <>
          <AddSupplierOrderItemButton
            fetchSupplierOrderItems={() => setRefreshTableToggle(!refreshTableToggle)}
            productId={productId}
          />
          <IncreaseManualNeedQuantityButton
            fetchSupplierOrderItems={() => setRefreshTableToggle(!refreshTableToggle)}
            productId={productId}
          />
          <RegenerateOrderSuggestionButton
            fetchSupplierOrderItems={() => setRefreshTableToggle(!refreshTableToggle)}
            productId={productId}
          />
        </>
      }
    />
  );
};

interface IProductSupplierOrdersProps {
  productId: any;
}

export default ProductSupplierOrders;
