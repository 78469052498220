import React, {useEffect, useState} from 'react';
import DataTable from '../../components/Table/Table';
import {useTranslation} from 'react-i18next';
import MatchingSupplierArticleActions from '../../components/SupplierArticle/MatchingSupplierArticleActions';
import {useSelection} from '../../hooks/selection';
import {Button} from '@material-ui/core';
import {useFetch} from '../../hooks/fetch';
import {useDispatch} from 'react-redux';
import {appActions} from '../../modules/app/actions';

const MatchingSupplierArticle = (props: IMatchingSupplierArticleProps) => {
  const {t} = useTranslation();
  const [ignored, setIgnored] = useState<boolean>(false);
  const selectionKey = 'MATCHING_EANS';
  const [selectedValues, setSelectedValues] = useSelection(selectionKey);
  const [tableData, setTableData] = useState<any[]>([]);
  const [ignoredProducts, setIgnoredProducts] = useState<string[]>([]);
  const [approveSelectedResponse, approveSelected] = useFetch<any>('supplier_article_approve_multiple');
  const [ignoreSelectedResponse, ignoreSelected] = useFetch<any>('supplier_article_ignore_multiple');
  const dispatch = useDispatch();

  const dataFilter = (data: any[]) => {
    return data.filter((item) => !ignoredProducts.includes(item._id));
  };

  const getFilteredData = () => {
    return tableData
      .filter((item) => selectedValues.includes(item._id))
      .map((item) => {
        return {
          product_id: item._id,
          supplierArticle: item.articles.map((article: any) => article._id),
        };
      });
  };

  const onApproveAll = () => {
    if (confirm(t('general.areYouSure'))) {
      approveSelected({products: getFilteredData()});
    }
  };

  const onIgnoreAll = () => {
    if (confirm(t('general.areYouSure'))) {
      ignoreSelected({products: getFilteredData()});
    }
  };

  const ApproveAllButton = () => (
    <Button
      variant="contained"
      className="blue-button"
      onClick={() => {
        onApproveAll();
      }}
    >
      {t('newArticles.approveSelected')}
    </Button>
  );

  const IgnoreAllButton = () => (
    <Button
      variant="contained"
      className="blue-button"
      onClick={() => {
        onIgnoreAll();
      }}
    >
      {t('newArticles.ignoreSelected')}
    </Button>
  );

  useEffect(() => {
    if (props.ignored) {
      setIgnored(props.ignored as boolean);
    }
  }, [props.ignored]);

  useEffect(() => {
    if (approveSelectedResponse?.error) {
      dispatch(appActions.showSnackBar({text: approveSelectedResponse.error.name, options: {severity: 'error'}}));
    } else if (approveSelectedResponse?.data) {
      dispatch(appActions.showSnackBar({text: t('general.success'), options: {severity: 'success'}}));
      setIgnoredProducts((prevIgnoredProducts) => [...prevIgnoredProducts, ...selectedValues]);
      setSelectedValues([]);
    }
  }, [approveSelectedResponse]);

  useEffect(() => {
    if (ignoreSelectedResponse?.error) {
      dispatch(appActions.showSnackBar({text: ignoreSelectedResponse.error.name, options: {severity: 'error'}}));
    } else if (ignoreSelectedResponse?.data) {
      dispatch(appActions.showSnackBar({text: t('general.success'), options: {severity: 'success'}}));
      setIgnoredProducts((prevIgnoredProducts) => [...prevIgnoredProducts, ...selectedValues]);
      setSelectedValues([]);
    }
  }, [ignoreSelectedResponse]);

  const additional = props.ignored ? {ignored: true} : {ignored: false};

  return (
    <div className="supplier-articles">
      <DataTable
        className="new-supplier__table"
        headers={[
          {
            kind: 'accessor',
            name: t('products.productId'),
            accessor: 'productId',
            sortable: true,
            type: 'number',
          },
          {
            kind: 'accessor',
            name: t('products.title'),
            accessor: 'title',
            sortable: true,
          },
          {
            kind: 'component',
            name: t('matchingArticles.screenTitle'),
            component: MatchingSupplierArticleActions,
            props: {type: 'ean'},
            onFetchProps: {conditions: {status: 'new'}},
          },
        ]}
        endpoint={'matching_articles'}
        additionalFields={additional}
        options={{}}
        canShowCheckbox={true}
        selectionKey={selectionKey}
        defaultSort={{key: 'productId', value: 1}}
        numberOfItemsPerPage={100}
        target={`products`}
        selectRowClickId={'_id'}
        title={ignored ? t('general.matchingIgnoredEans') : t('matchingArticles.screenTitle')}
        actionButtons={
          <>
            <ApproveAllButton />
            <IgnoreAllButton />
          </>
        }
        dataFilter={dataFilter}
        onDataChange={setTableData}
      />
    </div>
  );
};

export interface IMatchingSupplierArticleProps {
  ignored?: boolean;
}

export default MatchingSupplierArticle;
