export enum EntityType {
  PRODUCT = 'PRODUCT',
  CUSTOMER_ORDER = 'CUSTOMER_ORDER',
  SUPPLIER_ORDER = 'SUPPLIER_ORDER',
  CUSTOMER = 'CUSTOMER',
  SUPPLIER = 'SUPPLIER',
}

export enum ICustomFieldType {
  STRING = 'STRING',
  NUMBER = 'NUMBER',
  DATE = 'DATE',
  BOOLEAN = 'BOOLEAN',
}

export interface ICustomField {
  fieldName: string;
  type: ICustomFieldType;
}

export interface IEntity {
  type: EntityType;
  fields: ICustomField[];
}
