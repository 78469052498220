import React, {createContext} from 'react';
import {IDetailedOrder} from '../../../types/Orders/IDetailedOrder';
import {CountryOption} from '../CreateOrder';

const CreateOrderContext = createContext({} as ICreateOrderContext);
export const CreateOrderProvider = CreateOrderContext.Provider;
export default CreateOrderContext;

interface ICreateOrderContext {
  submitOrderResponse?: any;
  patchOrderResponse?: any;
  order: IDetailedOrder;
  doGetOrder: (data: any) => void;
  setOrder: (data: any) => void;
  updateOrderState: (value: any, fieldName: string, nestedObjectName?: string) => void;
  shippingAddresses?: any;
  countryOptions?: CountryOption[];
}
