import React from 'react';
import {IconButton} from '@material-ui/core';
import {useTranslation} from 'react-i18next';
import KeyboardReturn from '@material-ui/icons/Directions';
import {useDispatch} from 'react-redux';
import {modalActions} from '../../../modules/modal/actions';
import {MODALS} from '../../Modal/ModalContents';
import {ISupplierOrderItem} from '../../../types/SupplierOrders/ISupplierOrderItem';
import {Delete, FiberNew, NotInterested} from '@material-ui/icons';

interface IProps {
  row: ISupplierOrderItem;
  moveToSupplierOrder: (data: any) => void;
  deleteItem: (data: any) => void;
  createSupplierOrder: (data: any) => void;
  updateSupplierOrder: (data: any) => void;
}

export default function SupplierOrderFromBacklogButton({
  row: supplierOrderItem,
  moveToSupplierOrder,
  deleteItem,
  createSupplierOrder,
  updateSupplierOrder,
}: IProps) {
  const dispatch = useDispatch();
  const {t} = useTranslation();

  const handleDelete = (e: React.SyntheticEvent) => {
    e.preventDefault();
    if (confirm(t('general.areYouSure'))) {
      deleteItem({id: supplierOrderItem._id});
    }
  };

  const handleNewOrder = (e: React.SyntheticEvent) => {
    e.preventDefault();
    if (confirm(t('general.areYouSure'))) {
      createSupplierOrder({supplierOrderItemId: supplierOrderItem._id});
    }
  };

  const handleIgnoreForSuggestions = (e: React.SyntheticEvent) => {
    e.preventDefault();
    if (confirm(t('general.areYouSure'))) {
      updateSupplierOrder({id: supplierOrderItem._id, ignoreForSuggestions: !supplierOrderItem.ignoreForSuggestions});
    }
  };

  const handleCancel = () => dispatch(modalActions.closeModal());

  const handleOnClick = (e: React.SyntheticEvent) => {
    e.preventDefault();

    dispatch(
      modalActions.addModal(MODALS.SUPPLIER_ORDER_FROM_BACKLOG_MODAL, {
        onSubmit: ({quantity, supplierOrderNumber}: any) => {
          moveToSupplierOrder({id: supplierOrderItem._id, quantity, supplierOrderNumber});
          handleCancel();
        },
        onCancel: handleCancel,
        supplierOrderItem,
      }),
    );
  };

  return (
    <div className="flex-center">
      <IconButton
        title={t('supplierOrderDetails.moveToSupplierOrder')}
        style={{color: '#5cabef'}}
        onClick={handleOnClick}
      >
        <KeyboardReturn />
      </IconButton>
      <IconButton title={t('supplierOrderDetails.delete')} style={{color: '#ef4444'}} onClick={handleDelete}>
        <Delete />
      </IconButton>
      <IconButton title={t('supplierOrderDetails.createNewOrder')} style={{color: '#14b8a6'}} onClick={handleNewOrder}>
        <FiberNew />
      </IconButton>
      <IconButton
        style={{padding: '5px', color: supplierOrderItem.ignoreForSuggestions ? 'red' : 'grey'}}
        onClick={handleIgnoreForSuggestions}
      >
        <NotInterested />
      </IconButton>
    </div>
  );
}
