export interface IHeaderAccessor {
  kind: 'accessor';
  name: string;
  accessor: string;
  sortable?: boolean;
  override?: boolean;
  format?: string;
  optionalAccessor?: string;
  type?: string | DataTableItemType;
  options?: any[];
  currencyCode?: string;
  timeZone?: string;
  translationPrefixKey?: string;
  callback?: any;
}

export enum DataTableItemType {
  NUMBER = 'number',
  TEXT = 'text',
  IMAGE = 'image',
  DATE = 'date',
  BOOLEAN = 'boolean',
  CALLBACK = 'callback',
  MULTIPLE_SELECT = 'multiple_select',
  SELECT = 'select',
  LINK = 'link',
}
