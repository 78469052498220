import React, {useEffect, useState} from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import {Button} from '@material-ui/core';
import {useStorlessFetch} from '../../../hooks/fetch';
import {ShopDescriptionsStyle} from './ShopDescriptionsStyle';
import {useTranslation} from 'react-i18next';
import {appActions} from '../../../modules/app/actions';
import {useDispatch} from 'react-redux';
import DescriptionCounter from './DescriptionCounter';

interface IProps {
  productId?: number;
  label: string;
}

const ShopDescriptions = ({productId, label}: IProps) => {
  const [getDescriptionRes, getDescription] = useStorlessFetch('products_descriptions_by_id');
  const [saveDescriptionRes, saveDescription] = useStorlessFetch('products_description_save');
  const [shopDescription, setShopDescription] = useState({
    shopName: label,
    description: '',
    metaTitle: '',
    metaDescription: '',
  });
  const classes = ShopDescriptionsStyle();
  const dispatch = useDispatch();
  const {t} = useTranslation();

  const handleDescriptionChange = (key: string, value: string) => {
    setShopDescription((prevState) => {
      return {...prevState, [key]: value};
    });
  };

  const handleSaveClick = () => {
    saveDescription({
      id: productId,
      shopName: label,
      description: shopDescription.description,
      metaTitle: shopDescription.metaTitle,
      metaDescription: shopDescription.metaDescription,
    });
  };

  useEffect(() => {
    getDescription({id: productId});
  }, []);

  useEffect(() => {
    if (getDescriptionRes.data && getDescriptionRes.data.shopDescriptions) {
      const shopDescription = getDescriptionRes.data.shopDescriptions.find((x: any) => x.shopName === label);
      shopDescription && setShopDescription(shopDescription);
    }
  }, [getDescriptionRes]);

  useEffect(() => {
    if (saveDescriptionRes.data) {
      dispatch(appActions.showSnackBar({text: t('general.success'), options: {severity: 'success'}}));
    }

    if (saveDescriptionRes.error) {
      dispatch(appActions.showSnackBar({text: t('general.error'), options: {severity: 'error'}}));
    }

    return () => {
      saveDescriptionRes.data = null;
      saveDescriptionRes.error = null;
    };
  }, [saveDescriptionRes]);

  return (
    <div className={classes.root}>
      <div className="big-title">{label}</div>
      <div className="product-info-title">{t('productDetails.description')}</div>
      <ReactQuill
        className={classes.textArea}
        value={shopDescription.description}
        onChange={(value: string) => handleDescriptionChange('description', value)}
      />

      <div className="product-info-title">
        {t('productDetails.metaTitle')} {<DescriptionCounter text={shopDescription.metaTitle} maxChars={71} />}
      </div>
      <ReactQuill
        className={classes.textArea}
        value={shopDescription.metaTitle}
        onChange={(value: string) => handleDescriptionChange('metaTitle', value)}
      />

      <div className="product-info-title">
        {t('productDetails.metaDescription')}{' '}
        {<DescriptionCounter text={shopDescription.metaDescription} maxChars={158} />}
      </div>
      <ReactQuill
        className={classes.textArea}
        value={shopDescription.metaDescription}
        onChange={(value: string) => handleDescriptionChange('metaDescription', value)}
      />
      <Button className={classes.button + ' blue-button'} variant="contained" onClick={handleSaveClick}>
        {t('general.save')}
      </Button>
    </div>
  );
};

export default ShopDescriptions;
