import React, {useEffect} from 'react';
import {useStorlessFetch} from '../../hooks/fetch';
import {useState} from 'react';
import {IOrder} from '../../types/Orders/IOrder';
import {IOrderItem} from '../../types/OrderItems/IOrderItem';
import {OrderItemType} from '../../types/OrderItems/OrderItemType';
import DataTable from '../../components/Table/Table';
import {useTranslation} from 'react-i18next';
import SetPurchasingPrice from '../../components/Order/ManuallyCompleted/SetPurchasingPrice';
import FinishManuallyCompletedOrderButton from '../../components/Order/FinishManuallyCompletedOrderButton';

const ManuallyCompletedOrderDetails = (props: any) => {
  const {t} = useTranslation();
  const {
    match: {
      params: {id},
    },
  } = props;
  const [triggerRefreshTable, setTriggerRefreshTable] = useState<any>(null);
  const [orderDetails, getOrderDetails] = useStorlessFetch('order_details');
  const [order, setOrder] = useState<IOrder>(null as any);
  const [orderItems, setOrderItems] = useState<IOrderItem[]>(null as any);

  useEffect(() => {
    getOrderDetails({id});
  }, [id]);

  useEffect(() => {
    if (orderDetails.data) {
      setOrder(orderDetails.data);
      setOrderItems(orderDetails.data.orderItems);
    }
  }, [orderDetails]);

  return (
    <div className="orders">
      {order && (
        <DataTable
          hideFilters={true}
          className="order-details__table"
          headers={[
            {
              kind: 'accessor',
              name: t('orderItems.name'),
              accessor: 'name',
              sortable: true,
            },
            {
              kind: 'accessor',
              name: t('orderItems.sku'),
              accessor: 'sku',
              sortable: true,
            },
            {
              kind: 'accessor',
              name: t('orderItems.quantity'),
              accessor: 'quantity',
              sortable: true,
            },
            {
              kind: 'accessor',
              name: t('orderItems.priceNet'),
              accessor: 'priceNet',
              sortable: true,
              format: 'price',
              currencyCode: 'EUR',
            },
            {
              kind: 'accessor',
              name: t('orderItems.priceGross'),
              accessor: 'priceGross',
              sortable: true,
              format: 'price',
              currencyCode: 'EUR',
            },
            {
              kind: 'component',
              name: t('orderItems.purchasePrice'),
              component: SetPurchasingPrice,
              props: {
                setTriggerRefreshTable: (productId: string) => setTriggerRefreshTable(productId),
              },
              onFetchProps: {conditions: {deletedAt: {$eq: null}}},
            },
          ]}
          endpoint={'order_items'}
          options={{order: order._id, type: {$ne: OrderItemType.shipping}}}
          target={`products`}
          refreshData={triggerRefreshTable}
          selectRowClickId={'product._id'}
          defaultSort={{key: 'type', value: 1}}
          numberOfItemsPerPage={100}
          title={`${t('orders.orderNumber')}: ${order.orderNumber}`}
          actionButtons={<FinishManuallyCompletedOrderButton orderId={order._id}></FinishManuallyCompletedOrderButton>}
        />
      )}
    </div>
  );
};
export default ManuallyCompletedOrderDetails;
