import moment from 'moment';
import React from 'react';
import Form from '../components/Form/Form';

const fields = [
  {
    label: 'Test select',
    placeholder: 'test',
    fieldKey: 'testSelect',
    type: 'select',
    options: [
      {value: 'optionOne', label: 'Option One'},
      {value: 'optionTwo', label: 'Option Two'},
    ],
  },
  {
    label: 'Test select',
    placeholder: 'test',
    fieldKey: 'date',
    type: 'dateRange',
  },
];

const FormTest = () => {
  return (
    <Form
      initialValues={{date: {from: moment().format(), to: moment().format()}}}
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      onSubmit={() => {}}
      submitButtonText={'Submit'}
      fields={fields}
    />
  );
};

export default FormTest;
