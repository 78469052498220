import React, {ReactElement, useContext, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {useFetch} from '../../../hooks/fetch';
import {appActions} from '../../../modules/app/actions';
import {OrderProblemActions} from '../../../screens/OrderProblems/OrderProblems';
import OrderDetailsContext from '../../../screens/Orders/Context/OrderDetailsContext';
import DataTable from '../../Table/Table';
import {DataTableItemType} from '../../Table/interfaces/IHeaderAccessor';

const OrderProblemsTable = (): ReactElement => {
  const [orderProblemDelete, doOrderProblemDelete] = useFetch('order_problem_delete');
  const [orderProblemPut, doOrderProblemPut] = useFetch('order_problem_put');
  const {orderId, orderProblemTable} = useContext(OrderDetailsContext);
  const dispatch = useDispatch();
  const {t} = useTranslation();

  useEffect(() => {
    if (orderProblemDelete?.data) {
      dispatch(appActions.showSnackBar({text: t('general.submitFormText'), options: {severity: 'success'}}));
    } else if (orderProblemDelete?.error) {
      dispatch(appActions.showSnackBar({text: orderProblemDelete.error.name, options: {severity: 'error'}}));
    }

    return () => {
      orderProblemDelete.data = null;
      orderProblemDelete.error = null;
    };
  }, [orderProblemDelete]);

  useEffect(() => {
    if (orderProblemPut?.data) {
      dispatch(appActions.showSnackBar({text: t('general.submitFormText'), options: {severity: 'success'}}));
    } else if (orderProblemPut?.error) {
      dispatch(appActions.showSnackBar({text: orderProblemPut.error.name, options: {severity: 'error'}}));
    }

    return () => {
      orderProblemPut.data = null;
      orderProblemPut.error = null;
    };
  }, [orderProblemPut]);

  return (
    <div className="order-details__table-wrapper">
      <DataTable
        className="order-details__table"
        hideFilters={true}
        headers={[
          {
            kind: 'accessor',
            name: t('orderProblems.orderNumber'),
            accessor: 'order.orderNumber',
          },
          {
            kind: 'accessor',
            name: t('orderProblems.status'),
            accessor: 'status',
            sortable: true,
          },
          {
            kind: 'accessor',
            name: t('orderProblems.location'),
            accessor: 'location',
            sortable: true,
          },
          {
            kind: 'accessor',
            name: t('orderProblems.orderProblemType'),
            accessor: 'orderProblemType.name',
          },
          {
            kind: 'accessor',
            name: t('orderProblems.reminderDate'),
            accessor: 'reminderDate',
            sortable: true,
            type: DataTableItemType.DATE,
          },
          {
            kind: 'accessor',
            name: t('orderProblems.customer'),
            accessor: 'customerName',
          },
          {
            kind: 'accessor',
            name: t('orderProblems.shippingProvider'),
            accessor: 'order.shippingType',
          },
          {
            kind: 'accessor',
            name: t('orderProblems.comment'),
            accessor: 'comment',
            sortable: true,
          },
          {
            kind: 'component',
            name: t('general.actions'),
            component: OrderProblemActions,
            props: {doOrderProblemPut, doOrderProblemDelete},
            onFetchProps: {},
          },
        ]}
        endpoint={'order_problems'}
        options={{order: orderId}}
        refreshData={orderProblemTable}
      />
    </div>
  );
};

export default OrderProblemsTable;
