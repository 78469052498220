import {FormControl, Grid, InputLabel, MenuItem, Select, TextField, Typography} from '@material-ui/core';
import React, {useEffect, useState} from 'react';
import * as Yup from 'yup';
import {useTranslation} from 'react-i18next';
import {useFetch, useStorlessFetch} from '../../hooks/fetch';
import Form from '../Form/Form';
import {SupplierOrderStatus} from '../../types/SupplierOrders/SupplierOrderStatus';

export interface IEditSupplierOrderItem {
  title: string;
  onSubmit: (values: any) => void;
  onCancel: () => void;
  supplierOrderItemId?: any;
  alreadyOrdered?: boolean;
  orderStatus?: SupplierOrderStatus;
}

const supplierOrderItemSchema = Yup.object().shape({
  quantity: Yup.string().required('Required').nullable(),
});

const EditSupplierOrderItem = (props: IEditSupplierOrderItem) => {
  const {title, onCancel, onSubmit, supplierOrderItemId, alreadyOrdered, orderStatus} = props;
  const [supplierOrderItemDetails, getSupplierOrderItemDetails] = useStorlessFetch('supplier_order_items_details');
  const [suppliers, getSuppliers] = useFetch('suppliers_by_product');
  const [supplierArticles, getSupplierArticles] = useFetch('supplier_articles_by_supplier_and_product');
  const [initialValues, setInitialValues] = useState(null as any);
  const [productId, setProductId] = useState(null);
  const [supplierId, setSupplierId] = useState(null);
  const [externalArticleId, setExternalArticleId] = useState(null);
  const {t} = useTranslation();

  const generateInitialValues = (fields: any[], supplierOrderItemObject: any) => {
    if (supplierArticles?.data) {
      const article = supplierArticles?.data?.find((item: any) => item.externalArticleId === externalArticleId);
      return {
        ...supplierOrderItemObject,
        supplierQuantity: article?.quantity,
        price: article?.price,
        externalArticleId: article?.externalArticleId,
      };
    }
  };

  const setSupplierArticleData = () => {
    if (productId && supplierId) {
      getSupplierArticles({productId, supplierId});
    }
  };

  useEffect(() => {
    if (productId) {
      getSuppliers({id: productId});
    }

    return () => {
      suppliers.data = null;
      suppliers.loaded = null;
      suppliers.error = null;
    };
  }, [productId]);

  useEffect(() => {
    getSupplierOrderItemDetails({id: supplierOrderItemId});
    return () => {
      supplierOrderItemDetails.data = null;
      supplierOrderItemDetails.loaded = null;
      supplierOrderItemDetails.error = null;
    };
  }, []);

  useEffect(() => {
    if (supplierOrderItemDetails.data) {
      const {product, supplier, externalArticleId} = supplierOrderItemDetails.data;
      setProductId(product?._id);
      setSupplierId(supplier?._id);
      setExternalArticleId(externalArticleId);
    }
    return () => {
      setProductId(null);
      setSupplierId(null);
      setExternalArticleId(null);
    };
  }, [supplierOrderItemDetails]);

  useEffect(() => {
    setSupplierArticleData();
    return () => {
      supplierArticles.data = null;
      supplierArticles.loaded = null;
      supplierArticles.error = null;
    };
  }, [supplierId]);

  useEffect(() => {
    setExternalArticleId(supplierOrderItemDetails?.data?.externalArticleId);
    return () => {
      setExternalArticleId(null);
    };
  }, [supplierArticles]);

  useEffect(() => {
    if (supplierOrderItemDetails?.data && supplierArticles?.data && externalArticleId) {
      const article = supplierArticles?.data?.find((item: any) => item.externalArticleId === externalArticleId);
      const data = generateInitialValues(fields, supplierOrderItemDetails?.data);
      setInitialValues(() => ({
        ...data,
        supplier: supplierId,
        supplierQuantity: parseInt(article?.quantity),
        price: data.price ?? parseFloat(article?.price),
        externalArticleId: externalArticleId,
        quantityDelivered: data.quantityDelivered ?? data.quantity,
      }));
    } else if (supplierOrderItemDetails?.data && !externalArticleId) {
      setInitialValues(() => ({
        ...supplierOrderItemDetails?.data,
        supplier: supplierId,
        supplierQuantity: 0,
        price: supplierOrderItemDetails?.data?.price || 0,
        externalArticleId: '',
      }));
    }

    return () => {
      setInitialValues(null);
    };
  }, [supplierArticles, externalArticleId]);

  const fields: any = [
    {
      label: t('supplierOrderItems.quantityToOrder'),
      placeholder: t('supplierOrderItems.quantityToOrder'),
      fieldKey: 'quantity',
      type: 'input',
      fieldKeyType: 'number',
      disabled: orderStatus !== SupplierOrderStatus.ordered,
    },
    {
      label: t('translation:supplierOrderItems.price'),
      placeholder: t('translation:supplierOrderItems.price'),
      fieldKey: 'price',
      type: 'input',
      fieldKeyType: 'number',
      disabled: orderStatus !== SupplierOrderStatus.ordered,
    },
    // alreadyOrdered
    //   ? {
    //       label: t('supplierOrderItems.quantityDelivered'),
    //       placeholder: t('supplierOrderItems.quantityDelivered'),
    //       fieldKey: 'quantityDelivered',
    //       type: 'input',
    //       fieldKeyType: 'number',
    //     }
    //   : null,
  ];

  const onPriceChange = (e: any) => {
    setInitialValues((prevState: any) => ({...prevState, price: parseFloat(e.target.value)}));
  };

  return (
    <>
      <Typography className="modal-title">{title}</Typography>

      {suppliers?.data && initialValues ? (
        <>
          <Typography className="modal-title">{t('supplierOrderItems.supplierArticleInfo')}</Typography>
          <FormControl variant="outlined">
            <InputLabel id="supplierLabel">{t('supplierOrderItems.supplier')}</InputLabel>
            <Select
              labelId="supplierLabel"
              id="supplierSelect"
              value={supplierId}
              onChange={(e: any) => {
                setSupplierId(e.target.value);
              }}
              disabled={alreadyOrdered}
            >
              {suppliers?.data?.map((item: any) => {
                return (
                  <MenuItem key={item._id} value={item._id}>
                    {item.name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>

          <FormControl variant="outlined">
            <InputLabel id="articleLabel">{t('supplierOrderItems.supplierArticle')}</InputLabel>
            <Select
              labelId="articleLabel"
              id="supplierArticleSelect"
              value={externalArticleId}
              onChange={(e: any) => {
                setExternalArticleId(e.target.value);
              }}
              disabled={alreadyOrdered}
            >
              {supplierArticles?.data?.map((item: any) => {
                return (
                  <MenuItem key={item._id} value={item.externalArticleId}>
                    {item.externalArticleId}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>

          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <TextField
                id="supplierQuantity"
                label={t('supplierOrderItems.quantity')}
                variant="outlined"
                type="number"
                value={initialValues.supplierQuantity}
                disabled
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                id="price"
                label={t('supplierOrderItems.price')}
                variant="outlined"
                type="number"
                value={initialValues.price}
                onChange={onPriceChange}
                disabled={alreadyOrdered}
              />
            </Grid>
          </Grid>

          <Typography className="modal-title">{t('supplierOrderItems.productInfo')}</Typography>

          <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
              <TextField
                id="productTitle"
                label={t('supplierOrderItems.product')}
                variant="outlined"
                value={initialValues.product.title}
                disabled
              />
            </Grid>

            <Grid item xs={12} sm={4}>
              <TextField
                id="stockDDA"
                label={t('supplierOrderItems.stockDDA')}
                variant="outlined"
                type="number"
                value={initialValues.product.stockDDA}
                disabled
              />
            </Grid>

            <Grid item xs={12} sm={4}>
              <TextField
                id="minimumStock"
                label={t('supplierOrderItems.minimumStock')}
                variant="outlined"
                type="number"
                value={initialValues.product.minimumStock}
                disabled
              />
            </Grid>

            <Grid item xs={12} sm={4}>
              <TextField
                id="minimumStockReach"
                label={t('supplierOrderItems.minimumStockReach')}
                variant="outlined"
                type="number"
                value={initialValues.product.minimumStockReach}
                disabled
              />
            </Grid>
          </Grid>

          <Typography className="modal-title">{t('supplierOrderItems.orderInfo')}</Typography>

          <Form
            initialValues={initialValues}
            fields={fields.filter((x: any) => x !== null)}
            submitButtonText={t('general.submit')}
            cancelButtonText={t('general.cancel')}
            onSubmit={(values: any) =>
              onSubmit({
                ...initialValues,
                quantity: values.quantity,
                quantityDelivered: values.quantityDelivered,
                price: values.price,
              })
            }
            onCancel={onCancel}
            validationSchema={supplierOrderItemSchema}
          />
        </>
      ) : null}
    </>
  );
};

export default EditSupplierOrderItem;
