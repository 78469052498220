import {Button} from '@material-ui/core';
import {Sync} from '@material-ui/icons';
import React, {ReactElement, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {useFetch} from '../../hooks/fetch';
import {appActions} from '../../modules/app/actions';
import {modalActions} from '../../modules/modal/actions';
import {MODALS} from '../Modal/ModalContents';
import {IEbayMultiVariant} from '../../types/Product/IEbayMultiVariant';

const PublishVariantToEbayButton = (props: IProps): ReactElement => {
  const [publishVariantResponse, publishProduct] = useFetch<any>('publish_multi_variant_to_ebay');
  const [unpublishVariantResponse, unpublishProduct] = useFetch<any>('unpublish_multi_variant_from_ebay');
  const {multiVariant, getMultiVariant} = props;
  const dispatch = useDispatch();
  const {t} = useTranslation();

  const handleCancelModal = () => dispatch(modalActions.closeModal());
  const handleSubmit = async () => {
    !multiVariant.listingId ? publishProduct({id: multiVariant._id}) : unpublishProduct({id: multiVariant._id});
    handleCancelModal();
  };

  const handleSyncToShop = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    event.preventDefault();

    dispatch(
      modalActions.addModal(MODALS.ALERT_DIALOG, {
        title: t('general.areYouSure') || '',
        description: !multiVariant?.listingId
          ? t('productDetails.eBayPublishOfferMessage')
          : t('productDetails.eBayUnPublishOfferMessage'),
        onSubmit: handleSubmit,
        onClose: handleCancelModal,
        onOpen: true,
      }),
    );
  };

  useEffect(() => {
    if (publishVariantResponse.error || unpublishVariantResponse.error) {
      dispatch(appActions.showSnackBar({text: t('activatedArticles.failedSyncMessage'), options: {severity: 'error'}}));
    }

    if (
      (publishVariantResponse.loaded && !publishVariantResponse.error) ||
      (unpublishVariantResponse.loaded && !unpublishVariantResponse.error)
    ) {
      getMultiVariant({id: multiVariant._id});
      dispatch(appActions.showSnackBar({text: t('general.success'), options: {severity: 'success'}}));
    }

    return () => {
      publishVariantResponse.data = null;
      publishVariantResponse.loaded = null;
      publishVariantResponse.error = null;
      unpublishVariantResponse.data = null;
      unpublishVariantResponse.loaded = null;
      unpublishVariantResponse.error = null;
    };
  }, [publishVariantResponse, unpublishVariantResponse]);

  if (!multiVariant?.inventoryItemGroupKey) {
    return <></>;
  }

  return multiVariant?.inventoryItemGroupKey ? (
    <Button
      startIcon={<Sync fontSize="small"></Sync>}
      variant="contained"
      className="blue-button"
      onClick={handleSyncToShop}
    >
      {!multiVariant?.listingId ? t('productDetails.publishOnEbayButton') : t('productDetails.unPublishOnEbayButton')}
    </Button>
  ) : (
    <></>
  );
};

interface IProps {
  getMultiVariant: (data: any) => void;
  multiVariant: IEbayMultiVariant;
}

export default PublishVariantToEbayButton;
