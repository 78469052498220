import React, {useEffect, useState} from 'react';
import {useFetch} from '../../hooks/fetch';
import {Button, TextField} from '@material-ui/core';
import {ManufacturerVariable} from '../../screens/Pricing/AmazonPricing';
import {useTranslation} from 'react-i18next';
import {appActions} from '../../modules/app/actions';
import {useDispatch} from 'react-redux';

type AmazonManufacturerVariableProps = {
  variable: ManufacturerVariable;
};

export const AmazonManufacturerVariable = (props: AmazonManufacturerVariableProps) => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const manufacturerCoefficient = props?.variable.coefficient;
  const manufacturer = props?.variable.manufacturer;
  const [coefficient, setCoefficient] = useState(manufacturerCoefficient);
  const [updateAmazonManufacturerVariable, doUpdateAmazonManufacturerVariable] = useFetch(
    'update_amazon_manufacturer_variable',
  );

  useEffect(() => {
    if (updateAmazonManufacturerVariable.error) {
      dispatch(appActions.showSnackBar({text: 'Error saving', options: {severity: 'error'}}));
    }
    if (updateAmazonManufacturerVariable.data) {
      dispatch(appActions.showSnackBar({text: t('general.success'), options: {severity: 'success'}}));
    }

    return () => {
      updateAmazonManufacturerVariable.data = null;
      updateAmazonManufacturerVariable.error = null;
      updateAmazonManufacturerVariable.loaded = null;
      updateAmazonManufacturerVariable.loading = null;
    };
  }, [updateAmazonManufacturerVariable]);

  const update = () => {
    doUpdateAmazonManufacturerVariable({
      coefficient: coefficient,
      manufacturer: manufacturer,
    });
  };
  return (
    <div className="productPricing">
      <p className="manufacturer">{manufacturer}</p>
      <div>
        <TextField
          id={manufacturer}
          variant="outlined"
          type="number"
          value={coefficient}
          onChange={(event) => setCoefficient(+event.target.value)}
          InputProps={{
            inputProps: {
              min: 0,
              step: '0.01',
            },
          }}
        />
      </div>
      <div>
        <Button variant="contained" className="blue-button" onClick={update}>
          {t('pricing.submitChanges')}
        </Button>
      </div>
    </div>
  );
};
