import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {DataTableItemType} from '../../components/Table/interfaces/IHeaderAccessor';

import useUser from '../../hooks/user';
import {useStorlessFetch} from '../../hooks/fetch';
import {SupplierOrderStatus} from '../../types/SupplierOrders/SupplierOrderStatus';
import {ISupplierOrderScreen, initSupplierOrderScreenConfig} from '../../types/CustomConfig/ISupplierOrderConfig';
import {ISupplierOrder} from '../../types/SupplierOrders/ISupplierOrder';

export const useSupplierOrderHeaders = (): any => {
  const user = useUser() as any;
  const userEmail = user?.email || '';
  const featureName = userEmail ? `customConfig-${userEmail}` : 'default';
  const [getConfigRes, getConfig] = useStorlessFetch('custom_config');
  const [configObject, setConfigObject] = useState<ISupplierOrderScreen>(initSupplierOrderScreenConfig());
  const {t} = useTranslation();

  useEffect(() => {
    getConfig({featureName});
  }, []);

  useEffect(() => {
    if (getConfigRes?.data?.data?.supplierOrderScreen) {
      setConfigObject(() => getConfigRes?.data?.data?.supplierOrderScreen);
    }
  }, [getConfigRes]);

  const customFields = Object.entries(configObject?.displayedCustomFields || {})
    .filter(([key, value]) => value === true)
    .map(([key, value]) => {
      return {
        kind: 'accessor',
        name: key,
        accessor: 'customFields.' + key,
        sortable: false,
      };
    });

  const headers: any = [
    configObject?.displayedFields?.status && {
      kind: 'accessor',
      name: t('supplierOrders.status'),
      accessor: 'status',
      sortable: true,
      type: DataTableItemType.MULTIPLE_SELECT,
      options: ([{value: 'all', label: 'All'}] as any).concat(
        Object.values(SupplierOrderStatus).map((item) => {
          return {value: item, label: item};
        }),
      ),
    },
    configObject?.displayedFields?.orderNumberText && {
      kind: 'accessor',
      name: t('supplierOrders.orderNumber'),
      accessor: 'orderNumberText',
      sortable: true,
    },
    configObject?.displayedFields?.confirmed && {
      kind: 'accessor',
      name: t('supplierOrderDetails.confirmed'),
      accessor: 'confirmed',
      sortable: false,
      callback: (item: ISupplierOrder) => {
        return item.confirmed ? 'B' : undefined;
      },
    },
    configObject?.displayedFields?.supplierName && {
      kind: 'accessor',
      name: t('supplierOrders.supplier'),
      accessor: 'supplierName',
      sortable: true,
    },
    configObject?.displayedFields?.totalGross && {
      kind: 'accessor',
      name: t('supplierOrderDetails.totalGross'),
      accessor: 'totalGross',
      sortable: true,
      format: 'price',
    },
    configObject?.displayedFields?.additionalCost && {
      kind: 'accessor',
      name: t('supplierOrderDetails.additionalCost'),
      accessor: 'additionalCost',
      sortable: true,
      format: 'price',
    },
    configObject?.displayedFields?.createdAt && {
      kind: 'accessor',
      name: t('supplierOrders.createdAt'),
      accessor: 'createdAt',
      sortable: true,
      type: DataTableItemType.DATE,
    },
    configObject?.displayedFields?.deliveryDate && {
      kind: 'accessor',
      name: t('supplierOrders.deliveryDate'),
      accessor: 'deliveryDate',
      sortable: true,
      type: DataTableItemType.DATE,
    },
    configObject?.displayedFields?.deliveredAt && {
      kind: 'accessor',
      name: t('supplierOrders.deliveredAt'),
      accessor: 'deliveredAt',
      sortable: true,
      type: DataTableItemType.DATE,
    },
    configObject?.displayedFields?.invoiceUploaded && {
      kind: 'accessor',
      name: t('supplierOrderDetails.invoiceUploaded'),
      accessor: 'invoiceUploaded',
      sortable: true,
      type: DataTableItemType.BOOLEAN,
    },
    configObject?.displayedFields?.['invoice.invoiceNumber'] && {
      kind: 'accessor',
      name: t('supplierOrderDetails.invoiceNumber'),
      accessor: 'invoice.invoiceNumber',
      sortable: true,
    },
    configObject?.displayedFields?.['invoice.date'] && {
      kind: 'accessor',
      name: t('supplierOrderDetails.invoiceDate'),
      accessor: 'invoice.date',
      sortable: true,
      type: DataTableItemType.DATE,
    },
    configObject?.displayedFields?.['invoice.dueDate'] && {
      kind: 'accessor',
      name: t('supplierOrderDetails.dueDate'),
      accessor: 'invoice.dueDate',
      sortable: true,
      type: DataTableItemType.DATE,
    },
    configObject?.displayedFields?.['invoice.uploadedAt'] && {
      kind: 'accessor',
      name: t('supplierOrderDetails.uploadedAt'),
      accessor: 'invoice.uploadedAt',
      sortable: true,
      type: DataTableItemType.DATE,
    },
    configObject?.displayedFields?.['invoice.paidAt'] && {
      kind: 'accessor',
      name: t('supplierOrderDetails.paidAt'),
      accessor: 'invoice.paidAt',
      sortable: true,
      type: DataTableItemType.DATE,
    },
    configObject?.displayedFields?.paymentMethod && {
      kind: 'accessor',
      name: t('supplierOrderDetails.paymentMethod'),
      accessor: 'paymentMethod',
      sortable: true,
    },
    configObject?.displayedFields?.orderConfirmationNumber && {
      kind: 'accessor',
      name: t('supplierOrderDetails.orderConfirmationNumber'),
      accessor: 'orderConfirmationNumber',
      sortable: true,
    },
    ...customFields,
  ].filter(Boolean);

  return headers;
};

export const getSupplierOrderLabelForField = (fieldName: string, t: any): any => {
  if (fieldName.startsWith('invoice.')) {
    return t(`supplierOrderDetails.${fieldName.split('.').slice(1).join('.')}`);
  } else {
    return t(`supplierOrderDetails.${fieldName}`);
  }
};
