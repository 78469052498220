import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import axios from 'axios';
import Spinner from '../../components/Spinner/Spinner';
import {Button} from '@material-ui/core';
import config from '../../lib/config';

const SupplierArticleCsvProcessing = () => {
  const [csvFile, setCsvFile] = React.useState<File | null>(null);
  const [confirmUpload, setConfirmUpload] = React.useState(false);
  const [spinner, setSpinner] = useState(false);
  const {t} = useTranslation();

  const handleFileUpload = (event: any) => {
    setCsvFile(event.target.files[0]);
    setConfirmUpload(true);
  };

  const handleConfirmUpload = async () => {
    if (csvFile) {
      setSpinner(true);
      const url = `${config.api}products/sync-mpns`;
      const formData = new FormData();
      formData.append('file', csvFile as unknown as Blob);

      axios
        .post(url, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            responseType: 'blob',
          },
        })
        .then((response) => {
          setConfirmUpload(false);
          setSpinner(false);
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'response.csv'); // or any other extension
          document.body.appendChild(link);
          link.click();
        })
        .catch((error) => {
          console.log(error);
          setSpinner(false);
        });
    }
  };

  return (
    <div className="import-products">
      <div className="import-products__wrapper">
        <img src="/images/import-products.webp" />
        <h2>
          <b>{t('supplierOrders.csvProcessing')}</b>
        </h2>
        <h4 className="import-products__choose__text">{t('supplierOrders.csvProcessing')}</h4>
        <div className="import-products__wrapper__import__file">
          <p>Choose file</p>
          <input
            className="import-products__wrapper__import__file__hide"
            type="file"
            id="file"
            name="file"
            accept=".csv"
            onChange={handleFileUpload}
          />
        </div>
        {csvFile?.name && <div className="import-products__wrapper__uploaded__file__text">{csvFile?.name}</div>}
        <br />
        {spinner && <Spinner />}
        {csvFile?.name && (
          <Button variant="contained" className="blue-button" onClick={handleConfirmUpload}>
            {t('importProducts.sendFileButton')}
          </Button>
        )}
      </div>
    </div>
  );
};

export default SupplierArticleCsvProcessing;
