import React, {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import DataTable from '../../components/Table/Table';
import {DataTableItemType} from '../../components/Table/interfaces/IHeaderAccessor';
import config from '../../lib/config';
import {useFetch} from '../../hooks/fetch';

const SupplierPayments = () => {
  const {t} = useTranslation();
  const [downloadPaymentXmlResponse, downloadPaymentXml] = useFetch<any>('download_payment_xml');
  const [requestedId, setRequestedId] = React.useState<string | null>(null);

  useEffect(() => {
    if (downloadPaymentXmlResponse.data) {
      downloadFile(downloadPaymentXmlResponse.data);
    }
    return () => {
      downloadPaymentXmlResponse.data = null;
      downloadPaymentXmlResponse.error = null;
      downloadPaymentXmlResponse.loaded = null;
    };
  }, [downloadPaymentXmlResponse]);

  const downloadFile = async (data: any) => {
    const url = window.URL.createObjectURL(new Blob([data]));
    const link = document.createElement('a');

    link.setAttribute('download', `${requestedId}.xml`);
    link.href = url;

    document.body.appendChild(link);

    link.click();
    link.remove();
  };

  return (
    <div className="standard-page" style={{marginTop: '15px'}}>
      <DataTable
        className="order-details__table"
        headers={[
          {
            kind: 'accessor',
            name: t('activityLog.createdAt'),
            accessor: 'createdAt',
            sortable: true,
            type: DataTableItemType.DATE,
          },
          {
            kind: 'accessor',
            name: t('supplierOrders.supplier'),
            accessor: 'data.supplierName',
            sortable: true,
            type: DataTableItemType.TEXT,
          },
          {
            kind: 'accessor',
            name: t('supplierOrderDetails.invoiceNumbers'),
            accessor: 'data.invoices',
            sortable: true,
            type: DataTableItemType.TEXT,
          },
          {
            kind: 'accessor',
            name: t('supplierOrders.paymentId'),
            accessor: 'data.paymentId',
            sortable: true,
            type: DataTableItemType.CALLBACK,
            callback: (data: any) => {
              return data.data.paymentId ? (
                <a
                  onClick={() => {
                    setRequestedId(data.data.paymentId);
                    downloadPaymentXml({id: data.data.paymentId});
                  }}
                  className="link"
                  href={`#`}
                >
                  {data.data.paymentId}
                </a>
              ) : (
                '-'
              );
            },
          },
          {
            kind: 'accessor',
            name: t('supplierOrders.total'),
            accessor: 'data.total',
            sortable: false,
            type: DataTableItemType.NUMBER,
            format: 'price',
          },
        ]}
        endpoint={'activity_log_list'}
        options={{actionType: 'INVOICES_PAID'}}
        defaultSort={{key: 'createdAt', value: -1}}
        title={t('general.supplierPayments')}
      />
    </div>
  );
};

export default SupplierPayments;
