import {Typography} from '@material-ui/core';
import React from 'react';
import Form, {IField} from '../Form/Form';
import {useTranslation} from 'react-i18next';
import * as Yup from 'yup';

const AddProductToComplaintModal = (props: IAddProductToComplaintModalProps) => {
  const {isLoading, onSubmit, onCancel} = props;
  const {t} = useTranslation();

  const generateInitialValues = (fields: any[]) => {
    let defaults = {};
    fields.forEach((field) => {
      defaults = {...defaults, [field.fieldKey]: ''};
    });
    return defaults;
  };

  const fields: IField[] = [
    {
      label: t('supplierOrderDetails.product'),
      placeholder: t('supplierOrderDetails.product'),
      fieldKey: 'product',
      type: 'autocomplete',
      endpoint: 'product_autocomplete_list',
      options: [],
      required: true,
    },
    {
      label: t('supplierOrderDetails.quantity'),
      placeholder: t('supplierOrderDetails.quantity'),
      fieldKey: 'quantity',
      type: 'input',
      fieldKeyType: 'number',
      required: true,
    },
    {
      label: t('supplierOrderDetails.price'),
      placeholder: t('supplierOrderDetails.price'),
      fieldKey: 'price',
      type: 'input',
      fieldKeyType: 'number',
      required: true,
    },
    {
      label: t('supplierComplaints.reasonForComplaint'),
      placeholder: t('supplierComplaints.reasonForComplaint'),
      fieldKey: 'reason',
      type: 'input',
    },
  ];
  const initialValues = generateInitialValues(fields);

  const validationSchema = Yup.object().shape({
    product: Yup.string().required('Required').nullable(),
    quantity: Yup.number().required('Required').nullable(),
    price: Yup.number().required('Required').nullable(),
  });

  return (
    <>
      <Typography className="modal-title">{t('customerReturns.addProductToCustomerComplaint')}</Typography>
      <Form
        initialValues={initialValues}
        fields={fields}
        submitButtonText={t('general.submit')}
        cancelButtonText={t('general.cancel')}
        onSubmit={onSubmit}
        onCancel={onCancel}
        validationSchema={validationSchema}
        isLoading={isLoading}
      ></Form>
    </>
  );
};

export interface IAddProductToComplaintModalProps {
  onSubmit: (values: any) => void;
  onCancel: () => void;
  isLoading?: boolean;
}

export default AddProductToComplaintModal;
