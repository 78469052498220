import React, {useContext, useEffect, useState} from 'react';
import {Button, TextField} from '@material-ui/core';
import {useTranslation} from 'react-i18next';
import {useFetch} from '../../hooks/fetch';
import ProductDetailsContext from '../../screens/Products/Context/ProductDetailsContext';
import {useDispatch} from 'react-redux';
import {modalActions} from '../../modules/modal/actions';
import {MODALS} from '../Modal/ModalContents';
import {appActions} from '../../modules/app/actions';

export const StockUpdate = () => {
  const {product, onStockUpdate, updateProductState, getProductDetails} = useContext(ProductDetailsContext);
  const [stock, setStock] = useState(product.stockDDA);
  const [averagePriceInput, setAveragePriceInput] = useState(0);
  const [comment, setComment] = useState('');
  const [newDDAStock, setNewDDAStock] = useFetch<any>('update_product_stock');
  const dispatch = useDispatch();
  const {t} = useTranslation();

  const handleKeyPress = (e: any) => {
    if (!(e.charCode >= 48 && e.charCode <= 57)) {
      e.preventDefault();
    }
  };

  const handleStockUpdate = () => {
    setNewDDAStock({
      productId: product._id,
      newQuantity: Number(stock),
      averagePrice: averagePriceInput,
      comment: comment,
    });

    dispatch(modalActions.closeModal());
  };

  const onFormSubmit = (e: any) => {
    e.preventDefault();

    dispatch(
      modalActions.addModal(MODALS.CONFIRM_DIALOG, {
        title: t('general.areYouSure'),
        content: t('general.areYouSure'),
        onYes: handleStockUpdate,
        onNo: () => dispatch(modalActions.closeModal()),
      }),
    );
  };

  useEffect(() => {
    setStock(product.stockDDA);
  }, [product._id]);

  useEffect(() => {
    if (newDDAStock.data === '' && newDDAStock.loaded) {
      onStockUpdate();
      updateProductState(stock, 'stockDDA');
      setStock(stock);
      setAveragePriceInput(0);
      setComment('');
      getProductDetails({id: product._id});
      dispatch(appActions.showSnackBar({text: t('general.success'), options: {severity: 'success'}}));
    } else if (newDDAStock.error) {
      dispatch(appActions.showSnackBar({text: t('general.error'), options: {severity: 'error'}}));
    }

    return () => {
      newDDAStock.data = null;
      newDDAStock.error = null;
      newDDAStock.loaded = null;
      newDDAStock.loading = null;
    };
  }, [newDDAStock]);

  return (
    <>
      {product._id && (
        <form onSubmit={onFormSubmit} style={{paddingRight: '15px'}}>
          <TextField
            id="outlined-number"
            label={t('productDetails.newTotalStock')}
            className="product-info-input"
            style={{width: '100%'}}
            variant="standard"
            size="small"
            type="number"
            InputProps={{inputProps: {min: 0, step: 1}}}
            value={stock}
            onChange={(e) => {
              setStock(parseInt(e.target.value));
            }}
            onKeyPress={handleKeyPress}
          />
          <TextField
            id="averagePrice"
            label={t('productDetails.buyingPrice')}
            className="product-info-input"
            style={{width: '100%'}}
            variant="standard"
            size="small"
            type="number"
            InputProps={{inputProps: {min: 0, step: 0.01}}}
            value={averagePriceInput}
            onChange={(e) => setAveragePriceInput(parseFloat(e.target.value))}
          />
          <TextField
            id="averagePrice"
            label={t('productDetails.comment')}
            className="product-info-input"
            style={{width: '100%'}}
            variant="standard"
            size="small"
            value={comment}
            onChange={(e) => setComment(e.target.value)}
            required
          />
          <Button
            style={{float: 'right'}}
            type="submit"
            variant="contained"
            className="blue-button"
            disabled={newDDAStock.loading}
          >
            {t('general.save')}
          </Button>
        </form>
      )}
    </>
  );
};
