import {ActionsUnion, createAction} from '../../lib/actions';

enum FILTER {
  SET_FILTER_VALUE = 'SET_FILTER_VALUE',
}
const filterActions = {
  setFilterValue: (data: {key: string; value: any}) => createAction(FILTER.SET_FILTER_VALUE, data),
};

export type FilterActions = ActionsUnion<typeof filterActions>;
export {FILTER, filterActions};
