import {Grid} from '@material-ui/core';
import React, {ReactElement} from 'react';
import {useTranslation} from 'react-i18next';
import {IProduct} from '../../types/Product/IProduct';

const DefectRate = (props: IDefectRateProps): ReactElement => {
  const {t} = useTranslation();
  const {product} = props;

  return (
    <div style={{padding: '10px'}}>
      <div style={{padding: '15px 0'}}>{t('productDetails.returnedProducts')}:</div>

      <Grid container sm={12} xs={12} className="product-info" style={{border: 'none', boxShadow: 'none'}}>
        <Grid item sm={6} xs={12} className="product-info-item">
          <div className="product-info-label">{t('productDetails.complainedToSupplier')}</div>
          <div className="product-info-text">{product?.defectRate?.complainedToSupplier}</div>
        </Grid>
        <Grid item sm={6} xs={12} className="product-info-item">
          <div className="product-info-label">{t('productDetails.returnedToStock')}</div>
          <div className="product-info-text">{product?.defectRate?.returnedOnStock}</div>
        </Grid>
        <Grid item sm={6} xs={12} className="product-info-item">
          <div className="product-info-label">{t('productDetails.total')}</div>
          <div className="product-info-text">{product?.defectRate?.returned}</div>
        </Grid>
      </Grid>
      {(product?.defectRate?.defectRate || 0) <= 0 ? (
        <div>{t('productDetails.noDefectsForProduct')}.</div>
      ) : (
        <>
          <Grid container sm={12} xs={12} className="product-info" style={{border: 'none', boxShadow: 'none'}}>
            <Grid item sm={6} xs={12} className="product-info-item">
              <div className="product-info-label">{t('productDetails.totalSold')}</div>
              <div className="product-info-text">{product?.defectRate?.sold}</div>
            </Grid>
            <Grid item sm={6} xs={12} className="product-info-item">
              <div className="product-info-label">{t('productDetails.defectRate.defectRate')}</div>
              <div className="product-info-text">{product?.defectRate?.defectRate}%</div>
            </Grid>
          </Grid>
        </>
      )}
    </div>
  );
};

interface IDefectRateProps {
  product: IProduct;
}

export default DefectRate;
