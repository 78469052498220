import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import DataTable from '../../components/Table/Table';
import CriticalInvoiceActions from '../../components/Order/CriticalInvoiceActions';

const CriticalOverduePayments = () => {
  const {t} = useTranslation();
  const [triggerRefreshTable, setTriggerRefreshTable] = useState<any>(null);

  return (
    <div className="orders">
      <DataTable
        headers={[
          {
            kind: 'accessor',
            name: t('customerDetails.customerName'),
            accessor: 'customerName',
            sortable: false,
          },
          {
            kind: 'accessor',
            name: t('customerDetails.easybillId'),
            accessor: 'easybillId',
            sortable: false,
          },
          {
            kind: 'accessor',
            name: t('translation:orderDetails.invoiceNumber'),
            accessor: 'invoiceNumber',
            sortable: false,
          },
          {
            kind: 'accessor',
            name: t('translation:orderDetails.reminderSentAt'),
            accessor: 'reminderSentAt',
            sortable: false,
          },
          {
            kind: 'accessor',
            name: t('translation:orderDetails.warningSentAt'),
            accessor: 'warningSentAt',
            sortable: false,
          },
          {
            kind: 'accessor',
            name: t('translation:orders.status'),
            accessor: 'markedStatus',
            callback: (row: any) => {
              const status = row.markedStatus;
              return t(`criticalOverdueList.${status}`);
            },
            sortable: true,
            options: [
              {value: 'all', label: 'All'},
              {value: 'LAWYER', label: t('criticalOverdueList.LAWYER')},
              {value: 'DEBT_COMPANY', label: t('criticalOverdueList.DEBT_COMPANY')},
              {value: 'NOT_SET', label: t('criticalOverdueList.NOT_SET')},
            ],
          },
          {
            kind: 'component',
            name: t('translation:general.actions'),
            component: CriticalInvoiceActions,
            props: {setTriggerRefreshTable: (productId: string) => setTriggerRefreshTable(productId)},
            onFetchProps: {},
          },
        ]}
        endpoint={'customers_critical_overdue_list'}
        target={'orders'}
        refreshData={triggerRefreshTable}
        defaultSort={{key: 'date', value: 1}}
        selectRowClickId={'orderId'}
        numberOfItemsPerPage={100}
        title={t('general.criticalOverdueList')}
      />
    </div>
  );
};

export default CriticalOverduePayments;
