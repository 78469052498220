import {Button} from '@material-ui/core';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {MODALS} from '../../components/Modal/ModalContents';
import DataTable from '../../components/Table/Table';
import {modalActions} from '../../modules/modal/actions';
import {DataTableItemType} from '../../components/Table/interfaces/IHeaderAccessor';

const Suppliers = () => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  return (
    <div className="new-supplier">
      <DataTable
        className="new-supplier__table"
        headers={[
          {
            kind: 'accessor',
            name: t('suppliers.companyName'),
            accessor: 'companyName',
            sortable: true,
          },
          {
            kind: 'accessor',
            name: t('suppliers.email'),
            accessor: 'email',
            sortable: true,
          },
          {
            kind: 'accessor',
            name: t('suppliers.phone'),
            accessor: 'phone',
            sortable: true,
          },
          {
            kind: 'accessor',
            name: t('suppliers.address'),
            accessor: 'address.address',
            sortable: true,
          },
          {
            kind: 'accessor',
            name: t('suppliers.city'),
            accessor: 'address.city',
            sortable: true,
          },
          {
            kind: 'accessor',
            name: t('suppliers.zipCode'),
            accessor: 'address.zipCode',
            sortable: true,
          },
          {
            kind: 'accessor',
            name: t('suppliers.excludeFromPricing'),
            accessor: 'excludeFromPricing',
            sortable: true,
            type: DataTableItemType.BOOLEAN,
          },
          {
            kind: 'accessor',
            name: t('syncStatus.updatedFileDate'),
            accessor: 'priceListFileInfo.updatedFileDate',
            type: DataTableItemType.DATE,
            sortable: true,
          },

          {
            kind: 'accessor',
            name: t('syncStatus.status'),
            accessor: 'syncStatus.status',
            sortable: true,
            type: DataTableItemType.BOOLEAN,
          },
        ]}
        endpoint={'suppliers'}
        target={'suppliers'}
        refreshData={true}
        defaultSort={{key: 'name', value: 1}}
        numberOfItemsPerPage={50}
        title={t('suppliers.screenTitle')}
        actionButtons={
          <Button
            variant="contained"
            className="blue-button"
            onClick={() => {
              dispatch(modalActions.addModal(MODALS.NEW_SUPPLIER, {}));
            }}
          >
            {t('newSupplier.addNewSupplier')}
          </Button>
        }
      />
    </div>
  );
};

export default Suppliers;
