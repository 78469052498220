import React, {createContext, RefObject} from 'react';

const VideoRecorderContext = createContext({} as IVideoRecorderContext);
export const VideoRecorderProvider = VideoRecorderContext.Provider;
export default VideoRecorderContext;

interface IVideoRecorderContext {
  stopRecordingRef: RefObject<HTMLButtonElement>;
  startRecordingRef: RefObject<HTMLButtonElement>;
}
