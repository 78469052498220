import axios from 'axios';

export class Util {
  static getCurrencyCodeByCurrencyIso(currencyIso: string): string {
    switch (currencyIso) {
      case 'EUR':
        return 'EUR';
      case 'Pfund':
        return 'GBP';
      case 'GBP':
        return 'GBP';
      case 'SEK':
        return 'SEK';
      case 'PLN':
        return 'PLN';
      default:
        return 'EUR';
    }
  }

  static getBlob(base64Label = ''): Blob {
    const byteCharacters = atob(base64Label);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], {type: 'application/pdf;base64'});
    return blob;
  }

  static svgString2Image(svgString: string, width: number, height: number, format: string): any {
    return new Promise((resolve) => {
      try {
        format = format ? format : 'png';
        const svgData = 'data:image/svg+xml;base64,' + btoa(unescape(encodeURIComponent(svgString)));
        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');
        canvas.width = width;
        canvas.height = height;
        const image = new Image();
        image.onload = function () {
          context?.clearRect(0, 0, width, height);
          context?.drawImage(image, 0, 0, width, height);
          const pngData = canvas.toDataURL('image/' + format);
          resolve(pngData);
        };
        image.src = svgData;
      } catch (error) {
        resolve(null);
      }
    });
  }

  static printWithPrinterService(
    serviceUrl: string,
    printerName: string,
    base64Content: string,
    fileType = PrinterFileType.PDF,
  ) {
    if (!serviceUrl) {
      return;
    }

    const reqBody = {
      base64Content,
      printerName,
      fileType,
    };

    axios
      .post(serviceUrl, reqBody)
      .then(() => {
        console.log('Printing in progress...');
      })
      .catch((error) => {
        console.log(error);
      });
  }

  static getMimeType = (extension: string | undefined): string | undefined => {
    switch (extension) {
      case 'pdf':
        return 'application/pdf';
      case 'png':
        return 'image/png';
      case 'jpg':
      case 'jpeg':
        return 'image/jpeg';
      default:
        return undefined;
    }
  };

  static async sleep(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  static convertUTCDateToLocalDate = (dateString: string, withHours = true) => {
    const timeZone = 'Europe/Berlin';

    if (dateString && dateString.length > 0) {
      const dateOptions: Intl.DateTimeFormatOptions = {
        timeZone,
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
        hour12: false,
      };
      if (!withHours) {
        delete dateOptions.hour;
        delete dateOptions.minute;
        delete dateOptions.second;
      }
      const dateFormatter = new Intl.DateTimeFormat('de-DE', dateOptions);
      const dateAsFormattedString = dateFormatter.format(new Date(dateString));
      return dateAsFormattedString;
    }
    return '';
  };
}

export enum PrinterFileType {
  PDF = 1,
  IMAGE = 2,
}
