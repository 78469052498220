import {Button, TextField} from '@material-ui/core';
import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useFetch} from '../../hooks/fetch';
import {useDispatch} from 'react-redux';
import {appActions} from '../../modules/app/actions';
import {MODALS} from '../../components/Modal/ModalContents';
import {modalActions} from '../../modules/modal/actions';

const MergeProducts = () => {
  const [mergeProductsFunction, doMergeProducts] = useFetch<any>('merge_products');
  const [fromProduct, setFromProduct] = useState(0);
  const [toProduct, setToProduct] = useState(0);
  const dispatch = useDispatch();
  const {t} = useTranslation();

  const handleMergeProducts = () => {
    doMergeProducts({from: fromProduct, to: toProduct});
    dispatch(modalActions.closeModal());
  };

  const mergeProducts = () => {
    dispatch(
      modalActions.addModal(MODALS.CONFIRM_DIALOG, {
        title: t('general.areYouSure'),
        content: t('general.areYouSure'),
        onYes: handleMergeProducts,
        onNo: () => dispatch(modalActions.closeModal()),
      }),
    );
  };

  useEffect(() => {
    if (mergeProductsFunction?.data) {
      dispatch(appActions.showSnackBar({text: t('general.submitFormText'), options: {severity: 'success'}}));
    } else if (mergeProductsFunction?.error) {
      dispatch(appActions.showSnackBar({text: mergeProductsFunction.error.name, options: {severity: 'error'}}));
    }

    return () => {
      mergeProductsFunction.data = null;
      mergeProductsFunction.error = null;
    };
  }, [mergeProductsFunction]);

  return (
    <div className="products">
      <h3>
        <b>{t('mergeProducts.screenTitle')}</b>
      </h3>
      <div>
        <TextField
          id="outlined-number"
          label={t('mergeProducts.deletedArticleId')}
          type="number"
          InputLabelProps={{
            shrink: true,
          }}
          style={{width: 150, marginRight: 20, marginBottom: 20, paddingTop: 10}}
          InputProps={{inputProps: {min: 0, step: 1}}}
          value={fromProduct}
          onChange={(e) => {
            setFromProduct(Number(e.target.value));
          }}
        />
      </div>
      <div>
        <TextField
          id="outlined-number"
          label={t('mergeProducts.remainingArticleId')}
          type="number"
          InputLabelProps={{
            shrink: true,
          }}
          style={{width: 150, marginRight: 20, marginBottom: 20, paddingTop: 10}}
          InputProps={{inputProps: {min: 0, step: 1}}}
          value={toProduct}
          onChange={(e) => {
            setToProduct(Number(e.target.value));
          }}
        />
      </div>
      <Button variant="contained" className="blue-button" onClick={mergeProducts}>
        {t('mergeProducts.mergeButton')}
      </Button>
    </div>
  );
};

export default MergeProducts;
