const ENDPOINTS = {
  login: {
    uri: 'auth/login',
    method: 'POST',
  },
  refresh_token: {
    uri: 'auth/refresh-token',
    method: 'POST',
  },
  me: {
    uri: 'auth/me',
    method: 'GET',
  },
  suppliers: {
    uri: 'suppliers/list',
    method: 'POST',
  },
  supplier_articles: {
    uri: 'supplierArticles/list',
    method: 'POST',
  },
  supplier_articles_post: {
    uri: 'supplierArticles',
    method: 'POST',
  },
  supplier_articles_put: {
    uri: 'supplierArticles/{id}',
    method: 'PUT',
  },
  supplier_articles_delete: {
    uri: 'supplierArticles/{id}',
    method: 'DELETE',
  },
  article_details: {
    uri: 'supplierArticles/{id}',
    method: 'GET',
  },
  products: {
    uri: 'products/search',
    method: 'POST',
  },
  products_by_barcode: {
    uri: 'products/barcode/{barcode}',
    method: 'GET',
  },
  supplier_article_approve: {
    uri: 'supplierArticles/{id}/approve',
    method: 'POST',
  },
  supplier_article_reject_matching: {
    uri: 'supplierArticles/{id}/disable-match',
    method: 'POST',
  },
  supplier_article_batch_ignore: {
    uri: 'supplierArticles/batch-ignore',
    method: 'POST',
  },
  forgot_password: {
    uri: 'auth/forgot-password',
    method: 'POST',
  },
  forgot_password_confirmation: {
    uri: 'auth/forgot-password-confirmation',
    method: 'POST',
  },
  change_password: {
    uri: 'auth/change-password',
    method: 'PATCH',
  },
  orders: {
    method: 'POST',
    uri: 'orders/list',
  },
  products_list: {
    uri: 'products/list',
    method: 'POST',
  },
  inactive_amazon_products_list: {
    uri: 'products/inactive-amazon-products',
    method: 'POST',
  },
  products_detailed_list: {
    uri: 'products/detailed-list',
    method: 'POST',
  },
  products_inventory_list: {
    uri: 'products/inventory-list',
    method: 'POST',
  },
  product_set_inventory: {
    uri: 'products/set-inventory-count',
    method: 'POST',
  },
  product_details: {
    uri: 'products/{id}',
    method: 'GET',
  },
  product_average_price: {
    uri: 'stockItems/average-price/product/{id}',
    method: 'GET',
  },
  update_product_stock: {
    uri: 'stockItems/update-product-stock',
    method: 'POST',
  },
  product_put: {
    uri: 'products/{id}',
    method: 'PUT',
  },
  product_shop_image: {
    uri: 'products/shop-image/{id}',
    method: 'PUT',
  },
  product_details_multibox_information: {
    uri: 'products/multibox-information/{id}',
    method: 'GET',
  },
  feature_configuration: {
    uri: 'featureConfiguration/{featureName}',
    method: 'GET',
  },
  product_delete: {
    uri: 'products/{id}',
    method: 'DELETE',
  },
  product_find_by_name: {
    uri: 'products/findByName',
    method: 'POST',
  },
  product_list_priority_search: {
    uri: 'products/searchByPriority',
    method: 'POST',
  },
  supplier_find_by_name: {
    uri: 'suppliers/findByName',
    method: 'POST',
  },
  customers: {
    uri: 'customers/list',
    method: 'POST',
  },
  customer_report: {
    uri: 'customers/get-customer-report-list',
    method: 'POST',
  },
  create_customer: {
    uri: 'customers',
    method: 'POST',
  },
  create_customer_order: {
    uri: 'customers/create-customer-order',
    method: 'POST',
  },
  customer_details: {
    uri: 'customers/{id}',
    method: 'GET',
  },
  customer_put: {
    uri: 'customers/{id}',
    method: 'PUT',
  },
  order_details: {
    uri: 'orders/{id}',
    method: 'GET',
  },
  order_creation_details: {
    uri: 'orders/order-creation/{id}',
    method: 'GET',
  },
  add_order_item: {
    uri: 'orders/order-creation/add',
    method: 'POST',
  },
  remove_order_item: {
    uri: 'orders/order-creation/remove',
    method: 'POST',
  },
  update_order_item: {
    uri: 'orders/order-creation/update',
    method: 'POST',
  },
  order_put: {
    uri: 'orders/{id}',
    method: 'PUT',
  },
  history_by_ids: {
    uri: 'history/list',
    method: 'POST',
  },
  update_product_status: {
    uri: 'products/update-product-status',
    method: 'POST',
  },
  merge_products: {
    uri: 'products/merge-products',
    method: 'POST',
  },
  pricing_get: {
    uri: 'pricing/shopware-variables/{id}/{type}',
    method: 'GET',
  },
  pricing_update: {
    uri: 'pricing/update-variable',
    method: 'POST',
  },
  pricing_update_shipping: {
    uri: 'pricing/update-shipping-data',
    method: 'POST',
  },
  customers_new: {
    uri: 'customers/new',
    method: 'POST',
  },
  product_shopware_sync: {
    uri: 'products/shopware',
    method: 'POST',
  },
  orders_latest: {
    uri: 'orders/latest',
    method: 'POST',
  },
  orders_marketplace: {
    uri: 'orders/per/marketplace',
    method: 'GET',
  },
  orders_country: {
    uri: 'orders/per/country',
    method: 'GET',
  },
  orders_complete_order: {
    uri: 'orders/complete-order/{id}',
    method: 'POST',
  },
  country_shipping_providers: {
    uri: 'countryShippingProviders/list',
    method: 'POST',
  },
  countries_with_codes: {
    uri: 'countryShippingProviders/countries-with-codes',
    method: 'GET',
  },
  country_shipping_providers_post: {
    uri: 'countryShippingProviders',
    method: 'POST',
  },
  country_shipping_providers_details: {
    uri: 'countryShippingProviders/{id}',
    method: 'GET',
  },
  country_shipping_providers_put: {
    uri: 'countryShippingProviders/{id}',
    method: 'PUT',
  },
  country_shipping_providers_delete: {
    uri: 'countryShippingProviders/{id}',
    method: 'DELETE',
  },
  feature_configuration_put: {
    uri: 'featureConfiguration/{id}',
    method: 'PUT',
  },
  order_problem_types_post: {
    uri: 'orderProblemTypes',
    method: 'POST',
  },
  order_problem_types: {
    uri: 'orderProblemTypes/list',
    method: 'POST',
  },
  order_problem_types_get: {
    uri: 'orderProblemTypes/',
    method: 'GET',
  },
  order_problem_type_details: {
    uri: 'orderProblemTypes/{id}',
    method: 'GET',
  },
  order_problem_type_put: {
    uri: 'orderProblemTypes/{id}',
    method: 'PUT',
  },
  order_problem_type_delete: {
    uri: 'orderProblemTypes/{id}',
    method: 'DELETE',
  },
  upload: {
    uri: 'file-upload',
    method: 'POST',
  },
  upload_optimized: {
    uri: 'file-upload/optimized',
    method: 'POST',
  },
  order_problem_post: {
    uri: 'orderProblems',
    method: 'POST',
  },
  order_problems: {
    uri: 'orderProblems/list',
    method: 'POST',
  },
  order_problem_details: {
    uri: 'orderProblems/{id}',
    method: 'GET',
  },
  order_problem_put: {
    uri: 'orderProblems/{id}',
    method: 'PUT',
  },
  order_problem_delete: {
    uri: 'orderProblems/{id}',
    method: 'DELETE',
  },
  sync_statuses: {
    uri: 'sync-status/all',
    method: 'POST',
  },
  sync_statuses_name: {
    uri: 'sync-status/{name}',
    method: 'GET',
  },
  sync_status_post: {
    uri: 'sync-status',
    method: 'POST',
  },
  run_sync: {
    uri: 'sync-status/run',
    method: 'POST',
  },
  order_items: {
    uri: 'orderItems/list',
    method: 'POST',
  },
  order_items_by_product: {
    uri: 'orderItems/list-by-product',
    method: 'POST',
  },
  order_items_put: {
    uri: 'orderItems/{id}',
    method: 'PUT',
  },
  order_items_details: {
    uri: 'orderItems/{id}',
    method: 'GET',
  },
  product_by_shopware_id: {
    uri: 'products/shopwareId/{id}',
    method: 'GET',
  },
  create_supplier: {
    uri: 'suppliers',
    method: 'POST',
  },
  get_supplier_by_id: {
    uri: 'suppliers/{id}',
    method: 'GET',
  },
  update_supplier: {
    uri: 'suppliers/{id}',
    method: 'PUT',
  },
  supplier_order_items: {
    uri: 'supplierOrderItems/list',
    method: 'POST',
  },
  supplier_order_items_details: {
    uri: 'supplierOrderItems/{id}',
    method: 'GET',
  },
  supplier_order_items_delete: {
    uri: 'supplierOrderItems/{id}',
    method: 'DELETE',
  },
  supplier_order_items_put: {
    uri: 'supplierOrderItems/{id}',
    method: 'PUT',
  },
  supplier_order_items_without_stock_put: {
    uri: 'supplierOrderItems/update-items-without-stock/{id}',
    method: 'PUT',
  },
  supplier_pending_order_items: {
    uri: 'supplierOrderItems/pendingOrders/suppliers',
    method: 'GET',
  },
  supplier_order_items_toggle: {
    uri: 'supplierOrderItems/toggle-purchase-list/{id}/{purchaseList}',
    method: 'PUT',
  },
  add_order_item_to_purchase_list: {
    uri: 'supplierOrderItems/add-order-item-to-purchase-list',
    method: 'POST',
  },
  suppliers_by_product: {
    uri: 'suppliers/product/{id}',
    method: 'GET',
  },
  supplier_autocomplete_list: {
    uri: 'suppliers/autocomplete',
    method: 'GET',
  },
  product_autocomplete_list: {
    uri: 'products/autocomplete',
    method: 'POST',
  },
  country_autocomplete_list: {
    uri: 'countryShippingProviders/autocomplete',
    method: 'GET',
  },
  supplier_articles_by_supplier_and_product: {
    uri: 'supplierArticles/supplier/{supplierId}/product/{productId}',
    method: 'GET',
  },
  add_new_manual_supplier_article: {
    uri: 'supplierArticles/manual/new',
    method: 'POST',
  },
  update_manual_supplier_article: {
    uri: 'supplierArticles/manual/update',
    method: 'POST',
  },
  matching_articles: {
    uri: 'supplierArticles/matching',
    method: 'POST',
  },
  matching_articles_by_mpn: {
    uri: 'supplierArticles/matching-mpns',
    method: 'POST',
  },
  supplier_order_post: {
    uri: 'supplierOrders/createOrder/{supplierId}',
    method: 'POST',
  },
  supplier_orders: {
    uri: 'supplierOrders/list',
    method: 'POST',
  },
  supplier_orders_due_report: {
    uri: 'supplierOrders/due-report',
    method: 'POST',
  },
  generate_supplier_payments: {
    uri: 'supplierOrders/generate-supplier-payments',
    method: 'POST',
  },
  supplier_order_details: {
    uri: 'supplierOrders/{id}',
    method: 'GET',
  },
  supplier_orders_complete_order: {
    uri: 'supplierOrders/complete-order/{id}',
    method: 'POST',
  },
  supplier_orders_send_email: {
    uri: 'supplierOrders/send-email/{id}',
    method: 'POST',
  },
  unfinished_supplier_order_products: {
    uri: 'supplierOrders/unfinished',
    method: 'POST',
  },
  unfinished_supplier_order_items: {
    uri: 'supplierOrders/unfinished-order-items',
    method: 'POST',
  },
  customer_returns: {
    uri: 'customerReturns/list',
    method: 'POST',
  },
  customer_returns_post: {
    uri: 'customerReturns/create',
    method: 'POST',
  },
  customer_return_add_product: {
    uri: 'customerReturns/add-product',
    method: 'POST',
  },
  customer_return_previous: {
    uri: 'customerReturns/previous',
    method: 'POST',
  },
  customer_return_single: {
    uri: 'customerReturns/previous/single',
    method: 'POST',
  },
  download_payment_xml: {
    uri: 'supplierOrders/download-payment-xml',
    method: 'POST',
  },
  order_items_scan_send: {
    uri: 'orderItems/{id}/scan-send',
    method: 'POST',
  },
  orders_get_order_items: {
    uri: 'orders/get-order-items/{id}',
    method: 'GET',
  },
  orders_get_order_items_for_return: {
    uri: 'orders/get-order-items-for-return/{id}',
    method: 'GET',
  },
  orders_handle_scanned_order_items: {
    uri: 'orders/handle-scanned-order-items/{id}',
    method: 'POST',
  },
  orders_handle_returned_scanned_order_items: {
    uri: 'orders/handle-returned-scanned-order-items/{id}',
    method: 'POST',
  },
  products_bechlem_sync: {
    uri: 'products/sync-bechlem',
    method: 'POST',
  },
  orders_by_product_id: {
    uri: 'orders/by-productId',
    method: 'POST',
  },
  create_exchange_order: {
    uri: 'orders/create-exchange-order',
    method: 'POST',
  },
  detailed_orders_by_product_id: {
    uri: 'orders/detailed-by-productId',
    method: 'POST',
  },
  multi_box_orders_by_product_id: {
    uri: 'orders/scanned-as-multi-box',
    method: 'POST',
  },
  amazon_config_get: {
    uri: 'pricing/amazon-variables',
    method: 'GET',
  },
  amazon_config_put: {
    uri: 'pricing/update-amazon-variable',
    method: 'POST',
  },
  products_post: {
    uri: 'products',
    method: 'POST',
  },
  orders_ready_for_shipping: {
    uri: 'orders/ready-for-shipping',
    method: 'POST',
  },
  orders_ready_for_shipping_custom: {
    uri: 'orders/ready-for-shipping-custom',
    method: 'POST',
  },
  get_custom_ready_for_shipping_product_lists: {
    uri: 'orders/custom-lists/product/all',
    method: 'GET',
  },
  get_custom_ready_for_shipping_lists: {
    uri: 'orders/custom-lists/all',
    method: 'GET',
  },
  create_custom_list: {
    uri: 'orders/custom-lists/create',
    method: 'POST',
  },
  get_custom_list: {
    uri: 'orders/custom-lists/{name}',
    method: 'GET',
  },
  delete_custom_list: {
    uri: 'orders/custom-lists/delete',
    method: 'POST',
  },
  delete_custom_list_product: {
    uri: 'orders/custom-lists/delete-product',
    method: 'POST',
  },
  add_custom_list_product: {
    uri: 'orders/custom-lists/add-product',
    method: 'POST',
  },
  orders_delivery_bill: {
    uri: 'orders/delivery-bill/{id}/parcel/{parcelIndex}',
    method: 'GET',
  },
  supplier_orders_get_order_items: {
    uri: 'supplierOrders/get-supplier-order-items/{id}',
    method: 'GET',
  },
  supplier_orders_handle_scanned_order_items: {
    uri: 'supplierOrders/handle-scanned-order-items/{id}',
    method: 'POST',
  },
  supplier_orders_manually_complete: {
    uri: 'supplierOrders/complete-supplier-order/{id}',
    method: 'GET',
  },
  supplier_orders_cancel: {
    uri: 'supplierOrders/cancel/{id}',
    method: 'GET',
  },
  supplier_orders_reopen: {
    uri: 'supplierOrders/reopen-supplier-order/{id}',
    method: 'GET',
  },
  supplier_orders_pdf: {
    uri: 'supplierOrders/pdf/{id}',
    method: 'GET',
  },
  orders_push_tracking_number: {
    uri: 'orders/push-tracking-number/{id}',
    method: 'POST',
  },
  order_items_resolve: {
    uri: 'orderItems/without-products/resolve',
    method: 'POST',
  },
  verify_token: {
    uri: 'auth/verify-token',
    method: 'POST',
  },
  orders_amazon_connection_status: {
    uri: 'orders/amazon/connection-status',
    method: 'GET',
  },
  sync_connection_status: {
    uri: 'sync-connection-status',
    method: 'GET',
  },
  shopware_connection_status: {
    uri: 'shopware-connection-status',
    method: 'GET',
  },
  buffer_queue_info: {
    uri: 'buffer-queue-info',
    method: 'GET',
  },
  shop_config: {
    uri: 'shop-config',
    method: 'GET',
  },
  shop_config_factors: {
    uri: 'shop-config/pricing-factors',
    method: 'GET',
  },
  product_excluded_suppliers_put: {
    uri: 'products/update-excluded-suppliers/{id}',
    method: 'PUT',
  },
  product_included_suppliers_put: {
    uri: 'products/update-included-suppliers/{id}',
    method: 'PUT',
  },
  supplier_custom_order_create: {
    uri: 'supplierOrders/createCustomOrder',
    method: 'POST',
  },
  supplier_articles_unlink: {
    uri: 'supplierArticles/unlink-product-relation/{id}',
    method: 'PUT',
  },
  orders_amazon_tracking_numbers_info: {
    uri: 'orders/amazon/tracking-numbers-info',
    method: 'POST',
  },
  amazon_submissions_list: {
    uri: 'amazonSubmissions/list',
    method: 'POST',
  },
  orders_set_as_completed_manually: {
    uri: 'orders/set-as-completed-manually/{id}',
    method: 'PUT',
  },
  activity_log_list: {
    uri: 'activity-log/list',
    method: 'POST',
  },
  supplier_order_item_create: {
    uri: 'supplierOrderItems/create',
    method: 'POST',
  },
  increase_manual_need_for_required_product: {
    uri: 'required-product/increase/{id}/{quantity}',
    method: 'PUT',
  },
  upload_shipping_label: {
    uri: 'orders/upload_shipping_label/{id}',
    method: 'PUT',
  },
  non_eu_orders: {
    method: 'POST',
    uri: 'orders/non-eu-list',
  },
  regenerate_supplier_order_suggestion: {
    uri: 'required-product/regenerate-supplier-order-suggestion/{id}',
    method: 'POST',
  },
  feature_configuration_patch_put: {
    uri: 'featureConfiguration/{id}',
    method: 'PATCH',
  },
  get_custom_fields_entities: {
    uri: 'featureConfiguration/custom-field-entities',
    method: 'GET',
  },
  update_amazon_manufacturer_variable: {
    uri: 'pricing/update-amazon-manufacturer',
    method: 'POST',
  },
  orders_patch: {
    method: 'PATCH',
    uri: 'orders/{id}',
  },
  order_status_patch: {
    method: 'PATCH',
    uri: 'orders/{id}/status',
  },
  order_due_date_patch: {
    method: 'PATCH',
    uri: 'orders/{id}/due-date',
  },
  update_order: {
    method: 'POST',
    uri: 'orders/update-order/{id}',
  },
  convert_offer_to_order: {
    method: 'POST',
    uri: 'orders/convert-offer/{id}',
  },
  customer_shipping_addresses: {
    method: 'GET',
    uri: 'customers/shipping-addresses/{customerId}',
  },
  orders_export_csv: {
    uri: 'orders/export/csv',
    method: 'POST',
  },
  products_export_csv: {
    uri: 'products/export/csv',
    method: 'POST',
  },
  orders_cancel: {
    uri: 'orders/cancel-order/{id}',
    method: 'PUT',
  },
  purchase_list_total: {
    uri: 'supplierOrderItems/purchase-list/total',
    method: 'POST',
  },
  order: {
    uri: 'supplierOrderItems/purchase-list/total',
    method: 'POST',
  },
  orders_pdf_offer: {
    uri: 'orders/offer-pdf/{type}/{id}',
    method: 'GET',
  },
  easybill_invoice_retry: {
    uri: 'orders/easybill/invoice-retry/{id}',
    method: 'PUT',
  },
  orders_overdue_invoices: {
    uri: 'orders/easybill/overdue-invoices-list',
    method: 'POST',
  },
  orders_easybill_export_xml: {
    uri: 'orders/easybill/export-xml',
    method: 'GET',
  },
  set_empty_tracking: {
    uri: 'orders/set-empty-tracking/{id}',
    method: 'GET',
  },
  set_purchase_price: {
    uri: 'orderItems/set-purchase-price',
    method: 'POST',
  },
  pdf_inventory_list: {
    uri: 'products/pdf/inventory-list',
    method: 'GET',
  },
  supplier_order_items_by_order_id: {
    uri: 'supplierOrderItems/supplier-order/{id}',
    method: 'GET',
  },
  supplier_order_items_update_list: {
    uri: 'supplierOrderItems/update-list',
    method: 'PATCH',
  },
  customers_payment_reminder_list: {
    uri: 'orders/customers/payment-reminder-list',
    method: 'POST',
  },
  customers_critical_overdue_list: {
    uri: 'orders/customers/critical-overdue-list',
    method: 'POST',
  },
  open_invoices_list: {
    uri: 'orders/customers/open-invoices-list',
    method: 'POST',
  },
  set_invoice_status: {
    uri: 'orders/set-invoice-status/{id}',
    method: 'POST',
  },
  countries_find_list: {
    uri: 'countryShippingProviders/find-country-list',
    method: 'POST',
  },
  orders_easybill_invoice: {
    uri: 'orders/easybill-invoice/{id}',
    method: 'GET',
  },
  merge_customers: {
    uri: 'customers/merge-customers',
    method: 'POST',
  },
  orders_delivery_confirmation: {
    uri: 'orders/delivery-confirmation/pdf/{id}',
    method: 'POST',
  },
  finish_manually_completed_order: {
    uri: 'orders/finish-manually-completed-order/{id}',
    method: 'POST',
  },
  orders_easybill_issues: {
    method: 'POST',
    uri: 'orders/easybill-issues',
  },
  generate_shipping_label: {
    method: 'GET',
    uri: 'orders/{id}/generate-shipping-label',
  },
  orders_send_email: {
    uri: 'orders/send-email',
    method: 'POST',
  },
  auth_generate_2fa_code: {
    uri: 'auth/generate-2fa-code',
    method: 'PATCH',
  },
  auth_2fa_code_generated: {
    uri: 'auth/is-2fa-configured',
    method: 'GET',
  },
  products_descriptions_by_id: {
    uri: 'products/{id}/descriptions',
    method: 'GET',
  },
  products_description_save: {
    uri: 'products/{id}/description/save',
    method: 'PATCH',
  },
  products_remove_multibox: {
    uri: 'products/{id}/remove-multiBox/{multiBoxId}',
    method: 'PATCH',
  },
  users: {
    uri: 'users/list',
    method: 'POST',
  },
  users_patch: {
    uri: 'users/{id}',
    method: 'PATCH',
  },
  users_delete: {
    uri: 'users/{id}',
    method: 'DELETE',
  },
  users_post: {
    uri: 'users',
    method: 'POST',
  },
  products_to_reactivate: {
    uri: 'products/products-to-reactivate',
    method: 'POST',
  },
  orders_proforma: {
    uri: 'orders/proforma/{id}',
    method: 'GET',
  },
  supplier_order_items_backlog: {
    uri: 'supplierOrderItems/backlog',
    method: 'POST',
  },
  supplier_order_items_move_to_backlog: {
    uri: 'supplierOrderItems/move-to-backlog/{id}',
    method: 'PATCH',
  },
  supplier_order_items_move_to_order: {
    uri: 'supplierOrderItems/move-to-order/{id}',
    method: 'PATCH',
  },
  upload_supplier_order_invoice: {
    uri: 'supplierOrders/upload-invoice/{id}',
    method: 'POST',
  },
  supplier_order_patch: {
    uri: 'supplierOrders/{id}',
    method: 'PATCH',
  },
  supplier_order_invoices: {
    uri: 'supplierOrders/invoices',
    method: 'POST',
    responseType: 'blob',
  },
  supplier_order_winterholt_csv: {
    uri: 'supplierOrders/winterholt/{id}',
    method: 'GET',
  },
  ebay_pricing_factors: {
    uri: 'pricing/ebay-pricing-factors',
    method: 'GET',
  },
  ebay_pricing_factors_update: {
    uri: 'pricing/ebay-pricing-factors',
    method: 'PATCH',
  },
  update_product_to_ebay: {
    uri: 'ebay/update-product/{id}',
    method: 'POST',
  },
  publish_product_to_ebay: {
    uri: 'ebay/publish-product/{id}',
    method: 'POST',
  },
  unpublish_from_ebay: {
    uri: 'ebay/unpublish-product/{id}',
    method: 'POST',
  },
  pricing_items_list: {
    uri: 'pricing-items/list',
    method: 'POST',
  },
  galaxus_pricing_factors: {
    uri: 'pricing/galaxus-pricing-factors',
    method: 'GET',
  },
  galaxus_pricing_factors_update: {
    uri: 'pricing/galaxus-pricing-factors',
    method: 'PATCH',
  },
  countries_taxes: {
    uri: 'countryShippingProviders/countries-taxes',
    method: 'GET',
  },
  order_id_by_scanned_product: {
    uri: 'orders/order-id-by-scanned-product/{barCode}',
    method: 'GET',
  },
  shipping_create_label: {
    uri: 'shipping/create-label/{id}',
    method: 'POST',
  },
  shipping_create_return_label: {
    uri: 'shipping/create-return-label/{id}',
    method: 'POST',
  },
  delete_pricelist_info: {
    uri: 'suppliers/delete-supplier-price-list/{id}',
    method: 'PATCH',
  },
  todo_lists: {
    uri: 'todo-list/user-lists',
    method: 'GET',
  },
  create_todo_list: {
    uri: 'todo-list/create',
    method: 'POST',
  },
  update_todo_list: {
    uri: 'todo-list/update-list/{id}',
    method: 'PATCH',
  },
  delete_todo_list: {
    uri: 'todo-list/delete-list/{id}',
    method: 'DELETE',
  },
  todo_tasks: {
    uri: 'todo-list/tasks/{id}',
    method: 'GET',
  },
  todo_tasks_upcoming: {
    uri: 'todo-list/upcoming-tasks',
    method: 'GET',
  },
  todo_tasks_serial: {
    uri: 'todo-list/serial-tasks',
    method: 'GET',
  },
  create_todo_task: {
    uri: 'todo-list/create-task',
    method: 'POST',
  },
  update_todo_task: {
    uri: 'todo-list/update/task/{id}',
    method: 'PATCH',
  },
  update_todo_put_task: {
    uri: 'todo-list/update/task/{id}',
    method: 'PUT',
  },
  complete_todo_task: {
    uri: 'todo-list/complete-task/{id}',
    method: 'POST',
  },
  todo_lists_serial: {
    uri: 'todo-list/serial',
    method: 'GET',
  },
  todo_lists_upcoming: {
    uri: 'todo-list/upcoming',
    method: 'GET',
  },
  search_todo_tasks: {
    uri: 'todo-list/search',
    method: 'POST',
  },
  get_usernames: {
    uri: 'users/usernames',
    method: 'GET',
  },
  get_users: {
    uri: 'users/get-users',
    method: 'GET',
  },
  ebay_for_multi_variant_item: {
    uri: 'ebay/{id}/multi-variant-items',
    method: 'GET',
  },
  ebay_create_multi_variant: {
    uri: 'ebay/create-multi-variant',
    method: 'POST',
  },
  ebay_multi_variant_list: {
    uri: 'ebay/multi-variant-list',
    method: 'POST',
  },
  ebay_multi_variant_product: {
    uri: 'ebay/multi-variant-product/{id}',
    method: 'GET',
  },
  publish_multi_variant_to_ebay: {
    uri: 'ebay/publish-multi-variant/{id}',
    method: 'POST',
  },
  unpublish_multi_variant_from_ebay: {
    uri: 'ebay/unpublish-multi-variant/{id}',
    method: 'POST',
  },
  ebay_update_excluded_categories: {
    uri: 'ebay/update-excluded-categories',
    method: 'PUT',
  },
  open_supplier_orders: {
    uri: 'supplierOrders/open-supplier-orders',
    method: 'POST',
  },
  pricing_factors: {
    uri: 'pricing/pricing-factors/{featureName}',
    method: 'GET',
  },
  pricing_factors_update: {
    uri: 'pricing/pricing-factors/{featureName}',
    method: 'PATCH',
  },
  update_excluded_categories: {
    uri: 'pricing/update-excluded-categories/{featureName}',
    method: 'PUT',
  },
  update_custom_config: {
    uri: 'pricing/update-custom-config/{featureName}',
    method: 'PUT',
  },
  custom_config: {
    uri: 'featureConfiguration/custom-config/{featureName}',
    method: 'GET',
  },
  set_shipping_provider: {
    uri: 'shipping/set-shipping-provider',
    method: 'PUT',
  },
  merge_supplier_orders: {
    uri: 'supplierOrders/merge-supplier-orders',
    method: 'POST',
  },
  orders_easybill_invoice_jpg: {
    uri: 'orders/easybill-invoice-jpg/{id}',
    method: 'GET',
  },
  orders_ebay_tracking_numbers_info: {
    uri: 'ebay/tracking-numbers-info',
    method: 'POST',
  },
  products_add_multibox: {
    uri: 'products/{id}/add-multiBox',
    method: 'POST',
  },
  orders_create_credit_note: {
    uri: 'orders/create-credit-note-manually/{id}',
    method: 'POST',
  },
  upload_return_image: {
    uri: 'orders/upload-return-image/{id}',
    method: 'POST',
  },
  orders_return_image: {
    uri: 'orders/return-image',
    method: 'POST',
  },
  confirm_stock_for_product: {
    uri: 'products/confirm-stock/{id}',
    method: 'POST',
  },
  ebay_multi_variant_delete: {
    uri: 'ebay/multi-variant-product/{id}',
    method: 'DELETE',
  },
  get_next_order: {
    uri: 'orders/get-next-order',
    method: 'POST',
  },
  add_comment_to_product: {
    uri: 'products/add-comment/{id}',
    method: 'POST',
  },
  get_shopware_differences_csv: {
    uri: 'test/test-differences/{shopName}',
    method: 'GET',
  },
  change_supplier_on_customer_complaint: {
    uri: 'customerReturns/change-supplier',
    method: 'POST',
  },
  customer_return_delete: {
    uri: 'customerReturns/{id}',
    method: 'DELETE',
  },
  fetch_order_from_amazon: {
    uri: 'orders/fetch-order-from-amazon/{orderNumber}',
    method: 'GET',
  },
  product_statuses: {
    uri: 'products/{id}/statuses',
    method: 'GET',
  },
  product_status_update: {
    uri: 'products/update-shop-status/{id}',
    method: 'PATCH',
  },
  ebay_sync_offer_templates: {
    uri: 'ebay/sync-offer-templates',
    method: 'POST',
  },
  shipping_create_second_label: {
    uri: 'shipping/create-second-label/{id}',
    method: 'POST',
  },
  error_logs: {
    uri: 'error-log/list',
    method: 'POST',
  },
  error_logs_delete: {
    uri: 'error-log/{id}',
    method: 'DELETE',
  },
  error_logs_id: {
    uri: 'error-log/{id}',
    method: 'GET',
  },
  products_get_all_shop_statuses: {
    uri: 'products/get-all-products-shop-statuses',
    method: 'POST',
  },
  products_get_products_statuses_and_excluded_pricing: {
    uri: 'products/get-products-statuses-and-excluded-pricing',
    method: 'POST',
  },
  supplier_complaints_list: {
    uri: 'supplier-complaints/list',
    method: 'POST',
  },
  supplier_complaint_create: {
    uri: 'supplier-complaints/{supplierName}/create',
    method: 'POST',
  },
  supplier_complaint_get: {
    uri: 'supplier-complaints/{id}',
    method: 'GET',
  },
  supplier_complaint_patch: {
    uri: 'supplier-complaints/{id}',
    method: 'PATCH',
  },
  upload_supplier_complaint_invoice: {
    uri: 'supplier-complaints/upload-invoice/{id}',
    method: 'POST',
  },
  create_change_log: {
    uri: 'change-log/create',
    method: 'POST',
  },
  get_change_logs: {
    uri: 'change-log/all',
    method: 'GET',
  },
  ebay_create_promotion: {
    uri: 'ebay/create-promotion',
    method: 'POST',
  },
  ebay_delete_promotion: {
    uri: 'ebay/delete-promotion',
    method: 'POST',
  },
  create_order_from_backlog: {
    uri: 'supplierOrders/create-order-from-backlog',
    method: 'POST',
  },
  create_custom_field: {
    uri: 'featureConfiguration/create-custom-field',
    method: 'POST',
  },
  get_custom_field_entities: {
    uri: 'featureConfiguration/custom-field-entities',
    method: 'GET',
  },
  get_custom_field_entity: {
    uri: 'featureConfiguration/custom-field-entities/{entityType}',
    method: 'GET',
  },
  get_custom_field_entity_fields: {
    uri: 'featureConfiguration/custom-field-entities/{entityType}/fields',
    method: 'GET',
  },
  delete_custom_field: {
    uri: 'featureConfiguration/delete-custom-field',
    method: 'POST',
  },
  create_service_order: {
    uri: 'supplierOrders/create-service-order',
    method: 'POST',
  },
  file_get: {
    uri: 'file-upload/get-file',
    method: 'POST',
  },
  create_credit_note: {
    uri: 'supplierOrders/create-credit-note',
    method: 'POST',
  },
  update_products_shop_status: {
    uri: 'products/update-products-shop-status',
    method: 'POST',
  },
  ebay_auth_url: {
    uri: 'ebay/auth-url',
    method: 'GET',
  },
  sync_statuses_turn_on_all: {
    uri: 'sync-status/turn-on-all',
    method: 'PATCH',
  },
  sync_statuses_turn_off_all: {
    uri: 'sync-status/turn-off-all',
    method: 'PATCH',
  },
  mark_supplier_orders_as_paid: {
    uri: 'supplierOrders/mark-as-paid',
    method: 'POST',
  },
  get_user_completed_tasks_list: {
    uri: 'todo-list/user-completed-tasks',
    method: 'POST',
  },
  complete_subtask: {
    uri: 'todo-list/complete-subtask/{id}',
    method: 'POST',
  },
  uncomplete_todo_task: {
    uri: 'todo-list/uncomplete-task/{id}',
    method: 'POST',
  },
  save_todo_receiver: {
    uri: 'featureConfiguration/save-todo-receiver',
    method: 'POST',
  },
  supplier_order_by_scanned_label: {
    uri: 'supplierOrders/by-scanned-label/{label}',
    method: 'GET',
  },
  supplier_order_scan_shipping_label: {
    uri: 'supplierOrders/scan-shipping-label',
    method: 'POST',
  },
  scanned_labels_list: {
    uri: 'supplierOrders/scanned-labels-list',
    method: 'POST',
  },
  get_supplier_order_by_scanned_label: {
    uri: 'supplierOrders/order-by-tracking-number',
    method: 'POST',
  },
  product_set_multibox: {
    uri: 'products/set-multi-box',
    method: 'POST',
  },
  undelivered_dhl_orders: {
    uri: 'orders/undelivered-dhl-orders',
    method: 'POST',
  },
  products_stock_comparision: {
    uri: 'products/stock-comparison',
    method: 'POST',
  },
  get_exchange_order_invoice_data: {
    uri: 'orders/get-exchange-order-invoice-data/{id}',
    method: 'GET',
  },
  create_invoice_for_exchange_order: {
    uri: 'orders/create-invoice-for-exchange-order/{id}',
    method: 'POST',
  },
  ebay_multi_variants_to_republish: {
    uri: 'ebay/multi-variants-to-republish',
    method: 'POST',
  },
  ebay_products_to_republish: {
    uri: 'ebay/products-to-republish',
    method: 'POST',
  },
  match_mpns_and_eans: {
    uri: 'products/match-mpns-and-eans/supplier/{id}',
    method: 'GET',
  },
  supplier_article_approve_multiple: {
    uri: 'supplierArticles/approve-multiple',
    method: 'POST',
  },
  supplier_article_ignore_multiple: {
    uri: 'supplierArticles/ignore-multiple',
    method: 'POST',
  },
  get_static_files: {
    uri: 'file-upload/static-files',
    method: 'GET',
  },
  download_product_import_template: {
    uri: 'test/download-template',
    method: 'GET',
  },
  ebay_category_autocomplete_list: {
    uri: 'ebay/category-ids-autocomplete',
    method: 'GET',
  },
  pricing_product_type_list: {
    uri: 'products/product-type/autocomplete/list',
    method: 'GET',
  },
  update_pricing_factors: {
    uri: 'pricing/pricing-factors/bulk/update',
    method: 'PATCH',
  },
  sync_deactivate: {
    uri: 'sync-status/deactivate/{syncName}',
    method: 'PATCH',
  },
  sync_activate: {
    uri: 'sync-status/activate/{syncName}',
    method: 'PATCH',
  },
  sync_status_patch: {
    uri: 'sync-status/update/{syncName}',
    method: 'PATCH',
  },
  sync_status_config_update: {
    uri: 'sync-status/update-config/{syncName}',
    method: 'PATCH',
  },
  suppliers_emails: {
    uri: 'suppliers/emails/get',
    method: 'GET',
  },
  product_manufacturers: {
    uri: 'products/manufacturers/list',
    method: 'GET',
  },
  manufacturer_image_upload: {
    uri: 'products/manufacturers/{name}/upload-image',
    method: 'POST',
  },
  manufacturer_image_patch: {
    uri: 'products/manufacturers/patch',
    method: 'PATCH',
  },
  product_manufacturers_list: {
    uri: 'products/manufacturers/list',
    method: 'POST',
  },
  products_stock_per_day: {
    uri: 'products/products-stock-per-day/list',
    method: 'POST',
  },
  pricing_shopware_shop_tax_update: {
    uri: 'pricing/shopware-shop/{shopConfigId}/update',
    method: 'PATCH',
  },
  undelivered_ups_orders: {
    uri: 'orders/undelivered-ups-orders',
    method: 'POST',
  },
  supplier_order_prices_per_month: {
    uri: 'supplierOrders/prices-per-month/csv',
    method: 'POST',
    responseType: 'blob',
  },
  activity_log_list_with_products: {
    uri: 'activity-log/list-with-populated-products',
    method: 'POST',
  },
  manufacturer_image_delete: {
    uri: 'products/manufacturers/image/{id}',
    method: 'DELETE',
  },
  ebay_update_inventory_item_group: {
    uri: 'ebay/update-inventory-item-group',
    method: 'PUT',
  },
  change_intern_tracking_status: {
    uri: 'shipping/intern-tracking-status/{id}',
    method: 'PATCH',
  },
  sync_stop: {
    uri: 'sync-status/stop/{syncName}',
    method: 'PATCH',
  },
  ebay_required_fields: {
    uri: 'ebay/category/{id}/required-fields',
    method: 'GET',
  },
  upload_product_information: {
    uri: 'products/upload-product-information/{language}/{id}',
    method: 'POST',
  },
  delete_product_information: {
    uri: 'products/delete-product-information/{language}/{id}',
    method: 'PATCH',
  },
  sync_product_information: {
    uri: 'products/sync-security-information-to-shopware/{id}',
    method: 'PATCH',
  },
};

export type Endpoint = keyof typeof ENDPOINTS;

export {ENDPOINTS};
