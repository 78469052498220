import React, {useState} from 'react';
import DataTable from '../../components/Table/Table';
import {useTranslation} from 'react-i18next';
import UpdateManualSupplierArticleButton from '../../components/SupplierArticle/UpdateManualSupplierArticleButton';
import AddNewManualSupplierArticleButton from '../../components/SupplierArticle/AddNewManualSupplierArticleButton';

const ManualSupplierArticle = () => {
  const [triggerRefreshTable, setTriggerRefreshTable] = useState<any>(null);
  const {t} = useTranslation();
  const options = {manual: true};

  return (
    <div className="supplier-articles">
      <DataTable
        className="new-supplier__table"
        headers={[
          {
            kind: 'accessor',
            name: t('newArticles.supplierName'),
            accessor: 'supplierName',
            sortable: true,
          },
          {
            kind: 'accessor',
            name: t('newArticles.articleTitle'),
            accessor: 'title',
            sortable: true,
          },
          {
            kind: 'accessor',
            name: t('activatedArticles.price'),
            accessor: 'price',
            sortable: true,
            format: 'price',
            type: 'number',
          },
          {
            kind: 'accessor',
            name: t('activatedArticles.quantity'),
            accessor: 'quantity',
            sortable: true,
            type: 'number',
          },
          {
            kind: 'component',
            name: t('newArticles.actions'),
            component: UpdateManualSupplierArticleButton,
            props: {setTriggerRefreshTable: (productId: string) => setTriggerRefreshTable(productId)},
            onFetchProps: {conditions: {status: 'new'}},
          },
        ]}
        endpoint={'supplier_articles'}
        options={options}
        refreshData={triggerRefreshTable}
        target={`products`}
        selectRowClickId={'product'}
        title={t('manualArticles.screenTitle')}
        actionButtons={<AddNewManualSupplierArticleButton setTriggerRefreshTable={setTriggerRefreshTable} />}
      />
    </div>
  );
};

export default ManualSupplierArticle;
