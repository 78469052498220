import {useState, useEffect} from 'react';
import {api} from '../modules/api/ApiFactory';

export const useUserId = () => {
  const [userId, setUserId] = useState<string>('');

  useEffect(() => {
    const fetchUserId = async () => {
      const fetchedUserId = await api.getUserId();
      setUserId(fetchedUserId);
    };
    fetchUserId();
  }, []);

  return {userId};
};
