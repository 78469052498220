import React, {useState, useEffect, useRef, useContext} from 'react';
import {
  Button,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import {MuiPickersUtilsProvider, TimePicker} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import {useTranslation} from 'react-i18next';
import {ITodoTask, SubTask} from '../../types/Todo/ITodoTask';
import TodoContext from '../../screens/Todo/Context/TodoContext';
import {ScreenType} from '../../types/Todo/ITodoScreen';

const AddNewTask = () => {
  const {
    usernames,
    selectedList,
    editingTodo,
    updateTodoTask,
    updateTodoTaskResponse,
    createTodoTask,
    createTodoTaskResponse,
    screen,
  } = useContext(TodoContext);
  const {t, i18n} = useTranslation();
  const [newTodoName, setNewTodoName] = useState<string>('');
  const [newTodoDescription, setNewTodoDescription] = useState<string>('');
  const [serial, setSerial] = useState(false);
  const [selectedUsername, setSelectedUsername] = useState<string | null>(null);
  const [scheduleType, setScheduleType] = useState<string>('DAY'); // DAY, WEEK, MONTH, YEAR
  const [scheduleFrom, setScheduleFrom] = useState<Date | null>(null);
  const [editingMode, setEditingMode] = useState(false);
  const [editingTaskId, setEditingTaskId] = useState<string | null>(null);
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const [selectedTime, setSelectedTime] = useState<Date | null>(null);
  const [dateSet, setDateSet] = useState(false);
  const [timeSet, setTimeSet] = useState(false);
  const [showNewTaskInput, setShowNewTaskInput] = useState(false);
  const [subtasks, setSubtasks] = useState<SubTask[]>([]);
  const [everyXDays, setEveryXDays] = useState<number>(1);
  const [specificDays, setSpecificDays] = useState<string[]>([]);
  const [dayInMonth, setDayInMonth] = useState<number | null>(null);
  const [subtaskName, setSubtaskName] = useState<string>('');
  const [newListName, setNewListName] = useState<string>('');
  const nameInputRef: any = useRef(null);

  const language = i18n.language;

  const scheduleTypes = {
    DAY: 'DAY',
    WEEK: 'WEEK',
    MONTH: 'MONTH',
    YEAR: 'YEAR',
    DAY_SPECIFIC: 'DAY_SPECIFIC',
    EVERY_X_DAYS: 'EVERY_X_DAYS',
    EVERY_X_IN_MONTH: 'EVERY_X_IN_MONTH',
  };

  const days: {[key: string]: string} = {
    sunday: 'Sonntag',
    monday: 'Montag',
    tuesday: 'Dienstag',
    wednesday: 'Mittwoch',
    thursday: 'Donnerstag',
    friday: 'Freitag',
    saturday: 'Samstag',
  };

  const removeDayAndTimeFromName = (name: string): string => {
    for (const day in days) {
      const dayPattern = new RegExp(`\\b${day}\\b`, 'i');
      const germanDayPattern = new RegExp(`\\b${days[day]}\\b`, 'i');
      if (dayPattern.test(name)) {
        name = name.replace(dayPattern, '').trim();
        break;
      } else if (germanDayPattern.test(name)) {
        name = name.replace(germanDayPattern, '').trim();
        break;
      }
    }

    // Remove the first occurrence of time mentions in the format "at HH:MM" or "um HH:MM"
    const timePattern = /(?:at|um) (\d{1,2}:\d{2})/i;
    if (timePattern.test(name)) {
      name = name.replace(timePattern, '').trim();
    }

    return name;
  };

  const addSubtask = () => {
    if (subtaskName.trim() === '') {
      return;
    }

    const newSubtask: SubTask = {
      id: Math.floor(Math.random() * 10000000),
      name: subtaskName,
      completed: false,
    };

    setSubtasks([...subtasks, newSubtask]);
    setSubtaskName('');
  };

  const cancelNewTask = () => {
    resetInput();
    setShowNewTaskInput(false);
  };

  const createDateFromTimeString = (timeString: string) => {
    const today = new Date();

    const [hours, minutes] = timeString.split(':').map(Number);

    today.setHours(hours, minutes, 0, 0); // Sets hours, minutes, seconds, milliseconds

    return today;
  };

  const editTodoTask = (todo: ITodoTask) => {
    setNewTodoName(todo.name);
    setNewTodoDescription(todo.description);
    setSelectedDate(todo.dueDate ? new Date(todo.dueDate) : null);
    setSelectedTime(todo.dueTime ? createDateFromTimeString(todo.dueTime) : null);
    setSelectedUsername(null);
    setShowNewTaskInput(true);
    setEditingMode(true);
    setEditingTaskId(todo._id);
    setSerial(todo.serial ? true : false);
    setScheduleType(todo.serialSchedule?.type || 'DAY');
    setScheduleFrom(todo.serialSchedule?.from ? new Date(todo.serialSchedule?.from) : null);
    setSubtasks(todo.subtasks?.filter((subtask: SubTask) => !subtask.completed) || []);
    setSpecificDays(todo.serialSchedule?.specificDays || []);
    setDayInMonth(todo.serialSchedule?.dayInMonth || null);
    setEveryXDays(todo.serialSchedule?.everyXDays || 1);
  };

  const createNewTodoTask = () => {
    if (!selectedList || !newTodoName) {
      return;
    }

    const payload: any = {
      name: removeDayAndTimeFromName(newTodoName),
      description: newTodoDescription,
      serial: serial,
      list: selectedList?._id,
      subtasks: subtasks,
      dateSet,
      timeSet,
    };

    if (payload.name.trim() === '') {
      return;
    }

    if (selectedDate) {
      payload.dueDate =
        selectedDate.getFullYear() +
        '-' +
        String(selectedDate.getMonth() + 1).padStart(2, '0') +
        '-' +
        String(selectedDate.getDate()).padStart(2, '0');
    }

    if (selectedUsername) {
      payload.username = selectedUsername;
    }

    if (selectedTime) {
      payload.dueTime =
        String(selectedTime.getHours()).padStart(2, '0') + ':' + String(selectedTime.getMinutes()).padStart(2, '0');
    }

    if (payload.serial) {
      payload.scheduleType = scheduleType;
      if (scheduleFrom) {
        payload.scheduleFrom = scheduleFrom.toDateString();
      }
      if (scheduleType === 'EVERY_X_DAYS') {
        payload.everyXDays = everyXDays;
      } else if (scheduleType === 'DAY_SPECIFIC') {
        payload.specificDays = specificDays;
      } else if (scheduleType === 'EVERY_X_IN_MONTH') {
        payload.dayInMonth = dayInMonth;
      }
    }

    createTodoTask(payload);
    nameInputRef.current.focus();
  };

  const sendEditTodoRequst = () => {
    if (!selectedList || !newTodoName || !editingTaskId) {
      return;
    }

    const payload: any = {
      name: removeDayAndTimeFromName(newTodoName),
      description: newTodoDescription,
      serial: serial,
      list: selectedList?._id,
      dateSet,
      timeSet,
      subtasks,
    };

    if (payload.name.trim() === '') {
      return;
    }

    if (selectedDate) {
      payload.dueDate =
        selectedDate.getFullYear() +
        '-' +
        String(selectedDate.getMonth() + 1).padStart(2, '0') +
        '-' +
        String(selectedDate.getDate()).padStart(2, '0');
    }

    if (selectedUsername) {
      payload.username = selectedUsername;
    }

    if (selectedTime) {
      payload.dueTime =
        String(selectedTime.getHours()).padStart(2, '0') + ':' + String(selectedTime.getMinutes()).padStart(2, '0');
    }

    if (payload.serial) {
      payload.scheduleType = scheduleType;
      if (scheduleFrom) {
        payload.scheduleFrom = scheduleFrom.toDateString();
      }
    }

    updateTodoTask({id: editingTaskId, ...payload});
  };

  const save = () => {
    if (!editingMode) {
      createNewTodoTask();
    } else {
      sendEditTodoRequst();
    }
  };

  const onTodoNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewTodoName(e.target.value);
  };

  const onSubTaskNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSubtaskName(e.target.value);
  };

  useEffect(() => {
    if (createTodoTaskResponse.data) {
      resetInput();
    }
  }, [createTodoTaskResponse]);

  useEffect(() => {
    if (nameInputRef.current) {
      nameInputRef.current.focus();
    }
  }, []);

  useEffect(() => {
    if (updateTodoTaskResponse.data) {
      resetInput();
    }
  }, [updateTodoTaskResponse]);

  useEffect(() => {
    const today = new Date().getUTCDay();

    const tomorrowPattern = new RegExp('\\b(morgen|tomorrow)\\b', 'i');
    const datePattern = /\b(\d{1,2})\.(\d{1,2})(?:\.(\d{4}))?\b/;
    const nextDayPattern =
      /\b(Nächster|next)\s+(sunday|monday|tuesday|wednesday|thursday|friday|saturday|sonntag|montag|dienstag|mittwoch|donnerstag|freitag|samstag)\b/i;
    const timePattern = /(?:at|um) (\d{1,2}:\d{2})/i;

    let targetDate = null;
    let targetTime = null;

    if (tomorrowPattern.test(newTodoName)) {
      targetDate = new Date();
      targetDate.setUTCDate(targetDate.getUTCDate() + 1);
    } else if (datePattern.test(newTodoName)) {
      const dateMatch = newTodoName.match(datePattern);
      if (dateMatch) {
        const day = parseInt(dateMatch[1], 10);
        const month = parseInt(dateMatch[2], 10) - 1;
        const year = dateMatch[3] ? parseInt(dateMatch[3], 10) : new Date().getUTCFullYear();
        targetDate = new Date(Date.UTC(year, month, day));
      }
    } else if (nextDayPattern.test(newTodoName)) {
      const dayMatch = newTodoName.match(nextDayPattern);
      if (dayMatch) {
        const isNext = dayMatch[1];
        const dayName = dayMatch[2].toLowerCase();
        const daysOfWeek = [
          'sunday',
          'monday',
          'tuesday',
          'wednesday',
          'thursday',
          'friday',
          'saturday',
          'sonntag',
          'montag',
          'dienstag',
          'mittwoch',
          'donnerstag',
          'freitag',
          'samstag',
        ];
        const targetDayIndex = daysOfWeek.indexOf(dayName) % 7;
        let difference = targetDayIndex - today;
        difference = difference < 0 ? 7 + difference : difference;
        difference = isNext ? difference + 7 : difference;
        targetDate = new Date();
        targetDate.setUTCDate(targetDate.getUTCDate() + difference);
      }
    } else {
      for (const day in days) {
        const dayPattern = new RegExp(`\\b${day}\\b`, 'i');
        const germanDayPattern = new RegExp(`\\b${days[day]}\\b`, 'i');
        if (dayPattern.test(newTodoName) || germanDayPattern.test(newTodoName)) {
          const targetDay = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'].indexOf(day);
          let difference = targetDay - today;
          difference = difference <= 0 ? 7 + difference : difference;
          targetDate = new Date();
          targetDate.setUTCDate(targetDate.getUTCDate() + difference);
          break;
        }
      }
    }

    if (timePattern.test(newTodoName)) {
      const timeMatch = newTodoName.match(timePattern);
      if (timeMatch) {
        const [hours, minutes] = timeMatch[1].split(':').map(Number);
        targetTime = new Date();
        targetTime.setHours(hours);
        targetTime.setMinutes(minutes);
      }
    }

    setSelectedDate(targetDate);
    setSelectedTime(targetTime);
  }, [newTodoName]);

  useEffect(() => {
    if (selectedDate) {
      setDateSet(true);
    } else {
      setDateSet(false);
    }
  }, [selectedDate]);

  useEffect(() => {
    if (selectedTime) {
      setTimeSet(true);
    } else {
      setTimeSet(false);
    }
  }, [selectedTime]);

  const resetInput = () => {
    setNewTodoName('');
    setNewTodoDescription('');
    setNewListName('');
    setSerial(false);
    setSelectedDate(null);
    setSelectedTime(null);
    setSelectedUsername(null);
    setEditingMode(false);
    setEditingTaskId(null);
    setShowNewTaskInput(false);
    setSubtasks([]);
    setSubtaskName('');
    setScheduleType('DAY');
    setScheduleFrom(null);
    setEveryXDays(1);
    setSpecificDays([]);
    setDayInMonth(null);
  };

  useEffect(() => {
    if (showNewTaskInput) {
      nameInputRef.current.focus();
    }
  }, [showNewTaskInput]);

  useEffect(() => {
    if (editingTodo) {
      setShowNewTaskInput(true);
      setEditingMode(true);
      editTodoTask(editingTodo);
    }
  }, [editingTodo]);

  useEffect(() => {
    setShowNewTaskInput(false);
  }, [screen]);

  return (
    <div className="add-new-task">
      {!showNewTaskInput && screen.type === ScreenType.LIST && (
        <Button
          className="add-new-task-button"
          variant={'contained'}
          color={'primary'}
          onClick={() => setShowNewTaskInput(true)}
        >
          {t('todos.addNewTask')}
        </Button>
      )}
      {showNewTaskInput && (
        <div className="new-task">
          <div> {editingMode ? t('todos.editTask') : t('todos.addNewTask')}</div>
          <div className="name-input">
            <input
              ref={nameInputRef}
              dir="ltr"
              autoFocus
              placeholder={t('todos.taskName')}
              value={newTodoName}
              onInput={onTodoNameChange}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  save();
                }
              }}
            />
          </div>
          <div className="subtasks">
            <div>{t('todos.subtasks')}:</div>
            <input
              dir="ltr"
              autoFocus
              placeholder={t('todos.subtaskName')}
              value={subtaskName}
              onInput={onSubTaskNameChange}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  addSubtask();
                }
              }}
            />
            {subtasks.length > 0 && (
              <div className="subtaskList">
                {subtasks.map((subtask, index) => (
                  <div key={index} className="subtask">
                    <span>{subtask.name}</span>
                  </div>
                ))}
              </div>
            )}
          </div>

          <div className="flex flex-center new-item-footer">
            <div>
              <TextField
                label={t('todos.date')}
                type="date"
                fullWidth={true}
                value={selectedDate ? selectedDate.toISOString().split('T')[0] : ''}
                onChange={(e) => {
                  if (!e.target.value) {
                    setDateSet(false);
                    setSelectedDate(null);
                    return;
                  }

                  const newDate = new Date(selectedDate || Date.now());
                  newDate.setFullYear(parseInt(e.target.value.split('-')[0]));
                  newDate.setMonth(parseInt(e.target.value.split('-')[1]) - 1);
                  newDate.setDate(parseInt(e.target.value.split('-')[2]));
                  setSelectedDate(newDate);
                  setDateSet(true);
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  style: {width: '100%'},
                }}
              />
            </div>
            <div style={{marginTop: '4px'}}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <TimePicker
                  clearable={true}
                  ampm={false}
                  label={t('todos.time')}
                  value={selectedTime}
                  onChange={(date) => {
                    setSelectedTime(date);
                    setTimeSet(true);
                  }}
                  style={{width: '100%'}}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </MuiPickersUtilsProvider>
            </div>
            {!editingMode && (
              <div style={{marginTop: '4px'}}>
                <FormControl fullWidth>
                  <InputLabel id="username-label">{t('todos.username')}</InputLabel>
                  <Select
                    labelId="username-label"
                    value={selectedUsername || ''}
                    onChange={(e) => setSelectedUsername(e.target.value as string)}
                    displayEmpty
                    fullWidth
                  >
                    {usernames.map((username, index) => (
                      <MenuItem key={index} value={username}>
                        {username}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
            )}
            <div style={{paddingTop: '10px', marginTop: '4px'}}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={serial}
                    onChange={(e) => setSerial(e.target.checked)}
                    name="serial"
                    defaultChecked
                    color={'primary'}
                  />
                }
                label={t('todos.serial')}
              />
            </div>
          </div>
          {serial && (
            <div className="flex">
              <div style={{marginTop: '4px', width: '23%'}}>
                <FormControl fullWidth>
                  <InputLabel id="schedule-label">{t('todos.scheduleType')}</InputLabel>
                  <Select
                    labelId="schedule-label"
                    value={scheduleType || ''}
                    onChange={(e) => setScheduleType(e.target.value as string)}
                    displayEmpty
                    fullWidth
                  >
                    {Object.entries(scheduleTypes).map(([key, value]) => (
                      <MenuItem key={key} value={value}>
                        {t(`todos.${value}`)}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
              <div className="schedule-date-formatting">
                <TextField
                  label={t('todos.scheduleDateFrom')}
                  type="date"
                  fullWidth={true}
                  value={scheduleFrom ? scheduleFrom.toISOString().split('T')[0] : ''}
                  onChange={(e) => {
                    if (!e.target.value) {
                      setScheduleFrom(null);
                      return;
                    }

                    const newDate = new Date(scheduleFrom || Date.now());
                    newDate.setFullYear(parseInt(e.target.value.split('-')[0]));
                    newDate.setMonth(parseInt(e.target.value.split('-')[1]) - 1);
                    newDate.setDate(parseInt(e.target.value.split('-')[2]));
                    setScheduleFrom(newDate);
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    style: {width: '100%'},
                  }}
                />
              </div>
              <div className="schedule-extra-options" style={{marginTop: '4px', marginLeft: '15px'}}>
                {scheduleType === 'EVERY_X_DAYS' && (
                  <TextField
                    label={t('todos.day')}
                    type="number"
                    fullWidth={true}
                    value={everyXDays}
                    onChange={(e) => setEveryXDays(Number(e.target.value))}
                    InputProps={{
                      inputProps: {
                        min: 1,
                      },
                    }}
                  />
                )}
                {scheduleType === 'DAY_SPECIFIC' && (
                  <FormControl fullWidth>
                    <InputLabel id="specific-days-label">{t('todos.specificDays')}</InputLabel>
                    <Select
                      labelId="specific-days-label"
                      multiple
                      value={specificDays}
                      style={{width: '100%', minWidth: '350px'}}
                      onChange={(event, child) => {
                        setSpecificDays(event.target.value as string[]);
                      }}
                      renderValue={(selected) => {
                        const selectedDays = (selected as string[]).map((day) => {
                          if (language === 'de') {
                            return days[day];
                          }
                          return day.charAt(0).toUpperCase() + day.slice(1);
                        });

                        if (Array.isArray(selected)) {
                          return selectedDays.join(', ');
                        }
                        return '';
                      }}
                    >
                      {Object.entries(days).map(([key, name]) => (
                        <MenuItem
                          key={key}
                          value={key}
                          style={specificDays.includes(key) ? {fontWeight: 'bold'} : undefined}
                        >
                          {specificDays.includes(key) ? (
                            <>&#10003; {language === 'de' ? days[key] : key.charAt(0).toUpperCase() + key.slice(1)}</>
                          ) : language === 'de' ? (
                            days[key]
                          ) : (
                            key.charAt(0).toUpperCase() + key.slice(1)
                          )}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
                {scheduleType === 'EVERY_X_IN_MONTH' && (
                  <TextField
                    label={t('todos.day')}
                    type="number"
                    fullWidth={true}
                    value={dayInMonth}
                    onChange={(e) => setDayInMonth(Number(e.target.value))}
                    InputProps={{
                      inputProps: {
                        min: 1,
                        max: 31,
                      },
                    }}
                  />
                )}
              </div>
            </div>
          )}
          <div className="buttons-row">
            <div style={{padding: '7px 0'}}>
              <Button
                variant="outlined"
                color="default"
                onClick={cancelNewTask}
                style={{width: '100%', padding: '5px 0'}}
              >
                {t('general.cancel')}
              </Button>
            </div>
            <div style={{padding: '7px'}}>
              <Button variant="contained" color="primary" onClick={save} style={{width: '100%', padding: '5px 0'}}>
                {t('general.save')}
              </Button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AddNewTask;
