import React, {useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Button, Grid} from '@material-ui/core';
import {useFetch, useStorlessFetch} from '../../hooks/fetch';
import UpdateOrderItems from '../../components/Order/UpdateOrderItems';
import {IDetailedOrder} from '../../types/Orders/IDetailedOrder';
import {useDispatch} from 'react-redux';
import {appActions} from '../../modules/app/actions';
import {modalActions} from '../../modules/modal/actions';
import {MODALS} from '../../components/Modal/ModalContents';
import {omit} from 'lodash';
import {OrderStatusType} from '../../types/Orders/OrderStatusType';
import PdfOfferButton from '../../components/Order/CreatingOrders/PdfOfferButton';
import {CreateOrderProvider} from './Context/CreateOrderContext';
import {OfferTemplateType} from '../../types/Orders/OfferTemplateType';
import SendEmailButton from '../../components/Order/OrderActions/SendEmailButton';
import OrderInfo from '../../components/Order/CreatingOrders/Sections/OrderInfo';
import ShippingAddressInfo from '../../components/Order/CreatingOrders/Sections/ShippingAddressInfo';
import BillingAddressInfo from '../../components/Order/CreatingOrders/Sections/BillingAddressInfo';
import {history} from '../../lib/history';

export interface CountryOption {
  name: string;
  code: string;
}

const CreateOrder = (props: any) => {
  const [shippingAddresses, getShippingAddresses] = useFetch<any>('customer_shipping_addresses');
  const [submitOrderResponse, submitOrder] = useStorlessFetch('convert_offer_to_order');
  const [countriesResponse, getCountries] = useFetch<any>('countries_with_codes');
  const [patchOrderResponse, patchOrder] = useStorlessFetch('update_order');
  const [countryOptions, setCountryOptions] = useState<CountryOption[]>([]);
  const [getOrder, doGetOrder] = useFetch<any>('order_creation_details');
  const query = new URLSearchParams(props.location.search);
  const createOrderAsOffer: any = useRef(query.get('isOffer'));
  const [order, setOrder] = useState<IDetailedOrder>(null as any);
  const orderId = props?.match?.params?.id;
  const {OFFER, ORDER} = OfferTemplateType;
  const dispatch = useDispatch();
  const {t} = useTranslation();

  const setCustomerName = () => {
    const {company: bCompany, firstName: bFirstName, lastName: bLastName} = order.billingAddress;

    order.billingAddress.customerName = bCompany || `${bFirstName || ''} ${bLastName || ''}`;
  };

  const save = () => {
    setCustomerName();
    const updatedOrder = omit(order, ['orderItems']);
    patchOrder({id: orderId, ...updatedOrder, type: createOrderAsOffer.current ? 'Offer' : 'Order'});
  };

  const handleSaveButton = (event: any) => {
    event.preventDefault();
    save();
  };

  const placeOrder = (event: any) => {
    event.preventDefault();
    setCustomerName();
    const updatedOrder = omit(order, ['orderItems']);
    submitOrder({
      id: orderId,
      ...updatedOrder,
      status: OrderStatusType.New,
      externalOrderNumber: updatedOrder.shopwareCustomFields?.internalOrderNumber,
    });
    dispatch(modalActions.closeModal());
  };

  const finishOffer = (event: any) => {
    event.preventDefault();
    setCustomerName();
    const updatedOrder = omit(order, ['orderItems']);
    patchOrder({id: orderId, ...updatedOrder, status: OrderStatusType.Offer});
    dispatch(modalActions.closeModal());
    setOrder({...order, status: OrderStatusType.Offer});
  };

  const updateOrderState = (value: any, fieldName: string, nestedObjectName?: string) => {
    setOrder((prevState: any) => {
      const updateData = nestedObjectName
        ? {
            [nestedObjectName]: {
              ...prevState[nestedObjectName],
              [fieldName]: value,
            },
          }
        : {[fieldName]: value};

      return {...prevState, ...updateData};
    });
  };

  const handlePlaceOrder = (event: any) => {
    handleSaveButton(event);
    event.preventDefault();
    dispatch(
      modalActions.addModal(MODALS.CONFIRM_DIALOG, {
        title: t('general.areYouSure'),
        content: createOrderAsOffer.current ? t('createOrders.questionOffer') : t('createOrders.questionOrder'),
        onYes: () => placeOrder(event),
        onNo: () => dispatch(modalActions.closeModal()),
      }),
    );
  };

  const handleFinishOffer = (event: any) => {
    handleSaveButton(event);
    event.preventDefault();
    dispatch(
      modalActions.addModal(MODALS.CONFIRM_DIALOG, {
        title: t('general.areYouSure'),
        content: t('createOrders.questionFinishOffer'),
        onYes: () => finishOffer(event),
        onNo: () => dispatch(modalActions.closeModal()),
      }),
    );
  };

  const getCountryCodeValue = (currentValue: any) => {
    if (currentValue?.length > 3 && countryOptions) {
      return countryOptions.find((item) => item.name === currentValue)?.code;
    }
    return currentValue;
  };

  const ScreenTitle = () => {
    const title = [OrderStatusType.Offer, OrderStatusType.PendingOffer].includes(order?.status)
      ? t('createOrders.screenTitleOffer')
      : t('createOrders.screenTitle');

    return (
      <div className="order-header-title">
        <span className="left-title">
          {t('general.orders')} / <span className="right-title">{title}</span>
        </span>
      </div>
    );
  };

  const PlaceOrderButton = () => {
    return (
      <>
        {order.status !== OrderStatusType.PendingOffer && (
          <Button type="submit" variant="contained" className="yellow-button">
            {createOrderAsOffer.current ? <b>{t('createOrders.convertToOrder')}</b> : <b>{t('general.placeOrder')}</b>}
          </Button>
        )}
      </>
    );
  };

  useEffect(() => {
    getCountries();
    doGetOrder({id: orderId});
  }, [orderId]);

  useEffect(() => {
    if (countriesResponse.data && countriesResponse.loaded) {
      const countries = countriesResponse.data as CountryOption[];
      setCountryOptions(countries);
    }
  }, [countriesResponse?.data]);

  useEffect(() => {
    if (getOrder.data && getOrder.loaded && countryOptions.length > 0) {
      getShippingAddresses({customerId: getOrder.data.customerId});
      const billingCountryCode = getCountryCodeValue(getOrder.data.billingAddress.countryCode);
      const shippingCountryCode = getCountryCodeValue(getOrder.data.shippingAddress.countryCode);
      createOrderAsOffer.current = createOrderAsOffer.current || getOrder.data.status === OrderStatusType.Offer;

      if (!order) {
        setOrder({
          ...getOrder.data,
          billingAddress: {
            ...getOrder.data.billingAddress,
            countryCode: billingCountryCode,
          },
          shippingAddress: {
            ...getOrder.data.shippingAddress,
            countryCode: shippingCountryCode,
          },
        });
      } else {
        setOrder((prevState: any) => {
          return {...prevState, orderItems: getOrder.data.orderItems};
        });
      }
    }
  }, [getOrder?.data, countryOptions.length]);

  useEffect(() => {
    if (patchOrderResponse?.error) {
      dispatch(appActions.showSnackBar({text: patchOrderResponse.error?.name, options: {severity: 'error'}}));
    } else if (patchOrderResponse?.data) {
      dispatch(appActions.showSnackBar({text: t('general.success'), options: {severity: 'success'}}));
    }

    return () => {
      patchOrderResponse.data = null;
      patchOrderResponse.error = null;
    };
  }, [patchOrderResponse?.data]);

  useEffect(() => {
    if (submitOrderResponse?.data) {
      dispatch(appActions.showSnackBar({text: t('general.success'), options: {severity: 'success'}}));
      history.push(`/orders/${orderId}`);
    } else if (submitOrderResponse?.error) {
      dispatch(appActions.showSnackBar({text: submitOrderResponse.error?.name, options: {severity: 'error'}}));
    }

    return () => {
      submitOrderResponse.data = null;
      submitOrderResponse.error = null;
      patchOrderResponse.data = null;
      getOrder.data = null;
      getOrder.error = null;
      shippingAddresses.data = null;
      shippingAddresses.error = null;
      setOrder(null as any);
      setCountryOptions([]);
    };
  }, [submitOrderResponse?.data]);

  return order &&
    [OrderStatusType.Pending, OrderStatusType.Offer, OrderStatusType.PendingOffer].includes(order.status) ? (
    <div className="order-details">
      <CreateOrderProvider
        value={{
          submitOrderResponse,
          patchOrderResponse,
          shippingAddresses,
          countryOptions,
          doGetOrder,
          order,
          updateOrderState,
          setOrder,
        }}
      >
        <form onSubmit={handlePlaceOrder}>
          <div className="order-header">
            <ScreenTitle />
            <PlaceOrderButton />
            <div className="order-header-buttons">
              <SendEmailButton
                type={[OrderStatusType.Offer, OrderStatusType.PendingOffer].includes(order?.status) ? OFFER : ORDER}
                orderNumber={order.orderNumber}
                orderId={orderId}
              />
              <PdfOfferButton orderId={order.orderId} type={OfferTemplateType.OFFER} save={save} />
            </div>
          </div>

          <Grid container spacing={2}>
            <OrderInfo />
            <ShippingAddressInfo />
            <BillingAddressInfo />
          </Grid>

          <UpdateOrderItems editingMode={false} orderId={orderId} />

          <br />
          <div style={{float: 'right'}}>
            <Button type="button" variant="contained" className="blue-button" onClick={handleSaveButton}>
              {t('general.save')}
            </Button>

            {order.status === OrderStatusType.PendingOffer && (
              <Button type="button" variant="contained" className="yellow-button" onClick={handleFinishOffer}>
                {t('createOrders.finishOffer')}
              </Button>
            )}
          </div>
        </form>
      </CreateOrderProvider>
    </div>
  ) : null;
};

export default CreateOrder;
