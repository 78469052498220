import React, {useContext, useEffect, useState} from 'react';
import TodoContext from '../../screens/Todo/Context/TodoContext';
import {Button, Checkbox, IconButton, TextField, Theme} from '@material-ui/core';
import {useTranslation} from 'react-i18next';
import {Add, Check, Close, Edit} from '@material-ui/icons';
import {useFetch} from '../../hooks/fetch';
import {ITodoList} from '../../types/Todo/ITodoList';
import {makeStyles} from '@material-ui/styles';

interface ITodoSidebarProps {
  test?: string;
}

export const iconButtonStyles = makeStyles((theme: Theme) => ({
  smallIconButton: {
    padding: 5,
    '& svg': {
      fontSize: 16,
    },
  },
}));

const TodoListsSidebar: React.FC<ITodoSidebarProps> = () => {
  const {t} = useTranslation();
  const {
    todoLists,
    completedToday,
    switchList,
    selectedList,
    todos,
    getTodoLists,
    todoSocketLists,
    refetchTodoSocketLists,
  } = useContext(TodoContext);
  const [newListName, setNewListName] = useState<string>('');
  const [listIgnored, setListIgnored] = useState<boolean>(false);
  const [createTodoListResponse, createTodoList] = useFetch<any>('create_todo_list');
  const [updateTodoListResponse, updateTodoList] = useFetch<any>('update_todo_list');
  const [deleteTodoListResponse, doDeleteTodoList] = useFetch<any>('delete_todo_list');
  const [showNewTodoListInput, setShowNewTodoListInput] = useState(false);
  const [editing, setEditing] = useState('');

  const getListClassName = (listId: string) => {
    const socketList = todoSocketLists.find((socketList) => socketList.listId === listId);
    if (socketList) {
      if (socketList.hasTasks && !socketList.hasOverdueTasks) {
        return 'task-list-single yellow-bg';
      } else if (socketList.hasTasks && socketList.hasOverdueTasks) {
        return 'task-list-single red-bg';
      }
    }
    return 'task-list-single';
  };

  const toggleEditTodoList = (list: ITodoList) => {
    if (showNewTodoListInput) {
      setShowNewTodoListInput(false);
      setNewListName('');
      setListIgnored(false);
    } else {
      setNewListName(list.name);
      setListIgnored(list.ignored);
      setShowNewTodoListInput(true);
      setEditing(list._id);
    }
  };

  const deleteTodoList = (list: ITodoList) => {
    if (confirm(t('todos.deleteListConfirm'))) {
      doDeleteTodoList({id: list._id});
    }
  };

  const cancelNewListForm = () => {
    setNewListName('');
    setShowNewTodoListInput(false);
    setEditing('');
  };

  const saveNewListForm = () => {
    if (!editing) {
      createTodoList({name: newListName, ignored: listIgnored});
    } else {
      updateTodoList({id: editing, name: newListName, ignored: listIgnored});
    }
  };

  useEffect(() => {
    if (createTodoListResponse.data) {
      getTodoLists();
      setShowNewTodoListInput(false);
      setNewListName('');
      setEditing('');
    }
  }, [createTodoListResponse]);

  useEffect(() => {
    if (updateTodoListResponse.data) {
      getTodoLists();
      refetchTodoSocketLists();
      setShowNewTodoListInput(false);
      setEditing('');
    }
  }, [updateTodoListResponse]);

  useEffect(() => {
    if (deleteTodoListResponse.data) {
      getTodoLists();
    }
  }, [deleteTodoListResponse]);

  const showAddNewInput = () => {
    setShowNewTodoListInput(true);
    setEditing('');
  };

  const classes = iconButtonStyles();

  return (
    <div className="side">
      <div className="task-list">
        {todoLists.map((todoList) => (
          <div key={todoList._id} className={getListClassName(todoList._id)}>
            <a
              href="#"
              onClick={() => {
                switchList(todoList);
              }}
            >
              {selectedList?.name === todoList.name ? (
                <>
                  <span style={{fontWeight: 'bold'}}>
                    {todoList.name} &nbsp;({todos.length})
                  </span>
                </>
              ) : (
                <span>{todoList.name}</span>
              )}
            </a>
            {!todoList.main && (
              <div className="list-buttons">
                <IconButton
                  onClick={() => {
                    toggleEditTodoList(todoList);
                  }}
                  size={'small'}
                  className={classes.smallIconButton}
                >
                  <Edit />
                </IconButton>
                <IconButton
                  onClick={() => {
                    deleteTodoList(todoList);
                  }}
                  size={'small'}
                  className={classes.smallIconButton}
                >
                  <Close />
                </IconButton>
              </div>
            )}
          </div>
        ))}
      </div>
      <Button variant="outlined" startIcon={<Add />} onClick={showAddNewInput} style={{marginTop: '10px'}} size="small">
        {t('general.new')}
      </Button>
      {showNewTodoListInput && (
        <div>
          <TextField
            autoFocus
            className="create-list-input"
            label={t('general.name')}
            value={newListName}
            onChange={(e) => setNewListName(e.target.value)}
          />

          <Checkbox
            checked={listIgnored}
            onChange={(e: any) => {
              setListIgnored(e.target.checked);
            }}
          />

          <label>{t('todos.ignoreListForReminding')}</label>

          <div className="create-list-buttons">
            <Button variant="outlined" onClick={cancelNewListForm} startIcon={<Close />}>
              {t('general.cancel')}
            </Button>
            <Button variant="contained" onClick={saveNewListForm} endIcon={<Check />}>
              {t('general.confirm')}
            </Button>
          </div>
        </div>
      )}
      <div style={{marginTop: '20px', fontWeight: 'bold', color: '#334155', fontSize: '0.9rem'}}>
        {t('todos.completedToday')}: {completedToday}
      </div>
    </div>
  );
};

export default TodoListsSidebar;
