import _ from 'lodash';
import {API, ApiAction} from './actions';
import {Endpoint} from './endpoints';
import {initApi} from './helpers/initApi';
export const INITIAL_STATE = initApi();

const apiReducer = (state = INITIAL_STATE, action: ApiAction) => {
  if (/^FETCH_START_/.test(action.type)) {
    const subReducer = _.camelCase(action.type.replace(API.FETCH_START, '')) as Endpoint;

    return {
      ...state,
      [subReducer]: {
        ...action.payload,
        //@ts-ignore
        data: state[subReducer].data,
        loading: true,
        error: null,
        loaded: false,
      },
    };
  }

  if (/^REMOVE_/.test(action.type)) {
    const subReducer = _.camelCase(action.type.replace(API.REMOVE, '')) as Endpoint;
    return {
      ...state,
      [subReducer]: {
        data: null,
        loading: false,
        error: null,
        loaded: false,
      },
    };
  }

  if (/^FETCH_SUCCESS_/.test(action.type)) {
    const subReducer = _.camelCase(action.type.replace(API.FETCH_SUCCESS, '')) as Endpoint;
    return {
      ...state,
      [subReducer]: {
        //...action.payload,
        loading: false,
        loaded: true,
        error: null,
        data: action.payload,
      },
    };
  }

  if (/^FETCH_FAILURE_/.test(action.type)) {
    const subReducer = _.camelCase(action.type.replace(API.FETCH_FAILURE, '')) as Endpoint;
    // eslint-disable-next-line no-console
    console.log('FAILURE', action.payload.response.data);
    return {
      ...state,
      [subReducer]: {
        loading: false,
        loaded: false,
        error: action.payload.response.data.error ?? action.payload.response.data,
      },
    };
  }
  return state;
};

export {apiReducer};
