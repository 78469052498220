import {Button, Grid} from '@material-ui/core';
import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {appActions} from '../../modules/app/actions';
import {useStorlessUpload} from '../../hooks/fetch';
import {Endpoint} from '../../modules/api/endpoints';

interface IProps {
  onSuccessCallback: () => void;
  endpoint: Endpoint;
  entityId: string;
}

export default function UploadInvoiceButton({endpoint, entityId, onSuccessCallback}: IProps) {
  const [uploadInvoiceResponse, uploadInvoice] = useStorlessUpload(endpoint);
  const [uploadedFile, setUploadedFile] = useState<any>(null);
  const dispatch = useDispatch();
  const {t} = useTranslation();

  useEffect(() => {
    if (uploadInvoiceResponse?.data) {
      onSuccessCallback();
      dispatch(appActions.showSnackBar({text: t('general.success'), options: {severity: 'success'}}));
    }

    if (uploadInvoiceResponse?.error) {
      dispatch(appActions.showSnackBar({text: t('general.error'), options: {severity: 'error'}}));
    }

    return () => {
      setUploadedFile(null);
      uploadInvoiceResponse.data = null;
      uploadInvoiceResponse.error = null;
    };
  }, [uploadInvoiceResponse]);

  const handleFileUpload = (event: any) => {
    setUploadedFile(event?.target?.files[0]);
    event.target.value = null;
  };

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    setUploadedFile(file);
  };

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  };

  const uploadImage = async () => {
    uploadInvoice({file: uploadedFile, id: entityId});
  };

  return (
    <Grid item xs={12} sm={6} className="order-info-item">
      <span className="order-info-label">{t('supplierOrderDetails.uploadInvoice')}</span>
      <div className="drop-area" onDrop={handleDrop} onDragOver={handleDragOver}>
        {!uploadedFile ? (
          <Button variant="contained" component="label" className="order-info-button">
            {t('general.chooseFile')}
            <input type="file" hidden id="file" name="file" onChange={handleFileUpload} />
          </Button>
        ) : (
          <Button variant="contained" className="upload-button" onClick={uploadImage}>
            {t('general.uploadFile')}
          </Button>
        )}
      </div>
    </Grid>
  );
}
