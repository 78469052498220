import React from 'react';
import {useTranslation} from 'react-i18next';
import DataTable from '../../components/Table/Table';
import {TrackingStatus} from '../../types/Orders/TrackingStatus';
import {DataTableItemType} from '../../components/Table/interfaces/IHeaderAccessor';

const AmazonTrackingNumbersInfo = () => {
  const {t} = useTranslation();

  return (
    <div className="orders">
      <DataTable
        headers={[
          {
            kind: 'accessor',
            name: t('orderDetails.orderNumber'),
            accessor: 'orderNumber',
            sortable: true,
          },
          {
            kind: 'accessor',
            name: t('orderDetails.trackingNumber'),
            accessor: 'trackingNumber',
            sortable: true,
          },
          {
            kind: 'accessor',
            name: t('orderDetails.trackingStatus'),
            accessor: 'trackingInformation.status',
            sortable: true,
            type: DataTableItemType.SELECT,
            options: ([{value: 'all', label: 'All'}] as any).concat(
              Object.values(TrackingStatus).map((item) => {
                return {value: item, label: item};
              }),
            ),
          },
          {
            kind: 'accessor',
            name: t('orderDetails.trackingNumberPushedBeforeScanning'),
            accessor: 'trackingNumberPushedBeforeScanning',
            sortable: true,
            type: DataTableItemType.BOOLEAN,
          },
          {
            kind: 'accessor',
            name: t('orderDetails.dateAdded'),
            accessor: 'trackingInformation.dateAdded',
            sortable: true,
            type: DataTableItemType.DATE,
          },
          {
            kind: 'accessor',
            name: t('orderDetails.dateSynced'),
            accessor: 'trackingInformation.dateSynced',
            sortable: true,
            type: DataTableItemType.DATE,
          },
        ]}
        endpoint={'orders_amazon_tracking_numbers_info'}
        target={'orders'}
        defaultSort={{key: 'trackingInformation.dateAdded', value: -1}}
        title={t('amazonTrackingNumbersInfo.screenTitle')}
      />
    </div>
  );
};

export default AmazonTrackingNumbersInfo;
