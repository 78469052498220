import {Box, Divider, Drawer, Grid, IconButton} from '@material-ui/core';
import {ChevronRight} from '@material-ui/icons';
import React, {useEffect, useState} from 'react';
import eventBus from '../../lib/eventBus';
import SupplierArticlesTable from '../Product/SupplierArticlesTable';
import {IProduct} from '../../types/Product/IProduct';
import {ISupplierOrderItem} from '../../types/SupplierOrders/ISupplierOrderItem';
import SectionSupplierOrdersTable from '../Order/CreatingOrders/Sections/SectionSupplierOrdersTable';
import SectionProductOrdersTable from '../Order/CreatingOrders/Sections/SectionProductOrdersTable';
import {useTranslation} from 'react-i18next';

interface ISupplierItemInfoDrawerState {
  supplierOrderItem?: ISupplierOrderItem;
  drawerOpen?: boolean;
  product?: IProduct;
}

const SupplierItemInfoDrawer = (): React.ReactElement => {
  const [state, setState] = useState<ISupplierItemInfoDrawerState>({drawerOpen: false});
  const {t} = useTranslation();

  useEffect(() => {
    eventBus.on('openSupplierItemInfoDrawer', (data: any) => {
      setState((prevState) => ({
        ...prevState,
        drawerOpen: true,
        supplierOrderItem: data.supplierOrderItem,
        product: data.supplierOrderItem.product,
      }));
    });

    return () => eventBus.remove('openSupplierItemInfoDrawer');
  }, []);

  return state.product ? (
    <Drawer anchor="right" open={state.drawerOpen} variant="persistent">
      <Box style={{maxWidth: 1300}} role="presentation">
        <div style={{padding: 10, display: 'flex', alignItems: 'center'}}>
          <IconButton
            style={{marginBottom: 0}}
            onClick={() => setState((prevState) => ({...prevState, drawerOpen: false}))}
          >
            <ChevronRight />
          </IconButton>
          {state.supplierOrderItem?.productTitle}
        </div>
        <Divider />
        <Grid container spacing={3}>
          <Grid item xs={6} className="product-details">
            <Box padding={3}>{t('general.generalOrders')}</Box>
            {state.product?._id && <SectionProductOrdersTable productId={state.product?._id ?? ''} customerId="" />}
          </Grid>
          <Grid item xs={6} className="product-details">
            <Box padding={3}>{t('general.supplierArticles')}</Box>
            {state.product?._id && <SupplierArticlesTable product={state.product} />}
          </Grid>
        </Grid>
        <Divider />
        <Grid container spacing={3}>
          <Grid item xs={12} className="product-details">
            <Box padding={3}>{t('general.latestSupplierOrders')}</Box>
            {state.product?._id && <SectionSupplierOrdersTable productId={state.product?._id ?? ''} />}
          </Grid>
        </Grid>
      </Box>
    </Drawer>
  ) : (
    <></>
  );
};

export default SupplierItemInfoDrawer;
