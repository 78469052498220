import _ from 'lodash';
import React, {useEffect, useRef} from 'react';
import {BaseModalState} from '../../modules/modal/reducer';
import {modals} from './ModalContents';
interface IDrawerBase {
  modalData: BaseModalState;
  orientation: string;
}

export enum DRAWER_ORIENTATION {
  LEFT = 'LEFT',
  RIGHT = 'RIGHT',
}
const DrawerBase = ({modalData, orientation}: IDrawerBase) => {
  const drawerLeft = useRef<HTMLDivElement>(null);
  const Contents = modalData.modalKey ? modals[modalData.modalKey] : (): null => null;
  const modalProps = _.get(modalData, `props`, {}) as any;
  useEffect(() => {
    if (drawerLeft.current) {
      if (orientation === DRAWER_ORIENTATION.LEFT) {
        drawerLeft.current.style.left = '0px';
      } else {
        drawerLeft.current.style.right = '0px';
      }
    }
  }, [drawerLeft.current]);

  return (
    <>
      <div className={`drawer ${orientation === DRAWER_ORIENTATION.LEFT ? 'drawer--left' : 'drawer--right'}`}>
        <div
          className={`drawer__content ${
            orientation === DRAWER_ORIENTATION.LEFT ? 'drawer__content--left' : 'drawer__content--right'
          }`}
          ref={drawerLeft}
        >
          <Contents {...modalProps} />
        </div>
      </div>
    </>
  );
};

export default DrawerBase;
