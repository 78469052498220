import {Grid, Typography, TextField, Button} from '@material-ui/core';
import React, {ReactElement, useContext} from 'react';
import CloseIcon from '@material-ui/icons/Close';
import {modalActions} from '../../../modules/modal/actions';
import CustomerOrderItemsTable from './CustomerOrderItemsTable';
import DeliveryBillScannerCheckBox from './DeliveryBillScannerCheckBox';
import MultiBoxOrderItemsTable from './MultiBoxOrderItemsTable';
import ProductQuantityScannedComponent from './ProductQuantityScannedComponent';
import ScanningCustomerOrderItemInfo from './ScanningCustomerOrderItemInfo';
import ShippingAddressInfo from './ShippingAddressInfo';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import OrderScanningContext from '../../../screens/Orders/Context/OrderScanningContext';

const CustomerOrderScannerComponent = (): ReactElement => {
  const {order, product, orderItems, setBarcode, doHandleOrderItems, setIsParcel, setShowTrackingNumberInput} =
    useContext(OrderScanningContext);
  const dispatch = useDispatch();
  const {t} = useTranslation();
  const onChangeBarcodeHandler = (e: any) => setBarcode(e.target.value);
  const closeModal = () => {
    dispatch(modalActions.closeModal());
  };

  const onParcelFull = () => {
    setIsParcel(true);
    doHandleOrderItems({isParcel: true, scannedProducts: orderItems, id: order._id});
    setShowTrackingNumberInput(true);
  };

  return (
    <Grid container spacing={1} style={{width: '100%'}}>
      <Grid item xs={12} sm={6}>
        <ShippingAddressInfo order={order} paddingLeft={'10px'} />
      </Grid>

      <Grid item xs={12} sm={6}>
        <CloseIcon onClick={closeModal} style={{float: 'right', cursor: 'pointer', margin: '10px'}} />
        <br />
        <div>
          <p style={{textAlign: 'right'}}>
            {t('orderDetails.orderType')}: {order.orderType}
            <br />
            <DeliveryBillScannerCheckBox />
          </p>
        </div>
      </Grid>

      <Grid item xs={12} sm={6}>
        <Grid item xs={12} sm={12}>
          <Grid item xs={12} sm={12}>
            <h2 style={{textAlign: 'center'}}>{product?.productTitle || ''}</h2>
          </Grid>
          <div style={{textAlign: 'left'}}>
            {!product ? (
              <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px'}}>
                <Typography style={{textAlign: 'center', width: '100%'}}>{t('scanner.barCodeInfo')}</Typography>
              </div>
            ) : (
              <ScanningCustomerOrderItemInfo />
            )}
          </div>
        </Grid>
      </Grid>

      <Grid container item xs={12} sm={6} alignItems="center">
        <Grid container spacing={2} style={{marginTop: '10px'}}>
          <Grid item xs={12} sm={12}>
            <TextField
              onChange={onChangeBarcodeHandler}
              id="input-bar-code"
              placeholder={t('scanner.inputBarcodeLabel')}
              label={t('scanner.barCode')}
              variant="outlined"
              autoFocus
            />
          </Grid>

          <ProductQuantityScannedComponent />

          <Button onClick={onParcelFull} variant="contained" className="blue-button">
            {t('scanner.parcelFull')}
          </Button>
        </Grid>
      </Grid>

      <Grid item xs={12} sm={12}>
        <MultiBoxOrderItemsTable />
      </Grid>

      <Grid item xs={12} sm={12}>
        <CustomerOrderItemsTable />
      </Grid>
    </Grid>
  );
};

export default CustomerOrderScannerComponent;
