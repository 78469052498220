import {Button} from '@material-ui/core';
import moment from 'moment';
import React, {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import DataTable from '../../components/Table/Table';
import {useFetch} from '../../hooks/fetch';
import {appActions} from '../../modules/app/actions';
import {OrderStatusType} from '../../types/Orders/OrderStatusType';
import {ShopType} from '../../types/Orders/ShopType';
import {DataTableItemType} from '../../components/Table/interfaces/IHeaderAccessor';

const OrdersWithOverdueInvoices = () => {
  const {t} = useTranslation();
  const [exportXmlResponse, getXmlFile] = useFetch('orders_easybill_export_xml');
  const dispatch = useDispatch();
  const downloadExportedXmlFile = (data: any) => {
    const url = window.URL.createObjectURL(new Blob([data]));
    const link: HTMLAnchorElement = document.createElement('a');
    link.href = url;
    link.style.display = 'none';
    link.setAttribute('download', `SEPA-${moment().format('DD-MM-YYYY')}.xml`);

    document.body.appendChild(link);
    link.click();
    link?.parentNode?.removeChild(link);
  };

  useEffect(() => {
    if (exportXmlResponse.error) {
      dispatch(appActions.showSnackBar({text: exportXmlResponse.error?.name, options: {severity: 'error'}}));
    } else if (exportXmlResponse.data) {
      downloadExportedXmlFile(exportXmlResponse.data);
      dispatch(appActions.showSnackBar({text: t('general.success'), options: {severity: 'success'}}));
    }
  }, [exportXmlResponse]);

  return (
    <div className="orders">
      <DataTable
        headers={[
          {
            kind: 'accessor',
            name: t('orders.orderNumber'),
            accessor: 'orderNumber',
            sortable: true,
          },
          {
            kind: 'accessor',
            name: t('orderDetails.externalOrderNumber'),
            accessor: 'externalOrderNumber',
            sortable: true,
          },
          {
            kind: 'accessor',
            name: t('orders.status'),
            accessor: 'status',
            sortable: true,
            type: DataTableItemType.MULTIPLE_SELECT,
            options: ([{value: 'all', label: 'All'}] as any).concat(
              Object.values(OrderStatusType).map((item) => {
                return {value: item, label: item};
              }),
            ),
          },
          {
            kind: 'accessor',
            name: t('orders.shopName'),
            accessor: 'shopName',
            sortable: true,
            type: DataTableItemType.SELECT,
            options: ([{value: 'all', label: 'All'}] as any).concat(
              Object.values(ShopType).map((item) => {
                return {value: item, label: item};
              }),
            ),
          },
          {
            kind: 'accessor',
            name: t('orders.date'),
            accessor: 'date',
            sortable: true,
            type: DataTableItemType.DATE,
            timeZone: 'Europe/Berlin',
          },
          {
            kind: 'accessor',
            name: t('orderDetails.paymentMethodName'),
            accessor: 'paymentMethodName',
            sortable: true,
          },
          {
            kind: 'accessor',
            name: t('orderDetails.orderTotalSum'),
            accessor: 'orderTotalSum',
            sortable: true,
            type: 'number',
          },
          {
            kind: 'accessor',
            name: t('orders.dueDate'),
            accessor: 'easyBillInfo.dueDate',
            sortable: true,
            type: DataTableItemType.DATE,
            timeZone: 'UTC',
          },
          {
            kind: 'accessor',
            name: t('orders.customerNumber'),
            accessor: 'customerNumber',
            sortable: true,
          },
          {
            kind: 'accessor',
            name: t('orderDetails.customerName'),
            accessor: 'billingAddress.customerName',
            optionalAccessor: 'shippingAddress.customerName',
            sortable: true,
          },
          {
            kind: 'accessor',
            name: t('orders.email'),
            accessor: 'billingAddress.email',
            optionalAccessor: 'shippingAddress.email',
            sortable: true,
          },
        ]}
        endpoint={'orders_overdue_invoices'}
        target={'orders'}
        defaultSort={{key: 'date', value: -1}}
        numberOfItemsPerPage={100}
        title={t('general.overdueInvoices')}
        actionButtons={
          <Button variant="contained" className="blue-button" onClick={() => getXmlFile()}>
            {t('general.export')}
          </Button>
        }
      />
    </div>
  );
};

export default OrdersWithOverdueInvoices;
