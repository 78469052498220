import {Action} from 'redux';
import {put, takeEvery} from 'redux-saga/effects';
import {api} from './ApiFactory';
import {API, ApiAction, apiActions} from './actions';
import {Endpoint, ENDPOINTS} from './endpoints';
import {appActions} from '../app/actions';

export function* onApiLoad({payload, type}: ApiAction) {
  const actionType = type.replace(API.FETCH_START, '').toLowerCase() as Endpoint;
  const endpoint = ENDPOINTS[actionType];

  //@ts-ignore
  if (endpoint?.globalLoader) {
    yield put(appActions.doLoader(true));
  }
  try {
    const response = yield api.fetch(actionType, payload);
    yield put(apiActions.fetchSuccess(actionType, response.data));
    yield put(appActions.doLoader(false));
  } catch (e) {
    if (api.checkIsUserUnauthorized(e.response.data?.error?.name)) {
      yield put(appActions.doLogout());
    }
    yield put(apiActions.fetchFailure(actionType, e));
    yield put(appActions.doLoader(false));
  }
}

export function* watchApiLoad() {
  yield takeEvery((action: Action) => /^FETCH_START_/.test(action.type), onApiLoad);
}

export default [watchApiLoad];
