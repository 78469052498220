import {Button, Link, Typography} from '@material-ui/core';
import React, {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {MODALS} from '../../components/Modal/ModalContents';
import {useFetch} from '../../hooks/fetch';
import CONFIG from '../../lib/config';
import {appActions} from '../../modules/app/actions';
import {modalActions} from '../../modules/modal/actions';
import SettingsIcon from '@material-ui/icons/Settings';
import {AmazonManufacturerVariable} from '../../components/Pricing/AmazonManufacturerVariable';

export interface ManufacturerVariable {
  manufacturer: string;
  coefficient: number;
}

export interface AmazonPricingVariables {
  minimumPriceMargin: number;
  wishPriceMargin: number;
  maximumPriceMargin: number;
  manufacturerVariables: ManufacturerVariable[];
}

const AmazonPricing = () => {
  const {t} = useTranslation();
  const [configDetails, getConfigDetails] = useFetch('amazon_config_get');
  const [configPut, doConfigPut] = useFetch('amazon_config_put');
  const dispatch = useDispatch();

  const configObject = configDetails?.data as AmazonPricingVariables;

  const handleCancelModal = () => {
    dispatch(modalActions.closeModal());
  };

  const handleSubmitModal = async (values: any) => {
    const data = {...values};
    await doConfigPut(data);
    handleCancelModal();
  };

  const handleClick = async () => {
    dispatch(
      modalActions.addModal(MODALS.EDIT_AMAZON_CONFIG, {
        title: t('amazonPricing.editTitle') || '',
        onSubmit: handleSubmitModal,
        onCancel: handleCancelModal,
        data: configObject,
      }),
    );
  };

  useEffect(() => {
    getConfigDetails();
  }, []);

  useEffect(() => {
    if (configPut?.data && !configPut.error) {
      dispatch(appActions.showSnackBar({text: t('amazonPricing.configUpdated'), options: {severity: 'success'}}));
      getConfigDetails();
    } else if (configPut?.error) {
      dispatch(appActions.showSnackBar({text: configPut.error.name, options: {severity: 'error'}}));
    }
  }, [configPut]);

  return (
    <div>
      <div className="customer-details" style={{width: 'unset'}}>
        <div className="center-content">
          <div className="customer-details__header">
            <p>{t('amazonPricing.screenTitle')}</p>
            <Button variant="contained" className="blue-button" onClick={handleClick}>
              {t('general.edit')}
            </Button>
          </div>
          <div style={{marginBottom: 10}}>
            <a className="link" href="/amazon/products-without-minimum-margin">
              {t('products.productsWithoutMinimumMargin')}
            </a>
          </div>
          <div className="customer-details__data-container">
            <div className="cards-info amazon-pricing">
              <div className="customer-details__data-container__title">
                <SettingsIcon style={{fontSize: 25}} />
                <p>{t('amazonPricing.priceList')}</p>
              </div>
              <div className="customer-details__data-container__body">
                <Typography>
                  <Link href={`${CONFIG.api}static/priceDE.csv`}>Download Marketplace DE</Link>
                </Typography>
                <Typography>
                  <Link href={`${CONFIG.api}static/priceIT.csv`}>Download Marketplace IT</Link>
                </Typography>
                <Typography>
                  <Link href={`${CONFIG.api}static/priceES.csv`}>Download Marketplace ES</Link>
                </Typography>
                <Typography>
                  <Link href={`${CONFIG.api}static/priceFR.csv`}>Download Marketplace FR</Link>
                </Typography>
                <Typography>
                  <Link href={`${CONFIG.api}static/priceGB.csv`}>Download Marketplace GB</Link>
                </Typography>
                <Typography>
                  <Link href={`${CONFIG.api}static/pricePL.csv`}>Download Marketplace PL</Link>
                </Typography>
                <Typography>
                  <Link href={`${CONFIG.api}static/priceNL.csv`}>Download Marketplace NL</Link>
                </Typography>
                <Typography>
                  <Link href={`${CONFIG.api}static/priceSE.csv`}>Download Marketplace SE</Link>
                </Typography>
              </div>
            </div>

            <div className="cards-info amazon-pricing">
              <div className="customer-details__data-container__title">
                <SettingsIcon style={{fontSize: 25}} />
                <p>{t('amazonPricing.variables')}</p>
              </div>
              <div className="customer-details__data-container__body">
                <div className="customer-details__data-container__field">
                  <p>{t(`amazonPricing.minimumPriceMargin`)}</p>
                  <p>{configObject?.minimumPriceMargin}</p>
                </div>
                <div className="customer-details__data-container__field">
                  <p>{t(`amazonPricing.maximumPriceMargin`)}</p>
                  <p>{configObject?.maximumPriceMargin}</p>
                </div>
                <div className="customer-details__data-container__field">
                  <p>{t(`amazonPricing.wishPriceMargin`)}</p>
                  <p>{configObject?.wishPriceMargin}</p>
                </div>
              </div>
            </div>
          </div>
          <div>
            {configObject?.manufacturerVariables.map((manufacturerVariable, index) => {
              return <AmazonManufacturerVariable key={index} variable={manufacturerVariable} />;
            })}
          </div>
        </div>
      </div>
    </div>
  );
};
export default AmazonPricing;
