import {Box} from '@material-ui/core';
import React from 'react';
import DataTable from '../../components/Table/Table';
import {useTranslation} from 'react-i18next';
import {OrderStatusType} from '../../types/Orders/OrderStatusType';
import {DataTableItemType} from '../../components/Table/interfaces/IHeaderAccessor';

export interface IPieDetails {
  entry: any;
}

export const PieDetails = (props: IPieDetails) => {
  const {entry} = props;
  return (
    <div className="home__pie-tooltip">
      <div className="home__pie-tooltip__others">
        {entry?.others?.map((el: any, index: number) => {
          return (
            <Box className="home__pie-tooltip__others__field" boxShadow={3} key={index} style={{padding: 3}}>
              <p>{el.name}</p>
              <p>{el.income}</p>
            </Box>
          );
        })}
      </div>
    </div>
  );
};

const Home = () => {
  const {t} = useTranslation('translation');

  return (
    <div className="home">
      <DataTable
        className="home__table"
        headers={[
          {
            kind: 'accessor',
            name: t('orders.orderNumber'),
            accessor: 'orderNumber',
            sortable: true,
          },
          {
            kind: 'accessor',
            name: t('orders.status'),
            accessor: 'status',
            sortable: true,
          },
          {
            kind: 'accessor',
            name: t('orders.shippingType'),
            accessor: 'shippingType',
            sortable: true,
          },
          {
            kind: 'accessor',
            name: t('orders.date'),
            accessor: 'date',
            sortable: true,
            type: DataTableItemType.DATE,
          },
          {
            kind: 'accessor',
            name: t('orders.invoiceNumber'),
            accessor: 'invoiceNumber',
            sortable: true,
          },
          {
            kind: 'accessor',
            name: t('orders.countryCode'),
            accessor: 'shipCountryCode',
            sortable: true,
          },
          {
            kind: 'accessor',
            name: t('orders.customerNumber'),
            accessor: 'customerNumber',
            sortable: true,
          },
          {
            kind: 'accessor',
            name: t('orders.shippingAddress'),
            accessor: 'shippingAddressName',
            sortable: true,
          },
          {
            kind: 'accessor',
            name: t('orders.billingAddress'),
            accessor: 'billingAddressName',
            sortable: true,
          },
        ]}
        endpoint={'orders'}
        target={'orders'}
        defaultSort={{key: 'date', value: -1}}
        options={{status: {$nin: [OrderStatusType.Offer, OrderStatusType.PendingOffer]}}}
        title={t('dashboard.mostRecentOrders')}
      />
    </div>
  );
};

export default Home;
