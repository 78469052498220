import React, {ReactElement, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {IOrder} from '../../../types/Orders/IOrder';
import UpdateOrderItems from '../UpdateOrderItems';
import {IDetailedOrder} from '../../../types/Orders/IDetailedOrder';
import {useFetch, useStorlessFetch} from '../../../hooks/fetch';
import {CreateOrderProvider} from '../../../screens/Orders/Context/CreateOrderContext';
import {Button, Grid} from '@material-ui/core';
import {appActions} from '../../../modules/app/actions';
import {useDispatch} from 'react-redux';
import {EventsName, socketFactory} from '../../../lib/socketFactory';

const EditOrderItems = (props: IEditOrderItemsProps): ReactElement => {
  const {t} = useTranslation();
  const [getOrder, doGetOrder] = useFetch<any>('order_creation_details');
  const [order, setOrder] = useState<IDetailedOrder>(null as any);
  const [updateOrderItem, doUpdateOrderItem] = useStorlessFetch('update_order_item');
  const dispatch = useDispatch();

  const handleSaveButton = (event: any) => {
    event.preventDefault();
    const preparedOrderItems = order.orderItems.map((item: any) => {
      return {...item, vat: order.vatPercentage, orderId: order.orderId};
    });
    doUpdateOrderItem({orderItems: preparedOrderItems});
  };

  useEffect(() => {
    doGetOrder({id: props.order._id});
    socketFactory.listenOrderModifying(props.onOrderEditing);
    socketFactory.emitOrderModifying(props.order?.orderNumber || '');

    return () => {
      socketFactory.emitStopOrderModifying(props.order?.orderNumber || '');
      socketFactory.removeEventListener(EventsName.ORDER_MODIFYING);
    };
  }, []);

  useEffect(() => {
    if (getOrder.data) {
      setOrder(getOrder.data);
    }
  }, [getOrder]);

  useEffect(() => {
    if (updateOrderItem?.error) {
      dispatch(appActions.showSnackBar({text: updateOrderItem.error?.name, options: {severity: 'error'}}));
    } else if (updateOrderItem?.data) {
      dispatch(appActions.showSnackBar({text: t('general.success'), options: {severity: 'success'}}));
    }

    return () => {
      updateOrderItem.data = null;
      updateOrderItem.error = null;
    };
  }, [updateOrderItem?.data]);

  return (
    <div style={{minHeight: '150px'}}>
      {order ? (
        <form>
          <CreateOrderProvider value={{order, setOrder, doGetOrder, updateOrderState: null as any}}>
            <UpdateOrderItems editingMode={true} orderId={order.orderId} />
          </CreateOrderProvider>

          <br />
          <Grid container spacing={2}>
            <Grid item xs={8}></Grid>
            <Grid item xs={4} style={{textAlign: 'right'}}>
              <Button type="submit" variant="contained" className="blue-button" onClick={handleSaveButton}>
                {t('general.save')}
              </Button>
              &nbsp;&nbsp;
            </Grid>
          </Grid>
        </form>
      ) : null}
    </div>
  );
};

interface IEditOrderItemsProps {
  order: IOrder;
  onOrderEditing: (orderNumber: string) => void;
}

export default EditOrderItems;
