export interface IEbayMultiVariant {
  _id?: string;
  inventoryItemGroupKey?: string;
  description?: string;
  product?: any;
  title?: string;
  colors?: string[];
  manufacturerNumbers?: string;
  variants?: any[];
  printerModels?: any[];
  uploadedImage?: string;
  offerId?: string;
  listingId?: string;
  additionalImages?: string[];
  compatibleType?: string;
  selectedProducts?: any;
}

export enum EbayCompatibleTypes {
  UNITER_TONER = 'Uniter Toner',
  GREEN_GECKO = 'green gecko',
  DRUCKDICHAUS = 'druckdichaus',
}
