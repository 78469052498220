import Button from '@material-ui/core/Button';
import React, {ReactElement, useContext, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {Util} from '../../../modules/api/utils';
import jsPDF from 'jspdf';
import {ShippingProviderType} from '../../../types/Shipment/ShippingProviderType';
import {useStorlessFetch} from '../../../hooks/fetch';
import {useDispatch} from 'react-redux';
import {appActions} from '../../../modules/app/actions';
import OrderDetailsContext from '../../../screens/Orders/Context/OrderDetailsContext';
import ButtonListContext from '../../ButtonList/ButtonListContext';
import {modalActions} from '../../../modules/modal/actions';
import {MODALS} from '../../Modal/ModalContents';

interface IProps {
  isListItem?: boolean;
}

const SecondLabelButton = ({isListItem}: IProps): ReactElement => {
  const [secondLabelResponse, createSecondLabel] = useStorlessFetch('shipping_create_second_label');
  const {order, getOrderDetails} = useContext(OrderDetailsContext);
  const {hideButtonList} = useContext(ButtonListContext);
  const dispatch = useDispatch();
  const {t} = useTranslation();

  const requestSecondLabelHandler = async (e: any) => {
    e.stopPropagation();

    dispatch(
      modalActions.addChild(MODALS.SECOND_LABEL_MODAL, {
        onYes: (type: ShippingProviderType) => {
          const data = {
            id: order._id,
            ...order,
            secondLabelInfo: {...(order.secondLabelInfo || {}), shippingProviderType: type},
          };
          createSecondLabel(data);
          dispatch(modalActions.closeModal());
        },
        onNo: () => {
          dispatch(modalActions.closeModal());
        },
      }),
    );
  };

  const displayBase64PdfLabel = () => {
    const base64Content = order.secondLabelInfo?.base64Pdf;
    const blob = Util.getBlob(base64Content);
    const blobUrl = URL.createObjectURL(blob);

    window.open(blobUrl);
  };

  const displayBase64JpgUpsLabel = () => {
    const base64Content = order.secondLabelInfo?.gifImage;
    const doc = new jsPDF({orientation: 'l'});

    doc.setProperties({
      title: `${order.orderNumber}-UPS-label`,
      subject: `${order.orderNumber}-UPS-label`,
    });
    const width = doc.internal.pageSize.getWidth();
    const height = doc.internal.pageSize.getHeight();
    const imgData = `data:image/gif;base64,${base64Content}`;

    doc.addImage(imgData, 'JPEG', 0, 0, width, height);
    doc.autoPrint();
    doc.output('dataurlnewwindow');
  };

  const secondLabelHandler = async (e: any) => {
    e.stopPropagation();

    if (order?.secondLabelInfo?.shippingProviderType === ShippingProviderType.UPS) {
      displayBase64JpgUpsLabel();
    } else {
      displayBase64PdfLabel();
    }
  };

  useEffect(() => {
    if (secondLabelResponse?.data && !secondLabelResponse.data?.error) {
      dispatch(appActions.showSnackBar({text: t('orderDetails.orderConfirmed'), options: {severity: 'success'}}));
      getOrderDetails({id: order._id});
      hideButtonList && hideButtonList();
    } else if (secondLabelResponse.error || secondLabelResponse.data?.error) {
      dispatch(
        appActions.showSnackBar({
          text: secondLabelResponse.data?.error || t('orderDetails.dataValidationFailed'),
          options: {severity: 'error'},
        }),
      );
      getOrderDetails({id: order._id});
    }

    return () => {
      secondLabelResponse.loaded = null;
      secondLabelResponse.data = null;
      secondLabelResponse.error = null;
    };
  }, [secondLabelResponse]);

  return order.secondLabelInfo?.trackingNumber ? (
    <Button
      variant={isListItem ? 'text' : 'contained'}
      className={isListItem ? 'button-list-item' : 'blue-button'}
      onClick={secondLabelHandler}
    >
      {t('orderDetails.secondLabel')}
    </Button>
  ) : (
    <Button
      variant={isListItem ? 'text' : 'contained'}
      className={isListItem ? 'button-list-item' : 'blue-button'}
      onClick={requestSecondLabelHandler}
    >
      {t('orderDetails.requestSecondLabel')}
    </Button>
  );
};

export default SecondLabelButton;
