import React from 'react';
import Form from '../Form/Form';
import _ from 'lodash';
import {useTranslation} from 'react-i18next';
import {DataTableItemType} from '../Table/interfaces/IHeaderAccessor';
import {ChangeLogType} from '../../types/ChangeLog/ChangeLogType';
import * as Yup from 'yup';

export interface IAddChangeLogModalProps {
  title: string;
  onSubmit: (values: any) => void;
  onCancel: () => void;
}

const generateInitialValues = (fields: any[]) => {
  let defaults = {};
  fields.forEach((field) => {
    if (field.type === DataTableItemType.DATE) {
      defaults = {...defaults, [field.fieldKey]: new Date()};
    } else {
      defaults = {...defaults, [field.fieldKey]: ''};
    }
  });
  return defaults;
};

const AddChangeLogModal = (props: IAddChangeLogModalProps) => {
  const {title, onSubmit, onCancel} = props;
  const {t} = useTranslation();

  const fields = [
    {
      label: t('general.title'),
      placeholder: t('general.title'),
      fieldKey: 'title',
      type: 'input',
      autofocus: true,
    },
    {
      label: t('general.description'),
      placeholder: t('general.description'),
      fieldKey: 'description',
      type: 'input',
    },
    {
      label: t('general.link'),
      placeholder: t('general.link'),
      fieldKey: 'link',
      type: 'input',
    },
    {
      label: t('general.date'),
      placeholder: t('general.date'),
      fieldKey: 'date',
      type: DataTableItemType.DATE,
    },
    {
      label: t('changeLog.type'),
      placeholder: t('changeLog.type'),
      fieldKey: 'type',
      type: 'select',
      options: ([{value: null, label: ''}] as any).concat(
        Object.values(ChangeLogType).map((item) => {
          return {value: item, label: item};
        }),
      ),
    },
  ];

  const initialValues = generateInitialValues(fields);

  const validationSchema = Yup.object().shape({
    title: Yup.string().required('title'),
    date: Yup.string().required('date'),
    type: Yup.string().required('type'),
  });

  return (
    <Form
      title={title}
      initialValues={initialValues}
      fields={fields}
      submitButtonText={t('general.submit')}
      cancelButtonText={t('general.cancel')}
      onSubmit={onSubmit}
      onCancel={onCancel}
      validationSchema={validationSchema}
    ></Form>
  );
};

export default AddChangeLogModal;
