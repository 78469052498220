import {Button} from '@material-ui/core';
import React, {ReactElement, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useFetch} from '../../hooks/fetch';

const PdfInventoryListButton = (): ReactElement => {
  const [pdfOfferResponse, getPdfOffer] = useFetch('pdf_inventory_list');
  const [openPdfOfferResponse, setOpenPdfOffer] = useState<boolean>(false);
  const {t} = useTranslation();

  useEffect(() => {
    if (pdfOfferResponse.data && openPdfOfferResponse) {
      const blob = new Blob([new Uint8Array(pdfOfferResponse.data.data).buffer], {type: 'application/pdf'});
      const blobURL = URL.createObjectURL(blob);
      window.open(blobURL);
    }

    return () => {
      pdfOfferResponse.data = null;
      setOpenPdfOffer(false);
    };
  }, [pdfOfferResponse.data]);

  useEffect(() => {
    if (openPdfOfferResponse) {
      getPdfOffer();
    }
  }, [openPdfOfferResponse]);

  return (
    <Button variant="contained" className="blue-button" onClick={() => setOpenPdfOffer(true)}>
      {t('inventoryProducts.viewPdf')}
    </Button>
  );
};

export default PdfInventoryListButton;
