import React from 'react';
import {useTranslation} from 'react-i18next';
import DataTable from '../../components/Table/Table';
import {ShopType} from '../../types/Orders/ShopType';
import {ShippingProviderType} from '../../types/Shipment/ShippingProviderType';
import {DataTableItemType} from '../../components/Table/interfaces/IHeaderAccessor';

const OrdersWithInvoiceIssue = () => {
  const {t} = useTranslation();

  return (
    <div className="orders">
      <DataTable
        headers={[
          {
            kind: 'accessor',
            name: t('orders.orderNumber'),
            accessor: 'orderNumber',
            sortable: true,
          },
          {
            kind: 'accessor',
            name: t('orderDetails.externalOrderNumber'),
            accessor: 'externalOrderNumber',
            sortable: true,
          },
          {
            kind: 'accessor',
            name: t('orderDetails.isTrackingNumberScanned'),
            accessor: 'isTrackingNumberScanned',
            sortable: true,
            type: DataTableItemType.BOOLEAN,
          },
          {
            kind: 'accessor',
            name: t('orders.shippingType'),
            accessor: 'shippingType',
            sortable: true,
            type: DataTableItemType.SELECT,
            options: ([{value: 'all', label: 'All'}] as any).concat(
              Object.values(ShippingProviderType).map((item) => {
                return {value: item, label: item};
              }),
            ),
          },
          {
            kind: 'accessor',
            name: t('orders.shopName'),
            accessor: 'shopName',
            sortable: true,
            type: DataTableItemType.SELECT,
            options: ([{value: 'all', label: 'All'}] as any).concat(
              Object.values(ShopType).map((item) => {
                return {value: item, label: item};
              }),
            ),
          },
          {
            kind: 'accessor',
            name: t('orders.date'),
            accessor: 'date',
            sortable: true,
            type: DataTableItemType.DATE,
            timeZone: 'Europe/Berlin',
          },
          {
            kind: 'accessor',
            name: t('orderDetails.paymentMethodName'),
            accessor: 'paymentMethodName',
            sortable: true,
          },
          {
            kind: 'accessor',
            name: t('orderDetails.orderTotalSum'),
            accessor: 'orderTotalSum',
            sortable: true,
            type: 'number',
          },
          {
            kind: 'accessor',
            name: t('orders.customerNumber'),
            accessor: 'customerNumber',
            sortable: true,
          },
          {
            kind: 'accessor',
            name: t('orderDetails.customerName'),
            accessor: 'billingAddress.customerName',
            optionalAccessor: 'shippingAddress.customerName',
            sortable: true,
          },
          {
            kind: 'accessor',
            name: t('orders.email'),
            accessor: 'billingAddress.email',
            optionalAccessor: 'shippingAddress.email',
            sortable: true,
          },
        ]}
        endpoint={'orders_easybill_issues'}
        target={'orders'}
        defaultSort={{key: 'date', value: 1}}
        title={t('general.easyBillIssues')}
      />
    </div>
  );
};

export default OrdersWithInvoiceIssue;
