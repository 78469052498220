import React, {createContext} from 'react';
import {ISupplierOrderItem} from '../../../types/SupplierOrders/ISupplierOrderItem';

const SupplierOrderItemsTableBodyContext = createContext({} as ISupplierOrderItemsTableBodyContext);
export const SupplierOrderItemsTableBodyProvider = SupplierOrderItemsTableBodyContext.Provider;
export default SupplierOrderItemsTableBodyContext;

interface ISupplierOrderItemsTableBodyContext {
  supplierOrderItem: ISupplierOrderItem;
  orderItemsData: any;
  setOrderItemsData: (data: any) => void;
  doSupplierOrderItemPut: (data: any) => void;
  doSupplierOrderItemDelete: (data: any) => void;
  toggleInPurchaseList: (data: any) => void;
}
