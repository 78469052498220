import React from 'react';
import {useTranslation} from 'react-i18next';
import DataTable from '../../components/Table/Table';
import OpenInvoiceActions from '../../components/Order/OpenInvoiceActions';

const OpenInvoices = () => {
  const {t} = useTranslation();

  return (
    <div className="orders">
      <DataTable
        headers={[
          {
            kind: 'accessor',
            name: t('customerDetails.customerName'),
            accessor: 'customerName',
            sortable: false,
          },
          {
            kind: 'accessor',
            name: t('customerDetails.easybillId'),
            accessor: 'easybillId',
            sortable: false,
          },
          {
            kind: 'component',
            name: t('general.openInvoices'),
            component: OpenInvoiceActions,
            onFetchProps: {},
          },
        ]}
        endpoint={'open_invoices_list'}
        target={'customers'}
        selectRowClickId={'customerId'}
        numberOfItemsPerPage={100}
        title={t('general.openInvoices')}
      />
    </div>
  );
};

export default OpenInvoices;
