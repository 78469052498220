import Form from '../../components/Form/Form';
import React, {useState} from 'react';
import {Button, Typography} from '@material-ui/core';
import {useTranslation} from 'react-i18next';

export interface ISelectProduct {
  title: string;
  onSubmit: (values: any) => void;
  onCancel: () => void;
  onCreateProduct?: () => void;
}

const SelectProduct = (props: ISelectProduct) => {
  const {title, onSubmit, onCancel, onCreateProduct} = props;
  const {t} = useTranslation();
  const [values, setValues] = useState({product: null});
  const [triggerSubmit, setTriggerSubmit] = useState(0);

  return (
    <>
      <Typography className="modal-title">{title}</Typography>
      <Form
        initialValues={values}
        values={values}
        isFilter={true}
        isFilterReset={false}
        onSubmit={onSubmit}
        submitButtonText={t('general.submit')}
        fields={[
          {
            label: t('selectProduct.product'),
            placeholder: t('selectProduct.selectProducts'),
            fieldKey: 'product_id',
            type: 'autocomplete',
            options: [],
            endpoint: 'product_autocomplete_list',
          },
        ]}
        triggerSubmit={triggerSubmit}
      />
      <div className="action-buttons">
        <Button variant="contained" className="blue-button" onClick={onCreateProduct}>
          {t('selectProduct.btnCreate')}
        </Button>
        <Button
          variant="contained"
          className="blue-button"
          onClick={() => {
            setTriggerSubmit(1);
          }}
        >
          {t('selectProduct.submitText')}
        </Button>
        <Button variant="contained" className="blue-button" onClick={onCancel}>
          {t('general.cancel')}
        </Button>
        &nbsp; &nbsp;
      </div>
    </>
  );
};

export default SelectProduct;
