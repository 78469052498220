import {Button} from '@material-ui/core';
import React, {ReactElement, useContext, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {useFetch} from '../../../hooks/fetch';
import {appActions} from '../../../modules/app/actions';
import {modalActions} from '../../../modules/modal/actions';
import {MODALS} from '../../Modal/ModalContents';
import {ISupplierOrderItem} from '../../../types/SupplierOrders/ISupplierOrderItem';
import ProductsWithoutStockContext from '../Context/ProductsWithoutStockContext';

const UpdateSupplierOrderItemWithoutStockButton = ({supplierOrderItem, supplierOrderId}: IProps): ReactElement => {
  const [supplierOrderItemPut, doSupplierOrderItemPut] = useFetch<any>('supplier_order_items_without_stock_put');
  const {fetchSupplierOrderItems} = useContext(ProductsWithoutStockContext);
  const dispatch = useDispatch();
  const {t} = useTranslation();

  const handleCancelModal = () => {
    dispatch(modalActions.closeModal());
  };

  const handleSubmitModal = async (values: any) => {
    const data = {...values, ...(supplierOrderId && {supplierOrder: supplierOrderId})};

    await doSupplierOrderItemPut({...data, id: supplierOrderItem?._id.toString()});
    handleCancelModal();
  };

  const handleClick = async () => {
    dispatch(
      modalActions.addModal(MODALS.ADD_SUPPLIER_ORDER_ITEM, {
        onSubmit: handleSubmitModal,
        onCancel: handleCancelModal,
        productId: supplierOrderItem?.product?._id?.toString() ?? '',
        defaultValues: {quantity: supplierOrderItem?.quantity},
      }),
    );
  };

  useEffect(() => {
    if (supplierOrderItemPut?.error) {
      dispatch(appActions.showSnackBar({text: supplierOrderItemPut.error.name, options: {severity: 'error'}}));
    } else if (supplierOrderItemPut?.data) {
      fetchSupplierOrderItems();
      dispatch(
        appActions.showSnackBar({text: t('supplierOrderItems.movedToShoppingList'), options: {severity: 'success'}}),
      );
    }

    return () => {
      supplierOrderItemPut.data = null;
      supplierOrderItemPut.error = null;
      supplierOrderItemPut.loaded = null;
    };
  }, [supplierOrderItemPut]);

  return (
    <Button variant="contained" className="blue-button" onClick={handleClick}>
      {t('supplierOrderItems.moveToShoppingListButton')}
    </Button>
  );
};

interface IProps {
  supplierOrderId?: string;
  supplierOrderItem?: ISupplierOrderItem;
}

export default UpdateSupplierOrderItemWithoutStockButton;
