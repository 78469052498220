import {Button} from '@material-ui/core';
import React, {ReactElement, useContext} from 'react';
import {useTranslation} from 'react-i18next';
import {modalActions} from '../../../modules/modal/actions';
import {MODALS} from '../../Modal/ModalContents';
import {useDispatch} from 'react-redux';
import {useFetch} from '../../../hooks/fetch';
import {OfferTemplateType} from '../../../types/Orders/OfferTemplateType';
import ButtonListContext from '../../ButtonList/ButtonListContext';

interface IProps {
  type: string;
  orderNumber: string;
  orderId: string;
  isListItem?: boolean;
}

const SendEmailButton = ({orderNumber, orderId, type, isListItem}: IProps): ReactElement => {
  const [sendEmailResponse, sendEmail] = useFetch<any>('orders_send_email');
  const {hideButtonList} = useContext(ButtonListContext);
  const dispatch = useDispatch();
  const {t} = useTranslation();

  const handleClick = async () => {
    dispatch(
      modalActions.addModal(MODALS.SEND_EMAIL_COMPONENT, {
        title: t('supplierOrderDetails.sendEmailButton') || '',
        onSubmit: handleSubmitModal,
        onCancel: handleCancelModal,
      }),
    );
  };

  const handleCancelModal = () => {
    dispatch(modalActions.closeModal());
    hideButtonList && hideButtonList();
  };

  const handleSubmitModal = async (values: any) => {
    const subject = type === OfferTemplateType.ORDER ? `Bestellung ${orderNumber}` : `Angebot ${orderNumber}`;
    sendEmail({...values, orderNumber: orderNumber || '', orderId, subject, type});
    handleCancelModal();
  };

  return (
    <Button
      variant={isListItem ? 'text' : 'contained'}
      className={isListItem ? 'button-list-item' : 'blue-button'}
      onClick={handleClick}
    >
      {t('supplierOrderDetails.sendEmailButton')}
    </Button>
  );
};

export default SendEmailButton;
