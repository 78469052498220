import {runSaga, Saga} from 'redux-saga';
import {all, call} from 'redux-saga/effects';
import {AppAction} from '../modules/app/actions';
import appSaga from '../modules/app/saga';
import apiSaga from '../modules/api/saga';
const sagas = [...appSaga, ...apiSaga];

function* root() {
  yield all(sagas.map((saga) => call(saga)));
}

export type ReduxAction = AppAction;
export async function recordSaga(saga: Saga, initalAction: ReduxAction) {
  const dispatched: ReduxAction[] = [];
  const runner = runSaga(
    {
      dispatch: (action: ReduxAction) => dispatched.push(action),
    },
    saga,
    initalAction,
  );
  //@ts-ignore
  await runner.done;
  return dispatched;
}
export {root};
