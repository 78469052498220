import {MenuItem, Select, Typography} from '@material-ui/core';
import {Button} from '@material-ui/core';
import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {ShippingProviderType} from '../../types/Shipment/ShippingProviderType';

export interface IProps {
  onYes: (data: any) => void;
  onNo: () => void;
}

const SecondLabelModal = ({onYes, onNo}: IProps) => {
  const [shippingProviderType, setShippingProviderType] = useState(ShippingProviderType.DHL);
  const {t} = useTranslation();

  const onChangeShippingProviderType = (e: any) => {
    const shippingType = e.target.value;
    setShippingProviderType(shippingType);
  };

  return (
    <div style={{minWidth: '380px'}}>
      <Typography className="modal-title">{t('orderDetails.requestSecondLabel')}</Typography>
      <div>
        <p className="modal-fields">
          <div style={{paddingBottom: '5px'}}> {t('orderDetails.shippingProvider')}</div>
          <Select
            id="selectShippingProvider"
            value={shippingProviderType}
            onChange={onChangeShippingProviderType}
            style={{marginRight: '5px'}}
            fullWidth
          >
            {[ShippingProviderType.DHL, ShippingProviderType.UPS, ShippingProviderType.GLS].map((item: any) => (
              <MenuItem key={item} value={item}>
                {item}
              </MenuItem>
            ))}
          </Select>
        </p>

        <div className="action-buttons" style={{float: 'right', padding: '5px 10px 0px 0px'}}>
          <Button onClick={() => onYes(shippingProviderType)} className="blue-button">
            {t('general.yes')}
          </Button>

          <Button onClick={onNo} className="yellow-button">
            {t('general.no')}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default SecondLabelModal;
