/* eslint-disable prefer-const */
import React, {useContext, useState} from 'react';
import DataTable from '../../Table/Table';
import {useTranslation} from 'react-i18next';
import AddToOrderButton from './AddToOrderButton';
import {appActions} from '../../../modules/app/actions';
import {useDispatch} from 'react-redux';
import {ShopType} from '../../../types/Orders/ShopType';
import CreateOrderContext from '../../../screens/Orders/Context/CreateOrderContext';
import CreateSupplierOrderContext from '../../../screens/SupplierOrders/Context/CreateSupplierOrderContext';
import {SupplierOrderItemStatus} from '../../../types/SupplierOrders/SupplierOrderItemStatus';
import {IDetailedOrder} from '../../../types/Orders/IDetailedOrder';
import {ISupplierOrder} from '../../../types/SupplierOrders/ISupplierOrder';

export enum ProductPickType {
  CUSTOMER_ORDER = 'CUSTOMER_ORDER',
  SUPPLIER_ORDER = 'SUPPLIER_ORDER',
}

interface ProductPickerProps {
  doAddOrderItem?: (data: any) => void;
  productPickType: ProductPickType;
}

const getContextData = (props: ProductPickerProps) => {
  let order, orderId, postSupplierOrderItem, state;

  if (props.productPickType === ProductPickType.CUSTOMER_ORDER) {
    const context = useContext(CreateOrderContext);
    order = context.order;
  } else {
    const context = useContext(CreateSupplierOrderContext);
    order = context.order;
    orderId = context.orderId;
    postSupplierOrderItem = context.postSupplierOrderItem;
    state = context.state;
  }

  return {order, orderId, state, postSupplierOrderItem};
};

export const ProductPicker = (props: ProductPickerProps) => {
  let {order, orderId, postSupplierOrderItem, state} = getContextData(props);
  const dispatch = useDispatch();
  const {t} = useTranslation();

  const addOrderItemButtonHandler = (addedProduct: any) => {
    order = order as IDetailedOrder;
    const {_id: addedProductId, ddaPrice, suppliesPartnerPrice} = addedProduct;
    const priceNet = order?.pricingShop === ShopType.DRUCKDICHAUS ? ddaPrice : suppliesPartnerPrice;
    const priceGross = order ? +(priceNet * (1 + order.vatPercentage / 100)).toFixed(2) : 0;
    const data = {productId: addedProductId, orderId: order?.orderId, quantity: 1, priceNet, priceGross};

    if (order?.orderItems.some((item) => item.productId === addedProductId)) {
      dispatch(
        appActions.showSnackBar({
          text: t('createOrders.productAlreadyAdded'),
          options: {severity: 'error'},
        }),
      );
      return;
    }

    props.doAddOrderItem && props.doAddOrderItem(data);
  };

  const addSupplierOrderItemButtonHandler = (addedProduct: any) => {
    const alreadyAdded = state?.supplierOrderItems.find((item) => item.product.productId === addedProduct.productId);
    order = order as ISupplierOrder;

    if (alreadyAdded) {
      dispatch(
        appActions.showSnackBar({
          text: t('createOrders.productAlreadyAdded'),
          options: {severity: 'error'},
        }),
      );
      return;
    }

    const data = {
      product: addedProduct._id,
      supplier: order?.supplier,
      supplierOrder: orderId,
      supplierOrderNumber: order?.orderNumber,
      status: SupplierOrderItemStatus.ordered,
      productTitle: addedProduct.title,
      quantity: 1,
      price: 0,
      note: '',
      supplierName: order?.supplier?.name,
      quantityDelivered: 0,
      dateOrdered: new Date(),
      purchaseList: false,
      externalArticleId: '',
    };

    postSupplierOrderItem && postSupplierOrderItem(data);
  };

  return (
    <DataTable
      tableHeight={'400px'}
      headers={[
        {
          kind: 'accessor',
          name: t('products.productId'),
          accessor: 'productId',
          sortable: true,
          type: 'number',
        },
        {
          kind: 'accessor',
          name: t('products.shopwareId'),
          accessor: 'shopwareId',
          sortable: true,
        },
        {
          kind: 'accessor',
          name: t('products.title'),
          accessor: 'title',
          sortable: true,
        },
        {
          kind: 'accessor',
          name: t('products.ddaPrice'),
          accessor: 'ddaPrice',
        },
        {
          kind: 'accessor',
          name: t('products.suppliesPartnerPrice'),
          accessor: 'suppliesPartnerPrice',
        },
        {
          kind: 'accessor',
          name: t('productDetails.calculatedPrice'),
          accessor: 'calculatedPrice',
        },
        {
          kind: 'accessor',
          name: t('productDetails.average-price'),
          accessor: 'averagePrice',
        },
        {
          kind: 'accessor',
          name: t('productDetails.stockDDA'),
          accessor: 'stockDDA',
        },
        {
          kind: 'component',
          name: '',
          component: AddToOrderButton,
          props: {
            onClick: (data: any) => {
              props.productPickType === ProductPickType.CUSTOMER_ORDER
                ? addOrderItemButtonHandler(data)
                : addSupplierOrderItemButtonHandler(data);
            },
          },
          onFetchProps: {},
        },
      ]}
      endpoint={'products_detailed_list'}
      defaultSort={{key: 'productId', value: 1}}
      title={t('createOrders.productSearch')}
    />
  );
};
