import {Button} from '@material-ui/core';
import React, {ReactElement, useContext, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {useFetch} from '../../../hooks/fetch';
import {appActions} from '../../../modules/app/actions';
import {modalActions} from '../../../modules/modal/actions';
import OrderDetailsContext from '../../../screens/Orders/Context/OrderDetailsContext';
import {OrderStatusType} from '../../../types/Orders/OrderStatusType';
import {MODALS} from '../../Modal/ModalContents';

interface IProps {
  isListItem?: boolean;
}

const CompleteOrderButton = ({isListItem}: IProps): ReactElement => {
  const [completeOrderManuallyResponse, completeOrderManually] = useFetch<any>('orders_set_as_completed_manually');
  const {order, getOrderDetails} = useContext(OrderDetailsContext);
  const {t} = useTranslation();
  const dispatch = useDispatch();

  const completeOrderManuallyHandler = () => {
    dispatch(
      modalActions.addModal(MODALS.CONFIRM_DIALOG, {
        title: t('general.areYouSure'),
        content: t('orderDetails.completeOrderMessage'),
        onYes: () => {
          dispatch(modalActions.closeModal());
          completeOrderManually({id: order._id});
        },
        onNo: () => dispatch(modalActions.closeModal()),
      }),
    );
  };

  useEffect(() => {
    if (completeOrderManuallyResponse.data) {
      getOrderDetails({id: order._id});
      dispatch(appActions.showSnackBar({text: t('general.save'), options: {severity: 'success'}}));
      Object.assign(document.createElement('a'), {
        target: '_blank',
        rel: 'noopener noreferrer',
        href: `/manually-completed-orders/${order._id}`,
      }).click();
    }

    if (completeOrderManuallyResponse.error) {
      const errorMessage = completeOrderManuallyResponse.error?.name || 'Error';
      dispatch(appActions.showSnackBar({text: errorMessage, options: {severity: 'error'}}));
    }

    return () => {
      completeOrderManuallyResponse.data = null;
      completeOrderManuallyResponse.error = null;
    };
  }, [completeOrderManuallyResponse]);

  return order.status !== OrderStatusType.Completed || !order.isTrackingNumberScanned ? (
    <Button
      variant={isListItem ? 'text' : 'contained'}
      className={isListItem ? 'button-list-item' : 'blue-button'}
      onClick={completeOrderManuallyHandler}
    >
      {t('orderDetails.completeOrder')}
    </Button>
  ) : (
    <></>
  );
};

export default CompleteOrderButton;
