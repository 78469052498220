import React, {useState, useEffect} from 'react';
import {TextField, Button, Typography} from '@material-ui/core';
import {useFetch} from '../../hooks/fetch';
import {debounce} from 'lodash';
import {useDispatch} from 'react-redux';
import {appActions} from '../../modules/app/actions';
import {useTranslation} from 'react-i18next';

export interface ILabelScannerProps {
  onClose: () => void;
  refresh: (value: boolean) => void;
  carrier: string;
}

const LabelScannerModal: React.FC<ILabelScannerProps> = ({onClose, refresh, carrier}) => {
  const [barcode, setBarcode] = useState<string>('');
  const [scanShippingLabelRes, scanShippingLabel] = useFetch<any>('supplier_order_scan_shipping_label');
  const dispatch = useDispatch();
  const {t} = useTranslation();
  const debouncedHandleBarcodeChange = debounce((newBarcode: string) => {
    refresh(false);
    scanShippingLabel({trackingNumber: newBarcode, carrier: carrier});
  }, 500);

  const handleBarcodeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newBarcode = event.target.value;
    setBarcode(newBarcode);
  };

  useEffect(() => {
    if (barcode === '') {
      return;
    }
    debouncedHandleBarcodeChange(barcode);
    return debouncedHandleBarcodeChange.cancel;
  }, [barcode]);

  useEffect(() => {
    if (scanShippingLabelRes?.data) {
      const data = scanShippingLabelRes.data;

      if (data.success) {
        dispatch(
          appActions.showSnackBar({
            text: data.message,
            options: {severity: 'success'},
          }),
        );
        refresh(true);
        setBarcode('');
      } else {
        dispatch(
          appActions.showSnackBar({
            text: data.message,
            options: {severity: 'error'},
          }),
        );
      }
    }

    return () => {
      scanShippingLabelRes.data = null;
      scanShippingLabelRes.error = null;
      refresh(false);
    };
  }, [scanShippingLabelRes]);

  useEffect(() => {
    setBarcode('');

    return () => {
      setBarcode('');
    };
  }, []);

  return (
    <div className="orders" style={{minWidth: '400px', width: 'auto'}}>
      <Typography>{t('supplierOrders.shippingLabelScannerTitle')}</Typography>
      <br />
      <TextField label="Barcode" variant="outlined" autoFocus value={barcode} onChange={handleBarcodeChange} />
      <br />
      <Button style={{float: 'right'}} className="blue-button" onClick={onClose}>
        {t('general.cancel')}
      </Button>
    </div>
  );
};

export default LabelScannerModal;
