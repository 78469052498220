import {Button} from '@material-ui/core';
import React, {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useStorlessFetch} from '../../hooks/fetch';
import {Util} from '../../modules/api/utils';

interface IProps {
  path: string;
  fileName: string;
}

export default function ViewInvoiceButton({path, fileName}: IProps) {
  const [getInvoiceResponse, getInvoice] = useStorlessFetch('file_get');
  const {t} = useTranslation();

  useEffect(() => {
    if (getInvoiceResponse.data) {
      const extension = fileName ? fileName.split('.').pop()?.toLowerCase() : '';
      const mimeType = Util.getMimeType(extension);

      if (mimeType) {
        const blob = new Blob([new Uint8Array(getInvoiceResponse.data.data).buffer], {type: mimeType});
        const blobURL = URL.createObjectURL(blob);
        window.open(blobURL);
      }
    }

    return () => {
      getInvoiceResponse.data = null;
    };
  }, [getInvoiceResponse.data]);

  return fileName ? (
    <Button variant="contained" className="blue-button" onClick={() => getInvoice({path, fileName})}>
      {t('supplierOrderDetails.viewInvoice')}
    </Button>
  ) : (
    <></>
  );
}
