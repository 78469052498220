import {createTheme} from '@material-ui/core';
import vars from './_export.module.scss';

export const darkTheme = createTheme({
  spacing: (s) => [0, 8, 16, 18, 20, 26, 36, 42, 48, 66, 86, 112][s],
  typography: {
    fontFamily: ['Roboto-Regular', 'sans-serif'].join(','),
    allVariants: {
      fontFamily: 'Roboto-Regular',
      fontSize: 14,
    },
    h1: {
      fontSize: vars.fontSizeTitle,
      fontWeight: 500,
      lineHeight: 1.3,
      letterSpacing: 0.5,
    },
    h2: {
      fontSize: vars.fontSizeSubtitle,
      fontWeight: 500,
    },
    h3: {
      fontSize: vars.fontSizeTitleSmall,
      fontWeight: 300,
    },
    h4: {
      fontSize: vars.fontSizeBody,
      fontWeight: 300,
    },
    h5: {
      fontSize: vars.fontSizeSubtitleSmall,
      fontWeight: 700,
    },
  },
  palette: {
    common: {
      black: vars.whiteColor,
      white: vars.blackColor,
    },
    background: {
      paper: vars.whiteColor,
      default: '#1e293b',
    },
    primary: {
      light: vars.darkPrimaryLightColor,
      main: vars.darkPrimaryColor,
      dark: vars.darkPrimaryDarkColor,
    },
    secondary: {
      light: vars.darkSecondaryLightColor,
      main: vars.darkSecondaryColor,
      dark: vars.darkSecondaryDarkColor,
    },
    error: {
      light: vars.darkErrorLightColor,
      main: vars.darkErrorColor,
      dark: vars.darkErrorDarkColor,
    },
    text: {
      primary: vars.darkPrimaryTextColor,
      secondary: vars.darkSecondaryTextColor,
      disabled: vars.darkDisabledColor,
      hint: vars.darkHintColor,
    },
  },
  overrides: {
    MuiFormControl: {
      root: {width: '100%'},
    },
    MuiPaper: {
      root: {
        backgroundColor: vars.darkThirdBackground,
      },
    },
    MuiOutlinedInput: {
      root: {
        '&$multiline': {
          // Correctly targets the multiline variant of MuiOutlinedInput
          background: vars.darkSecondaryBackground,
          padding: '18.5px 14px', // If you want to add padding
        },
      },
    },
    MuiAccordion: {
      root: {
        backgroundColor: vars.darkThirdBackground,
        color: vars.darkPrimaryTextColor,
      },
    },
    MuiAccordionSummary: {
      root: {
        backgroundColor: vars.darkThirdBackground,
        color: vars.darkPrimaryTextColor,
      },
      content: {
        color: vars.darkPrimaryTextColor,
      },
    },
    MuiAccordionDetails: {
      root: {
        backgroundColor: vars.darkThirdBackground,
        color: vars.darkPrimaryTextColor,
      },
    },
    MuiIconButton: {
      root: {
        color: vars.darkTextColor,
      },
    },
    MuiGrid: {
      item: {
        color: vars.darkTextColor,
      },
    },
    MuiChip: {
      root: {
        backgroundColor: vars.darkThirdBackground,
        color: vars.darkPrimaryTextColor,
      },
    },

    MuiSwitch: {
      switchBase: {
        // Controls default (unchecked) color for the thumb
        color: '#ccc',
      },
      colorSecondary: {
        '&$checked': {
          // Controls checked color for the thumb
          color: '#f2ff00',
        },
      },
      track: {
        // Controls default (unchecked) color for the track
        opacity: 0.2,
        backgroundColor: '#fff',
        '$checked$checked + &': {
          // Controls checked color for the track
          opacity: 0.7,
          backgroundColor: vars.darkPrimaryButtonBackground,
        },
      },
    },
  },
});
