import {Grid, IconButton} from '@material-ui/core';
import {ExpandMore} from '@material-ui/icons';
import React, {useState} from 'react';
import i18n from '../i18n';

interface Language {
  code: string;
  name: string;
  imageSrc: string;
}

interface IProps {
  languages: Language[];
}

const LanguageSelector = ({languages}: IProps) => {
  const [selectedLanguage, setSelectedLanguage] = useState<Language>(languages[0]);
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);

  const handleLanguageSelect = (language: Language) => {
    setSelectedLanguage(language);
    setIsDropdownOpen(false);
    i18n.changeLanguage(language.code);
  };

  return (
    <div className="language-selector">
      <div className="language-selector-toggle" onClick={() => setIsDropdownOpen(!isDropdownOpen)}>
        <Grid container alignItems="center" spacing={0}>
          <Grid item>
            <img height={30} src={selectedLanguage.imageSrc} alt={selectedLanguage.name} />
          </Grid>
          <Grid item>
            <IconButton style={{color: 'white'}} className="accordion-icon">
              <ExpandMore />
            </IconButton>
          </Grid>
        </Grid>
      </div>
      {isDropdownOpen && (
        <div className="language-selector-menu">
          {languages.map((language) => (
            <div
              key={language.name}
              className="language-selector-item"
              onClick={() => handleLanguageSelect(language)}
              style={{
                fontWeight: selectedLanguage === language ? 'bold' : 'normal',
              }}
            >
              <Grid container alignItems="center" spacing={2}>
                <Grid item>
                  <img height={30} src={language.imageSrc} alt={language.name} />
                </Grid>
                <Grid item>{language.name}</Grid>
              </Grid>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default LanguageSelector;
