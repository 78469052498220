import React, {ReactElement, useContext} from 'react';
import {useTranslation} from 'react-i18next';
import {Grid} from '@material-ui/core';
import {Check, Remove} from '@material-ui/icons';
import moment from 'moment';
import CreateSupplierOrderContext from '../../screens/SupplierOrders/Context/CreateSupplierOrderContext';
import {ICustomField} from '../../types/CustomFields/CustomField';
import {CustomField} from '../Product/CustomField';
import UploadInvoiceButton from '../UploadInvoiceComponent/UploadInvoiceButton';
import OrderNotesBox from '../Order/OrderNotesBox';

const SupplierOrderCardInfo = (): ReactElement => {
  const {order, getSupplierOrderDetails, customFields, setSupplierOrder} = useContext(CreateSupplierOrderContext);
  const {t} = useTranslation();
  const {invoice} = order;

  return (
    <Grid container>
      <Grid item xs={12} sm={8} md={8}>
        <div className="order-info">
          <div className="order-info-title">{t('orderDetails.orderInfo')}</div>
          <Grid container spacing={0} alignItems="center">
            <Grid item xs={12} sm={6} className="order-info-item">
              <div className="order-info-label">{t('supplierOrderDetails.supplier')} </div>
              <div className="order-info-text">{order.supplier?.name}</div>
            </Grid>
            <Grid item xs={12} sm={6} className="order-info-item">
              <span className="order-info-label">{t('supplierOrderDetails.orderNumber')}</span>
              <span className="order-info-text">{order?.orderNumber}</span>
            </Grid>
            <Grid item xs={12} sm={6} className="order-info-item">
              <span className="order-info-label">{t('supplierOrderDetails.status')}</span>
              <span className="order-info-text">{order?.status}</span>
            </Grid>

            <Grid item xs={12} sm={6} className="order-info-item">
              <span className="order-info-label">{t('supplierOrderDetails.createdAt')}</span>
              <span className="order-info-text">{moment(order?.createdAt).format('DD. MMM YYYY HH:mm:ss')}</span>
            </Grid>
            <Grid item xs={12} sm={6} className="order-info-item">
              <span className="order-info-label">{t('supplierOrderDetails.deliveredAt')}</span>
              <span className="order-info-text">
                {order?.deliveredAt ? moment(order?.deliveredAt).format('DD. MMM YYYY HH:mm:ss') : '-'}
              </span>
            </Grid>
            <Grid item xs={12} sm={6} className="order-info-item">
              <span className="order-info-label">{t('supplierOrderDetails.deliveryDate')}</span>
              <span className="order-info-text">
                {order?.deliveryDate ? moment(order?.deliveryDate).format('DD. MMM YYYY') : '-'}
              </span>
            </Grid>

            <Grid item xs={12} sm={6} className="order-info-item">
              <span className="order-info-label">{t('supplierOrderDetails.invoiceUploaded')}</span>
              <span className="order-info-text">{order.invoice?.fileName ? <Check /> : <Remove height={10} />}</span>
            </Grid>

            <UploadInvoiceButton
              onSuccessCallback={() => getSupplierOrderDetails({id: order._id})}
              endpoint="upload_supplier_order_invoice"
              entityId={order._id || ''}
            />

            <Grid item xs={12} sm={6} className="order-info-item">
              <span className="order-info-label">{t('supplierOrderDetails.invoiceNumber')}</span>
              <span className="order-info-text">{invoice?.invoiceNumber}</span>
            </Grid>

            <Grid item xs={12} sm={6} className="order-info-item">
              <span className="order-info-label">{t('supplierOrderDetails.invoiceUploadedAt')}</span>
              <span className="order-info-text">
                {invoice?.uploadedAt ? moment(invoice?.uploadedAt).format('DD. MMM YYYY') : '-'}
              </span>
            </Grid>
            <Grid item xs={12} sm={6} className="order-info-item">
              <span className="order-info-label">{t('supplierOrderDetails.invoiceDate')}</span>
              <span className="order-info-text">
                {invoice?.date ? moment(invoice?.date).format('DD. MMM YYYY') : '-'}
              </span>
            </Grid>
            <Grid item xs={12} sm={6} className="order-info-item">
              <span className="order-info-label">{t('supplierOrderDetails.invoiceDueDate')}</span>
              <span className="order-info-text">
                {invoice?.dueDate ? moment(invoice?.dueDate).format('DD. MMM YYYY') : '-'}
              </span>
            </Grid>
            <Grid item xs={12} sm={6} className="order-info-item">
              <span className="order-info-label">{t('supplierOrderDetails.paymentMethod')}</span>
              <span className="order-info-text">{order?.paymentMethod}</span>
            </Grid>
            <Grid item xs={12} sm={6} className="order-info-item">
              <span className="order-info-label">{t('supplierOrderDetails.vatPercentage')}</span>
              <span className="order-info-text">{order?.vatPercentage}%</span>
            </Grid>
            <Grid item xs={12} sm={6} className="order-info-item">
              <span className="order-info-label">{t('supplierOrderDetails.additionalCost')}</span>
              <span className="order-info-text">
                {order?.additionalCost?.toLocaleString('de-DE', {
                  style: 'currency',
                  currency: 'EUR',
                  minimumFractionDigits: 2,
                }) || `0,00 €`}
              </span>
            </Grid>
            <Grid item xs={12} sm={6} className="order-info-item">
              <span className="order-info-label">{t('supplierOrderDetails.totalNet')}</span>
              <span className="order-info-text">
                {order?.totalNet?.toLocaleString('de-DE', {
                  style: 'currency',
                  currency: 'EUR',
                  minimumFractionDigits: 2,
                }) || `0,00 €`}
              </span>
            </Grid>
            <Grid item xs={12} sm={6} className="order-info-item">
              <span className="order-info-label">{t('supplierOrderDetails.totalGross')}</span>
              <span className="order-info-text">
                {order?.totalGross?.toLocaleString('de-DE', {
                  style: 'currency',
                  currency: 'EUR',
                  minimumFractionDigits: 2,
                }) || `0,00 €`}
              </span>
            </Grid>
            <Grid item xs={12} sm={6} className="order-info-item">
              <span className="order-info-label">{t('supplierOrderDetails.isPaid')}</span>
              <span className="order-info-text">
                {order.invoice?.paidAt ? (
                  <>
                    <Check style={{marginRight: '10px'}} />
                    {moment(invoice?.paidAt).format('DD. MMM YYYY')}
                  </>
                ) : (
                  <Remove height={10} />
                )}
              </span>
            </Grid>
            <Grid item xs={12} sm={6} className="order-info-item">
              <span className="order-info-label">{t('supplierOrderDetails.confirmed')}</span>
              <span className="order-info-text">{order.confirmed ? <Check /> : <Remove height={10} />}</span>
            </Grid>
            <Grid item xs={12} sm={6} className="order-info-item">
              <span className="order-info-label">{t('supplierOrderDetails.orderConfirmationNumber')}</span>
              <span className="order-info-text">{order.orderConfirmationNumber ?? ''}</span>
            </Grid>
            {customFields?.map((field: ICustomField) => {
              return (
                <Grid item xs={12} sm={6} className="order-info-item" key={field.fieldName}>
                  <span className="order-info-label">{field.fieldName}</span>
                  <span className="order-info-text">
                    <CustomField type={field.type} value={order?.customFields?.[field.fieldName] ?? ''} />
                  </span>
                </Grid>
              );
            })}
          </Grid>
        </div>
      </Grid>
      <Grid item xs={12} sm={4} md={4}>
        <OrderNotesBox endpoint="supplier_order_patch" marginTop="0px" setOrder={setSupplierOrder} order={order} />
      </Grid>
    </Grid>
  );
};

export default SupplierOrderCardInfo;
