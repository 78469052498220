import React, {useState} from 'react';
import SingleTodoView from './SingleTodoView';
import {ITodoTask} from '../../types/Todo/ITodoTask';
import {useTranslation} from 'react-i18next';

interface TodoItemsListProps {
  todos: ITodoTask[];
}

const ListOfTodos: React.FC<TodoItemsListProps> = ({todos}) => {
  const {t} = useTranslation();

  return (
    <div className="tasks-list">
      {todos.map((todo) => (
        <SingleTodoView key={todo._id} todo={todo} />
      ))}
      {todos.length === 0 && <div className="all-tasks-completed">{t('todos.allTasksCompleted')}</div>}
    </div>
  );
};

export default ListOfTodos;
