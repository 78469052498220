import React, {ReactElement, useContext, useEffect, useState} from 'react';
import {Select, MenuItem, Button} from '@material-ui/core';
import {SHOPWARE_PAYMENT_TYPE} from '../../types/Orders/ShopwarePaymentType';
import OrderDetailsContext from '../../screens/Orders/Context/OrderDetailsContext';
import {useTranslation} from 'react-i18next';
import {useStorlessFetch} from '../../hooks/fetch';
import {appActions} from '../../modules/app/actions';
import {useDispatch} from 'react-redux';
import {MODALS} from '../Modal/ModalContents';
import {modalActions} from '../../modules/modal/actions';
import {OrderType} from '../../types/Orders/OrderType';

const EditPaymentMethod = (): ReactElement => {
  const {order, setOrder, retryEasyBillInvoiceCreation} = useContext(OrderDetailsContext);
  const [orderPut, doOrderPut] = useStorlessFetch('order_put');
  const [currentPaymentMethod, setCurrentPaymentMethod] = useState(order.paymentMethodName);
  const {easyBillInfo} = order;
  const dispatch = useDispatch();
  const {t} = useTranslation();

  const onPaymentMethodChange = (e: any) => {
    const paymentMethodName = e.target.value;
    setOrder({
      ...order,
      paymentMethodName: paymentMethodName,
      paymentInfo: {
        ...(order.paymentInfo && order.paymentInfo),
        paymentMethod: paymentMethodName,
        paymentMethodManuallyUpdated: true,
      },
    });
  };

  const handleCreateInvoice = (createInvoice: boolean) => {
    doOrderPut({...order, id: order._id});
    setCurrentPaymentMethod(order.paymentMethodName);
    dispatch(modalActions.closeModal());

    if (createInvoice) {
      retryEasyBillInvoiceCreation({id: order._id});
    }
  };

  const onSaveButton = () => {
    if (order.paymentMethodName === SHOPWARE_PAYMENT_TYPE.PAYMENT_IN_ADVANCE && !easyBillInfo?.easybillInvoiceId) {
      dispatch(
        modalActions.addModal(MODALS.CONFIRM_DIALOG, {
          title: t('general.areYouSure'),
          content: t('orderDetails.createEasyBillInvoice'),
          onYes: () => handleCreateInvoice(true),
          onNo: () => handleCreateInvoice(false),
        }),
      );
    } else {
      doOrderPut({...order, id: order._id});
      setCurrentPaymentMethod(order.paymentMethodName);
    }
  };

  useEffect(() => {
    if (orderPut?.data) {
      dispatch(appActions.showSnackBar({text: t('general.submitFormText'), options: {severity: 'success'}}));
    } else if (orderPut?.error) {
      dispatch(appActions.showSnackBar({text: orderPut.error.name, options: {severity: 'error'}}));
    }

    return () => {
      orderPut.data = null;
      orderPut.error = null;
    };
  }, [orderPut]);

  return (
    <>
      {order.orderType === OrderType.Amazon ? (
        order?.paymentMethodName
      ) : (
        <span>
          <Select
            id="selectPaymentMethod"
            value={order.paymentMethodName}
            onChange={onPaymentMethodChange}
            style={{marginRight: '5px'}}
          >
            {Object.values(SHOPWARE_PAYMENT_TYPE).map((item: any) => (
              <MenuItem key={item} value={item}>
                {item}
              </MenuItem>
            ))}
          </Select>
          {order.paymentMethodName !== currentPaymentMethod && (
            <Button variant="contained" className="order-info-button" onClick={onSaveButton}>
              {t('general.save')}
            </Button>
          )}
        </span>
      )}
    </>
  );
};

export default EditPaymentMethod;
