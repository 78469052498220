import {Button} from '@material-ui/core';
import React, {ReactElement, useContext} from 'react';
import {useTranslation} from 'react-i18next';
import {Util} from '../../../modules/api/utils';
import OrderDetailsContext from '../../../screens/Orders/Context/OrderDetailsContext';

export default function CustomsButton(): ReactElement {
  const {order} = useContext(OrderDetailsContext);
  const {t} = useTranslation();

  const displayBase64PdfLabel = () => {
    const label = order.shippingLabel?.customsDoc;
    const blob = Util.getBlob(label);
    const blobUrl = URL.createObjectURL(blob);
    window.open(blobUrl);
  };

  const onClick = async () => {
    displayBase64PdfLabel();
  };

  return order.shippingLabel?.customsDoc ? (
    <Button variant="contained" className="blue-button" onClick={onClick}>
      {t('orderDetails.customsDoc')}
    </Button>
  ) : (
    <></>
  );
}
