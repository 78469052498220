/* eslint-disable react/display-name */
import React, {useCallback, useEffect, useRef} from 'react';
import {useDispatch} from 'react-redux';
import {modalActions} from '../../modules/modal/actions';
import {useSelector} from 'react-redux';
import {selectRightDrawer, selectLeftDrawer} from '../../modules/modal/selectors';
import {MODALS} from '../Modal/ModalContents';

export const ComponentWithCliclListener = (Component: React.ComponentType<any> | string) => (props: any) => {
  const divRef = useRef(null);
  const dispatch = useDispatch();

  const rightDrawerData = useSelector(selectRightDrawer);
  const leftDrawerData = useSelector(selectLeftDrawer);

  const clickListener = useCallback(
    (e: MouseEvent) => {
      if (!(divRef.current! as any)?.contains(e.target) && e.target !== document.body) {
        const presentationDiv = document.querySelectorAll('[role="presentation"]');
        let returnFlag = false;
        presentationDiv.forEach((parent) => {
          if ((parent as any).contains(e.target)) {
            returnFlag = true;
          }
        });
        if (returnFlag) return;
        if (rightDrawerData.modalKey !== MODALS.NONE) {
          dispatch(modalActions.closeRightDrawer());
        } else if (leftDrawerData.modalKey !== MODALS.NONE) {
          e.stopImmediatePropagation();
        }
      }
    },
    [divRef.current],
  );
  useEffect(() => {
    if (rightDrawerData?.modalKey !== MODALS.NONE || leftDrawerData?.modalKey !== MODALS.NONE) {
      document.addEventListener('mouseup', clickListener);
    }
    return () => {
      document.removeEventListener('mouseup', clickListener);
    };
  }, [rightDrawerData, leftDrawerData]);

  return (
    <div ref={divRef}>
      <Component {...(props as any)} />
    </div>
  );
};
