import {Typography} from '@material-ui/core';
import React from 'react';
import Form from '../Form/Form';
import _ from 'lodash';
import {useTranslation} from 'react-i18next';
import {ICompanyConfig} from '../../types/Company/ICompanyDetails';

export interface IEditCompanyConfig {
  title: string;
  onSubmit: (values: any) => void;
  onCancel: () => void;
  data?: ICompanyConfig;
}

const generateInitialValues = (fields: any[], data: any) => {
  if (data) {
    const fields = {...data};
    return fields;
  } else {
    let defaults = {};
    fields.forEach((field) => {
      defaults = {...defaults, [field.fieldKey]: ''};
    });
    return defaults;
  }
};

export const EditCompanyConfig = (props: IEditCompanyConfig) => {
  const {title, onSubmit, onCancel, data} = props;
  const {t} = useTranslation();

  const fields = [
    {
      label: t('companyConfig.street'),
      placeholder: t('companyConfig.street'),
      fieldKey: 'data.addressDetails.street',
      type: 'input',
    },
    {
      label: t('companyConfig.city'),
      placeholder: t('companyConfig.city'),
      fieldKey: 'data.addressDetails.city',
      type: 'input',
    },
    {
      label: t('companyConfig.country'),
      placeholder: t('companyConfig.country'),
      fieldKey: 'data.addressDetails.country',
      type: 'input',
    },
    {
      label: t('companyConfig.zipCode'),
      placeholder: t('companyConfig.zipCode'),
      fieldKey: 'data.addressDetails.zipCode',
      type: 'input',
    },
    {
      label: t('companyConfig.iban'),
      placeholder: t('companyConfig.iban'),
      fieldKey: 'data.bankDetails.iban',
      type: 'input',
    },
    {
      label: t('companyConfig.localCourt'),
      placeholder: t('companyConfig.localCourt'),
      fieldKey: 'data.bankDetails.localCourt',
      type: 'input',
    },
    {
      label: t('companyConfig.label'),
      placeholder: t('companyConfig.label'),
      fieldKey: 'data.bankDetails.label',
      type: 'input',
    },
    {
      label: t('companyConfig.phone'),
      placeholder: t('companyConfig.phone'),
      fieldKey: 'data.contactDetails.phone',
      type: 'input',
    },
    {
      label: t('companyConfig.fax'),
      placeholder: t('companyConfig.fax'),
      fieldKey: 'data.contactDetails.fax',
      type: 'input',
    },
    {
      label: t('companyConfig.email'),
      placeholder: t('companyConfig.email'),
      fieldKey: 'data.contactDetails.email',
      type: 'input',
    },
    {
      label: t('companyConfig.web'),
      placeholder: t('companyConfig.web'),
      fieldKey: 'data.contactDetails.web',
      type: 'input',
    },
    {
      label: t('companyConfig.managingDirector'),
      placeholder: t('companyConfig.managingDirector'),
      fieldKey: 'data.additionalDetails.managingDirector',
      type: 'input',
    },
    {
      label: t('companyConfig.salesTaxId'),
      placeholder: t('companyConfig.salesTaxId'),
      fieldKey: 'data.additionalDetails.salesTaxId',
      type: 'input',
    },
    {
      label: t('companyConfig.taxNumber'),
      placeholder: t('companyConfig.taxNumber'),
      fieldKey: 'data.additionalDetails.taxNumber',
      type: 'input',
    },
  ];

  const initialValues = generateInitialValues(fields, data);

  return (
    <>
      <Typography className="modal-title">{title}</Typography>
      <Form
        initialValues={initialValues}
        fields={fields}
        submitButtonText={t('general.submit')}
        cancelButtonText={t('general.cancel')}
        onSubmit={onSubmit}
        onCancel={onCancel}
      ></Form>
    </>
  );
};

export default EditCompanyConfig;
