import {Button} from '@material-ui/core';
import {CropFree} from '@material-ui/icons';
import React, {ReactElement, useContext, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {modalActions} from '../../../modules/modal/actions';
import OrderDetailsContext from '../../../screens/Orders/Context/OrderDetailsContext';
import {OrderItemType} from '../../../types/OrderItems/OrderItemType';
import {OrderStatusType} from '../../../types/Orders/OrderStatusType';
import ButtonListContext from '../../ButtonList/ButtonListContext';
import {MODALS} from '../../Modal/ModalContents';

interface IProps {
  isListItem?: boolean;
}

const ReturnOrderButton = ({isListItem}: IProps): ReactElement => {
  const [showReturnButton, setShowReturnButton] = useState(false);
  const {order, getOrderDetails} = useContext(OrderDetailsContext);
  const {hideButtonList} = useContext(ButtonListContext);
  const dispatch = useDispatch();
  const {t} = useTranslation();

  const handleReturnedOrderScanning = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    event.preventDefault();

    dispatch(
      modalActions.addModal(MODALS.CUSTOMER_RETURN_ORDER_SCANNER_MODAL, {
        onScan: async () => {
          await getOrderDetails({id: order._id});
          hideButtonList && hideButtonList();
        },
        orderId: order._id,
        hideDefaultCloseButton: true,
      }),
    );
  };

  useEffect(() => {
    const allowedStatuses = [OrderStatusType.Completed, OrderStatusType.Returned, OrderStatusType.PartiallyReturned];
    const notAllItemsAreReturned = order.orderItems
      ?.filter((item) => [OrderItemType.product0, OrderItemType.product1].includes(item.type))
      .some((item) => item.quantity !== item.returnQuantityScanned);

    if (allowedStatuses.includes(order.status) && notAllItemsAreReturned) {
      setShowReturnButton(true);
    } else {
      setShowReturnButton(false);
    }
  }, [order]);

  return showReturnButton ? (
    <Button
      variant={isListItem ? 'text' : 'contained'}
      className={isListItem ? 'button-list-item' : 'blue-button'}
      onClick={handleReturnedOrderScanning}
      endIcon={<CropFree fontSize="small"></CropFree>}
    >
      {t('scanner.returnArticle')}
    </Button>
  ) : (
    <></>
  );
};

export default ReturnOrderButton;
