import React, {createContext} from 'react';
import {ITodoList} from '../../../types/Todo/ITodoList';
import {ITodoTask} from '../../../types/Todo/ITodoTask';
import {ITodoScreen} from '../../../types/Todo/ITodoScreen';
import {TodoSocketList} from '../../../types/Todo/TodoSocketList';

interface ITodoContext {
  selectedList: ITodoList | null;
  setSelectedList: React.Dispatch<React.SetStateAction<ITodoList | null>>;
  todoLists: ITodoList[];
  setTodoLists: React.Dispatch<React.SetStateAction<ITodoList[]>>;
  switchList: (list: ITodoList) => void;
  todos: ITodoTask[];
  setTodos: React.Dispatch<React.SetStateAction<ITodoTask[]>>;
  upcomingTodos: ITodoTask[];
  setUpcomingTodos: React.Dispatch<React.SetStateAction<ITodoTask[]>>;
  serialTodos: ITodoTask[];
  setSerialTodos: React.Dispatch<React.SetStateAction<ITodoTask[]>>;
  searchTodos: ITodoTask[];
  completedToday: number;
  setCompletedToday: React.Dispatch<React.SetStateAction<number>>;
  getTodoLists: () => void;
  usernames: string[];
  editingTodo: ITodoTask | null;
  setEditingTodo: React.Dispatch<React.SetStateAction<ITodoTask | null>>;
  screens: ITodoScreen[];
  screen: ITodoScreen;
  switchScreen: (screen: ITodoScreen) => void;
  refreshTodos: () => void;
  completeTodoTask: (task: {id: string}) => void;
  updateTodoTask: (task: ITodoTask) => void;
  updateTodoTaskResponse: any;
  createTodoTask: (task: any) => void;
  createTodoTaskResponse: any;
  updatePutTodoTask: (data: any) => void;
  completeSubtask: (task: {id: string; subtaskId: number}) => void;
  todoSocketLists: TodoSocketList[];
  activeTaskId: string | null;
  setActiveTaskId: React.Dispatch<React.SetStateAction<string | null>>;
  uncompleteTodoTask: (data: {id: string}) => void;
  refetchTodoSocketLists: () => void;
}

const TodoContext = createContext({} as ITodoContext);
export const TodoProvider = TodoContext.Provider;
export default TodoContext;
