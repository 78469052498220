import React, {ReactElement, useContext, useEffect} from 'react';
import OrderDetailsContext from '../../../screens/Orders/Context/OrderDetailsContext';
import {modalActions} from '../../../modules/modal/actions';
import {useFetch} from '../../../hooks/fetch';
import {MODALS} from '../../Modal/ModalContents';
import {useTranslation} from 'react-i18next';
import {Button} from '@material-ui/core';
import {useDispatch} from 'react-redux';
import {appActions} from '../../../modules/app/actions';
import {get} from 'lodash';

interface IProps {
  isListItem?: boolean;
}

const CreateInvoiceForExchangeOrderButton = ({isListItem}: IProps): ReactElement => {
  const {order, getOrderDetails} = useContext(OrderDetailsContext);
  const [createInvoiceForExchangeOrderResponse, createInvoiceForExchangeOrder] = useFetch<any>(
    'create_invoice_for_exchange_order',
  );

  const {t} = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    if (createInvoiceForExchangeOrderResponse.data) {
      dispatch(appActions.showSnackBar({text: t('general.submitFormText'), options: {severity: 'success'}}));
    }

    if (createInvoiceForExchangeOrderResponse.error) {
      dispatch(
        appActions.showSnackBar({text: createInvoiceForExchangeOrderResponse.error.name, options: {severity: 'error'}}),
      );
    }

    getOrderDetails({id: order._id});
  }, [createInvoiceForExchangeOrderResponse]);

  const onSubmit = async (values: any) => {
    if (confirm(t('general.areYouSure'))) {
      createInvoiceForExchangeOrder({id: order._id, data: values});
    }
  };

  const onCancel = () => {
    dispatch(modalActions.closeModal());
  };

  const action = () => {
    dispatch(modalActions.addModal(MODALS.EXCHANGE_ORDER_INVOICE, {orderId: order._id, onSubmit, onCancel}));
  };

  return order.exchangeOrder && !order.easyBillInfo?.invoiceCreated ? (
    <Button
      variant={isListItem ? 'text' : 'contained'}
      className={isListItem ? 'button-list-item' : 'blue-button'}
      onClick={action}
    >
      {t('orderDetails.createInvoiceForExchangeOrder')}
    </Button>
  ) : (
    <></>
  );
};

export default CreateInvoiceForExchangeOrderButton;
