import './App.scss';
import React from 'react';
import {ConnectedRouter} from 'connected-react-router';
import {history} from './lib/history';
import RouterSwitcher from './routers/RouterSwitcher';
import Splash from './screens/Splash';
import {Modal} from './components/Modal/Modal';
import SnackBarBase from './components/SnackBar/SnackBarBase';
import {withTranslation} from 'react-i18next';
import {ToastContainer} from 'react-toastify';

const App = () => {
  return (
    <div className="page-content">
      <Splash>
        <ConnectedRouter history={history}>
          <RouterSwitcher />
        </ConnectedRouter>
      </Splash>
      <Modal />
      <SnackBarBase />
      <ToastContainer />
    </div>
  );
};

export default withTranslation('translation')(App);
