import {Box} from '@material-ui/core';
import React from 'react';
import moment from 'moment';
import {useTranslation} from 'react-i18next';

interface IDateRangeComponentProps {
  name: string;
  state: any;
  setState: (data: any) => void;
}

export const DateRangeComponent = (props: IDateRangeComponentProps): React.ReactElement => {
  const {name, state, setState} = props;
  const {t} = useTranslation();

  return (
    <Box>
      <div>
        <label style={{width: '40px', display: 'inline-flex', padding: '0px'}} htmlFor={`${name}-from`}>
          {t('general.from')}:
        </label>
        <input
          type="date"
          id={`${name}-from`}
          name={`${name}-from`}
          min="2015-01-01"
          onChange={(e) => {
            setState({
              ...state,
              [`${name}`]: {
                ...state[name],
                $gte: e.target.valueAsDate,
                isDate: true,
              },
            });
          }}
        />
      </div>

      <div>
        <label style={{width: '40px', display: 'inline-flex', padding: '0px'}} htmlFor={`${name}-to`}>
          {t('general.to')}:
        </label>
        <input
          type="date"
          id={`${name}-to`}
          name={`${name}-to`}
          min="2015-01-01"
          onChange={(e) => {
            setState({
              ...state,
              [`${name}`]: {
                ...state[name],
                $lte: moment(e.target.valueAsDate).endOf('day').toDate(),
                isDate: true,
              },
            });
          }}
        />
      </div>
    </Box>
  );
};
