import {Box, Tabs, Tab} from '@material-ui/core';
import {TabContext, TabPanel} from '@material-ui/lab';
import React, {useContext} from 'react';
import {useTranslation} from 'react-i18next';
import OrderDetailsContext from '../../screens/Orders/Context/OrderDetailsContext';
import OrderActivityLog from './OrderActivityLog';
import OrderItemsTable from './OrderItemsTable';
import OrderProblemsTable from './OrderProblem/OrderProblemsTable';
import CreditNotesTable from './CreditNotesTable';
import ExchangeOrders from './ExchangeOrders';
import ReturnOrderImages from './ReturnOrderImages';
import ErrorLogsTable from '../Product/ErrorLogTable';

const OrderDetailsTabContainer = () => {
  const {t} = useTranslation();
  const [tabValue, setTabValue] = React.useState<any>('0');
  const handleTabChange = (event: any, newValue: any) => setTabValue(newValue);
  const {order, orderDetails, getOrderDetails} = useContext(OrderDetailsContext);

  return (
    <TabContext value={tabValue}>
      <Box>
        <Tabs
          variant="scrollable"
          scrollButtons="auto"
          value={tabValue}
          onChange={handleTabChange}
          TabIndicatorProps={{style: {display: 'none'}}}
          className="tab-container"
        >
          <Tab
            className={tabValue == 0 ? 'tab-item-active' : 'tab-item'}
            label={t('orderDetails.orderItems')}
            value="0"
          />
          <Tab
            className={tabValue == 1 ? 'tab-item-active' : 'tab-item'}
            label={t('orderDetails.orderProblems')}
            value="1"
          />
          <Tab
            className={tabValue == 2 ? 'tab-item-active' : 'tab-item'}
            label={t('orderDetails.activityLog')}
            value="2"
          />
          <Tab
            className={tabValue == 3 ? 'tab-item-active' : 'tab-item'}
            label={t('orderDetails.creditNotes')}
            value="3"
          />
          {(order.exchangeOrder || (order?.exchangeOrders?.length || 0) > 0) && (
            <Tab
              className={tabValue == 4 ? 'tab-item-active' : 'tab-item'}
              label={t('orderDetails.exchangeOrders')}
              value="4"
            />
          )}
          <Tab
            className={tabValue == 5 ? 'tab-item-active' : 'tab-item'}
            label={t('orderDetails.returnOrderImages')}
            value="5"
          />
          <Tab className={tabValue == 6 ? 'tab-item-active' : 'tab-item'} label={t('general.errorLogs')} value="6" />
        </Tabs>
      </Box>
      <TabPanel value="0" className="tab-panel">
        <OrderItemsTable order={order} getOrderDetails={getOrderDetails} />
      </TabPanel>
      <TabPanel value="1" className="tab-panel">
        <OrderProblemsTable />
      </TabPanel>
      <TabPanel value="2" className="tab-panel">
        <OrderActivityLog orderId={order._id} orderDetails={orderDetails} />
      </TabPanel>
      <TabPanel value="3" className="tab-panel">
        <CreditNotesTable order={order} />
      </TabPanel>
      <TabPanel value="4" className="tab-panel">
        <ExchangeOrders order={order} />
      </TabPanel>
      <TabPanel value="5" className="tab-panel">
        <ReturnOrderImages order={order} />
      </TabPanel>
      <TabPanel value="6" className="tab-panel">
        <ErrorLogsTable
          entityId={order?._id || ''}
          onDeleteCallBack={() => {
            getOrderDetails({id: order._id});
          }}
        />
      </TabPanel>
    </TabContext>
  );
};

export default OrderDetailsTabContainer;
