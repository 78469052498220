import {Button} from '@material-ui/core';
import {Sync} from '@material-ui/icons';
import React, {ReactElement, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {useFetch} from '../../../hooks/fetch';
import {appActions} from '../../../modules/app/actions';
import {modalActions} from '../../../modules/modal/actions';
import {IProduct} from '../../../types/Product/IProduct';
import {MODALS} from '../../Modal/ModalContents';

const SyncToShopwareButton = (props: ISyncToShopwareButtonProps): ReactElement => {
  const [syncResponse, doSyncArticle] = useFetch('product_shopware_sync');
  const dispatch = useDispatch();
  const {t} = useTranslation();
  const {product, getProductDetails} = props;

  const handleCancelModal = () => {
    dispatch(modalActions.closeModal());
  };

  const handleSyncToShopModal = async (selectedShops: string[]) => {
    doSyncArticle({productId: product._id, shopIds: selectedShops});
    handleCancelModal();
  };

  const handleSyncToShop = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    event.preventDefault();

    dispatch(
      modalActions.addModal(MODALS.ALERT_DIALOG, {
        title: t('activatedArticles.confirmTitle') || '',
        description: t('activatedArticles.confirmationMessage'),
        onSubmit: handleSyncToShopModal,
        onClose: handleCancelModal,
        showShopSelection: true,
        onOpen: true,
      }),
    );
  };

  useEffect(() => {
    if (syncResponse.error) {
      dispatch(appActions.showSnackBar({text: t('activatedArticles.failedSyncMessage'), options: {severity: 'error'}}));
    }

    if (syncResponse.loaded && !syncResponse.error) {
      getProductDetails({id: product._id});
      dispatch(
        appActions.showSnackBar({text: t('activatedArticles.successSyncMessage'), options: {severity: 'success'}}),
      );
    }

    return () => {
      syncResponse.data = null;
      syncResponse.loaded = null;
      syncResponse.error = null;
    };
  }, [syncResponse]);

  return (
    <Button
      startIcon={<Sync fontSize="small"></Sync>}
      variant="contained"
      className="blue-button"
      onClick={handleSyncToShop}
    >
      {t('productDetails.syncToShopwareButton')}
    </Button>
  );
};

interface ISyncToShopwareButtonProps {
  product: IProduct;
  getProductDetails: (data: any) => void;
}

export default SyncToShopwareButton;
