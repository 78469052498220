import {Button} from '@material-ui/core';
import React, {ReactElement, useContext, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import _ from 'lodash';
import {modalActions} from '../../modules/modal/actions';
import {MODALS} from '../Modal/ModalContents';
import {useFetch} from '../../hooks/fetch';
import {appActions} from '../../modules/app/actions';

interface IProps {
  getChangeLogs?: any;
}

const AddChangeLogButton = (props: IProps): ReactElement => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const handleCancelModal = () => dispatch(modalActions.closeModal());

  const [createChangeLogRes, createChangeLog] = useFetch<any>('create_change_log');

  const handleSubmitModal = async (values: any) => {
    createChangeLog({...values});
    handleCancelModal();
  };

  const handleClick = async () => {
    dispatch(
      modalActions.addModal(MODALS.ADD_CHANGE_LOG, {
        title: t('changeLog.addChange') || '',
        onSubmit: handleSubmitModal,
        onCancel: handleCancelModal,
      }),
    );
  };

  useEffect(() => {
    if (createChangeLogRes?.error) {
      dispatch(appActions.showSnackBar({text: createChangeLogRes.error.name, options: {severity: 'error'}}));
    } else if (createChangeLogRes?.data) {
      dispatch(appActions.showSnackBar({text: t('general.success'), options: {severity: 'success'}}));
      props.getChangeLogs();
    }

    return () => {
      createChangeLogRes.data = null;
      createChangeLogRes.error = null;
      createChangeLogRes.loaded = null;
    };
  }, [createChangeLogRes]);

  return (
    <Button variant="contained" color="primary" onClick={handleClick}>
      {t('changeLog.addChange')}
    </Button>
  );
};

export default AddChangeLogButton;
