import React, {useState, useEffect, useRef, ChangeEvent} from 'react';
import {useTranslation} from 'react-i18next';
import EditIcon from '@material-ui/icons/Edit';
import {useDispatch} from 'react-redux';
import {Button, Checkbox, FormControlLabel, Grid, IconButton, TextField} from '@material-ui/core';
import {useStorlessFetch, useStorlessUpload} from '../hooks/fetch';
import ChipsInput from '../components/ChipsInput/ChipsInput';
import {appActions} from '../modules/app/actions';
import CONFIG from '../lib/config';
import useUser from '../hooks/user';
import moment from 'moment';
import {ICustomConfig} from '../types/CustomConfig/ICustomConfig';
import {initOrderScreenConfig} from '../types/CustomConfig/IOrderConfig';
import {initProductScreenConfig} from '../types/CustomConfig/IProductConfig';
import {getOrderLabelForField} from './Orders/Orders/OrderHeadrs';
import {initSupplierOrderScreenConfig} from '../types/CustomConfig/ISupplierOrderConfig';
import {getSupplierOrderLabelForField} from './Supplier/SupplierOrderHeaders';
import TodoReceivers from '../components/Config/TodoReceivers';

interface IOtherConfig {
  supplierPaymentMethods: string[];
  daysSinceLastPriceListUpdate: number;
  recommendationBlackList: {firstName: string; lastName: string; email: string}[];
  multiBoxBarcode: string;
  sepaOverdueWarningDelay: number;
  productInfoLanguages: string[];
}

const CustomConfigScreen = () => {
  const user = useUser() as any;
  const featureName = user?.email ? `customConfig-${user?.email}` : undefined;
  const [syncOfferTemplatesRes, syncOfferTemplates] = useStorlessFetch('ebay_sync_offer_templates');
  const [updateConfigRes, updateConfig] = useStorlessFetch('feature_configuration_patch_put');
  const [eBayConfig, getEbayConfig] = useStorlessFetch('feature_configuration');
  const [otherConfigRes, getOtherConfig] = useStorlessFetch('feature_configuration');
  const [getConfigRes, getConfig] = useStorlessFetch('custom_config');
  const [uploadFile, doUploadFile] = useStorlessUpload('upload');
  const [getAuthUrlRes, getAuthUrl] = useStorlessFetch('ebay_auth_url');
  const [customFieldsRes, getCustomFields] = useStorlessFetch('get_custom_field_entities');
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [selectedFile, setSelectedFile] = useState<File>(null as any);
  const [configObject, setConfigObject] = useState<ICustomConfig>({
    printerConfig: {} as any,
    productScreen: initProductScreenConfig(),
    orderScreen: initOrderScreenConfig(),
    supplierOrderScreen: initSupplierOrderScreenConfig(),
  });
  const [editingPrinterConfig, setEditingPrinterConfig] = useState(false);
  const [editingEbayConfig, setEditingEbayConfig] = useState(false);
  const [editingOtherConfig, setEditingSupplierPaymentMethods] = useState(false);
  const [editingBlackList, setEditingBlackList] = useState(false);
  const [editingProductComparision, setEditingProductComparision] = useState(false);
  const [editingProductConfig, setEditingProductConfig] = useState(false);
  const [editingOrderConfig, setEditingOrderConfig] = useState(false);
  const [editingSupplierOrderConfig, setEditingSupplierOrderConfig] = useState(false);
  const [otherConfig, setOtherConfig] = useState<IOtherConfig>(null as any);
  const [productComparisionData, setProductComparisionData] = useState<any>({});
  const [todoReceivers, setTodoReceivers] = useState<any[]>([]);
  const [customFields, setCustomFields] = useState<any[]>([]);
  const dispatch = useDispatch();
  const {t} = useTranslation();

  useEffect(() => {
    getConfig({featureName});
    getEbayConfig({featureName: 'eBayConfig'});
    getOtherConfig({featureName: 'otherConfig'});
    getAuthUrl();
    getCustomFields();
  }, []);

  useEffect(() => {
    if (otherConfigRes?.data?.data?.supplierPaymentMethods) {
      setOtherConfig({
        supplierPaymentMethods: otherConfigRes.data.data.supplierPaymentMethods,
        daysSinceLastPriceListUpdate: otherConfigRes.data.data.daysSinceLastPriceListUpdate,
        recommendationBlackList: otherConfigRes.data.data.recommendationBlackList || [],
        multiBoxBarcode: otherConfigRes.data.data.multiBoxBarcode,
        sepaOverdueWarningDelay: otherConfigRes.data.data.sepaOverdueWarningDelay || 0,
        productInfoLanguages: otherConfigRes.data.data.productInfoLanguages || [],
      });
      setTodoReceivers(otherConfigRes.data.data.todoReceivers || []);

      if (!otherConfigRes.data.data.productComparisionData) {
        setProductComparisionData({
          ignoredDays: 10,
          daysRange: 10,
          percentage: 0.5,
        });
      } else {
        setProductComparisionData(otherConfigRes.data.data.productComparisionData);
      }
    }
  }, [otherConfigRes]);

  // refetch the eBayConfig on component focus
  useEffect(() => {
    const onFocus = () => {
      getEbayConfig({featureName: 'eBayConfig'});
    };

    window.addEventListener('focus', onFocus);

    return () => {
      window.removeEventListener('focus', onFocus);
    };
  }, []);

  useEffect(() => {
    if (getConfigRes?.data?.data) {
      setConfigObject((prevState) => ({...prevState, ...getConfigRes.data.data}));
    }
  }, [getConfigRes]);

  useEffect(() => {
    if (customFieldsRes.data) {
      setCustomFields(customFieldsRes.data);
    }
  }, [customFieldsRes]);

  const getCustomFieldsForEntity = (entityType: string) => {
    const customEntity = customFields.find((field) => field.type === entityType);
    return customEntity?.fields || [];
  };

  useEffect(() => {
    if (syncOfferTemplatesRes.data) {
      dispatch(appActions.showSnackBar({text: t('general.success'), options: {severity: 'success'}}));
      getEbayConfig({featureName: 'eBayConfig'});
    }

    return () => {
      syncOfferTemplatesRes.data = null;
      syncOfferTemplatesRes.error = null;
    };
  }, [syncOfferTemplatesRes]);

  useEffect(() => {
    if (updateConfigRes.data) {
      dispatch(appActions.showSnackBar({text: t('general.success'), options: {severity: 'success'}}));
      setEditingPrinterConfig(false);
    } else if (updateConfigRes.error) {
      dispatch(appActions.showSnackBar({text: updateConfigRes.error?.name, options: {severity: 'error'}}));
    }

    return () => {
      updateConfigRes.data = null;
      updateConfigRes.error = null;
    };
  }, [updateConfigRes]);

  useEffect(() => {
    if (uploadFile.data) {
      updateConfig({id: eBayConfig?.data?._id, 'data.imageUrl': `${CONFIG.api}static/images/dda-ebay.png`});
      dispatch(appActions.showSnackBar({text: t('general.success'), options: {severity: 'success'}}));
    } else if (uploadFile.error) {
      dispatch(appActions.showSnackBar({text: t('general.error'), options: {severity: 'error'}}));
    }
    return () => {
      uploadFile.data = null;
      uploadFile.error = null;
    };
  }, [uploadFile]);

  const ConfigEditButton = ({onClick, editing}: any) => (
    <IconButton onClick={onClick}>
      <EditIcon htmlColor={editing ? '#5cabef' : 'none'} style={{fontSize: 20}} />
    </IconButton>
  );

  const updateObjectState = (value: any, fieldName: string, nestedObjectName?: string) => {
    setConfigObject((prevState: any) => {
      const updateData = nestedObjectName
        ? {
            [nestedObjectName]: {
              ...prevState[nestedObjectName],
              [fieldName]: value,
            },
          }
        : {[fieldName]: value};

      return {...prevState, ...updateData};
    });
  };

  const onConfigObjectSave = () => {
    updateConfig({id: getConfigRes?.data?._id, data: configObject});
  };

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target?.files?.[0];
    setSelectedFile(file as any);
  };

  const handleUpload = () => {
    const fileForUpload = new File([selectedFile], 'dda-ebay.png', {type: selectedFile.type});
    doUploadFile({file: fileForUpload});
    setSelectedFile(null as any);
  };

  const onOfferTemplatesSync = () => {
    syncOfferTemplates();
  };

  const onGetAuthUrl = () => {
    const link: HTMLAnchorElement = document.createElement('a');
    link.href = getAuthUrlRes?.data;
    link.style.display = 'none';
    link.target = '_blank';

    document.body.appendChild(link);
    link.click();
    link?.parentNode?.removeChild(link);
  };

  const addOrUpdateOtherConfig = () => {
    updateConfig({
      id: otherConfigRes?.data?._id,
      'data.supplierPaymentMethods': otherConfig.supplierPaymentMethods,
      'data.daysSinceLastPriceListUpdate': otherConfig.daysSinceLastPriceListUpdate,
      'data.recommendationBlackList': otherConfig.recommendationBlackList,
      'data.multiBoxBarcode': otherConfig.multiBoxBarcode,
      'data.sepaOverdueWarningDelay': otherConfig.sepaOverdueWarningDelay,
      'data.productInfoLanguages': otherConfig.productInfoLanguages,
    });
  };

  const updateProductComparisionDetails = () => {
    updateConfig({id: otherConfigRes?.data?._id, 'data.productComparisionData': productComparisionData || {}});
  };

  const onOtherConfigChange = (data: any, fieldName: string) => {
    setOtherConfig((prevState) => ({
      ...prevState,
      [fieldName]: data,
    }));
  };

  const handleProductColumnConfigChange = (fieldName: string) => {
    setConfigObject((prevState: any) => ({
      ...prevState,
      productScreen: {
        ...prevState.productScreen,
        displayedFields: {
          ...prevState.productScreen.displayedFields,
          [fieldName]: !prevState.productScreen.displayedFields[fieldName],
        },
      },
    }));
  };

  const handleOrderColumnConfigChange = (fieldName: string) => {
    setConfigObject((prevState: any) => ({
      ...prevState,
      orderScreen: {
        ...prevState.orderScreen,
        displayedFields: {
          ...prevState.orderScreen.displayedFields,
          [fieldName]: !prevState.orderScreen.displayedFields[fieldName],
        },
      },
    }));
  };

  const handleSupplierOrderColumnConfigChange = (fieldName: string) => {
    setConfigObject((prevState: any) => ({
      ...prevState,
      supplierOrderScreen: {
        ...prevState.supplierOrderScreen,
        displayedFields: {
          ...prevState.supplierOrderScreen.displayedFields,
          [fieldName]: !prevState.supplierOrderScreen.displayedFields[fieldName],
        },
      },
    }));
  };

  const handleRecommendationBlackListChange = (index: number, field: string, value: string) => {
    setOtherConfig((prevState) => {
      const updatedList = [...prevState.recommendationBlackList];
      updatedList[index] = {...updatedList[index], [field]: value};
      return {...prevState, recommendationBlackList: updatedList};
    });
  };

  const addRecommendationBlackListEntry = () => {
    setOtherConfig((prevState) => ({
      ...prevState,
      recommendationBlackList: [...prevState.recommendationBlackList, {firstName: '', lastName: '', email: ''}],
    }));
  };

  const removeRecommendationBlackListEntry = (index: number) => {
    setOtherConfig((prevState) => {
      const updatedList = [...prevState.recommendationBlackList];
      updatedList.splice(index, 1);
      return {...prevState, recommendationBlackList: updatedList};
    });
  };

  const getFields = (type: string, userFields: any[]) => {
    if (type === 'PRODUCT') {
      const displayedFields = Object.entries(initProductScreenConfig().displayedFields);

      for (const field of displayedFields) {
        if (!userFields.find((userField) => userField[0] === field[0])) {
          userFields.push(field);
        }
      }

      return userFields;
    }
    return userFields;
  };

  return (
    <div className="customer-details" style={{width: 'unset'}}>
      <div className="center-content">
        <div className="customer-details__header">
          <p>{t('general.customConfig')}</p>
        </div>
      </div>
      <div className="config-links">
        <a href="/static-files">{t('general.staticFiles')}</a>
      </div>
      {configObject && (
        <div className="order-details">
          <div className="order-info">
            <Grid container spacing={0} alignItems="center">
              <Grid item xs={6}>
                <div className="order-info-title">Printer Config</div>
              </Grid>
              <Grid
                item
                xs={6}
                alignContent="flex-end"
                alignItems="flex-end"
                style={{display: 'inline-block', textAlign: 'right', paddingRight: '7px'}}
              >
                <ConfigEditButton
                  onClick={() => setEditingPrinterConfig((prevState) => !prevState)}
                  editing={editingPrinterConfig}
                />
                {editingPrinterConfig && (
                  <Button className="blue-button" variant="contained" onClick={onConfigObjectSave}>
                    {t('general.save')}
                  </Button>
                )}
              </Grid>
              <Grid item xs={12} sm={6} className="order-info-item" style={{paddingRight: '10px'}}>
                <span className="order-info-label">{t('customConfig.shippingLabelPrinterName')}</span>
                {editingPrinterConfig ? (
                  <TextField
                    className="product-info-input"
                    variant="standard"
                    size="small"
                    value={configObject?.printerConfig?.shippingLabelPrinterName}
                    onChange={(event) => {
                      updateObjectState(event.target.value, 'shippingLabelPrinterName', 'printerConfig');
                    }}
                  />
                ) : (
                  <div className="order-info-label">{configObject?.printerConfig?.shippingLabelPrinterName}</div>
                )}
              </Grid>
              <Grid item xs={12} sm={6} className="order-info-item" style={{paddingRight: '10px'}}>
                <span className="order-info-label">{t('customConfig.returnLabelPrinterName')}</span>
                {editingPrinterConfig ? (
                  <TextField
                    className="product-info-input"
                    variant="standard"
                    size="small"
                    value={configObject?.printerConfig?.returnLabelPrinterName}
                    onChange={(event) => {
                      updateObjectState(event.target.value, 'returnLabelPrinterName', 'printerConfig');
                    }}
                  />
                ) : (
                  <div className="order-info-label">{configObject?.printerConfig?.returnLabelPrinterName}</div>
                )}
              </Grid>
              <Grid item xs={12} sm={6} className="order-info-item" style={{paddingRight: '10px'}}>
                <span className="order-info-label">{t('customConfig.deliveryBillPrinterName')}</span>
                {editingPrinterConfig ? (
                  <TextField
                    className="product-info-input"
                    variant="standard"
                    size="small"
                    value={configObject?.printerConfig?.deliveryBillPrinterName}
                    onChange={(event) => {
                      updateObjectState(event.target.value, 'deliveryBillPrinterName', 'printerConfig');
                    }}
                  />
                ) : (
                  <div className="order-info-label">{configObject?.printerConfig?.customsPrinterName}</div>
                )}
              </Grid>
              <Grid item xs={12} sm={6} className="order-info-item" style={{paddingRight: '10px'}}>
                <span className="order-info-label">{t('customConfig.customsPrinterName')}</span>
                {editingPrinterConfig ? (
                  <TextField
                    className="product-info-input"
                    variant="standard"
                    size="small"
                    value={configObject?.printerConfig?.customsPrinterName}
                    onChange={(event) => {
                      updateObjectState(event.target.value, 'customsPrinterName', 'printerConfig');
                    }}
                  />
                ) : (
                  <div className="order-info-label">{configObject?.printerConfig?.customsPrinterName}</div>
                )}
              </Grid>

              <Grid item xs={12} sm={6} className="order-info-item" style={{paddingRight: '10px'}}>
                <span className="order-info-label">{t('customConfig.serviceUrl')}</span>
                {editingPrinterConfig ? (
                  <TextField
                    className="product-info-input"
                    variant="standard"
                    size="small"
                    value={configObject?.printerConfig?.serviceUrl}
                    onChange={(event) => {
                      updateObjectState(event.target.value, 'serviceUrl', 'printerConfig');
                    }}
                  />
                ) : (
                  <a href={configObject?.printerConfig?.serviceUrl} className="order-info-label" target="blank">
                    {configObject?.printerConfig?.serviceUrl}
                  </a>
                )}
              </Grid>
              <Grid item xs={12} sm={6} className="order-info-item" style={{paddingRight: '10px'}}>
                <span className="order-info-label">{t('customConfig.openInNewTab')}</span>
                <Checkbox
                  disabled={!editingPrinterConfig}
                  color="primary"
                  checked={configObject?.printerConfig?.openInNewTab}
                  onChange={(event) => {
                    updateObjectState(event.target.checked, 'openInNewTab', 'printerConfig');
                  }}
                ></Checkbox>
              </Grid>
              <Grid item xs={12} sm={6} className="order-info-item" style={{paddingRight: '10px'}}>
                <span className="order-info-label">{t('customConfig.jumpToNextOrder')}</span>
                <Checkbox
                  disabled={!editingPrinterConfig}
                  color="primary"
                  checked={configObject?.printerConfig?.jumpToNextOrder}
                  onChange={(event) => {
                    updateObjectState(event.target.checked, 'jumpToNextOrder', 'printerConfig');
                  }}
                ></Checkbox>
              </Grid>
              <Grid item xs={12} sm={6} className="order-info-item" style={{paddingRight: '10px'}}>
                <span className="order-info-label">{t('customConfig.serviceDownloadUrl')}</span>
                <a href={`${CONFIG.api}static/PrinterService.zip`} className="order-info-label" target="blank">
                  Printer Service
                </a>
              </Grid>
              <Grid item xs={12} sm={6} className="order-info-item" style={{paddingRight: '10px'}}></Grid>
            </Grid>
          </div>
        </div>
      )}
      {eBayConfig?.data && (
        <div className="order-details">
          <div className="order-info">
            <Grid container spacing={0} alignItems="center">
              <Grid item xs={6}>
                <div className="order-info-title">eBay Config</div>
              </Grid>
              <Grid
                item
                xs={6}
                alignContent="flex-end"
                alignItems="flex-end"
                style={{display: 'inline-block', textAlign: 'right', paddingRight: '7px'}}
              >
                <ConfigEditButton
                  onClick={() => setEditingEbayConfig((prevState) => !prevState)}
                  editing={editingEbayConfig}
                />
              </Grid>
              <Grid item xs={12} sm={6} className="order-info-item" style={{paddingRight: '10px'}}>
                <span className="order-info-label">{t('customConfig.ddaOfferImage')}</span>
                {editingEbayConfig ? (
                  <>
                    <TextField onChange={handleFileChange} inputRef={fileInputRef} variant="standard" type="file" />
                    {selectedFile && (
                      <Button
                        onClick={handleUpload}
                        variant="contained"
                        className={selectedFile ? 'inactive-button' : 'blue-button'}
                        style={{alignSelf: 'center'}}
                      >
                        Upload
                      </Button>
                    )}
                  </>
                ) : (
                  <>
                    <a href={`${CONFIG.api}static/images/dda-ebay.png`} className="order-info-label" target="blank">
                      {`${getEbayConfig?.data?.imageUrl || `${CONFIG.api}static/images/dda-ebay.png`}`}
                    </a>
                  </>
                )}
              </Grid>

              <Grid item xs={12} sm={6} className="order-info-item" style={{paddingRight: '10px'}}>
                <span className="order-info-label">{t('customConfig.syncOfferTemplates')}</span>
                {eBayConfig?.data?.data?.syncingOfferTemplates || syncOfferTemplatesRes?.loading ? (
                  t('customConfig.syncInProgress')
                ) : (
                  <Button
                    onClick={onOfferTemplatesSync}
                    variant="contained"
                    className={selectedFile ? 'inactive-button' : 'blue-button'}
                    style={{alignSelf: 'center'}}
                  >
                    Sync
                  </Button>
                )}
              </Grid>

              <Grid item xs={12} sm={6} className="order-info-item" style={{paddingRight: '10px'}}>
                <span className="order-info-label">{t('customConfig.eBayTokenEndDate')}</span>
                {eBayConfig?.data?.data?.token?.expires_at
                  ? moment(eBayConfig?.data?.data?.token?.expires_at).format('DD.MM.YYYY HH:MM:ss')
                  : '-'}
              </Grid>

              <Grid item xs={12} sm={6} className="order-info-item" style={{paddingRight: '10px'}}>
                <span className="order-info-label">{t('customConfig.eBayRefreshTokenEndDate')}</span>
                {eBayConfig?.data?.data?.token?.expires_at
                  ? moment(eBayConfig?.data?.data?.token?.refresh_token_expires_at).format('DD.MM.YYYY HH:MM:ss')
                  : '-'}
              </Grid>

              <Grid item xs={12} sm={6} className="order-info-item" style={{paddingRight: '10px'}}>
                <span className="order-info-label">{t('customConfig.eBayGetNewToken')}</span>
                {getAuthUrlRes?.data && (
                  <Button type="button" className="blue-button" style={{alignSelf: 'center'}} onClick={onGetAuthUrl}>
                    {t('customConfig.eBayGetNewToken')}
                  </Button>
                )}
              </Grid>

              <Grid item xs={12} sm={6} className="order-info-item" style={{paddingRight: '10px'}}></Grid>
            </Grid>
          </div>
        </div>
      )}
      <div className="order-details">
        <div className="order-info">
          <Grid container spacing={0}>
            <Grid item xs={6}>
              <div className="order-info-title">{t('customConfig.todoReceivers')}</div>
            </Grid>
            <Grid item xs={6}></Grid>

            <TodoReceivers todoReceivers={todoReceivers} />
          </Grid>
        </div>
      </div>
      <div className="order-details">
        <div className="order-info">
          <Grid container spacing={0} alignItems="center">
            <Grid item xs={6}>
              <div className="order-info-title">{t('customConfig.otherConfiguration')}</div>
            </Grid>
            <Grid
              item
              xs={6}
              alignContent="flex-end"
              alignItems="flex-end"
              style={{display: 'inline-block', textAlign: 'right', paddingRight: '7px'}}
            >
              <ConfigEditButton
                onClick={() => setEditingSupplierPaymentMethods((prevState) => !prevState)}
                editing={editingOtherConfig}
              />
              {editingOtherConfig && (
                <Button variant="contained" className="blue-button" onClick={addOrUpdateOtherConfig}>
                  {t('general.save')}
                </Button>
              )}
            </Grid>
            <Grid item xs={6} sm={6} className="order-info-item">
              <span className="order-info-label">{t('customConfig.multiBoxBarcode')}</span>
              <TextField
                className="product-info-input"
                variant="standard"
                size="small"
                value={otherConfig?.multiBoxBarcode}
                onChange={(event) => {
                  onOtherConfigChange(event.target.value, 'multiBoxBarcode');
                }}
                disabled={!editingOtherConfig}
              />
            </Grid>
            <Grid item xs={6} sm={6} className="order-info-item">
              <span className="order-info-label">{t('customConfig.daysSinceLastPriceListUpdate')}</span>
              <TextField
                className="product-info-input"
                variant="standard"
                type="number"
                size="small"
                value={otherConfig?.daysSinceLastPriceListUpdate}
                onChange={(event) => {
                  onOtherConfigChange(+event.target.value, 'daysSinceLastPriceListUpdate');
                }}
                disabled={!editingOtherConfig}
              />
            </Grid>
            <Grid item xs={6} sm={6} className="order-info-item">
              <span className="order-info-label">{t('customConfig.sepaDelayForOverdueWarning')}</span>
              <TextField
                className="product-info-input"
                variant="standard"
                type="number"
                size="small"
                value={otherConfig?.sepaOverdueWarningDelay}
                onChange={(event) => {
                  onOtherConfigChange(+event.target.value, 'sepaOverdueWarningDelay');
                }}
                disabled={!editingOtherConfig}
              />
            </Grid>
            <Grid item xs={6} sm={6} className="order-info-item">
              <span className="order-info-label">{t('customConfig.supplierPaymentMethod')}</span>
              <ChipsInput
                placeholder={t('customConfig.supplierPaymentMethodPlaceholder')}
                value={otherConfig?.supplierPaymentMethods || []}
                onChange={(data) => onOtherConfigChange(data, 'supplierPaymentMethods')}
                disabled={!editingOtherConfig}
              />
            </Grid>
            <Grid item xs={6} sm={6} className="order-info-item"></Grid>
            <Grid item xs={6} sm={6} className="order-info-item"></Grid>
            <Grid item xs={6} sm={6} className="order-info-item">
              <span className="order-info-label">{t('customConfig.productInfoLanguages')}</span>
              <ChipsInput
                placeholder={t('customConfig.productInfoLanguages')}
                value={otherConfig?.productInfoLanguages || []}
                onChange={(data) => onOtherConfigChange(data, 'productInfoLanguages')}
                disabled={!editingOtherConfig}
              />
            </Grid>
          </Grid>
        </div>
      </div>

      <div className="order-details">
        <div className="order-info">
          <Grid container spacing={0} alignItems="center">
            <Grid item xs={6}>
              <div className="order-info-title">{t('customConfig.comparisionProducts')}</div>
            </Grid>
            <Grid
              item
              xs={6}
              alignContent="flex-end"
              alignItems="flex-end"
              style={{display: 'inline-block', textAlign: 'right', paddingRight: '7px', padding: '10px'}}
            >
              <ConfigEditButton
                onClick={() => setEditingProductComparision((prevState) => !prevState)}
                editing={editingOtherConfig}
              />
              {editingProductComparision && (
                <Button variant="contained" className="blue-button" onClick={updateProductComparisionDetails}>
                  {t('general.save')}
                </Button>
              )}
            </Grid>
            <Grid item xs={12} sm={6} className="order-info-item">
              <span className="order-info-label">{t('customConfig.ignoredDays')}</span>
              <TextField
                className="product-info-input"
                variant="standard"
                size="small"
                type="number"
                value={productComparisionData?.ignoredDays}
                onChange={(event) => {
                  setProductComparisionData((prevState: any) => ({...prevState, ignoredDays: +event.target.value}));
                }}
                disabled={!editingProductComparision}
              />
            </Grid>
            <Grid item xs={12} sm={6} className="order-info-item">
              <span className="order-info-label">{t('customConfig.daysRange')}</span>
              <TextField
                className="product-info-input"
                variant="standard"
                size="small"
                type="number"
                value={productComparisionData?.daysRange}
                onChange={(event) => {
                  setProductComparisionData((prevState: any) => ({...prevState, daysRange: +event.target.value}));
                }}
                disabled={!editingProductComparision}
              />
            </Grid>
            <Grid item xs={12} sm={6} className="order-info-item">
              <span className="order-info-label">{t('customConfig.percentage')}</span>
              <TextField
                className="product-info-input"
                variant="standard"
                size="small"
                type="number"
                value={productComparisionData?.percentage}
                inputProps={{step: 0.01, min: 0, max: 1}}
                onChange={(event) => {
                  setProductComparisionData((prevState: any) => ({...prevState, percentage: +event.target.value}));
                }}
                disabled={!editingProductComparision}
              />
            </Grid>
            <Grid item xs={12} sm={6} className="order-info-item"></Grid>
          </Grid>
        </div>
      </div>

      <div className="order-details">
        <div className="order-info">
          <Grid container spacing={0} alignItems="center">
            <Grid item xs={6}>
              <div className="order-info-title">{t('customConfig.recommendationBlackList')}</div>
            </Grid>
            <Grid
              item
              xs={6}
              alignContent="flex-end"
              alignItems="flex-end"
              style={{display: 'inline-block', textAlign: 'right', paddingRight: '7px'}}
            >
              <ConfigEditButton
                onClick={() => setEditingBlackList((prevState) => !prevState)}
                editing={editingBlackList}
              />
              {editingBlackList && (
                <Button variant="contained" className="blue-button" onClick={addOrUpdateOtherConfig}>
                  {t('general.save')}
                </Button>
              )}
            </Grid>
            <Grid item xs={12}>
              {otherConfig?.recommendationBlackList?.length > 0 ? (
                otherConfig.recommendationBlackList.map((entry, index) => (
                  <Grid container spacing={2} key={index} alignItems="center" style={{padding: '0 10px '}}>
                    <Grid item xs={4}>
                      <TextField
                        className="product-info-input"
                        variant="standard"
                        size="small"
                        label={editingBlackList ? t('customConfig.firstName') : ''}
                        value={entry.firstName}
                        onChange={(event) =>
                          handleRecommendationBlackListChange(index, 'firstName', event.target.value)
                        }
                        disabled={!editingBlackList}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <TextField
                        className="product-info-input"
                        variant="standard"
                        size="small"
                        label={editingBlackList ? t('customConfig.lastName') : ''}
                        value={entry.lastName}
                        onChange={(event) => handleRecommendationBlackListChange(index, 'lastName', event.target.value)}
                        disabled={!editingBlackList}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <TextField
                        className="product-info-input"
                        variant="standard"
                        size="small"
                        label={editingBlackList ? t('customConfig.email') : ''}
                        value={entry.email}
                        onChange={(event) => handleRecommendationBlackListChange(index, 'email', event.target.value)}
                        disabled={!editingBlackList}
                      />
                    </Grid>
                    {editingOtherConfig && (
                      <Grid item xs={12} style={{textAlign: 'right'}}>
                        <Button
                          variant="contained"
                          color="secondary"
                          onClick={() => removeRecommendationBlackListEntry(index)}
                        >
                          {t('general.remove')}
                        </Button>
                      </Grid>
                    )}
                  </Grid>
                ))
              ) : (
                <div style={{padding: '10px'}}>{t('customConfig.noBlacklistEntries')}</div>
              )}
              {editingBlackList && (
                <Grid item xs={12} style={{textAlign: 'left', marginTop: '5px', paddingLeft: '10px'}}>
                  <Button variant="contained" color="primary" onClick={addRecommendationBlackListEntry}>
                    {t('general.add')}
                  </Button>
                </Grid>
              )}
            </Grid>
          </Grid>
        </div>
      </div>

      <div className="order-details">
        <div className="order-info">
          <Grid container spacing={0} alignItems="center">
            <Grid item xs={6}>
              <div className="order-info-title">{t('customConfig.comparisionProducts')}</div>
            </Grid>
            <Grid
              item
              xs={6}
              alignContent="flex-end"
              alignItems="flex-end"
              style={{display: 'inline-block', textAlign: 'right', paddingRight: '7px'}}
            >
              <ConfigEditButton
                onClick={() => setEditingProductComparision((prevState) => !prevState)}
                editing={editingOtherConfig}
              />
              {editingProductComparision && (
                <Button variant="contained" className="blue-button" onClick={updateProductComparisionDetails}>
                  {t('general.save')}
                </Button>
              )}
            </Grid>
            <Grid item xs={12} sm={6} className="order-info-item">
              <span className="order-info-label">{t('customConfig.ignoredDays')}</span>
              <TextField
                className="product-info-input"
                variant="standard"
                size="small"
                type="number"
                value={productComparisionData?.ignoredDays}
                onChange={(event) => {
                  setProductComparisionData((prevState: any) => ({...prevState, ignoredDays: +event.target.value}));
                }}
                disabled={!editingProductComparision}
              />
            </Grid>
            <Grid item xs={12} sm={6} className="order-info-item">
              <span className="order-info-label">{t('customConfig.daysRange')}</span>
              <TextField
                className="product-info-input"
                variant="standard"
                size="small"
                type="number"
                value={productComparisionData?.daysRange}
                onChange={(event) => {
                  setProductComparisionData((prevState: any) => ({...prevState, daysRange: +event.target.value}));
                }}
                disabled={!editingProductComparision}
              />
            </Grid>
            <Grid item xs={12} sm={6} className="order-info-item">
              <span className="order-info-label">{t('customConfig.percentage')}</span>
              <TextField
                className="product-info-input"
                variant="standard"
                size="small"
                type="number"
                value={productComparisionData?.percentage}
                inputProps={{step: 0.01, min: 0, max: 1}}
                onChange={(event) => {
                  setProductComparisionData((prevState: any) => ({...prevState, percentage: +event.target.value}));
                }}
                disabled={!editingProductComparision}
              />
            </Grid>
            <Grid item xs={12} sm={6} className="order-info-item"></Grid>
          </Grid>
        </div>
      </div>
      <div className="order-details">
        <div className="order-info" style={{maxHeight: '600px', overflowY: 'scroll'}}>
          <Grid container spacing={0} alignItems="flex-start" style={{padding: '10px'}}>
            <Grid item xs={12} sm={12}>
              <div className="order-info-title">
                Products Column Configuration
                <Grid
                  item
                  xs={6}
                  alignContent="flex-end"
                  alignItems="flex-end"
                  style={{display: 'inline-block', textAlign: 'right', paddingRight: '7px'}}
                >
                  <ConfigEditButton
                    onClick={() => setEditingProductConfig((prevState) => !prevState)}
                    editing={editingProductConfig}
                  />
                  {editingProductConfig && (
                    <Button className="blue-button" variant="contained" onClick={onConfigObjectSave}>
                      {t('general.save')}
                    </Button>
                  )}
                </Grid>
              </div>
              <Grid container spacing={2}>
                {configObject &&
                  getFields('PRODUCT', Object.entries(configObject?.productScreen?.displayedFields ?? {}))
                    .sort((a, b) => (a > b ? 1 : -1))
                    .map(([fieldName]) => (
                      <Grid item xs={3} sm={3} key={fieldName}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={(configObject?.productScreen?.displayedFields as any)[fieldName]}
                              onChange={() => handleProductColumnConfigChange(fieldName)}
                              color="primary"
                              disabled={!editingProductConfig}
                            />
                          }
                          label={t(`productDetails.${fieldName.replaceAll('productInformation.', '')}`)}
                        />
                      </Grid>
                    ))}

                {/* Add checkboxes for custom fields */}
                {getCustomFieldsForEntity('PRODUCT').map((customField: any) => (
                  <Grid item xs={3} sm={3} key={customField.fieldName}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={configObject?.productScreen?.displayedCustomFields?.[customField.fieldName] ?? false}
                          onChange={() => {
                            setConfigObject((prevState: any) => ({
                              ...prevState,
                              productScreen: {
                                ...prevState.productScreen,
                                displayedCustomFields: {
                                  ...prevState.productScreen.displayedCustomFields,
                                  [customField.fieldName]:
                                    !prevState.productScreen?.displayedCustomFields?.[customField.fieldName],
                                },
                              },
                            }));
                          }}
                          color="primary"
                          disabled={!editingProductConfig}
                        />
                      }
                      label={customField.fieldName}
                    />
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
        </div>
      </div>
      <div className="order-details">
        <div className="order-info" style={{maxHeight: '600px', overflowY: 'scroll'}}>
          <Grid container spacing={0} alignItems="flex-start" style={{padding: '10px'}}>
            <Grid item xs={12} sm={12}>
              <div className="order-info-title">
                Orders Column Configuration
                <Grid
                  item
                  xs={6}
                  alignContent="flex-end"
                  alignItems="flex-end"
                  style={{display: 'inline-block', textAlign: 'right', paddingRight: '7px'}}
                >
                  <ConfigEditButton
                    onClick={() => setEditingOrderConfig((prevState) => !prevState)}
                    editing={editingOrderConfig}
                  />
                  {editingOrderConfig && (
                    <Button className="blue-button" variant="contained" onClick={onConfigObjectSave}>
                      {t('general.save')}
                    </Button>
                  )}
                </Grid>
              </div>
              <Grid container spacing={2}>
                {configObject &&
                  Object.entries(configObject?.orderScreen?.displayedFields || {}).map(([fieldName]) => (
                    <Grid item xs={3} sm={3} key={fieldName}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={(configObject?.orderScreen?.displayedFields as any)[fieldName]}
                            onChange={() => handleOrderColumnConfigChange(fieldName)}
                            color="primary"
                            disabled={!editingOrderConfig}
                          />
                        }
                        label={getOrderLabelForField(fieldName, t)}
                      />
                    </Grid>
                  ))}

                {/* Add checkboxes for custom fields */}
                {getCustomFieldsForEntity('CUSTOMER_ORDER').map((customField: any) => (
                  <Grid item xs={3} sm={3} key={customField.fieldName}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={configObject?.orderScreen?.displayedCustomFields?.[customField.fieldName] ?? false}
                          onChange={() => {
                            setConfigObject((prevState: any) => ({
                              ...prevState,
                              orderScreen: {
                                ...prevState.orderScreen,
                                displayedCustomFields: {
                                  ...prevState.orderScreen.displayedCustomFields,
                                  [customField.fieldName]:
                                    !prevState.orderScreen?.displayedCustomFields?.[customField.fieldName],
                                },
                              },
                            }));
                          }}
                          color="primary"
                          disabled={!editingOrderConfig}
                        />
                      }
                      label={customField.fieldName}
                    />
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
        </div>
      </div>
      <div className="order-details">
        <div className="order-info" style={{maxHeight: '600px', overflowY: 'scroll'}}>
          <Grid container spacing={0} alignItems="flex-start" style={{padding: '10px'}}>
            <Grid item xs={12} sm={12}>
              <div className="order-info-title">
                Supplier Orders Column Configuration
                <Grid
                  item
                  xs={6}
                  alignContent="flex-end"
                  alignItems="flex-end"
                  style={{display: 'inline-block', textAlign: 'right', paddingRight: '7px'}}
                >
                  <ConfigEditButton
                    onClick={() => setEditingSupplierOrderConfig((prevState) => !prevState)}
                    editing={editingSupplierOrderConfig}
                  />
                  {editingSupplierOrderConfig && (
                    <Button className="blue-button" variant="contained" onClick={onConfigObjectSave}>
                      {t('general.save')}
                    </Button>
                  )}
                </Grid>
              </div>
              <Grid container spacing={2}>
                {configObject &&
                  Object.entries(configObject?.supplierOrderScreen?.displayedFields || {}).map(([fieldName]) => (
                    <Grid item xs={3} sm={3} key={fieldName}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={(configObject?.supplierOrderScreen?.displayedFields as any)[fieldName]}
                            onChange={() => handleSupplierOrderColumnConfigChange(fieldName)}
                            color="primary"
                            disabled={!editingSupplierOrderConfig}
                          />
                        }
                        label={getSupplierOrderLabelForField(fieldName, t)}
                      />
                    </Grid>
                  ))}

                {/* Add checkboxes for custom fields */}
                {getCustomFieldsForEntity('SUPPLIER_ORDER').map((customField: any) => (
                  <Grid item xs={3} sm={3} key={customField.fieldName}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={
                            configObject?.supplierOrderScreen?.displayedCustomFields?.[customField.fieldName] ?? false
                          }
                          onChange={() => {
                            setConfigObject((prevState: any) => ({
                              ...prevState,
                              supplierOrderScreen: {
                                ...prevState.supplierOrderScreen,
                                displayedCustomFields: {
                                  ...prevState.supplierOrderScreen.displayedCustomFields,
                                  [customField.fieldName]:
                                    !prevState.supplierOrderScreen?.displayedCustomFields?.[customField.fieldName],
                                },
                              },
                            }));
                          }}
                          color="primary"
                          disabled={!editingSupplierOrderConfig}
                        />
                      }
                      label={customField.fieldName}
                    />
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
};
export default CustomConfigScreen;
