import {Button} from '@material-ui/core';
import {Sync} from '@material-ui/icons';
import React, {ReactElement, useContext, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {useFetch} from '../../../hooks/fetch';
import {appActions} from '../../../modules/app/actions';
import {modalActions} from '../../../modules/modal/actions';
import {MODALS} from '../../Modal/ModalContents';
import {ShopType} from '../../../types/Orders/ShopType';
import {ProductStatus} from '../../../types/Product/ProductStatus.enum';
import {IShopProductProp} from '../../../types/Product/IShopProductProp';
import ProductDetailsContext from '../../../screens/Products/Context/ProductDetailsContext';

const SyncToEbayButton = (): ReactElement => {
  const [syncProductResponse, syncProduct] = useFetch<any>('update_product_to_ebay');
  const {product, getProductDetails} = useContext(ProductDetailsContext);
  const dispatch = useDispatch();
  const {t} = useTranslation();
  const [shopProp, setShopProps] = useState<IShopProductProp | undefined>(undefined);

  const handleCancelModal = () => dispatch(modalActions.closeModal());
  const handleSubmit = async () => {
    syncProduct({id: product._id});
    handleCancelModal();
  };

  const handleSyncToShop = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    event.preventDefault();

    dispatch(
      modalActions.addModal(MODALS.ALERT_DIALOG, {
        title: t('general.areYouSure') || '',
        description: t('productDetails.eBaySyncOfferMessage'),
        onSubmit: handleSubmit,
        onClose: handleCancelModal,
        onOpen: true,
      }),
    );
  };

  useEffect(() => {
    if (product?.shopProps?.length) {
      setShopProps(product.shopProps.find((x: any) => x.shopName === ShopType.DRUCKDICHAUS));
    }

    return () => {
      setShopProps(undefined);
    };
  }, []);

  useEffect(() => {
    if (syncProductResponse.error) {
      getProductDetails({id: product._id});
      dispatch(appActions.showSnackBar({text: t('activatedArticles.failedSyncMessage'), options: {severity: 'error'}}));
    }

    if (syncProductResponse.loaded && !syncProductResponse.error) {
      dispatch(appActions.showSnackBar({text: t('general.success'), options: {severity: 'success'}}));
    }

    return () => {
      syncProductResponse.data = null;
      syncProductResponse.loaded = null;
      syncProductResponse.error = null;
    };
  }, [syncProductResponse]);

  return shopProp?.status === ProductStatus.ACTIVE && product.eBayProps?.listingId ? (
    <Button
      startIcon={<Sync fontSize="small"></Sync>}
      variant="contained"
      className="blue-button"
      onClick={handleSyncToShop}
    >
      {t('productDetails.syncToEbayButton')}
    </Button>
  ) : (
    <></>
  );
};

export default SyncToEbayButton;
