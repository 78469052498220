import React, {ReactElement, useContext} from 'react';
import {Box, Collapse, Table, TableBody, TableCell, TableHead, TableRow, Typography} from '@material-ui/core';
import {Link} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {APP_ROUTES} from '../../routers/routes';
import SupplierOrderItemsTableBodyContext from './Context/SupplierOrterItemsTableBodyContext';
import DataTable from '../Table/Table';
import {DataTableItemType} from '../Table/interfaces/IHeaderAccessor';

const SupplierOrderItemsSubTable = (props: ISupplierOrderItemsSubTableProps): ReactElement => {
  const {supplierOrderItem, orderItemsData, setOrderItemsData} = useContext(SupplierOrderItemsTableBodyContext);
  const {open, setPrice} = props;
  const {t} = useTranslation();

  const handleChange = (supplierOrderItemId: string, supplierArticleId: string) => {
    const newState = [...orderItemsData];
    const supplierOrderItem = newState.find((supplierOrderItem) => supplierOrderItem._id === supplierOrderItemId);
    const supplierArticle = supplierOrderItem?.product.supplierArticle?.find(
      (supplierArticle: any) => supplierArticle._id === supplierArticleId,
    );

    if (supplierOrderItem && supplierArticle) {
      supplierOrderItem.supplier = supplierArticle?.supplier as any;
      supplierOrderItem.supplierName = supplierArticle?.supplierName;
      supplierOrderItem.price = supplierArticle?.price;
      supplierOrderItem.externalArticleId = supplierArticle.externalArticleId;
      setPrice(supplierOrderItem.price);
      setOrderItemsData(newState);
    }
  };

  return (
    <TableRow style={{borderTop: 'hidden'}}>
      <TableCell style={{paddingBottom: 0, paddingTop: 0, verticalAlign: 'top'}} colSpan={2}>
        <Collapse in={open} timeout="auto" unmountOnExit style={{height: '50px', paddingTop: '20px'}}>
          <Box style={{paddingBottom: '10px'}}>
            <div className="data-table-wrapper" style={{height: '450px'}}>
              <div className="data-table-wrapper__action-buttons">
                <div className="data-table-wrapper__title">{t('general.suppliers')}</div>
              </div>
              <div className="table-scroll">
                <Table size="small" aria-label="purchases">
                  <TableHead>
                    <TableRow>
                      <TableCell style={{zIndex: 3}}>{t('supplierOrderItems.supplier')}</TableCell>
                      <TableCell style={{zIndex: 3}}>{t('supplierOrderItems.externalArticleId')}</TableCell>
                      <TableCell style={{zIndex: 3}} align="right">
                        {t('supplierOrderItems.quantity')}
                      </TableCell>
                      <TableCell style={{zIndex: 3}} align="right">
                        {t('supplierOrderItems.price')}
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody style={{height: '100px'}}>
                    {(supplierOrderItem?.product?.supplierArticle || [])
                      .sort((x, y) => {
                        if (x.price === 0 && x.quantity === 0 && (y.price !== 0 || y.quantity !== 0)) {
                          return 1;
                        } else if (y.price === 0 && y.quantity === 0 && (x.price !== 0 || x.quantity !== 0)) {
                          return -1;
                        } else {
                          return x.price > y.price ? 1 : y.price > x.price ? -1 : 0;
                        }
                      })
                      .map((supplierArticle) => (
                        <TableRow key={supplierArticle._id} className="hoverTableRow">
                          <TableCell component="th" scope="row">
                            <input
                              id={supplierArticle._id}
                              value={supplierArticle._id}
                              name={supplierArticle.product as any}
                              type="radio"
                              onChange={() => {
                                handleChange(supplierOrderItem._id, supplierArticle._id);
                              }}
                              checked={
                                supplierArticle.externalArticleId === supplierOrderItem.externalArticleId &&
                                supplierArticle.supplier === supplierOrderItem.supplier
                              }
                            />
                            {supplierArticle.supplierName}
                          </TableCell>
                          <TableCell>{supplierArticle.externalArticleId}</TableCell>
                          <TableCell align="right">{supplierArticle.quantity}</TableCell>
                          <TableCell align="right">
                            {supplierArticle.price.toLocaleString('de-DE', {
                              style: 'currency',
                              currency: 'EUR',
                              minimumFractionDigits: 2,
                            })}
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </div>
            </div>
          </Box>
          <Box style={{paddingBottom: '10px'}}>
            {supplierOrderItem.ordersWaitingForProduct?.length ? (
              <Typography variant="h6" gutterBottom component="div">
                {t('supplierOrderItems.pendingOrders')}
              </Typography>
            ) : null}
            {supplierOrderItem.ordersWaitingForProduct?.map((item) => {
              return (
                <span key={item.orderId}>
                  <Link
                    className="link"
                    style={{display: 'inline-flex', marginLeft: '10px'}}
                    to={`${APP_ROUTES.ORDERS}/${item.orderId}`}
                    target="_blank"
                  >
                    <p> {item.orderNumber}</p>
                  </Link>
                </span>
              );
            })}
          </Box>
        </Collapse>
      </TableCell>
      <TableCell style={{paddingBottom: 0, paddingTop: 0, verticalAlign: 'top'}} colSpan={3}>
        <Collapse
          in={open}
          timeout="auto"
          unmountOnExit
          style={{height: '50px', minHeight: '400px', maxHeight: '500px', paddingTop: '20px'}}
        >
          {open && (
            <DataTable
              tableHeight="450px"
              title={t('general.orders')}
              headers={[
                {
                  kind: 'accessor',
                  name: t('orders.date'),
                  accessor: 'date',
                  type: DataTableItemType.DATE,
                  timeZone: 'Europe/Berlin',
                },
                {
                  kind: 'accessor',
                  name: t('orderDetails.customerName'),
                  accessor: 'customerName',
                },
                {
                  kind: 'accessor',
                  name: t('orderItems.quantity'),
                  accessor: 'quantity',
                },
                {
                  kind: 'accessor',
                  name: t('orderItems.priceNet'),
                  accessor: 'priceNet',
                  format: 'price',
                },
              ]}
              endpoint={'order_items_by_product'}
              additionalFields={{productId: supplierOrderItem?.product._id}}
              defaultSort={{key: 'createdAt', value: -1}}
              selectRowClickId={'order'}
              target={'orders'}
            />
          )}
        </Collapse>
      </TableCell>
      <TableCell style={{paddingBottom: 0, paddingTop: 0, verticalAlign: 'top'}} colSpan={3}>
        <Collapse in={open} timeout="auto" unmountOnExit style={{height: '50px', paddingTop: '20px'}}>
          {open && (
            <DataTable
              tableHeight="450px"
              title={t('general.supplierOrders')}
              headers={[
                {
                  kind: 'accessor',
                  name: t('supplierOrderItems.date'),
                  accessor: 'dateOrdered',
                  type: DataTableItemType.DATE,
                },
                {
                  kind: 'accessor',
                  name: t('supplierOrderItems.supplierName'),
                  accessor: 'supplierName',
                },
                {
                  kind: 'accessor',
                  name: t('supplierOrderItems.quantity'),
                  accessor: 'quantity',
                  type: 'number',
                },
                {
                  kind: 'accessor',
                  name: t('supplierOrderItems.price'),
                  accessor: 'price',
                  format: 'price',
                  type: 'number',
                },
              ]}
              options={{product: supplierOrderItem.product._id}}
              endpoint={'supplier_order_items'}
              defaultSort={{key: 'createdAt', value: -1}}
              target="supplierOrders"
              selectRowClickId="supplierOrder._id"
            />
          )}
        </Collapse>
      </TableCell>
    </TableRow>
  );
};

interface ISupplierOrderItemsSubTableProps {
  open: boolean;
  setPrice: (data: any) => void;
}

export default SupplierOrderItemsSubTable;
