import {Button} from '@material-ui/core';
import React, {ReactElement, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {useStorlessFetch} from '../../../hooks/fetch';
import {appActions} from '../../../modules/app/actions';
import {modalActions} from '../../../modules/modal/actions';
import {MODALS} from '../../Modal/ModalContents';

const IncreaseManualNeedQuantityButton = (props: IIncreaseManualNeedQuantityButtonProps): ReactElement => {
  const [increaseManualNeedForProductResponse, increaseManualNeedForProduct] = useStorlessFetch(
    'increase_manual_need_for_required_product',
  );
  const {productId, fetchSupplierOrderItems} = props;
  const dispatch = useDispatch();
  const {t} = useTranslation();

  const handleCancelModal = () => {
    dispatch(modalActions.closeModal());
  };

  const handleSubmitModal = async (values: any) => {
    const data = {id: productId, ...values};
    await increaseManualNeedForProduct(data);
    handleCancelModal();
  };

  const handleClick = async () => {
    dispatch(
      modalActions.addModal(MODALS.ADD_PRODUCT_QUANTITY_REQUIREMENT_MODAL, {
        onSubmit: handleSubmitModal,
        onCancel: handleCancelModal,
      }),
    );
  };

  useEffect(() => {
    if (increaseManualNeedForProductResponse?.error) {
      dispatch(
        appActions.showSnackBar({text: increaseManualNeedForProductResponse.error.name, options: {severity: 'error'}}),
      );
    } else if (increaseManualNeedForProductResponse?.data) {
      fetchSupplierOrderItems();
      dispatch(appActions.showSnackBar({text: t('general.success'), options: {severity: 'success'}}));
    }

    return () => {
      increaseManualNeedForProductResponse.data = null;
      increaseManualNeedForProductResponse.error = null;
      increaseManualNeedForProductResponse.loaded = null;
    };
  }, [increaseManualNeedForProductResponse]);

  return (
    <Button variant="contained" className="blue-button" onClick={handleClick}>
      {t('productDetails.orderProductFromCheapestSupplier')}
    </Button>
  );
};

interface IIncreaseManualNeedQuantityButtonProps {
  fetchSupplierOrderItems: () => void;
  productId: string;
}

export default IncreaseManualNeedQuantityButton;
