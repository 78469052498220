import React, {useEffect, useState} from 'react';
import {useFetch, useStorlessFetch} from '../../hooks/fetch';
import {IProduct} from '../../types/Product/IProduct';
import {ProductCardDetails} from './ProductCardDetails';
import MultiBoxProductsTable from '../../components/Product/MultiBoxProductsTable';
import ProductEditButton from '../../components/Product/ProductActions/ProductEditButton';
import SyncToShopwareButton from '../../components/Product/ProductActions/SyncToShopwareButton';
import MultiBoxParentsTable from '../../components/Product/MultiBoxParentsTable';
import ProductDetailsTabContainer from './ProductDetailsTabContainer';
import {useTranslation} from 'react-i18next';
import {Grid} from '@material-ui/core';
import {ProductDetailsProvider} from './Context/ProductDetailsContext';
import withRoleGuard from '../../guards/Role-Guard';
import {USER_ROLE} from '../../types/User/UserRole';
import PublishToEbayButton from '../../components/Product/ProductActions/PublishToEbayButton';
import SyncToEbayButton from '../../components/Product/ProductActions/SyncToEbayButton';
import AddMultiboxProductButton from '../../components/Product/ProductActions/AddMultiboxProductButton';
import useDataFetcher from '../../hooks/dataFetcher';
import {EntityType} from '../../types/CustomFields/CustomField';
import {useDispatch} from 'react-redux';
import {appActions} from '../../modules/app/actions';

const ProductDetails = (props: any) => {
  const {
    match: {
      params: {id},
    },
  } = props;
  const [productDetails, getProductDetails, onStockUpdate] = useFetch<any>('product_details');
  const [getErrorLogsResponse, getErrorLogs] = useStorlessFetch('error_logs_id');
  const [multiBoxInformation, getMultiBoxInformation] = useFetch<any>('product_details_multibox_information');
  const [setNewMultiBoxResponse, setNewMultiBox] = useFetch<any>('product_set_multibox');
  const [multiBox, setMultiBox] = useState<any>(null);
  const [multiBoxProducts, setMultiBoxProducts] = useState<any[]>([]);
  const [product, setProduct] = useState<IProduct>(null as any);
  const {t} = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    getProductDetails({id});
  }, [id]);

  useEffect(() => {
    if (productDetails.data) {
      getErrorLogs({id});
      setProduct(productDetails.data);
      getMultiBoxInformation({id: productDetails.data._id});
    }
  }, [productDetails]);

  useEffect(() => {
    if (multiBoxInformation.data) {
      setMultiBox(multiBoxInformation.data);
      setMultiBoxProducts(multiBoxInformation.data.productList);
    }
  }, [multiBoxInformation.data]);

  useEffect(() => {
    if (setNewMultiBoxResponse.data) {
      getMultiBoxInformation({id: product._id});
      dispatch(appActions.showSnackBar({text: t('general.submitFormText'), options: {severity: 'success'}}));
    }
  }, [setNewMultiBoxResponse.data]);

  const updateProductState = (value: any, fieldName: string, nestedObjectName?: string) => {
    setProduct((prevState: any) => {
      const updateData = nestedObjectName
        ? {
            [nestedObjectName]: {
              ...prevState[nestedObjectName],
              [fieldName]: value,
            },
          }
        : {[fieldName]: value};

      return {...prevState, ...updateData};
    });
  };

  const {data: customFields} = useDataFetcher('get_custom_field_entity_fields', {
    entityType: EntityType.PRODUCT,
  });

  const adminPermissions = [USER_ROLE.ADMIN, USER_ROLE.STANDARD];
  const ProductEditButtonWithGuard = withRoleGuard(adminPermissions, ProductEditButton, false);
  const SyncToShopwareButtonWithGuard = withRoleGuard(adminPermissions, SyncToShopwareButton, false);
  const PublishOnEbayButtonWithGuard = withRoleGuard(adminPermissions, PublishToEbayButton, false);
  const SyncToEbayButtonWithGuard = withRoleGuard(adminPermissions, SyncToEbayButton, false);
  const AddMultiBoxToProductWithGuard = withRoleGuard(adminPermissions, AddMultiboxProductButton, false);

  return (
    product && (
      <ProductDetailsProvider
        value={{
          product,
          updateProductState,
          onStockUpdate,
          getProductDetails,
          customFields,
          multiBoxProducts,
          multiBox,
          setNewMultiBox,
        }}
      >
        <div className="product-details">
          <div className="product-header">
            <div className="product-header-title">
              <span className="left-title">
                {t('general.products')} /{' '}
                <span className="right-title">{product.manualTitle ? product.manualTitle : product.title}</span>
              </span>
              <div className="product-overview-title">{product.manualTitle ? product.manualTitle : product.title}</div>
            </div>

            <div className="product-header-buttons">
              <ProductEditButtonWithGuard
                product={product}
                getProductDetails={getProductDetails}
                setProduct={setProduct}
              />
              <SyncToShopwareButtonWithGuard product={product} getProductDetails={getProductDetails} />
              <SyncToEbayButtonWithGuard />
              <PublishOnEbayButtonWithGuard product={product} getProductDetails={getProductDetails} />
              <AddMultiBoxToProductWithGuard product={product} />
            </div>
          </div>

          <Grid container>
            <Grid item xs={12} sm={12} md={12}>
              <div style={{color: 'red'}}>{getErrorLogsResponse?.data ? t('general.containsErrorLogs') : ''}</div>
              <ProductCardDetails />
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              {product && (multiBoxProducts?.length || 0) > 0 && <MultiBoxProductsTable product={product} />}
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              {product && (product?.inMultiBoxProductIds?.length || 0) > 0 && (
                <MultiBoxParentsTable products={product?.inMultiBoxProductIds} />
              )}
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <ProductDetailsTabContainer product={product} id={id} getProductDetails={getProductDetails} />
            </Grid>
          </Grid>
        </div>
      </ProductDetailsProvider>
    )
  );
};

export default ProductDetails;
