import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import useUser from '../../../hooks/user';
import {useStorlessFetch} from '../../../hooks/fetch';
import {DataTableItemType} from '../../../components/Table/interfaces/IHeaderAccessor';
import {OrderStatusType} from '../../../types/Orders/OrderStatusType';
import {ShippingProviderType} from '../../../types/Shipment/ShippingProviderType';
import {IOrderScreen} from '../../../types/CustomConfig/IOrderConfig';
import {initOrderScreenConfig} from '../../../types/CustomConfig/IOrderConfig';
import {ShopType} from '../../../types/Orders/ShopType';

export const useOrderHeaders = () => {
  const user = useUser() as any;
  const userEmail = user?.email || '';
  const featureName = userEmail ? `customConfig-${userEmail}` : 'default';
  const [getConfigRes, getConfig] = useStorlessFetch('custom_config');
  const [configObject, setConfigObject] = useState<IOrderScreen>(initOrderScreenConfig());
  const {t} = useTranslation();

  useEffect(() => {
    getConfig({featureName});
  }, [featureName]);

  useEffect(() => {
    if (getConfigRes?.data?.data?.orderScreen) {
      setConfigObject(() => getConfigRes?.data?.data?.orderScreen);
    }
  }, [getConfigRes]);

  const customFields = Object.entries(configObject?.displayedCustomFields || {})
    .filter(([key, value]) => value === true)
    .map(([key, value]) => {
      return {
        kind: 'accessor',
        name: key,
        accessor: 'customFields.' + key,
        sortable: false,
      };
    });

  const headers = [
    configObject?.displayedFields?.orderNumber && {
      kind: 'accessor',
      name: getOrderLabelForField('orderNumber', t),
      accessor: 'orderNumber',
      sortable: true,
    },
    configObject?.displayedFields?.externalOrderNumber && {
      kind: 'accessor',
      name: getOrderLabelForField('externalOrderNumber', t),
      accessor: 'externalOrderNumber',
      sortable: true,
    },
    configObject?.displayedFields?.['easyBillInfo.invoiceNumber'] && {
      kind: 'accessor',
      name: getOrderLabelForField('invoiceNumber', t),
      accessor: 'easyBillInfo.invoiceNumber',
      sortable: true,
    },
    configObject?.displayedFields?.status && {
      kind: 'accessor',
      name: getOrderLabelForField('status', t),
      accessor: 'status',
      sortable: true,
      type: DataTableItemType.MULTIPLE_SELECT,
      options: ([{value: 'all', label: 'All'}] as any).concat(
        Object.values(OrderStatusType).map((item) => {
          return {value: item, label: item};
        }),
      ),
    },
    configObject?.displayedFields?.isTrackingNumberScanned && {
      kind: 'accessor',
      name: getOrderLabelForField('isTrackingNumberScanned', t),
      accessor: 'isTrackingNumberScanned',
      sortable: true,
      type: DataTableItemType.BOOLEAN,
    },
    configObject?.displayedFields?.isPaid && {
      kind: 'accessor',
      name: getOrderLabelForField('isPaid', t),
      accessor: 'isPaid',
      sortable: true,
      type: DataTableItemType.BOOLEAN,
    },
    configObject?.displayedFields?.shippingType && {
      kind: 'accessor',
      name: getOrderLabelForField('shippingType', t),
      accessor: 'shippingType',
      sortable: true,
      type: DataTableItemType.SELECT,
      options: ([{value: 'all', label: 'All'}] as any).concat(
        Object.values(ShippingProviderType).map((item) => {
          return {value: item, label: item};
        }),
      ),
    },
    configObject?.displayedFields?.shopName && {
      kind: 'accessor',
      name: getOrderLabelForField('shopName', t),
      accessor: 'shopName',
      sortable: true,
      type: DataTableItemType.SELECT,
      options: ([{value: 'all', label: 'All'}] as any).concat(
        Object.values(ShopType).map((item) => {
          return {value: item, label: item};
        }),
      ),
    },
    configObject?.displayedFields?.date && {
      kind: 'accessor',
      name: getOrderLabelForField('date', t),
      accessor: 'date',
      sortable: true,
      type: DataTableItemType.DATE,
      timeZone: 'Europe/Berlin',
    },
    configObject?.displayedFields?.paymentMethodName && {
      kind: 'accessor',
      name: getOrderLabelForField('paymentMethodName', t),
      accessor: 'paymentMethodName',
      sortable: true,
    },
    configObject?.displayedFields?.orderTotalNetSum && {
      kind: 'accessor',
      name: getOrderLabelForField('orderTotalNetSum', t),
      accessor: 'orderTotalNetSum',
      sortable: true,
      type: DataTableItemType.NUMBER,
    },
    configObject?.displayedFields?.orderTotalSum && {
      kind: 'accessor',
      name: getOrderLabelForField('orderTotalSum', t),
      accessor: 'orderTotalSum',
      sortable: true,
      type: DataTableItemType.NUMBER,
    },
    configObject?.displayedFields?.shippingCost && {
      kind: 'accessor',
      name: getOrderLabelForField('shippingCost', t),
      accessor: 'shippingCost',
      sortable: true,
      type: DataTableItemType.NUMBER,
    },
    configObject?.displayedFields?.manualShippingCost && {
      kind: 'accessor',
      name: getOrderLabelForField('manualShippingCost', t),
      accessor: 'manualShippingCost',
      sortable: true,
      type: DataTableItemType.NUMBER,
    },
    configObject?.displayedFields?.actualShippingCost && {
      kind: 'accessor',
      name: getOrderLabelForField('actualShippingCost', t),
      accessor: 'actualShippingCost',
      sortable: true,
      type: DataTableItemType.NUMBER,
    },
    configObject?.displayedFields?.orderTotalPaid && {
      kind: 'accessor',
      name: getOrderLabelForField('orderTotalPaid', t),
      accessor: 'orderTotalPaid',
      sortable: true,
      type: DataTableItemType.NUMBER,
    },
    configObject?.displayedFields?.customerNumber && {
      kind: 'accessor',
      name: getOrderLabelForField('customerNumber', t),
      accessor: 'customerNumber',
      sortable: true,
    },
    configObject?.displayedFields?.orderType && {
      kind: 'accessor',
      name: getOrderLabelForField('orderType', t),
      accessor: 'orderType',
      sortable: true,
    },
    configObject?.displayedFields?.['shippingAddress.salutation'] && {
      kind: 'accessor',
      name: getOrderLabelForField('shippingAddress.salutation', t),
      accessor: 'shippingAddress.salutation',
      sortable: true,
    },
    configObject?.displayedFields?.['shippingAddress.title'] && {
      kind: 'accessor',
      name: getOrderLabelForField('shippingAddress.title', t),
      accessor: 'shippingAddress.title',
      sortable: true,
    },
    configObject?.displayedFields?.['shippingAddress.customerName'] && {
      kind: 'accessor',
      name: getOrderLabelForField('shippingAddress.customerName', t),
      accessor: 'shippingAddress.customerName',
      optionalAccessor: 'billingAddress.customerName',
      sortable: true,
    },
    configObject?.displayedFields?.['shippingAddress.firstName'] && {
      kind: 'accessor',
      name: getOrderLabelForField('shippingAddress.firstName', t),
      accessor: 'shippingAddress.firstName',
      sortable: true,
    },
    configObject?.displayedFields?.['shippingAddress.lastName'] && {
      kind: 'accessor',
      name: getOrderLabelForField('shippingAddress.lastName', t),
      accessor: 'shippingAddress.lastName',
      sortable: true,
    },
    configObject?.displayedFields?.['shippingAddress.company'] && {
      kind: 'accessor',
      name: getOrderLabelForField('shippingAddress.company', t),
      accessor: 'shippingAddress.company',
      sortable: true,
    },
    configObject?.displayedFields?.['shippingAddress.addressLine1'] && {
      kind: 'accessor',
      name: getOrderLabelForField('shippingAddress.addressLine1', t),
      accessor: 'shippingAddress.addressLine1',
      sortable: true,
    },
    configObject?.displayedFields?.['shippingAddress.addressLine2'] && {
      kind: 'accessor',
      name: getOrderLabelForField('shippingAddress.addressLine2', t),
      accessor: 'shippingAddress.addressLine2',
      sortable: true,
    },
    configObject?.displayedFields?.['shippingAddress.zip'] && {
      kind: 'accessor',
      name: getOrderLabelForField('shippingAddress.zip', t),
      accessor: 'shippingAddress.zip',
      sortable: true,
    },
    configObject?.displayedFields?.['shippingAddress.city'] && {
      kind: 'accessor',
      name: getOrderLabelForField('shippingAddress.city', t),
      accessor: 'shippingAddress.city',
      sortable: true,
    },
    configObject?.displayedFields?.['shippingAddress.phone'] && {
      kind: 'accessor',
      name: getOrderLabelForField('shippingAddress.phone', t),
      accessor: 'shippingAddress.phone',
      sortable: true,
    },
    configObject?.displayedFields?.['shippingAddress.fax'] && {
      kind: 'accessor',
      name: getOrderLabelForField('shippingAddress.fax', t),
      accessor: 'shippingAddress.fax',
      sortable: true,
    },
    configObject?.displayedFields?.['shippingAddress.email'] && {
      kind: 'accessor',
      name: getOrderLabelForField('shippingAddress.email', t),
      accessor: 'shippingAddress.email',
      sortable: true,
    },
    configObject?.displayedFields?.['shippingAddress.deliveryInstruction'] && {
      kind: 'accessor',
      name: getOrderLabelForField('shippingAddress.deliveryInstruction', t),
      accessor: 'shippingAddress.deliveryInstruction',
      sortable: true,
    },
    configObject?.displayedFields?.['shippingAddress.state'] && {
      kind: 'accessor',
      name: getOrderLabelForField('shippingAddress.state', t),
      accessor: 'shippingAddress.state',
      sortable: true,
    },
    configObject?.displayedFields?.['shippingAddress.countryCode'] && {
      kind: 'accessor',
      name: getOrderLabelForField('shippingAddress.countryCode', t),
      accessor: 'shippingAddress.countryCode',
      sortable: true,
    },
    configObject?.displayedFields?.['shippingAddress.stateCode'] && {
      kind: 'accessor',
      name: getOrderLabelForField('shippingAddress.stateCode', t),
      accessor: 'shippingAddress.stateCode',
      sortable: true,
    },
    configObject?.displayedFields?.['shippingAddress.retailId'] && {
      kind: 'accessor',
      name: getOrderLabelForField('shippingAddress.retailId', t),
      accessor: 'shippingAddress.retailId',
      sortable: true,
    },
    configObject?.displayedFields?.['shippingAddress.postNumber'] && {
      kind: 'accessor',
      name: getOrderLabelForField('shippingAddress.postNumber', t),
      accessor: 'shippingAddress.postNumber',
      sortable: true,
    },
    configObject?.displayedFields?.['shippingAddress.lockerId'] && {
      kind: 'accessor',
      name: getOrderLabelForField('shippingAddress.lockerId', t),
      accessor: 'shippingAddress.lockerId',
      sortable: true,
    },
    configObject?.displayedFields?.['billingAddress.salutation'] && {
      kind: 'accessor',
      name: getOrderLabelForField('billingAddress.salutation', t),
      accessor: 'billingAddress.salutation',
      sortable: true,
    },
    configObject?.displayedFields?.['billingAddress.title'] && {
      kind: 'accessor',
      name: getOrderLabelForField('billingAddress.title', t),
      accessor: 'billingAddress.title',
      sortable: true,
    },
    configObject?.displayedFields?.['billingAddress.customerName'] && {
      kind: 'accessor',
      name: getOrderLabelForField('billingAddress.customerName', t),
      accessor: 'billingAddress.customerName',
      optionalAccessor: 'shippingAddress.customerName',
      sortable: true,
    },
    configObject?.displayedFields?.['billingAddress.firstName'] && {
      kind: 'accessor',
      name: getOrderLabelForField('billingAddress.firstName', t),
      accessor: 'billingAddress.firstName',
      sortable: true,
    },
    configObject?.displayedFields?.['billingAddress.lastName'] && {
      kind: 'accessor',
      name: getOrderLabelForField('billingAddress.lastName', t),
      accessor: 'billingAddress.lastName',
      sortable: true,
    },
    configObject?.displayedFields?.['billingAddress.company'] && {
      kind: 'accessor',
      name: getOrderLabelForField('billingAddress.company', t),
      accessor: 'billingAddress.company',
      sortable: true,
    },
    configObject?.displayedFields?.['billingAddress.addressLine1'] && {
      kind: 'accessor',
      name: getOrderLabelForField('billingAddress.addressLine1', t),
      accessor: 'billingAddress.addressLine1',
      sortable: true,
    },
    configObject?.displayedFields?.['billingAddress.addressLine2'] && {
      kind: 'accessor',
      name: getOrderLabelForField('billingAddress.addressLine2', t),
      accessor: 'billingAddress.addressLine2',
      sortable: true,
    },
    configObject?.displayedFields?.['billingAddress.zip'] && {
      kind: 'accessor',
      name: getOrderLabelForField('billingAddress.zip', t),
      accessor: 'billingAddress.zip',
      sortable: true,
    },
    configObject?.displayedFields?.['billingAddress.city'] && {
      kind: 'accessor',
      name: getOrderLabelForField('billingAddress.city', t),
      accessor: 'billingAddress.city',
      sortable: true,
    },
    configObject?.displayedFields?.['billingAddress.phone'] && {
      kind: 'accessor',
      name: getOrderLabelForField('billingAddress.phone', t),
      accessor: 'billingAddress.phone',
      sortable: true,
    },
    configObject?.displayedFields?.['billingAddress.fax'] && {
      kind: 'accessor',
      name: getOrderLabelForField('billingAddress.fax', t),
      accessor: 'billingAddress.fax',
      sortable: true,
    },
    configObject?.displayedFields?.['billingAddress.email'] && {
      kind: 'accessor',
      name: getOrderLabelForField('billingAddress.email', t),
      accessor: 'billingAddress.email',
      sortable: true,
    },
    configObject?.displayedFields?.['billingAddress.deliveryInstruction'] && {
      kind: 'accessor',
      name: getOrderLabelForField('billingAddress.deliveryInstruction', t),
      accessor: 'billingAddress.deliveryInstruction',
      sortable: true,
    },
    configObject?.displayedFields?.['billingAddress.state'] && {
      kind: 'accessor',
      name: getOrderLabelForField('billingAddress.state', t),
      accessor: 'billingAddress.state',
      sortable: true,
    },
    configObject?.displayedFields?.['billingAddress.countryCode'] && {
      kind: 'accessor',
      name: getOrderLabelForField('billingAddress.countryCode', t),
      accessor: 'billingAddress.countryCode',
      sortable: true,
    },
    configObject?.displayedFields?.['billingAddress.stateCode'] && {
      kind: 'accessor',
      name: getOrderLabelForField('billingAddress.stateCode', t),
      accessor: 'billingAddress.stateCode',
      sortable: true,
    },
    configObject?.displayedFields?.note && {
      kind: 'accessor',
      name: getOrderLabelForField('note', t),
      accessor: 'note',
      sortable: true,
    },
    configObject?.displayedFields?.latestShipDate && {
      kind: 'accessor',
      name: getOrderLabelForField('latestShipDate', t),
      accessor: 'latestShipDate',
      sortable: true,
      type: DataTableItemType.DATE,
    },
    configObject?.displayedFields?.earliestShipDate && {
      kind: 'accessor',
      name: getOrderLabelForField('earliestShipDate', t),
      accessor: 'earliestShipDate',
      sortable: true,
      type: DataTableItemType.DATE,
    },
    configObject?.displayedFields?.taxId && {
      kind: 'accessor',
      name: getOrderLabelForField('taxId', t),
      accessor: 'taxId',
      sortable: true,
    },
    configObject?.displayedFields?.isTrackingNumberPushed && {
      kind: 'accessor',
      name: getOrderLabelForField('isTrackingNumberPushed', t),
      accessor: 'isTrackingNumberPushed',
      sortable: true,
      type: DataTableItemType.BOOLEAN,
    },
    configObject?.displayedFields?.trackingNumberPushedBeforeScanning && {
      kind: 'accessor',
      name: getOrderLabelForField('trackingNumberPushedBeforeScanning', t),
      accessor: 'trackingNumberPushedBeforeScanning',
      sortable: true,
      type: DataTableItemType.BOOLEAN,
    },
    configObject?.displayedFields?.trackingNumber && {
      kind: 'accessor',
      name: getOrderLabelForField('trackingNumber', t),
      accessor: 'trackingNumber',
      sortable: true,
    },
    configObject?.displayedFields?.['returnLabelInfo.trackingNumber'] && {
      kind: 'accessor',
      name: getOrderLabelForField('returnLabelInfo.trackingNumber', t),
      accessor: 'returnLabelInfo.trackingNumber',
      sortable: true,
    },
    configObject?.displayedFields?.['secondLabelInfo.trackingNumber'] && {
      kind: 'accessor',
      name: getOrderLabelForField('secondLabelInfo.trackingNumber', t),
      accessor: 'secondLabelInfo.trackingNumber',
      sortable: true,
    },
    configObject?.displayedFields?.comment && {
      kind: 'accessor',
      name: getOrderLabelForField('comment', t),
      accessor: 'comment',
      sortable: true,
    },
    configObject?.displayedFields?.amazonOrderId && {
      kind: 'accessor',
      name: getOrderLabelForField('amazonOrderId', t),
      accessor: 'amazonOrderId',
      sortable: true,
    },
    configObject?.displayedFields?.isPrime && {
      kind: 'accessor',
      name: getOrderLabelForField('isPrime', t),
      accessor: 'isPrime',
      sortable: true,
      type: DataTableItemType.BOOLEAN,
    },
    configObject?.displayedFields?.fulfillmentChannel && {
      kind: 'accessor',
      name: getOrderLabelForField('fulfillmentChannel', t),
      accessor: 'fulfillmentChannel',
      sortable: true,
    },

    configObject?.displayedFields?.pricingShop && {
      kind: 'accessor',
      name: getOrderLabelForField('pricingShop', t),
      accessor: 'pricingShop',
      sortable: true,
    },
    configObject?.displayedFields?.firstOrderItemTitle && {
      kind: 'accessor',
      name: getOrderLabelForField('firstOrderItemTitle', t),
      accessor: 'firstOrderItemTitle',
      sortable: true,
    },
    configObject?.displayedFields?.isManuallyCompleted && {
      kind: 'accessor',
      name: getOrderLabelForField('isManuallyCompleted', t),
      accessor: 'isManuallyCompleted',
      sortable: true,
      type: DataTableItemType.BOOLEAN,
    },
    configObject?.displayedFields?.isManuallyCompletedAndFinished && {
      kind: 'accessor',
      name: getOrderLabelForField('isManuallyCompletedAndFinished', t),
      accessor: 'isManuallyCompletedAndFinished',
      sortable: true,
      type: DataTableItemType.BOOLEAN,
    },
    configObject?.displayedFields?.isPickupOrder && {
      kind: 'accessor',
      name: getOrderLabelForField('isPickupOrder', t),
      accessor: 'isPickupOrder',
      sortable: true,
      type: DataTableItemType.BOOLEAN,
    },
    configObject?.displayedFields?.shippingMethodName && {
      kind: 'accessor',
      name: getOrderLabelForField('shippingMethodName', t),
      accessor: 'shippingMethodName',
      sortable: true,
    },
    configObject?.displayedFields?.shippingTax && {
      kind: 'accessor',
      name: getOrderLabelForField('shippingTax', t),
      accessor: 'shippingTax',
      sortable: true,
    },
    configObject?.displayedFields?.vatPercentage && {
      kind: 'accessor',
      name: getOrderLabelForField('vatPercentage', t),
      accessor: 'vatPercentage',
      sortable: true,
      type: DataTableItemType.NUMBER,
    },
    configObject?.displayedFields?.['shopwareCustomFields.destinationUrl'] && {
      kind: 'accessor',
      name: getOrderLabelForField('shopwareCustomFields.destinationUrl', t),
      accessor: 'shopwareCustomFields.destinationUrl',
      sortable: true,
    },
    configObject?.displayedFields?.['shopwareCustomFields.googleCloudId'] && {
      kind: 'accessor',
      name: getOrderLabelForField('shopwareCustomFields.googleCloudId', t),
      accessor: 'shopwareCustomFields.googleCloudId',
      sortable: true,
    },
    configObject?.displayedFields?.['shopwareCustomFields.referrer'] && {
      kind: 'accessor',
      name: getOrderLabelForField('shopwareCustomFields.referrer', t),
      accessor: 'shopwareCustomFields.referrer',
      sortable: true,
    },
    configObject?.displayedFields?.['shopwareCustomFields.internalOrderNumber'] && {
      kind: 'accessor',
      name: getOrderLabelForField('shopwareCustomFields.internalOrderNumber', t),
      accessor: 'shopwareCustomFields.internalOrderNumber',
      sortable: true,
    },
    configObject?.displayedFields?.['shopwareCustomFields.deliveryNote'] && {
      kind: 'accessor',
      name: getOrderLabelForField('shopwareCustomFields.deliveryNote', t),
      accessor: 'shopwareCustomFields.deliveryNote',
      sortable: true,
    },
    configObject?.displayedFields?.exchangeOrder && {
      kind: 'accessor',
      name: getOrderLabelForField('exchangeOrder', t),
      accessor: 'exchangeOrder',
      sortable: true,
      type: DataTableItemType.BOOLEAN,
    },
    ...customFields,
  ].filter(Boolean);

  return headers;
};

export const getOrderLabelForField = (fieldName: string, t: any): any => {
  if (fieldName.startsWith('billingAddress.')) {
    return t(`shippingAddress.${fieldName.split('.').slice(1).join('.')}`) + ' (Billing)';
  } else if (fieldName.startsWith('shippingAddress.')) {
    return t(`shippingAddress.${fieldName.split('.').slice(1).join('.')}`) + ' (Shipping)';
  } else if (fieldName.startsWith('shopwareCustomFields.')) {
    return t(`orderDetails.${fieldName.split('.').slice(1).join('.')}`);
  } else if (fieldName.startsWith('secondLabelInfo.')) {
    return t(`orderDetails.${fieldName.split('.').slice(1).join('.')}`) + ' (Second Label)';
  } else if (fieldName.startsWith('returnLabelInfo.')) {
    return t(`orderDetails.${fieldName.split('.').slice(1).join('.')}`) + ' (Return Label)';
  } else if (fieldName.startsWith('easyBillInfo.')) {
    return t(`orderDetails.${fieldName.split('.').slice(1).join('.')}`);
  } else {
    return t(`orderDetails.${fieldName}`);
  }
};
