import React, {useEffect, useState} from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import {useTranslation} from 'react-i18next';
import {useFetch} from '../../hooks/fetch';
import {Checkbox} from '@material-ui/core';

const AlertDialog = (props: IAlertDialogProps) => {
  const {onOpen, onClose, title, description, onSubmit, showShopSelection} = props;
  const [shopConfigs, getShopConfigs] = useFetch<any>('shop_config');
  const [selectedShops, setSelectedShops] = useState<any[]>([]);
  const {t} = useTranslation();

  useEffect(() => {
    if (showShopSelection) {
      getShopConfigs();
    }
  }, []);

  useEffect(() => {
    if (shopConfigs.data) {
      const data = shopConfigs.data.map((item: any) => {
        return {_id: item._id, aliasName: item.aliasName, checked: true};
      });
      setSelectedShops(data);
    }
  }, [shopConfigs]);

  return (
    <Dialog
      open={onOpen}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title" className="modal-title">
        {title}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">{description}</DialogContentText>
        {showShopSelection && selectedShops ? (
          <div>
            {selectedShops.map((element: any) => (
              <span key={element._id}>
                <Checkbox
                  value={element._id}
                  checked={element.checked}
                  defaultChecked={true}
                  onChange={(e: any) => {
                    const newState = [...selectedShops];
                    const item = newState.find((item: any) => item._id === element._id);
                    if (item) {
                      item.checked = e.target.checked;
                      setSelectedShops(newState);
                    }
                  }}
                />{' '}
                {element.aliasName}
              </span>
            ))}
          </div>
        ) : null}
      </DialogContent>
      <div className="action-buttons" style={{float: 'right'}}>
        <DialogActions>
          <Button
            onClick={
              showShopSelection
                ? () => {
                    onSubmit(selectedShops.filter((item) => item.checked).map((item) => item._id));
                  }
                : onSubmit
            }
            className="blue-button"
            autoFocus
            disabled={showShopSelection ? selectedShops.every((item) => !item.checked) : false}
          >
            {t('general.yes')}
          </Button>
          <Button onClick={onClose} className="yellow-button">
            {t('general.no')}
          </Button>
        </DialogActions>
      </div>
    </Dialog>
  );
};

export interface IAlertDialogProps {
  title: string;
  description: string;
  onOpen: boolean;
  onClose: () => any;
  onSubmit: (selectedShops?: any) => any;
  showShopSelection?: boolean;
}

export default AlertDialog;
