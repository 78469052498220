import React, {useState, useEffect, useContext, useCallback} from 'react';
import {useTranslation} from 'react-i18next';
import {useFetch} from '../../hooks/fetch';
import {ITodoList} from '../../types/Todo/ITodoList';
import {ITodoTask} from '../../types/Todo/ITodoTask';
import ListOfTodos from '../../components/Todos/ListOfTodos';
import AddNewTask from '../../components/Todos/AddNewTask';
import {TodoProvider} from './Context/TodoContext';
import TodoListsSidebar from '../../components/Todos/TodoListsSidebar';
import {ITodoScreen, ScreenType} from '../../types/Todo/ITodoScreen';
import UpcomingTodos from '../../components/Todos/UpcomingTasks';
import SerialTasks from '../../components/Todos/SerialTasks';
import CompletedTasks from '../../components/Todos/CompletedTasks';
import {screens} from './screens';
import {Button, TextField} from '@material-ui/core';
import SearchTasks from '../../components/Todos/SearchTasks';
import {TodoSocketList} from '../../types/Todo/TodoSocketList';
import {useUserId} from '../../hooks/userId';
import {ThemeContext} from '../..';

const TodoList = () => {
  const {t} = useTranslation();
  const {todoSocketInformation, fetchTodoSocketInformation} = useContext(ThemeContext);
  const [getTodoListsResponse, getTodoLists] = useFetch<any>('todo_lists');
  const [getTodoTasksResponse, getTodoTasks] = useFetch<any>('todo_tasks');
  const [completeTodoTaskResponse, completeTodoTask] = useFetch<any>('complete_todo_task');
  const [usernamesResponse, getUsernames] = useFetch<any>('get_usernames');
  const [getUpcomingTodosResponse, getUpcomingTodos] = useFetch<any>('todo_tasks_upcoming');
  const [searchTodosResponse, dosearchTodos] = useFetch<any>('search_todo_tasks');
  const [updateTodoTaskResponse, updateTodoTask] = useFetch<any>('update_todo_task');
  const [updatePutTodoTaskResponse, updatePutTodoTask] = useFetch<any>('update_todo_put_task');
  const [createTodoTaskResponse, createTodoTask] = useFetch<any>('create_todo_task');
  const [serialTasksResponse, getSerialTasks] = useFetch<any>('todo_tasks_serial');
  const [completeSubtaskResponse, completeSubtask] = useFetch<any>('complete_subtask');
  const [uncompleteTodoTaskResponse, uncompleteTodoTask] = useFetch<any>('uncomplete_todo_task');
  const [todoLists, setTodoLists] = useState<ITodoList[]>([]);
  const [selectedList, setSelectedList] = useState<ITodoList | null>(null);
  const [usernames, setUsernames] = useState<string[]>([]);
  const [todos, setTodos] = useState<ITodoTask[]>([]);
  const [upcomingTodos, setUpcomingTodos] = useState<ITodoTask[]>([]);
  const [serialTodos, setSerialTodos] = useState<ITodoTask[]>([]);
  const [searchTodos, setSearchTodos] = useState<ITodoTask[]>([]);
  const [completedToday, setCompletedToday] = useState<number>(0);
  const [editingTodo, setEditingTodo] = useState<ITodoTask | null>(null);
  const [screen, setScreen] = useState<ITodoScreen>(screens[0]);
  const [searchInput, setSearchInput] = useState<string>('');
  const [todoSocketLists, setTodoSocketLists] = useState<TodoSocketList[]>([]);
  const [activeTaskId, setActiveTaskId] = useState<string | null>(null);
  const {userId} = useUserId();

  const switchScreen = (screen: ITodoScreen) => {
    setScreen(screen);
  };

  const refetchTodoSocketLists = useCallback(() => {
    if (userId === '') {
      return;
    } else {
      fetchTodoSocketInformation(userId);
    }
  }, [userId, fetchTodoSocketInformation]);

  const refreshTodos = useCallback(() => {
    if (screen.type === ScreenType.LIST && selectedList) {
      getTodoTasks({id: selectedList?._id});
    } else if (screen.type === ScreenType.UPCOMING) {
      getUpcomingTodos();
    } else if (screen.type === ScreenType.SERIAL) {
      getSerialTasks();
    } else if (screen.type === ScreenType.SEARCH) {
      dosearchTodos({searchInput});
    }
  }, [screen.type, selectedList?._id, getTodoTasks, getUpcomingTodos, getSerialTasks, dosearchTodos, searchInput]);

  const switchList = (list: ITodoList) => {
    const selectedList = todoLists.find((todoList) => todoList._id === list._id);
    if (selectedList) {
      setSelectedList(selectedList);
    }
  };

  const searchTodosForSearchInput = () => {
    if (screen.type !== ScreenType.SEARCH) {
      switchScreen(screens.find((screen) => screen.type === ScreenType.SEARCH) || screens[0]);
    }
    refreshTodos();
  };

  useEffect(() => {
    getTodoLists();
    getUsernames();
  }, []);

  useEffect(() => {
    refetchTodoSocketLists();
  }, [userId]);

  useEffect(() => {
    if (todoSocketInformation) {
      const todoSocket = JSON.parse(todoSocketInformation);
      setTodoSocketLists(todoSocket.lists);
    }
  }, [todoSocketInformation]);

  useEffect(() => {
    if (usernamesResponse.data) {
      setUsernames(usernamesResponse.data);
    }
  }, [usernamesResponse]);

  useEffect(() => {
    if (getTodoListsResponse.data) {
      setTodoLists(getTodoListsResponse.data.lists);
      setCompletedToday(getTodoListsResponse.data.completedToday);
      if (!selectedList) {
        setSelectedList(getTodoListsResponse.data.lists[0]);
      }
    }
  }, [getTodoListsResponse]);

  useEffect(() => {
    if (getUpcomingTodosResponse.data) {
      setUpcomingTodos(getUpcomingTodosResponse.data);
    }
  }, [getUpcomingTodosResponse]);

  useEffect(() => {
    if (searchTodosResponse.data) {
      setSearchTodos(searchTodosResponse.data.data);
    }
  }, [searchTodosResponse.data]);

  useEffect(() => {
    if (selectedList) {
      getTodoTasks({id: selectedList._id});
    }
  }, [selectedList]);

  useEffect(() => {
    if (getTodoTasksResponse.data) {
      setTodos(getTodoTasksResponse.data);
    }
  }, [getTodoTasksResponse]);

  useEffect(() => {
    if (completeTodoTaskResponse.data) {
      getTodoLists();
      refreshTodos();
      refetchTodoSocketLists();
    }
  }, [completeTodoTaskResponse]);

  useEffect(() => {
    if (updateTodoTaskResponse.data || updatePutTodoTaskResponse.data || createTodoTaskResponse.data) {
      refreshTodos();
      refetchTodoSocketLists();
    }
  }, [updateTodoTaskResponse, updatePutTodoTaskResponse, createTodoTaskResponse]);

  useEffect(() => {
    if (serialTasksResponse.data) {
      setSerialTodos(serialTasksResponse.data);
    }
  }, [serialTasksResponse]);

  useEffect(() => {
    if (completeSubtaskResponse.data) {
      getTodoLists();
      refreshTodos();
    }
  }, [completeSubtaskResponse]);

  useEffect(() => {
    if (uncompleteTodoTaskResponse.data) {
      getTodoLists();
      getTodoTasks({id: selectedList?._id});
      refreshTodos();
    }
  }, [uncompleteTodoTaskResponse]);

  return (
    <TodoProvider
      value={{
        selectedList,
        todoLists,
        setTodoLists,
        setSelectedList,
        todos,
        setTodos,
        upcomingTodos,
        setUpcomingTodos,
        completedToday,
        setCompletedToday,
        switchList,
        getTodoLists,
        usernames,
        editingTodo,
        setEditingTodo,
        screens,
        screen,
        switchScreen,
        refreshTodos,
        completeTodoTask,
        updateTodoTask,
        updateTodoTaskResponse,
        createTodoTask,
        createTodoTaskResponse,
        serialTodos,
        setSerialTodos,
        updatePutTodoTask,
        searchTodos,
        completeSubtask,
        todoSocketLists,
        activeTaskId,
        setActiveTaskId,
        uncompleteTodoTask,
        refetchTodoSocketLists,
      }}
    >
      <div className="todos">
        <div className="todos-top">
          {screens.map((screen) =>
            screen.showInHeader ? (
              <a href="#" key={screen.id} onClick={() => switchScreen(screen)}>
                {t(screen.title)}
              </a>
            ) : null,
          )}
          <div style={{display: 'flex', alignItems: 'center', marginLeft: '20px'}}>
            <TextField
              id="outlined-basic"
              label={t('todos.search')}
              variant="outlined"
              value={searchInput}
              onChange={(e) => setSearchInput(e.target.value)}
              onKeyUp={(e) => {
                if (e.key === 'Enter') {
                  searchTodosForSearchInput();
                }
              }}
              InputProps={{
                style: {
                  padding: '9px',
                },
                inputProps: {
                  style: {
                    padding: '0px',
                  },
                },
              }}
              InputLabelProps={{
                style: {
                  top: '-8px',
                  fontSize: '0.875rem',
                },
              }}
              style={{
                width: '180px',
              }}
            />
            <Button variant="contained" color="primary" style={{marginLeft: '5px'}} onClick={searchTodosForSearchInput}>
              {t('todos.search')}
            </Button>
          </div>
        </div>
        <h1>{t(screen.title)}</h1>
        <div className="content">
          {screen.id === 1 && <TodoListsSidebar />}
          <div className="main">
            {screen.type === ScreenType.LIST && (
              <>
                <ListOfTodos todos={todos} />
              </>
            )}
            {screen.type === ScreenType.UPCOMING && (
              <div>
                <UpcomingTodos />
              </div>
            )}
            {screen.type === ScreenType.SERIAL && (
              <div>
                <SerialTasks />
              </div>
            )}
            {screen.type === ScreenType.COMPLETED && (
              <div>
                <CompletedTasks />
              </div>
            )}
            {screen.type === ScreenType.SEARCH && (
              <div>
                <SearchTasks />
              </div>
            )}
            <AddNewTask />
          </div>
        </div>
      </div>
    </TodoProvider>
  );
};

export default TodoList;
