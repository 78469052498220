import React from 'react';
import DataTable from '../../components/Table/Table';
import _ from 'lodash';
import {useTranslation} from 'react-i18next';

const InactiveAmazonProducts = () => {
  const {t} = useTranslation();
  return (
    <div className="products">
      <DataTable
        className="products__table"
        headers={[
          {
            kind: 'accessor',
            name: t('products.productId'),
            accessor: 'productId',
            sortable: true,
          },
          {
            kind: 'accessor',
            name: t('products.title'),
            accessor: 'title',
            sortable: true,
          },
          {
            kind: 'accessor',
            name: t('products.productType'),
            accessor: 'productInformation.productType',
            sortable: true,
          },
          {
            kind: 'accessor',
            name: t('products.manufacturer'),
            accessor: 'productInformation.manufacturer',
            sortable: true,
          },
          {
            kind: 'accessor',
            name: t('products.shopwareId'),
            accessor: 'shopwareId',
            sortable: true,
          },
        ]}
        endpoint={'inactive_amazon_products_list'}
        target={'products'}
        defaultSort={{key: 'productId', value: 1}}
        title={t('general.inactiveAmazonProducts')}
      />
    </div>
  );
};

export default InactiveAmazonProducts;
