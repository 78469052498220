import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Table, TableContainer} from '@material-ui/core';
import TableHeader from '../../components/SupplierOrderItems/ProductsWithoutStock/TableHeader';
import TableBody from '../../components/SupplierOrderItems/ProductsWithoutStock/TableBody';
import TableFooter from '../../components/SupplierOrderItems/ProductsWithoutStock/TableFooter';
import {useStorlessFetch} from '../../hooks/fetch';
import {ProductsWithoutStockProvider} from '../../components/SupplierOrderItems/Context/ProductsWithoutStockContext';

const SupplierOrderItemsWithoutStock = () => {
  const {t} = useTranslation();
  const [supplierOrderItems, getSupplierOrderItems] = useStorlessFetch('supplier_order_items');
  const [options, setOptions] = useState({supplier: null});
  const [sort, setSort] = useState({key: 'date', value: -1});
  const [filter, setFilter] = useState({} as any);
  const [page, setPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const defaultParameters = {
    page: page,
    itemsPerPage: itemsPerPage,
    sort: {[sort.key]: sort.value},
    conditions: {...options, ...filter},
  };

  const fetchSupplierOrderItems = (params: any = defaultParameters) => {
    getSupplierOrderItems(params);
  };

  useEffect(() => {
    getSupplierOrderItems(defaultParameters);

    return () => {
      supplierOrderItems.data = null;
      supplierOrderItems.error = null;
      supplierOrderItems.loaded = null;
    };
  }, [options, sort, filter]);

  return (
    <div className="orders orders__supplier">
      <h3>
        <b>{t('general.supplierItemsWithoutStock')}</b>
      </h3>

      <ProductsWithoutStockProvider value={{supplierOrderItems: supplierOrderItems, fetchSupplierOrderItems}}>
        <TableContainer className="table-scroll" style={{height: 'calc(100% - 30px)'}}>
          <Table>
            <TableHeader filter={filter} setFilter={setFilter} setSort={setSort} sort={sort} />
            <TableBody />
            <TableFooter
              fetchSupplierOrderItems={fetchSupplierOrderItems}
              itemsPerPage={itemsPerPage}
              options={options}
              page={page}
              setItemsPerPage={setItemsPerPage}
              setPage={setPage}
              sort={sort}
              supplierOrderItems={supplierOrderItems}
            />
          </Table>
        </TableContainer>
      </ProductsWithoutStockProvider>
    </div>
  );
};

export default SupplierOrderItemsWithoutStock;
