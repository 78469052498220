import {IconButton, TableFooter as Footer, TablePagination, TableRow} from '@material-ui/core';
import {KeyboardArrowLeft, KeyboardArrowRight} from '@material-ui/icons';
import LastPageIcon from '@material-ui/icons/LastPage';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import React, {ReactElement} from 'react';
import {useTranslation} from 'react-i18next';

const TableFooter = (props: IProps): ReactElement => {
  const {options, sort, supplierOrderItems, fetchSupplierOrderItems, itemsPerPage, page, setItemsPerPage, setPage} =
    props;
  const {t} = useTranslation();

  const handleEventChangePage = async (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    await handlePagination(newPage);
  };

  const handleEventChangeRowsPerPage = async (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    await handlePagination(1, +event.target.value);
  };

  const generatePaginationComponent = () => {
    const handleFirstPageButtonClick = async () => await handlePagination(1);
    const handleBackButtonClick = async () => await handlePagination(page - 1);
    const handleNextButtonClick = async () => await handlePagination(page + 1);
    const handleLastPageButtonClick = async () =>
      await handlePagination(Math.ceil(supplierOrderItems?.data?.total / itemsPerPage));

    return (
      <div className="pagination">
        <IconButton onClick={handleFirstPageButtonClick} disabled={page === 1} aria-label="first page">
          <FirstPageIcon />
        </IconButton>
        <IconButton onClick={handleBackButtonClick} disabled={page === 1} aria-label="previous page">
          <KeyboardArrowLeft />
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(supplierOrderItems?.data?.total / itemsPerPage)}
          aria-label="next page"
        >
          <KeyboardArrowRight />
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(supplierOrderItems?.data?.total / itemsPerPage)}
          aria-label="last page"
        >
          <LastPageIcon />
        </IconButton>
      </div>
    );
  };

  const handlePagination = async (newPage: number, newItemsPerPage?: number) => {
    setPage(newPage);
    newItemsPerPage && setItemsPerPage(newItemsPerPage);
    const sortOptions = sort.key ? {[sort.key]: sort.value} : undefined;

    fetchSupplierOrderItems({
      page: newPage,
      itemsPerPage: newItemsPerPage || itemsPerPage,
      sort: sortOptions,
      conditions: {...options},
    });
  };

  return (
    <Footer>
      <TableRow>
        <TablePagination
          rowsPerPageOptions={[10, 25, 50, 100]}
          colSpan={10}
          count={supplierOrderItems?.data?.total || 0}
          component="td"
          rowsPerPage={itemsPerPage}
          page={page - 1}
          SelectProps={{
            inputProps: {'aria-label': 'rows per page'},
            native: true,
          }}
          ActionsComponent={generatePaginationComponent}
          labelRowsPerPage={t('general.rowsPerPage')}
          onPageChange={handleEventChangePage}
          onChangeRowsPerPage={handleEventChangeRowsPerPage}
        />
      </TableRow>
    </Footer>
  );
};

interface IProps {
  sort: any;
  options: any;
  supplierOrderItems: any;
  fetchSupplierOrderItems: (data: any) => void;
  itemsPerPage: number;
  setItemsPerPage: (data: number) => void;
  page: number;
  setPage: (data: number) => void;
}

export default TableFooter;
