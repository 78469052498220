import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import DataTable from '../../components/Table/Table';
import {OrderStatusType} from '../../types/Orders/OrderStatusType';
import {ShopType} from '../../types/Orders/ShopType';
import {DataTableItemType} from '../../components/Table/interfaces/IHeaderAccessor';
import {IDhlConfig} from '../../types/Shipment/interfaces/IDhlConfig';
import {useStorlessFetch} from '../../hooks/fetch';
import {useDispatch} from 'react-redux';
import {appActions} from '../../modules/app/actions';

export default function UndeliveredUpsOrders() {
  const [upsConfigDetailsResponse, getUpsConfigDetails] = useStorlessFetch('feature_configuration');
  const [upsConfig, setUpsConfig] = useState<IDhlConfig>(null as any);
  const dispatch = useDispatch();
  const {t} = useTranslation();

  useEffect(() => {
    getUpsConfigDetails({featureName: 'upsIntegrationConfig'});
  }, []);

  useEffect(() => {
    if (upsConfigDetailsResponse?.data && !upsConfigDetailsResponse.error) {
      setUpsConfig(upsConfigDetailsResponse?.data);
    } else if (upsConfigDetailsResponse?.error) {
      dispatch(appActions.showSnackBar({text: upsConfigDetailsResponse.error.name, options: {severity: 'error'}}));
    }

    return () => {
      upsConfigDetailsResponse.data = null;
      upsConfigDetailsResponse.error = null;
      upsConfigDetailsResponse.loaded = null;
    };
  }, [upsConfigDetailsResponse]);

  return upsConfig ? (
    <div className="orders">
      <DataTable
        headers={[
          {
            kind: 'accessor',
            name: t('orders.orderNumber'),
            accessor: 'orderNumber',
            sortable: true,
          },
          {
            kind: 'accessor',
            name: t('orderDetails.trackingNumber'),
            accessor: 'trackingNumber',
            sortable: true,
          },
          {
            kind: 'accessor',
            name: t('orders.status'),
            accessor: 'status',
            sortable: true,
            type: DataTableItemType.MULTIPLE_SELECT,
            options: ([{value: 'all', label: 'All'}] as any).concat(
              Object.values(OrderStatusType).map((item) => {
                return {value: item, label: item};
              }),
            ),
          },
          {
            kind: 'accessor',
            name: t('orders.shopName'),
            accessor: 'shopName',
            sortable: true,
            type: DataTableItemType.SELECT,
            options: ([{value: 'all', label: 'All'}] as any).concat(
              Object.values(ShopType).map((item) => {
                return {value: item, label: item};
              }),
            ),
          },
          {
            kind: 'accessor',
            name: t('orders.date'),
            accessor: 'date',
            sortable: true,
            type: DataTableItemType.DATE,
            timeZone: 'Europe/Berlin',
          },
          {
            kind: 'accessor',
            name: t('orderDetails.orderTotalSum'),
            accessor: 'orderTotalSum',
            sortable: true,
            type: 'number',
          },
          {
            kind: 'accessor',
            name: t('orders.customerNumber'),
            accessor: 'customerNumber',
            sortable: true,
          },
          {
            kind: 'accessor',
            name: t('orders.lastEventDate'),
            accessor: 'lastEventDate',
            sortable: false,
          },
          {
            kind: 'accessor',
            name: t('orders.lastTrackingStatus'),
            accessor: 'lastTrackingStatus',
            sortable: true,
            type: DataTableItemType.SELECT,
            options: ([{value: 'all', label: 'All'}] as any).concat(
              Object.entries(upsConfig.data.eventTypes).map(([key]) => {
                return {value: key, label: t(`upsConfiguration.eventTypes.${key}`)};
              }),
            ),
          },
          {
            kind: 'accessor',
            name: t('orders.lastEventMessage'),
            accessor: 'lastEventMessage',
            // sortable: true,
            type: DataTableItemType.SELECT,
            // options: ([{value: 'all', label: 'All'}] as any).concat(
            //   upsConfig.data.eventMessages
            //     .sort((a, b) => a.localeCompare(b))
            //     .map((x) => {
            //       return {value: x, label: x};
            //     }),
            // ),
          },
        ]}
        endpoint={'undelivered_ups_orders'}
        target={'orders'}
        defaultSort={{key: 'date', value: -1}}
        title={t('general.undeliveredUpsOrders')}
      />
    </div>
  ) : (
    <></>
  );
}
