import {Button, Checkbox, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from '@material-ui/core';
import {useTranslation} from 'react-i18next';
import React, {ReactElement, useEffect, useState} from 'react';
import {IProduct} from '../../types/Product/IProduct';
import {ISupplierArticle} from '../../types/SupplierArticle/ISupplierArticle';
import {useFetch} from '../../hooks/fetch';
import {appActions} from '../../modules/app/actions';
import {useDispatch} from 'react-redux';

const ExcludedSuppliersTable = (props: IExcludedSuppliersTableProps): ReactElement => {
  const [excludedSuppliersResponse, updateExcludedSuppliers] = useFetch<any>('product_excluded_suppliers_put');
  const [productSuppliers, setProductSuppliers] = useState([]);
  const {product, getProductDetails} = props;
  const dispatch = useDispatch();
  const {t} = useTranslation();

  useEffect(() => {
    if (excludedSuppliersResponse.error) {
      dispatch(appActions.showSnackBar({text: excludedSuppliersResponse.error.name, options: {severity: 'error'}}));
    }

    if (excludedSuppliersResponse.data || excludedSuppliersResponse.data === '') {
      getProductDetails({id: product._id});
      dispatch(
        appActions.showSnackBar({
          text: t('general.submitFormText'),
          options: {severity: 'success'},
        }),
      );
    }

    return () => {
      excludedSuppliersResponse.data = null;
      excludedSuppliersResponse.error = null;
    };
  }, [excludedSuppliersResponse]);

  useEffect(() => {
    const suppliers: any = [];
    product.supplierArticle?.forEach((supplierArticle: ISupplierArticle) => {
      const supplierAlreadyInArray = suppliers.find((item: any) => item.supplier._id === supplierArticle.supplier._id);

      if (!supplierAlreadyInArray) {
        suppliers.push({
          supplier: supplierArticle.supplier,
          excluded: product.excludedSuppliers?.includes(supplierArticle.supplier._id),
        });
      }
    });
    setProductSuppliers(suppliers);
  }, [product]);

  return (
    <div
      className="data-table-wrapper"
      style={{
        height: 'auto',
        maxHeight: '750px',
        marginBottom: '20px',
        padding: '0 3px',
        overflowY: 'auto',
        overflowX: 'hidden',
      }}
    >
      <div style={{marginBottom: '10px', padding: '10px'}}>{t('productDetails.excludedSuppliers')}</div>
      <TableContainer className="table-scroll">
        <Table>
          <TableHead>
            <TableRow>
              <TableCell style={{width: '40%'}}>{t('productDetails.supplierName')}</TableCell>
              <TableCell style={{width: '40%'}}>{t('productDetails.excludeSupplier')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {productSuppliers.map((item: any) => (
              <TableRow key={item.supplier.companyName}>
                <TableCell>{item.supplier.companyName}</TableCell>
                <TableCell>
                  <Checkbox
                    checked={item.excluded}
                    value={item.excluded}
                    onChange={(e: any) => {
                      const newState = [...productSuppliers];
                      const newItem = newState.find((element: any) => item.supplier._id === element.supplier._id);
                      if (newItem) {
                        item.excluded = e.target.checked;
                        setProductSuppliers(newState);
                      }
                    }}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <div style={{width: '99%', textAlign: 'right', marginRight: '110px'}}>
        <Button
          variant="contained"
          className="blue-button"
          onClick={() => {
            const excludedSuppliers = productSuppliers
              .filter((item: any) => item.excluded)
              .map((item: any) => item.supplier._id);
            updateExcludedSuppliers({id: product._id, excludedSuppliers});
          }}
        >
          {t('general.submit')}
        </Button>
      </div>
    </div>
  );
};

interface IExcludedSuppliersTableProps {
  product: IProduct;
  getProductDetails: (data: any) => void;
}

export default ExcludedSuppliersTable;
