import React, {useState, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {EntityType, IEntity} from '../../types/CustomFields/CustomField';
import {useFetch} from '../../hooks/fetch';
import {Button, IconButton} from '@material-ui/core';
import {MODALS} from '../../components/Modal/ModalContents';
import {useDispatch} from 'react-redux';
import {modalActions} from '../../modules/modal/actions';
import {Delete} from '@material-ui/icons';

const CustomFields = () => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const [entities, setEntities] = useState<IEntity[]>([]);
  const [getEntitiesResponse, getEntities] = useFetch<any>('get_custom_fields_entities');
  const [createCustomFieldResponse, createCustomField] = useFetch<any>('create_custom_field');
  const [deleteCustomFieldResponse, deleteCustomField] = useFetch<any>('delete_custom_field');

  useEffect(() => {
    getEntities();
  }, []);

  const isOnlyText = (inputString: string) => {
    return /^[A-Za-z]+$/.test(inputString);
  };

  useEffect(() => {
    if (getEntitiesResponse?.data && !getEntitiesResponse.error) {
      setEntities(getEntitiesResponse?.data);
    }
  }, [getEntitiesResponse]);

  const handleSubmitModal = async (values: any) => {
    if (!isOnlyText(values.fieldName)) {
      alert('Field name must be only text');
      return;
    }

    createCustomField(values);
  };

  const handleCancelModal = () => {
    dispatch(modalActions.closeModal());
  };

  const addField = (entityType: EntityType) => {
    dispatch(
      modalActions.addModal(MODALS.CREATE_CUSTOM_FIELD, {
        title: t('customFields.createCustomField') || '',
        onSubmit: handleSubmitModal,
        onCancel: handleCancelModal,
        entityType,
      }),
    );
  };

  useEffect(() => {
    if (getEntitiesResponse?.data && !getEntitiesResponse.error) {
      setEntities(getEntitiesResponse?.data);
    }
  }, [getEntitiesResponse]);

  useEffect(() => {
    if (createCustomFieldResponse?.data && !createCustomFieldResponse.error) {
      getEntities();
      dispatch(modalActions.closeModal());
    }
  }, [createCustomFieldResponse]);

  useEffect(() => {
    if (deleteCustomFieldResponse?.data && !deleteCustomFieldResponse.error) {
      getEntities();
    }
  }, [deleteCustomFieldResponse]);

  const deleteField = (entityType: EntityType, fieldName: string) => {
    if (confirm(t('general.areYouSure'))) {
      deleteCustomField({entityType, fieldName});
    }
  };

  return (
    <div className="standard-page">
      <h1>{t('general.customFields')}</h1>

      {entities.map((entity) => {
        return (
          <div key={entity.type} className="custom-field-entity">
            <h2>
              {t(`customFields.${entity.type}`)}
              <Button
                variant="contained"
                color="primary"
                style={{marginLeft: '15px'}}
                onClick={() => addField(entity.type)}
              >
                {t('customFields.addNew')}
              </Button>
            </h2>

            {entity.fields.map((field) => {
              return (
                <div key={field.fieldName} className="single-custom-field">
                  <h3 style={{marginTop: '3px'}}>
                    {field.fieldName} ({t(`customFields.${field.type}`)})
                  </h3>
                  <IconButton
                    size="small"
                    title={t('supplierOrderDetails.delete')}
                    style={{color: '#ef4444', margin: 0}}
                    onClick={() => deleteField(entity.type, field.fieldName)}
                  >
                    <Delete />
                  </IconButton>
                </div>
              );
            })}
          </div>
        );
      })}
    </div>
  );
};
export default CustomFields;
