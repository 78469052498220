import {makeStyles} from '@material-ui/core';

export const useEbayStyles = makeStyles((theme: any) => ({
  container: {
    border: '1px solid #ddd',
    borderRadius: '8px',
    padding: theme.spacing(2),
    margin: '20px',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
    width: 'auto',
    marginTop: '10px',
    paddingBottom: '70px',
  },
  variantRow: {
    marginBottom: theme.spacing(1),
  },
  textArea: {
    width: '-webkit-fill-available',
    padding: theme.spacing(2),
  },
  chipContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: theme.spacing(1),
  },
  imageUpload: {
    display: 'none',
  },
  imageBox: {
    width: '150px',
    height: '150px',
    border: '1px solid #ddd',
    borderRadius: '4px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    overflow: 'hidden',
    textAlign: 'left',
    marginLeft: '10px',
  },
  imageUploadContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'left',
    margin: '17px 0px',
    textAlign: 'left',
    border: '1px solid #ddd',
    borderRadius: '4px',
  },
  imageUploadButton: {
    marginTop: '0px',
  },
  uploadedImage: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
  thumbnail: {
    width: '50px',
    height: '50px',
    border: '1px solid #ddd',
    borderRadius: '4px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
  },
  selectedVariationsContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: theme.spacing(1),
  },
  selectedVariationsScrollContainer: {
    padding: '20px',
    maxHeight: '600px',
    overflowY: 'scroll',
    marginTop: '16px',
  },
  evenRow: {
    backgroundColor: 'lightgray',
  },
  oddRow: {
    backgroundColor: 'white',
  },
}));
