import React, {ReactElement} from 'react';
import {Box, Collapse, Table, TableBody, TableCell, TableHead, TableRow, Typography} from '@material-ui/core';
import {Link} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import DataTable from '../../Table/Table';
import {APP_ROUTES} from '../../../routers/routes';
import {ISupplierOrderItem} from '../../../types/SupplierOrders/ISupplierOrderItem';
import {DataTableItemType} from '../../Table/interfaces/IHeaderAccessor';

const SubTable = ({supplierOrderItem, open}: ISupplierOrderItemsSubTableProps): ReactElement => {
  const {t} = useTranslation();

  return (
    <TableRow style={{borderTop: 'hidden'}}>
      <TableCell
        style={{paddingBottom: 0, paddingTop: 0, verticalAlign: 'top', display: open ? 'table-cell' : 'none'}}
        colSpan={4}
      >
        <TableCell style={{paddingBottom: 0, paddingTop: 0, verticalAlign: 'top'}}>
          <Collapse in={open} timeout="auto" unmountOnExit style={{height: '50px', maxWidth: '550px'}}>
            <Box style={{paddingBottom: '10px'}}>
              <Typography variant="h6" gutterBottom component="div" style={{height: '55px'}}>
                {t('general.suppliers')}
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell style={{zIndex: 3}}>{t('supplierOrderItems.supplier')}</TableCell>
                    <TableCell style={{zIndex: 3}}>{t('supplierOrderItems.externalArticleId')}</TableCell>
                    <TableCell style={{zIndex: 3}} align="right">
                      {t('supplierOrderItems.quantity')}
                    </TableCell>
                    <TableCell style={{zIndex: 3}} align="right">
                      {t('supplierOrderItems.price')}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody style={{height: '100px'}}>
                  {(supplierOrderItem?.product?.supplierArticle || [])
                    ?.sort((x, y) => {
                      if (x.price === 0 && x.quantity === 0 && (y.price !== 0 || y.quantity !== 0)) {
                        return 1;
                      } else if (y.price === 0 && y.quantity === 0 && (x.price !== 0 || x.quantity !== 0)) {
                        return -1;
                      } else {
                        return x.price > y.price ? 1 : y.price > x.price ? -1 : 0;
                      }
                    })
                    .map((supplierArticle) => (
                      <TableRow key={supplierArticle._id} className="hoverTableRow">
                        <TableCell component="th" scope="row">
                          <input
                            id={supplierArticle._id}
                            value={supplierArticle._id}
                            name={supplierArticle.product as any}
                            type="radio"
                            checked={
                              supplierArticle.externalArticleId === supplierOrderItem.externalArticleId &&
                              supplierArticle.supplier === supplierOrderItem.supplier
                            }
                            readOnly={true}
                          />
                          {supplierArticle.supplierName}
                        </TableCell>
                        <TableCell>{supplierArticle.externalArticleId}</TableCell>
                        <TableCell align="right">{supplierArticle.quantity}</TableCell>
                        <TableCell align="right">
                          {supplierArticle.price.toLocaleString('de-DE', {
                            style: 'currency',
                            currency: 'EUR',
                            minimumFractionDigits: 2,
                          })}
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </Box>
            <Box style={{paddingBottom: '10px'}}>
              {supplierOrderItem.ordersWaitingForProduct?.length ? (
                <Typography variant="h6" gutterBottom component="div">
                  {t('supplierOrderItems.pendingOrders')}
                </Typography>
              ) : null}
              {supplierOrderItem.ordersWaitingForProduct?.map((item: any) => {
                return (
                  <span key={item.orderId}>
                    <Link
                      style={{color: 'black', display: 'inline-flex', marginLeft: '10px'}}
                      to={`${APP_ROUTES.ORDERS}/${item.orderId}`}
                      target="_blank"
                    >
                      <p> {item.orderNumber}</p>
                    </Link>
                  </span>
                );
              })}
            </Box>
          </Collapse>
        </TableCell>
        <TableCell style={{paddingBottom: 0, paddingTop: 0, verticalAlign: 'top'}}>
          <Collapse in={open} timeout="auto" unmountOnExit style={{minHeight: '250px'}}>
            {open && (
              <div className="orders" style={{padding: 0, margin: 0}}>
                <DataTable
                  title={t('general.orders')}
                  className="orders__table"
                  headers={[
                    {
                      kind: 'accessor',
                      name: t('orders.date'),
                      accessor: 'createdAt',
                      type: DataTableItemType.DATE,
                      timeZone: 'Europe/Berlin',
                    },
                    {
                      kind: 'accessor',
                      name: t('orderDetails.customerName'),
                      accessor: 'customerName',
                    },
                    {
                      kind: 'accessor',
                      name: t('orderItems.quantity'),
                      accessor: 'quantity',
                    },
                    {
                      kind: 'accessor',
                      name: t('orderItems.priceNet'),
                      accessor: 'priceNet',
                      format: 'price',
                    },
                  ]}
                  endpoint={'order_items_by_product'}
                  additionalFields={{productId: supplierOrderItem?.product._id}}
                  defaultSort={{key: 'createdAt', value: -1}}
                />
              </div>
            )}
          </Collapse>
        </TableCell>
        <TableCell style={{paddingBottom: 0, paddingTop: 0, verticalAlign: 'top'}}>
          <Collapse in={open} timeout="auto" unmountOnExit style={{minHeight: '250px'}}>
            {open && (
              <div className="orders" style={{padding: 0, margin: 0}}>
                <DataTable
                  title={t('general.supplierOrders')}
                  className="orders__table"
                  headers={[
                    {
                      kind: 'accessor',
                      name: t('supplierOrderItems.date'),
                      accessor: 'dateOrdered',
                      type: DataTableItemType.DATE,
                    },
                    {
                      kind: 'accessor',
                      name: t('supplierOrderItems.supplierName'),
                      accessor: 'supplierName',
                    },
                    {
                      kind: 'accessor',
                      name: t('supplierOrderItems.quantity'),
                      accessor: 'quantity',
                      type: 'number',
                    },
                    {
                      kind: 'accessor',
                      name: t('supplierOrderItems.price'),
                      accessor: 'price',
                      format: 'price',
                      type: 'number',
                    },
                  ]}
                  options={{product: supplierOrderItem.product._id}}
                  endpoint={'supplier_order_items'}
                  defaultSort={{key: 'createdAt', value: -1}}
                  target="supplierOrders"
                  selectRowClickId="supplierOrder._id"
                />
              </div>
            )}
          </Collapse>
        </TableCell>
      </TableCell>
    </TableRow>
  );
};

interface ISupplierOrderItemsSubTableProps {
  supplierOrderItem: ISupplierOrderItem;
  open: boolean;
}

export default SubTable;
