import {Button, Grid, Table, TableBody, TableCell, TableHead, TableRow, Typography} from '@material-ui/core';
import {TextField} from '@material-ui/core';
import React, {useEffect, useMemo, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {useFetch} from '../../hooks/fetch';
import {appActions} from '../../modules/app/actions';
import {modalActions} from '../../modules/modal/actions';
import {IScannedOrderItem} from '../../types/OrderItems/IScannedOrderItem';
import CloseIcon from '@material-ui/icons/Close';
import Spinner from '../Spinner/Spinner';
import BarcodeButton from './SupplierOrderScanner/BarcodeButton';
import {EventsName, socketFactory} from '../../lib/socketFactory';
import {MODALS} from '../Modal/ModalContents';

export interface ISupplierOrderScannerModal {
  onScan: () => void;
  quantity?: number;
  supplierOrderId: string;
  hideDefaultCloseButton?: boolean;
}

const SupplierOrderScanner = (props: ISupplierOrderScannerModal): JSX.Element => {
  const [barcode, setBarcode] = useState('');
  const [orderItemsScanned, setOrderItemsScanned] = useState(false);
  const [allOrderItemsScanned, setAllOrderItemsScanned] = useState(false);
  const [product, setProduct] = useState<IScannedOrderItem>(null as any);
  const [supplierOrderItems, setOrderItems] = useState<IScannedOrderItem[]>([]);
  const [supplierOrderItemsByOrderId, getSupplierOrderItemsByOrderId] = useFetch<any>(
    'supplier_orders_get_order_items',
  );
  const [handleSupplierOrderItems, doHandleSupplierOrderItems] = useFetch<any>(
    'supplier_orders_handle_scanned_order_items',
  );
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const handlingOrder = useRef(false);
  const {t} = useTranslation();

  const onOrderScanning = (orderId: any) => {
    if (orderId === props.supplierOrderId) {
      dispatch(
        modalActions.addChild(MODALS.CONFIRM_DIALOG, {
          title: t('general.warning'),
          content: t('general.orderIsBeingModified'),
          onOk: closeModal,
          hideDefaultCloseButton: true,
        }),
      );
    }
  };

  const closeModal = () => {
    dispatch(modalActions.closeModal());
  };

  useEffect(() => {
    setLoading(true);
    getSupplierOrderItemsByOrderId({id: props.supplierOrderId});
    socketFactory.listenOrderModifying(onOrderScanning);
    socketFactory.emitOrderModifying(props.supplierOrderId || '');

    return () => {
      socketFactory.emitStopOrderModifying(props.supplierOrderId || '');
      socketFactory.removeEventListener(EventsName.ORDER_MODIFYING);
    };
  }, [props.supplierOrderId]);

  useEffect(() => {
    let debounceTimeout: any;
    if (supplierOrderItems?.length && barcode) {
      const productScanned = supplierOrderItems
        .filter((item) => item.quantity !== item.quantityScanned)
        .find((item) => item.barcodeArticleNumbers.barcodeArticleNumbers.includes(barcode));

      if (productScanned) {
        clearTimeout(debounceTimeout);
        setProduct(productScanned);
        updateScannedItemQuantity(productScanned);
        resetBarcodeInput();
        setOrderItems([...supplierOrderItems]);
        dispatch(appActions.showSnackBar({text: t('scanner.scanSuccess'), options: {severity: 'success'}}));
      } else {
        clearTimeout(debounceTimeout);
        debounceTimeout = setTimeout(() => {
          const beep = new Audio('/sounds/beep-05.mp3');
          beep.play();
        }, 500);
      }

      checkIfAllProductsAreScanned();
    }

    return () => debounceTimeout && clearTimeout(debounceTimeout);
  }, [barcode]);

  useEffect(() => {
    if (allOrderItemsScanned && !handlingOrder.current) {
      handlingOrder.current = true;
      doHandleSupplierOrderItems({scannedProducts: supplierOrderItems, id: props.supplierOrderId});

      return () => {
        setBarcode('');
        setAllOrderItemsScanned(false);
        setOrderItemsScanned(false);
        setOrderItems(null as any);
      };
    }
  }, [allOrderItemsScanned]);

  useEffect(() => {
    if (orderItemsScanned) {
      props.onScan();
      dispatch(appActions.showSnackBar({text: t('scanner.scanSuccess'), options: {severity: 'success'}}));
      dispatch(modalActions.closeModal());
    }

    return () => {
      setBarcode('');
      setOrderItemsScanned(false);
      supplierOrderItemsByOrderId.data = null;
      supplierOrderItemsByOrderId.error = null;
    };
  }, [orderItemsScanned]);

  useEffect(() => {
    if (supplierOrderItemsByOrderId.data) {
      const data = (supplierOrderItemsByOrderId.data as IScannedOrderItem[]).filter(
        (item) => item.quantity !== item.quantityScanned,
      );
      setOrderItems(data);
      setLoading(false);
    }

    if (supplierOrderItemsByOrderId.error) {
      setLoading(false);
    }

    return () => {
      supplierOrderItemsByOrderId.data = null;
      supplierOrderItemsByOrderId.error = null;
    };
  }, [supplierOrderItemsByOrderId]);

  useEffect(() => {
    if (!loading) {
      focusBarcodeInput();
    }
  }, [loading]);

  useEffect(() => {
    if (handleSupplierOrderItems.data) {
      setOrderItemsScanned(true);
      setLoading(false);
      handlingOrder.current = false;
    }
    return () => {
      handleSupplierOrderItems.data = null;
      handlingOrder.current = false;
    };
  }, [handleSupplierOrderItems]);

  const focusBarcodeInput = () => {
    const inputBarcode = document.getElementById('input-bar-code') as HTMLInputElement;
    if (inputBarcode) {
      inputBarcode.value = '';
      inputBarcode.focus();
      setBarcode('');
    }
  };

  const resetBarcodeInput = () => focusBarcodeInput();

  const confirmButtonHandler = async () => {
    setLoading(true);
    handlingOrder.current = true;
    doHandleSupplierOrderItems({scannedProducts: supplierOrderItems, id: props.supplierOrderId});
  };

  const onChangeBarcodeHandler = (e: any) => {
    setBarcode(e.target.value);
  };

  const updateScannedItemQuantity = (item: IScannedOrderItem) => {
    if (isNaN(item.quantityScanned)) {
      item.quantityScanned = 0;
    }
    if (item.quantityScanned < item.quantity) {
      item.quantityScanned += 1;
    }
  };

  const checkIfAllProductsAreScanned = () => {
    const allProductsAreScanned = supplierOrderItems.every((item) => item.quantity === item.quantityScanned);
    if (allProductsAreScanned) {
      setAllOrderItemsScanned(true);
    }
  };

  const SubmitButton = (
    <Button
      style={{width: '100%', marginTop: '10px'}}
      variant="contained"
      className="blue-button"
      onClick={confirmButtonHandler}
      disabled={!product && (loading || handlingOrder.current)}
    >
      {t('general.confirm')}
    </Button>
  );

  const OrderItemsTable = useMemo(() => {
    return supplierOrderItems && supplierOrderItems.filter((item) => item.quantity !== item.quantityScanned).length ? (
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>{t('scanner.productId')}</TableCell>
            <TableCell>EAN</TableCell>
            <TableCell>{t('scanner.product')}</TableCell>
            <TableCell>{t('scanner.quantity')}</TableCell>
            <TableCell>Barcode</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {supplierOrderItems
            .filter((item) => item.quantity !== item.quantityScanned)
            .map((scannedProduct) => (
              <React.Fragment key={scannedProduct.productId}>
                <TableRow>
                  <TableCell>{scannedProduct.id.toString()}</TableCell>
                  <TableCell>{scannedProduct.ean}</TableCell>
                  <TableCell>{scannedProduct.productTitle}</TableCell>
                  <TableCell>{scannedProduct.quantity}</TableCell>
                  <TableCell>
                    <BarcodeButton scannedProduct={scannedProduct} />
                  </TableCell>
                </TableRow>
              </React.Fragment>
            ))}
        </TableBody>
      </Table>
    ) : (
      <div style={{marginTop: '70px'}}></div>
    );
  }, [supplierOrderItems, product]);

  return (
    <div className="scanner-body">
      {loading && <Spinner />}
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} alignItems="flex-end">
          <div style={{width: '100%'}}>
            <CloseIcon
              onClick={() => {
                dispatch(modalActions.closeModal());
              }}
              style={{float: 'right', cursor: 'pointer', margin: '10px'}}
            />
          </div>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Grid item xs={12} sm={12}>
            <div style={{textAlign: 'left'}}>
              {!product ? (
                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px'}}>
                  <Typography style={{textAlign: 'center', width: '100%'}}>{t('scanner.barCodeInfo')}</Typography>
                </div>
              ) : (
                <Grid container item xs={12} sm={12} spacing={1}>
                  <Grid item xs={12} sm={12}>
                    <h2 style={{textAlign: 'center'}}>{product?.productTitle || ''}</h2>
                  </Grid>
                  <Grid item xs={12} sm={6} style={{alignSelf: 'center', textAlign: 'center'}}>
                    <img
                      src={product.productImage ?? '/images/no_image2.webp'}
                      style={{maxWidth: '100%', maxHeight: '100%'}}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <p style={{whiteSpace: 'pre-line'}}>
                      {t('productDetails.manufacturer')}: {product.productInformation?.manufacturer + '\n'}
                      {t('productDetails.compatibility')}: {product.productInformation?.compatibility + '\n'}
                      {t('productDetails.content')}: {product.productInformation?.content + '\n'}
                      {t('productDetails.colour')}: {product.productInformation?.colour + '\n'}
                      {t('productDetails.numberOfItems')}: {product.productInformation?.numberOfItems + '\n'}
                      {t('productDetails.pageYield')}: {product.productInformation?.pageYield + '\n'}
                    </p>
                  </Grid>
                </Grid>
              )}
            </div>
          </Grid>
        </Grid>
        <Grid container item xs={12} sm={6} spacing={2} alignItems="center">
          <Grid container spacing={2} style={{marginTop: '10px'}}>
            <Grid item xs={12} sm={12}>
              <TextField
                onChange={onChangeBarcodeHandler}
                id="input-bar-code"
                placeholder={t('scanner.inputBarcodeLabel')}
                label={t('scanner.barCode')}
                variant="outlined"
                autoFocus
                disabled={loading || handlingOrder.current}
              />
            </Grid>
            {product && (
              <>
                <Grid item xs={12} sm={6}>
                  <span>
                    {t('scanner.quantityOrdered')}: {product.quantity || 0}
                  </span>
                  <p style={{fontWeight: 'bold', textDecoration: 'underline'}}>
                    {t('scanner.pendingQuantity') + ': '} {product.numberOfPendingProducts}
                  </p>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <span>
                    {' '}
                    {t('scanner.quantityScanned') + ': '}
                    {product.quantity <= 20 ? (
                      product.quantityScanned
                    ) : (
                      <TextField
                        type="number"
                        variant="outlined"
                        value={product.quantityScanned}
                        InputProps={{
                          inputProps: {
                            min: 0,
                            max: product.quantity,
                          },
                        }}
                        disabled={product.quantity <= 20}
                        onChange={(e) => {
                          const quantity = +e.target.value;
                          if (quantity >= 0 && quantity <= product.quantity) {
                            product.quantityScanned = quantity;
                            setOrderItems([...supplierOrderItems]);
                          }
                        }}
                      ></TextField>
                    )}
                  </span>
                </Grid>
              </>
            )}
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12}>
          {OrderItemsTable}
          {SubmitButton}
        </Grid>
      </Grid>
    </div>
  );
};

export default SupplierOrderScanner;
