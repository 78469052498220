import {Button} from '@material-ui/core';
import React, {useContext, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import ReturnOrderScanningContext from '../../../screens/Orders/Context/ReturnOrderScanningContext ';

const ReturnSubmitButton = () => {
  const {t} = useTranslation();
  const [isButtonActive, setIsButtonActive] = useState<boolean>(false);

  const {
    scannedProducts,
    orderId,
    initialScannedProducts,
    refundShippingCosts,
    skipCreationOfCreditNote,
    discount,
    handleReturnedOrderItems,
    doHandleReturnedOrderItems,
  } = useContext(ReturnOrderScanningContext);

  const confirmButtonHandler = async () => {
    if (handleReturnedOrderItems?.loading) {
      return;
    }

    doHandleReturnedOrderItems({scannedProducts, id: orderId, refundShippingCosts, skipCreationOfCreditNote, discount});
  };

  useEffect(() => {
    const changedFromOriginalState = scannedProducts.some((item) => {
      const initialProduct = initialScannedProducts.find((initialItem) => initialItem.productId === item.productId);
      return initialProduct?.returnQuantityScanned !== item.returnQuantityScanned;
    });

    if (changedFromOriginalState) {
      setIsButtonActive(true);
    }
  }, [scannedProducts]);

  return (
    <Button
      style={{width: '100%'}}
      variant="contained"
      color="primary"
      onClick={confirmButtonHandler}
      disabled={!isButtonActive || handleReturnedOrderItems?.loading}
    >
      {t('general.confirm')}
    </Button>
  );
};

export default ReturnSubmitButton;
