import {Button, IconButton} from '@material-ui/core';
import {Edit, Clear} from '@material-ui/icons';
import React, {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {MODALS} from '../../components/Modal/ModalContents';
import DataTable from '../../components/Table/Table';
import {useFetch} from '../../hooks/fetch';
import {appActions} from '../../modules/app/actions';
import {modalActions} from '../../modules/modal/actions';
import {DataTableItemType} from '../../components/Table/interfaces/IHeaderAccessor';

interface IProps {
  doCountryPut: (param: any) => void;
  doCountryDelete: (param: any) => void;
  fetchData: () => void;
  row?: {email: string; username: string; _id: string};
}

export const CountryActions: React.FC<IProps> = (props: IProps) => {
  const {doCountryPut, doCountryDelete, fetchData, row} = props;
  const dispatch = useDispatch();
  const {t} = useTranslation();

  const handleCancelModal = () => {
    dispatch(modalActions.closeModal());
  };

  const handleDeleteItem = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    event.preventDefault();

    dispatch(
      modalActions.addModal(MODALS.CONFIRM_DIALOG, {
        title: t('users.deleteUser') || '',
        content: t('general.areYouSure'),
        onYes: deleteButtonHandler,
        onNo: handleCancelModal,
      }),
    );
  };

  const editButtonHandler = async (values: any) => {
    const data = {id: row?._id, ...values};

    doCountryPut(data);
    handleCancelModal();
    fetchData();
  };

  const deleteButtonHandler = async () => {
    doCountryDelete({id: row?._id});
    handleCancelModal();
    fetchData();
  };

  const handleEditItem = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    event.preventDefault();

    dispatch(
      modalActions.addModal(MODALS.EDIT_USER, {
        title: t('users.editUser') || '',
        onSubmit: editButtonHandler,
        onCancel: handleCancelModal,
        row,
      }),
    );
  };

  return (
    <div>
      <IconButton onClick={handleEditItem}>
        <Edit fontSize="small" style={{fill: 'gray'}}></Edit>
      </IconButton>
      <IconButton onClick={handleDeleteItem}>
        <Clear fontSize="small" style={{fill: 'red'}}></Clear>
      </IconButton>
    </div>
  );
};

const Users = () => {
  const dispatch = useDispatch();
  const [addUserResponse, addUser] = useFetch<any>('users_post');
  const [patchUserResponse, patchUser] = useFetch<any>('users_patch');
  const [deleteUserResponse, deleteUser] = useFetch<any>('users_delete');
  const {t} = useTranslation();

  const handleCancelModal = () => {
    dispatch(modalActions.closeModal());
  };

  const submitHandler = async (values: any) => {
    const data = {...values};

    addUser(data);
    handleCancelModal();
  };

  const handleAddCountryBtnClick = async () => {
    dispatch(
      modalActions.addModal(MODALS.EDIT_USER, {
        title: t('users.addUser') || '',
        onCancel: handleCancelModal,
        onSubmit: submitHandler,
      }),
    );
  };

  useEffect(() => {
    if (addUserResponse.error) {
      dispatch(appActions.showSnackBar({text: addUserResponse.error?.name, options: {severity: 'error'}}));
    } else if (addUserResponse.data) {
      dispatch(appActions.showSnackBar({text: t('general.success'), options: {severity: 'success'}}));
      dispatch(modalActions.closeModal());
    }

    return () => {
      addUserResponse.data = null;
      addUserResponse.error = null;
      addUserResponse.loaded = null;
      addUserResponse.loading = null;
    };
  }, [addUserResponse]);

  useEffect(() => {
    if (deleteUserResponse.error) {
      dispatch(appActions.showSnackBar({text: deleteUserResponse.error?.name, options: {severity: 'error'}}));
    } else if (deleteUserResponse.data) {
      dispatch(appActions.showSnackBar({text: t('general.success'), options: {severity: 'success'}}));
    }

    return () => {
      deleteUserResponse.data = null;
      deleteUserResponse.error = null;
      deleteUserResponse.loaded = null;
      deleteUserResponse.loading = null;
    };
  }, [deleteUserResponse]);

  useEffect(() => {
    if (patchUserResponse.error) {
      dispatch(appActions.showSnackBar({text: patchUserResponse.error?.name, options: {severity: 'error'}}));
    } else if (patchUserResponse.data) {
      dispatch(appActions.showSnackBar({text: t('general.success'), options: {severity: 'success'}}));
    }

    return () => {
      patchUserResponse.data = null;
      patchUserResponse.error = null;
      patchUserResponse.loaded = null;
      patchUserResponse.loading = null;
    };
  }, [patchUserResponse]);

  return (
    <div className="country-shipping-providers">
      <DataTable
        headers={[
          {
            kind: 'accessor',
            name: t('users.username'),
            accessor: 'username',
            sortable: true,
          },
          {
            kind: 'accessor',
            name: t('users.email'),
            accessor: 'email',
            sortable: true,
          },
          {
            kind: 'accessor',
            name: t('users.lastActivityDate'),
            accessor: 'lastActivityDate',
            sortable: true,
            type: DataTableItemType.DATE,
          },
          {
            kind: 'component',
            name: t('general.actions'),
            component: CountryActions,
            props: {doCountryPut: patchUser, doCountryDelete: deleteUser, user: this},
            onFetchProps: {},
          },
        ]}
        endpoint={'users'}
        title={t('general.users')}
        actionButtons={
          <Button variant="contained" className="blue-button" onClick={handleAddCountryBtnClick}>
            {t('users.add')}
          </Button>
        }
        refreshData={addUserResponse.loaded}
      />
    </div>
  );
};

export default Users;
