import {ICustomFieldType} from '../types/CustomFields/CustomField';

export const getType = (fieldType: any) => {
  switch (fieldType) {
    case ICustomFieldType.STRING:
      return 'input';
    case ICustomFieldType.BOOLEAN:
      return 'checkbox';
    case ICustomFieldType.NUMBER:
      return 'input';
    case ICustomFieldType.DATE:
      return 'date';
    default:
      return 'input';
  }
};
