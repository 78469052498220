import React, {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import DataTable from '../../components/Table/Table';
import {Link} from 'react-router-dom';
import {APP_ROUTES} from '../../routers/routes';
import {Button} from '@material-ui/core';
import {DataTableItemType} from '../../components/Table/interfaces/IHeaderAccessor';
import {IEbayMultiVariant} from '../../types/Product/IEbayMultiVariant';
import {useStorlessFetch} from '../../hooks/fetch';
import {MODALS} from '../../components/Modal/ModalContents';
import {useDispatch} from 'react-redux';
import {modalActions} from '../../modules/modal/actions';
import {appActions} from '../../modules/app/actions';
import {Check} from '@material-ui/icons';

export default function EbayMultiVariants() {
  const [deleteItemRes, deleteItem] = useStorlessFetch('ebay_multi_variant_delete');
  const {t} = useTranslation();
  const dispatch = useDispatch();

  const onDelete = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, item: IEbayMultiVariant): void => {
    e.preventDefault();

    dispatch(
      modalActions.addChild(MODALS.CONFIRM_DIALOG, {
        title: t('general.warning'),
        content: t('general.areYouSure'),
        onYes: () => {
          deleteItem({id: item._id});
          dispatch(modalActions.closeModal());
        },
        onNo: () => dispatch(modalActions.closeModal()),
      }),
    );
  };

  useEffect(() => {
    if (deleteItemRes?.data) {
      dispatch(appActions.showSnackBar({text: t('general.submitFormText'), options: {severity: 'success'}}));
    } else if (deleteItemRes?.error) {
      dispatch(appActions.showSnackBar({text: deleteItemRes.error.name, options: {severity: 'error'}}));
    }

    return () => {
      deleteItemRes.data = null;
      deleteItemRes.error = null;
    };
  }, [deleteItemRes]);

  return (
    <div className="products">
      <DataTable
        actionButtons={
          <Link className="link" to={APP_ROUTES.EBAY_MULTI_VARIANT_PRODUCT}>
            <Button className="blue-button">{t('eBayMultiVariant.createItem')}</Button>
          </Link>
        }
        className="products__table"
        headers={[
          {
            kind: 'accessor',
            name: '',
            accessor: 'uploadedImage',
            sortable: false,
            type: 'image',
          },
          {
            kind: 'accessor',
            name: t('eBayMultiVariant.inventoryItemGroupKey'),
            accessor: 'inventoryItemGroupKey',
            sortable: true,
          },
          {
            kind: 'accessor',
            name: t('eBayMultiVariant.published'),
            accessor: 'listingId',
            sortable: true,
            callback: (item: IEbayMultiVariant) => {
              return item.listingId ? <Check color="primary" /> : '';
            },
          },
          {
            kind: 'accessor',
            name: t('eBayMultiVariant.title'),
            accessor: 'title',
            sortable: true,
          },
          {
            kind: 'accessor',
            name: t('eBayMultiVariant.createdAt'),
            accessor: 'createdAt',
            sortable: true,
            type: DataTableItemType.DATE,
          },
          {
            kind: 'accessor',
            name: '',
            accessor: '_id',
            type: DataTableItemType.CALLBACK,
            callback: (item: IEbayMultiVariant) => {
              return (
                <Button variant="contained" color="primary" className="blue-button" onClick={(e) => onDelete(e, item)}>
                  {t('general.delete')}
                </Button>
              );
            },
          },
        ]}
        endpoint={'ebay_multi_variant_list'}
        target={'ebay-multi-variant-product'}
        title={t('general.eBayProducts')}
        options={{deletedAt: null}}
        defaultSort={{key: 'createdAt', value: -1}}
        refreshData={deleteItemRes}
      />
    </div>
  );
}
