import {TableRow as Row, TableCell, IconButton} from '@material-ui/core';
import {KeyboardArrowUp, KeyboardArrowDown} from '@material-ui/icons';
import React, {ReactElement} from 'react';
import {Link} from 'react-router-dom';
import {APP_ROUTES} from '../../../routers/routes';
import {ISupplierOrderItem} from '../../../types/SupplierOrders/ISupplierOrderItem';
import UpdateSupplierOrderItemWithoutStockButton from './UpdateSupplierOrderItemWithoutStockButton';
import SubTable from './SubTable';

const TableRow = ({setExpandedRow, expandedRow, supplierOrderItem}: IProps): ReactElement => {
  const handleExpandRow = () => {
    if (expandedRow !== supplierOrderItem._id) {
      setExpandedRow(supplierOrderItem._id);
    } else {
      setExpandedRow('');
    }
  };

  return (
    <React.Fragment>
      <Row key={supplierOrderItem._id}>
        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={handleExpandRow}>
            {expandedRow === supplierOrderItem._id ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
        </TableCell>
        <TableCell>
          <Link
            style={{color: 'black', display: 'flex'}}
            to={`${APP_ROUTES.PRODUCTS}/${supplierOrderItem?.product?._id}`}
            target="_blank"
          >
            <p> {supplierOrderItem.productTitle}</p>
          </Link>
        </TableCell>
        <TableCell>{supplierOrderItem.quantity}</TableCell>
        <TableCell>
          <UpdateSupplierOrderItemWithoutStockButton
            supplierOrderItem={supplierOrderItem}
            supplierOrderId={supplierOrderItem?.supplierOrder?._id}
          />
        </TableCell>
      </Row>
      <SubTable supplierOrderItem={supplierOrderItem} open={expandedRow === supplierOrderItem._id} />
    </React.Fragment>
  );
};

interface IProps {
  supplierOrderItem: ISupplierOrderItem;
  expandedRow: any;
  setExpandedRow: (data: any) => void;
}

export default TableRow;
