import {SELECTION, SelectionActions} from './actions';

export interface ISelectionState {
  selections: {key: string; values: any[]}[];
}
export const INITIAL_STATE: ISelectionState = {
  selections: [],
};

const selectionReducer = (state = INITIAL_STATE, action: SelectionActions) => {
  switch (action.type) {
    case SELECTION.SELECTION_VALUES: {
      const selections = state.selections;
      const index = selections.findIndex((selection) => selection.key === action.payload.key);
      if (index > -1) {
        selections[index] = {...selections[index], ...action.payload};
        return {...state, selections: selections};
      }
      return {
        ...state,
        selections: [...selections, action.payload],
      };
    }
    default:
      return state;
  }
};

export {selectionReducer};
