import React, {ReactElement, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {IProduct} from '../../types/Product/IProduct';
import {Button, TextField, List, ListItem, ListItemText, Paper} from '@material-ui/core';
import {useStorlessFetch, useStorlessUpload} from '../../hooks/fetch';
import NotificationHelper from '../../lib/notificationHelper';
import {Util} from '../../modules/api/utils';
import {SecurityInfo} from '../../types/Product/ISecurityInfo';
import {Checkbox} from '@material-ui/core';

const SecurityInfoDetails = ({product, getProductDetails}: Props): ReactElement => {
  const [getFileResponse, getFile] = useStorlessFetch('file_get');
  const [deleteProductInformationRes, deleteProductInformation] = useStorlessFetch('delete_product_information');
  const [syncToShopwareRes, syncToShopware] = useStorlessFetch('sync_product_information');
  const [uploadFileResponse, uploadFile] = useStorlessUpload('upload_product_information');
  const [languages, setLanguages] = useState<string[]>([]);
  const [otherConfig, getOtherConfig] = useStorlessFetch('feature_configuration');
  const [uploadedFile, setUploadedFile] = useState<{[key: string]: File | null}>({});
  const [isDragging, setIsDragging] = useState(false);
  const [fileName, setFileName] = useState('');
  const {t} = useTranslation();

  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>, language: string) => {
    setUploadedFile((prevState) => ({...prevState, [language]: event.target.files?.[0] || null}));
  };

  const uploadImage = async (language: string) => {
    if (uploadedFile[language]) {
      await uploadFile({file: uploadedFile[language], language, id: product._id});
      setUploadedFile((prevState) => ({...prevState, [language]: null}));
    }
  };

  const handleDrop = (event: React.DragEvent<HTMLDivElement>, language: string) => {
    event.preventDefault();
    setIsDragging(false);
    if (event.dataTransfer.files && event.dataTransfer.files.length > 0) {
      setUploadedFile((prevState) => ({...prevState, [language]: event.dataTransfer.files[0]}));
      event.dataTransfer.clearData();
    }
  };

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsDragging(true);
  };

  const handleDragEnter = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsDragging(false);
  };

  useEffect(() => {
    getOtherConfig({featureName: 'otherConfig'});
  }, []);

  useEffect(() => {
    if (otherConfig?.data?.data?.productInfoLanguages) {
      setLanguages(otherConfig.data.data.productInfoLanguages);
    }
  }, [otherConfig]);

  useEffect(() => {
    if (uploadFileResponse?.data && uploadFileResponse.loaded) {
      getProductDetails({id: product._id});
      NotificationHelper.showSuccess(t('general.success'));
    }

    if (uploadFileResponse?.error && uploadFileResponse.loaded) {
      NotificationHelper.showError(t('general.error'));
    }
  }, [uploadFileResponse?.data]);

  useEffect(() => {
    if (syncToShopwareRes?.data && syncToShopwareRes.loaded) {
      getProductDetails({id: product._id});
      NotificationHelper.showSuccess(t('general.success'));
    }

    if (syncToShopwareRes?.error && syncToShopwareRes.loaded) {
      NotificationHelper.showError(t('general.error'));
    }
  }, [syncToShopwareRes?.data]);

  useEffect(() => {
    if (deleteProductInformationRes?.data && deleteProductInformationRes.loaded) {
      getProductDetails({id: product._id});
      NotificationHelper.showSuccess(t('general.success'));
    }

    if (deleteProductInformationRes?.error && deleteProductInformationRes.loaded) {
      NotificationHelper.showError(t('general.error'));
    }
    return () => {
      deleteProductInformationRes.loaded = null;
      deleteProductInformationRes.data = null;
      deleteProductInformationRes.error = null;
    };
  }, [deleteProductInformationRes?.data]);

  useEffect(() => {
    if (getFileResponse.data) {
      const extension = fileName ? fileName.split('.').pop()?.toLowerCase() : '';
      const mimeType = Util.getMimeType(extension);

      if (mimeType) {
        const blob = new Blob([new Uint8Array(getFileResponse.data.data).buffer], {type: mimeType});
        const blobURL = URL.createObjectURL(blob);
        window.open(blobURL);
      }
      setFileName('');
    }

    return () => {
      getFileResponse.data = null;
      setFileName('');
    };
  }, [getFileResponse.data]);

  const onDownload = (file: SecurityInfo) => {
    setFileName(file.filename);
    getFile({path: 'productSecurityInformation', fileName: file.filename});
  };

  const onDelete = (file: SecurityInfo) => {
    deleteProductInformation({language: file.language, id: product._id});
  };

  const syncAllFilesToShopware = (event: any) => {
    syncToShopware({id: product._id});
  };

  return (
    <div style={{padding: '20px'}}>
      {languages.map((language) => {
        const fileExists = product?.securityInfo?.some((file) => file.language === language);

        return (
          !fileExists && (
            <div
              key={language}
              onDrop={(event) => handleDrop(event, language)}
              onDragOver={handleDragOver}
              onDragEnter={handleDragEnter}
              onDragLeave={handleDragLeave}
              style={{
                display: 'flex',
                alignItems: 'center',
                marginBottom: '10px',
                border: '2px dashed #66b2ff',
                borderRadius: '4px',
                padding: '10px',
              }}
            >
              <div style={{flex: 1, fontWeight: 'bold'}}>{language}</div>

              {!uploadedFile[language] && (
                <label htmlFor={`choose-file-${language}`}>
                  <Button
                    variant="contained"
                    color="primary"
                    component="span"
                    className="blue-button"
                    style={{padding: '4px 8px', minWidth: 'auto', marginLeft: 'auto'}}
                  >
                    {t('general.chooseFile')}
                  </Button>
                  <input
                    type="file"
                    accept=".pdf"
                    onChange={(e) => handleFileUpload(e, language)}
                    style={{display: 'none'}}
                    id={`choose-file-${language}`}
                    disabled={uploadFileResponse?.loading}
                  />
                </label>
              )}

              {uploadedFile[language] && (
                <>
                  <TextField
                    value={uploadedFile[language]?.name || ''}
                    fullWidth
                    InputProps={{readOnly: true}}
                    style={{marginRight: '10px', width: '250px'}}
                  />
                  <Button
                    variant="contained"
                    color="primary"
                    className={uploadFileResponse?.loading ? 'inactive-button' : 'blue-button'}
                    onClick={() => uploadImage(language)}
                    style={{padding: '4px 8px', minWidth: 'auto'}}
                    disabled={uploadFileResponse?.loading}
                  >
                    {t('general.uploadFile')}
                  </Button>
                </>
              )}
            </div>
          )
        );
      })}
      {product?.securityInfo?.length ? (
        <>
          <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
            <h4>{t('productDetails.uploadedFiles')}</h4>
            <Button
              className={syncToShopwareRes?.loading ? 'inactive-button' : 'blue-button'}
              disabled={syncToShopwareRes?.loading}
              onClick={syncAllFilesToShopware}
              variant="contained"
              color="primary"
              style={{
                visibility: product?.securityInfo?.some((x) => !x.spMediaId || !x.ddaMediaId) ? 'visible' : 'hidden',
              }}
            >
              {t('productDetails.syncToShopware')}
            </Button>
          </div>
          <Paper style={{maxHeight: '250px', overflowY: 'auto'}}>
            <List>
              {product.securityInfo.map((file, index) => (
                <ListItem key={index} style={{borderBottom: '1px solid #f0f0f0'}}>
                  <ListItemText
                    primary={`${file.language}: ${file.filename}`}
                    secondary={`${t('productDetails.uploadedAt')}: ${Util.convertUTCDateToLocalDate(
                      file.uploadedAt as any,
                    )}`}
                    primaryTypographyProps={{style: {fontWeight: 500}}}
                  />
                  <span key={`dda${file.language}`}>
                    <Checkbox readOnly checked={file.ddaMediaId != null} />
                    {t('productDetails.uploadedToDdaShop')}
                  </span>
                  <span key={`sp${file.language}`}>
                    <Checkbox readOnly checked={file.spMediaId != null} />
                    {t('productDetails.uploadedToSpShop')}
                  </span>
                  <Button className="blue-button" variant="contained" color="primary" onClick={() => onDownload(file)}>
                    {t('productDetails.viewFile')}
                  </Button>
                  <Button className="blue-button" variant="contained" color="secondary" onClick={() => onDelete(file)}>
                    {t('general.delete')}
                  </Button>
                </ListItem>
              ))}
            </List>
          </Paper>
        </>
      ) : (
        <div style={{marginTop: '20px', fontStyle: 'italic'}}>{t('general.noData')}</div>
      )}
    </div>
  );
};

interface Props {
  product: IProduct;
  getProductDetails: (data: any) => void;
}

export default SecurityInfoDetails;
