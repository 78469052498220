import {EventsName, socketFactory} from '../lib/socketFactory';
import {TodoSocketListDto} from '../types/Todo/TodoSocketList';

export const fetchTodoInformation = (userId: string, callback: (todoSocketDto: TodoSocketListDto) => any) => {
  if (!userId) return;

  const onTodoUpdate = (todoSocketDto: TodoSocketListDto) => {
    callback(todoSocketDto);
  };

  socketFactory.emitFetchTodoInformation(userId);
  socketFactory.listenFetchTodoInformation(onTodoUpdate);

  return () => socketFactory.removeEventListener(EventsName.ORDER_MODIFYING);
};
