import {Button, Grid} from '@material-ui/core';
import React, {ReactElement, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {useFetch} from '../../hooks/fetch';
import {appActions} from '../../modules/app/actions';
import AlertDialog from '../AlertDialog/AlertDialog';
import {IProduct} from '../../types/Product/IProduct';

const ProductPrinterIds = (props: IProductPrinterIdsProps): ReactElement => {
  const [bechlemSync, doBechlemSync] = useFetch<any>('products_bechlem_sync');
  const [bechlemAlertDialog, setBechlemAlertDialog] = useState(false);
  const openBechlemDialog = () => setBechlemAlertDialog(true);
  const closeBechlemDialog = () => setBechlemAlertDialog(false);
  const onBechlemSyncClick = () => openBechlemDialog();
  const dispatch = useDispatch();
  const {t} = useTranslation();

  const onBechlemSyncSubmit = async (selectedShops: string[]) => {
    doBechlemSync({productId: props.product._id, shopIds: selectedShops});
    setBechlemAlertDialog(false);
  };

  useEffect(() => {
    if (bechlemSync.data) {
      setBechlemAlertDialog(false);
      dispatch(
        appActions.showSnackBar({
          text: t('productDetails.bechlemSuccessMessage'),
          options: {severity: 'success'},
        }),
      );
    }
    return () => {
      bechlemSync.data = null;
    };
  }, [bechlemSync]);

  return (
    <div className="product-info" style={{border: 'none', boxShadow: 'none'}}>
      <Grid container style={{marginTop: '10px'}}>
        {props.product.printerIds?.split(' + ')?.map((printerId: string, index: number) => {
          return (
            <Grid item xs={6} sm={2} key={index} className="product-info-item">
              <div className="product-info-label">
                {t('productDetails.printerId')} {index + 1}
              </div>
              <div className="product-info-text">{printerId}</div>
            </Grid>
          );
        })}

        <div style={{width: '99%', display: 'flex', justifyContent: 'flex-end'}}>
          <Button variant="contained" className="blue-button" onClick={onBechlemSyncClick}>
            {t('productDetails.syncToShopwareButton')}
          </Button>
        </div>
      </Grid>

      <AlertDialog
        title={t('productDetails.bechlemAlertTitle')}
        description={t('productDetails.bechlemAlertContent')}
        onClose={closeBechlemDialog}
        onOpen={bechlemAlertDialog}
        onSubmit={onBechlemSyncSubmit}
        showShopSelection={true}
      />
    </div>
  );
};

interface IProductPrinterIdsProps {
  product: IProduct;
}

export default ProductPrinterIds;
