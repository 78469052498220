import React, {useEffect} from 'react';
import {modalActions} from '../../modules/modal/actions';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {MODALS} from '../Modal/ModalContents';
import {ManualSupplierArticleModalType} from './ManualSupplierArticleModal';
import {Button} from '@material-ui/core';
import {useFetch} from '../../hooks/fetch';
import {appActions} from '../../modules/app/actions';

const AddNewManualSupplierArticleButton: React.FC<IActionsProps> = (props: any) => {
  const {setTriggerRefreshTable} = props;
  const [createSupplierArticle, doCreateSupplierArticle] = useFetch('add_new_manual_supplier_article');
  const dispatch = useDispatch();
  const handleCancelModal = () => {
    dispatch(modalActions.closeModal());
  };

  useEffect(() => {
    if (createSupplierArticle?.error) {
      dispatch(appActions.showSnackBar({text: createSupplierArticle.error.name, options: {severity: 'error'}}));
    } else if (createSupplierArticle?.data) {
      dispatch(appActions.showSnackBar({text: t('general.success'), options: {severity: 'success'}}));
      setTriggerRefreshTable(Math.random());
    }

    return () => {
      createSupplierArticle.data = null;
      createSupplierArticle.error = null;
      createSupplierArticle.loaded = null;
    };
  }, [createSupplierArticle]);

  const handleSubmitModal = async (values: any) => {
    const data = {...values};
    await doCreateSupplierArticle(data);
    setTriggerRefreshTable(Math.random());
    handleCancelModal();
  };

  const {t} = useTranslation();

  const openAddNewModal = () => {
    dispatch(
      modalActions.addModal(MODALS.MANUAL_SUPPLIER_ARTICLE_MODAL, {
        onSubmit: handleSubmitModal,
        onCancel: handleCancelModal,
        type: ManualSupplierArticleModalType.ADD,
        defaultValues: {},
      }),
    );
  };
  return (
    <Button variant="contained" className="blue-button" onClick={openAddNewModal}>
      {t('manualArticles.addNew')}
    </Button>
  );
};

interface IActionsProps {
  setTriggerRefreshTable: (param: any) => void;
}

export default AddNewManualSupplierArticleButton;
