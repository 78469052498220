import React, {createContext} from 'react';

const SupplierOrderItemsContext = createContext({} as ISupplierOrderItemsContext);
export const SupplierOrderItemsProvider = SupplierOrderItemsContext.Provider;
export default SupplierOrderItemsContext;

interface ISupplierOrderItemsContext {
  supplierOrderItems: any;
  getTotalPurchasePrice: () => void;
  getPendingSupplierOrderItems: () => void;
}
