import React from 'react';
import {ICustomFieldType} from '../../types/CustomFields/CustomField';
import moment from 'moment';
import {Done} from '@material-ui/icons';

export interface ICustomFieldProps {
  type: ICustomFieldType;
  value: any;
}

export const CustomField = (props: ICustomFieldProps) => {
  let date;

  if (props.type === ICustomFieldType.DATE) {
    if (!props.value) {
      date = '';
    } else {
      date = moment(props.value).format('DD.MM.YYYY');
    }
  }
  return (
    <div>
      {props.type === ICustomFieldType.STRING ? <span>{props.value}</span> : null}
      {props.type === ICustomFieldType.NUMBER ? <span>{props.value}</span> : null}
      {props.type === ICustomFieldType.BOOLEAN ? <span>{props.value && <Done className="green" />}</span> : null}
      {props.type === ICustomFieldType.DATE ? <span>{date}</span> : null}
    </div>
  );
};
