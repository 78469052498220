import {LinearProgress} from '@material-ui/core';
import React, {useEffect, useState} from 'react';
import {socketFactory, EventsName} from '../../lib/socketFactory';

const ScanningIndicator = ({row}: any) => {
  const [showIndicator, setShowIndicator] = useState(false);
  const onOrderScanning = ({isActive, orderNumber}: any) => {
    if (orderNumber === row?.orderNumber) {
      setShowIndicator(isActive);
    }
  };

  useEffect(() => {
    socketFactory.listenOnOrderStatusUpdate(onOrderScanning);

    return () => {
      setShowIndicator(false);
      socketFactory.removeEventListener(EventsName.ORDER_STATUS_UPDATE);
    };
  }, [row.orderNumber]);

  socketFactory.emitOrderStatusUpdate(row?.orderNumber);
  return <>{showIndicator ? <LinearProgress /> : ''}</>;
};

export default ScanningIndicator;
