import {Button} from '@material-ui/core';
import React, {ReactElement, useContext, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {useFetch} from '../../../hooks/fetch';
import {modalActions} from '../../../modules/modal/actions';
import {IProduct} from '../../../types/Product/IProduct';
import {MODALS} from '../../Modal/ModalContents';
import _ from 'lodash';
import ProductDetailsContext from '../../../screens/Products/Context/ProductDetailsContext';

const AddMultiboxProductButton = (props: IProductEditButtonProps): ReactElement => {
  const [addMultiBoxProductResponse, addMultiBoxProduct] = useFetch<any>('products_add_multibox');
  const {multiBoxProducts, setNewMultiBox} = useContext(ProductDetailsContext);
  const dispatch = useDispatch();
  const {t} = useTranslation();
  const {product, reloadMultiboxes} = props;

  const handleCancelModal = () => {
    dispatch(modalActions.closeModal());
  };

  const handleSubmitModal = async (values: any) => {
    addMultiBoxProduct({id: product._id, ...values});
    handleCancelModal();
  };

  useEffect(() => {
    if (addMultiBoxProductResponse?.data) {
      reloadMultiboxes();
    }

    return () => {
      addMultiBoxProductResponse.data = null;
      addMultiBoxProductResponse.loaded = null;
      addMultiBoxProductResponse.error = null;
    };
  }, [addMultiBoxProductResponse]);

  const handleClick = async () => {
    dispatch(
      modalActions.addModal(MODALS.ADD_MULTIBOX_PRODUCT, {
        title: t('productDetails.editMultiBox') || '',
        onSubmit: handleSubmitModal,
        onCancel: handleCancelModal,
        product: product,
        passedMultiBoxProducts: multiBoxProducts,
        setMultiBox: setNewMultiBox,
      }),
    );
  };

  return (
    <Button variant="contained" className="blue-button" onClick={handleClick}>
      {t('productDetails.editMultiBox')}
    </Button>
  );
};

interface IProductEditButtonProps {
  product: IProduct;
  reloadMultiboxes: () => void;
}

export default AddMultiboxProductButton;
