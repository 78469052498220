import React, {ReactElement, useContext} from 'react';
import {useTranslation} from 'react-i18next';
import {IProduct} from '../../types/Product/IProduct';
import {Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from '@material-ui/core';
import {Link} from 'react-router-dom';
import {modalActions} from '../../modules/modal/actions';
import {useDispatch} from 'react-redux';
import {MODALS} from '../Modal/ModalContents';
import ProductDetailsContext from '../../screens/Products/Context/ProductDetailsContext';

const MultiBoxProductsTable = (props: IMultiBoxProductsTable): ReactElement => {
  const {multiBoxProducts, multiBox, setNewMultiBox} = useContext(ProductDetailsContext);
  const dispatch = useDispatch();
  const {t} = useTranslation();
  const {product} = props;

  const handleCancelModal = () => {
    dispatch(modalActions.closeModal());
  };

  const handleSubmitModal = async (values: any) => {
    setNewMultiBox(values);
  };

  const handleClick = async () => {
    dispatch(
      modalActions.addModal(MODALS.ADD_MULTIBOX_PRODUCT, {
        title: t('productDetails.editMultiBox') || '',
        onSubmit: handleSubmitModal,
        onCancel: handleCancelModal,
        product: product,
        passedMultiBoxProducts: multiBoxProducts,
        setMultiBox: setNewMultiBox,
      }),
    );
  };

  return (
    <div className="data-table-wrapper" style={{height: 'auto', marginBottom: '20px'}}>
      <div className="data-table-wrapper__action-buttons">
        <div className="data-table-wrapper__title">
          {t('productDetails.multiBoxInfo')}: &nbsp;
          <span>
            {t('productDetails.multiBoxCalcPrice')}:&nbsp;
            {parseFloat(multiBox?.multiBoxPrice)?.toLocaleString('de-DE', {
              style: 'currency',
              currency: 'EUR',
              minimumFractionDigits: 2,
            })}
            &nbsp;
          </span>
          <span>
            {t('productDetails.multiBoxAvgPrice')}:&nbsp;
            {parseFloat(multiBox?.totalMultiBoxAvgPrice)?.toLocaleString('de-DE', {
              style: 'currency',
              currency: 'EUR',
              minimumFractionDigits: 2,
            })}
          </span>
        </div>
      </div>
      <TableContainer className="table-scroll">
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{t('productDetails.product')}</TableCell>
              <TableCell>{t('productDetails.price')}</TableCell>
              <TableCell>{t('productDetails.quantity')}</TableCell>
              <TableCell>{t('productDetails.totalPrice')}</TableCell>
              <TableCell>{t('productDetails.average-price')}</TableCell>
              <TableCell>{t('productDetails.stockDDA')}</TableCell>
              <TableCell>{t('productDetails.cheapestSupplierQuantity')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {multiBoxProducts?.map((multiBoxItem: any) => {
              return (
                <>
                  <TableRow
                    style={{cursor: 'pointer', textDecoration: 'none'}}
                    component={Link}
                    to={`/products/${multiBoxItem.productId}`}
                  >
                    <TableCell>{multiBoxItem.title}</TableCell>
                    <TableCell>
                      {multiBoxItem.cheapestPrice?.toLocaleString('de-DE', {
                        style: 'currency',
                        currency: 'EUR',
                        minimumFractionDigits: 2,
                      })}
                    </TableCell>
                    <TableCell>{multiBoxItem?.numberOfProducts}</TableCell>
                    <TableCell>
                      {multiBoxItem.totalPrice?.toLocaleString('de-DE', {
                        style: 'currency',
                        currency: 'EUR',
                        minimumFractionDigits: 2,
                      })}
                    </TableCell>
                    <TableCell>
                      {multiBoxItem.averagePrice?.toLocaleString('de-DE', {
                        style: 'currency',
                        currency: 'EUR',
                        minimumFractionDigits: 2,
                      })}
                    </TableCell>
                    <TableCell>{multiBoxItem.stockDDA}</TableCell>
                    <TableCell>{multiBoxItem.cheapestSupplierQuantity}</TableCell>
                  </TableRow>
                </>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <div style={{paddingLeft: '8px', marginTop: '8px', marginBottom: '8px'}}>
        <Button variant="contained" color="primary" onClick={handleClick}>
          {t('productDetails.editMultiBox')}
        </Button>
      </div>
    </div>
  );
};

interface IMultiBoxProductsTable {
  product: IProduct;
}

export default MultiBoxProductsTable;
