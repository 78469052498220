import React, {ReactElement} from 'react';
import {useTranslation} from 'react-i18next';
import {IHeader} from '../../../Table/interfaces/IHeader';
import DataTable from '../../../Table/Table';
import {DataTableItemType} from '../../../Table/interfaces/IHeaderAccessor';

const SectionProductOrdersTable = ({showTitle, title, productId, customerId}: IProps): ReactElement => {
  const {t} = useTranslation();

  const headers: IHeader[] = [
    {
      kind: 'accessor',
      name: t('orders.orderNumber'),
      accessor: 'orderNumber',
    },
    {
      kind: 'accessor',
      name: t('orders.date'),
      accessor: 'createdAt',
      sortable: true,
      type: DataTableItemType.DATE,
      timeZone: 'Europe/Berlin',
    },
    {
      kind: 'accessor',
      name: t('orderItems.quantity'),
      accessor: 'quantity',
    },
    {
      kind: 'accessor',
      name: t('translation:orderItems.priceNet'),
      accessor: 'priceNet',
    },
    {
      kind: 'accessor',
      name: t('TOTAL'),
      accessor: 'totalNet',
    },
  ];
  if (showTitle) {
    headers.splice(1, 0, {
      kind: 'accessor',
      name: t('products.title'),
      accessor: 'name',
    });
  }
  return (
    <DataTable
      title={title}
      className="orders__table"
      headers={headers}
      endpoint={'detailed_orders_by_product_id'}
      target={'orders'}
      tableHeight="420px"
      options={{product: productId, customer: customerId}}
      defaultSort={{key: 'createdAt', value: -1}}
      storlessFetch={true}
      hideFilters={true}
    />
  );
};

interface IProps {
  productId: string;
  customerId: string;
  showTitle?: boolean;
  title?: string;
}

export default SectionProductOrdersTable;
