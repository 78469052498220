import React, {createContext} from 'react';

const ProductsWithoutStockContext = createContext({} as IProps);
export const ProductsWithoutStockProvider = ProductsWithoutStockContext.Provider;
export default ProductsWithoutStockContext;

interface IProps {
  supplierOrderItems: any;
  fetchSupplierOrderItems: () => void;
}
