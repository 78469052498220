import React from 'react';

const CompleteTaskButton = () => {
  return (
    <svg className="todo-icon" strokeLinecap="round" strokeLinejoin="round">
      {/* Circle */}
      <circle cx="12" cy="12" r="11" />

      {/* Checkmark - hidden initially and shown on hover */}
      <path
        id="checkmark"
        d="m8 13 2.165 2.165a1 1 0 0 0 1.521-.126L16 9"
        fill="none"
        className="todo-icon-checkmark"
      />
    </svg>
  );
};

export default CompleteTaskButton;
