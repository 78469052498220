import {TableRow, TableCell, IconButton, TextField, Button} from '@material-ui/core';
import {KeyboardArrowUp, KeyboardArrowDown, Clear} from '@material-ui/icons';
import React, {ReactElement, useContext, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {Link} from 'react-router-dom';
import {modalActions} from '../../modules/modal/actions';
import {APP_ROUTES} from '../../routers/routes';
import {MODALS} from '../Modal/ModalContents';
import SupplierOrderItemsTableBodyContext from './Context/SupplierOrterItemsTableBodyContext';
import SupplierOrderItemsSubTable from './SupplierOrderItemsSubTable';
import TogglePurchaseList from './TogglePurchaseList';

const SupplierOrderItemsTableRow = (props: ISupplierOrderItemsTableRowProps): ReactElement => {
  const {supplierOrderItem, orderItemsData, setOrderItemsData, doSupplierOrderItemDelete, doSupplierOrderItemPut} =
    useContext(SupplierOrderItemsTableBodyContext);
  const [quantity, setQuantity] = useState(supplierOrderItem.quantity);
  const handleCancelModal = () => dispatch(modalActions.closeModal());
  const [price, setPrice] = useState(supplierOrderItem.price);
  const {setExpandedRow, expandedRow} = props;
  const {t} = useTranslation();
  const dispatch = useDispatch();

  const handleDeleteItem = async (id: string) => {
    dispatch(
      modalActions.addModal(MODALS.CONFIRM_DIALOG, {
        title: t('general.deleteModalTitle') || '',
        content: t('general.deleteConfirmation'),
        onYes: () => deleteButtonHandler(id),
        onNo: handleCancelModal,
      }),
    );
  };

  const deleteButtonHandler = async (id: string) => {
    const newState = [...orderItemsData];
    const supplierOrderItemIndex = newState.findIndex((supplierOrderItem) => supplierOrderItem._id === id);

    if (supplierOrderItemIndex > -1) {
      newState.splice(supplierOrderItemIndex, 1);
      setOrderItemsData(newState);
      doSupplierOrderItemDelete({id});
    }

    handleCancelModal();
  };

  const handleExpandRow = () => {
    if (expandedRow !== supplierOrderItem._id) {
      setExpandedRow(supplierOrderItem._id);
    } else {
      setExpandedRow('');
    }
  };

  const onClickSaveSupplierOrderItem = () => {
    const newState = [...orderItemsData];
    const updatedSupplierOrderItem = newState.find((item) => item._id === supplierOrderItem._id);

    if (updatedSupplierOrderItem) {
      updatedSupplierOrderItem.price = price;
      updatedSupplierOrderItem.quantity = quantity;
      setOrderItemsData(newState);
    }

    doSupplierOrderItemPut({
      supplier: supplierOrderItem.supplier,
      supplierName: supplierOrderItem.supplierName,
      quantity,
      price,
      externalArticleId: supplierOrderItem.externalArticleId,
      id: supplierOrderItem._id,
    });
  };

  const onChangeQuantity = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void =>
    setQuantity(+e.target.value);

  const onChangePrice = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void =>
    setPrice(+e.target.value);

  return (
    <React.Fragment>
      <TableRow key={supplierOrderItem._id}>
        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={handleExpandRow}>
            {expandedRow === supplierOrderItem._id ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          <Link
            className="link"
            style={{display: 'flex'}}
            to={`${APP_ROUTES.PRODUCTS}/${supplierOrderItem?.product?._id}`}
            target="_blank"
          >
            <p> {supplierOrderItem.productTitle}</p>
          </Link>
        </TableCell>
        <TableCell>{supplierOrderItem.supplierName}</TableCell>
        <TableCell>{supplierOrderItem.externalArticleId}</TableCell>
        <TableCell>
          <TextField
            type="number"
            InputLabelProps={{
              shrink: true,
            }}
            style={{width: 80, marginRight: 20}}
            InputProps={{inputProps: {min: 0, step: 1}}}
            value={quantity}
            onChange={onChangeQuantity}
          />
        </TableCell>
        <TableCell>
          <TextField
            type="number"
            InputLabelProps={{
              shrink: true,
            }}
            style={{width: 80, marginRight: 20}}
            InputProps={{inputProps: {min: 0, step: 0.1}}}
            value={price}
            onChange={onChangePrice}
          />
        </TableCell>
        <TableCell>
          <TogglePurchaseList
            key={supplierOrderItem._id}
            purchase={supplierOrderItem.purchaseList}
            id={supplierOrderItem._id}
          />
        </TableCell>
        <TableCell style={{overflow: 'visible'}}>
          <span style={{display: 'inline-flex'}}>
            <Button variant="contained" className="blue-button" onClick={onClickSaveSupplierOrderItem}>
              {t('general.save')}
            </Button>
            <IconButton onClick={() => handleDeleteItem(supplierOrderItem._id)}>
              <Clear fontSize="small" style={{fill: 'red'}}></Clear>
            </IconButton>
          </span>
        </TableCell>
      </TableRow>
      <SupplierOrderItemsSubTable open={expandedRow === supplierOrderItem._id} setPrice={setPrice} />
    </React.Fragment>
  );
};

interface ISupplierOrderItemsTableRowProps {
  expandedRow: any;
  setExpandedRow: (data: any) => void;
}

export default SupplierOrderItemsTableRow;
