import _ from 'lodash';
import React, {useRef, useCallback} from 'react';
import {useDispatch} from 'react-redux';
import {modalActions} from '../../modules/modal/actions';
import {BaseModalState} from '../../modules/modal/reducer';
import {modals, MODALS} from './ModalContents';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

interface ModalBaseProps {
  modalData: BaseModalState;
  child?: boolean;
}

const ModalBase = ({modalData, child}: ModalBaseProps) => {
  const node = useRef(null);
  const dispatch = useDispatch();
  const modalKey = modalData.modalKey;
  const modalProps = _.get(modalData, `props`, {}) as any;
  //@ts-ignore
  const Contents = modalKey ? modals[modalKey] : (): null => null;
  const isModalVisible = modalKey !== MODALS.NONE;

  const closeIconClick = () => {
    const key = child ? modalKey : undefined;
    dispatch(modalActions.closeModal(key));
  };

  return (
    <>
      {isModalVisible && (
        <div
          id="modalContainer"
          className="modal"
          ref={node}
          style={{opacity: isModalVisible ? 1 : 0, zIndex: 300}}
          // onClick={closeModal}
        >
          <div id="modalContent" className="modal-content" style={{position: 'relative'}}>
            <div className="modal-close-button" style={{position: 'absolute', top: '10px', right: '10px', zIndex: 1}}>
              {!modalProps.hideDefaultCloseButton && (
                <IconButton onClick={closeIconClick}>
                  <CloseIcon />
                </IconButton>
              )}
            </div>

            <Contents {...modalProps} />
          </div>
        </div>
      )}
    </>
  );
};

export {ModalBase};
