import {toast, ToastOptions} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

class NotificationHelper {
  private static defaultOptions: ToastOptions = {
    position: 'bottom-right',
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  };

  static showSuccess(message: string, options: ToastOptions = {}) {
    toast.success(message, {...NotificationHelper.defaultOptions, ...options});
  }

  static showError(message: string, options: ToastOptions = {}) {
    toast.error(message, {...NotificationHelper.defaultOptions, ...options});
  }

  static showWarning(message: string, options: ToastOptions = {}) {
    toast.warn(message, {...NotificationHelper.defaultOptions, ...options});
  }

  static showInfo(message: string, options: ToastOptions = {}) {
    toast.info(message, {...NotificationHelper.defaultOptions, ...options});
  }
}

export default NotificationHelper;
