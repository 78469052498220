import {CircularProgress, List, ListItem, ListItemText, Typography} from '@material-ui/core';
import React, {useEffect, useState} from 'react';
import Form from '../Form/Form';
import _ from 'lodash';
import {useTranslation} from 'react-i18next';
import {IEBayRequiredField} from '../../screens/Pricing/Ebay/EbayCategories';
import {useStorlessFetch} from '../../hooks/fetch';
import NotificationHelper from '../../lib/notificationHelper';
import {Divider} from 'antd';

export interface IEditEbayCategoryModalProps {
  row?: any;
  title: string;
  onSubmit: (values: any) => void;
  onCancel: () => void;
}

const generateInitialValues = (fields: any[], dataObject: any) => {
  if (dataObject) {
    return dataObject;
  } else {
    let defaults = {};
    fields.forEach((field) => {
      defaults = {...defaults, [field.fieldKey]: ''};
    });
    return defaults;
  }
};

export default function EditEbayCategoryModal(props: IEditEbayCategoryModalProps) {
  const {title, onSubmit, onCancel, row} = props;
  const [initialValues, setInitialValues] = useState(null);
  const [fieldsData, getRequiredFields] = useStorlessFetch('ebay_required_fields');
  const [requiredFields, setRequiredFields] = useState<IEBayRequiredField[]>([]);
  const {t} = useTranslation();

  const handleCategoryChange = (event: React.ChangeEvent<any>, value: any, setFieldValue: any) => {
    if (value) {
      getRequiredFields({id: value});
    }
  };

  useEffect(() => {
    const data = generateInitialValues(fields, row);
    setInitialValues(data);
  }, [row]);

  useEffect(() => {
    if (fieldsData?.data) {
      setRequiredFields(fieldsData.data);
    } else {
      setRequiredFields([]);
    }

    if (fieldsData?.error) {
      NotificationHelper.showError(t('general.error'));
    }

    return () => {
      fieldsData.data = null;
      fieldsData.error = null;
    };
  }, [fieldsData]);

  const fields = [
    {
      label: t('pricing.excludeFromPricing'),
      placeholder: t('pricing.excludeFromPricing'),
      fieldKey: 'excludeFromPricing',
      type: 'checkbox',
    },
    {
      label: t('pricing.categoryId'),
      placeholder: t('pricing.categoryId'),
      fieldKey: 'categoryId',
      type: 'autocomplete',
      options: [],
      endpoint: 'ebay_category_autocomplete_list',
      defaultValue: row?.categoryId || '',
      onChange: handleCategoryChange,
    },
  ] as any;

  return (
    <div>
      <Typography className="modal-title">{title}</Typography>
      {initialValues ? (
        <>
          <>
            {fieldsData?.loading ? (
              <CircularProgress />
            ) : (
              <>
                {requiredFields.length > 0 ? (
                  <div style={{margin: '0px 25px'}}>
                    <Typography variant="h6">{t('Required Fields for Category')}</Typography>
                    <List>
                      {requiredFields.map((field) => (
                        <ListItem key={field.name}>
                          <ListItemText primary={`${field.name} (${field.dataType}, ${field.cardinality})`} />
                        </ListItem>
                      ))}
                    </List>
                  </div>
                ) : (
                  requiredFields?.length === 0 && (
                    <Typography variant="body1" style={{margin: '20px 20px 0px 30px'}}>
                      {t('No required fields for this category')}
                    </Typography>
                  )
                )}
              </>
            )}
            <Divider style={{margin: '0px 20px'}} />
          </>
          <Form
            initialValues={initialValues}
            fields={fields}
            submitButtonText={t('general.submit')}
            cancelButtonText={t('general.cancel')}
            onSubmit={onSubmit}
            onCancel={onCancel}
            minWidth="800px"
            isLoading={fieldsData?.loading}
          ></Form>
        </>
      ) : null}
    </div>
  );
}
