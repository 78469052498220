import {ENDPOINTS} from '../endpoints';
import _ from 'lodash';
interface IReducer {
  data: any;
  loading: boolean;
  loaded: boolean;
  error: 400 | 401 | 404 | 402 | null;
}
const initApi = () => {
  const reducers = {};
  Object.keys(ENDPOINTS).forEach((endpointKey) => {
    const reducer: IReducer = {
      data: null,
      loaded: false,
      loading: false,
      error: null,
    };

    Object.assign(reducers, {[_.camelCase(endpointKey)]: reducer});
  });

  return reducers;
};

export {initApi};
