import React, {ReactElement, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {TableContainer, TableHead, TableRow, TableCell, TableBody, Table, Button} from '@material-ui/core';
import {IOrder} from '../../types/Orders/IOrder';
import {useStorlessFetch} from '../../hooks/fetch';
import UploadReturnImagesComponent from './OrderActions/UploadReturnImagesComponent';

const ReturnOrderImages = ({order}: IProps): ReactElement => {
  const [returnImageResponse, getReturnImage] = useStorlessFetch('orders_return_image');
  const {t} = useTranslation();

  useEffect(() => {
    if (returnImageResponse.data) {
      const blob = new Blob([new Uint8Array(returnImageResponse.data.data).buffer], {type: 'image/png'});
      const blobURL = URL.createObjectURL(blob);
      window.open(blobURL);
    }

    return () => {
      returnImageResponse.data = null;
    };
  }, [returnImageResponse.data]);

  return (
    <div
      className="data-table-wrapper"
      style={{height: 'auto', maxHeight: '400px', marginBottom: '20px', overflow: 'auto'}}
    >
      <UploadReturnImagesComponent />
      <TableContainer className="table-scroll">
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{t('orderDetails.fileName')}</TableCell>
              <TableCell>{t('orderDetails.comment')}</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {order.returnOrderImages?.map((item: any) => (
              <TableRow key={item}>
                <TableCell>{item.fileName}</TableCell>
                <TableCell>{item.comment || ''}</TableCell>
                <TableCell>
                  <Button
                    variant="contained"
                    className="blue-button"
                    onClick={() => {
                      getReturnImage({fileName: item.fileName});
                    }}
                  >
                    {t('general.view')}
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

interface IProps {
  order: IOrder;
}

export default ReturnOrderImages;
