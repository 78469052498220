import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Button, TextField} from '@material-ui/core';
import {appActions} from '../../modules/app/actions';
import {useDispatch} from 'react-redux';
import {useStorlessFetch} from '../../hooks/fetch';
import {modalActions} from '../../modules/modal/actions';
import {MODALS} from '../Modal/ModalContents';
import Form, {IField} from '../Form/Form';

export const ManufacturerImagesModal = ({
  item,
  onSubmit,
  onCancel,
}: {
  item: any;
  onSubmit: () => void;
  onCancel: () => void;
}) => {
  const {t} = useTranslation();
  const fields: IField[] = [
    {
      fieldKey: 'companyName',
      label: t('general.companyName'),
      type: 'input',
    },
    {
      fieldKey: 'street',
      label: t('general.street'),
      type: 'input',
    },
    {
      fieldKey: 'zip',
      label: t('general.zip'),
      type: 'input',
    },
    {
      fieldKey: 'city',
      label: t('general.city'),
      type: 'input',
    },
    {
      fieldKey: 'country',
      label: t('general.country'),
      type: 'input',
    },
    {
      fieldKey: 'mail',
      label: t('general.mail'),
      type: 'input',
    },
    {
      fieldKey: 'motherCompany',
      label: t('general.motherCompany'),
      type: 'input',
    },
  ];

  return (
    <Form
      title={''}
      initialValues={item}
      fields={fields}
      submitButtonText={t('general.submit')}
      cancelButtonText={t('general.cancel')}
      onSubmit={onSubmit}
      onCancel={onCancel}
    ></Form>
  );
};
