import {Button, MenuItem, Select} from '@material-ui/core';
import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {MODALS} from '../../../components/Modal/ModalContents';
import DataTable from '../../../components/Table/Table';
import {useStorlessFetch, useStorlessUpload} from '../../../hooks/fetch';
import {modalActions} from '../../../modules/modal/actions';
import {appActions} from '../../../modules/app/actions';
import {ProductStatus} from '../../../types/Product/ProductStatus.enum';
import {IProduct} from '../../../types/Product/IProduct';
import _ from 'lodash';
import withRoleGuard from '../../../guards/Role-Guard';
import {USER_ROLE} from '../../../types/User/UserRole';
import {ShopType} from '../../../types/Orders/ShopType';
import {useSelection} from '../../../hooks/selection';
import {useProductHeaders} from './ProductHeaders';

const Products = () => {
  const dispatch = useDispatch();
  const [changeProductStatusRes, changeProductStatus] = useStorlessFetch('update_products_shop_status');
  const [createProductResponse, createProduct] = useStorlessFetch('products_post');
  const [csvFileResponse, getCsvFile] = useStorlessFetch('products_export_csv');
  const [uploadFile, doUploadFile] = useStorlessUpload('upload_optimized');
  const selectionKey = 'PRODUCTS_SELECTION_KEY';
  const [selectedValues, setSelectedValues] = useSelection(selectionKey);
  const [shopStatus, setShopStatus] = useState({shopName: null, status: null});
  const [product, setProduct] = useState<IProduct>(null as any);
  const [imageUrl, setImageUrl] = useState(null as any);
  const [refreshData, setRefreshData] = useState(false);
  const {t} = useTranslation();

  useEffect(() => {
    if (csvFileResponse.data) {
      const url = window.URL.createObjectURL(new Blob([csvFileResponse.data]));
      const link: HTMLAnchorElement = document.createElement('a');
      link.href = url;
      link.style.display = 'none';
      link.setAttribute('download', `products.csv`);

      document.body.appendChild(link);
      link.click();
      link?.parentNode?.removeChild(link);
    }

    return () => {
      csvFileResponse.data = null;
    };
  }, [csvFileResponse]);

  useEffect(() => {
    if (createProductResponse?.data) {
      dispatch(appActions.showSnackBar({text: t('general.submitFormText'), options: {severity: 'success'}}));
      setRefreshData((prevState: boolean) => !prevState);
    } else if (createProductResponse?.error) {
      dispatch(appActions.showSnackBar({text: createProductResponse.error.name, options: {severity: 'error'}}));
    }

    return () => {
      createProductResponse.data = null;
      createProductResponse.error = null;
    };
  }, [createProductResponse]);

  useEffect(() => {
    if (changeProductStatusRes?.data) {
      dispatch(appActions.showSnackBar({text: t('general.submitFormText'), options: {severity: 'success'}}));
      setRefreshData((prevState: boolean) => !prevState);
    } else if (changeProductStatusRes?.error) {
      dispatch(appActions.showSnackBar({text: changeProductStatusRes.error.name, options: {severity: 'error'}}));
    }

    return () => {
      changeProductStatusRes.data = null;
      changeProductStatusRes.error = null;
    };
  }, [changeProductStatusRes]);

  useEffect(() => {
    if (uploadFile.data) {
      setImageUrl(uploadFile.data);
    }

    return () => {
      uploadFile.data = null;
      uploadFile.loaded = null;
      uploadFile.error = null;
    };
  }, [uploadFile]);

  useEffect(() => {
    if (product) {
      createProduct({...product, productInformation: {...product.productInformation, image: imageUrl}});
    }
  }, [imageUrl]);

  const handleCancelModal = () => {
    dispatch(modalActions.closeModal());
  };

  const handleApproveArticle = async (values: any) => {
    const data = _.omit({...values}, 'inMultiBoxProductIds') as any;
    setProduct(data);

    if (data.productInformation?.image) {
      doUploadFile({file: data.productInformation.image});
    } else {
      setImageUrl('');
    }

    handleCancelModal();
  };

  const AddNewButton = () => (
    <Button
      variant="contained"
      className="blue-button"
      startIcon={<img src="/icons/plus.webp" />}
      onClick={() => {
        dispatch(
          modalActions.addModal(MODALS.CREATE_PRODUCT, {
            title: t('addNewArticle.screenTitle') || '',
            onSubmit: handleApproveArticle,
            onCancel: handleCancelModal,
          }),
        );
      }}
    >
      {t('manualArticles.addNew')}
    </Button>
  );

  const AddButtonWithGuard = withRoleGuard([USER_ROLE.ADMIN, USER_ROLE.STANDARD], AddNewButton, false);
  const onStatusChange = (e: any, fieldName: string) => {
    const newValue = e.target.value as ProductStatus;
    setShopStatus((prevState: any) => ({...prevState, [fieldName]: newValue}));
  };

  const shopNames = [
    'All',
    ShopType.DRUCKDICHAUS,
    ShopType.SUPPLIES_PARTNER,
    ShopType.EBAY,
    ShopType.KAUFLAND,
    ShopType.GALAXUS,
    ShopType.METRO,
    ShopType.FNAC,
    ShopType.AMAZON,
  ];
  const shopStatuses = [
    'All',
    ProductStatus.ACTIVE,
    ProductStatus.DEACTIVATED,
    ProductStatus.END_OF_LIFE,
    ProductStatus.EXPIRED,
  ];

  const changeStatusHandler = async (e: any) => {
    e.stopPropagation();

    dispatch(
      modalActions.addChild(MODALS.CHANGE_PRODUCT_STATUS_MODAL, {
        onYes: (state: {productIds: []; shopName: ShopType; status: ProductStatus}) => {
          state.productIds = selectedValues;
          changeProductStatus({...state});
          dispatch(modalActions.closeModal());
        },
        onNo: () => {
          dispatch(modalActions.closeModal());
        },
      }),
    );
  };

  return (
    <div className="products">
      <DataTable
        className="products__table"
        headers={useProductHeaders()}
        refreshData={refreshData}
        endpoint={'products_get_all_shop_statuses'}
        defaultSort={{key: 'productId', valsue: 1}}
        isCsvLoading={csvFileResponse?.loading}
        title={t('products.screenTitle')}
        enableBackendCsvExport={true}
        selectionKey={selectionKey}
        enableExportToCsv={true}
        canShowCheckbox={true}
        onExport={getCsvFile}
        target={'products'}
        actionButtons={
          <>
            <AddButtonWithGuard />
            <Button variant={'contained'} className={'blue-button'} onClick={changeStatusHandler}>
              {t('products.changeProductStatus')}
            </Button>
            <Select
              id="shopName"
              value={shopStatus?.shopName}
              onChange={(e) => onStatusChange(e, 'shopName')}
              style={{marginLeft: '10px', padding: '5px'}}
              variant="standard"
              className="yellow-button"
              defaultValue="All"
            >
              {shopNames.map((x: any) => (
                <MenuItem key={x} value={x !== 'All' ? x : null}>
                  {x}
                </MenuItem>
              ))}
            </Select>
            <Select
              id="status"
              value={shopStatus?.status}
              onChange={(e) => onStatusChange(e, 'status')}
              style={{marginLeft: '10px', padding: '5px'}}
              variant="standard"
              className="yellow-button"
              defaultValue="All"
            >
              {shopStatuses.map((x: any) => (
                <MenuItem key={x} value={x !== 'All' ? x : null}>
                  {x}
                </MenuItem>
              ))}
            </Select>
          </>
        }
        options={{...shopStatus}}
      />
    </div>
  );
};

export default Products;
