import React, {ReactElement, useContext} from 'react';
import {useTranslation} from 'react-i18next';
import DataTable from '../Table/Table';
import {OrderActionType} from '../../types/ActivityLog/OrderActionType';
import CreateSupplierOrderContext from '../../screens/SupplierOrders/Context/CreateSupplierOrderContext';
import {DataTableItemType} from '../Table/interfaces/IHeaderAccessor';

const SupplierOrderActivityLogTable = (): ReactElement => {
  const {t} = useTranslation();
  const {supplierOrderDetails, orderId} = useContext(CreateSupplierOrderContext);

  return (
    <div className="order-details__table-wrapper">
      <DataTable
        className="order-details__table"
        headers={[
          {
            kind: 'accessor',
            name: t('activityLog.actionType'),
            accessor: 'actionType',
            type: DataTableItemType.SELECT,
            options: ([{value: 'all', label: 'All'}] as any).concat(
              Object.values(OrderActionType).map((item) => {
                return {value: item, label: t(`activityLog.${item}`)};
              }),
            ),
            translationPrefixKey: 'activityLog',
            sortable: true,
          },
          {
            kind: 'accessor',
            name: t('productDetails.title'),
            accessor: 'data.product.title',
            sortable: true,
          },
          {
            kind: 'accessor',
            name: t('activityLog.quantity'),
            accessor: 'data.quantity',
            sortable: true,
          },
          {
            kind: 'accessor',
            name: t('activityLog.createdAt'),
            accessor: 'createdAt',
            sortable: true,
            type: DataTableItemType.DATE,
          },
        ]}
        endpoint={'activity_log_list_with_products'}
        options={{'data.supplierOrder': orderId}}
        defaultSort={{key: 'createdAt', value: -1}}
        refreshData={supplierOrderDetails?.loaded}
      />
    </div>
  );
};

export default SupplierOrderActivityLogTable;
