import React, {createContext} from 'react';
import {IProduct} from '../../../types/Product/IProduct';
import {ICustomField} from '../../../types/CustomFields/CustomField';

const ProductDetailsContext = createContext({} as IProductDetailsContext);
export const ProductDetailsProvider = ProductDetailsContext.Provider;
export default ProductDetailsContext;

interface IProductDetailsContext {
  product: IProduct;
  updateProductState: (value: any, fieldName: string, nestedObjectName?: string) => void;
  getProductDetails: (data: any) => void;
  onStockUpdate: () => void;
  customFields: ICustomField[];
  multiBoxProducts: any[];
  multiBox: any;
  setNewMultiBox: (data: any) => void;
}
