import React, {useEffect, useState} from 'react';
import {Button} from '@material-ui/core';
import {useTranslation} from 'react-i18next';
import DataTable from '../../components/Table/Table';
import {OrderItemType} from '../../types/OrderItems/OrderItemType';
import {useFetch} from '../../hooks/fetch';
import {useDispatch} from 'react-redux';
import {appActions} from '../../modules/app/actions';
import Spinner from '../../components/Spinner/Spinner';
import {DataTableItemType} from '../../components/Table/interfaces/IHeaderAccessor';

const OrderItemsWithoutProduct = () => {
  const [resolvedOrderItems, resolveOrderItems] = useFetch('order_items_resolve');
  const {t} = useTranslation();
  const [isResolving, setIsResolving] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (resolvedOrderItems.data !== null) {
      const numberOfResolvedItems: number = resolvedOrderItems.data;
      setIsResolving(false);
      resolvedOrderItems.data = null;
      dispatch(
        appActions.showSnackBar({
          text: `${t('orderItemsWithoutProduct.successMessage')}: ${numberOfResolvedItems}`,
          options: {severity: 'success'},
        }),
      );
    }

    if (resolvedOrderItems.error) {
      setIsResolving(false);
      dispatch(appActions.showSnackBar({text: resolvedOrderItems.errors, options: {severity: 'error'}}));
    }

    return () => {
      resolvedOrderItems.data = null;
      resolvedOrderItems.error = null;
    };
  }, [resolvedOrderItems.data]);

  useEffect(() => {
    if (isResolving) {
      resolveOrderItems();
    }
  }, [isResolving]);

  const handleResolveOrderItemsButton = () => {
    setIsResolving(true);
  };

  return (
    <div className="orders">
      <DataTable
        className="orders__table"
        headers={[
          {
            kind: 'accessor',
            name: t('orderDetails.orderNumber'),
            accessor: 'orderNumber',
            sortable: true,
          },
          {
            kind: 'accessor',
            name: t('orderItems.name'),
            accessor: 'name',
            sortable: true,
          },
          {
            kind: 'accessor',
            name: t('orderItems.sku'),
            accessor: 'sku',
            sortable: true,
          },
          {
            kind: 'accessor',
            name: t('orderItems.quantity'),
            accessor: 'quantity',
            sortable: true,
            type: 'number',
          },
          {
            kind: 'accessor',
            name: t('orderItems.priceGross'),
            accessor: 'priceGross',
            sortable: true,
            format: 'price',
            type: 'number',
          },
          {
            kind: 'accessor',
            name: t('orderItems.note'),
            accessor: 'note',
            sortable: true,
          },
          {
            kind: 'accessor',
            name: t('orderDetails.date'),
            accessor: 'createdAt',
            sortable: true,
            type: DataTableItemType.DATE,
          },
        ]}
        endpoint={'order_items'}
        options={{product: null, type: {$in: [OrderItemType.product0, OrderItemType.product1]}}}
        target={`orders`}
        selectRowClickId={'order'}
        defaultSort={{key: 'createdAt', value: -1}}
        refreshData={resolvedOrderItems}
        title={t('general.orderItemsWithoutProduct')}
        actionButtons={
          <Button
            variant="contained"
            className="blue-button"
            onClick={handleResolveOrderItemsButton}
            disabled={isResolving}
          >
            {t('orderItemsWithoutProduct.resolveButton')}
          </Button>
        }
      />
      {isResolving && <Spinner />}
    </div>
  );
};

export default OrderItemsWithoutProduct;
