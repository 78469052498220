import {Button} from '@material-ui/core';
import React, {ReactElement} from 'react';
import {useTranslation} from 'react-i18next';
import {modalActions} from '../../../modules/modal/actions';
import {MODALS} from '../../Modal/ModalContents';
import {useDispatch} from 'react-redux';
import {useStorlessFetch} from '../../../hooks/fetch';

const SupplierOrderSendEmailButton = ({
  supplierOrderId,
  email,
  createOrder,
}: ISupplierOrderSendEmailButtonProps): ReactElement => {
  const [sendEmail, doSendEmail] = useStorlessFetch('supplier_orders_send_email');
  const {t} = useTranslation();
  const dispatch = useDispatch();

  const handleClick = async () => {
    dispatch(
      modalActions.addModal(MODALS.SEND_EMAIL_COMPONENT, {
        title: t('supplierOrderDetails.sendEmailButton') || '',
        onSubmit: handleSubmitModal,
        onCancel: handleCancelModal,
        email,
      }),
    );
  };

  const handleCancelModal = () => {
    dispatch(modalActions.closeModal());
  };

  const handleSubmitModal = async (values: any) => {
    if (!createOrder) {
      doSendEmail({...values, supplierOrderId, id: supplierOrderId});
    }

    handleCancelModal();
  };

  return (
    <Button variant="contained" className="blue-button" onClick={handleClick}>
      {t('supplierOrderDetails.sendEmailButton')}
    </Button>
  );
};

interface ISupplierOrderSendEmailButtonProps {
  supplierOrderId: any;
  createOrder: boolean;
  email?: string;
}

export default SupplierOrderSendEmailButton;
