import {Button} from '@material-ui/core';
import React, {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {MODALS} from '../../components/Modal/ModalContents';
import DataTable from '../../components/Table/Table';
import {useFetch, useStorlessFetch} from '../../hooks/fetch';
import {appActions} from '../../modules/app/actions';
import {modalActions} from '../../modules/modal/actions';
import {useSupplierOrderHeaders} from '../Supplier/SupplierOrderHeaders';

const SupplierOrders = () => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const [supplierOrder, postSupplierOrder] = useFetch<any>('supplier_custom_order_create');
  const [getInvoicesRes, getInvoices] = useFetch<any>('supplier_order_invoices');
  const [mergeSupplierOrdersRes, mergeSupplierOrders] = useStorlessFetch('merge_supplier_orders');
  const [createServiceOrderRes, createServiceOrder] = useFetch<any>('create_service_order');
  const [refresh, setRefresh] = React.useState(false);
  const [getPricesPerMonthRes, getPricesPerMonth] = useStorlessFetch('supplier_order_prices_per_month');

  useEffect(() => {
    if (supplierOrder?.error) {
      dispatch(appActions.showSnackBar({text: supplierOrder.error.name, options: {severity: 'error'}}));
    } else if (supplierOrder?.data) {
      dispatch(appActions.showSnackBar({text: t('supplierOrderItems.orderCreated'), options: {severity: 'success'}}));
    }

    return () => {
      supplierOrder.data = null;
      supplierOrder.error = null;
      supplierOrder.loaded = null;
    };
  }, [supplierOrder]);

  useEffect(() => {
    if (mergeSupplierOrdersRes?.error) {
      dispatch(appActions.showSnackBar({text: mergeSupplierOrdersRes.error.name, options: {severity: 'error'}}));
    } else if (mergeSupplierOrdersRes?.data) {
      dispatch(appActions.showSnackBar({text: t('general.success'), options: {severity: 'success'}}));
    }

    return () => {
      mergeSupplierOrdersRes.data = null;
      mergeSupplierOrdersRes.error = null;
      mergeSupplierOrdersRes.loaded = null;
    };
  }, [mergeSupplierOrdersRes]);

  useEffect(() => {
    if (createServiceOrderRes?.error) {
      dispatch(appActions.showSnackBar({text: createServiceOrderRes.error.name, options: {severity: 'error'}}));
    } else if (createServiceOrderRes?.data) {
      dispatch(appActions.showSnackBar({text: t('general.success'), options: {severity: 'success'}}));
    }

    return () => {
      createServiceOrderRes.data = null;
      createServiceOrderRes.error = null;
      createServiceOrderRes.loaded = null;
    };
  }, [createServiceOrderRes]);

  const handleClick = async () => {
    dispatch(
      modalActions.addModal(MODALS.ADD_SUPPLIER_ORDER, {
        title: t('supplierOrders.addButton') || '',
        onSubmit: handleSubmitModal,
        onCancel: handleCancelModal,
        isLoading: supplierOrder?.loading,
      }),
    );
  };

  const handleCancelModal = () => {
    dispatch(modalActions.closeModal());
  };

  const handleSubmitModal = async (values: any) => {
    const data = {...values};
    await postSupplierOrder(data);
    handleCancelModal();
  };

  const downloadFile = async (data: any, fileName = 'invoices.zip') => {
    const url = window.URL.createObjectURL(new Blob([data]));
    const link = document.createElement('a');

    link.setAttribute('download', fileName);
    link.href = url;

    document.body.appendChild(link);

    link.click();
    link.remove();
  };

  const handleMergeSupplierOrders = () => {
    dispatch(
      modalActions.addModal(MODALS.MERGE_SUPPLIER_ORDERS_MODAL, {
        onCancel: () => {
          dispatch(modalActions.closeModal());
        },
        onSubmit: (fields: any) => {
          mergeSupplierOrders({...fields});
        },
      }),
    );
  };

  const handleScanShippingLabel = () => {
    dispatch(
      modalActions.addModal(MODALS.SUPPLIER_ORDER_LABEL_SCANNER, {
        onClose: () => {
          dispatch(modalActions.closeModal());
        },
        refresh: (value: boolean) => {
          setRefresh(value);
        },
      }),
    );
  };

  const handleAddServiceOrder = () => {
    dispatch(
      modalActions.addModal(MODALS.ADD_SERVICE_ORDER, {
        onSubmit: (values: any) => {
          createServiceOrder(values);
        },
        onCancel: () => {
          dispatch(modalActions.closeModal());
        },
      }),
    );
  };

  useEffect(() => {
    if (getInvoicesRes.data) {
      downloadFile(getInvoicesRes.data);
    }

    return () => {
      getInvoicesRes.data = null;
      getInvoicesRes.error = null;
      getInvoicesRes.loaded = null;
      getInvoicesRes.loading = null;
    };
  }, [getInvoicesRes?.data]);

  useEffect(() => {
    if (getInvoicesRes.error) {
      if (getInvoicesRes.error instanceof Blob) {
        const reader = new FileReader();
        reader.onload = () => {
          try {
            const json = JSON.parse(reader.result as string);
            dispatch(appActions.showSnackBar({text: json.error, options: {severity: 'error'}}));
          } catch (e) {
            console.error('Error parsing JSON', e);
            dispatch(appActions.showSnackBar({text: 'Unknown error', options: {severity: 'error'}}));
          }
        };
        reader.readAsText(getInvoicesRes.error);
      } else {
        dispatch(appActions.showSnackBar({text: getInvoicesRes.error.name, options: {severity: 'error'}}));
      }
    }

    return () => {
      getInvoicesRes.data = null;
      getInvoicesRes.error = null;
      getInvoicesRes.loaded = null;
      getInvoicesRes.loading = null;
    };
  }, [getInvoicesRes]);

  useEffect(() => {
    if (getPricesPerMonthRes.data) {
      downloadFile(getPricesPerMonthRes.data, 'supplier_order_prices_per_month.csv');
    }

    return () => {
      getPricesPerMonthRes.data = null;
      getPricesPerMonthRes.error = null;
      getPricesPerMonthRes.loaded = null;
      getPricesPerMonthRes.loading = null;
    };
  }, [getPricesPerMonthRes?.data]);

  return (
    <div className="orders">
      <DataTable
        className="orders__table"
        headers={useSupplierOrderHeaders()}
        endpoint={'supplier_orders'}
        target={'supplierOrders'}
        additionalRowClickId="mergedToOrder"
        defaultSort={{key: 'createdAt', value: -1}}
        refreshData={supplierOrder?.data || mergeSupplierOrdersRes?.data || createServiceOrderRes?.data || refresh}
        title={t('supplierOrders.screenTitle')}
        actionButtons={
          <>
            <Button disabled={supplierOrder.loading} variant="contained" className="blue-button" onClick={handleClick}>
              {t('supplierOrders.addButton')}
            </Button>
            <Button
              disabled={mergeSupplierOrdersRes.loading}
              variant="contained"
              className="blue-button"
              onClick={handleMergeSupplierOrders}
            >
              {t('supplierOrders.mergeSupplierOrders')}
            </Button>
            <Button
              disabled={supplierOrder.loading}
              variant="contained"
              className="blue-button"
              onClick={handleAddServiceOrder}
            >
              {t('supplierOrders.addServiceOrder')}
            </Button>
            <Button variant="contained" className="blue-button" onClick={handleScanShippingLabel}>
              {t('supplierOrders.scanShippingLabel')}
            </Button>
          </>
        }
        queryButtons={[
          {
            name: t('supplierOrders.downloadInvoices'),
            callback: (data: any) => {
              if (!data?.filters?.['invoice.date']) {
                dispatch(
                  modalActions.addChild(MODALS.CONFIRM_DIALOG, {
                    title: t('general.info'),
                    content: t('supplierOrders.invoiceUploadDateRange'),
                    onOk: () => {
                      dispatch(modalActions.closeModal());
                    },
                  }),
                );
              }
              data?.filters?.['invoice.date'] && getInvoices({...data?.filters});
            },
            isLoading: getInvoicesRes?.loading,
          },
          {
            name: t('supplierOrders.downloadPricesPerMonthReport'),
            callback: (data: any) => {
              if (!data?.filters?.['deliveredAt']) {
                dispatch(
                  modalActions.addChild(MODALS.CONFIRM_DIALOG, {
                    title: t('general.info'),
                    content: t('supplierOrders.deliveredAtDateRange'),
                    onOk: () => {
                      dispatch(modalActions.closeModal());
                    },
                  }),
                );
              }
              data?.filters?.['deliveredAt'] && getPricesPerMonth({...data?.filters});
            },
            isLoading: getInvoicesRes?.loading,
          },
        ]}
      />
    </div>
  );
};

export default SupplierOrders;
