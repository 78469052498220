import React from 'react';
import {useTranslation} from 'react-i18next';
import ScanningIndicator from '../../components/Order/ScanningIndicator';
import DataTable from '../../components/Table/Table';
import {OrderStatusType} from '../../types/Orders/OrderStatusType';
import {ShopType} from '../../types/Orders/ShopType';
import {ShippingProviderType} from '../../types/Shipment/ShippingProviderType';
import {DataTableItemType} from '../../components/Table/interfaces/IHeaderAccessor';
import {Table, TableBody, TableCell, TableHead, TableRow} from '@material-ui/core';

export interface IOrdersReadyForShippingCustom {
  listName?: string;
  match?: any;
  isProductList?: boolean;
}

export interface ITitleComponentProps {
  additionalData: any;
}

export interface INeededProduct {
  id: string;
  name: string;
  count: number;
  stock: number;
}

const OrdersReadyForShippingCustom = (props: IOrdersReadyForShippingCustom) => {
  const isProductList = !!props.isProductList;
  const name = props.listName;
  const {t} = useTranslation();

  const getTitle = () => {
    if (isProductList) {
      return `${t('general.readyForShipping')}`;
    } else {
      return `${name} ${t('general.readyForShipping')}`;
    }
  };

  const HeaderTitleComponent = ({additionalData}: ITitleComponentProps) => {
    return (
      <div style={{marginBottom: '15px'}}>
        <div className="custom-orders-summary">
          <div className="custom-orders-summary-title">{t('orders.productsNeeded')}</div>

          <Table size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>
                <TableCell>{t('productDetails.product')}</TableCell>
                <TableCell align="right">{t('orders.productsNeeded')}</TableCell>
                <TableCell align="right">{t('general.stock')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {additionalData?.orderSummary?.map((item: INeededProduct) => (
                <TableRow key={item.id}>
                  <TableCell component="th" scope="row">
                    <a href={`/products/${item.id}`} target="_blank" rel="noreferrer">
                      {item.name}
                    </a>
                  </TableCell>
                  <TableCell align="right">{item.count}</TableCell>
                  <TableCell align="right">{item.stock}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      </div>
    );
  };

  return (
    <div className="orders" style={{height: 'unset'}}>
      <DataTable
        className="orders__table"
        headers={[
          {
            kind: 'accessor',
            name: t('orders.orderNumber'),
            accessor: 'orderNumber',
            sortable: true,
          },
          {
            kind: 'accessor',
            name: t('productDetails.product'),
            accessor: 'firstOrderItemTitle',
            sortable: true,
          },
          {
            kind: 'accessor',
            name: t('orders.shippingType'),
            accessor: 'shippingType',
            sortable: true,
            type: DataTableItemType.SELECT,
            options: ([{value: 'all', label: 'All'}] as any).concat(
              Object.values(ShippingProviderType).map((item) => {
                return {value: item, label: item};
              }),
            ),
          },
          {
            kind: 'accessor',
            name: t('orders.shopName'),
            accessor: 'shopName',
            sortable: true,
            type: DataTableItemType.SELECT,
            options: ([{value: 'all', label: 'All'}] as any).concat(
              Object.values(ShopType).map((item) => {
                return {value: item, label: item};
              }),
            ),
          },
          {
            kind: 'accessor',
            name: t('orders.date'),
            accessor: 'date',
            sortable: true,
            type: DataTableItemType.DATE,
            timeZone: 'Europe/Berlin',
          },
          {
            kind: 'accessor',
            name: t('orderDetails.paymentMethodName'),
            accessor: 'paymentMethodName',
            sortable: true,
          },
          {
            kind: 'accessor',
            name: t('orderDetails.orderTotalSum'),
            accessor: 'orderTotalSum',
            sortable: true,
            type: 'number',
          },
          {
            kind: 'accessor',
            name: t('orders.customerNumber'),
            accessor: 'customerNumber',
            sortable: true,
          },
          {
            kind: 'accessor',
            name: t('orderDetails.customerName'),
            accessor: 'shippingAddress.customerName',
            sortable: true,
          },
          {
            kind: 'component',
            name: t('scanner.somebodyScanning'),
            component: ScanningIndicator,
          },
        ]}
        endpoint={'orders_ready_for_shipping_custom'}
        target={'orders'}
        defaultSort={{key: 'date', value: 1}}
        queryParams={`scanOrder=1&listName=${name}&isProductList=${isProductList}`}
        additionalFields={{listName: name, isProductList}}
        options={{
          status: {$nin: [OrderStatusType.Offer, OrderStatusType.PendingOffer]},
        }}
        title={getTitle()}
        aboveTableComponent={HeaderTitleComponent}
      />
    </div>
  );
};

export default OrdersReadyForShippingCustom;
