import {FormLabel} from '@material-ui/core';
import {KeyboardDatePicker} from '@material-ui/pickers';
import {MaterialUiPickersDate} from '@material-ui/pickers/typings/date';
import moment from 'moment';
import React, {Fragment, useState} from 'react';

interface IPickerDate {
  from: MaterialUiPickersDate;
  to: MaterialUiPickersDate;
}
interface IDateRangePicker {
  id: string | string[];
  placeholder: string | undefined;
  autoOk: boolean;
  label: string;
  format: string;
  InputAdornmentProps?: IObject;
  variant: 'dialog' | 'inline' | 'static';
  inputVariant: 'outlined' | 'standard' | 'filled';
  onChange: (selectedDate: IPickerDate) => void;
}

interface ISelectedDate {
  from: Date;
  to: Date;
}
const DateRangePicker = (props: IDateRangePicker) => {
  const {id, onChange, label} = props;
  const [selectedDate, setSelectedDate] = useState<ISelectedDate>({from: new Date(), to: new Date()});
  return (
    <Fragment>
      <FormLabel>{label}</FormLabel>
      <KeyboardDatePicker
        {...props}
        label={'From'}
        id={`${id}From`}
        name={`${id}From`}
        value={selectedDate.from}
        onChange={(date) => {
          if (moment(date) > moment(selectedDate.to)) {
            selectedDate.to = selectedDate.from;
          }
          setSelectedDate({from: moment(date).toDate(), to: selectedDate.to});
          onChange({from: date, to: selectedDate.to});
        }}
      />

      <KeyboardDatePicker
        {...props}
        label={'To'}
        id={`${id}To`}
        name={`${id}To`}
        value={selectedDate.to}
        minDate={selectedDate.from || new Date()}
        onChange={(date: MaterialUiPickersDate) => {
          setSelectedDate({from: selectedDate.from, to: moment(date).toDate()});
          onChange({from: selectedDate.from, to: date});
        }}
      />
    </Fragment>
  );
};
export default DateRangePicker;
