import {Typography} from '@material-ui/core';
import React from 'react';
import Form from '../../components/Form/Form';
import _ from 'lodash';
import {useTranslation} from 'react-i18next';

export interface IEditSupplierArticle {
  title: string;
  onSubmit: (values: any) => void;
  onCancel: () => void;
  articleObject?: any;
}

const generateInitialValues = (fields: any[], articleObject: any) => {
  if (articleObject) {
    const defaults = _.omit(articleObject, ['_id', '__v', 'supplier', 'product', 'history']);
    return defaults;
  } else {
    let defaults = {};
    fields.forEach((field) => {
      defaults = {...defaults, [field.fieldKey]: ''};
    });
    return defaults;
  }
};

const EditSupplierArticle = (props: IEditSupplierArticle) => {
  const {title, onSubmit, onCancel, articleObject} = props;
  const {t} = useTranslation();

  const fields = [
    {
      label: t('editArticle.externalArticleId'),
      placeholder: t('editArticle.externalArticleId'),
      fieldKey: 'externalArticleId',
      type: 'input',
    },
    {
      label: t('editArticle.articlePrice'),
      placeholder: t('editArticle.articlePrice'),
      fieldKey: 'price',
      type: 'input',
      fieldKeyType: 'number',
    },
    {
      label: t('editArticle.articleQuantity'),
      placeholder: t('editArticle.articleQuantity'),
      fieldKey: 'quantity',
      type: 'input',
      fieldKeyType: 'number',
    },
  ];
  const initialValues = generateInitialValues(fields, articleObject);
  return (
    <>
      <Typography className="modal-title">{title}</Typography>
      <Form
        initialValues={initialValues}
        fields={fields}
        submitButtonText={t('general.submit')}
        cancelButtonText={t('general.cancel')}
        onSubmit={onSubmit}
        onCancel={onCancel}
      ></Form>
    </>
  );
};

export default EditSupplierArticle;
