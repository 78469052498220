import {Button} from '@material-ui/core';
import _ from 'lodash';
import React, {ReactElement, useContext, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import CreateSupplierOrderContext from '../../screens/SupplierOrders/Context/CreateSupplierOrderContext';
import {ISupplierOrderItem} from '../../types/SupplierOrders/ISupplierOrderItem';
import SupplierOrderItemsEditTable from './SupplierOrderItemsEditTable';
import SupplierOrderItemsViewTable from './SupplierOrderItemsViewTable';

const SupplierOrderItemsTable = (): ReactElement => {
  const {state, setState, supplierOrderItem, supplierOrderDetails, supplierOrderItemDelete} =
    useContext(CreateSupplierOrderContext);
  const handleEditButton = () => setState((prevState: any) => ({...prevState, edit: !state.edit}));
  const {t} = useTranslation();

  useEffect(() => {
    setState((prevState: any) => ({...prevState, toggleRefreshTable: !prevState.toggleRefreshTable}));
  }, [state.edit, supplierOrderItem, supplierOrderItemDelete, supplierOrderDetails]);

  return (
    <div>
      {supplierOrderDetails?.data?.status !== 'MERGED' && (
        <Button variant="contained" className="blue-button" onClick={handleEditButton}>
          {state.edit ? t('general.list') : t('general.edit')}
        </Button>
      )}

      {!state.edit ? <SupplierOrderItemsViewTable /> : <SupplierOrderItemsEditTable />}
    </div>
  );
};

export interface ISupplierOrderItemsState {
  supplierOrderItems: ISupplierOrderItem[];
  initialSupplierOrderItems: ISupplierOrderItem[];
  isEdited: boolean;
  edit: boolean;
  toggleRefreshTable: boolean;
  supplierOrderItemsToUpdate: ISupplierOrderItem[];
}

export default SupplierOrderItemsTable;
