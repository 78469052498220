import FilterBase from '../Filters/FilterBase';
import {FILTERS} from '../Filters/filters';
import ConfirmDialog, {IConfirmDialog} from '../ConfirmDialog/ConfirmDialog';
import NewProductForm, {INewProductForm} from '../Product/NewProductForm';
import Menu, {IMenuProps} from '../Menu/Menu';
import {Test} from '../Test';
import EditSupplierArticle, {IEditSupplierArticle} from '../SupplierArticle/EditSupplierArticle';
import EditOrder, {IEditOrder} from '../Order/EditOrder';
import EditCustomer, {IEditCustomer} from '../Customer/EditCustomer';
import SelectProduct, {ISelectProduct} from '../Product/SelectProduct';
import {IPieDetails, PieDetails} from '../../screens/Home/Home';
import EditCountryShippingProvider, {
  IEditCountryShippingProvider,
} from '../CountryShippingProvider/EditCountryShippingProvider';
import ViewShipmentLabel, {IViewShipmentLabel} from '../Order/ViewShipmentLabel';
import EditUpsConfig, {IEditUpsConfig} from '../ShipmentConfig/UpsConfig/EditUpsConfig';
import EditDhlConfig, {IEditDhlConfig} from '../ShipmentConfig/DhlConfig/EditDhlConfig';
import EditOrderProblemType, {IEditOrderProblemType} from '../Order/OrderProblemType/EditOrderProblemType';
import AddOrderProblem, {IAddOrderProblem} from '../Order/OrderProblem/AddOrderProblem';
import CustomerReturnOrderScannerModal, {
  ICustomerReturnOrderScannerModal,
} from '../Scanner/CustomerReturnOrderScannerModal';
import {RunSyncMessage} from '../../screens/SyncStatuses/SyncStatus';
import SupplierSyncStatus, {ISupplierSyncStatus} from '../Supplier/SupplierSyncStatus';
import NewSupplier, {INewSupplier} from '../../screens/Supplier/NewSupplier';
import EditSupplierOrderItem, {IEditSupplierOrderItem} from '../SupplierOrder/EditSupplierOrderItem';
import SupplierOrderScannerModal, {ISupplierOrderScannerModal} from '../Scanner/SupplierOrderScannerModal';
import {IEditAmazonConfig, EditAmazonConfig} from '../Pricing/EditAmazonConfig';
import EditCompanyConfig, {IEditCompanyConfig} from '../CompanyConfig/EditCompanyConfig';
import CustomerOrderScannerModal, {
  ICustomerOrderScanner as ICustomerOrderScannerModal,
} from '../Scanner/CustomerOrderScannerModal';
import AlertDialog, {IAlertDialogProps} from '../AlertDialog/AlertDialog';
import AddSupplierOrder, {IAddSupplierOrder} from '../SupplierOrder/AddSupplierOrder';
import AddSupplierOrderItemModal, {IAddSupplierOrderItem} from '../SupplierOrderItems/AddSupplierOrderItemModal';
import AddProductQuantityRequirementModal, {
  IAddProductQuantityRequirementModal,
} from '../Product/AddProductQuantityRequirementModal';
import ManualSupplierArticleModal, {
  IManualSupplierArticleModalProps,
} from '../SupplierArticle/ManualSupplierArticleModal';
import NewCustomer, {INewCustomer} from '../Customer/NewCustomer';
import ShippingAddressModal, {IShippingAddressModal} from '../Order/CreatingOrders/ShippingAddressModal';
import SendEmailComponent, {ISendEmailComponent} from '../SendEmailComponent';
import AddProductToCustomListModal, {IAddProductToCustomListModal} from '../Order/AddProductToCustomListModal';
import EditShopwareShippingData, {IEditShopwareShippingData} from '../Pricing/EditShopwareShippingData';
import DeliveryConfirmationModal, {IDeliveryConfirmationModalProps} from '../Order/DeliveryConfirmationModal';
import FinishOrderManuallyModal, {IFinishOrderManuallyModal} from '../Order/FinishOrderManuallyModal';
import EditUser, {IEditUserProps} from '../Users/EditUser';
import SupplierOrderBacklogModal, {
  ISupplierOrderBacklogModalProps,
} from '../SupplierOrder/SupplierOrderBacklog/SupplierOrderBacklogModal';
import SupplierOrderFromBacklogModal, {
  ISupplierOrderFromBacklogModalProps,
} from '../SupplierOrder/SupplierOrderBacklog/SupplierOrderFromBacklogModal';
import EditSupplierOrder, {IEditSupplierOrderProps} from '../SupplierOrder/EditSupplierOrder';
import ProductScanner, {IProductScannerProps} from '../Scanner/ProductScanner';
import ShippingProviderModal, {IShippingProviderModalProps} from '../Order/ShippingProviderModal';
import MergeSupplierOrdersModal, {IMergeSupplierOrdersModalProps} from '../SupplierOrder/MergeSupplierOrdersModal';
import AddMultiboxProductModal, {IAddMultiboxModalProps} from '../Product/AddMultiboxProductModal';
import EditOrderDueDateModal, {IEditOrderDueDateModalProps} from '../Order/EditOrderDueDateModal';
import AddProductToComplaintModal, {IAddProductToComplaintModalProps} from '../Product/AddProductToComplaintModal';
import PreviousComplaintExportsModal, {
  IPreviousComplaintExportsModalProps,
} from '../Supplier/PreviousComplaintExportsModal';
import CreateCreditNoteModal, {ICreateCreditNoteFormProps} from '../Order/CreateCreditNoteModal';
import AddComentToProductModal, {IAddCommentToProductModalProps} from '../Product/AddCommentToProductModal';
import ChangeSupplierOnCustomerComplaintModal, {
  IChangeSupplierOnCustomerComplaintModalProps,
} from '../Order/ChangeSupplierOnCustomerComplaintModal';
import SecondLabelModal from '../Modals/SecondLabelModal';
import ReturnLabelModal from '../Modals/ReturnLabelModal';
import EditSupplierComplaint, {IEditSupplierComplaintProps} from '../SupplierComplaints/EditSupplierComplaint';
import AddChangeLogModal, {IAddChangeLogModalProps} from '../ChangeLog/AddChangeLogModal';
import CreateExchangeOrderModal, {ICreateExchangeOrderModalProps} from '../Order/CreateExchangeOrderModal';
import CreateCustomField, {ICreateCustomFieldProps} from '../CustomFields/CreateCustomField';
import ChangeProductStatusModal, {IChangeProductStatusModalProps} from '../Modals/ChangeProductStatusModal';
import AddServiceOrder, {IAddServiceOrderProps} from '../SupplierOrder/AddServiceOrder';
import SupplierOrderLabelScanner, {ISupplierOrderScannerProps} from '../Scanner/SupplierOrderLabelScanner';
import CreateExchangeOrderInvoiceModal, {
  ICreateExchangeOrderInvoiceModalProps,
} from '../Order/CreateExchangeOrderInvoiceModal';
import LabelScannerModal, {ILabelScannerProps} from '../Scanner/LabelScannerModal';
import EditEbayCategoryModal, {IEditEbayCategoryModalProps} from '../Modals/EditEbayCategoryModal';
import UpdateShopFactorsModal, {IUpdateShopFactorsModalProps} from '../Modals/UpdateShopFactorsModal';
import EditCronConfigModal from '../../screens/SyncStatuses/EditCronConfigModal';
import {ManufacturerImagesModal} from '../Product/ManufacturerImagesModal';

export enum MODALS {
  NONE = 'NONE',
  TEST = 'TEST',
  CREATE_PRODUCT = 'CREATE_PRODUCT',
  SELECT_PRODUCT = 'SELECT_PRODUCT',
  CONFIRM_DIALOG = 'CONFIRM_DIALOG',
  MENU = 'MENU',
  FILTER = 'FILTER',
  EDIT_SUPPLIER_ARTICLE = 'EDIt_SUPPLIER_ARTICLE',
  NEW_CUSTOMER = 'NEW_CUSTOMER',
  EDIT_CUSTOMER = 'EDIT_CUSTOMER',
  EDIT_ORDER = 'EDIT_ORDER',
  PIE_DETAILS = 'PIE_DETAILS',
  EDIT_COUNTRY_SHIPPING_PROVIDER_DETAILS = 'EDIT_COUNTRY_SHIPPING_PROVIDER_DETAILS',
  VIEW_SHIPMENT_LABEL = 'VIEW_SHIPMENT_LABEL',
  EDIT_UPS_CONFIG = 'EDIT_UPS_CONFIG',
  EDIT_DHL_CONFIG = 'EDIT_DHL_CONFIG',
  EDIT_ORDER_PROBLEM_TYPE = 'EDIT_ORDER_PROBLEM_TYPE',
  ADD_ORDER_PROBLEM = 'ADD_ORDER_PROBLEM',
  RUN_SYNC_MESSAGE = 'RUN_SYNC_MESSAGE',
  SUPPLIER_SYNC_STATUS = 'SUPPLIER_SYNC_STATUS',
  NEW_SUPPLIER = 'NEW_SUPPLIER',
  EDIT_SUPPLIER_ORDER_ITEM = 'EDIT_SUPPLIER_ORDER_ITEM',
  CUSTOMER_RETURN_ORDER_SCANNER_MODAL = 'CUSTOMER_RETURN_ORDER_SCANNER_MODAL',
  SUPPLIER_ORDER_SCANNER_MODAL = 'SUPPLIER_ORDER_SCANNER_MODAL',
  EDIT_AMAZON_CONFIG = 'EDIT_AMAZON_CONFIG',
  EDIT_COMPANY_CONFIG = 'EDIT_COMPANY_CONFIG',
  CUSTOMER_ORDER_SCANNER_MODAL = 'CUSTOMER_ORDER_SCANNER_MODAL',
  ALERT_DIALOG = 'ALERT_DIALOG',
  ADD_SUPPLIER_ORDER = 'ADD_SUPPLIER_ORDER',
  SEND_EMAIL_COMPONENT = 'SEND_EMAIL_COMPONENT',
  ADD_SUPPLIER_ORDER_ITEM = 'ADD_SUPPLIER_ORDER_ITEM',
  ADD_PRODUCT_QUANTITY_REQUIREMENT_MODAL = 'ADD_PRODUCT_QUANTITY_REQUIREMENT_MODAL',
  MANUAL_SUPPLIER_ARTICLE_MODAL = 'MANUAL_SUPPLIER_ARTICLE_MODAL',
  SHIPPING_ADDRESS_MODAL = 'SHIPPING_ADDRESS_MODAL',
  ADD_PRODUCT_TO_CUSTOM_LIST = 'ADD_PRODUCT_TO_CUSTOM_LIST',
  EDIT_SHOP_SHIPPING_DATA = 'EDIT_SHOP_SHIPPING_DATA',
  DELIVERY_CONFIRMATION = 'DELIVERY_CONFIRMATION',
  FINISH_ORDER = 'FINISH_ORDER',
  EDIT_USER = 'EDIT_USER',
  SUPPLIER_ORDER_BACKLOG_MODAL = 'SUPPLIER_ORDER_BACKLOG_MODAL',
  SUPPLIER_ORDER_FROM_BACKLOG_MODAL = 'SUPPLIER_ORDER_FROM_BACKLOG_MODAL',
  EDIT_SUPPLIER_ORDER = 'EDIT_SUPPLIER_ORDER',
  PRODUCT_SCANNER = 'PRODUCT_SCANNER',
  SHIPPING_PROVIDER_MODAL = 'SET_SHIPPING_PROVIDER',
  MERGE_SUPPLIER_ORDERS_MODAL = 'MERGE_SUPPLIER_ORDERS_MODAL',
  ADD_MULTIBOX_PRODUCT = 'ADD_MULTIBOX_PRODUCT',
  EDIT_ORDER_DUE_DATE = 'EDIT_ORDER_DUE_DATE',
  ADD_PRODUCT_TO_COMPLAINT = 'ADD_PRODUCT_TO_COMPLAINT',
  PREVIOUS_COMPLAINT_EXPORTS = 'PREVIOUS_COMPLAINT_EXPORTS',
  CREATE_CREDIT_NOTE_MODAL = 'CREATE_CREDIT_NOTE_FORM',
  ADD_COMMENT_TO_PRODUCT = 'ADD_COMMENT_TO_PRODUCT',
  CHANGE_SUPPLIER_ON_CUSTOMER_COMPLAINT = 'CHANGE_SUPPLIER_ON_CUSTOMER_COMPLAINT',
  SECOND_LABEL_MODAL = 'SECOND_LABEL_MODAL',
  RETURN_LABEL_MODAL = 'RETURN_LABEL_MODAL',
  EDIT_SUPPLIER_COMPLAINT = 'EDIT_SUPPLIER_COMPLAINT',
  ADD_CHANGE_LOG = 'ADD_CHANGE_LOG',
  CREATE_EXCHANGE_ORDER = 'CREATE_EXCHANGE_ORDER',
  CREATE_CUSTOM_FIELD = 'CREATE_CUSTOM_FIELD',
  CHANGE_PRODUCT_STATUS_MODAL = 'CHANGE_PRODUCT_STATUS_MODAL',
  ADD_SERVICE_ORDER = 'ADD_SERVICE_ORDER',
  SUPPLIER_ORDER_LABEL_SCANNER = 'SUPPLIER_ORDER_LABEL_SCANNER',
  EXCHANGE_ORDER_INVOICE = 'EXCHANGE_ORDER_INVOICE',
  LABEL_SCANNER = 'LABEL_SCANNER',
  EDIT_EBAY_CATEGORY = 'EDIT_EBAY_CATEGORY',
  UPDATE_SHOP_FACTORS = 'UPDATE_SHOP_FACTORS',
  EDIT_CRON_CONFIG_MODAL = 'EDIT_CRON_CONFIG_MODAL',
  MANUFACTURER_IMAGES = 'MANUFACTURER_IMAGES',
}

export const modals = {
  [MODALS.NONE]: (__: IObject): null => null,
  [MODALS.TEST]: Test,
  [MODALS.CREATE_PRODUCT]: NewProductForm,
  [MODALS.SELECT_PRODUCT]: SelectProduct,
  [MODALS.CONFIRM_DIALOG]: ConfirmDialog,
  [MODALS.MENU]: Menu,
  [MODALS.FILTER]: FilterBase,
  [MODALS.EDIT_SUPPLIER_ARTICLE]: EditSupplierArticle,
  [MODALS.EDIT_CUSTOMER]: EditCustomer,
  [MODALS.NEW_CUSTOMER]: NewCustomer,
  [MODALS.EDIT_COUNTRY_SHIPPING_PROVIDER_DETAILS]: EditCountryShippingProvider,
  [MODALS.EDIT_ORDER]: EditOrder,
  [MODALS.PIE_DETAILS]: PieDetails,
  [MODALS.VIEW_SHIPMENT_LABEL]: ViewShipmentLabel,
  [MODALS.EDIT_UPS_CONFIG]: EditUpsConfig,
  [MODALS.EDIT_DHL_CONFIG]: EditDhlConfig,
  [MODALS.EDIT_ORDER_PROBLEM_TYPE]: EditOrderProblemType,
  [MODALS.ADD_ORDER_PROBLEM]: AddOrderProblem,
  [MODALS.RUN_SYNC_MESSAGE]: RunSyncMessage,
  [MODALS.SUPPLIER_SYNC_STATUS]: SupplierSyncStatus,
  [MODALS.NEW_SUPPLIER]: NewSupplier,
  [MODALS.EDIT_SUPPLIER_ORDER_ITEM]: EditSupplierOrderItem,
  [MODALS.CUSTOMER_RETURN_ORDER_SCANNER_MODAL]: CustomerReturnOrderScannerModal,
  [MODALS.SUPPLIER_ORDER_SCANNER_MODAL]: SupplierOrderScannerModal,
  [MODALS.EDIT_AMAZON_CONFIG]: EditAmazonConfig,
  [MODALS.EDIT_COMPANY_CONFIG]: EditCompanyConfig,
  [MODALS.CUSTOMER_ORDER_SCANNER_MODAL]: CustomerOrderScannerModal,
  [MODALS.ALERT_DIALOG]: AlertDialog,
  [MODALS.ADD_SUPPLIER_ORDER]: AddSupplierOrder,
  [MODALS.SEND_EMAIL_COMPONENT]: SendEmailComponent,
  [MODALS.ADD_SUPPLIER_ORDER_ITEM]: AddSupplierOrderItemModal,
  [MODALS.ADD_PRODUCT_QUANTITY_REQUIREMENT_MODAL]: AddProductQuantityRequirementModal,
  [MODALS.MANUAL_SUPPLIER_ARTICLE_MODAL]: ManualSupplierArticleModal,
  [MODALS.SHIPPING_ADDRESS_MODAL]: ShippingAddressModal,
  [MODALS.ADD_PRODUCT_TO_CUSTOM_LIST]: AddProductToCustomListModal,
  [MODALS.EDIT_SHOP_SHIPPING_DATA]: EditShopwareShippingData,
  [MODALS.DELIVERY_CONFIRMATION]: DeliveryConfirmationModal,
  [MODALS.FINISH_ORDER]: FinishOrderManuallyModal,
  [MODALS.EDIT_USER]: EditUser,
  [MODALS.SUPPLIER_ORDER_BACKLOG_MODAL]: SupplierOrderBacklogModal,
  [MODALS.SUPPLIER_ORDER_FROM_BACKLOG_MODAL]: SupplierOrderFromBacklogModal,
  [MODALS.EDIT_SUPPLIER_ORDER]: EditSupplierOrder,
  [MODALS.PRODUCT_SCANNER]: ProductScanner,
  [MODALS.SHIPPING_PROVIDER_MODAL]: ShippingProviderModal,
  [MODALS.MERGE_SUPPLIER_ORDERS_MODAL]: MergeSupplierOrdersModal,
  [MODALS.ADD_MULTIBOX_PRODUCT]: AddMultiboxProductModal,
  [MODALS.EDIT_ORDER_DUE_DATE]: EditOrderDueDateModal,
  [MODALS.ADD_PRODUCT_TO_COMPLAINT]: AddProductToComplaintModal,
  [MODALS.PREVIOUS_COMPLAINT_EXPORTS]: PreviousComplaintExportsModal,
  [MODALS.CREATE_CREDIT_NOTE_MODAL]: CreateCreditNoteModal,
  [MODALS.ADD_COMMENT_TO_PRODUCT]: AddComentToProductModal,
  [MODALS.CHANGE_SUPPLIER_ON_CUSTOMER_COMPLAINT]: ChangeSupplierOnCustomerComplaintModal,
  [MODALS.SECOND_LABEL_MODAL]: SecondLabelModal,
  [MODALS.RETURN_LABEL_MODAL]: ReturnLabelModal,
  [MODALS.EDIT_SUPPLIER_COMPLAINT]: EditSupplierComplaint,
  [MODALS.ADD_CHANGE_LOG]: AddChangeLogModal,
  [MODALS.CREATE_EXCHANGE_ORDER]: CreateExchangeOrderModal,
  [MODALS.CREATE_CUSTOM_FIELD]: CreateCustomField,
  [MODALS.CHANGE_PRODUCT_STATUS_MODAL]: ChangeProductStatusModal,
  [MODALS.ADD_SERVICE_ORDER]: AddServiceOrder,
  [MODALS.SUPPLIER_ORDER_LABEL_SCANNER]: SupplierOrderLabelScanner,
  [MODALS.EXCHANGE_ORDER_INVOICE]: CreateExchangeOrderInvoiceModal,
  [MODALS.LABEL_SCANNER]: LabelScannerModal,
  [MODALS.EDIT_EBAY_CATEGORY]: EditEbayCategoryModal,
  [MODALS.UPDATE_SHOP_FACTORS]: UpdateShopFactorsModal,
  [MODALS.EDIT_CRON_CONFIG_MODAL]: EditCronConfigModal,
  [MODALS.MANUFACTURER_IMAGES]: ManufacturerImagesModal,
};

export interface ModalProps {
  [MODALS.NONE]: IObject;
  [MODALS.TEST]: {text: string};
  [MODALS.CREATE_PRODUCT]: INewProductForm;
  [MODALS.SELECT_PRODUCT]: ISelectProduct;
  [MODALS.CONFIRM_DIALOG]: IConfirmDialog;
  [MODALS.MENU]: IMenuProps[];
  [MODALS.FILTER]: {elements: IFilterConfig; filterKey: FILTERS};
  [MODALS.EDIT_SUPPLIER_ARTICLE]: IEditSupplierArticle;
  [MODALS.NEW_CUSTOMER]: INewCustomer;
  [MODALS.EDIT_CUSTOMER]: IEditCustomer;
  [MODALS.EDIT_ORDER]: IEditOrder;
  [MODALS.PIE_DETAILS]: IPieDetails;
  [MODALS.EDIT_COUNTRY_SHIPPING_PROVIDER_DETAILS]: IEditCountryShippingProvider;
  [MODALS.VIEW_SHIPMENT_LABEL]: IViewShipmentLabel;
  [MODALS.EDIT_UPS_CONFIG]: IEditUpsConfig;
  [MODALS.EDIT_DHL_CONFIG]: IEditDhlConfig;
  [MODALS.EDIT_ORDER_PROBLEM_TYPE]: IEditOrderProblemType;
  [MODALS.ADD_ORDER_PROBLEM]: IAddOrderProblem;
  [MODALS.RUN_SYNC_MESSAGE]: IObject;
  [MODALS.SUPPLIER_SYNC_STATUS]: ISupplierSyncStatus;
  [MODALS.NEW_SUPPLIER]: INewSupplier;
  [MODALS.EDIT_SUPPLIER_ORDER_ITEM]: IEditSupplierOrderItem;
  [MODALS.CUSTOMER_RETURN_ORDER_SCANNER_MODAL]: ICustomerReturnOrderScannerModal;
  [MODALS.SUPPLIER_ORDER_SCANNER_MODAL]: ISupplierOrderScannerModal;
  [MODALS.EDIT_AMAZON_CONFIG]: IEditAmazonConfig;
  [MODALS.EDIT_COMPANY_CONFIG]: IEditCompanyConfig;
  [MODALS.CUSTOMER_ORDER_SCANNER_MODAL]: ICustomerOrderScannerModal;
  [MODALS.ALERT_DIALOG]: IAlertDialogProps;
  [MODALS.ADD_SUPPLIER_ORDER]: IAddSupplierOrder;
  [MODALS.SEND_EMAIL_COMPONENT]: ISendEmailComponent;
  [MODALS.ADD_SUPPLIER_ORDER_ITEM]: IAddSupplierOrderItem;
  [MODALS.ADD_PRODUCT_QUANTITY_REQUIREMENT_MODAL]: IAddProductQuantityRequirementModal;
  [MODALS.MANUAL_SUPPLIER_ARTICLE_MODAL]: IManualSupplierArticleModalProps;
  [MODALS.SHIPPING_ADDRESS_MODAL]: IShippingAddressModal;
  [MODALS.ADD_PRODUCT_TO_CUSTOM_LIST]: IAddProductToCustomListModal;
  [MODALS.EDIT_SHOP_SHIPPING_DATA]: IEditShopwareShippingData;
  [MODALS.DELIVERY_CONFIRMATION]: IDeliveryConfirmationModalProps;
  [MODALS.ADD_SUPPLIER_ORDER_ITEM]: IAddSupplierOrderItem;
  [MODALS.FINISH_ORDER]: IFinishOrderManuallyModal;
  [MODALS.EDIT_USER]: IEditUserProps;
  [MODALS.SUPPLIER_ORDER_BACKLOG_MODAL]: ISupplierOrderBacklogModalProps;
  [MODALS.SUPPLIER_ORDER_FROM_BACKLOG_MODAL]: ISupplierOrderFromBacklogModalProps;
  [MODALS.EDIT_SUPPLIER_ORDER]: IEditSupplierOrderProps;
  [MODALS.PRODUCT_SCANNER]: IProductScannerProps;
  [MODALS.SHIPPING_PROVIDER_MODAL]: IShippingProviderModalProps;
  [MODALS.MERGE_SUPPLIER_ORDERS_MODAL]: IMergeSupplierOrdersModalProps;
  [MODALS.ADD_MULTIBOX_PRODUCT]: IAddMultiboxModalProps;
  [MODALS.EDIT_ORDER_DUE_DATE]: IEditOrderDueDateModalProps;
  [MODALS.ADD_PRODUCT_TO_COMPLAINT]: IAddProductToComplaintModalProps;
  [MODALS.PREVIOUS_COMPLAINT_EXPORTS]: IPreviousComplaintExportsModalProps;
  [MODALS.CREATE_CREDIT_NOTE_MODAL]: ICreateCreditNoteFormProps;
  [MODALS.ADD_COMMENT_TO_PRODUCT]: IAddCommentToProductModalProps;
  [MODALS.CHANGE_SUPPLIER_ON_CUSTOMER_COMPLAINT]: IChangeSupplierOnCustomerComplaintModalProps;
  [MODALS.SECOND_LABEL_MODAL]: any;
  [MODALS.RETURN_LABEL_MODAL]: any;
  [MODALS.EDIT_SUPPLIER_COMPLAINT]: IEditSupplierComplaintProps;
  [MODALS.ADD_CHANGE_LOG]: IAddChangeLogModalProps;
  [MODALS.CREATE_EXCHANGE_ORDER]: ICreateExchangeOrderModalProps;
  [MODALS.CREATE_CUSTOM_FIELD]: ICreateCustomFieldProps;
  [MODALS.CHANGE_PRODUCT_STATUS_MODAL]: IChangeProductStatusModalProps;
  [MODALS.ADD_SERVICE_ORDER]: IAddServiceOrderProps;
  [MODALS.SUPPLIER_ORDER_LABEL_SCANNER]: ISupplierOrderScannerProps;
  [MODALS.EXCHANGE_ORDER_INVOICE]: ICreateExchangeOrderInvoiceModalProps;
  [MODALS.LABEL_SCANNER]: ILabelScannerProps;
  [MODALS.EDIT_EBAY_CATEGORY]: IEditEbayCategoryModalProps;
  [MODALS.UPDATE_SHOP_FACTORS]: IUpdateShopFactorsModalProps;
  [MODALS.EDIT_CRON_CONFIG_MODAL]: any;
  [MODALS.MANUFACTURER_IMAGES]: any;
}

export type ModalProp<T extends keyof ModalProps> = ModalProps[T];
