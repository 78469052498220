import React, {useEffect} from 'react';
import {Error, Done} from '@material-ui/icons';
import moment from 'moment';
import {Button, Switch} from '@material-ui/core';
import {useStorlessFetch} from '../../hooks/fetch';
import {useDispatch} from 'react-redux';
import {modalActions} from '../../modules/modal/actions';
import {MODALS} from '../../components/Modal/ModalContents';
import {useTranslation} from 'react-i18next';
import {ISyncStatus} from '../../types/SyncStatus/SyncStatus';

export const SyncStatus = (props: any) => {
  return (
    <div title={props.row.status === 'ERROR' ? 'Error' : 'Success'}>
      {props.row.status === 'ERROR' ? <Error className="red" /> : <Done className="green" />}
    </div>
  );
};

export const SyncDate = (props: any) => {
  const formattedDate = props.row[props.accessor]
    ? moment(props.row[props.accessor]).format('DD. MMM YYYY HH:mm:ss')
    : '-';
  return <p title={formattedDate}>{formattedDate}</p>;
};

interface IRunSyncProps {
  row: any;
  callback?: () => void;
}

export const RunSync = ({row, callback}: IRunSyncProps) => {
  const [runSync, doRunSync] = useStorlessFetch('run_sync');
  const dispatch = useDispatch();
  const {t} = useTranslation();

  const handleCancelModal = () => {
    dispatch(modalActions.closeModal());
  };

  useEffect(() => {
    callback && callback();
  }, [runSync]);

  useEffect(() => {
    if (runSync.loaded) {
      dispatch(
        modalActions.addModal(MODALS.CONFIRM_DIALOG, {
          title: 'Info',
          content: t('syncStatus.runMessage'),
          onOk: handleCancelModal,
        }),
      );
    }
  }, [runSync.loading]);

  return (
    <Button
      className={!row.enabled && row.enabled !== undefined ? 'inactive-button' : 'blue-button'}
      variant="contained"
      onClick={() => {
        dispatch(
          modalActions.addModal(MODALS.CONFIRM_DIALOG, {
            title: `${t('syncStatus.runManually')}?`,
            content: t('general.areYouSure'),
            onYes: () => doRunSync({syncName: row.syncName, supplierName: row?.supplierName}),
            onNo: handleCancelModal,
          }),
        );
      }}
      disabled={!row.enabled && row.enabled !== undefined}
    >
      {t('syncStatus.runManually')}
    </Button>
  );
};

export const RunSyncMessage = () => {
  const {t} = useTranslation();

  return (
    <>
      <p style={{padding: '10px 20px', background: 'white'}}>{t('syncStatus.runMessage')}</p>
    </>
  );
};

export const isEnabled = (props: any) => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const syncStatus: ISyncStatus = props.row;
  const onCancelHandler = () => {
    dispatch(modalActions.closeModal());
  };

  const onChangeCheckedHandler = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const onConfirmHandler = () => {
      props.updateSync({...syncStatus, enabled: !e.target.checked});
      dispatch(modalActions.closeModal());
    };

    dispatch(
      modalActions.addModal(MODALS.CONFIRM_DIALOG, {
        title: t('general.areYouSure'),
        content: t('syncStatus.confirmMessage'),
        onYes: onConfirmHandler,
        onNo: onCancelHandler,
      }),
    );
  };

  return (
    <>
      <Switch
        checked={syncStatus?.enabled}
        onChange={onChangeCheckedHandler}
        name="statusSyncEnabled"
        color="primary"
      />
    </>
  );
};
