import React, {ReactElement, useContext, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import CreateSupplierOrderContext from '../../screens/SupplierOrders/Context/CreateSupplierOrderContext';
import {SupplierOrderItemStatus} from '../../types/SupplierOrders/SupplierOrderItemStatus';
import DataTable from '../Table/Table';
import SupplierOrderToBacklogButton from './SupplierOrderBacklog/SupplierOrderToBacklogButton';
import {appActions} from '../../modules/app/actions';
import {useDispatch} from 'react-redux';
import {DataTableItemType} from '../Table/interfaces/IHeaderAccessor';

const SupplierOrderItemsViewTable = (): ReactElement => {
  const {t} = useTranslation();
  const {state, orderId, moveToBacklogResponse} = useContext(CreateSupplierOrderContext);
  const dispatch = useDispatch();

  useEffect(() => {
    if (moveToBacklogResponse?.data) {
      dispatch(appActions.showSnackBar({text: t('general.success'), options: {severity: 'success'}}));
    }

    if (moveToBacklogResponse?.error) {
      dispatch(appActions.showSnackBar({text: moveToBacklogResponse.error?.name, options: {severity: 'error'}}));
    }

    return () => {
      moveToBacklogResponse.data = null;
      moveToBacklogResponse.error = null;
      moveToBacklogResponse.loaded = null;
      moveToBacklogResponse.loading = null;
    };
  }, [moveToBacklogResponse]);

  return (
    <DataTable
      headers={[
        {
          kind: 'accessor',
          name: t('supplierOrderItems.status'),
          accessor: 'status',
          sortable: true,
          type: DataTableItemType.MULTIPLE_SELECT,
          options: ([{value: 'all', label: 'All'}] as any).concat(
            Object.values(SupplierOrderItemStatus).map((item) => {
              return {value: item, label: item};
            }),
          ),
        },
        {
          kind: 'accessor',
          name: t('supplierOrderItems.product'),
          accessor: 'productTitle',
          sortable: true,
        },
        {
          kind: 'accessor',
          name: t('supplierOrderItems.externalArticleId'),
          accessor: 'externalArticleId',
          sortable: true,
        },
        {
          kind: 'accessor',
          name: t('supplierOrderItems.note'),
          accessor: 'note',
          sortable: false,
        },
        {
          kind: 'accessor',
          name: t('supplierOrderItems.quantity'),
          accessor: 'quantity',
          sortable: true,
          type: 'number',
        },
        {
          kind: 'accessor',
          name: t('supplierOrderItems.quantityDelivered'),
          accessor: 'quantityDelivered',
          sortable: true,
          type: 'number',
        },
        {
          kind: 'accessor',
          name: t('supplierOrderItems.price'),
          accessor: 'price',
          sortable: true,
          format: 'price',
          type: 'number',
        },
        {
          kind: 'component',
          name: 'Backlog',
          component: SupplierOrderToBacklogButton,
          props: {},
          onFetchProps: {},
        },
      ]}
      refreshData={state.toggleRefreshTable || moveToBacklogResponse.loaded}
      options={{supplierOrder: orderId}}
      endpoint={'supplier_order_items'}
      target={'products'}
      selectRowClickId={'product._id'}
      defaultSort={{key: 'createdAt', value: 1}}
    />
  );
};

export default SupplierOrderItemsViewTable;
