import React, {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useFetch} from '../../hooks/fetch';
import {ICustomReadyForShippingProductDetails} from '../../types/Orders/ICustomReadyForShippingList';
import AddProductToCustomListButton from '../../components/Order/AddProductToCustomListButton';
import {modalActions} from '../../modules/modal/actions';
import {MODALS} from '../../components/Modal/ModalContents';
import {useDispatch} from 'react-redux';

const Orders = (props: any) => {
  const {
    match: {
      params: {name},
    },
  } = props;
  const {t} = useTranslation();
  const [products, setProducts] = React.useState<ICustomReadyForShippingProductDetails[]>([]);
  const [getListResponse, getList] = useFetch<any>('get_custom_list');
  const [deleteProductResponse, deleteProduct] = useFetch<any>('delete_custom_list_product');
  const dispatch = useDispatch();

  const onDeleteProduct = (productId: string) => {
    deleteProduct({name, productId});
    dispatch(modalActions.closeModal());
  };

  const deleteProductFromList = (productId: string) => {
    dispatch(
      modalActions.addModal(MODALS.CONFIRM_DIALOG, {
        title: t('general.areYouSure'),
        content: t('general.areYouSure'),
        onYes: () => onDeleteProduct(productId),
        onNo: () => dispatch(modalActions.closeModal()),
      }),
    );
  };

  useEffect(() => {
    getList({name});
  }, []);

  useEffect(() => {
    setProducts(getListResponse.data?.products);
    return () => {
      getListResponse.data = null;
      getListResponse.error = null;
      getListResponse.loaded = null;
    };
  }, [getListResponse?.data]);

  useEffect(() => {
    getList({name});

    return () => {
      deleteProductResponse.data = null;
      deleteProductResponse.error = null;
      deleteProductResponse.loaded = null;
    };
  }, [deleteProductResponse?.data]);

  return (
    <div className="orders">
      <div className="big-title">{name}</div>

      <AddProductToCustomListButton name={name} refresh={() => getList({name})} />
      <div className="custom-ready-for-shipping-settings">
        {products &&
          products.map((product) => (
            <div key={product.id} className="flex" style={{marginTop: '10px'}}>
              <span className="product-name">{product.name}</span>
              <span
                style={{color: 'red', fontWeight: 'bold', cursor: 'pointer'}}
                onClick={() => deleteProductFromList(product.id)}
              >
                &#10006;
              </span>
            </div>
          ))}
      </div>
    </div>
  );
};

export default Orders;
