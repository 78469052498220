import axios from 'axios';
import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Button} from '@material-ui/core';
import Spinner from '../../components/Spinner/Spinner';
import {appActions} from '../../modules/app/actions';
import {useDispatch} from 'react-redux';
import config from '../../lib/config';
import {useFetch} from '../../hooks/fetch';

const ImportProducts: React.FunctionComponent = () => {
  const {t} = useTranslation();
  const [uploadedFile, setUploadedFile] = useState<any>('');
  const [spinner, setSpinner] = useState(false);
  const [hideButton, setHideButton] = useState(true);
  const [getTemplateRes, getTemplate] = useFetch<any>('download_product_import_template');
  const dispatch = useDispatch();

  const handleFileUpload = (event: any) => {
    setUploadedFile(event?.target?.files[0]);
    event.target.value = null;
  };

  const checkIfFileIsLongerThan10Lines = async (): Promise<boolean> => {
    const reader = new FileReader();
    reader.readAsText(uploadedFile);
    const file = await new Promise((resolve) => {
      reader.onload = () => {
        resolve(reader.result);
      };
    });
    const lines = (file as string).split('\n');
    return lines.length > 10;
  };

  const submitForm = async () => {
    const url = `${config.api}products/sync`;
    const hasMoreThan10Lines = await checkIfFileIsLongerThan10Lines();

    if (hasMoreThan10Lines) {
      if (!confirm(t('importProducts.confirmationMessageForLines'))) {
        return false;
      }
    }

    const formData = new FormData();
    formData.append('file', uploadedFile);

    if (!uploadedFile?.name) {
      return;
    } else {
      setSpinner(true);
      axios
        .post(url, formData, {
          headers: {'Content-Type': 'multipart/form-data'},
        })
        .then(() => {
          setSpinner(false);
          if (uploadedFile !== '' && !uploadedFile?.name?.endsWith('.csv')) {
            dispatch(
              appActions.showSnackBar({text: t('importProducts.invalidUploadMessage'), options: {severity: 'error'}}),
            );
          } else {
            setHideButton(true);
            setUploadedFile('');
            dispatch(
              appActions.showSnackBar({text: t('importProducts.successUploadMessage'), options: {severity: 'success'}}),
            );
          }
        })
        .catch((error) => {
          const errorMessage = error.response?.data?.error?.data ?? error.response?.data?.error?.name;
          setSpinner(false);
          dispatch(appActions.showSnackBar({text: errorMessage, options: {severity: 'error'}}));
        });
    }
  };

  const downloadTemplate = () => {
    getTemplate();
  };

  useEffect(() => {
    if (getTemplateRes?.data) {
      const url = window.URL.createObjectURL(new Blob([getTemplateRes.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'product_import_template.csv');
      document.body.appendChild(link);
      link.click();
    }

    return () => {
      if (getTemplateRes?.data) {
        window.URL.revokeObjectURL(getTemplateRes.data);
      }
      getTemplateRes.data = null;
    };
  }, [getTemplateRes]);

  return (
    <div className="import-products">
      <Button variant="contained" className="blue-button" onClick={downloadTemplate}>
        {t('importProducts.downloadTemplate')}
      </Button>
      <div className="import-products__wrapper">
        <img src="/images/import-products.webp" />
        <h2>
          <b>{t('importProducts.screenTitle')}</b>
        </h2>

        <h4 className="import-products__choose__text">{t('importProducts.importFileTitle')}</h4>
        <div className="import-products__wrapper__import__file">
          <p>Choose file</p>
          <input
            className="import-products__wrapper__import__file__hide"
            type="file"
            id="file"
            name="file"
            accept=".csv"
            onChange={handleFileUpload}
          />
        </div>
        {uploadedFile?.name && (
          <div className="import-products__wrapper__uploaded__file__text">{uploadedFile?.name}</div>
        )}
        <br />
        {spinner && <Spinner />}
        {uploadedFile?.name && hideButton && (
          <Button variant="contained" className="blue-button" onClick={submitForm}>
            {t('importProducts.sendFileButton')}
          </Button>
        )}
      </div>
    </div>
  );
};

export default ImportProducts;
