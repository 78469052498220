import {Typography} from '@material-ui/core';
import React from 'react';
import {IShippingLabel} from '../../types/Shipment/IShippingLabel';

export interface IViewShipmentLabel {
  title?: string;
  shippingLabel?: IShippingLabel;
}

const ViewShipmentLabel = (props: IViewShipmentLabel) => {
  const {title} = props;

  return (
    <>
      <Typography className="modal-title">{title}</Typography>
      {props.shippingLabel?.gifImage && (
        <div id="upsShippingLabel" className="view_shipment_label">
          <img src={`data:image/gif;base64,${props.shippingLabel?.gifImage}`} />
        </div>
      )}
    </>
  );
};

export default ViewShipmentLabel;
