import React, {useEffect, useState} from 'react';
import {useFetch} from '../../hooks/fetch';
import {useDispatch} from 'react-redux';
import {push} from 'connected-react-router';
import {modalActions} from '../../modules/modal/actions';
import {appActions} from '../../modules/app/actions';
import DataTable from '../../components/Table/Table';
import {useTranslation} from 'react-i18next';
import {ISupplierArticle} from '../../types/SupplierArticle/ISupplierArticle';
import InfoIcon from '@material-ui/icons/Info';
import {Box, Button, Card, Tab, Tabs} from '@material-ui/core';
import {MODALS} from '../../components/Modal/ModalContents';
import {TabContext, TabPanel} from '@material-ui/lab';
import {Link} from 'react-router-dom';
import {APP_ROUTES} from '../../routers/routes';
import {DataTableItemType} from '../../components/Table/interfaces/IHeaderAccessor';

const SupplierArticleDetail = (props: any) => {
  const {
    match: {
      params: {id, shopwareId},
    },
  } = props;
  const [articleDetails, getArticleDetails] = shopwareId
    ? useFetch<any>('product_by_shopware_id')
    : useFetch<any>('article_details');
  const [articlePut, doArticlePut] = useFetch<any>('supplier_articles_put');
  const [unlinkProductRelationResponse, unlinkProductRelation] = useFetch<any>('supplier_articles_unlink');
  const [supplierArticle, setSupplierArticle] = useState<ISupplierArticle>(null as any);
  const [tabValue, setTabValue] = React.useState<any>('0');
  const handleTabChange = (event: any, newValue: any) => setTabValue(newValue);
  const dispatch = useDispatch();
  const {t} = useTranslation();

  useEffect(() => {
    getArticleDetails(shopwareId ? {id: shopwareId} : {id});
  }, [id]);

  useEffect(() => {
    dispatch(modalActions.closeModal(undefined, 'submited'));
  }, [articleDetails.data]);

  useEffect(() => {
    if (articleDetails.data) {
      setSupplierArticle(articleDetails.data);
    }
  }, [articleDetails]);

  useEffect(() => {
    if (articlePut.error) {
      dispatch(appActions.showSnackBar({text: articlePut.error.name, options: {severity: 'error'}}));
    }

    if (articlePut.loaded && !articlePut.error) {
      getArticleDetails(shopwareId ? {id: shopwareId} : {id});
      dispatch(
        appActions.showSnackBar({
          text: t('general.submitFormText'),
          options: {severity: 'success'},
        }),
      );
    }

    return () => {
      articlePut.loaded = null;
      articlePut.data = null;
      articlePut.error = null;
    };
  }, [articlePut]);

  useEffect(() => {
    if (unlinkProductRelationResponse.error) {
      dispatch(
        appActions.showSnackBar({
          text: unlinkProductRelationResponse.error.name,
          options: {severity: 'error'},
        }),
      );
      handleCancelModal();
    }

    if (unlinkProductRelationResponse.loaded && !unlinkProductRelationResponse.error) {
      getArticleDetails(shopwareId ? {id: shopwareId} : {id});
      dispatch(
        appActions.showSnackBar({
          text: t('general.submitFormText'),
          options: {severity: 'success'},
        }),
      );
      handleCancelModal();
    }

    return () => {
      unlinkProductRelationResponse.loaded = null;
      unlinkProductRelationResponse.data = null;
      unlinkProductRelationResponse.error = null;
    };
  }, [unlinkProductRelationResponse]);

  const handleCancelModal = () => {
    dispatch(modalActions.closeModal());
  };

  const handleUnlinkProduct = async () => {
    unlinkProductRelation({id: id});
  };

  const onUnlinkProductClick = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    event.preventDefault();

    dispatch(
      modalActions.addModal(MODALS.ALERT_DIALOG, {
        title: t('articleDetails.unlinkProductTitle') || '',
        description: t('articleDetails.unlinkProductDescription'),
        onSubmit: handleUnlinkProduct,
        onClose: handleCancelModal,
        onOpen: true,
      }),
    );
  };

  const article = articleDetails?.data as ISupplierArticle;

  const onClickTableRow = (supplierID: any) => {
    dispatch(push(`/articles/${supplierID}`));
  };

  const parseFormattedNumberToPlainNumber = (item: string) => {
    return parseFloat(item.replace(/,/g, '.').replace(/ €/g, ''));
  };

  const sortTable = (n: any, isNumber = false) => {
    let rows: any,
      switching: any,
      i,
      x,
      y,
      shouldSwitch,
      dir,
      switchcount = 0;
    const table: any = document.getElementById('article-details-table');
    switching = true;
    dir = 'asc';
    while (switching) {
      switching = false;
      rows = table?.rows;
      for (i = 1; i < rows.length - 1; i++) {
        shouldSwitch = false;
        x = rows[i].getElementsByTagName('TD')[n];
        y = rows[i + 1].getElementsByTagName('TD')[n];

        const cellContent1 = isNumber ? parseFormattedNumberToPlainNumber(x.innerText) : x.innerHTML.toLowerCase();
        const cellContent2 = isNumber ? parseFormattedNumberToPlainNumber(y.innerText) : y.innerHTML.toLowerCase();

        if (dir == 'asc') {
          if (cellContent1 > cellContent2) {
            shouldSwitch = true;
            break;
          }
        } else if (dir == 'desc') {
          if (cellContent1 < cellContent2) {
            shouldSwitch = true;
            break;
          }
        }
      }
      if (shouldSwitch) {
        rows[i].parentNode.insertBefore(rows[i + 1], rows[i]);
        switching = true;
        switchcount++;
      } else {
        if (switchcount == 0 && dir == 'asc') {
          dir = 'desc';
          switching = true;
        }
      }
    }
  };

  const alternativesTable = supplierArticle?.product?.supplierArticle
    ?.sort((x, y) => (x.price > y.price ? 1 : y.price > x.price ? -1 : 0))
    ?.map((item: ISupplierArticle) => {
      const actualDisplayedArticle = supplierArticle._id === item._id;
      return (
        !actualDisplayedArticle && (
          <tr
            key={item._id}
            onClick={() => {
              onClickTableRow(item._id);
            }}
          >
            <td>{item.supplierName}</td>
            <td>{item.externalArticleId}</td>
            <td>{item.quantity}</td>
            <td>
              {item.price.toLocaleString('de-DE', {
                style: 'currency',
                currency: 'EUR',
                minimumFractionDigits: 2,
              })}
            </td>
          </tr>
        )
      );
    });

  return (
    supplierArticle && (
      <>
        <div className="supplier-article-detail">
          <div className="center-content">
            <div className="supplier-article-detail__header">
              <p>
                {supplierArticle.supplier?.name} {t('articleDetails.screenTitle')} - {supplierArticle.externalArticleId}
              </p>
              <div className="product-details__header__buttons">
                {supplierArticle.product ? (
                  <Button variant="contained" className="blue-button" onClick={onUnlinkProductClick}>
                    {t('articleDetails.btnUnlinkFromProduct')}
                  </Button>
                ) : null}
              </div>
            </div>

            <div className="supplier-article-detail__data-container">
              <div className="card-info-supplier-article">
                <div className="supplier-article-detail__data-container__title">
                  <InfoIcon style={{fontSize: 25}} />
                  <p>{t('articleDetails.info')} -</p>
                  <Link
                    style={{color: 'black', display: 'flex', marginLeft: '5px'}}
                    to={`${APP_ROUTES.PRODUCTS}/${supplierArticle?.product?._id}`}
                  >
                    {t('productDetails.product')}
                  </Link>
                </div>
                <div className="supplier-article-detail__data-container__body__wrapper">
                  <div className="supplier-article-detail__data-container__field__items">
                    <h6>{t('articleDetails.externalArticleId')}</h6>
                    <p>{article.externalArticleId}</p>
                  </div>
                  <div className="supplier-article-detail__data-container__field__items">
                    <h6>{t('articleDetails.title')}</h6>
                    <p>{article.title}</p>
                  </div>
                  <div className="supplier-article-detail__data-container__field__items">
                    <h6>Status</h6>
                    <p>{article.status}</p>
                  </div>
                  <div className="supplier-article-detail__data-container__field__items">
                    <h6>{t('articleDetails.qualityLevel')}</h6>
                    <p>{article.qualityLevel ?? '-'}</p>
                  </div>
                  <div className="supplier-article-detail__data-container__field__items">
                    <h6>{t('articleDetails.quantity')}</h6>
                    <p>{article.quantity}</p>
                  </div>
                  <div
                    className="supplier-article-detail__data-container__field__items"
                    style={{borderBottom: 'unset'}}
                  >
                    <h6>{t('articleDetails.price')}</h6>
                    <p>
                      {article.price?.toLocaleString('de-DE', {
                        style: 'currency',
                        currency: 'EUR',
                        minimumFractionDigits: 2,
                      })}
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <Card variant="outlined">
              <TabContext key="tabContext" value={tabValue}>
                <Box>
                  <Tabs
                    variant="scrollable"
                    scrollButtons="auto"
                    value={tabValue}
                    onChange={handleTabChange}
                    selectionFollowsFocus
                  >
                    <Tab label={t('articleDetails.history')} value="0" />
                    <Tab label={t('articleDetails.alternatives')} value="1" />
                  </Tabs>
                </Box>
                <TabPanel value="0" className="tab-element">
                  <DataTable
                    headers={[
                      {
                        kind: 'accessor',
                        name: t('articleDetails.lastUpdated'),
                        accessor: 'updatedAt',
                        sortable: true,
                        type: DataTableItemType.DATE,
                      },
                      {
                        kind: 'accessor',
                        name: t('articleDetails.price'),
                        accessor: 'price',
                        sortable: true,
                        format: 'price',
                      },
                      {
                        kind: 'accessor',
                        name: t('articleDetails.quantity'),
                        accessor: 'quantity',
                        sortable: true,
                      },
                    ]}
                    endpoint={'history_by_ids'}
                    options={{supplierArticle: id}}
                    defaultSort={{key: 'createdAt', value: -1}}
                  />
                </TabPanel>
                <TabPanel value="1" className="tab-element">
                  <div className="supplier-article-detail__table">
                    <table id="article-details-table">
                      <thead>
                        <tr>
                          <th className="supplier-article-detail__table__click" onClick={() => sortTable(0)}>
                            {t('articleDetails.supplierName')}
                          </th>
                          <th className="supplier-article-detail__table__click" onClick={() => sortTable(1)}>
                            {t('articleDetails.externalArticleId')}
                          </th>
                          <th className="supplier-article-detail__table__click" onClick={() => sortTable(2, true)}>
                            {t('articleDetails.quantity')}
                          </th>
                          <th className="supplier-article-detail__table__click" onClick={() => sortTable(3, true)}>
                            {t('articleDetails.price')}
                          </th>
                        </tr>
                      </thead>
                      <tbody>{alternativesTable}</tbody>
                    </table>
                  </div>
                </TabPanel>
              </TabContext>
            </Card>
          </div>
        </div>
      </>
    )
  );
};

export default SupplierArticleDetail;
