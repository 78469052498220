import React, {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import DataTable from '../../../components/Table/Table';
import {useStorlessFetch} from '../../../hooks/fetch';
import {OrderStatusType} from '../../../types/Orders/OrderStatusType';
import {useOrderHeaders} from './OrderHeadrs';

const Orders = (props: any) => {
  const {t} = useTranslation();
  const [csvFileResponse, getCsvFile] = useStorlessFetch('orders_export_csv');

  useEffect(() => {
    if (csvFileResponse.data) {
      const url = window.URL.createObjectURL(new Blob([csvFileResponse.data]));
      const link: HTMLAnchorElement = document.createElement('a');
      link.href = url;
      link.style.display = 'none';
      link.setAttribute('download', `orders.csv`);

      document.body.appendChild(link);
      link.click();
      link?.parentNode?.removeChild(link);
    }

    return () => {
      csvFileResponse.data = null;
    };
  }, [csvFileResponse]);

  return (
    <div className="orders">
      <DataTable
        headers={useOrderHeaders() as any}
        endpoint={'orders'}
        target={'orders'}
        defaultSort={{key: 'date', value: -1}}
        enableExportToCsv={props?.isOffer ? false : true}
        enableBackendCsvExport={props?.isOffer ? false : true}
        csvFilename={'orders'}
        onExport={getCsvFile}
        options={
          props?.isOffer
            ? {status: {$in: [OrderStatusType.Offer, OrderStatusType.PendingOffer]}}
            : {status: {$nin: [OrderStatusType.Offer, OrderStatusType.PendingOffer]}}
        }
        isCsvLoading={csvFileResponse?.loading}
        title={props?.isOffer ? t('general.offers') : t('orders.screenTitle')}
      />
    </div>
  );
};

export default Orders;
