import {Typography} from '@material-ui/core';
import React, {useEffect, useMemo, useState} from 'react';
import Form, {IField} from '../../components/Form/Form';
import {useStorlessFetch} from '../../hooks/fetch';
import * as Yup from 'yup';
import {appActions} from '../../modules/app/actions';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {push} from 'connected-react-router';

const ForgotPassword = () => {
  const [forgotPasswordResponse, forgotPasswordRequest] = useStorlessFetch('forgot_password');
  const [emailSentState, setEmailSentState] = useState(false);
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const formikValidationSchema = Yup.object().shape({
    email: Yup.string().email('Invalid email').required('Required'),
  });
  const fields: IField[] = useMemo(
    () => [
      {
        label: 'Email',
        placeholder: 'Type your email',
        fieldKey: 'email',
        type: 'input',
        required: true,
      },
    ],
    [],
  );

  const handleCancel = () => {
    dispatch(push(`/`));
  };

  const title = useMemo(
    () => (
      <Typography style={{margin: '50px 0px 50px 0px'}} variant="h4">
        Drück Dich Aus
      </Typography>
    ),
    [],
  );
  let form = null;

  if (!emailSentState)
    form = (
      <Form
        initialValues={{email: ''}}
        submitButtonText="Reset Password"
        fields={fields}
        onSubmit={forgotPasswordRequest}
        onCancel={handleCancel}
        validationSchema={formikValidationSchema}
      />
    );
  else form = <h2>An email has been sent to your inbox!</h2>;

  useEffect(() => {
    if (forgotPasswordResponse.error) {
      appActions.showSnackBar({text: forgotPasswordResponse.error.name, options: {severity: 'error'}});
    }

    if (forgotPasswordResponse.loaded && !forgotPasswordResponse.error) {
      setEmailSentState(true);
    }
  }, [forgotPasswordResponse]);

  return (
    <div className="forgot-password">
      <h1>{t('general.forgotPassword')}</h1>
      <h2>{t('general.enterEmail')}</h2>
      <div className="forgot-password__form">{form}</div>
    </div>
  );
};
export default ForgotPassword;
