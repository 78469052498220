import React, {useEffect, useState, useRef} from 'react';
import {useTranslation} from 'react-i18next';
import DataTable from '../../components/Table/Table';
import OpenInvoicesActions from '../../components/SupplierOrder/OpenInvoicesAction';
import {MenuItem, Select, Button} from '@material-ui/core';
import {PaymentMethodType} from '../../types/Supplier/PaymentMethodType';
import {formatCurrency} from '../../lib/helpers';
import {useFetch} from '../../hooks/fetch';
import {useDispatch} from 'react-redux';
import {appActions} from '../../modules/app/actions';

export interface FilterButtonProps {
  paymentMethod: string;
  setPaymentMethod: (value: string) => void;
}

const HeaderComponent = ({additionalData}: any) => {
  const {t} = useTranslation();
  return (
    <p style={{padding: '5px'}}>
      {t('supplierOrders.totalOnPage')}: {formatCurrency(additionalData?.total)}
    </p>
  );
};

const FilterButton = ({paymentMethod, setPaymentMethod}: FilterButtonProps) => {
  const {t} = useTranslation();

  const paymentMethods: string[] = Object.values(PaymentMethodType);

  return (
    <span style={{marginLeft: '20px'}}>
      <Select
        value={paymentMethod}
        onChange={(e) => setPaymentMethod(e.target.value as string)}
        label={t('general.paymentType')}
        style={{minWidth: '150px'}}
      >
        <MenuItem key="all" value="all">
          {t('general.all')}
        </MenuItem>
        {paymentMethods.map((value) => (
          <MenuItem key={value} value={value}>
            {value}
          </MenuItem>
        ))}
      </Select>
    </span>
  );
};

const SupplierOrdersDue = () => {
  const {t} = useTranslation();
  const [paymentMethod, setPaymentMethod] = useState<string>('all');
  const [refresh, setRefresh] = useState<boolean>(false);
  const [allSelectedInvoices, setAllSelectedInvoices] = useState<Record<string, any[]>>({});
  const [generatePaymentResponse, doGeneratePayment] = useFetch<any>('generate_supplier_payments');
  const dispatch = useDispatch();
  const openInvoicesActionsRef = useRef<any>(null); // Reference to OpenInvoicesActions component

  useEffect(() => {
    setRefresh(!refresh);
  }, [paymentMethod]);

  // Function to handle the grouping of selected records by supplier
  const generatePayment = () => {
    const records = Object.values(allSelectedInvoices).flat();
    doGeneratePayment({
      orders: records,
    });
  };

  useEffect(() => {
    if (generatePaymentResponse.data) {
      const blob = new Blob([generatePaymentResponse.data], {type: 'application/xml'});
      const link = document.createElement('a');

      setRefresh((prev) => !prev);
      setAllSelectedInvoices({});

      link.href = window.URL.createObjectURL(blob);
      link.download = 'payment.xml';
      link.click();
    }

    if (generatePaymentResponse.error) {
      dispatch(
        appActions.showSnackBar({text: t('supplierOrderDetails.generatePaymentError'), options: {severity: 'error'}}),
      );
    }

    return () => {
      generatePaymentResponse.data = null;
      generatePaymentResponse.error = null;
      generatePaymentResponse.loading = false;
      generatePaymentResponse.loaded = false;
    };
  }, [generatePaymentResponse.data]);

  const handleInvoiceSelection = (supplierId: string, selectedInvoices: any[]) => {
    setAllSelectedInvoices((prev) => ({
      ...prev,
      [supplierId]: selectedInvoices.map((invoice) => ({
        ...invoice,
        supplierId,
      })),
    }));
  };

  return (
    <div className="orders">
      <div style={{display: 'flex', alignItems: 'center'}}></div>
      <DataTable
        headers={[
          {
            kind: 'accessor',
            name: t('supplierOrders.supplier'),
            accessor: 'supplierName',
            sortable: false,
          },
          {
            kind: 'component',
            name: t('general.openInvoices'),
            component: OpenInvoicesActions,
            props: {
              setRefresh: () => setRefresh((prevState) => !prevState),
              ref: openInvoicesActionsRef,
              onSelectionChange: handleInvoiceSelection,
            },
            onFetchProps: {},
          },
        ]}
        endpoint={'supplier_orders_due_report'}
        target={'suppliers'}
        selectRowClickId={'supplierId'}
        numberOfItemsPerPage={100}
        title={t('general.openInvoices')}
        actionButtons={
          <>
            <FilterButton paymentMethod={paymentMethod} setPaymentMethod={setPaymentMethod} />{' '}
            <Button variant="contained" color="primary" style={{marginLeft: '20px'}} onClick={generatePayment}>
              {t('supplierOrders.generatePayment')}
            </Button>
          </>
        }
        additionalFields={{paymentMethod}}
        refreshData={refresh}
        aboveTableComponent={HeaderComponent}
      />
    </div>
  );
};

export default SupplierOrdersDue;
