import React, {useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {useFetch} from '../../hooks/fetch';
import {Button, TableCell, TextField} from '@material-ui/core';
import {ISupplierArticle} from '../../types/SupplierArticle/ISupplierArticle';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import {ISupplierOrderItem} from '../../types/SupplierOrders/ISupplierOrderItem';
import {appActions} from '../../modules/app/actions';

const UnfinishedSupplierOrderActions: React.FC<IActionsProps> = (props: any) => {
  const [addOrderItem, doAddOrderItem] = useFetch('add_order_item_to_purchase_list');
  const {row, setTriggerRefreshTable} = props;
  const supplierOrderItems: ISupplierOrderItem[] = row.supplierOrderItems;
  const supplierArticles: ISupplierArticle[] = row.supplierArticles;
  const {t} = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    if (addOrderItem?.error) {
      dispatch(appActions.showSnackBar({text: addOrderItem.error.name, options: {severity: 'error'}}));
    } else if (addOrderItem?.data) {
      dispatch(appActions.showSnackBar({text: t('general.success'), options: {severity: 'success'}}));
      setTriggerRefreshTable(Math.random());
    }

    return () => {
      addOrderItem.data = null;
      addOrderItem.error = null;
      addOrderItem.loaded = null;
    };
  }, [addOrderItem]);

  const addSupplierOrderItemComponent = (supplierArticle: ISupplierArticle) => {
    const [orderedValue, setOrderedValue] = useState(0);
    return (
      <div className="flex-center">
        <TextField
          type="number"
          value={orderedValue}
          InputProps={{inputProps: {min: 0, max: supplierArticle.quantity}}}
          onChange={(e) => {
            e.preventDefault();
            setOrderedValue(+e.target.value);
          }}
        ></TextField>
        <Button
          variant="contained"
          className="blue-button"
          onClick={(e) => {
            e.preventDefault();
            addOrderItemToPurchaseList(supplierArticle, orderedValue);
          }}
          style={{marginLeft: '10px'}}
        >
          {t('unfinishedSupplierOrders.orderButton')}
        </Button>
      </div>
    );
  };

  const addOrderItemToPurchaseList = async (supplierArticle: ISupplierArticle, quantity: number) => {
    doAddOrderItem({
      supplierArticleId: supplierArticle._id,
      quantity: quantity,
    });
  };

  return (
    <div
      className="unfinished-action"
      onClick={(e) => {
        e.preventDefault();
      }}
    >
      <div className="current-items">
        {t('unfinishedSupplierOrders.currentOrders')}
        <Table>
          <TableBody>
            {supplierOrderItems.map((supplierOrderItem, index) => {
              return (
                <TableRow key={index}>
                  <TableCell style={{width: '20%'}}>{supplierOrderItem.supplierName}</TableCell>
                  <TableCell style={{width: '20%'}}>{supplierOrderItem.quantity}</TableCell>
                  <TableCell style={{width: '20%'}}>
                    {supplierOrderItem.price.toLocaleString('de-DE', {
                      style: 'currency',
                      currency: 'EUR',
                      minimumFractionDigits: 2,
                    })}
                  </TableCell>
                  <TableCell style={{width: '40%'}}>{supplierOrderItem.note}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </div>
      <div className="supplier-articles">
        {t('unfinishedSupplierOrders.availableArticles')}
        <Table>
          <TableBody>
            {supplierArticles.map((supplierArticle, index) => {
              return (
                <TableRow key={index}>
                  <TableCell style={{width: '30%'}}>{supplierArticle.supplierName}</TableCell>
                  <TableCell style={{width: '20%'}}>{supplierArticle.quantity}</TableCell>
                  <TableCell style={{width: '20%'}}>
                    {supplierArticle.price.toLocaleString('de-DE', {
                      style: 'currency',
                      currency: 'EUR',
                      minimumFractionDigits: 2,
                    })}
                  </TableCell>
                  <TableCell style={{width: '30%'}}>{addSupplierOrderItemComponent(supplierArticle)}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </div>
    </div>
  );
};

interface IActionsProps {
  id: string;
  setTriggerRefreshTable: (param: any) => void;
}

export default UnfinishedSupplierOrderActions;
