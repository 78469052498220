import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import AddChangeLogButton from '../../components/ChangeLog/AddChangeLogButton';
import {useFetch} from '../../hooks/fetch';
import {Launch} from '@material-ui/icons';

const ChangeLog = () => {
  const {t} = useTranslation();
  const [changeLogs, setChangeLogs] = useState<any>([]);
  const [changeLogsRes, getChangeLogs] = useFetch<any>('get_change_logs');

  useEffect(() => {
    getChangeLogs();
  }, []);

  useEffect(() => {
    if (changeLogsRes?.data) {
      setChangeLogs(changeLogsRes.data);
    }

    return () => {
      changeLogsRes.data = null;
      changeLogsRes.error = null;
      changeLogsRes.loaded = null;
    };
  }, [changeLogsRes]);

  return (
    <div className="standard-page">
      <h1>{t('changeLog.title')}</h1>
      <AddChangeLogButton getChangeLogs={getChangeLogs} />
      {changeLogs.map((changeLog: any) => (
        <div className="change-log-entry" key={changeLog.formattedDate}>
          <h3 className="change-log-date">{changeLog.formattedDate}</h3>
          {changeLog.features.map((item: any) => (
            <div className="change-log-item" key={item.id}>
              <h4 className="change-log-title">
                <div className="change-log-circle"></div>
                <div className="flex-center">
                  {item.title}
                  {item.id}
                  {item.link && (
                    <a href={item.link} target="_blank" rel="noreferrer" className="change-log-link">
                      <Launch style={{fontSize: '0.9rem'}}></Launch>
                    </a>
                  )}
                </div>
              </h4>
              {item.description && <p className="change-log-description">{item.description}</p>}
            </div>
          ))}
          {changeLog.bugs.map((item: any) => (
            <div className="change-log-item bug" key={item.id}>
              <h4 className="change-log-title">
                <div className="change-log-circle" style={{backgroundColor: '#ef4444'}}></div>
                <div>
                  {item.title}
                  {item.id}
                  {item.link && (
                    <a href={item.link} target="_blank" rel="noreferrer" className="change-log-link">
                      <Launch style={{fontSize: '0.9rem'}}></Launch>
                    </a>
                  )}
                </div>
              </h4>
              {item.description && <p className="change-log-description">{item.description}</p>}
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};

export default ChangeLog;
