import {Snackbar} from '@material-ui/core';
import {Alert, AlertProps} from '@material-ui/lab';
import _ from 'lodash';
import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';

const SnackBarBase = () => {
  const snackBarStatus = useSelector((state) => _.get(state, 'app.snackBar'));
  const [open, setOpen] = useState(false);
  const {text, options} = snackBarStatus;

  useEffect(() => {
    if (snackBarStatus?.text) setOpen(true);
  }, [snackBarStatus]);

  return (
    <Snackbar open={open} autoHideDuration={5000} onClose={() => setOpen(false)}>
      <Alert severity={options?.severity} variant="filled">
        {text}
      </Alert>
    </Snackbar>
  );
};

export interface ISnackBarData {
  text: string;
  options?: AlertProps;
}

export default SnackBarBase;
