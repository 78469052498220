import {Typography} from '@material-ui/core';
import React, {ReactElement} from 'react';
import Form from '../Form/Form';
import {useTranslation} from 'react-i18next';
import * as Yup from 'yup';

const ManualSupplierArticleModal = (props: IManualSupplierArticleModalProps): ReactElement => {
  const {onSubmit, onCancel, type, defaultValues} = props;
  const {t} = useTranslation();

  const generateInitialValues = (fields: any[]) => {
    let defaults = {};
    fields.forEach((field) => {
      defaults = {...defaults, [field.fieldKey]: defaultValues[field.fieldKey] ?? ''};
    });
    return defaults;
  };

  function getFields() {
    const fields = [];

    if (type === ManualSupplierArticleModalType.ADD) {
      fields.push({
        label: t('supplierOrderDetails.supplier'),
        placeholder: t('supplierOrderDetails.supplier'),
        fieldKey: 'supplier',
        type: 'autocomplete',
        options: [],
        endpoint: 'supplier_autocomplete_list',
        required: true,
      });
      fields.push({
        label: t('supplierOrderDetails.product'),
        placeholder: t('supplierOrderDetails.product'),
        fieldKey: 'product',
        type: 'autocomplete',
        options: [],
        endpoint: 'product_autocomplete_list',
        required: true,
      });
    }
    fields.push({
      label: t('addNewArticle.articlePrice'),
      placeholder: t('addNewArticle.articlePrice'),
      fieldKey: 'price',
      type: 'input',
      fieldKeyType: 'number',
    });
    fields.push({
      label: t('addNewArticle.articleQuantity'),
      placeholder: t('addNewArticle.articleQuantity'),
      fieldKey: 'quantity',
      type: 'input',
      fieldKeyType: 'number',
    });
    return fields;
  }

  const fields: any = getFields();

  const initialValues = generateInitialValues(fields);

  const validationSchema = Yup.object().shape({
    supplier:
      type === ManualSupplierArticleModalType.ADD
        ? Yup.string().required('Required').nullable()
        : Yup.string().optional(),
    product:
      type === ManualSupplierArticleModalType.ADD
        ? Yup.string().required('Required').nullable()
        : Yup.string().optional(),
    price: Yup.number().required('Required').nullable(),
    quantity: Yup.number().required('Required').nullable(),
  });

  return (
    <>
      <Typography className="modal-title">{t('supplierOrderItems.addButton')}</Typography>
      <Form
        initialValues={initialValues}
        fields={fields}
        submitButtonText={t('general.submit')}
        cancelButtonText={t('general.cancel')}
        onSubmit={onSubmit}
        onCancel={onCancel}
        validationSchema={validationSchema}
      ></Form>
    </>
  );
};

export enum ManualSupplierArticleModalType {
  ADD = 'ADD',
  UPDATE = 'UPDATED',
}

export interface IManualSupplierArticleModalProps {
  onSubmit: (values: any) => void;
  onCancel: () => void;
  productId?: string;
  supplierId?: string;
  type: ManualSupplierArticleModalType;
  defaultValues?: any;
}

export default ManualSupplierArticleModal;
