import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';

const FormImage = ({image, onChange}: {image: File | string; onChange: (data: File | null) => void}) => {
  const isUrl = typeof image === 'string';
  //@ts-ignore
  const isFile = typeof image?.name === 'string';
  const [base64Image, setBase64Image] = useState<any>(null);
  const {t} = useTranslation();

  useEffect(() => {
    if (isFile) {
      createBase64(image as File);
    } else {
      setBase64Image(null);
    }
    if (!image) {
      onChange(null);
    }
  }, [image]);

  const createBase64 = (file: File) => {
    if (file) {
      const reader = new FileReader();

      reader.onload = function (e: any) {
        setBase64Image(e.target.result);
      };

      reader.readAsDataURL(file);
    }
  };

  const handleChangeImage = (e: React.ChangeEvent<HTMLInputElement>): void => {
    if (e.target.files) {
      onChange(e.target.files[0]);
    }
  };

  return (
    <div style={{width: 200}}>
      {image || base64Image ? (
        <a style={{position: 'absolute', top: 10, right: 10, cursor: 'pointer'}} onClick={() => onChange(null)}>
          {t('general.delete')}
        </a>
      ) : null}

      {isUrl || isFile ? <img style={{width: '100%', height: '100%'}} src={isUrl ? image : base64Image} /> : null}
      {!image && <input type="file" onChange={handleChangeImage} />}
    </div>
  );
};

export default FormImage;
