import React, {useContext, useState} from 'react';
import {List, ListItem, IconButton, Grid} from '@material-ui/core';
import IconExpandLess from '@material-ui/icons/ExpandLess';
import IconExpandMore from '@material-ui/icons/ExpandMore';
import {Link} from 'react-router-dom';
import {USER_ROLE} from '../../types/User/UserRole';
import useUser from '../../hooks/user';
import {ThemeContext} from '../..';
import {useTranslation} from 'react-i18next';

export interface IMenuProps {
  name: string;
  path: string;
  icon?: any;
  items?: IMenuProps[];
  roles?: USER_ROLE[];
}

const Menu = (props: any) => {
  return (
    <div className="full-width">
      <List>
        {Object.keys(props?.categories).map((key: any, index) => {
          return <NestedListItem {...props?.categories[key]} key={index} />;
        })}
      </List>
    </div>
  );
};
export default Menu;

const NestedListItem = (prop: any) => {
  const hasChildren = prop.items && prop.items.length > 0;
  const [open, setOpen] = useState(false);
  const user: any = useUser();
  const {todoSocketLists} = useContext(ThemeContext);
  const {t} = useTranslation();
  const getTodoClass = () => {
    if (!todoSocketLists) return '';

    const socketLists = JSON.parse(todoSocketLists);
    if (socketLists?.hasOverdueTasks) {
      return 'red-bg';
    }
    if (socketLists?.hasTasks) {
      return 'yellow-bg';
    }
    return '';
  };

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <>
      {user.roles?.some((item: any) => prop.roles?.includes(item)) && (
        <ListItem
          button
          component={Link}
          to={prop?.items?.length ? '#' : prop?.path.toString()}
          disableGutters={true}
          key={prop.name}
          onClick={(e: any) => {
            e.stopPropagation();
            handleClick();
          }}
          className={'menu__list-item'}
        >
          <div className={'menu__list-item__body'}>
            <div className={'menu__list-item__body__first'}>
              <Grid container style={{width: '247px'}} alignItems="center">
                <Grid item xs={2} sm={2} md={2}>
                  {prop.icon && <IconButton color="inherit">{prop.icon}</IconButton>}
                </Grid>
                <Grid item xs={10} sm={10} md={10} style={{paddingLeft: '5px'}} className="menu-item-name">
                  <span className={prop.name === t('todos.title') ? getTodoClass() : ''}>{prop.name}</span>
                  <Grid />
                </Grid>
              </Grid>
            </div>
            <div className={'menu__list-item__body__second'}>
              {hasChildren && !open && <IconExpandMore />}
              {hasChildren && open && <IconExpandLess />}
            </div>
          </div>
        </ListItem>
      )}
      {hasChildren && open && prop?.items && (
        <>
          <div style={{marginLeft: 10}}>{<Menu categories={prop.items} />}</div>
          {prop?.items?.length > 1 && <hr></hr>}
        </>
      )}
    </>
  );
};
