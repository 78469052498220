import {Switch} from '@material-ui/core';
import React, {ReactElement, useContext, useState} from 'react';
import SupplierOrderItemsTableBodyContext from './Context/SupplierOrterItemsTableBodyContext';

const TogglePurchaseList = (props: ITogglePurchaseList): ReactElement => {
  const {toggleInPurchaseList} = useContext(SupplierOrderItemsTableBodyContext);
  const {id, purchase} = props;
  const [isInPurchaseList, setIsInPurchaseList] = useState(purchase);
  const toggle = () => {
    toggleInPurchaseList({id, purchaseList: !isInPurchaseList});
    setIsInPurchaseList(!isInPurchaseList);
  };

  return <Switch checked={isInPurchaseList} onChange={toggle} name="placeOrderStatusSwitch" color="primary" />;
};

interface ITogglePurchaseList {
  id: string;
  purchase: boolean;
}

export default TogglePurchaseList;
