import {Button} from '@material-ui/core';
import React, {ReactElement, useContext} from 'react';
import {useTranslation} from 'react-i18next';
import jsPDF from 'jspdf';
import {Util} from '../../../modules/api/utils';
import OrderDetailsContext from '../../../screens/Orders/Context/OrderDetailsContext';

interface IProps {
  parcelIndex?: number;
}

const ShippingLabelButton = ({parcelIndex = -1}: IProps): ReactElement => {
  const {order} = useContext(OrderDetailsContext);
  const {t} = useTranslation();

  const displayBase64PdfLabel = (isReturnLabel = false) => {
    const label = getLabel(isReturnLabel);
    const blob = Util.getBlob(label);
    const blobUrl = URL.createObjectURL(blob);
    window.open(blobUrl);
  };

  const getLabel = (isReturnLabel: boolean) => {
    if (isReturnLabel) {
      return order.returnLabelInfo?.base64Pdf;
    }

    if (!order.parcels?.length) {
      return order.shippingLabel?.base64Pdf;
    }

    if (order.parcels?.length && parcelIndex > -1) {
      return order.parcels[parcelIndex].shippingLabel;
    }
  };

  const displayBase64JpgUpsLabel = (isReturnLabel = false) => {
    const doc = new jsPDF({orientation: 'l'});
    const label = isReturnLabel ? order.returnLabelInfo?.gifImage : order.shippingLabel?.gifImage;
    doc.setProperties({
      title: `${order.orderNumber}-UPS-label`,
      subject: `${order.orderNumber}-UPS-label`,
    });
    const width = doc.internal.pageSize.getWidth();
    const height = doc.internal.pageSize.getHeight();
    const imgData = `data:image/gif;base64,${label}`;

    doc.addImage(imgData, 'JPEG', 0, 0, width, height);
    doc.autoPrint();
    doc.output('dataurlnewwindow');
  };

  const shipmentLabelHandler = async () => {
    if (order.shippingLabel?.gifImage) {
      displayBase64JpgUpsLabel();
    } else {
      displayBase64PdfLabel();
    }
  };

  return order.shippingLabel?.base64Pdf || order.shippingLabel?.gifImage || order.shippingLabel?.htmlImage ? (
    <Button variant="contained" className="blue-button" onClick={shipmentLabelHandler}>
      {t('orderDetails.shippingLabel')}
    </Button>
  ) : (
    <></>
  );
};

export default ShippingLabelButton;
