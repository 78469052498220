import {Typography} from '@material-ui/core';
import React, {useEffect, useState} from 'react';
import Form from '../../Form/Form';
import _ from 'lodash';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {ISupplierOrderItem} from '../../../types/SupplierOrders/ISupplierOrderItem';
import * as Yup from 'yup';

export interface ISupplierOrderBacklogModalProps {
  supplierOrderItem: ISupplierOrderItem;
  onSubmit: (values: any) => void;
  onCancel: () => void;
}

const SupplierOrderBacklogModal = (props: ISupplierOrderBacklogModalProps) => {
  const {onSubmit, onCancel, supplierOrderItem} = props;
  const [initialValues, setInitialValues] = useState<{quantity: number} | null>(null);
  const {t} = useTranslation();

  useEffect(() => {
    setInitialValues({quantity: supplierOrderItem.quantity});
  }, [supplierOrderItem]);

  const fields = [
    {
      label: t('supplierOrderDetails.quantity'),
      placeholder: t('supplierOrderDetails.quantity'),
      fieldKey: 'quantity',
      type: 'input',
      fieldKeyType: 'number',
    },
  ];

  const validationSchema = Yup.object().shape({
    quantity: Yup.number().required('Required').min(1).max(supplierOrderItem?.quantity),
  });

  return (
    <>
      <Typography className="modal-title">{t('supplierOrderDetails.moveToBacklog')}</Typography>
      {initialValues ? (
        <Form
          validationSchema={validationSchema}
          initialValues={initialValues}
          fields={fields}
          submitButtonText={t('general.submit')}
          cancelButtonText={t('general.cancel')}
          onSubmit={onSubmit}
          onCancel={onCancel}
        ></Form>
      ) : null}
    </>
  );
};

export default SupplierOrderBacklogModal;
