import React, {useEffect} from 'react';
import {useFetch} from '../../hooks/fetch';
import {Button} from '@material-ui/core';
import {useTranslation} from 'react-i18next';
import {appActions} from '../../modules/app/actions';
import {useDispatch} from 'react-redux';
import {modalActions} from '../../modules/modal/actions';
import {MODALS} from '../Modal/ModalContents';

const CriticalInvoiceActions: React.FC<IActionsProps> = ({row, setTriggerRefreshTable}: any) => {
  const [setInvoiceStatusResponse, setInvoiceStatus] = useFetch<any>('orders_patch');
  const hasSetStatus = ['LAWYER', 'DEBT_COMPANY'].includes(row?.markedStatus);
  const dispatch = useDispatch();
  const {t} = useTranslation();

  const handleSettingStatus = (status: string) => {
    const reqBody = {
      id: row?.orderId,
      ...(status === 'LAWYER' && {'easyBillInfo.qualifiedForSendingToLawyer': true}),
      ...(status === 'DEBT_COMPANY' && {'easyBillInfo.qualifiedForDebtCollectCompany': true}),
      ...(status === 'COMPLETED' && {'easyBillInfo.markedAsCompleted': true}),
      ...(status === 'UNSET' && {
        'easyBillInfo.qualifiedForSendingToLawyer': false,
        'easyBillInfo.qualifiedForDebtCollectCompany': false,
      }),
    };

    setInvoiceStatus(reqBody);
    dispatch(modalActions.closeModal());
  };

  const setStatus = (status: string) => {
    dispatch(
      modalActions.addModal(MODALS.CONFIRM_DIALOG, {
        title: t('general.areYouSure'),
        content: t('general.areYouSure'),
        onYes: () => handleSettingStatus(status),
        onNo: () => dispatch(modalActions.closeModal()),
      }),
    );
  };

  useEffect(() => {
    if (setInvoiceStatusResponse?.error) {
      dispatch(appActions.showSnackBar({text: setInvoiceStatusResponse.error.name, options: {severity: 'error'}}));
    } else if (setInvoiceStatusResponse?.data) {
      dispatch(appActions.showSnackBar({text: t('general.success'), options: {severity: 'success'}}));
      setTriggerRefreshTable(Math.random());
    }

    return () => {
      setInvoiceStatusResponse.data = null;
      setInvoiceStatusResponse.error = null;
      setInvoiceStatusResponse.loaded = null;
    };
  }, [setInvoiceStatusResponse]);

  return (
    <div>
      {!hasSetStatus && (
        <>
          <Button
            variant="contained"
            className="blue-button"
            onClick={(e) => {
              e.preventDefault();
              setStatus('LAWYER');
            }}
          >
            {t('criticalOverdueList.sendToLawyer')}
          </Button>
          <Button
            variant="contained"
            className="yellow-button"
            onClick={(e) => {
              e.preventDefault();
              setStatus('DEBT_COMPANY');
            }}
          >
            {t('criticalOverdueList.sendToDebtCollectionCompany')}
          </Button>
        </>
      )}
      {hasSetStatus && (
        <>
          <Button
            variant="contained"
            className="black-button"
            onClick={(e) => {
              e.preventDefault();
              setStatus('COMPLETED');
            }}
          >
            {t('criticalOverdueList.complete')}
          </Button>
          <Button
            variant="contained"
            className="blue-button"
            onClick={(e) => {
              e.preventDefault();
              setStatus('UNSET');
            }}
          >
            {t('criticalOverdueList.unset')}
          </Button>
        </>
      )}
    </div>
  );
};

interface IActionsProps {
  setTriggerRefreshTable: () => any;
}

export default CriticalInvoiceActions;
