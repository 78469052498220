import {Table, TableRow, TableBody, TableHead, TableCell} from '@material-ui/core';
import React, {ReactElement, useContext, useEffect} from 'react';
import eventBus from '../../lib/eventBus';
import {useFetch, useStorlessFetch} from '../../hooks/fetch';
import {ProductPicker, ProductPickType} from './CreatingOrders/ProductPicker';
import {IDetailedOrderItem} from '../../types/Orders/IDetailedOrder';
import EditableOrderRow from './CreatingOrders/EditableOrderRow';
import ProductInformationDrawer from './CreatingOrders/ProductInformationDrawer';
import {useTranslation} from 'react-i18next';
import CreateOrderContext from '../../screens/Orders/Context/CreateOrderContext';
import {modalActions} from '../../modules/modal/actions';
import {MODALS} from '../Modal/ModalContents';
import {useDispatch} from 'react-redux';

interface IUpdateOrderItemsProps {
  orderId: string;
  editingMode: boolean;
}

const UpdateOrderItems = (props: IUpdateOrderItemsProps): ReactElement<IUpdateOrderItemsProps> => {
  const {t} = useTranslation();
  const editingMode = props.editingMode;
  const {order, patchOrderResponse, submitOrderResponse, setOrder, doGetOrder} = useContext(CreateOrderContext);
  const [removeOrderItem, doRemoveOrderItem] = useFetch('remove_order_item');
  const [addOrderItem, doAddOrderItem] = useFetch('add_order_item');
  const [updateOrderItem, doUpdateOrderItem] = useStorlessFetch('update_order_item');
  const dispatch = useDispatch();

  const remove = (orderItemId: string) => {
    dispatch(
      modalActions.addModal(MODALS.CONFIRM_DIALOG, {
        title: t('general.areYouSure'),
        content: t('general.areYouSure'),
        onYes: () => {
          const data = {
            orderId: props.orderId,
            orderItemId,
          };

          setOrder((prevState: any) => {
            return {
              ...prevState,
              orderItems: order.orderItems.filter((item: any) => item.orderItemId !== orderItemId),
            };
          });

          doRemoveOrderItem(data);
          dispatch(modalActions.closeModal());
        },
        onNo: () => dispatch(modalActions.closeModal()),
      }),
    );
  };

  useEffect(() => {
    if (patchOrderResponse?.data || submitOrderResponse?.data) {
      const preparedOrderItems = order.orderItems.map((item: any) => {
        return {...item, vat: order.vatPercentage, orderId: order.orderId};
      });
      doUpdateOrderItem({orderItems: preparedOrderItems});
    }
  }, [patchOrderResponse?.data, submitOrderResponse?.data]);

  useEffect(() => {
    if (addOrderItem.data) {
      doGetOrder({id: order.orderId});
    }
  }, [addOrderItem]);

  const showDetailsForOrderItem = (orderItem: IDetailedOrderItem) => {
    eventBus.dispatch('openDrawer', {
      orderItem: orderItem,
    });
  };

  return order ? (
    <>
      <ProductPicker doAddOrderItem={doAddOrderItem} productPickType={ProductPickType.CUSTOMER_ORDER} />

      <div className="data-table-wrapper" style={{marginTop: '35px'}}>
        <div className="table-scroll">
          <div className="data-table-wrapper__action-buttons">
            <div className="data-table-wrapper__title">{t('orderDetails.orderItems')}</div>
          </div>

          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Pos.</TableCell>
                <TableCell>{t('products.shopwareId')}</TableCell>
                <TableCell style={{width: '100px'}}>{t('orderItems.quantity')}</TableCell>
                <TableCell>{t('products.title')}</TableCell>
                <TableCell style={{width: '100px'}}>VK Netto</TableCell>
                <TableCell style={{width: '100px'}}>VK Brutto</TableCell>
                <TableCell style={{width: '100px'}}>Rabatt(%)</TableCell>
                <TableCell>Netto Gesamt</TableCell>
                <TableCell style={{width: '100px'}} />
              </TableRow>
            </TableHead>
            <TableBody>
              {order.orderItems?.map((orderItem: IDetailedOrderItem, index: number) => {
                return (
                  <EditableOrderRow
                    key={orderItem.orderItemId}
                    index={index}
                    orderItemId={orderItem.orderItemId}
                    removeOrderItem={remove}
                    showDetailsForOrderItem={showDetailsForOrderItem}
                    editingMode={editingMode}
                  />
                );
              })}
            </TableBody>
          </Table>
        </div>
      </div>

      {!editingMode && <ProductInformationDrawer order={order} />}
    </>
  ) : (
    <></>
  );
};
export default UpdateOrderItems;
