import {IProduct} from '../Product/IProduct';
import {SupplierOrderItemScanStatus} from './SupplierOrderItemScanStatus';
import {SupplierOrderItemStatus} from './SupplierOrderItemStatus';

export enum ISupplierOrderType {
  'PRODUCT' = 'PRODUCT',
  'SERVICE' = 'SERVICE',
  'CREDIT_NOTE' = 'CREDIT_NOTE',
}

export interface ISupplierOrderItem {
  _id: any;
  id: string;
  supplier: any;
  supplierName: string;
  product: IProduct;
  externalArticleId: string;
  quantity: number;
  price: number;
  status: SupplierOrderItemStatus;
  supplierOrder: any;
  scannedBarcode: string;
  scanStatus: SupplierOrderItemScanStatus;
  quantityDelivered: number;
  tempQuantity?: number;
  productTitle: string;
  ordersWaitingForProduct?: {orderId: string; orderNumber: string}[];
  purchaseList: boolean;
  note?: string;
  createdAt?: any;
  ignoreForSuggestions?: boolean;
  supplierOrderNumber: number;
  type: ISupplierOrderType;
}
