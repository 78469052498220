import React from 'react';
import {useTranslation} from 'react-i18next';
import DataTable from '../../components/Table/Table';
import {DataTableItemType} from '../../components/Table/interfaces/IHeaderAccessor';

export default function EbayRepublishingProducts() {
  const {t} = useTranslation();

  return (
    <div className="products">
      <div style={{marginBottom: '50px', height: '82%'}}>
        <DataTable
          className="products__table"
          headers={[
            {
              kind: 'accessor',
              name: '',
              accessor: 'uploadedImage',
              sortable: false,
              type: 'image',
            },
            {
              kind: 'accessor',
              name: t('eBayMultiVariant.inventoryItemGroupKey'),
              accessor: 'inventoryItemGroupKey',
              sortable: false,
            },
            {
              kind: 'accessor',
              name: t('eBayMultiVariant.title'),
              accessor: 'title',
              sortable: false,
            },
            {
              kind: 'accessor',
              name: t('eBayMultiVariant.createdAt'),
              accessor: 'createdAt',
              sortable: false,
              type: DataTableItemType.DATE,
            },
            {
              kind: 'accessor',
              name: t('eBayMultiVariant.lastPublishedDate'),
              accessor: 'lastPublishedDate',
              sortable: false,
              type: DataTableItemType.DATE,
            },
          ]}
          endpoint={'ebay_multi_variants_to_republish'}
          target={'ebay-multi-variant-product'}
          title={t('general.eBayMultiVariantsToRePublish')}
          options={{deletedAt: null}}
          defaultSort={{key: 'createdAt', value: -1}}
        />
      </div>
      <div style={{marginBottom: '50px', height: '82%'}}>
        <DataTable
          className="products__table"
          headers={[
            {
              kind: 'accessor',
              name: t('productDetails.productId'),
              accessor: 'productId',
              sortable: false,
            },
            {
              kind: 'accessor',
              name: t('productDetails.title'),
              accessor: 'title',
              sortable: false,
            },
            {
              kind: 'accessor',
              name: t('eBayMultiVariant.lastPublishedDate'),
              accessor: 'eBayProps.lastPublishedDate',
              sortable: false,
              type: DataTableItemType.DATE,
            },
          ]}
          endpoint={'ebay_products_to_republish'}
          target={'products'}
          title={t('general.eBayProductsToRePublish')}
          options={{deletedAt: null}}
          defaultSort={{key: 'createdAt', value: -1}}
        />
      </div>
    </div>
  );
}
