import React, {createContext} from 'react';
import {IOrder} from '../../../types/Orders/IOrder';
import {ICustomField} from '../../../types/CustomFields/CustomField';

const OrderDetailsContext = createContext({} as IOrderDetailsContext);
export const OrderDetailsProvider = OrderDetailsContext.Provider;
export default OrderDetailsContext;

interface IOrderDetailsContext {
  easyBillRetryResponse: any;
  orderProblemTable: any;
  orderId: string;
  order: IOrder;
  orderDetails?: any;
  retryEasyBillInvoiceCreation: (data: any) => void;
  refreshOrderProblemTable: (data: boolean) => void;
  getOrderDetails: (data: any) => void;
  setOrder: (data: IOrder) => void;
  customFields: ICustomField[];
}
