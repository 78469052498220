import React, {useRef} from 'react';
import {Link, Route, Switch} from 'react-router-dom';
import HeaderBar from '../components/Header/HeaderBar';
import NewSupplierArticle from '../screens/SupplierArticle/NewSupplierArticle';
import ChangePassword from '../screens/Auth/ChangePassword';
import FormTest from '../screens/FormTest';
import Home from '../screens/Home/Home';
import Orders from '../screens/Orders/Orders/Orders';
import Products from '../screens/Products/ProductsScreen/Products';
import ImportProducts from '../screens/ImportProducts/ImportProducts';
import ProductDetails from '../screens/Products/ProductDetails';
import IgnoredSupplierArticle from '../screens/SupplierArticle/IgnoredSupplierArticle';
import Supplier from '../screens/Supplier/Supplier';
import ActivatedSupplierArticles from '../screens/SupplierArticle/ActivatedSupplierArticles';
import SupplierArticleDetail from '../screens/SupplierArticle/SupplierArticleDetail';
import {APP_ROUTES} from './routes';
import Customers from '../screens/Customers/Customers';
import OrderDetails from '../screens/Orders/OrderDetails';
import CustomerDetails from '../screens/Customers/CustomerDetails';
import DeactivatedProduct from '../screens/Products/DeactivatedProduct';
import ExpiredProduct from '../screens/Products/ExpiredProduct';
import ShopwarePricing from '../screens/Pricing/ShopwarePricing';
import CountryShippingProviders from '../screens/CountryShippingProviders/CountryShippingProviders';
import UpsConfigDetails from '../screens/ShipperConfig/UpsConfigDetails/UpsConfigDetails';
import DhlConfigDetails from '../screens/ShipperConfig/DhlConfigDetails/DhlConfigDetails';
import OrderProblemTypes from '../screens/OrderProblems/OrderProblemTypes';
import OrderProblems from '../screens/OrderProblems/OrderProblems';
import SyncStatuses from '../screens/SyncStatuses/SyncStatuses';
import SupplierOrderItems from '../screens/SupplierOrders/SupplierOrderItems';
import SupplierOrders from '../screens/SupplierOrders/SupplierOrders';
import UnfinishedSupplierOrders from '../screens/SupplierOrders/UnfinishedSupplierOrders';
import SupplierOrderDetails from '../screens/SupplierOrders/SupplierOrderDetails';
import CustomerReturns from '../screens/CustomerReturns/CustomerReturns';
import SupplierDetails from '../screens/Supplier/SupplierDetails';
import OrdersReadyForShipping from '../screens/Orders/OrdersReadyForShipping';
import CompanyConfiguration from '../screens/CompanyConfiguration/CompanyConfiguration';
import AmazonPricing from '../screens/Pricing/AmazonPricing';
import {ProblemOrderType} from '../types/OrderProblems/ProblemOrderType';
import OrderItemsWithoutProduct from '../screens/Orders/OrderItemsWithoutProduct';
import {useSelector} from 'react-redux';
import Menu, {IMenuProps} from '../components/Menu/Menu';
import {useTranslation} from 'react-i18next';
import {
  Error,
  Warning,
  LocalShipping,
  Settings,
  ShoppingCart,
  ImportExport,
  Check,
  AddCircle,
  Remove,
  NotInterested,
  Language,
  Info,
  LibraryAddCheckOutlined,
  Person,
  Inbox,
  HourglassEmpty,
  CheckCircle,
  PaymentOutlined,
  PublishOutlined,
  FormatListBulleted,
  ArrowRightAlt,
  Euro,
  TransitEnterexit,
  CropFree,
  Image,
  Category,
} from '@material-ui/icons';
import EuroSymbolIcon from '@material-ui/icons/EuroSymbol';
import ServiceStatus from '../screens/ServiceStatus/ServiceStatus';
import AmazonTrackingNumbersInfo from '../screens/Orders/AmazonTrackingNumbersInfo';
import AmazonSubmissionsInfo from '../screens/Orders/AmazonSubmissionsInfo';
import NonEuOrders from '../screens/Orders/NonEuOrders';
import MergeProducts from '../screens/Products/MergeProducts';
import InventoryProducts from '../screens/Products/InventoryProducts';
import ManualSupplierArticle from '../screens/SupplierArticle/ManualSupplierArticle';
import SupplierOrderItemsWithoutStock from '../screens/SupplierOrders/SupplierOrderItemsWithoutStock';
import MatchingSupplierArticle from '../screens/SupplierArticle/MatchingSupplierArticle';
import AmazonStockSkus from '../screens/Products/AmazonStockSkus';
import CreateOrder from '../screens/Orders/CreateOrder';
import OrdersWithInvoiceIssue from '../screens/Orders/OrdersWithInvoiceIssue';
import OrdersWithOverdueInvoices from '../screens/Orders/OrdersWithOverdueInvoices';
import ManuallyCompletedOrders from '../screens/Orders/ManuallyCompletedOrders';
import ManuallyCompletedOrderDetails from '../screens/Orders/ManuallyCompletedOrderDetails';
import UnfinishedSupplierOrderItems from '../screens/SupplierOrders/UnfinishedSupplierOrderItems';
import MatchingSupplierArticleMpns from '../screens/SupplierArticle/MatchingSupplierArticleMpns';
import PaymentReminderList from '../screens/Orders/PaymentReminderList';
import CustomListsReadyForShipping from '../screens/Orders/CustomListsReadyForShipping';
import CustomListsReadyForShippingSettings from '../screens/Orders/CustomListsReadyForShippingSettings';
import OrdersReadyForShippingCustom from '../screens/Orders/OrdersReadyForShippingCustom';
import MergeCustomers from '../screens/Customers/MergeCustomers';
import CriticalOverduePayments from '../screens/Orders/CriticalOverduePayments';
import OpenInvoices from '../screens/Orders/OpenInvoices';
import {VideoRecorderProvider} from '../components/VideoRecorder/VideoRecorderContext';
import {Grid} from '@material-ui/core';
import {appActions} from '../modules/app/actions';
import {useDispatch} from 'react-redux';
import {modalActions} from '../modules/modal/actions';
import {MODALS} from '../components/Modal/ModalContents';
import Users from '../screens/Users/Users';
import {USER_ROLE} from '../types/User/UserRole';
import withRoleGuard from '../guards/Role-Guard';
import SupplierOrderItemsBacklog from '../screens/SupplierOrders/SupplierOrderItemsBacklog';
import EbayPricing from '../screens/Pricing/Ebay/EbayPricing';
import DatevPaymentLists from '../screens/Pricing/DatevPaymentLists';
import PricingItems from '../screens/Products/PricingItems';
import GalaxusPricing from '../screens/Pricing/GalaxusPricing';
import TodoList from '../screens/Todo/TodoList';
import EbayMultiVariantItemScreen from '../screens/Ebay/EbayMultiVariantItemScreen';
import EbayMultiVariants from '../screens/Products/EbayMultiVariants';
import SupplierArticleCsvProcessing from '../screens/SupplierArticle/SupplierOrderCsvProcessing';
import OpenSupplierOrders from '../screens/SupplierOrders/OpenSupplierOrders';
import EndOfLifeProduct from '../screens/Products/EndOfLifeProduct';
import KauflandPricing from '../screens/Pricing/KauflandPricing';
import CustomConfigScreen from '../screens/CustomConfigScreen';
import EbayTrackingNumbers from '../screens/Orders/EbayTrackingNumbers';
import SupplierOrdersDue from '../screens/SupplierOrders/SupplierOrdersDue';
import ProductsWithoutMinimumMargin from '../screens/Products/ProductsWithoutMinimumMargin';
import ProductsByShopStatus from '../screens/Products/ProductsByShopStatus';
import ProductsWithCalculatedPrice from '../screens/Products/ProductsWithCalculatedPrice';
import SupplierComplaintScreen from '../screens/SupplierComplaintScreen';
import SupplierComplaintDetails from '../screens/SupplierOrders/SupplierComplaintDetails';
import ChangeLog from '../screens/ChangeLog/ChangeLog';
import CustomFields from '../screens/CustomFields/CustomFields';
import UndeliveredDhlOrders from '../screens/Orders/UndeliveredDhlOrders';
import InactiveAmazonProducts from '../screens/Products/InactiveAmazonProducts';
import SupplierPayments from '../screens/Supplier/SupplierPayments';
import ProductsStockComparision from '../screens/Products/ProductsStockComparision';
import MetroPricing from '../screens/Pricing/MetroPricing';
import FnacPricing from '../screens/Pricing/FnacPricing';
import EbayRepublishingProducts from '../screens/Products/EbayRepublishingProducts';
import LabelScanner from '../screens/LabelScanner/LabelScanner';
import StaticFiles from '../screens/Config/StaticFiles';
import ManufacturerImages from '../screens/Pricing/Ebay/ManufacturerImages';
import ProductsStockPerDay from '../screens/Products/ProductsStockPerDay';
import UndeliveredUpsOrders from '../screens/UPS/UndeliveredUpsOrders';
import EbayCategories from '../screens/Pricing/Ebay/EbayCategories';

const AppRouter = () => {
  const {t} = useTranslation('translation');
  const showMenuSelector = useSelector((state: any) => state.showMenu);
  const startRecordingRef = useRef<HTMLButtonElement>(null);
  const stopRecordingRef = useRef<HTMLButtonElement>(null);
  const dispatch = useDispatch();

  const categories: IMenuProps[] = [
    {
      name: t('general.dashboard'),
      path: '/',
      icon: <img src="/icons/dashboard.webp" />,
      roles: [USER_ROLE.ADMIN, USER_ROLE.STANDARD],
    },
    {
      name: t('general.orders'),
      path: '',
      icon: <img src="/icons/checkout.webp" />,
      roles: [USER_ROLE.ADMIN, USER_ROLE.STANDARD],
      items: [
        {
          name: t('general.orders'),
          path: '/orders',
          icon: <img src="/icons/checkout.webp" />,
          roles: [USER_ROLE.ADMIN, USER_ROLE.STANDARD],
        },
        {
          name: t('general.readyForShipping'),
          path: '/orders-ready-for-shipping',
          icon: <ShoppingCart />,
          roles: [USER_ROLE.ADMIN, USER_ROLE.STANDARD],
        },
        {
          name: t('general.customReadyForShipping'),
          path: '/custom-lists-ready-for-shipping',
          icon: <ShoppingCart />,
          roles: [USER_ROLE.ADMIN, USER_ROLE.STANDARD],
        },
        {
          name: t('general.nonEuOrders'),
          path: '/non-eu-orders',
          icon: <ShoppingCart />,
          roles: [USER_ROLE.ADMIN, USER_ROLE.STANDARD],
        },
        {
          name: t('general.offers'),
          path: '/offers',
          icon: <ShoppingCart />,
          roles: [USER_ROLE.ADMIN, USER_ROLE.STANDARD],
        },
        {
          name: t('general.amazonTrackingNumbersInfo'),
          path: '/amazon-tracking-numbers-info',
          icon: <Info />,
          roles: [USER_ROLE.ADMIN, USER_ROLE.STANDARD],
        },
        {
          name: t('general.eBayTrackingInfo'),
          path: '/ebay-tracking-numbers-info',
          icon: <Info />,
          roles: [USER_ROLE.ADMIN, USER_ROLE.STANDARD],
        },
        {
          name: t('general.amazonSubmissionsInfo'),
          path: '/amazon-submissions-info',
          icon: <Info />,
          roles: [USER_ROLE.ADMIN, USER_ROLE.STANDARD],
        },
        {
          name: t('general.orderItemsWithoutProduct'),
          path: '/order-items-without-product',
          icon: <Error />,
          roles: [USER_ROLE.ADMIN, USER_ROLE.STANDARD],
        },
        {
          name: t('general.problems'),
          path: '/orderProblems',
          icon: <Error />,
          roles: [USER_ROLE.ADMIN, USER_ROLE.STANDARD],
        },
        {
          name: t('general.easyBillIssues'),
          path: '/orders-easybill-issues',
          icon: <Warning />,
          roles: [USER_ROLE.ADMIN, USER_ROLE.STANDARD],
        },
        {
          name: t('general.overdueInvoices'),
          path: '/orders-easybill-overdue-invoices',
          icon: <Warning />,
          roles: [USER_ROLE.ADMIN, USER_ROLE.STANDARD],
        },
        {
          name: t('general.manuallyCompletedOrders'),
          path: '/manually-completed-orders',
          icon: <CheckCircle />,
          roles: [USER_ROLE.ADMIN, USER_ROLE.STANDARD],
        },
        {
          name: t('general.paymentReminderList'),
          path: '/payment-reminder-list',
          icon: <PaymentOutlined />,
          roles: [USER_ROLE.ADMIN, USER_ROLE.STANDARD],
        },
        {
          name: t('general.criticalOverdueList'),
          path: '/critical-overdue-list',
          icon: <PaymentOutlined />,
          roles: [USER_ROLE.ADMIN, USER_ROLE.STANDARD],
        },
        {
          name: t('general.openInvoices'),
          path: '/open-invoices',
          icon: <PaymentOutlined />,
          roles: [USER_ROLE.ADMIN, USER_ROLE.STANDARD],
        },
        {
          name: t('general.undeliveredDhlOrders'),
          path: '/undelivered-dhl-orders',
          icon: <TransitEnterexit />,
          roles: [USER_ROLE.ADMIN, USER_ROLE.STANDARD],
        },
        {
          name: t('general.undeliveredUpsOrders'),
          path: '/undelivered-ups-orders',
          icon: <TransitEnterexit />,
          roles: [USER_ROLE.ADMIN, USER_ROLE.STANDARD],
        },
      ],
    },
    {
      name: t('general.supplierOrders'),
      path: '',
      icon: <img src="/icons/sent.webp" />,
      roles: [USER_ROLE.ADMIN, USER_ROLE.STANDARD],
      items: [
        {
          name: t('general.supplierOrderItems'),
          path: '/supplierOrderItems',
          icon: <ShoppingCart />,
          roles: [USER_ROLE.ADMIN, USER_ROLE.STANDARD],
        },
        {
          name: t('general.supplierItemsWithoutStock'),
          path: '/supplier-items-without-stock',
          icon: <ShoppingCart />,
          roles: [USER_ROLE.ADMIN, USER_ROLE.STANDARD],
        },
        {
          name: t('general.supplierOrders'),
          path: '/supplierOrders',
          icon: <ShoppingCart />,
          roles: [USER_ROLE.ADMIN, USER_ROLE.STANDARD],
        },
        {
          name: t('general.problems'),
          path: '/supplierOrderProblems',
          icon: <Error />,
          roles: [USER_ROLE.ADMIN, USER_ROLE.STANDARD],
        },
        {
          name: t('general.unfinishedSupplierOrders'),
          path: '/unfinished-supplier-orders',
          icon: <HourglassEmpty />,
          roles: [USER_ROLE.ADMIN, USER_ROLE.STANDARD],
        },
        {
          name: t('general.supplierOrdersBacklogList'),
          path: '/supplier-order-items-backlog',
          icon: <HourglassEmpty />,
          roles: [USER_ROLE.ADMIN, USER_ROLE.STANDARD],
        },
        {
          name: t('general.openSupplierOrders'),
          path: '/open-supplier-orders',
          icon: <ShoppingCart />,
          roles: [USER_ROLE.ADMIN, USER_ROLE.STANDARD],
        },
        {
          name: t('supplierOrders.dueReport'),
          path: '/supplier-orders-due',
          icon: <Euro />,
          roles: [USER_ROLE.ADMIN, USER_ROLE.STANDARD],
        },
        {
          name: t('general.supplierComplaints'),
          path: '/supplier-complaints',
          icon: <img src="/icons/product-return.webp" />,
          roles: [USER_ROLE.ADMIN, USER_ROLE.STANDARD],
        },
        {
          name: t('general.supplierPayments'),
          path: '/supplier-payments',
          icon: <Euro />,
          roles: [USER_ROLE.ADMIN, USER_ROLE.STANDARD],
        },
      ],
    },
    {
      name: t('general.products'),
      path: '',
      icon: <img src="/icons/printer.webp" />,
      roles: [USER_ROLE.ADMIN, USER_ROLE.STANDARD, USER_ROLE.PRODUCT_DESCRIPTIONS],
      items: [
        {
          name: t('general.products'),
          path: '/products',
          icon: <img src="/icons/printer.webp" />,
          roles: [USER_ROLE.ADMIN, USER_ROLE.STANDARD, USER_ROLE.PRODUCT_DESCRIPTIONS],
        },
        {
          name: t('general.importProducts'),
          path: '/importProducts',
          icon: <ImportExport />,
          roles: [USER_ROLE.ADMIN, USER_ROLE.STANDARD],
        },
        {
          name: t('translation:mergeProducts.screenTitle'),
          path: '/products/merge',
          icon: <img src="/icons/merge.webp" />,
          roles: [USER_ROLE.ADMIN, USER_ROLE.STANDARD],
        },
        {
          name: t('general.expiredProducts'),
          path: '/expiredProducts',
          icon: <Remove />,
          roles: [USER_ROLE.ADMIN, USER_ROLE.STANDARD, USER_ROLE.PRODUCT_DESCRIPTIONS],
        },
        {
          name: t('general.endOfLifeProducts'),
          path: '/end-of-life-products',
          icon: <Remove />,
          roles: [USER_ROLE.ADMIN, USER_ROLE.STANDARD, USER_ROLE.PRODUCT_DESCRIPTIONS],
        },
        {
          name: t('general.deactivatedProducts'),
          path: '/deactivatedProducts',
          icon: <NotInterested />,
          roles: [USER_ROLE.ADMIN, USER_ROLE.STANDARD, USER_ROLE.PRODUCT_DESCRIPTIONS],
        },
        {
          name: t('general.productsInventory'),
          path: '/products-inventory',
          icon: <ImportExport />,
          roles: [USER_ROLE.ADMIN, USER_ROLE.STANDARD],
        },
        {
          name: t('general.amazonStockSkus'),
          path: '/amazon-stock-skus',
          icon: <Inbox />,
          roles: [USER_ROLE.ADMIN, USER_ROLE.STANDARD],
        },
        {
          name: t('pricingItems.screenTitle'),
          path: '/pricing-items',
          icon: <EuroSymbolIcon />,
          roles: [USER_ROLE.ADMIN, USER_ROLE.STANDARD],
        },
        {
          name: t('general.eBayProducts'),
          path: '/ebay-multi-variant-items',
          icon: <img src="/icons/printer.webp" />,
          roles: [USER_ROLE.ADMIN, USER_ROLE.STANDARD],
        },
        {
          name: t('general.eBayProductsToRepublish'),
          path: '/ebay-products-to-republish',
          icon: <img src="/icons/reload.webp" />,
          roles: [USER_ROLE.ADMIN, USER_ROLE.STANDARD],
        },
        {
          name: t('general.productsWithCalculatedPrice'),
          path: '/products-with-calculated-price',
          icon: <img src="/icons/printer.webp" />,
          roles: [USER_ROLE.ADMIN, USER_ROLE.STANDARD, USER_ROLE.PRODUCT_DESCRIPTIONS],
        },
        {
          name: t('general.productShopStatuses'),
          path: '/products-shop-statuses',
          icon: <img src="/icons/printer.webp" />,
          roles: [USER_ROLE.ADMIN, USER_ROLE.STANDARD, USER_ROLE.PRODUCT_DESCRIPTIONS],
        },
        {
          name: t('general.inactiveAmazonProducts'),
          path: '/inactive-amazon-products',
          icon: <img src="/icons/printer.webp" />,
          roles: [USER_ROLE.ADMIN, USER_ROLE.STANDARD],
        },
        {
          name: t('general.productsStockComparision'),
          path: '/products-stock-comparison',
          icon: <img src="/icons/printer.webp" />,
          roles: [USER_ROLE.ADMIN, USER_ROLE.STANDARD],
        },
        {
          name: t('general.productsStockPerDay'),
          path: '/products-stock-per-day',
          icon: <img src="/icons/printer.webp" />,
          roles: [USER_ROLE.ADMIN],
        },
      ],
    },
    {
      name: t('general.supplierArticles'),
      path: '',
      icon: <img src="/icons/article.webp" />,
      roles: [USER_ROLE.ADMIN, USER_ROLE.STANDARD],
      items: [
        {
          name: t('general.approved'),
          path: '/articles',
          icon: <Check />,
          roles: [USER_ROLE.ADMIN, USER_ROLE.STANDARD],
        },
        {
          name: t('general.new'),
          path: '/newArticles',
          icon: <AddCircle />,
          roles: [USER_ROLE.ADMIN, USER_ROLE.STANDARD],
        },
        {
          name: t('general.manualArticles'),
          path: '/manual-articles',
          icon: <Person />,
          roles: [USER_ROLE.ADMIN, USER_ROLE.STANDARD],
        },
        {
          name: t('general.matchingArticles'),
          path: '/matching-articles',
          icon: <LibraryAddCheckOutlined />,
          roles: [USER_ROLE.ADMIN, USER_ROLE.STANDARD],
        },
        {
          name: t('general.matchingArticlesMpn'),
          path: '/matching-articles-by-mpn',
          icon: <LibraryAddCheckOutlined />,
          roles: [USER_ROLE.ADMIN, USER_ROLE.STANDARD],
        },
        {
          name: t('general.matchingIgnoredEans'),
          path: '/matching-ignored-eans',
          icon: <LibraryAddCheckOutlined />,
          roles: [USER_ROLE.ADMIN, USER_ROLE.STANDARD],
        },
        {
          name: t('general.ignored'),
          path: '/ignoredArticles',
          icon: <NotInterested />,
          roles: [USER_ROLE.ADMIN, USER_ROLE.STANDARD],
        },
        {
          name: t('supplierOrders.csvProcessing'),
          path: '/supplier-article-csv-processing',
          icon: <ArrowRightAlt />,
          roles: [USER_ROLE.ADMIN, USER_ROLE.STANDARD],
        },
      ],
    },
    {
      name: t('general.labelScanner'),
      path: '/label-scanner',
      icon: <CropFree />,
      roles: [USER_ROLE.ADMIN, USER_ROLE.STANDARD],
    },
    {
      name: t('general.customers'),
      path: '',
      icon: <img src="/icons/customer.webp" />,
      roles: [USER_ROLE.ADMIN, USER_ROLE.STANDARD],
      items: [
        {
          name: t('general.customers'),
          path: '/customers',
          icon: <img src="/icons/customer.webp" />,
          roles: [USER_ROLE.ADMIN, USER_ROLE.STANDARD],
        },
        {
          name: t('general.customerReturns'),
          path: '/customerReturns',
          icon: <img src="/icons/product-return.webp" />,
          roles: [USER_ROLE.ADMIN, USER_ROLE.STANDARD],
        },
        {
          name: t('general.mergeCustomers'),
          path: '/customers-merge',
          icon: <FormatListBulleted />,
          roles: [USER_ROLE.ADMIN, USER_ROLE.STANDARD],
        },
      ],
    },
    {
      name: t('syncStatus.title'),
      path: APP_ROUTES.SYNC_STATUSES,
      icon: <img src="/icons/reload.webp" />,
      roles: [USER_ROLE.ADMIN, USER_ROLE.STANDARD],
    },
    {
      name: t('todos.title'),
      path: APP_ROUTES.TODO_LIST,
      icon: <img src="/icons/add.webp" />,
      roles: [USER_ROLE.ADMIN, USER_ROLE.STANDARD],
    },
    {
      name: t('general.config'),
      path: '',
      icon: <img src="/icons/settings.webp" />,
      roles: [USER_ROLE.ADMIN, USER_ROLE.STANDARD],
      items: [
        {
          name: t('general.upsConfig'),
          path: '/upsConfig',
          icon: <Settings />,
          roles: [USER_ROLE.ADMIN, USER_ROLE.STANDARD],
        },
        {
          name: t('general.dhlConfig'),
          path: '/dhlConfig',
          icon: <Settings />,
          roles: [USER_ROLE.ADMIN, USER_ROLE.STANDARD],
        },
        {
          name: t('general.suppliers'),
          path: '/suppliers',
          icon: <LocalShipping />,
          roles: [USER_ROLE.ADMIN, USER_ROLE.STANDARD],
        },
        {
          name: t('general.shopwarePricing'),
          path: '/shopware-pricing',
          icon: <EuroSymbolIcon />,
          roles: [USER_ROLE.ADMIN, USER_ROLE.STANDARD],
        },
        {
          name: t('general.eBayPricing'),
          path: '/ebay-pricing',
          icon: <EuroSymbolIcon />,
          roles: [USER_ROLE.ADMIN, USER_ROLE.STANDARD],
        },
        {
          name: t('general.amazonPricing'),
          path: '/amazon-pricing',
          icon: <EuroSymbolIcon />,
          roles: [USER_ROLE.ADMIN, USER_ROLE.STANDARD],
        },
        {
          name: t('general.galaxusPricingSideMenu'),
          path: '/galaxus-pricing',
          icon: <EuroSymbolIcon />,
          roles: [USER_ROLE.ADMIN, USER_ROLE.STANDARD],
        },
        {
          name: t('general.kauflandPricingSideMenu'),
          path: '/kaufland-pricing',
          icon: <EuroSymbolIcon />,
          roles: [USER_ROLE.ADMIN, USER_ROLE.STANDARD],
        },
        {
          name: t('general.metroPricing'),
          path: '/metro-pricing',
          icon: <EuroSymbolIcon />,
          roles: [USER_ROLE.ADMIN, USER_ROLE.STANDARD],
        },
        {
          name: t('general.fnacPricing'),
          path: '/fnac-pricing',
          icon: <EuroSymbolIcon />,
          roles: [USER_ROLE.ADMIN, USER_ROLE.STANDARD],
        },
        {
          name: t('general.datevLists'),
          path: '/datev-payment-lists',
          icon: <EuroSymbolIcon />,
          roles: [USER_ROLE.ADMIN, USER_ROLE.STANDARD],
        },
        {
          name: t('general.countries'),
          path: '/countryShippingProviders',
          icon: <Language />,
          roles: [USER_ROLE.ADMIN, USER_ROLE.STANDARD],
        },
        {
          name: t('general.problemTypes'),
          path: '/orderProblemTypes',
          icon: <Warning />,
          roles: [USER_ROLE.ADMIN, USER_ROLE.STANDARD],
        },
        {
          name: t('general.customFields'),
          path: '/custom-fields',
          icon: <Settings />,
          roles: [USER_ROLE.ADMIN],
        },
        {
          name: t('general.companyConfig'),
          path: '/company-config',
          icon: <Settings />,
          roles: [USER_ROLE.ADMIN, USER_ROLE.STANDARD],
        },
        {
          name: t('general.customConfig'),
          path: '/custom-config',
          icon: <Settings />,
          roles: [USER_ROLE.ADMIN, USER_ROLE.STANDARD],
        },
        {
          name: t('general.manufacturerImages'),
          path: '/ebay-images',
          icon: <Image />,
          roles: [USER_ROLE.ADMIN, USER_ROLE.STANDARD],
        },
        {
          name: t('general.eBayCategories'),
          path: '/ebay-categories',
          icon: <Category />,
          roles: [USER_ROLE.ADMIN, USER_ROLE.STANDARD],
        },
        {
          name: t('general.users'),
          path: '/users',
          icon: <img src="/icons/customer.webp" />,
          roles: [USER_ROLE.ADMIN],
        },
      ],
    },
    {
      name: t('changeLog.title'),
      path: APP_ROUTES.CHANGE_LOG,
      icon: <PublishOutlined />,
      roles: [USER_ROLE.ADMIN],
    },
  ];

  const onLogout = () => {
    dispatch(appActions.doLogout());
    dispatch(modalActions.closeModal());
  };

  const handleLogout = () => {
    dispatch(
      modalActions.addModal(MODALS.CONFIRM_DIALOG, {
        title: t('general.areYouSure'),
        content: t('general.logoutMessage'),
        onYes: onLogout,
        onNo: () => dispatch(modalActions.closeModal()),
      }),
    );
  };

  const standardPermssions = [USER_ROLE.ADMIN, USER_ROLE.STANDARD];
  const Offers = (props: any) => <Orders {...props} isOffer={true} />;
  const OffersWithGuard = withRoleGuard(standardPermssions, Offers);
  const OrderProblemsWithGuard = withRoleGuard(standardPermssions, OrderProblems);
  const OrdersReadyForShippingWithGuard = withRoleGuard(standardPermssions, OrdersReadyForShipping);
  const OrdersReadyForShippingCustomWithGuard = withRoleGuard(standardPermssions, OrdersReadyForShippingCustom);

  return (
    <>
      <VideoRecorderProvider value={{startRecordingRef, stopRecordingRef}}>
        <HeaderBar />
        <Switch>
          <div className="main-wrapper">
            <div className={`${showMenuSelector ? 'drawer__left drawer__left--left' : 'drawer__left__hide'}`}>
              <div className="drawer__left__content drawer__left__content--left">
                <Menu categories={categories} />
              </div>
            </div>
            <div className={`${showMenuSelector ? 'right-main-content' : 'right-main-content__fullSize'}`}>
              <Route path={APP_ROUTES.HOME} component={withRoleGuard(standardPermssions, Home, false)} exact />
              <Route path={APP_ROUTES.SUPPLIER} component={withRoleGuard(standardPermssions, Supplier)} exact />
              <Route
                path={APP_ROUTES.SUPPLIER_ARTICLES}
                component={withRoleGuard(standardPermssions, ActivatedSupplierArticles)}
                exact
              />
              <Route
                path={APP_ROUTES.NEW_SUPPLIER_ARTICLES}
                component={withRoleGuard(standardPermssions, NewSupplierArticle)}
                exact
              />
              <Route
                path={APP_ROUTES.MANUAL_ARTICLES}
                component={withRoleGuard(standardPermssions, ManualSupplierArticle)}
                exact
              />
              <Route
                path={APP_ROUTES.MATCHING_ARTICLES}
                component={withRoleGuard(standardPermssions, MatchingSupplierArticle)}
                exact
              />
              <Route
                path={APP_ROUTES.MATCHING_ARTICLES_MPN}
                component={withRoleGuard(standardPermssions, MatchingSupplierArticleMpns)}
                exact
              />
              <Route
                path={APP_ROUTES.MATCHING_IGNORED_EANS}
                render={() => <MatchingSupplierArticle ignored={true} />}
                exact
              />
              <Route
                path={APP_ROUTES.SUPPLIER_ARTICLE_DETAILS}
                component={withRoleGuard(standardPermssions, SupplierArticleDetail)}
                exact
              />
              <Route
                path={APP_ROUTES.IGNORED_SUPPLIER_ARTICLES}
                component={withRoleGuard(standardPermssions, IgnoredSupplierArticle)}
                exact
              />
              <Route component={FormTest} path={APP_ROUTES.FORM_TEST} exact />
              <Route component={ChangePassword} path={APP_ROUTES.CHANGE_PASSWORD} exact />
              <Route
                path={APP_ROUTES.PRODUCTS}
                component={withRoleGuard([...standardPermssions, USER_ROLE.PRODUCT_DESCRIPTIONS], Products)}
                exact
              />
              <Route
                path={APP_ROUTES.PRODUCT_DETAILS}
                component={withRoleGuard([...standardPermssions, USER_ROLE.PRODUCT_DESCRIPTIONS], ProductDetails)}
                exact
              />
              <Route
                path={APP_ROUTES.IMPORT_PRODUCTS}
                component={withRoleGuard(standardPermssions, ImportProducts)}
                exact
              />
              <Route
                path={APP_ROUTES.INVENTORY_PRODUCTS}
                component={withRoleGuard(standardPermssions, InventoryProducts)}
                exact
              />
              <Route path={APP_ROUTES.ORDERS} component={withRoleGuard(standardPermssions, Orders)} exact />
              <Route
                path={APP_ROUTES.MANUALLY_COMPLETED_ORDERS}
                component={withRoleGuard(standardPermssions, ManuallyCompletedOrders)}
                exact
              />
              <Route
                path={APP_ROUTES.MANUALLY_COMPLETED_ORDER_DETAILS}
                component={ManuallyCompletedOrderDetails}
                exact
              />
              <Route
                path={APP_ROUTES.OFFERS}
                component={(props: any) => <OffersWithGuard {...props} isOffer={true} />}
                exact
              />
              <Route path={APP_ROUTES.CREATE_ORDER} component={withRoleGuard(standardPermssions, CreateOrder)} exact />
              <Route path={APP_ROUTES.CUSTOMERS} component={withRoleGuard(standardPermssions, Customers)} exact />
              <Route
                path={APP_ROUTES.ORDER_DETAILS}
                component={withRoleGuard(standardPermssions, OrderDetails)}
                exact
              />
              <Route
                path={APP_ROUTES.CUSTOMER_DETAILS}
                component={withRoleGuard(standardPermssions, CustomerDetails)}
                exact
              />
              <Route
                path={APP_ROUTES.EXPIRED_PRODUCTS}
                component={withRoleGuard([...standardPermssions, USER_ROLE.PRODUCT_DESCRIPTIONS], ExpiredProduct)}
                exact
              />
              <Route
                path={APP_ROUTES.END_OF_LIFE_PRODUCTS}
                component={withRoleGuard([...standardPermssions, USER_ROLE.PRODUCT_DESCRIPTIONS], EndOfLifeProduct)}
                exact
              />
              <Route path={APP_ROUTES.PRICING} component={withRoleGuard(standardPermssions, ShopwarePricing)} exact />
              <Route
                path={APP_ROUTES.DEACTIVATED_PRODUCTS}
                component={withRoleGuard([...standardPermssions, USER_ROLE.PRODUCT_DESCRIPTIONS], DeactivatedProduct)}
                exact
              />
              <Route
                path={APP_ROUTES.COUNTRY_SHIPPING_PROVIDERS}
                component={withRoleGuard(standardPermssions, CountryShippingProviders)}
                exact
              />
              <Route
                path={APP_ROUTES.UPS_CONFIG}
                component={withRoleGuard(standardPermssions, UpsConfigDetails)}
                exact
              />
              <Route
                path={APP_ROUTES.DHL_CONFIG}
                component={withRoleGuard(standardPermssions, DhlConfigDetails)}
                exact
              />
              <Route
                path={APP_ROUTES.ORDER_PROBLEM_TYPES}
                component={withRoleGuard(standardPermssions, OrderProblemTypes)}
                exact
              />
              <Route
                path={APP_ROUTES.SYNC_STATUSES}
                component={withRoleGuard(standardPermssions, SyncStatuses)}
                exact
              />
              <Route
                path={APP_ROUTES.SUPPLIER_ORDER_ITEMS}
                component={withRoleGuard(standardPermssions, SupplierOrderItems)}
                exact
              />
              <Route
                path={APP_ROUTES.SUPPLIER_ITEMS_WITHOUT_STOCK}
                component={withRoleGuard(standardPermssions, SupplierOrderItemsWithoutStock)}
                exact
              />
              <Route
                path={APP_ROUTES.SUPPLIER_ORDERS_DETAILS}
                component={withRoleGuard(standardPermssions, SupplierOrderDetails)}
                exact
              />
              <Route
                path={APP_ROUTES.SUPPLIER_ORDERS}
                component={withRoleGuard(standardPermssions, SupplierOrders)}
                exact
              />
              <Route
                path={APP_ROUTES.UNFINISHED_SUPPLIER_ORDERS}
                component={withRoleGuard(standardPermssions, UnfinishedSupplierOrders)}
                exact
              />
              <Route
                path={APP_ROUTES.UNFINISHED_SUPPLIER_ORDER_ITEMS}
                component={withRoleGuard(standardPermssions, UnfinishedSupplierOrderItems)}
                exact
              />
              <Route
                path={APP_ROUTES.CUSTOMER_RETURNS}
                component={withRoleGuard(standardPermssions, CustomerReturns)}
                exact
              />
              <Route
                path={APP_ROUTES.SUPPLIER_DETAILS}
                component={withRoleGuard(standardPermssions, SupplierDetails)}
                exact
              />
              <Route
                path={APP_ROUTES.ORDERS_READY_FOR_SHIPPING}
                component={withRoleGuard(standardPermssions, OrdersReadyForShipping)}
                exact
              />
              <Route
                path={APP_ROUTES.AMAZON_PRICING}
                component={withRoleGuard(standardPermssions, AmazonPricing)}
                exact
              />
              <Route
                path={APP_ROUTES.COMPANY_CONFIG}
                component={withRoleGuard(standardPermssions, CompanyConfiguration)}
                exact
              />
              <Route
                path={APP_ROUTES.ORDER_PROBLEMS}
                render={() => <OrderProblemsWithGuard orderType={ProblemOrderType.customerOrder} />}
                exact
              />
              <Route
                path={APP_ROUTES.SUPPLIER_ORDER_PROBLEMS}
                render={() => <OrderProblemsWithGuard orderType={ProblemOrderType.supplierOrder} />}
                exact
              />
              <Route
                path={APP_ROUTES.ORDER_ITEMS_WITHOUT_PRODUCT}
                component={withRoleGuard(standardPermssions, OrderItemsWithoutProduct)}
                exact
              />
              <Route
                path={APP_ROUTES.SERVICE_STATUS}
                component={withRoleGuard(standardPermssions, ServiceStatus)}
                exact
              />
              <Route
                path={APP_ROUTES.AMAZON_TRACKING_NUMBERS_INFO}
                component={withRoleGuard(standardPermssions, AmazonTrackingNumbersInfo)}
                exact
              />
              <Route
                path={APP_ROUTES.AMAZON_SUBMISSIONS_INFO}
                component={withRoleGuard(standardPermssions, AmazonSubmissionsInfo)}
                exact
              />
              <Route path={APP_ROUTES.NON_EU_ORDERS} component={withRoleGuard(standardPermssions, NonEuOrders)} exact />
              <Route
                path={APP_ROUTES.MERGE_PRODUCTS}
                component={withRoleGuard(standardPermssions, MergeProducts)}
                exact
              />
              <Route
                path={APP_ROUTES.AMAZON_STOCK_SKUS}
                component={withRoleGuard(standardPermssions, AmazonStockSkus)}
                exact
              />
              <Route
                path={APP_ROUTES.ORDERS_WITH_EASYBILL_ISSUE}
                component={withRoleGuard(standardPermssions, OrdersWithInvoiceIssue)}
                exact
              />
              <Route
                path={APP_ROUTES.ORDERS_WITH_OVERDUE_INVOICES}
                component={withRoleGuard(standardPermssions, OrdersWithOverdueInvoices)}
                exact
              />
              <Route
                path={APP_ROUTES.PRIME_ORDERS_READY_FOR_SHIPPING}
                render={() => <OrdersReadyForShippingWithGuard type={'Prime'} />}
                exact
              />
              <Route
                path={APP_ROUTES.DDA_ORDERS_READY_FOR_SHIPPING}
                render={() => <OrdersReadyForShippingWithGuard type={'DDA'} />}
                exact
              />
              <Route
                path={APP_ROUTES.SP_ORDERS_READY_FOR_SHIPPING}
                render={() => <OrdersReadyForShippingWithGuard type={'SP'} />}
                exact
              />
              <Route
                path={APP_ROUTES.DHL_ORDERS_READY_FOR_SHIPPING}
                render={() => <OrdersReadyForShippingWithGuard type={'DHL'} />}
                exact
              />
              <Route
                path={APP_ROUTES.UPS_ORDERS_READY_FOR_SHIPPING}
                render={() => <OrdersReadyForShippingWithGuard type={'UPS'} />}
                exact
              />
              <Route
                path={APP_ROUTES.GLS_ORDERS_READY_FOR_SHIPPING}
                render={() => <OrdersReadyForShippingWithGuard type={'GLS'} />}
                exact
              />
              <Route
                path={APP_ROUTES.EBAY_ORDERS_READY_FOR_SHIPPING}
                render={() => <OrdersReadyForShippingWithGuard type={'EBAY'} />}
                exact
              />
              <Route
                path={APP_ROUTES.CUSTOM_LISTS_READY_FOR_SHIPPING_OVERVIEW}
                component={withRoleGuard(standardPermssions, CustomListsReadyForShipping)}
                exact
              />
              <Route
                path={APP_ROUTES.CUSTOM_LIST_READY_FOR_SHIPPING_SETTINGS}
                component={withRoleGuard(standardPermssions, CustomListsReadyForShippingSettings)}
                exact
              />
              <Route
                path={APP_ROUTES.CUSTOM_LIST_READY_FOR_SHIPPING}
                render={(props) => {
                  return (
                    <OrdersReadyForShippingCustomWithGuard listName={props.match.params.name} isProductList={false} />
                  );
                }}
                exact
              />
              <Route
                path={APP_ROUTES.CUSTOM_PRODUCT_LIST_READY_FOR_SHIPPING}
                render={(props) => {
                  return (
                    <OrdersReadyForShippingCustomWithGuard listName={props.match.params.name} isProductList={true} />
                  );
                }}
                exact
              />
              <Route
                path={APP_ROUTES.PAYMENT_REMINDER_LIST}
                component={withRoleGuard(standardPermssions, PaymentReminderList)}
                exact
              />
              <Route
                path={APP_ROUTES.OVERDUE_PAYMENTS}
                component={withRoleGuard(standardPermssions, CriticalOverduePayments)}
                exact
              />
              <Route
                path={APP_ROUTES.OPEN_INVOICES}
                component={withRoleGuard(standardPermssions, OpenInvoices)}
                exact
              />
              <Route
                path={APP_ROUTES.MERGE_CUSTOMERS}
                component={withRoleGuard(standardPermssions, MergeCustomers)}
                exact
              />
              <Route path={APP_ROUTES.USERS} component={withRoleGuard([USER_ROLE.ADMIN], Users)} exact />
              <Route
                path={APP_ROUTES.SUPPLIER_ORDER_ITEMS_BACKLOG}
                component={withRoleGuard(standardPermssions, SupplierOrderItemsBacklog)}
                exact
              />
              <Route path={APP_ROUTES.CHANGE_LOG} component={withRoleGuard([USER_ROLE.ADMIN], ChangeLog)} exact />
              <Route
                path={APP_ROUTES.SUPPLIER_ARTICLE_CSV_PROCESSING}
                component={withRoleGuard(standardPermssions, SupplierArticleCsvProcessing)}
                exact
              />
              <Route path={APP_ROUTES.EBAY_PRICING} component={withRoleGuard(standardPermssions, EbayPricing)} exact />
              <Route
                path={APP_ROUTES.DATEV_PAYMENTS_LIST}
                component={withRoleGuard(standardPermssions, DatevPaymentLists)}
                exact
              />

              <Route
                path={APP_ROUTES.PRICING_ITEMS}
                component={withRoleGuard(standardPermssions, PricingItems)}
                exact
              />
              <Route
                path={APP_ROUTES.GALAXUS_PRICING}
                component={withRoleGuard(standardPermssions, GalaxusPricing)}
                exact
              />
              <Route path={APP_ROUTES.TODO_LIST} component={withRoleGuard(standardPermssions, TodoList)} exact />
              <Route
                path={APP_ROUTES.EBAY_MULTI_VARIANT_PRODUCT}
                component={withRoleGuard(standardPermssions, EbayMultiVariantItemScreen)}
                exact
              />
              <Route
                path={APP_ROUTES.EBAY_MULTI_VARIANT_ITEMS}
                component={withRoleGuard([...standardPermssions], EbayMultiVariants)}
                exact
              />
              <Route
                path={APP_ROUTES.EBAY_MULTI_VARIANT_PRODUCT_ID}
                component={withRoleGuard([...standardPermssions], EbayMultiVariantItemScreen)}
                exact
              />
              <Route
                path={APP_ROUTES.OPEN_SUPPLIER_ORDERS}
                component={withRoleGuard(standardPermssions, OpenSupplierOrders)}
                exact
              />
              <Route
                path={APP_ROUTES.KAUFLAND_PRICING}
                component={withRoleGuard(standardPermssions, KauflandPricing)}
                exact
              />
              <Route
                path={APP_ROUTES.CUSTOM_CONFIG}
                component={withRoleGuard(standardPermssions, CustomConfigScreen)}
                exact
              />
              <Route
                path={APP_ROUTES.EBAY_TRACKING_NUMBERS_INFO}
                component={withRoleGuard(standardPermssions, EbayTrackingNumbers)}
                exact
              />
              <Route
                path={APP_ROUTES.SUPPLIER_ORDERS_DUE}
                component={withRoleGuard(standardPermssions, SupplierOrdersDue)}
                exact
              />
              <Route
                path={APP_ROUTES.PRODUCTS_WITHOUT_MINIMUM_MARGIN}
                component={withRoleGuard(standardPermssions, ProductsWithoutMinimumMargin)}
                exact
              />
              <Route
                path={APP_ROUTES.PRODUCTS_SHOP_STATUSES}
                component={withRoleGuard(standardPermssions, ProductsByShopStatus)}
                exact
              />
              <Route path={APP_ROUTES.PRODUCTS_WITH_CALCULATED_PRICES} component={ProductsWithCalculatedPrice} exact />
              <Route
                path={APP_ROUTES.SUPPLIER_COMPLAINTS}
                component={withRoleGuard(standardPermssions, SupplierComplaintScreen)}
                exact
              />
              <Route
                path={APP_ROUTES.SUPPLIER_COMPLAINTS_DETAILS}
                component={withRoleGuard(standardPermssions, SupplierComplaintDetails)}
                exact
              />
              <Route path={APP_ROUTES.CUSTOM_FIELDS} component={withRoleGuard([USER_ROLE.ADMIN], CustomFields)} exact />
              <Route
                path={APP_ROUTES.UNDELIVERED_DHL_ORDERS}
                component={withRoleGuard(standardPermssions, UndeliveredDhlOrders)}
                exact
              />
              <Route
                path={APP_ROUTES.INACTIVE_AMAZON_PRODUCTS}
                component={withRoleGuard(standardPermssions, InactiveAmazonProducts)}
                exact
              />
              <Route
                path={APP_ROUTES.SUPPLIER_PAYMENTS}
                component={withRoleGuard(standardPermssions, SupplierPayments)}
                exact
              />
              <Route
                path={APP_ROUTES.PRODUCTS_STOCK_COMPARISION}
                component={withRoleGuard(standardPermssions, ProductsStockComparision)}
                exact
              />
              <Route
                path={APP_ROUTES.METRO_PRICING}
                component={withRoleGuard(standardPermssions, MetroPricing)}
                exact
              />
              <Route path={APP_ROUTES.FNAC_PRICING} component={withRoleGuard(standardPermssions, FnacPricing)} exact />
              <Route
                path={APP_ROUTES.EBAY_PRODUCTS_TO_REPUBLISH}
                component={withRoleGuard([...standardPermssions], EbayRepublishingProducts)}
                exact
              />
              <Route
                path={APP_ROUTES.LABEL_SCANNER}
                component={withRoleGuard(standardPermssions, LabelScanner)}
                exact
              />
              <Route path={APP_ROUTES.STATIC_FILES} component={withRoleGuard(standardPermssions, StaticFiles)} exact />
              <Route
                path={APP_ROUTES.EBAY_IMAGES}
                component={withRoleGuard(standardPermssions, ManufacturerImages)}
                exact
              />
              <Route
                path={APP_ROUTES.PRODUCTS_STOCK_PER_DAY}
                component={withRoleGuard([USER_ROLE.ADMIN], ProductsStockPerDay)}
                exact
              />
              <Route
                path={APP_ROUTES.UNDELIVERED_UPS_ORDERS}
                component={withRoleGuard(standardPermssions, UndeliveredUpsOrders)}
                exact
              />
              <Route
                path={APP_ROUTES.EBAY_CATEGORIES}
                component={withRoleGuard(standardPermssions, EbayCategories)}
                exact
              />
            </div>

            <div className="logout-button-container">
              <Grid container spacing={2} style={{width: '247px'}} alignItems="center">
                <Grid item xs={10} sm={10} md={10} className="logout-button" onClick={handleLogout}>
                  <img style={{verticalAlign: 'middle'}} src="/icons/exit.webp" />
                  <span style={{verticalAlign: 'middle'}}> {t('general.logout')}</span>
                </Grid>
                <Grid item xs={2} sm={2} md={2} className="logout-button">
                  <Link to={APP_ROUTES.CHANGE_PASSWORD}>
                    <img src="/icons/settings.webp" alt="Change password" title="Change password" />
                  </Link>
                  <Grid />
                </Grid>
              </Grid>
            </div>
          </div>
        </Switch>
      </VideoRecorderProvider>
    </>
  );
};

export default AppRouter;
