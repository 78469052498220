import {Button} from '@material-ui/core';
import React, {ReactElement, useContext, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useFetch} from '../../../hooks/fetch';
import {useDispatch} from 'react-redux';
import OrderDetailsContext from '../../../screens/Orders/Context/OrderDetailsContext';
import ButtonListContext from '../../ButtonList/ButtonListContext';
import {modalActions} from '../../../modules/modal/actions';
import {MODALS} from '../../Modal/ModalContents';
import {appActions} from '../../../modules/app/actions';

interface IProps {
  isListItem?: boolean;
}

const CreateExchangeOrderButton = ({isListItem}: IProps): ReactElement => {
  const {hideButtonList} = useContext(ButtonListContext);
  const [createExchangeOrderResponse, createExchangeOrder] = useFetch<any>('create_exchange_order');
  const {order} = useContext(OrderDetailsContext);
  const dispatch = useDispatch();
  const {t} = useTranslation();

  const handleSubmitModal = (values: any) => {
    if (confirm(t('general.areYouSure'))) {
      createExchangeOrder({id: order._id, ...values});
    }
  };

  const handleCancelModal = () => {
    dispatch(modalActions.closeModal());
  };

  const createOrder = (e: any) => {
    e.stopPropagation();
    dispatch(
      modalActions.addModal(MODALS.CREATE_EXCHANGE_ORDER, {
        title: t('orderDetails.createExchangeOrder'),
        onSubmit: handleSubmitModal,
        onCancel: handleCancelModal,
      }),
    );
  };

  useEffect(() => {
    if (createExchangeOrderResponse.data) {
      dispatch(appActions.showSnackBar({text: t('general.success'), options: {severity: 'success'}}));
      hideButtonList();
    }
  }, [createExchangeOrderResponse]);

  return (
    <Button
      variant={isListItem ? 'text' : 'contained'}
      className={isListItem ? 'button-list-item' : 'blue-button'}
      onClick={createOrder}
    >
      {t('orderDetails.createExchangeOrder')}
    </Button>
  );
};

export default CreateExchangeOrderButton;
