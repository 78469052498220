import {Typography} from '@material-ui/core';
import React, {useEffect, useState} from 'react';
import Form from '../Form/Form';
import _ from 'lodash';
import {useTranslation} from 'react-i18next';
import {ProductCompatibility} from '../../types/Product/ProductCompatibility';
import * as Yup from 'yup';
import {useStorlessFetch} from '../../hooks/fetch';
import {modalActions} from '../../modules/modal/actions';
import {useDispatch} from 'react-redux';
import {appActions} from '../../modules/app/actions';

export default function UpdateShopFactorsModal(props: IUpdateShopFactorsModalProps) {
  const {featureName, shopConfigId, shopwareType, callback} = props;
  const [initialValues, setInitialValues] = useState(null);
  const [updateShopFactorsRes, updateShopFactors] = useStorlessFetch('update_pricing_factors');
  const {t} = useTranslation();
  const dispatch = useDispatch();

  const validationSchema = Yup.object().shape({
    compatibility: Yup.string().required('Required'),
    productType: Yup.string().required('Required'),
    shopFactor: Yup.number().required('Required'),
  });

  useEffect(() => {
    const data = generateInitialValues(fields, null);
    setInitialValues(data);
  }, []);

  useEffect(() => {
    if (updateShopFactorsRes.data) {
      callback();
    }

    if (updateShopFactorsRes.error) {
      dispatch(appActions.showSnackBar({text: t('general.error'), options: {severity: 'error'}}));
    }

    return () => {
      updateShopFactorsRes.data = null;
      updateShopFactorsRes.error = null;
      updateShopFactorsRes.loading = null;
    };
  }, [updateShopFactorsRes]);

  const fields = [
    {
      label: t('productDetails.compatibility'),
      placeholder: t('productDetails.compatibility'),
      fieldKey: 'compatibility',
      type: 'select',
      options: ([{value: null, label: ''}] as any).concat(
        Object.values(ProductCompatibility).map((item) => {
          return {value: item.toUpperCase(), label: item.toUpperCase()};
        }),
      ),
    },
    {
      label: t('productDetails.productType'),
      placeholder: t('productDetails.productType'),
      fieldKey: 'productType',
      type: 'autocomplete',
      options: [],
      endpoint: 'pricing_product_type_list',
      defaultValue: '',
    },
    {
      label: t('pricing.shopFactor'),
      placeholder: t('pricing.shopFactor'),
      fieldKey: 'shopFactor',
      type: 'input',
      fieldKeyType: 'number',
    },
  ] as any;

  const onSubmit = (values: ShopFactorData) => {
    updateShopFactors({featureName, shopConfigId, shopwareType, ...values});
  };

  const onClose = () => dispatch(modalActions.closeModal());
  return (
    <div>
      <Typography className="modal-title">{t('pricing.updateShopFactors')}</Typography>
      {initialValues ? (
        <Form
          initialValues={initialValues}
          fields={fields}
          submitButtonText={t('general.submit')}
          cancelButtonText={t('general.cancel')}
          onSubmit={onSubmit}
          onCancel={onClose}
          minWidth="600px"
          validationSchema={validationSchema}
          triggerOnCloseAfterSubmit={false}
        ></Form>
      ) : null}
    </div>
  );
}

export interface IUpdateShopFactorsModalProps {
  featureName: string;
  callback: () => void;
  shopConfigId?: string;
  shopwareType?: string;
}

interface ShopFactorData {
  compatibility: string;
  productType: string;
  shopFactor: number;
}

const generateInitialValues = (fields: any[], dataObject: any) => {
  if (dataObject) {
    return dataObject;
  } else {
    let defaults = {};
    fields.forEach((field) => {
      defaults = {...defaults, [field.fieldKey]: ''};
    });
    return defaults;
  }
};
