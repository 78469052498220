import React from 'react';
import Form, {IField} from '../Form/Form';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {modalActions} from '../../modules/modal/actions';
import {SalutationType} from '../../types/Customer/SalutationType';
import {SHOPWARE_PAYMENT_TYPE} from '../../types/Orders/ShopwarePaymentType';

export interface INewCustomer {
  createCustomerResponse: any;
  createCustomer: (data: any) => void;
}

const NewCustomer = ({createCustomerResponse, createCustomer}: INewCustomer) => {
  const {t} = useTranslation();

  const fields: IField[] = [
    {
      label: t('general.generalInfo'),
      fieldKey: '',
      type: 'section-title',
    },
    {
      label: t('customerDetails.customerName'),
      placeholder: t('customerDetails.customerName'),
      fieldKey: 'customerName',
      type: 'input',
    },
    {
      label: t('customerDetails.title'),
      placeholder: t('customerDetails.title'),
      fieldKey: 'title',
      type: 'input',
    },
    {
      label: t('customerDetails.salutation'),
      placeholder: t('customerDetails.salutation'),
      fieldKey: 'salutation',
      type: 'select',
      options: Object.values(SalutationType).map((item) => {
        return {value: item, label: item};
      }),
    },
    {
      label: t('customerDetails.firstName'),
      placeholder: t('customerDetails.firstName'),
      fieldKey: 'firstName',
      type: 'input',
    },
    {
      label: t('customerDetails.lastName'),
      placeholder: t('customerDetails.lastName'),
      fieldKey: 'lastName',
      type: 'input',
    },
    {
      label: t('customerDetails.companyName'),
      placeholder: t('customerDetails.companyName'),
      fieldKey: 'company',
      type: 'input',
    },
    {
      label: t('customerDetails.address'),
      placeholder: t('customerDetails.address'),
      fieldKey: 'address',
      type: 'input',
    },
    {
      label: t('customerDetails.addressSupplement'),
      placeholder: t('customerDetails.addressSupplement'),
      fieldKey: 'addressSupplement',
      type: 'input',
    },
    {
      label: t('customerDetails.postalCode'),
      placeholder: t('customerDetails.postalCode'),
      fieldKey: 'postalCode',
      type: 'input',
    },
    {
      label: t('customerDetails.city'),
      placeholder: t('customerDetails.city'),
      fieldKey: 'city',
      type: 'input',
    },
    {
      label: t('customerDetails.country'),
      placeholder: t('customerDetails.country'),
      fieldKey: 'country',
      type: 'autocomplete',
      options: [],
      endpoint: 'country_autocomplete_list',
      required: true,
    },
    {
      label: t('customerDetails.state'),
      placeholder: t('customerDetails.state'),
      fieldKey: 'state',
      type: 'input',
    },
    {
      label: t('general.contactInfo'),
      fieldKey: '',
      type: 'section-title',
    },
    {
      label: t('customerDetails.email'),
      placeholder: t('customerDetails.email'),
      fieldKey: 'email',
      type: 'input',
      required: true,
    },
    {
      label: t('customerDetails.otherEmails'),
      placeholder: t('customerDetails.otherEmails'),
      fieldKey: 'otherEmails',
      type: 'chipsInput',
    },
    {
      label: t('customerDetails.invoiceEmail'),
      placeholder: t('customerDetails.invoiceEmail'),
      fieldKey: 'invoiceEmail',
      type: 'input',
    },
    {
      label: t('customerDetails.sepaEmail'),
      placeholder: t('customerDetails.sepaEmail'),
      fieldKey: 'sepaDetails.email',
      type: 'input',
    },
    {
      label: t('customerDetails.phone'),
      placeholder: t('customerDetails.phone'),
      fieldKey: 'phone',
      type: 'input',
    },
    {
      label: t('customerDetails.fax'),
      placeholder: t('customerDetails.fax'),
      fieldKey: 'dealerCompanyDetails.contactDetails.fax',
      type: 'input',
    },
    {
      label: t('customerDetails.web'),
      placeholder: t('customerDetails.web'),
      fieldKey: 'dealerCompanyDetails.contactDetails.web',
      type: 'input',
    },
    {
      label: t('general.paymentInfo'),
      fieldKey: '',
      type: 'section-title',
    },
    {
      label: t('customerDetails.paymentMethodName'),
      placeholder: t('customerDetails.paymentMethodName'),
      fieldKey: 'paymentMethodName',
      type: 'select',
      options: Object.values(SHOPWARE_PAYMENT_TYPE).map((item) => {
        return {value: item, label: item};
      }),
    },
    {
      label: t('customerDetails.bankDetailsLabel'),
      placeholder: t('customerDetails.bankDetailsLabel'),
      fieldKey: 'dealerCompanyDetails.bankDetails.label',
      type: 'input',
    },
    {
      label: t('customerDetails.iban'),
      placeholder: t('customerDetails.iban'),
      fieldKey: 'dealerCompanyDetails.bankDetails.iban',
      type: 'input',
    },
    {
      label: t('customerDetails.swiftCode'),
      placeholder: t('customerDetails.swiftCode'),
      fieldKey: 'dealerCompanyDetails.bankDetails.swiftCode',
      type: 'input',
    },
    {
      label: t('customerDetails.sepaName'),
      placeholder: t('customerDetails.sepaName'),
      fieldKey: 'sepaName',
      type: 'input',
    },
    {
      label: t('customerDetails.sepaIban'),
      placeholder: t('customerDetails.sepaIban'),
      fieldKey: 'sepaDetails.iban',
      type: 'input',
    },
    {
      label: t('customerDetails.sepaSignatureDate'),
      placeholder: t('customerDetails.sepaSignatureDate'),
      fieldKey: 'sepaDetails.clientSignatureDate',
      type: 'date',
      fieldKeyType: 'date',
    },
    {
      label: t('customerDetails.sepaClientNumber'),
      placeholder: t('customerDetails.sepaClientNumber'),
      fieldKey: 'sepaDetails.clientNumber',
      type: 'input',
    },
    {
      label: t('customerDetails.easybillDueDays'),
      placeholder: t('customerDetails.easybillDueDays'),
      fieldKey: 'easybillDueDays',
      type: 'input',
      fieldKeyType: 'number',
    },
    {
      label: t('general.moreInfo'),
      fieldKey: '',
      type: 'section-title',
    },
    {
      label: t('customerDetails.localCourt'),
      placeholder: t('customerDetails.localCourt'),
      fieldKey: 'dealerCompanyDetails.bankDetails.localCourt',
      type: 'input',
    },
    {
      label: t('customerDetails.managingDirector'),
      placeholder: t('customerDetails.managingDirector'),
      fieldKey: 'dealerCompanyDetails.additionalDetails.managingDirector',
      type: 'input',
    },
    {
      label: t('customerDetails.salesTaxId'),
      placeholder: t('customerDetails.salesTaxId'),
      fieldKey: 'federalTaxId',
      type: 'input',
    },
    {
      label: t('customerDetails.taxNumber'),
      placeholder: t('customerDetails.taxNumber'),
      fieldKey: 'commercialRegisterNumber',
      type: 'input',
    },
    {
      label: t('customerDetails.excludeForPaymentReminders'),
      placeholder: t('newProductForm.excludeForPaymentReminders'),
      fieldKey: 'excludeForPaymentReminders',
      type: 'checkbox',
    },
  ];
  const initialValues = {
    customerName: '',
    title: '',
    salutation: '',
    firstName: '',
    lastName: '',
    company: '',
    email: '',
    phone: '',
    address: '',
    addressSupplement: '',
    country: '',
    city: '',
    postalCode: '',
    state: '',
    bankDetails: '',
    dealerCompanyDetails: {
      bankDetails: {
        label: '',
        iban: '',
        swiftCode: '',
        localCourt: '',
      },
      contactDetails: {
        fax: '',
        web: '',
      },
      additionalDetails: {
        managingDirector: '',
      },
    },
    federalTaxId: '',
    commercialRegisterNumber: '',
    sepaDetails: {
      iban: '',
      clientNumber: '',
      clientSignatureDate: null,
    },
    sepaName: '',
    excludeForPaymentReminders: false,
  };

  const dispatch = useDispatch();
  const onSubmit = (values: any) => {
    dispatch(modalActions.closeModal());
    createCustomer(values);
  };
  const onCancel = () => dispatch(modalActions.closeModal());

  return (
    <>
      <Form
        title={t('customers.newCustomer')}
        initialValues={initialValues}
        fields={fields}
        submitButtonText={t('general.submit')}
        cancelButtonText={t('general.cancel')}
        onSubmit={onSubmit}
        onCancel={onCancel}
        isLoading={createCustomerResponse?.loading}
      ></Form>
    </>
  );
};

export default NewCustomer;
