import {Button} from '@material-ui/core';
import React, {ReactElement, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useFetch} from '../../../hooks/fetch';
import {appActions} from '../../../modules/app/actions';
import {useDispatch} from 'react-redux';
import {modalActions} from '../../../modules/modal/actions';
import {MODALS} from '../../Modal/ModalContents';

const SupplierOrderCancelButton = (props: ISupplierOrderCancelButtonProps): ReactElement => {
  const [cancel, doCancelOrder] = useFetch<any>('supplier_orders_cancel');
  const {t} = useTranslation();
  const {supplierOrderId} = props;
  const dispatch = useDispatch();

  const handleCancelOrder = () => {
    doCancelOrder({id: supplierOrderId});
    dispatch(modalActions.closeModal());
  };

  const cancelSupplierOrder = async () => {
    dispatch(
      modalActions.addModal(MODALS.CONFIRM_DIALOG, {
        title: t('general.areYouSure'),
        content: t('general.areYouSure'),
        onYes: handleCancelOrder,
        onNo: () => dispatch(modalActions.closeModal()),
      }),
    );
  };

  useEffect(() => {
    if (cancel?.loaded && !cancel?.error) {
      dispatch(appActions.showSnackBar({text: t('general.submitFormText'), options: {severity: 'success'}}));
      window.location.reload();
    } else if (cancel?.error) {
      dispatch(appActions.showSnackBar({text: cancel.error.name, options: {severity: 'error'}}));
    }

    return () => {
      cancel.data = null;
      cancel.error = null;
    };
  }, [cancel]);

  return (
    <Button variant="contained" className="blue-button" onClick={cancelSupplierOrder}>
      {t('supplierOrderDetails.cancelOrder')}
    </Button>
  );
};

interface ISupplierOrderCancelButtonProps {
  supplierOrderId: any;
}

export default SupplierOrderCancelButton;
