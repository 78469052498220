import {CircularProgress, TableBody, TableCell, TableRow} from '@material-ui/core';
import React, {ReactElement, useContext, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {useFetch, useStorlessFetch} from '../../hooks/fetch';
import {appActions} from '../../modules/app/actions';
import {ISupplierOrderItem} from '../../types/SupplierOrders/ISupplierOrderItem';
import SupplierOrderItemsContext from './Context/SupplierOrderItemsContext';
import {SupplierOrderItemsTableBodyProvider} from './Context/SupplierOrterItemsTableBodyContext';
import SupplierOrderItemsTableRow from './SupplierOrderItemsTableRow';

const SupplierOrderItemsTableBody = (): ReactElement => {
  const {supplierOrderItems, getPendingSupplierOrderItems, getTotalPurchasePrice} =
    useContext(SupplierOrderItemsContext);
  const [expandedRow, setExpandedRow] = useState<any>();
  const {t} = useTranslation();
  const [orderItemsData, setOrderItemsData] = useState<ISupplierOrderItem[]>([]);
  const [supplierOrderItemPut, doSupplierOrderItemPut] = useFetch<any>('supplier_order_items_put');
  const [supplierOrderItemDelete, doSupplierOrderItemDelete] = useFetch<any>('supplier_order_items_delete');
  const [inPurchaseList, toggleInPurchaseList] = useStorlessFetch('supplier_order_items_toggle');
  const dispatch = useDispatch();

  useEffect(() => {
    if (supplierOrderItems?.data) {
      setOrderItemsData(supplierOrderItems.data.data);
    }
  }, [supplierOrderItems]);

  useEffect(() => {
    if (supplierOrderItemPut?.error) {
      dispatch(appActions.showSnackBar({text: supplierOrderItemPut.error.name, options: {severity: 'error'}}));
    } else if (supplierOrderItemPut?.data) {
      getPendingSupplierOrderItems();
      getTotalPurchasePrice();
      dispatch(
        appActions.showSnackBar({
          text: t('supplierOrderItems.successUpdatedMessage'),
          options: {severity: 'success'},
        }),
      );
    }

    return () => {
      supplierOrderItemPut.data = null;
      supplierOrderItemPut.error = null;
      supplierOrderItemPut.loaded = null;
    };
  }, [supplierOrderItemPut]);

  useEffect(() => {
    if (supplierOrderItemDelete?.error) {
      dispatch(appActions.showSnackBar({text: supplierOrderItemDelete.error.name, options: {severity: 'error'}}));
    } else if (supplierOrderItemDelete?.data) {
      getTotalPurchasePrice();
      dispatch(
        appActions.showSnackBar({
          text: t('supplierOrderItems.successDeletedMessage'),
          options: {severity: 'success'},
        }),
      );
    }

    return () => {
      supplierOrderItemDelete.data = null;
      supplierOrderItemDelete.error = null;
      supplierOrderItemDelete.loaded = null;
    };
  }, [supplierOrderItemDelete]);

  useEffect(() => {
    if (inPurchaseList?.data) {
      getTotalPurchasePrice();
    }
  }, [inPurchaseList]);

  return (
    <TableBody>
      {orderItemsData.map((item) => {
        return (
          <SupplierOrderItemsTableBodyProvider
            key={item._id}
            value={{
              supplierOrderItem: item,
              orderItemsData,
              setOrderItemsData,
              doSupplierOrderItemDelete,
              doSupplierOrderItemPut,
              toggleInPurchaseList,
            }}
          >
            <SupplierOrderItemsTableRow setExpandedRow={setExpandedRow} expandedRow={expandedRow} />
          </SupplierOrderItemsTableBodyProvider>
        );
      })}
      {((orderItemsData || []).length === 0 || supplierOrderItems?.loading) && (
        <TableRow>
          <TableCell colSpan={8} style={{textAlign: 'center'}}>
            {supplierOrderItems.loading ? <CircularProgress /> : t('general.noData')}
          </TableCell>
        </TableRow>
      )}
    </TableBody>
  );
};

export default SupplierOrderItemsTableBody;
