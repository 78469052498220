import Axios, {Method} from 'axios';
import _ from 'lodash';
import {Endpoint, ENDPOINTS} from './endpoints';
import CONFIG from '../../lib/config';
import {storage} from '../../lib/storage';
import {jwtDecode} from 'jwt-decode';

const UnauthorizeErrors = ['TokenExpires', 'Unauthorized', 'UnauthorizedException'];

class ApiFactory<T> {
  private token: string | null = null;
  private tokenRefreshQueue: (() => void)[] = [];
  private refreshingToken = false;
  endpoints: typeof ENDPOINTS;

  constructor(endpoints: typeof ENDPOINTS) {
    this.endpoints = endpoints;
    this.token = null;
    this.initializeToken();
    this.setupInterceptors();
  }

  private initializeToken() {
    const token = storage.getValue(CONFIG.tokenKey);
    if (token) {
      this.setToken(token);
    }
  }

  setToken = (token: string | null) => {
    this.token = token;
    if (token && token !== 'null') {
      storage.writeValue(CONFIG.tokenKey, token);
    }
    // else {
    //   this.removeAccessToken();
    // }
  };

  private setupInterceptors() {
    // Axios.defaults.baseURL = config.api;
    Axios.interceptors.request.use(async (config) => {
      const token = await this.getToken();

      if (token) {
        if (config.headers) {
          config.headers.Authorization = token;
        } else {
          config.headers = {Authorization: token};
        }
      }
      return config;
    });

    // Axios.interceptors.response.use(
    //   (response) => response,
    //   async (error) => {
    //     if (error?.response?.status === 400 && error?.response?.data?.error?.name === 'RefreshTokenMismatch') {
    //       this.removeAccessToken();
    //     }

    //     if (error.response && error.response.status === 401) {
    //       if (!this.refreshingToken) {
    //         this.refreshToken();
    //       }
    //       const retryOriginalRequest = new Promise<void>((resolve) => {
    //         this.tokenRefreshQueue.push(() => {
    //           error.config.headers.Authorization = this.token;
    //           resolve();
    //         });
    //       });
    //       return retryOriginalRequest.then(() => {
    //         return Axios(error.config);
    //       });
    //     }
    //   },
    // );
  }

  async getUserId(): Promise<string> {
    const token = await this.getToken();
    if (!token) return '';

    const decodedToken: any = jwtDecode(token as any);
    return decodedToken.user._id;
  }

  checkIsUserUnauthorized(error: string): boolean {
    return UnauthorizeErrors.indexOf(error) > -1;
  }

  private async refreshToken() {
    this.refreshingToken = true;
    try {
      const response = await Axios.post(`${CONFIG.api}auth/refresh-token`, {token: this.token});
      if (response?.data) {
        const newToken = response?.data;
        this.setToken(newToken);
        this.tokenRefreshQueue.forEach((callback) => callback());
      }
    } catch (error) {
      console.error('Failed to refresh access token:', error);
      this.removeAccessToken();
    } finally {
      this.refreshingToken = false;
      this.tokenRefreshQueue = [];
    }
  }

  private removeAccessToken() {
    window.localStorage.removeItem(CONFIG.tokenKey);
    window.location.reload();
  }

  private async getToken() {
    const isTokenEmpty = _.isEmpty(this.token) || this.token === 'null' || this.token === 'undefined';
    if (isTokenEmpty) return null;
    return this.token;
  }

  fetch = async (endpoint: Endpoint, data: any) => {
    const {url, removed} = this.generateFullUri(this.endpoints[endpoint].uri, data);
    const responseType = (this.endpoints[endpoint] as any).responseType;
    const body = _.omit(data, removed);
    const generatedRequest = {
      url,
      method: this.endpoints[endpoint].method as Method,
      ...(this.endpoints[endpoint].method !== 'GET' ? {data: body} : {params: body}),
      ...(responseType && {responseType: responseType}),
    };

    const response = await Axios(generatedRequest);
    if (response.status >= 200 && response.status < 300) return response;
    throw response;
  };

  upload = async (endpoint: Endpoint, data: any) => {
    const {url, removed} = this.generateFullUri(this.endpoints[endpoint].uri, data);
    const body = _.omit(data, removed);

    const imagesData = new FormData();
    imagesData.append('file', data?.file);
    imagesData.append('comment', data?.comment);

    const generatedRequest = {
      url,
      method: this.endpoints[endpoint].method as Method,
      ...(this.endpoints[endpoint].method !== 'GET' ? {data: imagesData} : {params: body}),
    };

    const response = await Axios(generatedRequest);
    if (response.status >= 200 && response.status < 300) return response;
    throw response;
  };

  private generateFullUri = (endpoint: string, data: any) => {
    const removed: string[] = [];

    const url = `${CONFIG.api}${endpoint}`.replace(/\{(.*?)\}/g, (token, name) => {
      let value = token;
      if (data && data[name] !== undefined) {
        removed.push(name);
        value = data[name].toString();
      }
      return value;
    });

    return {url, removed};
  };

  public reinitializeEndpoints = (endpoints: typeof ENDPOINTS) => {
    this.endpoints = endpoints;
  };
}

const api = new ApiFactory(ENDPOINTS);

export {api};
