import React, {ReactElement, useState, useEffect, useRef} from 'react';
import {Button} from '@material-ui/core';
import {ButtonListProvider} from './ButtonListContext';

interface IProps {
  items: ReactElement[];
  buttonText: string;
}

const ButtonList = ({items, buttonText}: IProps) => {
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [showList, setShowList] = useState(false);

  const handleButtonClick = () => {
    setShowList(!showList);
  };

  const handleItemClick = (event: React.MouseEvent<HTMLSpanElement>) => {
    const clickedElement = event.target as HTMLSpanElement;
    clickedElement.click();
  };

  return (
    <div className="button-list-container">
      <Button className="black-button" variant="contained" onClick={handleButtonClick}>
        {buttonText}
      </Button>
      <ButtonListProvider
        value={{
          hideButtonList: () => setTimeout(() => setShowList(false), 1000),
        }}
      >
        {showList && (
          <div ref={dropdownRef} className="button-list">
            {items.map((item, index) => (
              <span key={index} onClick={handleItemClick}>
                {item}
              </span>
            ))}
          </div>
        )}
      </ButtonListProvider>
    </div>
  );
};

export default ButtonList;
