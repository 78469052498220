import {Button} from '@material-ui/core';
import React, {ReactElement, useContext, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {useFetch} from '../../../hooks/fetch';
import {appActions} from '../../../modules/app/actions';
import {modalActions} from '../../../modules/modal/actions';
import OrderDetailsContext from '../../../screens/Orders/Context/OrderDetailsContext';
import {IOrderProblem} from '../../../types/OrderProblems/IOrderProblem';
import {ProblemOrderType} from '../../../types/OrderProblems/ProblemOrderType';
import ButtonListContext from '../../ButtonList/ButtonListContext';
import {MODALS} from '../../Modal/ModalContents';

interface IProps {
  isListItem?: boolean;
}

const AddProblemButton = ({isListItem}: IProps): ReactElement => {
  const [orderProblemPost, doOrderProblemPost] = useFetch<any>('order_problem_post');
  const {order, refreshOrderProblemTable} = useContext(OrderDetailsContext);
  const {hideButtonList} = useContext(ButtonListContext);
  const dispatch = useDispatch();
  const {t} = useTranslation();

  const handleCancelModal = () => {
    dispatch(modalActions.closeModal());
  };

  const orderProblemSubmitHandler = async (values: any) => {
    const orderProblem: IOrderProblem = {...values};
    orderProblem.order = order._id;
    orderProblem.orderType = ProblemOrderType.customerOrder;
    doOrderProblemPost(orderProblem);
    handleCancelModal();
  };

  const handleProblemClick = async () => {
    refreshOrderProblemTable(false);

    dispatch(
      modalActions.addModal(MODALS.ADD_ORDER_PROBLEM, {
        title: t('orderDetails.addProblem') || '',
        onCancel: handleCancelModal,
        onSubmit: orderProblemSubmitHandler,
      }),
    );
  };

  useEffect(() => {
    if (orderProblemPost?.data) {
      refreshOrderProblemTable(true);
      dispatch(appActions.showSnackBar({text: t('general.submitFormText'), options: {severity: 'success'}}));
      hideButtonList && hideButtonList();
    } else if (orderProblemPost?.error) {
      dispatch(appActions.showSnackBar({text: orderProblemPost.error.name, options: {severity: 'error'}}));
    }

    return () => {
      orderProblemPost.data = null;
      orderProblemPost.error = null;
    };
  }, [orderProblemPost.data]);

  return (
    <Button
      variant={isListItem ? 'text' : 'contained'}
      className={isListItem ? 'button-list-item' : 'blue-button'}
      onClick={handleProblemClick}
    >
      {t('orderDetails.addProblem')}
    </Button>
  );
};

export default AddProblemButton;
