import {useEffect, useState, useMemo} from 'react';
import {api} from '../modules/api/ApiFactory';
import {Endpoint} from '../modules/api/endpoints';

const useDataFetcher = (endpoint: Endpoint, requestDataParams: any = null) => {
  const [data, setData] = useState<any>(null);

  const requestData = useMemo(() => requestDataParams, [JSON.stringify(requestDataParams)]);

  useEffect(() => {
    const fetchData = async () => {
      const response = await api.fetch(endpoint, requestData);
      const responseData = response.data;
      setData(responseData);
    };

    fetchData();
  }, [endpoint, requestData]);

  return {data};
};

export default useDataFetcher;
