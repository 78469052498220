import {Button, Grid, TextField} from '@material-ui/core';
import React, {ReactElement, useContext, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {useStorlessUpload} from '../../../hooks/fetch';
import {appActions} from '../../../modules/app/actions';
import OrderDetailsContext from '../../../screens/Orders/Context/OrderDetailsContext';

const UploadReturnImagesComponent = (): ReactElement => {
  const {t} = useTranslation();
  const [uploadedFile, setUploadedFile] = useState<any>(null);
  const [uploadImageResponse, uploadImage] = useStorlessUpload('upload_return_image');
  const dispatch = useDispatch();
  const {orderId, getOrderDetails} = useContext(OrderDetailsContext);
  const [comment, setComment] = useState('');

  useEffect(() => {
    if (uploadImageResponse?.data) {
      dispatch(appActions.showSnackBar({text: t('general.success'), options: {severity: 'success'}}));
      getOrderDetails({id: orderId});
      setComment('');
    }

    if (uploadImageResponse?.error) {
      dispatch(appActions.showSnackBar({text: t('general.error'), options: {severity: 'error'}}));
    }

    return () => {
      setUploadedFile(null);
      uploadImageResponse.data = null;
      uploadImageResponse.error = null;
    };
  }, [uploadImageResponse]);

  const handleFileUpload = (event: any) => {
    setUploadedFile(event?.target?.files[0]);
    event.target.value = null;
  };

  const handleUploadImage = async () => {
    uploadImage({file: uploadedFile, id: orderId, comment});
  };

  return (
    <Grid container alignItems="flex-start">
      <Grid item xs={12} sm={6}>
        <div
          style={{
            display: 'flex',
            marginLeft: '5px',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <TextField
            onChange={(e) => setComment(e.target.value)}
            placeholder={t('orderDetails.comment')}
            variant="outlined"
            value={comment}
            size="small"
          />
          {!uploadedFile ? (
            <Button variant="contained" component="label" className="blue-button">
              {t('general.chooseFile')}
              <input type="file" hidden id="file" name="file" accept="image/*" onChange={handleFileUpload} />
            </Button>
          ) : (
            <Button variant="contained" onClick={handleUploadImage} className="yellow-button">
              {t('general.uploadFile')}
            </Button>
          )}
        </div>
      </Grid>
    </Grid>
  );
};

export default UploadReturnImagesComponent;
