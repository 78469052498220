import {Grid} from '@material-ui/core';
import {Check, Remove} from '@material-ui/icons';
import React, {useContext, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Util} from '../../modules/api/utils';
import OrderDetailsContext from '../../screens/Orders/Context/OrderDetailsContext';
import {OrderStatusType} from '../../types/Orders/OrderStatusType';
import {OrderType} from '../../types/Orders/OrderType';
import EditIsPaid from './EditIsPaid';
import EditIsPrime from './EditIsPrime';
import EditPaymentMethod from './EditPaymentMethod';
import UploadShippingLabelButton from './OrderActions/UploadShippingLabel';
import EditOrderStatus from './EditOrderStatus';
import {ICustomField} from '../../types/CustomFields/CustomField';
import {CustomField} from '../Product/CustomField';

const convertUTCDateToLocalDate = (dateString: string, withHours = true) => {
  const timeZone = 'Europe/Berlin';

  if (dateString && dateString.length > 0) {
    const dateOptions: Intl.DateTimeFormatOptions = {
      timeZone,
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
      hour12: false,
    };
    if (!withHours) {
      delete dateOptions.hour;
      delete dateOptions.minute;
      delete dateOptions.second;
    }
    const dateFormatter = new Intl.DateTimeFormat('de-DE', dateOptions);
    const dateAsFormattedString = dateFormatter.format(new Date(dateString));
    return dateAsFormattedString;
  }
  return '';
};

const OrderInfo = () => {
  const {order, getOrderDetails, customFields} = useContext(OrderDetailsContext);
  const [currencyCode, setCurrencyCode] = useState('EUR');
  const {t} = useTranslation();

  useEffect(() => {
    setCurrencyCode(Util.getCurrencyCodeByCurrencyIso(order.currencyIso || 'EUR'));
  }, [order]);

  return (
    <div className="order-info">
      <div className="order-info-title">{t('orderDetails.orderInfo')}</div>
      <Grid container spacing={0} alignItems="center">
        <Grid item xs={12} sm={6} className="order-info-item">
          <div className="order-info-label">{t('orderDetails.status')} </div>
          <EditOrderStatus order={order}></EditOrderStatus>
        </Grid>

        <Grid item xs={12} sm={6} className="order-info-item">
          <span className="order-info-label">{t('orderDetails.isTrackingNumberScanned')}</span>
          <span className="order-info-text">{order?.isTrackingNumberScanned ? <Check /> : <Remove />}</span>
        </Grid>

        <Grid item xs={12} sm={6} className="order-info-item">
          <span className="order-info-label">{t('orderDetails.orderNumber')}</span>
          <span className="order-info-text">{order?.orderNumber}</span>
        </Grid>

        {order?.orderType !== OrderType.Amazon ? (
          <Grid item xs={12} sm={6} className="order-info-item">
            <span className="order-info-label">{t('orderDetails.externalOrderNumber')}</span>
            <span className="order-info-text">{order?.externalOrderNumber ? order?.externalOrderNumber : '-'}</span>
          </Grid>
        ) : null}

        {order?.trackingNumber ? (
          <Grid item xs={12} sm={6} className="order-info-item">
            <span className="order-info-label">{t('orderDetails.trackingNumber')}</span>
            <span className="order-info-text">{order?.trackingNumber}</span>
          </Grid>
        ) : null}

        <UploadShippingLabelButton orderId={order._id} getOrderDetails={getOrderDetails} />

        <Grid item xs={12} sm={6} className="order-info-item">
          <span className="order-info-label">{t('orderDetails.shippingType')}</span>
          <span className="order-info-text">{order?.shippingType}</span>
        </Grid>

        <Grid item xs={12} sm={6} className="order-info-item">
          <span className="order-info-label">{t('orderDetails.orderType')}</span>
          <span className="order-info-text">{order?.orderType}</span>
        </Grid>

        <Grid item xs={12} sm={6} className="order-info-item">
          <span className="order-info-label">Shop</span>
          <span className="order-info-text">{order?.shopName}</span>
        </Grid>

        {order?.pricingShop ? (
          <Grid item xs={12} sm={6} className="order-info-item">
            <span className="order-info-label">Pricing Shop</span>
            <span className="order-info-text">{order?.pricingShop}</span>
          </Grid>
        ) : null}

        <Grid item xs={12} sm={6} className="order-info-item">
          <span className="order-info-label">{t('orderDetails.paymentMethodName')}</span>
          <span className="order-info-text">
            <EditPaymentMethod />
          </span>
        </Grid>

        <Grid item xs={12} sm={6} className="order-info-item">
          <span className="order-info-label">{t('orderDetails.isPaid')}</span>
          <span className="order-info-text">
            <EditIsPaid />
          </span>
        </Grid>

        <Grid item xs={12} sm={6} className="order-info-item">
          <span className="order-info-label">{t('orderDetails.isPickupOrder')}</span>
          <span className="order-info-text">{order?.isPickupOrder ? <Check /> : <Remove height={10} />}</span>
        </Grid>

        {order.status === OrderStatusType.Completed ? (
          <Grid item xs={12} sm={6} className="order-info-item">
            <span className="order-info-label">{t('orderDetails.isManuallyCompleted')}</span>
            <span className="order-info-text">{order?.isManuallyCompleted ? <Check /> : <Remove />}</span>
          </Grid>
        ) : null}

        <Grid item xs={12} sm={6} className="order-info-item">
          <span className="order-info-label">{t('orderDetails.currencyIso')}</span>
          <span className="order-info-text">{order?.currencyIso}</span>
        </Grid>

        <Grid item xs={12} sm={6} className="order-info-item">
          <span className="order-info-label">{t('orderDetails.orderTotalSum')}</span>
          <span className="order-info-text">
            {order?.orderTotalSum?.toLocaleString('de-DE', {
              style: 'currency',
              currency: currencyCode,
              minimumFractionDigits: 2,
            })}
          </span>
        </Grid>

        <Grid item xs={12} sm={6} className="order-info-item">
          <span className="order-info-label">{t('orderDetails.orderTotalNetSum')}</span>
          <span className="order-info-text">
            {order?.orderTotalNetSum?.toLocaleString('de-DE', {
              style: 'currency',
              currency: currencyCode,
              minimumFractionDigits: 2,
            })}
          </span>
        </Grid>

        <Grid item xs={12} sm={6} className="order-info-item">
          <span className="order-info-label">{t('orderDetails.shippingCost')}</span>
          <span className="order-info-text">
            {order?.shippingCost?.toLocaleString('de-DE', {
              style: 'currency',
              currency: currencyCode,
              minimumFractionDigits: 2,
            }) || `0,00 ${currencyCode}`}
          </span>
        </Grid>

        <Grid item xs={12} sm={6} className="order-info-item">
          <span className="order-info-label">{t('orderDetails.date')}</span>
          <span className="order-info-text">{convertUTCDateToLocalDate(order?.date?.toString() || '')}</span>
        </Grid>

        <Grid item xs={12} sm={6} className="order-info-item">
          <span className="order-info-label">{t('orderDetails.date')}</span>
          <span className="order-info-text">{convertUTCDateToLocalDate(order?.date?.toString() || '')}</span>
        </Grid>

        {customFields?.map((field: ICustomField) => {
          return (
            <Grid item xs={12} sm={6} className="order-info-item" key={field.fieldName}>
              <span className="order-info-label">{field.fieldName}</span>
              <span className="order-info-text">
                <CustomField type={field.type} value={order?.customFields?.[field.fieldName] ?? ''} />
              </span>
            </Grid>
          );
        })}

        {order?.easyBillInfo?.dueDate && (
          <Grid item xs={12} sm={6} className="order-info-item">
            <span className="order-info-label">{t('orders.dueDate')}</span>
            <span className="order-info-text">
              {convertUTCDateToLocalDate(order?.easyBillInfo?.dueDate.toString() || '', false)}
            </span>
          </Grid>
        )}

        {order?.note ? (
          <Grid item xs={12} sm={6} className="order-info-item">
            <span className="order-info-label">{t('orderDetails.note')}</span>
            <span className="order-info-text">{order.note}</span>
          </Grid>
        ) : null}

        {[OrderType.Amazon, OrderType.EBAY].includes(order?.orderType as any) && (
          <Grid item xs={12} sm={6} className="order-info-item">
            <span className="order-info-label">{t('orderDetails.latestShipDate')}</span>
            <span className="order-info-text">
              {convertUTCDateToLocalDate(order?.latestShipDate?.toString() || '')}
            </span>
          </Grid>
        )}

        {order?.orderType === OrderType.Amazon ? (
          <>
            <Grid item xs={12} sm={6} className="order-info-item">
              <span className="order-info-label">{t('orderDetails.fulfillmentChannel')}</span>
              <span className="order-info-text">{order?.fulfillmentChannel}</span>
            </Grid>

            <Grid item xs={12} sm={6} className="order-info-item">
              <span className="order-info-label">{t('orderDetails.isPrime')}</span>
              <span className="order-info-text">{<EditIsPrime />}</span>
            </Grid>
          </>
        ) : null}
      </Grid>
    </div>
  );
};

export default OrderInfo;
