import {Button} from '@material-ui/core';
import React, {ReactElement, useContext, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useFetch} from '../../../hooks/fetch';
import OrderDetailsContext from '../../../screens/Orders/Context/OrderDetailsContext';
import ButtonListContext from '../../ButtonList/ButtonListContext';

interface IProps {
  isListItem?: boolean;
}

const ViewInvoiceJpgButton = ({isListItem}: IProps): ReactElement => {
  const [invoiceResponse, getInvoice] = useFetch<any>('orders_easybill_invoice_jpg');
  const [printInvoice, setPrintInvoice] = useState<boolean>(false);
  const {hideButtonList} = useContext(ButtonListContext);
  const {order} = useContext(OrderDetailsContext);
  const {t} = useTranslation();

  useEffect(() => {
    if (invoiceResponse.data && printInvoice) {
      const blob = new Blob([new Uint8Array(invoiceResponse.data.data).buffer], {type: 'image/jpeg'});
      const downloadLink = document.createElement('a');
      downloadLink.href = window.URL.createObjectURL(blob);
      downloadLink.download = `${order.orderNumber}.jpg`;
      downloadLink.click();
      hideButtonList && hideButtonList();
    }

    return () => {
      invoiceResponse.data = null;
      setPrintInvoice(false);
    };
  }, [invoiceResponse.data]);

  useEffect(() => {
    if (printInvoice && order?.easyBillInfo) {
      getInvoice({id: order?.easyBillInfo?.easybillInvoiceId});
    }
  }, [printInvoice]);

  return order?.easyBillInfo?.invoiceNumber ? (
    <Button
      variant={isListItem ? 'text' : 'contained'}
      className={isListItem ? 'button-list-item' : 'blue-button'}
      onClick={() => setPrintInvoice(true)}
    >
      {t('orderDetails.viewInvoiceJpg')}
    </Button>
  ) : (
    <></>
  );
};

export default ViewInvoiceJpgButton;
