import React from 'react';
import {useHistory} from 'react-router';
import {AUTH_ROUTES} from '../routers/routes';

const Test = ({text}: {text: string}) => {
  const history = useHistory();
  return (
    <div style={{background: 'white'}}>
      Test
      <button onClick={() => history.push(AUTH_ROUTES.FORGOT_PASSWORD)}>Click</button>
      <p>{text}</p>
    </div>
  );
};

const Test1 = () => {
  return <div>Test 2</div>;
};

export {Test, Test1};
