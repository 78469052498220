import DataTable from '../../components/Table/Table';
import React from 'react';
import {Button, IconButton} from '@material-ui/core';
import {modalActions} from '../../modules/modal/actions';
import {MODALS} from '../../components/Modal/ModalContents';
import {useDispatch} from 'react-redux';
import {useFetch} from '../../hooks/fetch';
import {Clear, Edit} from '@material-ui/icons';
import {useTranslation} from 'react-i18next';

interface IActionsProps {
  doOrderProblemTypePut: (param: any) => void;
  doOrderProblemTypeDelete: (param: any) => void;
  doOrderProblemTypeDetails: (param: any) => void;
  fetchData: () => void;
  id: string;
}

const Actions: React.FC<IActionsProps> = (props: IActionsProps) => {
  const {doOrderProblemTypePut, doOrderProblemTypeDelete, fetchData, id} = props;
  const dispatch = useDispatch();
  const {t} = useTranslation();

  const handleCancelModal = () => {
    dispatch(modalActions.closeModal());
  };

  const handleEditItem = async () => {
    await dispatch(
      modalActions.addModal(MODALS.EDIT_ORDER_PROBLEM_TYPE, {
        title: t('orderProblemType.editOrderProblemType') || '',
        onSubmit: editButtonHandler,
        onCancel: handleCancelModal,
        id: id,
      }),
    );
  };

  const handleDeleteItem = async () => {
    dispatch(
      modalActions.addModal(MODALS.CONFIRM_DIALOG, {
        title: t('orderProblemType.modalTitle') || '',
        content: t('orderProblemType.deleteConfirmation') || '',
        onYes: deleteButtonHandler,
        onNo: handleCancelModal,
      }),
    );
  };

  const editButtonHandler = async (values: any) => {
    const data = {...values, id};
    await doOrderProblemTypePut(data);
    handleCancelModal();
    await fetchData();
  };

  const deleteButtonHandler = async () => {
    await doOrderProblemTypeDelete({id});
    handleCancelModal();
    await fetchData();
  };

  return (
    <div>
      <IconButton onClick={handleEditItem}>
        <Edit fontSize="small" style={{fill: 'gray'}}></Edit>
      </IconButton>
      <IconButton onClick={handleDeleteItem}>
        <Clear fontSize="small" style={{fill: 'red'}}></Clear>
      </IconButton>
    </div>
  );
};

const OrderProblemTypes = () => {
  const dispatch = useDispatch();
  const [orderProblemTypePost, doOrderProblemTypePost] = useFetch('order_problem_types_post');
  const [orderProblemTypePut, doOrderProblemTypePut] = useFetch('order_problem_type_put');
  const [orderProblemTypeDelete, doOrderProblemTypeDelete] = useFetch('order_problem_type_delete');
  const {t} = useTranslation();

  const handleCancelModal = () => {
    dispatch(modalActions.closeModal());
  };

  const handleSubmitModal = async (values: any) => {
    const data = {...values};
    await doOrderProblemTypePost(data);
    handleCancelModal();
  };

  return (
    <div className="orders orders__problems">
      <DataTable
        className="orders__problems__table"
        headers={[
          {
            kind: 'accessor',
            name: t('orderProblemType.name'),
            accessor: 'name',
            sortable: true,
          },
          {
            kind: 'accessor',
            name: t('orderProblemType.description'),
            accessor: 'description',
            sortable: true,
          },
          {
            kind: 'component',
            name: t('general.actions'),
            component: Actions,
            props: {doOrderProblemTypePut, doOrderProblemTypeDelete},
            onFetchProps: {},
          },
        ]}
        endpoint={'order_problem_types'}
        refreshData={orderProblemTypePost.loaded}
        title={t('orderProblemType.screenTitle')}
        actionButtons={
          <Button
            variant="contained"
            className="blue-button"
            onClick={() => {
              dispatch(
                modalActions.addModal(MODALS.EDIT_ORDER_PROBLEM_TYPE, {
                  title: t('orderProblemType.addModalTitle') || '',
                  onSubmit: handleSubmitModal,
                  onCancel: handleCancelModal,
                  orderProblemTypeObject: null,
                }),
              );
            }}
          >
            {t('orderProblemType.addButton')}
          </Button>
        }
      />
    </div>
  );
};

export default OrderProblemTypes;
