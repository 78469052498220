import Button from '@material-ui/core/Button/Button';
import React, {useEffect, useState} from 'react';
import {useFetch} from '../../../hooks/fetch';
import {useDispatch} from 'react-redux';
import {modalActions} from '../../../modules/modal/actions';
import {MODALS} from '../../../components/Modal/ModalContents';
import _ from 'lodash';
import {appActions} from '../../../modules/app/actions';
import {useTranslation} from 'react-i18next';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import BusinessIcon from '@material-ui/icons/Business';
import WorkIcon from '@material-ui/icons/Work';
import SettingsIcon from '@material-ui/icons/Settings';
import {IUpsConfig} from '../../../types/Shipment/interfaces/IUpsConfig';
import {Settings} from '@material-ui/icons';

const UpsConfigDetails = () => {
  const [upsConfigDetailsResponse, getUpsConfigDetails] = useFetch<any>('feature_configuration');
  const [upsConfig, setUpsConfig] = useState<IUpsConfig>(null as any);
  const [upsConfigPut, doUpsConfigPut] = useFetch<any>('feature_configuration_put');
  const dispatch = useDispatch();
  const {t} = useTranslation();

  useEffect(() => {
    getUpsConfigDetails({featureName: 'upsIntegrationConfig'});
  }, []);

  useEffect(() => {
    if (upsConfigDetailsResponse?.data && !upsConfigDetailsResponse.error) {
      setUpsConfig(upsConfigDetailsResponse?.data);
    } else if (upsConfigDetailsResponse?.error) {
      dispatch(appActions.showSnackBar({text: upsConfigDetailsResponse.error.name, options: {severity: 'error'}}));
    }

    return () => {
      upsConfigDetailsResponse.data = null;
      upsConfigDetailsResponse.error = null;
      upsConfigDetailsResponse.loaded = null;
    };
  }, [upsConfigDetailsResponse]);

  useEffect(() => {
    if (upsConfigPut?.data && !upsConfigPut.error) {
      dispatch(appActions.showSnackBar({text: t('upsConfiguration.configUpdated'), options: {severity: 'success'}}));
      getUpsConfigDetails({featureName: 'upsIntegrationConfig'});
    } else if (upsConfigPut?.error) {
      dispatch(appActions.showSnackBar({text: upsConfigPut.error.name, options: {severity: 'error'}}));
    }

    return () => {
      upsConfigPut.data = null;
      upsConfigPut.error = null;
      upsConfigPut.loaded = null;
    };
  }, [upsConfigPut]);

  const handleCancelModal = () => {
    dispatch(modalActions.closeModal());
  };

  const handleSubmitModal = async (values: any) => {
    const data = {id: upsConfig._id, ...values};
    doUpsConfigPut(data);
    handleCancelModal();
  };

  const handleClick = async () => {
    dispatch(
      modalActions.addModal(MODALS.EDIT_UPS_CONFIG, {
        title: t('upsConfiguration.editTitle') || '',
        onSubmit: handleSubmitModal,
        onCancel: handleCancelModal,
        data: upsConfig,
      }),
    );
  };

  return upsConfig ? (
    <div className="customer-details">
      <div className="center-content">
        <div className="customer-details__header">
          <p>{t('upsConfiguration.screenTitle')}</p>
          <Button variant="contained" className="blue-button" onClick={handleClick}>
            {t('general.edit')}
          </Button>
        </div>
        <div className="customer-details__data-container">
          <div className="cards-info-ups">
            <div className="customer-details__data-container__title">
              <LocalShippingIcon style={{fontSize: 25}} />
              <p>{t('upsConfiguration.shipperInfo')}</p>
            </div>
            <div className="customer-details__data-container__body">
              {Object.keys(_.omit(upsConfig.data.shipper, 'address', 'Phone') ?? {}).map((key) => {
                return (
                  <div key={key} className="customer-details__data-container__field">
                    <p>
                      {key
                        .split(/(?=[A-Z])/)
                        .join(' ')
                        .toUpperCase()}
                    </p>
                    <p>{(upsConfig.data.shipper as any)[key]}</p>
                  </div>
                );
              })}
              <div className="customer-details__data-container__field">
                <p>{t('upsConfiguration.phone')}</p>
                <p>{upsConfig.data.shipper.Phone?.Number}</p>
              </div>
            </div>
          </div>
          <div className="cards-info-ups">
            <div className="customer-details__data-container__title">
              <Settings style={{fontSize: 25}} />
              <p>{t('dhlConfiguration.connection')}</p>
            </div>

            <div className="customer-details__data-container__body">
              <>
                {Object.keys(_.omit(upsConfig.data.connectionConfig) ?? {}).map((key) => {
                  if (
                    !_.isObject((upsConfig.data.connectionConfig as any)[key]) &&
                    (upsConfig.data.connectionConfig as any)[key]
                  ) {
                    return (
                      <div key={key} className="customer-details__data-container__field">
                        <p>
                          {key
                            .split(/(?=[A-Z])/)
                            .join(' ')
                            .toUpperCase()}
                        </p>
                        <p>{(upsConfig.data.connectionConfig as any)[key]}</p>
                      </div>
                    );
                  }
                })}
              </>
            </div>
          </div>
          <div className="cards-info-ups">
            <div className="customer-details__data-container__title">
              <BusinessIcon style={{fontSize: 25}} />
              <p>{t('upsConfiguration.address')}</p>
            </div>
            <div className="customer-details__data-container__body">
              {Object.keys(upsConfig.data.shipper.address ?? {}).map((key) => {
                return (
                  <div key={key} className="customer-details__data-container__field">
                    <p>
                      {t(`upsConfiguration.${key}`)
                        .split(/(?=[A-Z])/)
                        .join(' ')
                        .toUpperCase()}
                    </p>
                    <p>{(upsConfig.data.shipper.address as any)[key]}</p>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="cards-info-ups">
            <div className="customer-details__data-container__title">
              <SettingsIcon style={{fontSize: 25}} />
              <p>{t('upsConfiguration.upsService')}</p>
            </div>
            <div className="customer-details__data-container__body">
              {Object.keys(upsConfig.data.service ?? {}).map((key) => {
                return (
                  <div key={key} className="customer-details__data-container__field">
                    <p>
                      {t(`upsConfiguration.${key}`)
                        .split(/(?=[A-Z])/)
                        .join(' ')
                        .toUpperCase()}
                    </p>
                    <p>{(upsConfig.data.service as any)[key]}</p>
                  </div>
                );
              })}
            </div>
          </div>

          <div className="cards-info-ups">
            <div className="customer-details__data-container__title">
              <WorkIcon style={{fontSize: 25}} />
              <p>{t('upsConfiguration.upsUnitOfMeasurement')}</p>
            </div>
            <div className="customer-details__data-container__body">
              <div className="customer-details__data-container__field">
                <p>{t('upsConfiguration.unit')}</p>
                <p>{upsConfig.data.packageWeight.unitOfMeasurement.code}</p>
              </div>
              <div className="customer-details__data-container__field">
                <p>{t('upsConfiguration.weight')}</p>
                <p>{upsConfig.data.packageWeight.weight}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : null;
};

export default UpsConfigDetails;
