import {TableBody as Body, TableCell, TableRow as Row, CircularProgress} from '@material-ui/core';
import React, {ReactElement, useContext, useState} from 'react';
import {useTranslation} from 'react-i18next';
import ProductsWithoutStockContext from '../Context/ProductsWithoutStockContext';
import TableRow from './TableRow';

const TableBody = (): ReactElement => {
  const {supplierOrderItems} = useContext(ProductsWithoutStockContext);
  const [expandedRow, setExpandedRow] = useState<any>();
  const {t} = useTranslation();

  return (
    <Body>
      {supplierOrderItems?.data?.data?.map((item: any) => (
        <TableRow key={item._id} supplierOrderItem={item} setExpandedRow={setExpandedRow} expandedRow={expandedRow} />
      ))}

      {((supplierOrderItems?.data?.data || []).length === 0 || supplierOrderItems?.loading) && (
        <Row>
          <TableCell colSpan={8} style={{textAlign: 'center'}}>
            {supplierOrderItems?.loading ? <CircularProgress /> : t('general.noData')}
          </TableCell>
        </Row>
      )}
    </Body>
  );
};

export default TableBody;
