import {createTheme} from '@material-ui/core';
import vars from './_export.module.scss';

export const defaultTheme = createTheme({
  spacing: (s) => [0, 8, 16, 18, 20, 26, 36, 42, 48, 66, 86, 112][s],
  typography: {
    fontFamily: ['Roboto-Regular', 'sans-serif'].join(','),
    allVariants: {
      fontFamily: 'Roboto-Regular',
      fontSize: 14,
    },
    h1: {
      fontSize: vars.fontSizeTitle,
      fontWeight: 500,
      lineHeight: 1.3,
      letterSpacing: 0.5,
    },
    h2: {
      fontSize: vars.fontSizeSubtitle,
      fontWeight: 500,
    },
    h3: {
      fontSize: vars.fontSizeTitleSmall,
      fontWeight: 300,
    },
    h4: {
      fontSize: vars.fontSizeBody,
      fontWeight: 300,
    },
    h5: {
      fontSize: vars.fontSizeSubtitleSmall,
      fontWeight: 700,
    },
  },
  palette: {
    common: {
      black: vars.blackColor,
      white: vars.whiteColor,
    },
    background: {
      paper: vars.whiteColor,
      default: 'rgba(255, 255, 255, 1)',
    },
    primary: {
      light: vars.primaryLightColor,
      main: vars.primaryColor,
      dark: vars.primaryDarkColor,
    },
    secondary: {
      light: vars.secondaryLightColor,
      main: vars.secondaryColor,
      dark: vars.secondaryDarkColor,
    },
    error: {
      light: vars.errorLightColor,
      main: vars.errorColor,
      dark: vars.errorDarkColor,
    },
    text: {
      primary: vars.primaryTextColor,
      secondary: vars.secondaryTextColor,
      disabled: vars.disabledColor,
      hint: vars.hintColor,
    },
  },
  overrides: {
    MuiFormControl: {
      root: {width: '100%'},
    },
  },
});
