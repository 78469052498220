import {Button, Link, TextField} from '@material-ui/core';
import axios from 'axios';
import React, {useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import config from '../../lib/config';
import {useFetch} from '../../hooks/fetch';
import {useDispatch} from 'react-redux';
import {appActions} from '../../modules/app/actions';

const StaticFiles = () => {
  const {t} = useTranslation();
  const [file, setFile] = useState<any>(null);
  const [getUploadedFilesRes, getUploadedFiles] = useFetch<any>('get_static_files');
  const [uploadedFiles, setUploadedFiles] = useState<any[]>([]);
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const dispatch = useDispatch();

  useEffect(() => {
    getUploadedFiles();
  }, []);

  useEffect(() => {
    if (getUploadedFilesRes.data) {
      setUploadedFiles(getUploadedFilesRes.data);
    }
  }, [getUploadedFilesRes.data]);

  const handleFileChange = (e: any) => {
    setFile(e.target.files[0]);
  };

  const handleFileUpload = async () => {
    if (!file) {
      alert('No file');
      return;
    }

    const formData = new FormData();
    formData.append('file', file);

    try {
      const url = `${config.api}file-upload/static-file`;
      const response = await axios.post(url, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      if (response.status === 201) {
        dispatch(appActions.showSnackBar({text: t('general.success'), options: {severity: 'success'}}));
        getUploadedFiles();
        if (fileInputRef.current) {
          fileInputRef.current.value = '';
        }
      } else {
        dispatch(
          appActions.showSnackBar({
            text: `${t('general.uploadFail')}`,
            options: {severity: 'error'},
          }),
        );
      }
    } catch (error) {
      dispatch(
        appActions.showSnackBar({
          text: `${t('general.uploadFail')}`,
          options: {severity: 'error'},
        }),
      );
      if (fileInputRef.current) {
        fileInputRef.current.value = '';
      }
    }
  };

  return (
    <div className="standard-page">
      <div>
        <h1>{t('general.staticFiles')}</h1>
        <div>
          <TextField onChange={handleFileChange} inputRef={fileInputRef} variant="outlined" type="file" />
        </div>
        <Button
          onClick={handleFileUpload}
          type="submit"
          variant="contained"
          color="primary"
          style={{marginTop: '12px'}}
        >
          {t('general.uploadFile')}
        </Button>
      </div>
      <div style={{marginTop: '12px'}}>
        {uploadedFiles.map((file, index) => (
          <div key={index} style={{marginBottom: '6px'}}>
            <Link href={`${config.api}static/${file}`} className="link">
              {file}
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
};

export default StaticFiles;
