import React from 'react';
import Form from '../Form/Form';
import _ from 'lodash';
import {useTranslation} from 'react-i18next';

export interface IMergeSupplierOrdersModalProps {
  onSubmit: (values: any) => void;
  onCancel: () => void;
}

const generateInitialValues = (fields: any[]) => {
  let defaults = {};
  fields.forEach((field) => {
    defaults = {...defaults, [field.fieldKey]: ''};
  });
  return defaults;
};

const MergeSupplierOrdersModal = (props: IMergeSupplierOrdersModalProps) => {
  const {onSubmit, onCancel} = props;
  const {t} = useTranslation();

  const fields = [
    {
      label: t('supplierOrders.mergeFrom'),
      placeholder: t('supplierOrders.mergeFrom'),
      fieldKey: 'mergeFrom',
      type: 'input',
    },
    {
      label: t('supplierOrders.mergeTo'),
      placeholder: t('supplierOrders.mergeTo'),
      fieldKey: 'mergeTo',
      type: 'input',
    },
  ];
  const initialValues = generateInitialValues(fields);

  return (
    <Form
      title={t('supplierOrders.mergeSupplierOrders')}
      initialValues={initialValues}
      fields={fields}
      submitButtonText={t('general.submit')}
      cancelButtonText={t('general.cancel')}
      onSubmit={onSubmit}
      onCancel={onCancel}
    ></Form>
  );
};

export default MergeSupplierOrdersModal;
