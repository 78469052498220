export enum OrderStatusType {
  /**Orders from an Amazon Customer, where the payment has not yet been made and the customer information is not yet available.*/
  Pending = 'Pending',
  /**Orders that are canceled by an Amazon Customer.*/
  Canceled = 'Canceled',
  /**Orders that failed UPS or DHL validation.*/
  ValidationFailed = 'Validation Failed',
  /**Orders from an Amazon Customer, where the payment has not yet been made and the customer information is not yet available.*/
  New = 'New',
  /**Orders that are on hold.*/
  OnHold = 'On Hold',
  /**Orders that are already shipped.*/
  Completed = 'Completed',
  /**Offers */
  Offer = 'Offer',
  /**Offers that are not finished yet.*/
  PendingOffer = 'Pending Offer',
  /**Returned order */
  Returned = 'Returned',
  /**Partially Returned order */
  PartiallyReturned = 'Partially Returned',
}
