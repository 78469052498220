import {Button} from '@material-ui/core';
import React, {ReactElement, useContext, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {useFetch} from '../../../hooks/fetch';
import {appActions} from '../../../modules/app/actions';
import {modalActions} from '../../../modules/modal/actions';
import {OrderStatusType} from '../../../types/Orders/OrderStatusType';
import {MODALS} from '../../Modal/ModalContents';
import OrderDetailsContext from '../../../screens/Orders/Context/OrderDetailsContext';

interface IProps {
  isListItem?: boolean;
}

const CancelOrderButton = ({isListItem}: IProps): ReactElement => {
  const [cancelOrderResponse, cancelOrder] = useFetch('orders_cancel');
  const {order, getOrderDetails} = useContext(OrderDetailsContext);
  const {t} = useTranslation();
  const dispatch = useDispatch();

  const cancelOrderHandler = () => {
    dispatch(
      modalActions.addModal(MODALS.CONFIRM_DIALOG, {
        title: t('general.areYouSure'),
        content: t('orderDetails.cancelOrderMessage'),
        onYes: () => cancelOrder({id: order._id}),
        onNo: () => dispatch(modalActions.closeModal()),
      }),
    );
  };

  useEffect(() => {
    if (cancelOrderResponse.data) {
      dispatch(modalActions.closeModal());
      getOrderDetails({id: order._id});
      dispatch(appActions.showSnackBar({text: t('general.success'), options: {severity: 'success'}}));
    }

    if (cancelOrderResponse.error) {
      const errorMessage = cancelOrderResponse.error?.name || 'Error';
      dispatch(appActions.showSnackBar({text: errorMessage, options: {severity: 'error'}}));
    }

    return () => {
      cancelOrderResponse.data = null;
      cancelOrderResponse.error = null;
    };
  }, [cancelOrderResponse]);

  const shouldShowButton = () => {
    if (order.status === OrderStatusType.Canceled) {
      return false;
    }

    return true;
  };

  return shouldShowButton() ? (
    <Button
      variant={isListItem ? 'text' : 'contained'}
      className={isListItem ? 'button-list-item' : 'blue-button'}
      onClick={cancelOrderHandler}
    >
      {t('orderDetails.cancelOrder')}
    </Button>
  ) : (
    <></>
  );
};

export default CancelOrderButton;
