export enum ShippingProviderType {
  DHL = 'DHL',
  UPS = 'UPS',
  GLS = 'GLS',
  DPD = 'DPD',
  DEUTSCHE_POST = 'Deutsche Post',
  KUEHNE_NAGEL = 'Kühne + Nagel',
  DB_SCHENKER = 'DB Schenker',
  TRANS_O_FLEX = 'trans-o-flex',
  HERMES = 'Hermes',
  DACHSER = 'Dachser',
  FEDEX = 'FedEx',
  TNT = 'TNT',
}

export const shippingProviderTypeFilter = Object.values(ShippingProviderType).map((value) => ({
  label: value,
  value,
}));
