import React, {useEffect, useState} from 'react';
import {appActions} from '../../modules/app/actions';
import {Button, TextField} from '@material-ui/core';
import {useStorlessFetch} from '../../hooks/fetch';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import moment from 'moment';
import {Endpoint} from '../../modules/api/endpoints';
import {IOrderNote} from '../../types/Orders/IOrderNote';
import useUser from '../../hooks/user';
import {Link} from 'react-router-dom';
import {IOrder} from '../../types/Orders/IOrder';
import {APP_ROUTES} from '../../routers/routes';
import {ISupplierOrder} from '../../types/SupplierOrders/ISupplierOrder';

interface IProps {
  endpoint: Endpoint;
  marginTop?: string;
  order: IOrder | ISupplierOrder;
  setOrder: any;
}

interface NoteRenderProps {
  orderId?: string;
  orderNumber?: string;
}

export default function OrderNotesBox({endpoint, marginTop = '40px', order, setOrder}: IProps) {
  const [updateOrderRes, updateOrder] = useStorlessFetch(endpoint);
  const user = useUser() as any;
  const [note, setNote] = useState('');
  const dispatch = useDispatch();
  const {t} = useTranslation();
  const [allNotes, setAllNotes] = useState<IOrderNote[]>([]);

  useEffect(() => {
    const combinedNotes: IOrderNote[] = [];

    if (order.notes) combinedNotes.push(...order.notes);

    if ((order as any).exchangeOrderParent?.id?.notes) {
      combinedNotes.push(
        ...(order as any).exchangeOrderParent.id.notes.map((note: IOrderNote) => ({
          ...note,
          orderId: (order as any).exchangeOrderParent.id._id,
          orderNumber: (order as any).exchangeOrderParent.orderNumber,
        })),
      );
    }

    if ((order as any).exchangeOrders) {
      (order as any).exchangeOrders.forEach((exchangeOrder: any) => {
        if (exchangeOrder.id?.notes) {
          combinedNotes.push(
            ...exchangeOrder.id.notes.map((note: IOrderNote) => ({
              ...note,
              orderId: exchangeOrder.id._id,
              orderNumber: exchangeOrder.orderNumber,
            })),
          );
        }
      });
    }

    combinedNotes.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());
    setAllNotes(combinedNotes);
  }, [order]);

  const addNote = () => {
    const newNote = {date: new Date(), note: note, username: user.username};
    const notes = [...(order.notes || []), newNote];
    updateOrder({id: order._id, notes});
    setOrder({...order, notes});
    setNote('');
  };

  useEffect(() => {
    if (updateOrderRes.data) {
      dispatch(appActions.showSnackBar({text: t('general.success'), options: {severity: 'success'}}));
    } else if (updateOrderRes.error) {
      dispatch(appActions.showSnackBar({text: t('general.error'), options: {severity: 'error'}}));
    }

    return () => {
      updateOrderRes.data = null;
      updateOrderRes.error = null;
    };
  }, [updateOrderRes]);

  const renderNote = (note: IOrderNote, {orderId, orderNumber}: NoteRenderProps = {}) => {
    return (
      <div key={note.note} className="order-notes-item-wrapper">
        <div className="order-notes-item-date">
          {orderId ? (
            <Link className="link" to={`${APP_ROUTES.ORDERS}/${orderId}`} target="_blank">
              <span>{orderNumber} </span>
            </Link>
          ) : null}
          {moment(note.date).format('DD.MM.YYYY HH:mm')}
        </div>
        <div className="order-notes-item">
          <span>
            {note.username ? <b>{note.username}: </b> : ''}
            {note.note}
          </span>
        </div>
      </div>
    );
  };

  return (
    <div className="order-notes-box" style={{marginTop: marginTop}}>
      <div className="order-info-title">{t('orderDetails.orderNotes')}</div>

      <div className="order-notes-list">
        {allNotes.length ? (
          allNotes.map((note: IOrderNote) =>
            renderNote(note, {
              orderId: note.orderId,
              orderNumber: note.orderNumber,
            }),
          )
        ) : (
          <div className="order-notes-item-wrapper">
            <div className="order-notes-item">{t('orderDetails.noNotes')}</div>
          </div>
        )}
      </div>

      <TextField
        onChange={(e) => setNote(e.target.value)}
        placeholder={t('orderDetails.enterNote')}
        className="input multiline-input"
        variant="outlined"
        value={note}
        minRows={4}
        multiline
      />

      <div style={{textAlign: 'right'}}>
        <Button
          disabled={note.length === 0}
          variant="contained"
          className={note.length > 0 ? 'blue-button' : 'inactive-button'}
          onClick={addNote}
        >
          {t('general.save')}
        </Button>
      </div>
    </div>
  );
}
