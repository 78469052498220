import React, {useState, useEffect} from 'react';
import {TextField, Button, Typography} from '@material-ui/core';
import {useFetch} from '../../hooks/fetch';
import {debounce} from 'lodash';
import {useTranslation} from 'react-i18next';
import {history} from '../../lib/history';

export interface ISupplierOrderScannerProps {
  onClose: () => void;
  refresh: (value: boolean) => void;
}

const SupplierOrderLabelScanner: React.FC<ISupplierOrderScannerProps> = ({onClose, refresh}) => {
  const [barcode, setBarcode] = useState<string>('');
  const [getOrderIdRes, getOrderId] = useFetch<any>('get_supplier_order_by_scanned_label');
  const {t} = useTranslation();
  const debouncedHandleBarcodeChange = debounce((newBarcode: string) => {
    refresh(false);
    getOrderId({trackingNumber: newBarcode});
  }, 500);

  const handleBarcodeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newBarcode = event.target.value;
    setBarcode(newBarcode);
  };

  useEffect(() => {
    if (barcode === '') {
      return;
    }
    debouncedHandleBarcodeChange(barcode);
    return debouncedHandleBarcodeChange.cancel;
  }, [barcode]);

  useEffect(() => {
    if (getOrderIdRes?.data) {
      const id = getOrderIdRes.data;

      history.push(`/supplierOrders/${id}`);
      onClose();
    }

    return () => {
      getOrderIdRes.data = null;
      getOrderIdRes.error = null;
    };
  }, [getOrderIdRes]);

  useEffect(() => {
    setBarcode('');

    return () => {
      setBarcode('');
    };
  }, []);

  return (
    <div className="orders" style={{minWidth: '400px', width: 'auto'}}>
      <Typography>{t('supplierOrders.shippingLabelScannerTitle')}</Typography>
      <br />

      <TextField label="Barcode" variant="outlined" autoFocus value={barcode} onChange={handleBarcodeChange} />
      <br />

      <Button style={{float: 'right'}} className="blue-button" onClick={onClose}>
        {t('general.cancel')}
      </Button>
    </div>
  );
};

export default SupplierOrderLabelScanner;
