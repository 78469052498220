import {Button} from '@material-ui/core';
import React, {ReactElement, useContext, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {useFetch} from '../../../hooks/fetch';
import {appActions} from '../../../modules/app/actions';
import {modalActions} from '../../../modules/modal/actions';
import {MODALS} from '../../Modal/ModalContents';
import _ from 'lodash';
import CreateSupplierOrderContext from '../../../screens/SupplierOrders/Context/CreateSupplierOrderContext';

interface IProps {
  isListItem?: boolean;
}

const SupplierOrderEditButton = ({isListItem}: IProps): ReactElement => {
  const [patchOrderResponse, patchOrder] = useFetch<any>('supplier_order_patch');
  const {order, orderId, setState, getSupplierOrderDetails, customFields} = useContext(CreateSupplierOrderContext);
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const handleCancelModal = () => dispatch(modalActions.closeModal());

  const handleSubmitModal = async (values: any) => {
    const updatedOrderData = _.omitBy(values, (value, key) => (order as any)[key] === value);
    const data = {...updatedOrderData, id: orderId};

    patchOrder(data);
    setState({...order, ...data});
    getSupplierOrderDetails({id: orderId});
    handleCancelModal();
  };

  const handleClick = async () => {
    dispatch(
      modalActions.addModal(MODALS.EDIT_SUPPLIER_ORDER, {
        title: t('orderDetails.editOrder') || '',
        onSubmit: handleSubmitModal,
        onCancel: handleCancelModal,
        orderObject: order,
        customFields,
      }),
    );
  };

  useEffect(() => {
    if (patchOrderResponse?.data) {
      dispatch(appActions.showSnackBar({text: t('general.submitFormText'), options: {severity: 'success'}}));
    } else if (patchOrderResponse?.error) {
      dispatch(appActions.showSnackBar({text: patchOrderResponse.error.name, options: {severity: 'error'}}));
    }

    return () => {
      patchOrderResponse.data = null;
      patchOrderResponse.error = null;
    };
  }, [patchOrderResponse]);

  return (
    <Button
      variant={isListItem ? 'text' : 'contained'}
      className={isListItem ? 'button-list-item' : 'blue-button'}
      onClick={handleClick}
    >
      {t('general.edit')}
    </Button>
  );
};

export default SupplierOrderEditButton;
