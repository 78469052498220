import {FILTER, FilterActions} from './actions';
import _ from 'lodash';

export interface IFilterState {
  filters: {key: string; value: any}[];
}
export const INITIAL_STATE: IFilterState = {
  filters: [],
};

const filterReducer = (state = INITIAL_STATE, action: FilterActions) => {
  switch (action.type) {
    case FILTER.SET_FILTER_VALUE: {
      const filters = state.filters;
      const index = filters.findIndex((filter) => filter.key === action.payload.key);
      if (index > -1) {
        filters[index] = {...filters[index], ...action.payload};
        return {...state, filters: filters};
      }
      return {
        ...state,
        filters: [...filters, action.payload],
      };
    }
    default:
      return state;
  }
};

export {filterReducer};
