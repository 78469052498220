import DataTable from '../../components/Table/Table';
import React, {useEffect} from 'react';
import {IconButton} from '@material-ui/core';
import {modalActions} from '../../modules/modal/actions';
import {MODALS} from '../../components/Modal/ModalContents';
import {useDispatch} from 'react-redux';
import {useFetch} from '../../hooks/fetch';
import {Clear, Edit} from '@material-ui/icons';
import {useTranslation} from 'react-i18next';
import {ProblemOrderType} from '../../types/OrderProblems/ProblemOrderType';
import {IOrderProblem} from '../../types/OrderProblems/IOrderProblem';
import {appActions} from '../../modules/app/actions';
import {OrderProblemLocationType} from '../../types/OrderProblems/OrderProblemLocationType';
import {OrderProblemStatusType} from '../../types/OrderProblems/OrderProblemStatusType';
import {ShippingProviderType} from '../../types/Shipment/ShippingProviderType';
import {DataTableItemType} from '../../components/Table/interfaces/IHeaderAccessor';

interface IActionsProps {
  doOrderProblemPut: (param: any) => void;
  doOrderProblemDelete: (param: any) => void;
  doOrderProblemDetails: (param: any) => void;
  fetchData: () => void;
  id: string;
}

export const OrderProblemActions: React.FC<IActionsProps> = (props: IActionsProps) => {
  const {doOrderProblemPut, doOrderProblemDelete, fetchData, id} = props;
  const dispatch = useDispatch();
  const {t} = useTranslation();

  const handleCancelModal = () => {
    dispatch(modalActions.closeModal());
  };

  const handleDeleteItem = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    event.preventDefault();

    dispatch(
      modalActions.addModal(MODALS.CONFIRM_DIALOG, {
        title: t('orderProblems.modalTitle') || '',
        content: t('orderProblems.deleteConfirmation'),
        onYes: deleteButtonHandler,
        onNo: handleCancelModal,
      }),
    );
  };

  const editButtonHandler = async (values: any) => {
    const orderProblem: IOrderProblem = {...values};

    await doOrderProblemPut(orderProblem);
    handleCancelModal();
    await fetchData();
  };

  const deleteButtonHandler = async () => {
    await doOrderProblemDelete({id});
    handleCancelModal();
    await fetchData();
  };

  const handleEditItem = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    event.preventDefault();

    dispatch(
      modalActions.addModal(MODALS.ADD_ORDER_PROBLEM, {
        title: t('orderProblems.editItemTitle') || '',
        onSubmit: editButtonHandler,
        onCancel: handleCancelModal,
        orderProblemId: id,
      }),
    );
  };

  return (
    <div>
      <IconButton onClick={handleEditItem}>
        <Edit fontSize="small" style={{fill: 'gray'}}></Edit>
      </IconButton>
      <IconButton onClick={handleDeleteItem}>
        <Clear fontSize="small" style={{fill: 'red'}}></Clear>
      </IconButton>
    </div>
  );
};

export interface IOrderProblemProps {
  orderType: ProblemOrderType;
}

const OrderProblems = (props: IOrderProblemProps) => {
  const [orderProblemPut, doOrderProblemPut] = useFetch('order_problem_put');
  const [orderProblemDelete, doOrderProblemDelete] = useFetch('order_problem_delete');
  const dispatch = useDispatch();
  const {t} = useTranslation();

  useEffect(() => {
    if (orderProblemDelete?.data) {
      dispatch(
        appActions.showSnackBar({
          text: t('general.submitFormText'),
          options: {severity: 'success'},
        }),
      );
    } else if (orderProblemDelete?.error) {
      dispatch(
        appActions.showSnackBar({
          text: orderProblemDelete.error.name,
          options: {severity: 'error'},
        }),
      );
    }

    return () => {
      orderProblemDelete.data = null;
      orderProblemDelete.error = null;
    };
  }, [orderProblemDelete]);

  useEffect(() => {
    if (orderProblemPut?.data) {
      dispatch(
        appActions.showSnackBar({
          text: t('general.submitFormText'),
          options: {severity: 'success'},
        }),
      );
    } else if (orderProblemPut?.error) {
      dispatch(
        appActions.showSnackBar({
          text: orderProblemPut.error.name,
          options: {severity: 'error'},
        }),
      );
    }

    return () => {
      orderProblemPut.data = null;
      orderProblemPut.error = null;
    };
  }, [orderProblemPut]);

  return (
    <div className="orders">
      <DataTable
        className="orders__table"
        headers={[
          {
            kind: 'accessor',
            name: t('orderProblems.orderNumber'),
            accessor: 'orderNumber',
            sortable: true,
          },
          {
            kind: 'accessor',
            name: t('orderProblems.status'),
            accessor: 'status',
            sortable: true,
            type: DataTableItemType.MULTIPLE_SELECT,
            options: ([{value: 'all', label: 'All'}] as any).concat(
              Object.values(OrderProblemStatusType).map((item) => {
                return {value: item, label: item};
              }),
            ),
          },
          {
            kind: 'accessor',
            name: t('orderProblems.location'),
            accessor: 'location',
            sortable: true,
            type: DataTableItemType.SELECT,
            options: ([{value: 'all', label: 'All'}] as any).concat(
              Object.values(OrderProblemLocationType).map((item) => {
                return {value: item, label: item};
              }),
            ),
          },
          {
            kind: 'accessor',
            name: t('orderProblems.orderProblemType'),
            accessor: 'orderProblemTypeName',
            sortable: true,
          },
          {
            kind: 'accessor',
            name: t('orderProblems.reminderDate'),
            accessor: 'reminderDate',
            sortable: true,
            type: DataTableItemType.DATE,
          },
          {
            kind: 'accessor',
            name: t('orderProblems.shippingProvider'),
            accessor: 'orderShippingType',
            sortable: true,
            type: DataTableItemType.SELECT,
            options: ([{value: 'all', label: 'All'}] as any).concat(
              Object.values(ShippingProviderType).map((item) => {
                return {value: item, label: item};
              }),
            ),
          },
          {
            kind: 'accessor',
            name: t('orderProblems.comment'),
            accessor: 'comment',
            sortable: true,
          },
          {
            kind: 'component',
            name: t('general.actions'),
            component: OrderProblemActions,
            props: {doOrderProblemPut, doOrderProblemDelete},
            onFetchProps: {conditions: {order: doOrderProblemPut.data?.id}},
          },
        ]}
        endpoint={'order_problems'}
        options={{orderType: props.orderType}}
        target={'orders'}
        selectRowClickId={'order._id'}
        refreshData={orderProblemPut.loaded}
        defaultSort={{key: 'createdAt', value: -1}}
        title={t('orderProblems.screenTitle')}
      />
    </div>
  );
};

export default OrderProblems;
