import React, {useState, useEffect} from 'react';
import {TextField, Button, Typography} from '@material-ui/core';
import {useFetch} from '../../hooks/fetch';
import {debounce} from 'lodash';
import {useDispatch} from 'react-redux';
import {appActions} from '../../modules/app/actions';
import {useTranslation} from 'react-i18next';

export interface IProductScannerProps {
  onClose: () => void;
  history: any;
}

const ProductScanner: React.FC<IProductScannerProps> = ({onClose, history}) => {
  const [barcode, setBarcode] = useState<string>('');
  const [getOrderIdRes, getOrderId] = useFetch<any>('order_id_by_scanned_product');
  const dispatch = useDispatch();
  const {t} = useTranslation();
  const debouncedHandleBarcodeChange = debounce((newBarcode: string) => {
    newBarcode && getOrderId({barCode: newBarcode});
  }, 500);

  const handleBarcodeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newBarcode = event.target.value;
    setBarcode(newBarcode);
  };

  useEffect(() => {
    debouncedHandleBarcodeChange(barcode);
    return debouncedHandleBarcodeChange.cancel;
  }, [barcode]);

  useEffect(() => {
    if (getOrderIdRes?.data) {
      const orderId = getOrderIdRes?.data;
      history.push(`/orders/${orderId}?scanOrder=1`);
    }

    if (getOrderIdRes?.data === false) {
      dispatch(
        appActions.showSnackBar({
          text: t('scanner.productScannerNoOrder'),
          options: {severity: 'error'},
        }),
      );
    }

    return () => {
      getOrderIdRes.data = null;
      getOrderIdRes.error = null;
    };
  }, [getOrderIdRes]);

  useEffect(() => {
    setBarcode('');

    return () => {
      setBarcode('');
    };
  }, []);

  return (
    <div className="orders" style={{minWidth: '400px', width: 'auto'}}>
      <Typography>{t('scanner.productScannerTitle')}</Typography>
      <br />

      <TextField label="Barcode" variant="outlined" autoFocus value={barcode} onChange={handleBarcodeChange} />
      <br />

      <Button style={{float: 'right'}} className="blue-button" onClick={onClose}>
        {t('general.cancel')}
      </Button>
    </div>
  );
};

export default ProductScanner;
