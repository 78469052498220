import React from 'react';
import DataTable from '../../components/Table/Table';
import {DataTableItemType} from '../../components/Table/interfaces/IHeaderAccessor';
import {useTranslation} from 'react-i18next';

export interface IProps {
  supplierId: string;
}

export default function SupplierComplaintsBySupplier({supplierId}: IProps) {
  const {t} = useTranslation();

  return (
    <DataTable
      className="orders__table"
      headers={[
        {
          kind: 'accessor',
          name: t('supplierComplaints.number'),
          accessor: 'number',
          sortable: true,
          type: DataTableItemType.NUMBER,
        },
        {
          kind: 'accessor',
          name: t('supplierComplaints.createdAt'),
          accessor: 'createdAt',
          sortable: true,
          type: DataTableItemType.DATE,
        },
      ]}
      endpoint={'supplier_complaints_list'}
      target="supplier-complaints"
      defaultSort={{key: 'createdAt', value: -1}}
      options={{supplier: supplierId}}
    />
  );
}
