import React, {useContext, useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {Grid} from '@material-ui/core';
import {useTranslation} from 'react-i18next';
import {MODALS} from '../../components/Modal/ModalContents';
import {modalActions} from '../../modules/modal/actions';
import {IOrder} from '../../types/Orders/IOrder';
import {useFetch, useStorlessFetch} from '../../hooks/fetch';
import ReturnOrderButton from '../../components/Order/OrderActions/ReturnOrderButton';
import DeliveryBillButton from '../../components/Order/OrderActions/DeliveryBillButton';
import ShippingLabelButton from '../../components/Order/OrderActions/ShippingLabelButton';
import ShipmentSyncButton from '../../components/Order/OrderActions/ShipmentSyncButton';
import ReturnLabelButton from '../../components/Order/OrderActions/ReturnLabelButton';
import AddProblemButton from '../../components/Order/OrderActions/AddProblemButton';
import CompleteOrderButton from '../../components/Order/OrderActions/CompleteOrderButton';
import EditOrderButton from '../../components/Order/OrderActions/EditOrderButton';
import CancelOrderButton from '../../components/Order/OrderActions/CancelOrderButton';
import PdfOfferButton from '../../components/Order/CreatingOrders/PdfOfferButton';
import EmptyTrackingOrderButton from '../../components/Order/OrderActions/EmptyTrackingOrderButton';
import ViewInvoiceButton from '../../components/Order/OrderActions/ViewInvoiceButton';
import ViewInvoiceJpgButton from '../../components/Order/OrderActions/ViewInvoiceJpgButton';
import OrderDeliveredConfirmationButton from '../../components/Order/OrderActions/OrderDeliveredConfirmationButton';
import VideoRecorderContext from '../../components/VideoRecorder/VideoRecorderContext';
import SendEmailButton from '../../components/Order/OrderActions/SendEmailButton';
import OrderInfo from '../../components/Order/OrderInfo';
import OrderSideInfo from '../../components/Order/OrderSideInfo';
import OrderDetailsTabContainer from '../../components/Order/OrderDetailsTabContainer';
import ButtonList from '../../components/ButtonList/ButtonList';
import OrderNotesBox from '../../components/Order/OrderNotesBox';
import EditDueDateButton from '../../components/Order/OrderActions/EditDueDateButton';
import CreateExchangeOrderButton from '../../components/Order/OrderActions/CreateExchangeOrderButton';
import CreateCreditNoteButton from '../../components/Order/OrderActions/CreateCreditNoteButton';
import useUser from '../../hooks/user';
import {OrderDetailsProvider} from './Context/OrderDetailsContext';
import {OrderStatusType} from '../../types/Orders/OrderStatusType';
import {OrderType} from '../../types/Orders/OrderType';
import {OfferTemplateType} from '../../types/Orders/OfferTemplateType';
import FetchOrderFromAmazonButton from '../../components/Order/OrderActions/FetchOrderFromAmazonButton';
import {history} from '../../lib/history';
import SecondLabelButton from '../../components/Order/OrderActions/SecondLabelButton';
import useDataFetcher from '../../hooks/dataFetcher';
import {EntityType} from '../../types/CustomFields/CustomField';
import CreateInvoiceForExchangeOrderButton from '../../components/Order/OrderActions/CreateInvoiceForExchangeOrderButton';
import CustomsButton from '../../components/Order/OrderActions/CustomsButton';

const OrderDetails = ({match, location}: any) => {
  const {id} = match.params;
  const user = useUser() as any;
  const [easyBillRetryResponse, retryEasyBillInvoiceCreation] = useStorlessFetch('easybill_invoice_retry');
  const [orderDetails, getOrderDetails] = useStorlessFetch('order_details');
  const [getNextOrderRes, getNextOrder] = useStorlessFetch('get_next_order');
  const [getErrorLogsResponse, getErrorLogs] = useStorlessFetch('error_logs_id');
  const featureName = user?.email ? `customConfig-${user?.email}` : undefined;
  const [customConfig, getCustomConfig] = useStorlessFetch('custom_config');
  const [orderProblemTable, refreshOrderProblemTable] = useState(false);
  const [order, setOrder] = useState<IOrder>(null as any);

  const query = new URLSearchParams(location.search);
  const shouldOpenScanningModal = query.get('scanOrder');
  const listName = query.get('listName') || undefined;
  const type = query.get('type') || undefined;
  const isProductList = query.get('isProductList') === 'true';

  const createOrderAsOffer = query.get('isOffer');
  const {startRecordingRef, stopRecordingRef} = useContext(VideoRecorderContext);
  const dispatch = useDispatch();

  const {t} = useTranslation();
  const {OFFER, ORDER} = OfferTemplateType;

  const {data: customFields} = useDataFetcher('get_custom_field_entity_fields', {
    entityType: EntityType.CUSTOMER_ORDER,
  });

  const getNextOrderLink = () => {
    if (listName) {
      return `/orders/${getNextOrderRes.data}?scanOrder=1&listName=${listName}&isProductList=${isProductList}`;
    }
    return `/orders/${getNextOrderRes.data}?scanOrder=1&type=${type}`;
  };

  const onTrackingCodeScanSuccess = async (resetState: any) => {
    getNextOrder({currentOrderNumber: order.orderNumber, listName, isProductList, type});
    resetState();
  };

  useEffect(() => {
    const fetchData = async () => {
      getOrderDetails({id});
      getCustomConfig({featureName});
    };

    fetchData();

    return () => {
      getNextOrderRes.data = null;
      getNextOrderRes.error = null;
    };
  }, [id]);

  useEffect(() => {
    if (orderDetails.data) {
      const {Pending, Offer, PendingOffer} = OrderStatusType;
      const {status, orderType} = orderDetails.data;

      if ([Pending, Offer, PendingOffer].includes(status) && orderType === OrderType.Manual) {
        history.push(`/orders/create/${id}?isOffer=${createOrderAsOffer}`);
      }

      getErrorLogs({id});
      setOrder(orderDetails.data);
    }
  }, [orderDetails]);

  useEffect(() => {
    if (order && shouldOpenScanningModal) {
      handleCustomerOrderScanning();
    }
  }, [order]);

  useEffect(() => {
    if (getNextOrderRes?.data) {
      const jumpToNextOrder = customConfig?.data?.data?.printerConfig?.jumpToNextOrder;

      if (jumpToNextOrder && getNextOrderRes?.data !== 'NO-ORDER' && order?._id !== getNextOrderRes?.data) {
        const nextOrderLink = getNextOrderLink();

        dispatch(modalActions.closeModal());
        history.push(nextOrderLink);
      } else {
        dispatch(modalActions.closeModal());

        if (type) {
          history.push(`/${type?.toLowerCase()}-orders-ready-for-shipping`);
        } else if (listName && isProductList) {
          history.push(`/orders-ready-for-shipping/product/${listName}`);
        } else if (listName) {
          history.push(`/orders-ready-for-shipping/${listName}`);
        } else {
          history.push(`/orders-ready-for-shipping`);
        }
      }
    }
  }, [getNextOrderRes.data]);

  const handleCustomerOrderScanning = async () => {
    startRecordingRef.current?.click();

    dispatch(
      modalActions.addModal(MODALS.CUSTOMER_ORDER_SCANNER_MODAL, {
        onTrackingCodeScanSuccess: onTrackingCodeScanSuccess,
        onClose: () => stopRecordingRef.current?.click(),
        onScan: async () => getOrderDetails({id}),
        hideDefaultCloseButton: true,
        order,
      }),
    );
  };

  if (!order) {
    return null;
  }

  return (
    <OrderDetailsProvider
      value={{
        order,
        orderId: id,
        orderDetails,
        setOrder,
        getOrderDetails,
        orderProblemTable,
        refreshOrderProblemTable,
        easyBillRetryResponse,
        retryEasyBillInvoiceCreation,
        customFields,
      }}
    >
      <div className="order-details">
        <div className="order-header">
          <div className="order-header-title">
            <span className="left-title">
              {t('general.orders')} / <span className="right-title">{order?.orderNumber}</span>
            </span>
          </div>

          <div className="order-header-buttons">
            {!order?.parcels?.length && <DeliveryBillButton />}
            <ShipmentSyncButton />
            {!order?.parcels?.length && <ShippingLabelButton />}
            <CustomsButton />
            <CompleteOrderButton />
            <CancelOrderButton />
            <EditOrderButton />
            <ButtonList
              buttonText={t('general.more')}
              items={[
                <ViewInvoiceButton key={'ViewInvoiceButton'} isListItem={true} />,
                <ViewInvoiceJpgButton key={'ViewInvoiceJpgButton'} isListItem={true} />,
                <CreateCreditNoteButton key={'CreateCreditNoteButton'} isListItem={true} />,
                <PdfOfferButton key={'PdfOfferButton'} orderId={id} type={OfferTemplateType.OFFER} isListItem={true} />,
                <PdfOfferButton key={'PdfOfferButton'} orderId={id} type={OfferTemplateType.ORDER} isListItem={true} />,
                <AddProblemButton key={'AddProblemButton'} isListItem={true} />,
                <OrderDeliveredConfirmationButton key={'OrderDeliveredConfirmationButton'} id={id} isListItem={true} />,
                <ReturnOrderButton key={'ReturnOrderButton'} isListItem={true} />,
                <ReturnLabelButton key={'ReturnLabelButton'} isListItem={true} />,
                <SecondLabelButton key={'SecondLabelButton'} isListItem={true} />,
                <SendEmailButton
                  key={'SendEmailButton'}
                  isListItem={true}
                  type={[OrderStatusType.Offer, OrderStatusType.PendingOffer].includes(order?.status) ? OFFER : ORDER}
                  orderNumber={order.orderNumber || ''}
                  orderId={id}
                />,
                <EditDueDateButton
                  key={'EditDueDateButton'}
                  isListItem={true}
                  orderId={id}
                  dueDate={order?.easyBillInfo?.dueDate}
                  getOrderDetails={getOrderDetails}
                />,
                <EmptyTrackingOrderButton key={'EmptyTrackingOrderButton'} isListItem={true} />,
                <CreateExchangeOrderButton key={'CreateExchangeOrderButton'} isListItem={true} />,
                <FetchOrderFromAmazonButton key={'FetchOrderFromAmazonButton'} isListItem={true} />,
                <CreateInvoiceForExchangeOrderButton key={'CreateInvoiceForExchangeOrderButton'} isListItem={true} />,
              ]}
            />
          </div>
        </div>

        <Grid container>
          <Grid item xs={12} sm={8} md={8}>
            <div className="order-overview-title">
              {t('general.orderOverview')} - {order.orderNumber}
            </div>
            <div style={{color: 'red'}}>{getErrorLogsResponse?.data ? t('general.containsErrorLogs') : ''}</div>
            <OrderInfo />
            <OrderDetailsTabContainer />
          </Grid>
          <Grid item xs={12} sm={4} md={4}>
            <OrderNotesBox endpoint="orders_patch" setOrder={setOrder} order={order} />
            <OrderSideInfo />
          </Grid>
        </Grid>
      </div>
    </OrderDetailsProvider>
  );
};

export default OrderDetails;
