import React, {useContext, useEffect, useState} from 'react';
import ListOfTodos from './ListOfTodos';
import TodoContext from '../../screens/Todo/Context/TodoContext';

const SerialTasks = () => {
  const {refreshTodos, serialTodos} = useContext(TodoContext);

  useEffect(() => {
    refreshTodos();
  }, []);

  return (
    <div>
      <ListOfTodos todos={serialTodos}></ListOfTodos>
    </div>
  );
};

export default SerialTasks;
