import React, {useEffect, useState} from 'react';
import {useStorlessFetch} from '../../hooks/fetch';
import {useTranslation} from 'react-i18next';
import DataTable from '../../components/Table/Table';
import {ISupplierComplaint} from '../../types/SupplierOrders/ISupplierComplaint';
import {DataTableItemType} from '../../components/Table/interfaces/IHeaderAccessor';
import {Button, Grid} from '@material-ui/core';
import {Check, Remove} from '@material-ui/icons';
import moment from 'moment';
import {APP_ROUTES} from '../../routers/routes';
import {Link} from 'react-router-dom';
import {useDispatch} from 'react-redux';
import {modalActions} from '../../modules/modal/actions';
import {MODALS} from '../../components/Modal/ModalContents';
import _ from 'lodash';
import {appActions} from '../../modules/app/actions';
import ViewInvoiceButton from '../../components/UploadInvoiceComponent/ViewInvoiceButton';

export default function SupplierComplaintDetails(props: any) {
  const {
    match: {
      params: {id},
    },
  } = props;
  const [supplierComplaintDetailsRes, getSupplierComplaintDetails] = useStorlessFetch('supplier_complaint_get');
  const [patchSupplierComplaintRes, patchSupplierComplaint] = useStorlessFetch('supplier_complaint_patch');
  const [createCreditNoteRes, createCreditNote] = useStorlessFetch('create_credit_note');
  const [supplierComplaint, setSupplierComplaint] = useState<ISupplierComplaint>(null as any);
  const {t} = useTranslation();
  const dispatch = useDispatch();

  const handleCancelModal = () => dispatch(modalActions.closeModal());

  const handleSubmitModal = async (values: any) => {
    const updatedOrderData = _.omitBy(values, (value, key) => (supplierComplaint as any)[key] === value);
    const data = {...updatedOrderData, id};

    patchSupplierComplaint(data);
    setSupplierComplaint({...supplierComplaint, ...data});
    handleCancelModal();
  };

  const handleClick = async () => {
    dispatch(
      modalActions.addModal(MODALS.EDIT_SUPPLIER_COMPLAINT, {
        title: t('supplierComplaints.editSupplierComplaint') || '',
        onSubmit: handleSubmitModal,
        onCancel: handleCancelModal,
        orderObject: supplierComplaint,
      }),
    );
  };

  const addCreditNote = () => {
    if (confirm(t('general.areYouSure'))) {
      createCreditNote({supplier: supplierComplaint.supplier, supplierComplaint: supplierComplaint._id});
    }
  };

  useEffect(() => {
    getSupplierComplaintDetails({id});
  }, [id]);

  useEffect(() => {
    if (supplierComplaintDetailsRes.data) {
      setSupplierComplaint(supplierComplaintDetailsRes.data);
    }
  }, [supplierComplaintDetailsRes]);

  useEffect(() => {
    if (patchSupplierComplaintRes?.data) {
      dispatch(appActions.showSnackBar({text: t('general.submitFormText'), options: {severity: 'success'}}));
    } else if (patchSupplierComplaintRes?.error) {
      dispatch(appActions.showSnackBar({text: patchSupplierComplaintRes.error.name, options: {severity: 'error'}}));
    }

    return () => {
      patchSupplierComplaintRes.data = null;
      patchSupplierComplaintRes.error = null;
    };
  }, [patchSupplierComplaintRes]);

  useEffect(() => {
    if (createCreditNoteRes?.data) {
      dispatch(appActions.showSnackBar({text: t('general.submitFormText'), options: {severity: 'success'}}));
      getSupplierComplaintDetails({id});
    } else if (createCreditNoteRes?.error) {
      dispatch(appActions.showSnackBar({text: createCreditNoteRes.error.name, options: {severity: 'error'}}));
    }

    return () => {
      createCreditNoteRes.data = null;
      createCreditNoteRes.error = null;
    };
  }, [createCreditNoteRes]);

  return (
    supplierComplaint && (
      <div className="order-details supplier__order__details">
        <div className="center-content">
          <div className="customer-details__header">
            <p>
              {t('supplierComplaints.screenTitle')} - {supplierComplaint.number}
            </p>
            <div className="order-details__header__buttons">
              <ViewInvoiceButton
                path="supplierComplaintInvoices"
                fileName={supplierComplaint?.invoice?.fileName || ''}
              />
              {!supplierComplaint.supplierOrder && (
                <Button variant="contained" className="blue-button" onClick={addCreditNote}>
                  {t('supplierComplaints.addCreditNote')}
                </Button>
              )}
              <Button variant="contained" className="blue-button" onClick={handleClick}>
                {t('general.edit')}
              </Button>
            </div>
          </div>

          <div className="order-info">
            <div className="order-info-title">{t('supplierComplaints.supplierComplaintsInfo')}</div>
            <Grid container spacing={0} alignItems="center">
              <Grid item xs={12} sm={6} className="order-info-item">
                <div className="order-info-label">{t('supplierComplaints.supplierName')} </div>
                <div className="order-info-text">
                  <Link className="link" to={`${APP_ROUTES.SUPPLIER}/${supplierComplaint.supplier}`}>
                    <div className="order-side-customer-name">{supplierComplaint?.supplierName || '-'}</div>
                  </Link>
                </div>
              </Grid>
              <Grid item xs={12} sm={6} className="order-info-item">
                <span className="order-info-label">{t('supplierComplaints.number')}</span>
                <span className="order-info-text">{supplierComplaint?.number}</span>
              </Grid>

              <Grid item xs={12} sm={6} className="order-info-item">
                <div className="order-info-label">{t('supplierComplaints.shippingProvider')} </div>
                <div className="order-info-text">
                  <div className="order-side-customer-name">
                    {supplierComplaint?.shippingInfo?.shippingProvider || '-'}
                  </div>
                </div>
              </Grid>

              <Grid item xs={12} sm={6} className="order-info-item">
                <div className="order-info-label">{t('supplierComplaints.trackingNumber')} </div>
                <div className="order-info-text">
                  <div className="order-side-customer-name">
                    {supplierComplaint?.shippingInfo?.trackingNumber || '-'}
                  </div>
                </div>
              </Grid>

              <Grid item xs={12} sm={6} className="order-info-item">
                <span className="order-info-label">{t('supplierComplaints.invoiceUploaded')}</span>
                <span className="order-info-text">
                  {supplierComplaint.invoice?.fileName ? <Check /> : <Remove height={10} />}
                </span>
              </Grid>

              <Grid item xs={12} sm={6} className="order-info-item">
                <span className="order-info-label">{t('supplierComplaints.supplierOrder')}</span>
                <span className="order-info-text">
                  {supplierComplaint.supplierOrder ? (
                    <Link to={`${APP_ROUTES.SUPPLIER_ORDERS}/${supplierComplaint.supplierOrder._id}`} className="link">
                      {supplierComplaint.supplierOrder.orderNumberText}
                    </Link>
                  ) : (
                    '-'
                  )}
                </span>
              </Grid>

              <Grid item xs={12} sm={6} className="order-info-item">
                <span className="order-info-label">{t('supplierComplaints.invoiceNumber')}</span>
                <span className="order-info-text">{supplierComplaint.invoice?.invoiceNumber || '-'}</span>
              </Grid>

              <Grid item xs={12} sm={6} className="order-info-item">
                <span className="order-info-label">{t('supplierComplaints.createdAt')}</span>
                <span className="order-info-text">
                  {moment(supplierComplaint?.createdAt).format('DD. MMM YYYY HH:mm:ss')}
                </span>
              </Grid>
              <Grid item xs={12} sm={6} className="order-info-item">
                <span className="order-info-label">{t('supplierComplaints.invoiceUploadedAt')}</span>
                <span className="order-info-text">
                  {supplierComplaint.invoice?.uploadedAt
                    ? moment(supplierComplaint.invoice?.uploadedAt).format('DD. MMM YYYY')
                    : '-'}
                </span>
              </Grid>
              <Grid item xs={12} sm={6} className="order-info-item">
                <span className="order-info-label">{t('supplierComplaints.invoiceDate')}</span>
                <span className="order-info-text">
                  {supplierComplaint.invoice?.date
                    ? moment(supplierComplaint.invoice?.date).format('DD. MMM YYYY')
                    : '-'}
                </span>
              </Grid>
              <Grid item xs={12} sm={6} className="order-info-item">
                <span className="order-info-label">{t('supplierComplaints.invoiceDueDate')}</span>
                <span className="order-info-text">
                  {supplierComplaint.invoice?.dueDate
                    ? moment(supplierComplaint.invoice?.dueDate).format('DD. MMM YYYY')
                    : '-'}
                </span>
              </Grid>

              <Grid item xs={12} sm={6} className="order-info-item">
                <div className="order-info-label">{t('supplierComplaints.note')} </div>
                <div className="order-info-text">
                  <div className="order-side-customer-name">{supplierComplaint?.note || '-'}</div>
                </div>
              </Grid>
            </Grid>
          </div>
        </div>

        <DataTable
          csvFilename={supplierComplaint.supplierName}
          className="orders__table"
          enableExportToCsv={true}
          headers={[
            {
              kind: 'accessor',
              name: t('orders.orderNumber'),
              accessor: 'orderNumber',
              sortable: true,
            },
            {
              kind: 'accessor',
              name: t('newArticles.externalArticleId'),
              accessor: 'externalArticleId',
              sortable: true,
            },
            {
              kind: 'accessor',
              name: t('products.title'),
              accessor: 'productTitle',
              sortable: true,
            },
            {
              kind: 'accessor',
              name: t('customers.customerNumber'),
              accessor: 'customerNumber',
              sortable: true,
            },
            {
              kind: 'accessor',
              name: t('customerDetails.customerName'),
              accessor: 'customerName',
              sortable: true,
            },
            {
              kind: 'accessor',
              name: t('customerReturns.quantity'),
              accessor: 'quantity',
              sortable: true,
              type: 'number',
            },
            {
              kind: 'accessor',
              name: t('customerReturns.createdAt'),
              accessor: 'createdAt',
              sortable: true,
              type: DataTableItemType.DATE,
            },
          ]}
          endpoint={'customer_returns'}
          options={{supplierComplaint: id}}
          defaultSort={{key: 'createdAt', value: -1}}
          title={t('customerReturns.screenTitle')}
        />
      </div>
    )
  );
}
