export enum SHOPWARE_PAYMENT_TYPE {
  PAYPAL = 'PayPal',
  SEPA = 'SEPA Firmenlastschrift',
  Rechnung = 'Rechnung',
  CREDIT_CARD = 'Kreditkarte',
  PAYMENT_IN_ADVANCE = 'Vorkasse',
  INSTANT_BANK_TRANSFER = 'SOFORT Überweisung',
  GIROPAY = 'Giropay',
  IDEAL = 'iDEAL',
  APPLE_PAY = 'Apple Pay',
  KLARNA = 'Klarna Rechnungskauf',
  KLARNA_PAY_NOW = 'Klarna pay now',
  KLARNA_INSTALLMENT_PUCRHASE = 'Klarna Ratenkauf',
  AMAZON_PAY = 'Amazon Pay',
  EBAY_PAYMENTS = 'ebay Payments',
  KAUFLAND_PAYMENTS = 'Kaufland Payments',
  ZINIA_RECHNUNG = 'Kauf auf Rechnung via Zinia',
  ZINIA_RECHNUNGSKAUF = 'Zinia Rechnungskauf',
}
