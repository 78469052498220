import {Typography} from '@material-ui/core';
import React from 'react';
import Form, {IField} from '../Form/Form';
import {useTranslation} from 'react-i18next';
import * as Yup from 'yup';

const CreateExchangeOrderModal = (props: ICreateExchangeOrderModalProps) => {
  const {title, onSubmit, onCancel} = props;
  const {t} = useTranslation();

  const generateInitialValues = () => {
    const defaults = {
      sendReminder: true,
      printReturnLabel: false,
    };

    return defaults;
  };

  const fields: IField[] = [
    {
      label: t('orderDetails.sendReminder'),
      placeholder: t('orderDetails.sendReminder'),
      fieldKey: 'sendReminder',
      type: 'checkbox',
      fieldKeyType: 'number',
    },
    {
      label: t('orderDetails.printReturnLabel'),
      placeholder: t('orderDetails.printReturnLabel'),
      fieldKey: 'printReturnLabel',
      type: 'checkbox',
      fieldKeyType: 'number',
    },
  ];
  const initialValues = generateInitialValues();

  const validationSchema = Yup.object().shape({
    sendReminder: Yup.string().required('Required'),
    printReturnLabel: Yup.string().required('Required'),
  });

  return (
    <>
      <Typography className="modal-title">{title}</Typography>
      <Form
        initialValues={initialValues}
        fields={fields}
        submitButtonText={t('general.submit')}
        cancelButtonText={t('general.cancel')}
        onSubmit={onSubmit}
        onCancel={onCancel}
        validationSchema={validationSchema}
        isLoading={false}
      ></Form>
    </>
  );
};

export interface ICreateExchangeOrderModalProps {
  title: string;
  onSubmit: (values: any) => void;
  onCancel: () => void;
}

export default CreateExchangeOrderModal;
