import React, {ReactElement, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {TableContainer, TableHead, TableRow, TableCell, TableBody, Table, Button} from '@material-ui/core';
import {IOrder} from '../../types/Orders/IOrder';
import {useStorlessFetch} from '../../hooks/fetch';

const CreditNotesTable = ({order}: IProps): ReactElement => {
  const [creditNotePdfRes, getCreditNotePdf] = useStorlessFetch('orders_easybill_invoice');
  const {t} = useTranslation();

  useEffect(() => {
    if (creditNotePdfRes.data) {
      const blob = new Blob([new Uint8Array(creditNotePdfRes.data.data).buffer], {type: 'application/pdf'});
      const blobURL = URL.createObjectURL(blob);
      window.open(blobURL);
    }

    return () => {
      creditNotePdfRes.data = null;
    };
  }, [creditNotePdfRes.data]);

  return (
    <div
      className="data-table-wrapper"
      style={{height: 'auto', maxHeight: '400px', marginBottom: '20px', overflow: 'auto'}}
    >
      <TableContainer className="table-scroll">
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{t('orderDetails.creditNoteId')}</TableCell>
              <TableCell>{t('orderDetails.creditNoteNumber')}</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {order.easyBillInfo?.creditNotes?.map((item: any, index: number) => (
              <TableRow key={item}>
                <TableCell>{item}</TableCell>
                <TableCell>{`${order.easyBillInfo?.invoiceNumber}-${index + 1}`}</TableCell>
                <TableCell>
                  <Button
                    variant="contained"
                    className="blue-button"
                    onClick={() => {
                      getCreditNotePdf({id: item});
                    }}
                  >
                    {t('general.view')}
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

interface IProps {
  order: IOrder;
}

export default CreditNotesTable;
