import React, {createContext} from 'react';
import {IScannedOrderItem} from '../../../types/OrderItems/IScannedOrderItem';
import {IOrder} from '../../../types/Orders/IOrder';

const OrderScanningContext = createContext({} as IOrderScanningContext);
export const OrderScanningProvider = OrderScanningContext.Provider;
export default OrderScanningContext;

interface IOrderScanningContext {
  orderItems: IScannedOrderItem[];
  orderMarkedAsPickup: boolean;
  product: IScannedOrderItem;
  printDeliveryBill: boolean;
  validationFailed: boolean;
  trackingNumberPushed: any;
  multiBoxInput: boolean;
  order: IOrder;
  isParcel: boolean;
  allOrderItemsScanned: boolean;
  orderItemsScanned: boolean;
  setOrderItems: (data: IScannedOrderItem[]) => void;
  setOrderMarkedAsPickup: (data: boolean) => void;
  setPrintDeliveryBill: (data: boolean) => void;
  setProduct: (data: IScannedOrderItem) => void;
  setMultiBoxInput: (data: boolean) => void;
  pushTrackingNumber: (data: any) => void;
  setBarcode: (data: string) => void;
  resetState: () => void;
  doHandleOrderItems: (data: any) => void;
  setIsParcel: (data: boolean) => void;
  setShowTrackingNumberInput: (data: boolean) => void;
  getProforma: (data: any) => void;
  setOrder: (data: any) => void;
  setAllOrderItemsScanned: (data: any) => void;
}
