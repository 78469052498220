import {
  Box,
  CircularProgress,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  TextField,
  Typography,
} from '@material-ui/core';
import {
  KeyboardArrowLeft,
  KeyboardArrowRight,
  KeyboardArrowUp,
  KeyboardArrowDown,
  ExpandLess,
  ExpandMore,
} from '@material-ui/icons';
import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useFetch} from '../../hooks/fetch';
import LastPageIcon from '@material-ui/icons/LastPage';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import {IAmazonSubmission} from '../../types/AmazonSubmission/IAmazonSubmission';

const AmazonSubmissionsInfo = () => {
  const [page, setPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [amazonSubmissions, getAmazonSubmissions] = useFetch<any>('amazon_submissions_list');
  const [orderItemsData, setOrderItemsData] = useState<IAmazonSubmission[]>([]);
  const [expandedRow, setExpandedRow] = useState<any>();
  const {t} = useTranslation();
  const [options, setOptions] = useState({});
  const [sort, setSort] = useState({key: 'updatedAt', value: -1});
  const [filter, setFilter] = useState({} as any);

  useEffect(() => {
    fetchAmazonSubmissionItems({
      page: page,
      itemsPerPage: itemsPerPage,
      sort: {[sort.key]: sort.value},
      conditions: {...options, ...filter},
    });

    return () => {
      amazonSubmissions.data = null;
      amazonSubmissions.error = null;
      amazonSubmissions.loaded = null;
    };
  }, [options, sort, filter]);

  useEffect(() => {
    if (amazonSubmissions.data) {
      setOrderItemsData(amazonSubmissions.data.data);
    }
  }, [amazonSubmissions]);

  const handleEventChangePage = async (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    await handlePagination(newPage);
  };

  const handleEventChangeRowsPerPage = async (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    await handlePagination(1, +event.target.value);
  };

  const generatePaginationComponent = () => {
    const handleFirstPageButtonClick = async () => await handlePagination(1);
    const handleBackButtonClick = async () => await handlePagination(page - 1);
    const handleNextButtonClick = async () => await handlePagination(page + 1);
    const handleLastPageButtonClick = async () =>
      await handlePagination(Math.ceil(amazonSubmissions?.data?.total / itemsPerPage));

    return (
      <div className="pagination">
        <IconButton onClick={handleFirstPageButtonClick} disabled={page === 1} aria-label="first page">
          <FirstPageIcon />
        </IconButton>
        <IconButton onClick={handleBackButtonClick} disabled={page === 1} aria-label="previous page">
          <KeyboardArrowLeft />
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(amazonSubmissions?.data?.total / itemsPerPage)}
          aria-label="next page"
        >
          <KeyboardArrowRight />
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(amazonSubmissions?.data?.total / itemsPerPage)}
          aria-label="last page"
        >
          <LastPageIcon />
        </IconButton>
      </div>
    );
  };

  const handlePagination = async (newPage: number, newItemsPerPage?: number) => {
    setPage(newPage);
    newItemsPerPage && setItemsPerPage(newItemsPerPage);
    const sortOptions = sort.key ? {[sort.key]: sort.value} : undefined;

    fetchAmazonSubmissionItems({
      page: newPage,
      itemsPerPage: newItemsPerPage || itemsPerPage,
      sort: sortOptions,
      conditions: {...options},
    });
  };

  const fetchAmazonSubmissionItems = (params: any) => {
    getAmazonSubmissions(params);
  };

  const Row = (amazonSubmission: IAmazonSubmission) => {
    const [open, setOpen] = useState(false);

    useEffect(() => {
      if (open === false && expandedRow === amazonSubmission._id) {
        setOpen(true);
      }
    }, []);

    const handleExpandRow = () => {
      if (!open) {
        setExpandedRow(amazonSubmission._id);
      } else {
        setExpandedRow('');
      }
    };

    const formatDate = (date: any) => {
      const dateOptions: Intl.DateTimeFormatOptions = {
        timeZone: 'Europe/Berlin',
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
        hour12: false,
      };
      const dateFormatter = new Intl.DateTimeFormat('de-DE', dateOptions);
      const dateAsFormattedString = date ? dateFormatter.format(new Date(date)) : '';
      return dateAsFormattedString;
    };

    const resultMessagesTable = (
      <TableRow style={{borderTop: 'hidden'}}>
        <TableCell style={{paddingBottom: 0, paddingTop: 0}} colSpan={7}>
          <Collapse in={open} timeout="auto" unmountOnExit style={{height: '50px'}}>
            <Box style={{paddingBottom: '10px'}}>
              <Typography variant="h6" gutterBottom component="div">
                {t('amazonSubmissions.resultMessagesTable')}
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell style={{zIndex: 3}}>{t('amazonSubmissions.messageId')}</TableCell>
                    <TableCell style={{zIndex: 3}}>{t('amazonSubmissions.amazonOrderId')}</TableCell>
                    <TableCell style={{zIndex: 3}}>{t('amazonSubmissions.resultCode')}</TableCell>
                    <TableCell style={{zIndex: 3}}>{t('amazonSubmissions.resultDescription')}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody style={{height: '100px'}}>
                  {(amazonSubmission?.processingReportInfo?.resultMessages || []).map((resultMessage) => (
                    <TableRow key={resultMessage.messageId}>
                      <TableCell>{resultMessage.messageId}</TableCell>
                      <TableCell>{resultMessage.amazonOrderId}</TableCell>
                      <TableCell>{resultMessage.resultCode}</TableCell>
                      <TableCell>{resultMessage.resultDescription}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    );

    return (
      <React.Fragment>
        <TableRow>
          <TableCell>
            <IconButton aria-label="expand row" size="small" onClick={handleExpandRow}>
              {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
            </IconButton>
          </TableCell>
          <TableCell>{amazonSubmission.amazonId}</TableCell>
          <TableCell>{amazonSubmission.amazonStatus}</TableCell>
          <TableCell>{amazonSubmission.submissionStatus}</TableCell>
          {/* <TableCell>{amazonSubmission.completed ? t('general.yes') : t('general.no')}</TableCell> */}
          <TableCell>{formatDate(amazonSubmission.createdAt)}</TableCell>
          <TableCell>{formatDate(amazonSubmission.updatedAt)}</TableCell>
        </TableRow>
        {resultMessagesTable}
      </React.Fragment>
    );
  };

  const tableFooter = (
    <TableFooter>
      <TableRow>
        <TablePagination
          rowsPerPageOptions={[10, 25, 50, 100]}
          colSpan={10}
          component="td"
          onPageChange={handleEventChangePage}
          count={amazonSubmissions?.data?.total || 0}
          rowsPerPage={itemsPerPage}
          page={page - 1}
          SelectProps={{
            inputProps: {'aria-label': 'rows per page'},
            native: true,
          }}
          onChangePage={handleEventChangePage}
          onRowsPerPageChange={handleEventChangeRowsPerPage}
          ActionsComponent={generatePaginationComponent}
          labelRowsPerPage={t('general.rowsPerPage')}
        />
      </TableRow>
    </TableFooter>
  );

  const handleSortChange = async (key: string) => {
    if (sort.key !== key) {
      setSort({key, value: 1});
    } else {
      setSort({key, value: sort.value === 1 ? -1 : 1});
    }
  };

  const isColumnSorted = (key: string) => {
    return sort.key === key ? sort.value : null;
  };

  const onFilterChange = (e: any) => {
    if (e.target.value !== '') {
      const inputValue = e.target.type === 'number' ? +e.target.value : e.target.value;
      setFilter({...filter, [`${e.target.id}`]: inputValue});
    } else {
      const state: any = {...filter};
      delete state[e.target.id];
      setFilter(state);
    }
  };

  const headerCell = (headerKey: string, label: string, type: string) => (
    <>
      <TableSortLabel
        id={headerKey}
        hideSortIcon={true}
        className="sort-icons"
        onClick={() => {
          handleSortChange(headerKey);
        }}
      >
        {label}
        {isColumnSorted(headerKey) && (
          <>
            {sort.value === -1 ? (
              <ExpandLess fontSize="small" className={'active-sort less'} />
            ) : (
              <ExpandMore fontSize="small" className={'active-sort more'} />
            )}
          </>
        )}
      </TableSortLabel>
      <div>
        <TextField
          type={type === 'number' ? 'number' : 'text'}
          datatype={type}
          id={headerKey}
          onChange={onFilterChange}
          style={{visibility: !['date', 'boolean'].includes(type) ? 'visible' : 'hidden'}}
        />
      </div>
    </>
  );

  const tableHeader = (
    <TableHead>
      <TableRow>
        <TableCell></TableCell>
        <TableCell>{headerCell('amazonId', t('amazonSubmissions.amazonId'), 'text')}</TableCell>
        <TableCell>{headerCell('amazonStatus', t('amazonSubmissions.amazonStatus'), 'text')}</TableCell>
        <TableCell>{headerCell('submissionStatus', t('amazonSubmissions.submissionStatus'), 'text')}</TableCell>
        {/* <TableCell>{headerCell('completed', t('amazonSubmissions.completed'), 'boolean')}</TableCell> */}
        <TableCell>{headerCell('createdAt', t('amazonSubmissions.createdAt'), 'date')}</TableCell>
        <TableCell>{headerCell('updatedAt', t('amazonSubmissions.updatedAt'), 'date')}</TableCell>
      </TableRow>
    </TableHead>
  );

  return (
    <div className="orders orders__supplier">
      <h3>
        <b>{t('amazonSubmissions.screenTitle')}</b>
      </h3>
      <br />
      <TableContainer className="table-scroll" style={{height: 'calc(100% - 70px)'}}>
        <Table>
          {tableHeader}
          <TableBody>
            {orderItemsData.map((item) => {
              return <Row key={item._id} {...item} />;
            })}
            {((orderItemsData || []).length === 0 || amazonSubmissions?.loading) && (
              <TableRow>
                <TableCell colSpan={6} style={{textAlign: 'center'}}>
                  {amazonSubmissions?.loading ? <CircularProgress /> : t('general.noData')}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
          {tableFooter}
        </Table>
      </TableContainer>
    </div>
  );
};

export default AmazonSubmissionsInfo;
