class LocalStorage {
  writeValue(key: string, value: string) {
    window.localStorage.setItem(key, value);
  }

  getValue(key: string) {
    return window.localStorage.getItem(key);
  }
}

export const storage = new LocalStorage();
