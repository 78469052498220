import {TextField, RadioGroup, FormControlLabel, Radio, Button} from '@material-ui/core';
import React, {ChangeEvent, useRef, useState} from 'react';
import axios from 'axios';
import {appActions} from '../../modules/app/actions';
import {useDispatch} from 'react-redux';
import {useTranslation} from 'react-i18next';
import Spinner from '../../components/Spinner/Spinner';
import config from '../../lib/config';

type PaymentType = 'ebay' | 'amazon' | 'mollie' | 'kaufland';

export default function DatevPaymentLists() {
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [selectedPaymentType, setSelectedPaymentType] = useState<PaymentType>('ebay');
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const {t} = useTranslation();
  const dispatch = useDispatch();

  const onFilesUpload = async (files: File[], paymentType: PaymentType) => {
    const url = `${config.api}pricing/upload-datev-files`;
    const formData = new FormData();

    for (let i = 0; i < files.length; i++) {
      formData.append('files', files[i]);
    }

    formData.append('paymentType', paymentType);

    setIsLoading(true);

    axios
      .post(url, formData, {
        headers: {'Content-Type': 'multipart/form-data'},
      })
      .then(() => {
        dispatch(appActions.showSnackBar({text: t('general.datevUploadSuccess'), options: {severity: 'success'}}));
        setSelectedFiles([]);
        setIsLoading(false);

        if (fileInputRef.current) {
          fileInputRef.current.value = '';
        }
      })
      .catch((error) => {
        setSelectedFiles([]);
        setIsLoading(false);

        if (fileInputRef.current) {
          fileInputRef.current.value = '';
        }

        dispatch(
          appActions.showSnackBar({
            text: `${t('general.uploadFail')}: ${error?.message}`,
            options: {severity: 'error'},
          }),
        );
      });
  };

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files) {
      const fileList = Array.from(files);
      setSelectedFiles(fileList);
    }
  };

  const handlePaymentTypeChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSelectedPaymentType(event.target.value as PaymentType);
  };

  const handleUpload = () => {
    onFilesUpload(selectedFiles, selectedPaymentType);
    setSelectedFiles([]);
  };

  return (
    <div className="customers">
      <div className="center-content">
        <div className="customer-details__header">
          <p>{t('general.datevLists')}</p>
        </div>
      </div>
      <div>
        <TextField
          inputProps={{multiple: true}}
          onChange={handleFileChange}
          inputRef={fileInputRef}
          variant="outlined"
          type="file"
        />
      </div>
      <div>
        <RadioGroup
          row
          aria-label="payment-type"
          name="payment-type"
          value={selectedPaymentType}
          onChange={handlePaymentTypeChange}
        >
          <FormControlLabel value="ebay" control={<Radio />} label="eBay payments" />
          <FormControlLabel value="amazon" control={<Radio />} label="Amazon payments" />
          <FormControlLabel value="mollie" control={<Radio />} label="Mollie payments" />
          <FormControlLabel value="kaufland" control={<Radio />} label="Kaufland payments" />
        </RadioGroup>
      </div>

      <Button onClick={handleUpload} variant="contained" className={isLoading ? 'inactive-button' : 'blue-button'}>
        Upload
      </Button>

      {isLoading && <Spinner />}
    </div>
  );
}
