import _ from 'lodash';

export class AuthHelper {
  public static checkRouteExist(pathName: string, router: any): boolean {
    let pathNameExist = false;
    for (const route in router) {
      if (pathName === _.get(router, route) || pathName.startsWith(_.get(router, route).split('/:')[0])) {
        pathNameExist = true;
      }
    }
    return pathNameExist;
  }
}
