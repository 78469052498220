import {Button} from '@material-ui/core';
import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {MODALS} from '../../components/Modal/ModalContents';
import DataTable from '../../components/Table/Table';
import withRoleGuard from '../../guards/Role-Guard';
import {useFetch} from '../../hooks/fetch';
import {useSelection} from '../../hooks/selection';
import {appActions} from '../../modules/app/actions';
import {modalActions} from '../../modules/modal/actions';
import {ProductStatus} from '../../types/Product/ProductStatus.enum';
import {USER_ROLE} from '../../types/User/UserRole';

const ExpiredProduct = () => {
  const selectionKey = 'EXPIRED_PRODUCTS';
  const [updateProductsResponse, doUpdateProducts] = useFetch('update_product_status');
  const [selectedValues, setSelectedValues] = useSelection(selectionKey);
  const {t} = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    if (updateProductsResponse.error) {
      dispatch(appActions.showSnackBar({text: t('activatedArticles.failedSyncMessage'), options: {severity: 'error'}}));
    }

    if (updateProductsResponse.loaded && !updateProductsResponse.error) {
      dispatch(
        appActions.showSnackBar({text: t('activatedArticles.successSyncMessage'), options: {severity: 'success'}}),
      );
    }

    return () => {
      updateProductsResponse.data = null;
      updateProductsResponse.error = null;
      updateProductsResponse.loaded = null;
    };
  }, [updateProductsResponse]);

  const onDeactivateProducts = async (productStatus: ProductStatus, selectedShops: string[]) => {
    await doUpdateProducts({status: productStatus, product: selectedValues, shopIds: selectedShops});
    dispatch(modalActions.closeModal());
  };

  const handleCancelModal = () => dispatch(modalActions.closeModal());

  const onChangeProductsHandler = (status: ProductStatus) => {
    const description =
      status === ProductStatus.DEACTIVATED
        ? t('products.confirmationMessage')
        : t('products.confirmationEndOfLifeMessage');
    dispatch(
      modalActions.addModal(MODALS.ALERT_DIALOG, {
        title: t('products.confirmTitle') || '',
        description: description || '',
        onSubmit: (selectedShops) => onDeactivateProducts(status, selectedShops),
        onClose: handleCancelModal,
        showShopSelection: true,
        onOpen: true,
      }),
    );
  };

  const DeactivateButton = () => (
    <Button
      variant="contained"
      className="blue-button"
      onClick={() => {
        onChangeProductsHandler(ProductStatus.DEACTIVATED);
      }}
    >
      {t('expiredProducts.btnDeactivate')}
    </Button>
  );

  const endOfLifeButton = () => (
    <Button
      variant="contained"
      className="blue-button"
      onClick={() => {
        onChangeProductsHandler(ProductStatus.END_OF_LIFE);
      }}
    >
      {t('deactivatedProducts.btnEndOfLife')}
    </Button>
  );

  const DeactivateButtonWithGuard = withRoleGuard([USER_ROLE.ADMIN, USER_ROLE.STANDARD], DeactivateButton, false);
  const EndOfLifeButtonWithGuard = withRoleGuard([USER_ROLE.ADMIN, USER_ROLE.STANDARD], endOfLifeButton, false);

  return (
    <div className="expired-products">
      <DataTable
        className="expired-products__table"
        headers={[
          {
            kind: 'accessor',
            name: t('expiredProducts.productId'),
            accessor: 'productId',
            sortable: true,
            type: 'number',
          },
          {
            kind: 'accessor',
            name: t('expiredProducts.title'),
            accessor: 'title',
            sortable: true,
          },
          {
            kind: 'accessor',
            name: t('expiredProducts.manufacturer'),
            accessor: 'productInformation.manufacturer',
            sortable: true,
          },
          {
            kind: 'accessor',
            name: t('expiredProducts.colour'),
            accessor: 'productInformation.colour',
            sortable: true,
          },
        ]}
        endpoint={'products_list'}
        options={{'shopProps.status': ProductStatus.EXPIRED}}
        target={'products'}
        canShowCheckbox={true}
        selectionKey={selectionKey}
        refreshData={updateProductsResponse.loaded}
        defaultSort={{key: 'productId', value: 1}}
        title={t('expiredProducts.screenTitle')}
        actionButtons={
          <>
            <DeactivateButtonWithGuard />
            <EndOfLifeButtonWithGuard />
          </>
        }
      />
    </div>
  );
};

export default ExpiredProduct;
