import React, {useContext, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import DataTable from '../Table/Table';
import {IconButton} from '@material-ui/core';
import {MoveToInbox} from '@material-ui/icons';
import TodoContext from '../../screens/Todo/Context/TodoContext';

export interface ICompletedTodosActionProps {
  setRefresh: React.Dispatch<React.SetStateAction<boolean>>;
  row: any;
}

export const CompletedTodosActions: React.FC<ICompletedTodosActionProps> = (props: ICompletedTodosActionProps) => {
  const {t} = useTranslation();
  const {uncompleteTodoTask} = useContext(TodoContext);
  const {row, setRefresh} = props;

  const moveToNotCompleted = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    event.preventDefault();
    uncompleteTodoTask({id: row._id});
    setRefresh(true);
  };

  return (
    <div>
      <IconButton onClick={moveToNotCompleted} title={t('todos.uncomplete')}>
        <MoveToInbox fontSize="small" style={{fill: 'gray'}}></MoveToInbox>
      </IconButton>
    </div>
  );
};

const CompletedTasks = () => {
  const {t} = useTranslation();
  const [refresh, setRefresh] = useState(false);

  return (
    <div>
      <DataTable
        headers={[
          {
            kind: 'accessor',
            name: t('todos.completed'),
            accessor: 'name',
            sortable: false,
          },
          {
            kind: 'component',
            name: t('general.actions'),
            component: CompletedTodosActions,
            props: {setRefresh},
            onFetchProps: {},
          },
        ]}
        endpoint={'get_user_completed_tasks_list'}
        title={''}
        refreshData={refresh}
      />
    </div>
  );
};

export default CompletedTasks;
