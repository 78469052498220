import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import DataTable from '../../components/Table/Table';
import SupplierOrderFromBacklogButton from '../../components/SupplierOrder/SupplierOrderBacklog/SupplierOrderFromBacklogButton';
import {useFetch} from '../../hooks/fetch';
import {useDispatch} from 'react-redux';
import {appActions} from '../../modules/app/actions';
import {DataTableItemType} from '../../components/Table/interfaces/IHeaderAccessor';

const SupplierOrderItemsBacklog = () => {
  const [moveToSupplierOrderResponse, moveToSupplierOrder] = useFetch<any>('supplier_order_items_move_to_order');
  const [deleteItemResponse, deleteItem] = useFetch<any>('supplier_order_items_delete');
  const [createSupplierOrderResponse, createSupplierOrder] = useFetch<any>('create_order_from_backlog');
  const [updateSupplierOrderResponse, updateSupplierOrder] = useFetch<any>('supplier_order_items_put');
  const [refreshData, setRefreshData] = useState(false);
  const dispatch = useDispatch();
  const {t} = useTranslation();

  useEffect(() => {
    if (moveToSupplierOrderResponse?.data) {
      dispatch(appActions.showSnackBar({text: t('general.success'), options: {severity: 'success'}}));
      setRefreshData((prevState: boolean) => !prevState);
    }

    if (moveToSupplierOrderResponse?.error) {
      dispatch(appActions.showSnackBar({text: moveToSupplierOrderResponse.error?.name, options: {severity: 'error'}}));
    }

    return () => {
      moveToSupplierOrderResponse.data = null;
      moveToSupplierOrderResponse.error = null;
      moveToSupplierOrderResponse.loaded = null;
      moveToSupplierOrderResponse.loading = null;
    };
  }, [moveToSupplierOrderResponse]);

  useEffect(() => {
    if (deleteItemResponse?.data) {
      dispatch(appActions.showSnackBar({text: t('general.success'), options: {severity: 'success'}}));
      setRefreshData((prevState: boolean) => !prevState);
    }

    if (deleteItemResponse?.error) {
      dispatch(appActions.showSnackBar({text: deleteItemResponse.error?.name, options: {severity: 'error'}}));
    }

    return () => {
      deleteItemResponse.data = null;
      deleteItemResponse.error = null;
      deleteItemResponse.loaded = null;
      deleteItemResponse.loading = null;
    };
  }, [deleteItemResponse]);

  useEffect(() => {
    if (createSupplierOrderResponse?.data) {
      dispatch(appActions.showSnackBar({text: t('general.success'), options: {severity: 'success'}}));
      setRefreshData((prevState: boolean) => !prevState);
    }

    if (createSupplierOrderResponse?.error) {
      dispatch(appActions.showSnackBar({text: createSupplierOrderResponse.error?.name, options: {severity: 'error'}}));
    }

    return () => {
      createSupplierOrderResponse.data = null;
      createSupplierOrderResponse.error = null;
      createSupplierOrderResponse.loaded = null;
      createSupplierOrderResponse.loading = null;
    };
  }, [createSupplierOrderResponse]);

  useEffect(() => {
    if (updateSupplierOrderResponse?.data) {
      dispatch(appActions.showSnackBar({text: t('general.success'), options: {severity: 'success'}}));
      setRefreshData((prevState: boolean) => !prevState);
    }

    if (updateSupplierOrderResponse?.error) {
      dispatch(appActions.showSnackBar({text: updateSupplierOrderResponse.error?.name, options: {severity: 'error'}}));
    }

    return () => {
      updateSupplierOrderResponse.data = null;
      updateSupplierOrderResponse.error = null;
      updateSupplierOrderResponse.loaded = null;
      updateSupplierOrderResponse.loading = null;
    };
  }, [updateSupplierOrderResponse]);

  return (
    <div className="supplier-articles">
      <DataTable
        className="new-supplier__table"
        headers={[
          {
            kind: 'accessor',
            name: t('supplierOrderItems.product'),
            accessor: 'productTitle',
            sortable: true,
          },
          {
            kind: 'accessor',
            name: t('supplierOrderItems.lastOrderNumber'),
            accessor: 'supplierOrderNumber',
            sortable: true,
            type: DataTableItemType.NUMBER,
          },
          {
            kind: 'accessor',
            name: t('supplierOrderItems.lastSupplierName'),
            accessor: 'supplierName',
            sortable: true,
          },
          {
            kind: 'accessor',
            name: t('supplierOrderItems.lastExternalArticleId'),
            accessor: 'externalArticleId',
            sortable: true,
          },
          {
            kind: 'accessor',
            name: t('supplierOrderItems.quantity'),
            accessor: 'quantity',
            sortable: true,
            type: 'number',
          },
          {
            kind: 'accessor',
            name: t('supplierOrderItems.price'),
            accessor: 'price',
            sortable: true,
            format: 'price',
            type: 'number',
          },
          {
            kind: 'component',
            name: t('supplierOrderDetails.moveToSupplierOrder'),
            component: SupplierOrderFromBacklogButton,
            props: {moveToSupplierOrder, deleteItem, createSupplierOrder, updateSupplierOrder},
            onFetchProps: {},
          },
        ]}
        endpoint={'supplier_order_items_backlog'}
        numberOfItemsPerPage={50}
        target={`products`}
        selectRowClickId={'product._id'}
        title={t('general.supplierOrderItemsBacklog')}
        refreshData={refreshData}
      />
    </div>
  );
};

export default SupplierOrderItemsBacklog;
