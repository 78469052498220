import React from 'react';
import Form from './Form/Form';
import {useTranslation} from 'react-i18next';

const SendEmailComponent = ({title, createOrder, email, onSubmit, onCancel}: ISendEmailComponent) => {
  const {t} = useTranslation();

  const generateInitialValues = (fields: any[]) => {
    let defaults: any = {};

    fields.forEach((field) => {
      defaults = {...defaults, [field.fieldKey]: '', email: email};
    });
    if (createOrder) {
      defaults = {...defaults, sendEmail: true};
    }
    return defaults;
  };

  const getFields = () => {
    const fields: any[] = [
      {
        label: t('supplierOrderDetails.email'),
        placeholder: t('supplierOrderDetails.email'),
        fieldKey: 'email',
        fieldKeyType: 'email',
        type: 'input',
        required: false,
      },
      {
        label: t('supplierOrderDetails.customText'),
        placeholder: t('supplierOrderDetails.customText'),
        fieldKey: 'customText',
        type: 'multiline',
        required: false,
      },
    ];

    if (createOrder) {
      fields.push({
        label: t('supplierOrderDetails.sendEmail'),
        placeholder: t('supplierOrderDetails.sendEmail'),
        fieldKey: 'sendEmail',
        type: 'checkbox',
        fieldKeyType: 'number',
      });
    }

    return fields;
  };
  const fields = getFields();
  const initialValues = generateInitialValues(fields);

  return (
    <>
      <Form
        initialValues={initialValues}
        fields={fields}
        submitButtonText={t('general.submit')}
        cancelButtonText={t('general.cancel')}
        onSubmit={onSubmit}
        onCancel={onCancel}
        title={title}
      ></Form>
    </>
  );
};

export interface ISendEmailComponent {
  title: string;
  createOrder?: boolean;
  email?: string;
  onSubmit: (values: any) => void;
  onCancel: () => void;
}

export default SendEmailComponent;
