export enum OrderActionType {
  ORDER_ITEMS_SCANNED = 'ORDER_ITEMS_SCANNED',
  SHIPPING_LABEL_SCANNED = 'SHIPPING_LABEL_SCANNED',
  CUSTOMER_RETURN_CREATED = 'CUSTOMER_RETURN_CREATED',
  ORDER_MANUALLY_COMPLETED = 'ORDER_MANUALLY_COMPLETED',
  MARKED_AS_PICKUP_ORDER = 'MARKED_AS_PICKUP_ORDER',
  CREATED = 'CREATED',
  CUSTOMER_ORDER_CANCELLED = 'CUSTOMER_ORDER_CANCELLED',
  STATUS_CHANGED_MANUALLY = 'STATUS_CHANGED_MANUALLY',
  ORDER_STATUS_UPDATED = 'ORDER_STATUS_UPDATED',
}
