import {DataGrid} from '@material-ui/data-grid';
import React, {ReactElement} from 'react';
import {useTranslation} from 'react-i18next';
import {IOrderAddress} from '../../../types/Orders/IOrderAddress';

const ShippingAddressModal = (props: IShippingAddressModal): ReactElement => {
  const {t} = useTranslation();

  const columns: any[] = [
    {field: 'customerName', headerName: t('shippingAddress.customerName'), flex: 1},
    {field: 'addressLine2', headerName: t('shippingAddress.addressLine2'), flex: 1},
    {field: 'deliveryInstruction', headerName: t('shippingAddress.deliveryInstruction'), flex: 1},
    {field: 'addressLine1', headerName: t('shippingAddress.addressLine1'), flex: 1},
    {field: 'city', headerName: t('shippingAddress.city'), flex: 1},
    {field: 'zip', headerName: t('shippingAddress.zipCode'), flex: 1},
    {field: 'countryCode', headerName: t('shippingAddress.countryCode'), flex: 1},
  ];

  const rows = props.data.map((item) => {
    return {
      id: `${item._id.addressLine1}-${item._id.customerName}`,
      ...item.shippingAddress,
    };
  });

  return (
    <div style={{width: '100vw', height: '90vh', cursor: 'pointer'}}>
      <DataGrid rows={rows} columns={columns} checkboxSelection={false} onRowClick={props.onSubmit} />
    </div>
  );
};

export interface IShippingAddressModal {
  onSubmit: (values: any) => void;
  data: IPreviousShippingAddress[];
}

interface IPreviousShippingAddress {
  _id: any;
  shippingAddress: IOrderAddress;
}
export default ShippingAddressModal;
