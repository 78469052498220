import React from 'react';
import {useTranslation} from 'react-i18next';
import DataTable from '../../components/Table/Table';
import {DataTableItemType} from '../../components/Table/interfaces/IHeaderAccessor';

export default function EbayTrackingNumbers() {
  const {t} = useTranslation();

  return (
    <div className="orders">
      <DataTable
        headers={[
          {
            kind: 'accessor',
            name: t('orderDetails.orderNumber'),
            accessor: 'orderNumber',
            sortable: true,
          },
          {
            kind: 'accessor',
            name: t('orderDetails.trackingNumber'),
            accessor: 'trackingNumber',
            sortable: true,
          },
          {
            kind: 'accessor',
            name: t('orderDetails.isTrackingNumberPushed'),
            accessor: 'isTrackingNumberPushed',
            sortable: true,
            type: DataTableItemType.BOOLEAN,
          },
          {
            kind: 'accessor',
            name: t('orderDetails.trackingNumberPushedBeforeScanning'),
            accessor: 'trackingNumberPushedBeforeScanning',
            sortable: true,
            type: DataTableItemType.BOOLEAN,
          },
          {
            kind: 'accessor',
            name: t('orderDetails.dateSynced'),
            accessor: 'trackingInformation.dateSynced',
            sortable: true,
            type: DataTableItemType.DATE,
          },
        ]}
        endpoint={'orders_ebay_tracking_numbers_info'}
        title={t('general.eBayTrackingInfo')}
        target={'orders'}
      />
    </div>
  );
}
