import React, {useContext} from 'react';
import {IconButton} from '@material-ui/core';
import {useTranslation} from 'react-i18next';
import KeyboardReturn from '@material-ui/icons/KeyboardReturn';
import {useDispatch} from 'react-redux';
import {modalActions} from '../../../modules/modal/actions';
import {MODALS} from '../../Modal/ModalContents';
import {ISupplierOrderItem} from '../../../types/SupplierOrders/ISupplierOrderItem';
import CreateSupplierOrderContext from '../../../screens/SupplierOrders/Context/CreateSupplierOrderContext';

interface IProps {
  row: ISupplierOrderItem;
}

export default function SupplierOrderToBacklogButton({row: supplierOrderItem}: IProps) {
  const {moveToBacklog} = useContext(CreateSupplierOrderContext);
  const dispatch = useDispatch();
  const {t} = useTranslation();
  const handleCancel = () => dispatch(modalActions.closeModal());

  const handleOnClick = (e: React.SyntheticEvent) => {
    e.preventDefault();

    dispatch(
      modalActions.addModal(MODALS.SUPPLIER_ORDER_BACKLOG_MODAL, {
        onSubmit: ({quantity}: any) => {
          moveToBacklog({id: supplierOrderItem._id, quantity: quantity});
          handleCancel();
        },
        onCancel: handleCancel,
        supplierOrderItem,
      }),
    );
  };

  return (
    <IconButton title={t('supplierOrderDetails.moveToBacklog')} style={{color: '#5cabef'}} onClick={handleOnClick}>
      <KeyboardReturn />
    </IconButton>
  );
}
