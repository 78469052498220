import React, {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import DataTable from '../../components/Table/Table';
import {CustomerReturnStatus} from '../../types/CustomerReturn/CustomerReturnStatus';
import {DataTableItemType} from '../../components/Table/interfaces/IHeaderAccessor';
import {modalActions} from '../../modules/modal/actions';
import {useDispatch} from 'react-redux';
import withRoleGuard from '../../guards/Role-Guard';
import {USER_ROLE} from '../../types/User/UserRole';
import {Button, IconButton} from '@material-ui/core';
import {MODALS} from '../../components/Modal/ModalContents';
import {useFetch} from '../../hooks/fetch';
import {Clear} from '@material-ui/icons';

const CustomerReturns = () => {
  const {t} = useTranslation();

  interface IActionsProps {
    doSyncArticle: (param: any) => void;
    doProductDeactivate: (param: any) => void;
    fetchData: () => void;
    id: string;
    row: any;
  }

  const Actions: React.FC<IActionsProps> = (props: IActionsProps) => {
    const {id, row} = props;

    const dispatch = useDispatch();
    const {t} = useTranslation();
    const [changeSupplierResponse, postChangeSupplier] = useFetch<any>('change_supplier_on_customer_complaint');
    const [deleteResponse, deleteCustomerReturn] = useFetch<any>('customer_return_delete');

    const handleChangeSupplier = async (event: React.MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation();
      event.preventDefault();

      dispatch(
        modalActions.addModal(MODALS.CHANGE_SUPPLIER_ON_CUSTOMER_COMPLAINT, {
          title: t('customerReturns.changeSupplier') || '',
          onSubmit: handleSubmitModal,
          onCancel: handleCancelModal,
        }),
      );
    };

    const handleSubmitModal = async (data: any) => {
      const supplier = data.supplier;
      postChangeSupplier({supplier, id});
    };

    useEffect(() => {
      if (changeSupplierResponse) {
        dispatch(modalActions.closeModal());
        props.fetchData();
      }
    }, [changeSupplierResponse]);

    const handleCancelModal = () => {
      dispatch(modalActions.closeModal());
    };

    const ChangeSupplierButton = () => (
      <Button
        style={{padding: '2px 6px', width: '120px'}}
        variant="contained"
        color="primary"
        onClick={handleChangeSupplier}
        title="Change supplier"
      >
        {t('customerReturns.changeSupplier')}
      </Button>
    );

    const handleDelete = async () => {
      if (confirm(t('general.areYouSure'))) {
        deleteCustomerReturn({id});
      }
    };

    const DeleteButton = () => (
      <IconButton onClick={handleDelete} title="Delete">
        <Clear fontSize="small" style={{fill: 'red'}}></Clear>
      </IconButton>
    );

    const ChangeSupplierButtonWithGuard = withRoleGuard(
      [USER_ROLE.ADMIN, USER_ROLE.STANDARD],
      ChangeSupplierButton,
      false,
    );

    return (
      <div className="products__actions">
        <ChangeSupplierButtonWithGuard />
        <DeleteButton />
      </div>
    );
  };

  return (
    <div className="orders__customer__return">
      <DataTable
        className="orders__table"
        headers={[
          {
            kind: 'accessor',
            name: t('customerReturns.status'),
            accessor: 'status',
            sortable: true,
            type: DataTableItemType.SELECT,
            options: ([{value: 'all', label: 'All'}] as any).concat(
              Object.values(CustomerReturnStatus).map((item) => {
                return {value: item, label: item};
              }),
            ),
          },
          {
            kind: 'accessor',
            name: t('orders.orderNumber'),
            accessor: 'orderNumber',
            sortable: true,
          },
          {
            kind: 'accessor',
            name: t('supplierOrders.supplier'),
            accessor: 'supplierName',
            sortable: true,
          },
          {
            kind: 'accessor',
            name: t('newArticles.externalArticleId'),
            accessor: 'externalArticleId',
            sortable: true,
          },
          {
            kind: 'accessor',
            name: t('products.title'),
            accessor: 'productTitle',
            sortable: true,
          },
          {
            kind: 'accessor',
            name: t('customers.customerNumber'),
            accessor: 'customerNumber',
            sortable: true,
          },
          {
            kind: 'accessor',
            name: t('customerDetails.customerName'),
            accessor: 'customerName',
            sortable: true,
          },
          {
            kind: 'accessor',
            name: t('customerReturns.quantity'),
            accessor: 'quantity',
            sortable: true,
            type: 'number',
          },
          {
            kind: 'accessor',
            name: t('customerReturns.createdAt'),
            accessor: 'createdAt',
            sortable: true,
            type: DataTableItemType.DATE,
          },
          {
            kind: 'component',
            name: t('general.actions'),
            component: Actions,
            props: {},
          },
        ]}
        endpoint={'customer_returns'}
        defaultSort={{key: 'createdAt', value: -1}}
        title={t('customerReturns.screenTitle')}
      />
    </div>
  );
};

export default CustomerReturns;
