import {Button} from '@material-ui/core';
import React, {ReactElement, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {useStorlessFetch} from '../../../hooks/fetch';
import {appActions} from '../../../modules/app/actions';
import {modalActions} from '../../../modules/modal/actions';
import {MODALS} from '../../Modal/ModalContents';

const RegenerateOrderSuggestionButton = (props: IRegenerateOrderSuggestionButtonProps): ReactElement => {
  const [regenerateOrderSuggestionResponse, regenerateOrderSuggestion] = useStorlessFetch(
    'regenerate_supplier_order_suggestion',
  );
  const {fetchSupplierOrderItems, productId} = props;
  const dispatch = useDispatch();
  const {t} = useTranslation();

  const handleClick = () => {
    dispatch(
      modalActions.addModal(MODALS.CONFIRM_DIALOG, {
        title: t('general.areYouSure'),
        content: t('productDetails.regenerateOrderSuggestion') + '?',
        onYes: () => regenerateOrderSuggestion({id: productId}),
        onNo: () => dispatch(modalActions.closeModal()),
      }),
    );
  };

  useEffect(() => {
    if (regenerateOrderSuggestionResponse.data) {
      dispatch(modalActions.closeModal());
      fetchSupplierOrderItems();
      dispatch(appActions.showSnackBar({text: t('general.success'), options: {severity: 'success'}}));
    }

    return () => {
      regenerateOrderSuggestionResponse.data = null;
      regenerateOrderSuggestionResponse.error = null;
    };
  }, [regenerateOrderSuggestionResponse]);

  return (
    <Button variant="contained" className="blue-button" onClick={handleClick}>
      {t('productDetails.regenerateOrderSuggestion')}
    </Button>
  );
};

interface IRegenerateOrderSuggestionButtonProps {
  productId: string;
  fetchSupplierOrderItems: () => void;
}

export default RegenerateOrderSuggestionButton;
