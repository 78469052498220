import {Grid, Card, TextField, MenuItem, Checkbox} from '@material-ui/core';
import React, {useContext} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {modalActions} from '../../../../modules/modal/actions';
import CreateOrderContext from '../../../../screens/Orders/Context/CreateOrderContext';
import {CountryOption} from '../../../../screens/Orders/CreateOrder';
import {ShippingProviderType} from '../../../../types/Shipment/ShippingProviderType';
import {MODALS} from '../../../Modal/ModalContents';
import DescriptionCounter from '../../../Product/ShopDescriptions/DescriptionCounter';

const ShippingAddressInfo = () => {
  const {order, shippingAddresses, countryOptions, updateOrderState, setOrder} = useContext(CreateOrderContext);
  const dispatch = useDispatch();
  const {t} = useTranslation();
  const maxChars = 35;

  const handleOnSubmitModal = (row: any) => {
    setOrder((prevState: any) => {
      const item = row?.row || row?.data;
      Object.keys(item).forEach((k) => (item[k] = item[k] === null ? '' : item[k]));
      const isDefaultAddress =
        item?.addressLine1 === order.defaultShippingAddress?.addressLine1 &&
        item?.customerName === order.defaultShippingAddress?.customerName;

      return {...prevState, shippingAddress: item, isDefaultAddress};
    });
    dispatch(modalActions.closeModal());
  };

  const openSelectShippingAddressModal = () => {
    dispatch(
      modalActions.addModal(MODALS.SHIPPING_ADDRESS_MODAL, {
        onSubmit: handleOnSubmitModal,
        data: shippingAddresses.data,
      }),
    );
  };

  return (
    <Grid item xs={12} sm={6} md={4}>
      <Card className="custom-card">
        <div className="title">
          {t('orderDetails.shippingAddress')}{' '}
          {shippingAddresses?.data?.length > 1 ? (
            <a
              style={{textDecoration: 'underline', cursor: 'pointer', float: 'right'}}
              onClick={openSelectShippingAddressModal}
            >
              {t('shippingAddress.selectPreviousAddress')}
            </a>
          ) : null}
        </div>
        <Grid container spacing={2}>
          <Grid item xs={12} style={{display: 'flex', alignItems: 'center'}}>
            <TextField
              variant="outlined"
              className="input"
              size="small"
              value={order.shippingAddress?.customerName}
              label={t('shippingAddress.customerName')}
              InputLabelProps={{shrink: true}}
              onChange={(event) => {
                updateOrderState(event.target.value, 'customerName', 'shippingAddress');
              }}
            />
            <DescriptionCounter
              text={order.shippingAddress?.customerName || ''}
              maxChars={maxChars}
              shippingType={order.shippingType}
            />
          </Grid>
          <Grid item xs={12} style={{display: 'flex', alignItems: 'center'}}>
            <TextField
              variant="outlined"
              className="input"
              size="small"
              value={order.shippingAddress?.addressLine2}
              label={t('shippingAddress.addressLine2')}
              InputLabelProps={{shrink: true}}
              onChange={(event) => {
                updateOrderState(event.target.value, 'addressLine2', 'shippingAddress');
              }}
            />
            <DescriptionCounter
              text={order.shippingAddress?.addressLine2 || ''}
              maxChars={maxChars}
              shippingType={order.shippingType}
            />
          </Grid>
          <Grid item xs={12} style={{display: 'flex', alignItems: 'center'}}>
            <TextField
              variant="outlined"
              className="input"
              size="small"
              value={order.shippingAddress?.deliveryInstruction}
              label={t('shippingAddress.deliveryInstruction')}
              InputLabelProps={{shrink: true}}
              onChange={(event) => {
                updateOrderState(event.target.value, 'deliveryInstruction', 'shippingAddress');
              }}
            />
            <DescriptionCounter
              text={order.shippingAddress?.deliveryInstruction || ''}
              maxChars={maxChars}
              shippingType={order.shippingType}
            />
          </Grid>
          <Grid item xs={12} style={{display: 'flex', alignItems: 'center'}}>
            <TextField
              variant="outlined"
              className="input"
              size="small"
              value={order.shippingAddress?.addressLine1}
              InputLabelProps={{shrink: true}}
              label={t('shippingAddress.addressLine1')}
              onChange={(event) => {
                updateOrderState(event.target.value, 'addressLine1', 'shippingAddress');
              }}
            />
            <DescriptionCounter
              text={order.shippingAddress?.addressLine1 || ''}
              maxChars={maxChars}
              shippingType={order.shippingType}
            />
          </Grid>

          {order.shippingType === ShippingProviderType.DHL ? (
            <>
              <Grid item xs={6}>
                <TextField
                  variant="outlined"
                  className="input"
                  size="small"
                  value={order.shippingAddress?.postNumber}
                  label={t('shippingAddress.postNumber')}
                  InputLabelProps={{shrink: true}}
                  onChange={(event) => {
                    updateOrderState(event.target.value, 'postNumber', 'shippingAddress');
                  }}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  variant="outlined"
                  className="input"
                  size="small"
                  value={order.shippingAddress?.lockerId}
                  label={t('shippingAddress.lockerId')}
                  InputLabelProps={{shrink: true}}
                  onChange={(event) => {
                    updateOrderState(event.target.value, 'lockerId', 'shippingAddress');
                  }}
                  type={'number'}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  variant="outlined"
                  className="input"
                  size="small"
                  value={order.shippingAddress?.retailId}
                  label={t('shippingAddress.retailId')}
                  InputLabelProps={{shrink: true}}
                  onChange={(event) => {
                    updateOrderState(event.target.value, 'retailId', 'shippingAddress');
                  }}
                  type={'number'}
                />
              </Grid>
            </>
          ) : null}
          <Grid item xs={6}>
            <TextField
              variant="outlined"
              className="input"
              size="small"
              value={order.shippingAddress?.zip}
              label={t('shippingAddress.zipCode')}
              InputLabelProps={{shrink: true}}
              onChange={(event) => {
                updateOrderState(event.target.value, 'zip', 'shippingAddress');
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              variant="outlined"
              className="input"
              size="small"
              value={order.shippingAddress?.city}
              label={t('shippingAddress.city')}
              InputLabelProps={{shrink: true}}
              onChange={(event) => {
                updateOrderState(event.target.value, 'city', 'shippingAddress');
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              variant="outlined"
              className="input"
              size="small"
              select
              label={t('shippingAddress.country')}
              value={order?.shippingAddress?.countryCode}
              onChange={(event) => {
                updateOrderState(event.target.value, 'countryCode', 'shippingAddress');
              }}
              required
            >
              {countryOptions &&
                countryOptions?.map((countryOption: CountryOption, countryIndex: number) => (
                  <MenuItem key={countryIndex} value={countryOption.code}>
                    {countryOption.name}
                  </MenuItem>
                ))}
            </TextField>
          </Grid>
          <Grid item xs={6}>
            {t('shippingAddress.isDefaultAddress')}
            <Checkbox
              color="primary"
              checked={order.isDefaultAddress}
              onChange={(event) => {
                updateOrderState(event.target.checked, 'isDefaultAddress');
              }}
            ></Checkbox>
          </Grid>
        </Grid>
      </Card>
    </Grid>
  );
};

export default ShippingAddressInfo;
