import {Checkbox, Grid} from '@material-ui/core';
import React, {ReactElement, useContext} from 'react';
import {useTranslation} from 'react-i18next';
import OrderScanningContext from '../../../screens/Orders/Context/OrderScanningContext';
import ConfirmStockButton from '../ConfirmStockButton';

const ScanningCustomerOrderItemInfo = (): ReactElement => {
  const {product, multiBoxInput, setMultiBoxInput, setProduct} = useContext(OrderScanningContext);
  const {t} = useTranslation();

  return (
    <Grid container item xs={12} sm={12} spacing={1}>
      <Grid item xs={12} sm={6} style={{alignSelf: 'center', textAlign: 'center'}}>
        <div style={{width: '300px', height: '300px', marginLeft: '10px'}}>
          <img src={product.productImage ?? '/images/no_image2.webp'} style={{maxWidth: '100%', maxHeight: '100%'}} />
        </div>
      </Grid>
      <Grid item xs={12} sm={6}>
        <p style={{whiteSpace: 'pre-line'}}>
          {t('productDetails.manufacturer')}: {product.productInformation?.manufacturer + '\n'}
          {t('productDetails.compatibility')}: {product.productInformation?.compatibility + '\n'}
          {t('productDetails.latestIncreaseDate')}: {product?.latestIncreaseDate + '\n'}
          {t('productDetails.latestIncreaseQuantity')}: {product?.latestIncreaseQuantity + '\n'}
          EAN: {product.ean + '\n'}
          {t('translation:productDetails.stockDDA')}: {product.stockDDA + '\n'}
          {product.isMultiBoxProduct ? (
            <>
              {t('productDetails.useMultiBox')}:
              <Checkbox
                color="primary"
                checked={multiBoxInput}
                onChange={(e) => {
                  product.usingMultibox = e.target.checked;
                  setProduct(product);
                  setMultiBoxInput(product.usingMultibox);
                }}
              ></Checkbox>
            </>
          ) : null}
          <br />
          <div>
            <ConfirmStockButton />
          </div>
        </p>
      </Grid>
    </Grid>
  );
};

export default ScanningCustomerOrderItemInfo;
