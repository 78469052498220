import {Typography} from '@material-ui/core';
import React, {useEffect, useState} from 'react';
import Form from '../../Form/Form';
import _ from 'lodash';
import {useTranslation} from 'react-i18next';
import {ISupplierOrderItem} from '../../../types/SupplierOrders/ISupplierOrderItem';
import * as Yup from 'yup';

export interface ISupplierOrderFromBacklogModalProps {
  supplierOrderItem: ISupplierOrderItem;
  onSubmit: (values: any) => void;
  onCancel: () => void;
}

interface Fields {
  quantity: number;
  supplierOrderNumber: number;
}

const SupplierOrderFromBacklogModal = (props: ISupplierOrderFromBacklogModalProps) => {
  const {onSubmit, onCancel, supplierOrderItem} = props;
  const [initialValues, setInitialValues] = useState<Fields | null>(null);
  const {t} = useTranslation();

  useEffect(() => {
    const {quantity, supplierOrderNumber} = supplierOrderItem;
    setInitialValues({quantity, supplierOrderNumber});
  }, [supplierOrderItem]);

  const fields = [
    {
      label: t('supplierOrderDetails.orderNumber'),
      placeholder: t('supplierOrderDetails.orderNumber'),
      fieldKey: 'supplierOrderNumber',
      type: 'input',
      fieldKeyType: 'number',
    },
    {
      label: t('supplierOrderDetails.quantity'),
      placeholder: t('supplierOrderDetails.quantity'),
      fieldKey: 'quantity',
      type: 'input',
      fieldKeyType: 'number',
    },
  ];

  const validationSchema = Yup.object().shape({
    quantity: Yup.number().required('Required').min(1).max(supplierOrderItem?.quantity),
    supplierOrderNumber: Yup.number().required('Required').min(1000000),
  });

  return (
    <>
      <Typography className="modal-title">{t('supplierOrderDetails.moveToSupplierOrder')}</Typography>
      {initialValues ? (
        <Form
          validationSchema={validationSchema}
          initialValues={initialValues}
          fields={fields}
          submitButtonText={t('general.submit')}
          cancelButtonText={t('general.cancel')}
          onSubmit={onSubmit}
          onCancel={onCancel}
        ></Form>
      ) : null}
    </>
  );
};

export default SupplierOrderFromBacklogModal;
