import {MenuItem, Select, Typography} from '@material-ui/core';
import {Button} from '@material-ui/core';
import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {ProductStatus} from '../../types/Product/ProductStatus.enum';
import {ShopType} from '../../types/Orders/ShopType';

export interface IChangeProductStatusModalProps {
  onYes: (data: any) => void;
  onNo: () => void;
}

export default function ChangeProductStatusModal({onYes, onNo}: IChangeProductStatusModalProps) {
  const [state, setState] = useState({productIds: [], shopName: null, status: null});
  const {t} = useTranslation();
  const shopNames = [
    ShopType.DRUCKDICHAUS,
    ShopType.SUPPLIES_PARTNER,
    ShopType.EBAY,
    ShopType.KAUFLAND,
    ShopType.GALAXUS,
    ShopType.AMAZON,
  ];
  const shopStatuses = [
    ProductStatus.ACTIVE,
    ProductStatus.DEACTIVATED,
    ProductStatus.END_OF_LIFE,
    ProductStatus.EXPIRED,
  ];
  const onChangeProductStatus = (e: any) => {
    const status = e.target.value;
    setState((prevState) => ({...prevState, status}));
  };
  const onChangeShopName = (e: any) => {
    const shopName = e.target.value;
    setState((prevState) => ({...prevState, shopName}));
  };

  const isDisabled = state.shopName === null || state.status === null;
  return (
    <div style={{minWidth: '380px'}}>
      <Typography className="modal-title">{t('products.changeProductStatus')}</Typography>
      <div>
        <p className="modal-fields">
          <div style={{paddingBottom: '5px'}}> {t('productDetails.shopName')}</div>
          <Select
            className="black-button"
            value={state.shopName}
            id="shopName"
            onChange={onChangeShopName}
            style={{marginRight: '5px'}}
            fullWidth
          >
            {shopNames.map((item: any) => (
              <MenuItem key={item} value={item}>
                {item}
              </MenuItem>
            ))}
          </Select>
          <div style={{paddingBottom: '5px'}}> {t('productDetails.status')}</div>
          <Select
            className="black-button"
            id="status"
            value={state.status}
            onChange={onChangeProductStatus}
            style={{marginRight: '5px'}}
            fullWidth
          >
            {shopStatuses.map((item: any) => (
              <MenuItem key={item} value={item}>
                {item}
              </MenuItem>
            ))}
          </Select>
        </p>

        <div className="action-buttons" style={{float: 'right', padding: '5px 10px 0px 0px'}}>
          <Button
            className={isDisabled ? 'inactive-button' : 'blue-button'}
            onClick={() => onYes(state)}
            disabled={isDisabled}
          >
            {t('general.yes')}
          </Button>

          <Button onClick={onNo} className="yellow-button">
            {t('general.no')}
          </Button>
        </div>
      </div>
    </div>
  );
}
