import axios from 'axios';
import React, {useContext, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import Webcam from 'react-webcam';
import {appActions} from '../../modules/app/actions';
import VideoRecorderContext from './VideoRecorderContext';
import config from '../../lib/config';

const VideoRecorder = () => {
  const [isRecording, setIsRecording] = useState(false);
  const [mediaRecorder, setMediaRecorder] = useState<MediaRecorder | null>(null);
  const {startRecordingRef, stopRecordingRef} = useContext(VideoRecorderContext);
  const [stream, setStream] = useState<MediaStream | null>(null);
  const webcamRef = React.useRef<Webcam>(null);
  const dispatch = useDispatch();
  const {t} = useTranslation();
  const startRecording = async () => {
    const mediaStream = await navigator.mediaDevices.getUserMedia({video: true});
    setStream(mediaStream);
    setIsRecording(true);
  };

  const stopRecording = async () => {
    if (!mediaRecorder) {
      return;
    }

    mediaRecorder.ondataavailable = onDataAvailable;
    mediaRecorder.stop();

    removeStreams();
    setIsRecording(false);
    setMediaRecorder(null);
    setStream(null);
  };

  const onDataAvailable = async (e: BlobEvent) => {
    const orderId = location.pathname.substring(location.pathname.lastIndexOf('/') + 1);
    const url = `${config.api}orders/${orderId}/video-record/upload`;
    const formData = new FormData();

    formData.append('file', e.data);

    axios
      .post(url, formData, {
        headers: {'Content-Type': 'multipart/form-data'},
      })
      .then(() => {
        dispatch(appActions.showSnackBar({text: t('videoRecorder.uploadSuccess'), options: {severity: 'success'}}));
      })
      .catch((error) => {
        dispatch(
          appActions.showSnackBar({
            text: `${t('videoRecorder.uploadFail')}: ${error?.message}`,
            options: {severity: 'error'},
          }),
        );
      });
  };

  const removeStreams = () => {
    mediaRecorder?.stream.getTracks().forEach((track) => track.stop());
    stream?.getTracks().forEach((track) => track.stop());
  };

  useEffect(() => {
    if (stream && isRecording) {
      const options = {mimeType: 'video/webm; codecs=vp9'};
      const mediaRecorder = new MediaRecorder(stream, options);
      setMediaRecorder(mediaRecorder);
      mediaRecorder.start();
    }

    return () => {
      setStream(null);
    };
  }, [isRecording]);

  return isRecording ? (
    <div>
      <span style={{color: 'red'}}>{mediaRecorder?.state?.toUpperCase()}</span>
      <button style={{display: 'none'}} ref={stopRecordingRef} onClick={stopRecording}>
        Stop Recording
      </button>
      <Webcam ref={webcamRef} audio={false} hidden={true} />
    </div>
  ) : (
    <button style={{display: 'none'}} ref={startRecordingRef} onClick={startRecording}>
      Start Recording
    </button>
  );
};

export default VideoRecorder;
