import React, {useContext, useEffect} from 'react';
import moment from 'moment';
import ListOfTodos from './ListOfTodos';
import TodoContext from '../../screens/Todo/Context/TodoContext';

const UpcomingTodos = () => {
  const {upcomingTodos, refreshTodos} = useContext(TodoContext);

  useEffect(() => {
    refreshTodos();
  }, []);

  const tasksGroupedByDate = () => {
    const tasks = upcomingTodos.map((todo) => {
      return {
        ...todo,
        dueDateFormatted: moment(todo.dueDate).format('DD.MM.YYYY'),
      };
    });

    const grouped = tasks.reduce((r: any, a: any) => {
      r[a.dueDateFormatted] = [...(r[a.dueDateFormatted] || []), a];
      return r;
    }, {});
    return grouped;
  };
  return (
    <div className="upcoming">
      {Object.entries(tasksGroupedByDate()).map(([date, value], index) => {
        const groups = tasksGroupedByDate();
        return (
          <div className="single-day" key={date}>
            <div className="date-title">{date}</div>
            <ListOfTodos todos={groups[date]}></ListOfTodos>
          </div>
        );
      })}
    </div>
  );
};

export default UpcomingTodos;
