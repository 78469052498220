import React from 'react';
import {IconButton} from '@material-ui/core';
import {AddBox} from '@material-ui/icons';

interface AddToOrderButtonProps {
  row: any;
  onClick: (row: any) => void;
}

const AddToOrderButton: React.FC<AddToOrderButtonProps> = ({row, onClick}: AddToOrderButtonProps) => {
  return (
    <IconButton onClick={() => onClick(row)} style={{marginBottom: 0}}>
      <AddBox className="teal-500" />
    </IconButton>
  );
};

export default AddToOrderButton;
