import React from 'react';
import {Grid, Box, TextField} from '@material-ui/core';
import {useEbayStyles} from './style';
import {Variant} from '../../types/Ebay/variant';

interface Props {
  variant: Variant;
  index: number;
  onUpdate: (index: any, variant: Variant) => void;
}

export default function EbayVariation({variant, index, onUpdate}: Props) {
  const classes = useEbayStyles();

  const handleFieldChange = (field: any, value: any) => {
    const updatedVariation = {...variant, [field]: value};
    onUpdate(index, updatedVariation);
  };

  return (
    <Grid
      container
      spacing={2}
      key={variant.initialPrinterModelName}
      className={`${classes.variantRow} ${index % 2 === 0 ? classes.evenRow : classes.oddRow}`}
    >
      <Grid item xs={1}>
        <p>{index + 1}.</p>
      </Grid>
      <Grid item xs={1}>
        <Box className={classes.thumbnail}>
          <img src={variant.image} alt={variant.title} className={classes.uploadedImage} />
        </Box>
      </Grid>
      <Grid item xs={2}>
        <TextField
          label="Product"
          fullWidth
          value={variant.title}
          onChange={(e) => handleFieldChange('title', e.target.value)}
          variant="outlined"
        />
      </Grid>

      <Grid item xs={2}>
        <TextField
          label="Printer Model"
          fullWidth
          value={variant.printerModel}
          variant="outlined"
          onChange={(e) => handleFieldChange('printerModel', e.target.value)}
        />
      </Grid>
      <Grid item xs={2}>
        <Grid container>
          <Grid item xs={6}>
            <TextField
              label="SKU"
              value={variant.sku}
              variant="outlined"
              onChange={(e) => handleFieldChange('sku', e.target.value)}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Offer Id"
              type="text"
              value={variant.offerId}
              disabled
              variant="outlined"
              InputProps={{readOnly: true}}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={2}>
        <TextField
          label="EAN"
          value={variant.ean}
          variant="outlined"
          onChange={(e) => handleFieldChange('ean', e.target.value)}
        />
      </Grid>
      <Grid item xs={1}>
        <TextField
          label={variant.fixedPrice ? 'Fixed Price' : 'Price'}
          fullWidth
          type="number"
          value={variant.fixedPrice ? variant.fixedPrice : variant.price}
          inputProps={{step: 0.01}}
          variant="outlined"
          InputProps={{readOnly: true}}
        />
      </Grid>
      <Grid item xs={1}>
        <TextField
          label="Quantity"
          type="number"
          value={variant.quantity}
          variant="outlined"
          InputProps={{readOnly: true}}
        />
      </Grid>
    </Grid>
  );
}
