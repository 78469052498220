import React, {useState, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useStorlessFetch} from '../../../hooks/fetch';
import {appActions} from '../../../modules/app/actions';
import {useDispatch} from 'react-redux';
import {Accordion, AccordionSummary, Button, Checkbox, IconButton} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {IEBayPricingVariable, IShopwareVariable} from '../../../types/ShopConfig/IShopConfig';
import EditIcon from '@material-ui/icons/Edit';
import moment from 'moment';
import {modalActions} from '../../../modules/modal/actions';
import {MODALS} from '../../../components/Modal/ModalContents';
import EbayConfig from './EbayConfig';

const EbayPricing = () => {
  const [pricingFactorsData, getPricingFactorsData] = useStorlessFetch('ebay_pricing_factors');
  const [sendUpdatePrices, sendUpdates] = useStorlessFetch('ebay_pricing_factors_update');
  const [updateExcludedCategoriesRes, updateExcludedCategories] = useStorlessFetch('ebay_update_excluded_categories');
  const [createPromotionRes, createPromotion] = useStorlessFetch('ebay_create_promotion');
  const [deletePromotionRes, deletePromotion] = useStorlessFetch('ebay_delete_promotion');
  const [pricingFactors, setPricingFactors] = useState<IEBayPricingVariable[]>([]);
  const [excludedCategories, setExcludedCategories] = useState<any>({});
  const [eBayCategories, setEbayCategories] = useState<any>({});
  const [sortedProducts, sortProducts] = useState<any>(null);
  const [editing, setEditing] = useState(false);
  const dispatch = useDispatch();
  const {t} = useTranslation();

  useEffect(() => {
    getPricingFactorsData();
  }, [createPromotionRes, deletePromotionRes]);

  useEffect(() => {
    if (pricingFactorsData.data?.priceFactors) {
      setPricingFactors(pricingFactorsData.data.priceFactors);
      setExcludedCategories(pricingFactorsData.data.excludedCategories);
      setEbayCategories(pricingFactorsData.data.eBayCategories || []);
    }
  }, [pricingFactorsData]);

  useEffect(() => {
    if (pricingFactors) {
      const data = groupBy(pricingFactors, 'compatibility');
      sortProducts(data);
    }
  }, [pricingFactors]);

  useEffect(() => {
    if (sendUpdatePrices.error) {
      dispatch(appActions.showSnackBar({text: sendUpdatePrices.error.name, options: {severity: 'error'}}));
    }

    if (sendUpdatePrices.loaded && !sendUpdatePrices.error) {
      dispatch(appActions.showSnackBar({text: t('pricing.shopFactorSaved'), options: {severity: 'success'}}));
    }
  }, [sendUpdatePrices]);

  useEffect(() => {
    if (updateExcludedCategoriesRes.data) {
      dispatch(appActions.showSnackBar({text: t('general.success'), options: {severity: 'success'}}));
    } else if (updateExcludedCategoriesRes.error) {
      dispatch(appActions.showSnackBar({text: updateExcludedCategoriesRes.error?.name, options: {severity: 'error'}}));
    }

    return () => {
      updateExcludedCategoriesRes.data = null;
      updateExcludedCategoriesRes.error = null;
    };
  }, [updateExcludedCategoriesRes]);

  useEffect(() => {
    if (createPromotionRes.data) {
      dispatch(appActions.showSnackBar({text: t('general.success'), options: {severity: 'success'}}));
    } else if (updateExcludedCategoriesRes.error) {
      dispatch(appActions.showSnackBar({text: createPromotionRes.error?.name, options: {severity: 'error'}}));
    }

    return () => {
      createPromotionRes.data = null;
      createPromotionRes.error = null;
    };
  }, [createPromotionRes]);

  useEffect(() => {
    if (deletePromotionRes.error) {
      dispatch(appActions.showSnackBar({text: deletePromotionRes.error.name, options: {severity: 'error'}}));
    }

    if (deletePromotionRes.loaded && !deletePromotionRes.error) {
      dispatch(appActions.showSnackBar({text: t('general.success'), options: {severity: 'success'}}));
    }
  }, [deletePromotionRes]);

  const groupBy = (items: any[], property: any) => {
    return items.reduce((accumulator: any, current: any) => {
      const key = current[property];
      if (!accumulator[key]) {
        accumulator[key] = [];
      }
      accumulator[key].push(current);
      return accumulator;
    }, {});
  };

  const sendPrices = (item: any) => {
    if (item.compatibility === 'undefined') {
      item.compatibility = undefined;
    }
    sendUpdates(item);
  };

  const productTypeSortCondition = (a: any, b: any) => (a[0] > b[0] ? 1 : -1);
  const manufacturerConditionSort = (a: any, b: any) => (a.manufacturer > b.manufacturer ? 1 : -1);

  const handleSaveExcludedCategories = (data: any, categoryKey: string) => {
    updateExcludedCategories({
      excludeCategory: {name: categoryKey, excluded: data.excludeFromPricing},
      categoryId: data.categoryId,
    });

    setExcludedCategories((prevState: any) => ({
      ...prevState,
      [categoryKey]: data.excludeFromPricing,
      categoryId: data.categoryId,
    }));

    setEbayCategories((prevState: any) => ({
      ...prevState,
      [categoryKey]: data.categoryId,
    }));
  };

  const handleEditItem = async (event: React.MouseEvent<HTMLButtonElement>, categoryKey: any, row: any) => {
    event.stopPropagation();
    event.preventDefault();

    dispatch(
      modalActions.addModal(MODALS.EDIT_EBAY_CATEGORY, {
        title: t('pricing.editConfigData') || '',
        onSubmit: (values: any) => editButtonHandler(values, categoryKey),
        onCancel: handleCancelModal,
        row,
        width: '1000px',
      } as any),
    );
  };

  const editButtonHandler = async (values: any, categoryKey: any) => {
    const data = {...values};
    handleSaveExcludedCategories(data, categoryKey);
    handleCancelModal();
  };

  const handleCancelModal = () => {
    dispatch(modalActions.closeModal());
    setEditing((prevState) => !prevState);
  };

  const onChangeItemFactors = (e: any, singleProduct: IShopwareVariable, fieldName: string) => {
    const newPricingFactors = [...pricingFactors];
    const pricingFactor: any = newPricingFactors.find(
      (item: IShopwareVariable) =>
        item.productType === singleProduct.productType &&
        item.manufacturer === singleProduct.manufacturer &&
        item.compatibility === singleProduct.compatibility,
    );

    if (pricingFactor) {
      if (fieldName === 'stockLimit') {
        pricingFactor[fieldName] = parseInt(e.target.value);
      } else {
        pricingFactor[fieldName] = e.target.value;
      }

      setPricingFactors(newPricingFactors);
    }
  };

  const handleClick = async () => {
    dispatch(
      modalActions.addModal(MODALS.UPDATE_SHOP_FACTORS, {
        featureName: 'eBayConfig',
        callback: () => {
          getPricingFactorsData();
          dispatch(appActions.showSnackBar({text: t('general.success'), options: {severity: 'success'}}));
          dispatch(modalActions.closeModal());
        },
      }),
    );
  };

  const renderRow = (singleProduct: IEBayPricingVariable, index: number) => {
    const isDisabled =
      !singleProduct.discount2OrMore && !singleProduct.discount3OrMore && !singleProduct.discount4OrMore;

    return (
      <div className="productPricing" key={index}>
        <p className="manufacturer">{singleProduct.manufacturer}</p>
        <p style={{textAlign: 'right'}}>
          <input
            id={'stockLimit' + index}
            type="number"
            min="1"
            step="1"
            value={singleProduct.stockLimit}
            onChange={(e: any) => onChangeItemFactors(e, singleProduct, 'stockLimit')}
          />
        </p>
        <p style={{textAlign: 'right'}}>
          <input
            id={'shopFactor' + index}
            type="number"
            min="0"
            step="0.01"
            value={singleProduct.shopFactor}
            onChange={(e: any) => onChangeItemFactors(e, singleProduct, 'shopFactor')}
          />
        </p>
        <p style={{textAlign: 'right'}}>
          <input
            id={'discount2OrMore' + index}
            type="number"
            min="0"
            step="1"
            value={singleProduct.discount2OrMore}
            onChange={(e: any) => onChangeItemFactors(e, singleProduct, 'discount2OrMore')}
          />
        </p>
        <p style={{textAlign: 'right'}}>
          <input
            id={'discount3OrMore' + index}
            type="number"
            min="0"
            step="1"
            value={singleProduct.discount3OrMore}
            onChange={(e: any) => onChangeItemFactors(e, singleProduct, 'discount3OrMore')}
          />
        </p>
        <p style={{textAlign: 'right'}}>
          <input
            id={'discount4OrMore' + index}
            type="number"
            min="0"
            step="1"
            value={singleProduct.discount4OrMore}
            onChange={(e: any) => onChangeItemFactors(e, singleProduct, 'discount4OrMore')}
          />
        </p>

        <p style={{textAlign: 'right'}}>
          {singleProduct.promotionStartDate
            ? moment(singleProduct.promotionStartDate).format('DD. MMM YYYY HH:mm:ss')
            : ''}
        </p>
        <p style={{textAlign: 'right'}}>
          {singleProduct.promotionEndDate ? moment(singleProduct.promotionEndDate).format('DD. MMM YYYY HH:mm:ss') : ''}
        </p>

        <p>
          <Button
            variant="contained"
            className="blue-button"
            onClick={() => {
              sendPrices({...singleProduct});
            }}
          >
            {t('pricing.submitChanges')}
          </Button>

          {!singleProduct.promotionStartDate && (
            <Button
              variant="contained"
              className={!isDisabled ? 'yellow-button' : 'inactive-button'}
              disabled={isDisabled}
              onClick={() => {
                createPromotion(singleProduct);
              }}
            >
              {t('pricing.createPromotion')}
            </Button>
          )}

          {singleProduct.promotionStartDate && (
            <Button
              variant="contained"
              className={'black-button'}
              onClick={() => {
                deletePromotion(singleProduct);
              }}
            >
              {t('pricing.deletePromotion')}
            </Button>
          )}
        </p>
      </div>
    );
  };

  return pricingFactorsData?.data ? (
    <>
      <div className="customer-details" style={{width: 'unset'}}>
        <div className="center-content">
          <div className="customer-details__header">
            <p>{t('general.eBayPricing')}</p>
            <Button variant="contained" color="primary" className="blue-button" onClick={handleClick}>
              {t('pricing.updateShopFactors')}
            </Button>
          </div>
        </div>

        <EbayConfig configObjectProp={pricingFactorsData?.data?.customConfig} />

        <div className="order-details">
          <div className="order-info">
            <div className="pricing">
              {Object.entries(sortedProducts || [])
                .sort(([key], [key2]) => key2.localeCompare(key))
                .map((item: any, index: any) => (
                  <Accordion className="productsContent" key={index} defaultExpanded>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <h1 className="productType" key={index}>
                        {item[0] !== 'undefined' ? item[0] : t('pricing.undefined')}
                      </h1>
                    </AccordionSummary>
                    <div>
                      {(Object.entries(groupBy(item[1], 'productType')) || [])
                        .sort(productTypeSortCondition)
                        .map((itemElement: any, inx: any) => {
                          const itemElementValue = `${itemElement?.[1]?.[0]?.compatibility};${itemElement[0]}`;
                          return (
                            <Accordion key={inx}>
                              <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                                onClick={(e) => {
                                  e.preventDefault();
                                  e.stopPropagation();
                                }}
                              >
                                <div style={{display: 'flex', width: '100%', alignItems: 'center', height: '20px'}}>
                                  <h2 className="productType" key={index} style={{width: '100%', alignItems: 'center'}}>
                                    <span>{itemElement[0]}</span>
                                  </h2>
                                  <span style={{float: 'right', width: '40%', textAlign: 'right'}}>
                                    <IconButton
                                      onClick={(e) =>
                                        handleEditItem(e, itemElementValue, {
                                          excludeFromPricing: excludedCategories[itemElementValue],
                                          categoryId: eBayCategories[itemElementValue],
                                        })
                                      }
                                    >
                                      <EditIcon
                                        htmlColor={editing ? '#5cabef' : 'none'}
                                        style={{fontSize: 20}}
                                        onClick={() => setEditing((prevState) => !prevState)}
                                      />
                                    </IconButton>
                                    {eBayCategories[itemElementValue] ? eBayCategories[itemElementValue] : ''}
                                    <Checkbox
                                      disabled
                                      color="primary"
                                      name="excludeFromPricing"
                                      onClick={(e) => {
                                        e.stopPropagation();
                                      }}
                                      checked={excludedCategories[itemElementValue]}
                                    />
                                    Excluded? &nbsp;
                                  </span>
                                </div>
                              </AccordionSummary>
                              <div className="productPricing productHead">
                                <p className="manufacturer">{t('pricing.manufacturer')}</p>
                                <p style={{textAlign: 'right'}}>{t('pricing.stockLimit')}</p>
                                <p style={{textAlign: 'right'}}>{t('pricing.shopFactor')}</p>
                                <p style={{textAlign: 'right'}}>{t('pricing.discount2OrMore')}</p>
                                <p style={{textAlign: 'right'}}>{t('pricing.discount3OrMore')}</p>
                                <p style={{textAlign: 'right'}}>{t('pricing.discount4OrMore')}</p>
                                <p style={{textAlign: 'right'}}>{t('pricing.promotionStartDate')}</p>
                                <p style={{textAlign: 'right'}}>{t('pricing.promotionEndDate')}</p>
                                <p></p>
                              </div>
                              {itemElement[1]
                                .sort(manufacturerConditionSort)
                                .map((singleProduct: IShopwareVariable, index: any) => renderRow(singleProduct, index))}
                            </Accordion>
                          );
                        })}
                    </div>
                  </Accordion>
                ))}
            </div>
          </div>
        </div>
      </div>
    </>
  ) : null;
};
export default EbayPricing;
