import React, {useContext, useEffect, useState} from 'react';
import ListOfTodos from './ListOfTodos';
import TodoContext from '../../screens/Todo/Context/TodoContext';

const SearchTasks = () => {
  const {searchTodos, refreshTodos} = useContext(TodoContext);

  useEffect(() => {
    refreshTodos();
  }, []);

  return (
    <div>
      <ListOfTodos todos={searchTodos}></ListOfTodos>
    </div>
  );
};

export default SearchTasks;
