import {Button, Checkbox} from '@material-ui/core';
import React, {useContext, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {useStorlessFetch} from '../../hooks/fetch';
import {appActions} from '../../modules/app/actions';
import OrderDetailsContext from '../../screens/Orders/Context/OrderDetailsContext';

const EditIsPaid = () => {
  const {order, setOrder} = useContext(OrderDetailsContext);
  const [currentIsPaidState, setCurrentIsPaidState] = useState(order.isPaid);
  const [orderPut, doOrderPut] = useStorlessFetch('order_put');
  const dispatch = useDispatch();
  const {t} = useTranslation();

  const onButtonSave = () => {
    doOrderPut({...order, id: order._id});
    setCurrentIsPaidState(order.isPaid);
  };

  useEffect(() => {
    if (orderPut?.data) {
      dispatch(appActions.showSnackBar({text: t('general.submitFormText'), options: {severity: 'success'}}));
    } else if (orderPut?.error) {
      dispatch(appActions.showSnackBar({text: orderPut.error.name, options: {severity: 'error'}}));
    }

    return () => {
      orderPut.data = null;
      orderPut.error = null;
    };
  }, [orderPut]);

  return (
    <span>
      <Checkbox
        checked={order?.isPaid}
        onChange={(e: any) => {
          setOrder({...order, isPaid: e.target.checked});
        }}
      />
      {order.isPaid !== currentIsPaidState && (
        <Button variant="contained" className="order-info-button" onClick={onButtonSave}>
          {t('general.save')}
        </Button>
      )}
    </span>
  );
};

export default EditIsPaid;
