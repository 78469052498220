import React, {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import DataTable from '../../components/Table/Table';
import {CustomerType} from '../../types/Customer/CustomerType';
import {modalActions} from '../../modules/modal/actions';
import {MODALS} from '../../components/Modal/ModalContents';
import {Button} from '@material-ui/core';
import {useDispatch} from 'react-redux';
import {ICustomer} from '../../types/Customer/ICustomer';
import {useFetch} from '../../hooks/fetch';
import {appActions} from '../../modules/app/actions';
import {DataTableItemType} from '../../components/Table/interfaces/IHeaderAccessor';

const Customers = () => {
  const [createCustomerResponse, createCustomer] = useFetch<any>('create_customer');
  const dispatch = useDispatch();
  const {t} = useTranslation();

  useEffect(() => {
    if (createCustomerResponse.error) {
      dispatch(appActions.showSnackBar({text: 'Error saving', options: {severity: 'error'}}));
    } else if (createCustomerResponse.data) {
      dispatch(appActions.showSnackBar({text: t('general.success'), options: {severity: 'success'}}));
      dispatch(modalActions.closeModal());
    }

    return () => {
      createCustomerResponse.data = null;
      createCustomerResponse.error = null;
      createCustomerResponse.loaded = null;
      createCustomerResponse.loading = null;
    };
  }, [createCustomerResponse]);

  return (
    <div className="customers">
      <DataTable
        className="customers__table"
        headers={[
          {
            kind: 'accessor',
            name: t('customers.customerNumber'),
            accessor: 'customerNumber',
            sortable: true,
          },
          {
            kind: 'accessor',
            name: t('customers.customerType'),
            accessor: 'customerType',
            sortable: true,
            type: DataTableItemType.SELECT,
            options: ([{value: 'all', label: 'All'}] as any).concat(
              Object.values(CustomerType).map((item) => {
                return {value: item, label: item};
              }),
            ),
          },
          {
            kind: 'accessor',
            name: t('customers.customerName'),
            accessor: 'customerName',
            sortable: true,
          },
          {
            kind: 'accessor',
            name: t('customers.email'),
            accessor: 'email',
            sortable: true,
          },
          {
            kind: 'accessor',
            name: t('customers.address'),
            accessor: 'address',
            sortable: true,
          },
          {
            kind: 'accessor',
            name: t('customers.country'),
            accessor: 'country',
            sortable: true,
            callback: (item: ICustomer) => {
              return item.country ? t(`countryCodes.${item.country}`) : undefined;
            },
          },
          {
            kind: 'accessor',
            name: t('customers.city'),
            accessor: 'city',
            sortable: true,
          },
          {
            kind: 'accessor',
            name: t('customers.phone'),
            accessor: 'phone',
            sortable: true,
          },
        ]}
        endpoint={'customers'}
        target={'customers'}
        defaultSort={{key: 'createdAt', value: -1}}
        options={{deletedAt: null}}
        title={t('customers.screenTitle')}
        refreshData={createCustomerResponse?.loaded}
        actionButtons={
          <Button
            variant="contained"
            className="blue-button"
            onClick={() => {
              dispatch(
                modalActions.addModal(MODALS.NEW_CUSTOMER, {
                  createCustomerResponse,
                  createCustomer,
                }),
              );
            }}
          >
            {t('customers.new')}
          </Button>
        }
      />
    </div>
  );
};

export default Customers;
