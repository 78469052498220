import {Grid, Card, TextField, MenuItem} from '@material-ui/core';
import React, {useContext} from 'react';
import {useTranslation} from 'react-i18next';
import CreateOrderContext from '../../../../screens/Orders/Context/CreateOrderContext';
import {CountryOption} from '../../../../screens/Orders/CreateOrder';

const BillingAddressInfo = () => {
  const {order, countryOptions, updateOrderState} = useContext(CreateOrderContext);
  const {t} = useTranslation();

  return (
    <Grid item xs={12} sm={6} md={4}>
      <Card className="custom-card">
        <div className="title">{t('orderDetails.billingAddress')}</div>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              className="input"
              size="small"
              value={order.billingAddress?.company}
              label={t('customerDetails.companyName')}
              InputLabelProps={{shrink: true}}
              onChange={(event) => {
                updateOrderState(event.target.value, 'company', 'billingAddress');
              }}
            />
          </Grid>
          <Grid item xs={2}>
            <TextField
              variant="outlined"
              className="input"
              size="small"
              select
              label={t('customerDetails.salutation')}
              value={order.billingAddress?.salutation}
              onChange={(event) => {
                updateOrderState(event.target.value, 'salutation', 'billingAddress');
              }}
            >
              <MenuItem key="Herr" value="Herr">
                Herr
              </MenuItem>
              <MenuItem key="Frau" value="Frau">
                Frau
              </MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={5}>
            <TextField
              variant="outlined"
              className="input"
              size="small"
              value={order.billingAddress?.firstName}
              label={t('customerDetails.firstName')}
              InputLabelProps={{shrink: true}}
              onChange={(event) => {
                updateOrderState(event.target.value, 'firstName', 'billingAddress');
              }}
            />
          </Grid>
          <Grid item xs={5}>
            <TextField
              variant="outlined"
              className="input"
              size="small"
              value={order.billingAddress?.lastName}
              label={t('customerDetails.lastName')}
              InputLabelProps={{shrink: true}}
              onChange={(event) => {
                updateOrderState(event.target.value, 'lastName', 'billingAddress');
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              className="input"
              size="small"
              value={order.billingAddress?.addressLine1}
              InputLabelProps={{shrink: true}}
              label={t('shippingAddress.addressLine1')}
              onChange={(event) => {
                updateOrderState(event.target.value, 'addressLine1', 'billingAddress');
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              className="input"
              size="small"
              value={order.billingAddress?.addressLine2}
              label={t('shippingAddress.addressLine2')}
              InputLabelProps={{shrink: true}}
              onChange={(event) => {
                updateOrderState(event.target.value, 'addressLine2', 'billingAddress');
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              variant="outlined"
              className="input"
              size="small"
              value={order.billingAddress?.zip}
              label={t('shippingAddress.zipCode')}
              InputLabelProps={{shrink: true}}
              onChange={(event) => {
                updateOrderState(event.target.value, 'zip', 'billingAddress');
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              variant="outlined"
              className="input"
              size="small"
              value={order.billingAddress?.city}
              label={t('shippingAddress.city')}
              InputLabelProps={{shrink: true}}
              onChange={(event) => {
                updateOrderState(event.target.value, 'city', 'billingAddress');
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              variant="outlined"
              className="input"
              size="small"
              select
              label={t('shippingAddress.country')}
              value={order?.billingAddress?.countryCode}
              onChange={(event) => {
                updateOrderState(event.target.value, 'countryCode', 'billingAddress');
              }}
              required
            >
              {countryOptions &&
                countryOptions?.map((countryOption: CountryOption, countryIndex: number) => (
                  <MenuItem key={countryIndex} value={countryOption.code}>
                    {countryOption.name}
                  </MenuItem>
                ))}
            </TextField>
          </Grid>
        </Grid>
      </Card>
    </Grid>
  );
};

export default BillingAddressInfo;
