import React from 'react';
import DataTable from '../../components/Table/Table';
import {DataTableItemType} from '../../components/Table/interfaces/IHeaderAccessor';
import {SupplierOrderStatus} from '../../types/SupplierOrders/SupplierOrderStatus';
import {useTranslation} from 'react-i18next';
import {ISupplier} from '../../types/Supplier/ISupplier';

export interface IProps {
  supplier: ISupplier;
}

export default function SupplierOrdersBySupplier({supplier}: IProps) {
  const {t} = useTranslation();

  return (
    <DataTable
      className="orders__table"
      headers={[
        {
          kind: 'accessor',
          name: t('supplierOrders.status'),
          accessor: 'status',
          sortable: true,
          type: DataTableItemType.MULTIPLE_SELECT,
          options: ([{value: 'all', label: 'All'}] as any).concat(
            Object.values(SupplierOrderStatus).map((item) => {
              return {value: item, label: item};
            }),
          ),
        },
        {
          kind: 'accessor',
          name: t('supplierOrders.orderNumber'),
          accessor: 'orderNumber',
          sortable: true,
          type: 'number',
        },
        {
          kind: 'accessor',
          name: t('supplierOrders.createdAt'),
          accessor: 'createdAt',
          sortable: true,
          type: DataTableItemType.DATE,
        },
        {
          kind: 'accessor',
          name: t('supplierOrders.deliveredAt'),
          accessor: 'deliveredAt',
          sortable: true,
          type: DataTableItemType.DATE,
        },
      ]}
      endpoint={'supplier_orders'}
      target={'supplierOrders'}
      defaultParams={{supplier: supplier._id}}
      defaultSort={{key: 'createdAt', value: -1}}
    />
  );
}
