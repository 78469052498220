import {TextField, Typography} from '@material-ui/core';
import React, {ReactElement, useEffect} from 'react';
import Form from '../Form/Form';
import {useTranslation} from 'react-i18next';
import {useFetch} from '../../hooks/fetch';

const CreateExchangeOrderInvoiceModal = (props: ICreateExchangeOrderInvoiceModalProps): ReactElement => {
  const {onSubmit, onCancel, orderId} = props;
  const [getInvoiceDataResponse, getInvoiceData] = useFetch<any>('get_exchange_order_invoice_data');
  const [invoiceData, setInvoiceData] = React.useState<any>([]);
  const [shippingFee, setShippingFee] = React.useState<number>(0);
  const {t} = useTranslation();

  useEffect(() => {
    getInvoiceData({id: orderId});
  }, [orderId]);

  useEffect(() => {
    if (getInvoiceDataResponse.data) {
      setInvoiceData(getInvoiceDataResponse.data);
    }
  }, [getInvoiceDataResponse.data]);

  const fields: any[] = [];
  const initialValues: any[] = [];

  return (
    <>
      <Typography className="modal-title">{t('orderDetails.createInvoiceForExchangeOrder')}</Typography>
      {invoiceData.length > 0 &&
        invoiceData.map((item: any, index: number) => (
          <div key={index} className="flex flex-center" style={{maxWidth: '480px'}}>
            {/* <Typography>{item.exchangeItemId}</Typography> */}
            <Typography style={{padding: '10px'}}>{item.exchangeItemTitle}</Typography>
            <TextField
              style={{width: '100px'}}
              name={`quantity${index}`}
              label={t('orderDetails.quantity')}
              type="number"
              value={item.quantity}
              inputProps={{min: 1}}
              onChange={(e) => {
                const newInvoiceData = [...invoiceData];
                newInvoiceData[index].quantity = +e.target.value;
                setInvoiceData(newInvoiceData);
              }}
              required
            >
              {item.quantity}
            </TextField>
            <TextField
              name={`price${index}`}
              label={t('orderItems.priceNet')}
              type="number"
              value={item.price}
              inputProps={{step: '0.01', min: 0.01, max: 9999999.99}}
              style={{width: '100px'}}
              onChange={(e) => {
                const newInvoiceData = [...invoiceData];
                newInvoiceData[index].price = +e.target.value;
                setInvoiceData(newInvoiceData);
              }}
              required
            >
              {item.price}
            </TextField>
          </div>
        ))}

      <div className="flex" style={{maxWidth: '480px', marginTop: '20px'}}>
        <Typography style={{padding: '10px', flex: 1}}>{t('orderDetails.shippingFeeGross')}</Typography>
        <TextField
          name="shippingFee"
          type="number"
          value={shippingFee}
          inputProps={{step: '0.01', min: 0.0, max: 9999999.99}}
          style={{width: '100px'}}
          onChange={(e) => setShippingFee(+e.target.value)}
          required
        >
          {shippingFee}
        </TextField>
      </div>

      <Form
        initialValues={initialValues}
        fields={fields}
        submitButtonText={t('general.submit')}
        cancelButtonText={t('general.cancel')}
        onSubmit={(values) => onSubmit({invoiceData, shippingFee})}
        onCancel={() => {
          onCancel();
        }}
      ></Form>
    </>
  );
};

export interface ICreateExchangeOrderInvoiceModalProps {
  onSubmit: (values: any) => void;
  onCancel: () => void;
  orderId?: string;
}

export default CreateExchangeOrderInvoiceModal;
