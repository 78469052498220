import React, {ReactElement} from 'react';
import {useTranslation} from 'react-i18next';
import DataTable from '../../../Table/Table';
import {SupplierOrderItemStatus} from '../../../../types/SupplierOrders/SupplierOrderItemStatus';
import {DataTableItemType} from '../../../Table/interfaces/IHeaderAccessor';

const SectionSupplierOrdersTable = ({title, productId}: IProductSupplierOrdersProps): ReactElement => {
  const {t} = useTranslation();

  return (
    <>
      <DataTable
        tableHeight="420px"
        title={title}
        headers={[
          {
            kind: 'accessor',
            name: t('supplierOrderItems.status'),
            accessor: 'status',
            sortable: true,
            type: DataTableItemType.MULTIPLE_SELECT,
            options: ([{value: 'all', label: 'All'}] as any).concat(
              Object.values(SupplierOrderItemStatus).map((item) => {
                return {value: item, label: item};
              }),
            ),
          },
          {
            kind: 'accessor',
            name: t('supplierOrderItems.orderNumber'),
            accessor: 'supplierOrderNumber',
            sortable: true,
            type: 'number',
          },
          {
            kind: 'accessor',
            name: t('supplierOrderItems.date'),
            accessor: 'dateOrdered',
            sortable: true,
            type: DataTableItemType.DATE,
          },
          {
            kind: 'accessor',
            name: t('supplierOrderItems.supplierName'),
            accessor: 'supplierName',
            sortable: true,
          },
          {
            kind: 'accessor',
            name: t('supplierOrderItems.quantity'),
            accessor: 'quantity',
            sortable: true,
            type: 'number',
          },
          {
            kind: 'accessor',
            name: t('supplierOrderItems.price'),
            accessor: 'price',
            sortable: true,
            format: 'price',
            type: 'number',
          },
        ]}
        options={{product: productId}}
        endpoint={'supplier_order_items'}
        defaultSort={{key: 'createdAt', value: -1}}
        target="supplierOrders"
        selectRowClickId="supplierOrder._id"
        hideFilters={true}
      />
    </>
  );
};

interface IProductSupplierOrdersProps {
  productId: any;
  title?: string;
}

export default SectionSupplierOrdersTable;
