import {Typography} from '@material-ui/core';
import React from 'react';
import Form, {IField} from '../Form/Form';
import _ from 'lodash';
import {useTranslation} from 'react-i18next';
import {SalutationType} from '../../types/Customer/SalutationType';
import {SHOPWARE_PAYMENT_TYPE} from '../../types/Orders/ShopwarePaymentType';
import {ICustomField, ICustomFieldType} from '../../types/CustomFields/CustomField';
import {getType} from '../../lib/customFields';

export interface IEditCustomer {
  title: string;
  onSubmit: (values: any) => void;
  onCancel: () => void;
  customerObject?: any;
  customFields?: ICustomField[];
}

const generateInitialValues = (fields: any[], customerObject: any) => {
  if (customerObject) {
    const defaults = _.omit(customerObject, ['_id', '__v']);
    return defaults;
  } else {
    let defaults = {};
    fields.forEach((field) => {
      defaults = {...defaults, [field.fieldKey]: ''};
    });
    return defaults;
  }
};

const EditCustomer = (props: IEditCustomer) => {
  const {title, onSubmit, onCancel, customerObject: customerObject, customFields} = props;
  const {t} = useTranslation();

  const fields: IField[] = [
    {
      label: t('general.generalInfo'),
      fieldKey: '',
      type: 'section-title',
    },
    {
      label: t('customerDetails.customerName'),
      placeholder: t('customerDetails.customerName'),
      fieldKey: 'customerName',
      type: 'input',
    },
    {
      label: t('customerDetails.title'),
      placeholder: t('customerDetails.title'),
      fieldKey: 'title',
      type: 'input',
    },
    {
      label: t('customerDetails.salutation'),
      placeholder: t('customerDetails.salutation'),
      fieldKey: 'salutation',
      type: 'select',
      options: Object.values(SalutationType).map((item) => {
        return {value: item, label: item};
      }),
    },
    {
      label: t('customerDetails.firstName'),
      placeholder: t('customerDetails.firstName'),
      fieldKey: 'firstName',
      type: 'input',
    },
    {
      label: t('customerDetails.lastName'),
      placeholder: t('customerDetails.lastName'),
      fieldKey: 'lastName',
      type: 'input',
    },
    {
      label: t('customerDetails.companyName'),
      placeholder: t('customerDetails.companyName'),
      fieldKey: 'company',
      type: 'input',
    },
    {
      label: t('customerDetails.address'),
      placeholder: t('customerDetails.address'),
      fieldKey: 'address',
      type: 'input',
    },
    {
      label: t('customerDetails.addressSupplement'),
      placeholder: t('customerDetails.addressSupplement'),
      fieldKey: 'addressSupplement',
      type: 'input',
    },
    {
      label: t('customerDetails.postalCode'),
      placeholder: t('customerDetails.postalCode'),
      fieldKey: 'postalCode',
      type: 'input',
    },
    {
      label: t('customerDetails.city'),
      placeholder: t('customerDetails.city'),
      fieldKey: 'city',
      type: 'input',
    },
    {
      label: t('customerDetails.country'),
      placeholder: t('customerDetails.country'),
      fieldKey: 'country',
      type: 'autocomplete',
      options: [],
      endpoint: 'country_autocomplete_list',
      defaultValue: customerObject.country || '',
      required: true,
    },
    {
      label: t('customerDetails.state'),
      placeholder: t('customerDetails.state'),
      fieldKey: 'state',
      type: 'input',
    },
    {
      label: t('general.contactInfo'),
      fieldKey: '',
      type: 'section-title',
    },
    {
      label: t('customerDetails.email'),
      placeholder: t('customerDetails.email'),
      fieldKey: 'email',
      type: 'input',
      required: true,
    },
    {
      label: t('customerDetails.otherEmails'),
      placeholder: t('customerDetails.otherEmails'),
      fieldKey: 'otherEmails',
      type: 'chipsInput',
    },
    {
      label: t('customerDetails.invoiceEmail'),
      placeholder: t('customerDetails.invoiceEmail'),
      fieldKey: 'invoiceEmail',
      type: 'input',
    },
    {
      label: t('customerDetails.sepaEmail'),
      placeholder: t('customerDetails.sepaEmail'),
      fieldKey: 'sepaDetails.email',
      type: 'input',
    },
    {
      label: t('customerDetails.phone'),
      placeholder: t('customerDetails.phone'),
      fieldKey: 'phone',
      type: 'input',
    },
    {
      label: t('customerDetails.fax'),
      placeholder: t('customerDetails.fax'),
      fieldKey: 'dealerCompanyDetails.contactDetails.fax',
      type: 'input',
    },
    {
      label: t('customerDetails.web'),
      placeholder: t('customerDetails.web'),
      fieldKey: 'dealerCompanyDetails.contactDetails.web',
      type: 'input',
    },
    {
      label: t('general.paymentInfo'),
      fieldKey: '',
      type: 'section-title',
    },
    {
      label: t('customerDetails.paymentMethodName'),
      placeholder: t('customerDetails.paymentMethodName'),
      fieldKey: 'paymentMethodName',
      type: 'select',
      options: Object.values(SHOPWARE_PAYMENT_TYPE).map((item) => {
        return {value: item, label: item};
      }),
    },
    {
      label: t('customerDetails.bankDetailsLabel'),
      placeholder: t('customerDetails.bankDetailsLabel'),
      fieldKey: 'dealerCompanyDetails.bankDetails.label',
      type: 'input',
    },
    {
      label: t('customerDetails.iban'),
      placeholder: t('customerDetails.iban'),
      fieldKey: 'dealerCompanyDetails.bankDetails.iban',
      type: 'input',
    },
    {
      label: t('customerDetails.swiftCode'),
      placeholder: t('customerDetails.swiftCode'),
      fieldKey: 'dealerCompanyDetails.bankDetails.swiftCode',
      type: 'input',
    },
    {
      label: t('customerDetails.sepaName'),
      placeholder: t('customerDetails.sepaName'),
      fieldKey: 'sepaName',
      type: 'input',
    },
    {
      label: t('customerDetails.sepaIban'),
      placeholder: t('customerDetails.sepaIban'),
      fieldKey: 'sepaDetails.iban',
      type: 'input',
    },
    {
      label: t('customerDetails.sepaSignatureDate'),
      placeholder: t('customerDetails.sepaSignatureDate'),
      fieldKey: 'sepaDetails.clientSignatureDate',
      type: 'date',
      fieldKeyType: 'date',
    },
    {
      label: t('customerDetails.sepaClientNumber'),
      placeholder: t('customerDetails.sepaClientNumber'),
      fieldKey: 'sepaDetails.clientNumber',
      type: 'input',
    },
    {
      label: t('customerDetails.easybillDueDays'),
      placeholder: t('customerDetails.easybillDueDays'),
      fieldKey: 'easybillDueDays',
      type: 'input',
      fieldKeyType: 'number',
    },
    {
      label: t('general.moreInfo'),
      fieldKey: '',
      type: 'section-title',
    },
    {
      label: t('customerDetails.localCourt'),
      placeholder: t('customerDetails.localCourt'),
      fieldKey: 'dealerCompanyDetails.bankDetails.localCourt',
      type: 'input',
    },
    {
      label: t('customerDetails.managingDirector'),
      placeholder: t('customerDetails.managingDirector'),
      fieldKey: 'dealerCompanyDetails.additionalDetails.managingDirector',
      type: 'input',
    },
    {
      label: t('customerDetails.salesTaxId'),
      placeholder: t('customerDetails.salesTaxId'),
      fieldKey: 'federalTaxId',
      type: 'input',
    },
    {
      label: t('customerDetails.taxNumber'),
      placeholder: t('customerDetails.taxNumber'),
      fieldKey: 'commercialRegisterNumber',
      type: 'input',
    },
    {
      placeholder: t('productDetails.image'),
      fieldKey: 'logo',
      label: t('productDetails.image'),
      type: 'image',
    },
    {
      label: t('customerDetails.excludeForPaymentReminders'),
      placeholder: t('newProductForm.excludeForPaymentReminders'),
      fieldKey: 'excludeForPaymentReminders',
      type: 'checkbox',
    },
  ];

  if (customFields) {
    customFields.forEach((field: ICustomField) => {
      const type = getType(field.type);
      fields.push({
        label: field.fieldName,
        placeholder: field.fieldName,
        fieldKey: `customFields.${field.fieldName}`,
        type: type,
        ...(type === 'input' ? {fieldKeyType: field.type === ICustomFieldType.NUMBER ? 'number' : 'string'} : {}),
      });
    });
  }

  const initialValues = generateInitialValues(fields, customerObject);

  return (
    <>
      <Typography className="modal-title">{title}</Typography>
      <Form
        initialValues={initialValues}
        fields={fields}
        submitButtonText={t('general.submit')}
        cancelButtonText={t('general.cancel')}
        onSubmit={onSubmit}
        onCancel={onCancel}
      ></Form>
    </>
  );
};

export default EditCustomer;
