import {Button} from '@material-ui/core';
import React, {ReactElement, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useFetch} from '../../../hooks/fetch';
import {appActions} from '../../../modules/app/actions';
import {useDispatch} from 'react-redux';
import {modalActions} from '../../../modules/modal/actions';
import {MODALS} from '../../Modal/ModalContents';

const SupplierOrderReopenButton = (props: ISupplierOrderFinishButtonProps): ReactElement => {
  const [reopenOrder, doReopenOrder] = useFetch<any>('supplier_orders_reopen');
  const {t} = useTranslation();
  const {supplierOrderId} = props;
  const dispatch = useDispatch();

  const handleFinishOrder = () => {
    doReopenOrder({id: supplierOrderId});
    dispatch(modalActions.closeModal());
  };

  const reopenSupplierOrder = async () => {
    dispatch(
      modalActions.addModal(MODALS.CONFIRM_DIALOG, {
        title: t('general.areYouSure'),
        content: t('general.areYouSure'),
        onYes: handleFinishOrder,
        onNo: () => dispatch(modalActions.closeModal()),
      }),
    );
  };

  useEffect(() => {
    if (reopenOrder?.loaded && !reopenOrder?.error) {
      dispatch(appActions.showSnackBar({text: t('general.submitFormText'), options: {severity: 'success'}}));
      window.location.reload();
    } else if (reopenOrder?.error) {
      dispatch(appActions.showSnackBar({text: reopenOrder.error.name, options: {severity: 'error'}}));
    }

    return () => {
      reopenOrder.data = null;
      reopenOrder.error = null;
    };
  }, [reopenOrder]);

  return (
    <Button variant="contained" className="blue-button" onClick={reopenSupplierOrder}>
      {t('supplierOrderDetails.reopenOrder')}
    </Button>
  );
};

interface ISupplierOrderFinishButtonProps {
  supplierOrderId: any;
}

export default SupplierOrderReopenButton;
