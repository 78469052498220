import {Button} from '@material-ui/core';
import React, {ReactElement, useContext, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {useFetch} from '../../../hooks/fetch';
import {modalActions} from '../../../modules/modal/actions';
import ButtonListContext from '../../ButtonList/ButtonListContext';
import {MODALS} from '../../Modal/ModalContents';

interface IProps {
  id: string;
  isListItem?: boolean;
}

const OrderDeliveredConfirmationButton = ({id, isListItem}: IProps): ReactElement => {
  const [deliveryConfirmation, getDeliveryConfirmation] = useFetch<any>('orders_delivery_confirmation');
  const {hideButtonList} = useContext(ButtonListContext);
  const dispatch = useDispatch();
  const {t} = useTranslation();
  const handleCancelModal = () => {
    dispatch(modalActions.closeModal());
    hideButtonList && hideButtonList();
  };

  const handleSubmitModal = async (values: {shippingDate: string}) => {
    const {shippingDate} = values;
    getDeliveryConfirmation({id, date: shippingDate});
  };

  const handleClick = async () => {
    dispatch(
      modalActions.addModal(MODALS.DELIVERY_CONFIRMATION, {
        title: t('orderDetails.deliveryConfirmation') || '',
        onCancel: handleCancelModal,
        onSubmit: handleSubmitModal,
      }),
    );
  };

  useEffect(() => {
    if (deliveryConfirmation.data) {
      const blob = new Blob([new Uint8Array(deliveryConfirmation.data.data).buffer], {type: 'application/pdf'});
      const blobURL = URL.createObjectURL(blob);
      window.open(blobURL);
      handleCancelModal();
    }

    return () => {
      deliveryConfirmation.data = null;
    };
  }, [deliveryConfirmation.data]);

  return (
    <Button
      variant={isListItem ? 'text' : 'contained'}
      className={isListItem ? 'button-list-item' : 'blue-button'}
      onClick={handleClick}
    >
      {t('orderDetails.deliveryConfirmation')}
    </Button>
  );
};

export default OrderDeliveredConfirmationButton;
