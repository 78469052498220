import {Button} from '@material-ui/core';
import React, {ReactElement, useContext, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {useStorlessFetch, useStorlessUpload} from '../../../hooks/fetch';
import {appActions} from '../../../modules/app/actions';
import {modalActions} from '../../../modules/modal/actions';
import {IProduct} from '../../../types/Product/IProduct';
import {MODALS} from '../../Modal/ModalContents';
import _ from 'lodash';
import ProductDetailsContext from '../../../screens/Products/Context/ProductDetailsContext';

const ProductEditButton = (props: IProductEditButtonProps): ReactElement => {
  const [updateProduct, doUpdateProduct] = useStorlessFetch('product_put');
  const [uploadFile, doUploadFile] = useStorlessUpload('upload_optimized');
  const [getProductRes, getProduct] = useStorlessFetch('product_details');
  const {customFields} = useContext(ProductDetailsContext);
  const dispatch = useDispatch();
  const {t} = useTranslation();
  const {product, getProductDetails, setProduct} = props;

  const handleCancelModal = () => {
    dispatch(modalActions.closeModal());
  };

  const renameFile = (originalFile: File) => {
    const extension = originalFile.name.split('.').pop();
    const newName = product.title ?? Date.now().toString();
    return new File([originalFile], `${newName}.${extension}`, {
      type: originalFile.type,
      lastModified: originalFile.lastModified,
    });
  };

  const handleSubmitModal = async (values: any) => {
    const data = _.omit({...values, id: product._id}, 'inMultiBoxProductIds');

    if (!data.calculatedPrice) {
      data.calculatedPrice = 0;
    }

    const isFileChosen = typeof data.productInformation['image']?.name === 'string';
    if (isFileChosen) {
      if (data.productInformation?.image) {
        const image = renameFile(data.productInformation.image);
        doUploadFile({file: image});
      }
    } else {
      if (data.calculatedPrice > 0) {
        if (data.calculatedPrice < data.averagePrice || data.calculatedPrice < data.cheapestSupplierPrice) {
          if (confirm(t('productDetails.priceWarning'))) {
            doUpdateProduct(data);
          }
        } else {
          doUpdateProduct(data);
        }
      } else {
        doUpdateProduct(data);
      }
    }

    handleCancelModal();
  };

  const handleClick = async () => {
    dispatch(
      modalActions.addModal(MODALS.CREATE_PRODUCT, {
        title: t('productDetails.productFormTitle') || '',
        onSubmit: handleSubmitModal,
        onCancel: handleCancelModal,
        productObject: product,
        customFields: customFields,
      }),
    );
  };

  useEffect(() => {
    if (updateProduct.error) {
      dispatch(appActions.showSnackBar({text: updateProduct.error.name, options: {severity: 'error'}}));
    }

    if (updateProduct.data && !updateProduct.error) {
      getProductDetails({id: product._id});
      dispatch(modalActions.closeModal());
    }
  }, [updateProduct]);

  useEffect(() => {
    if (uploadFile.loaded) {
      const data = {...product, productInformation: {...product.productInformation, image: uploadFile.data}};
      setProduct(data);
      doUpdateProduct({...data, id: product._id});
    }
  }, [uploadFile]);

  useEffect(() => {
    if (updateProduct?.data) {
      dispatch(appActions.showSnackBar({text: t('general.submitFormText'), options: {severity: 'success'}}));
    } else if (updateProduct?.error) {
      dispatch(appActions.showSnackBar({text: updateProduct.error.name, options: {severity: 'error'}}));
    }

    return () => {
      updateProduct.data = null;
      updateProduct.error = null;
    };
  }, [updateProduct]);

  useEffect(() => {
    if (getProductRes.data) {
      if (getProductRes.data.updatedAt !== product.updatedAt) {
        dispatch(
          modalActions.addModal(MODALS.CONFIRM_DIALOG, {
            title: t('general.areYouSure'),
            content: t('general.productReload'),
            onYes: () => window.location.reload(),
            onNo: () => dispatch(modalActions.closeModal()),
          }),
        );
      } else {
        handleClick();
      }
    }

    return () => {
      getProductRes.data = null;
      getProductRes.error = null;
    };
  }, [getProductRes]);

  return (
    <Button variant="contained" className="blue-button" onClick={() => getProduct({id: product._id})}>
      {t('general.edit')}
    </Button>
  );
};

interface IProductEditButtonProps {
  product: IProduct;
  getProductDetails: (data: any) => void;
  setProduct: (data: any) => void;
}

export default ProductEditButton;
