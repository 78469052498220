import React from 'react';
import DataTable from '../../components/Table/Table';

import {useTranslation} from 'react-i18next';

const SupplierArticle = () => {
  const options = {status: 'approved', deletedAt: {$eq: null}};
  const {t} = useTranslation();

  return (
    <div className="supplier-articles">
      <DataTable
        className="supplier-articles__table"
        headers={[
          {
            kind: 'accessor',
            name: t('activatedArticles.supplierName'),
            accessor: 'supplierName',
            sortable: true,
          },
          {
            kind: 'accessor',
            name: t('activatedArticles.productTitle'),
            accessor: 'productTitle',
            sortable: true,
          },
          {
            kind: 'accessor',
            name: t('activatedArticles.externalArticleId'),
            accessor: 'externalArticleId',
            sortable: true,
          },
          {
            kind: 'accessor',
            name: t('activatedArticles.price'),
            accessor: 'price',
            sortable: true,
            format: 'price',
            type: 'number',
          },
          {
            kind: 'accessor',
            name: t('activatedArticles.quantity'),
            accessor: 'quantity',
            sortable: true,
            type: 'number',
          },
          {
            kind: 'accessor',
            sortable: true,
            name: t('activatedArticles.productType'),
            accessor: 'productType',
          },
        ]}
        endpoint={'supplier_articles'}
        target={'articles'}
        options={options}
        defaultSort={{key: 'createdAt', value: -1}}
        title={t('activatedArticles.screenTitle')}
      />
    </div>
  );
};

export default SupplierArticle;
