import {Table, TableBody, TableCell, TableHead, TableRow} from '@material-ui/core';
import React, {ReactElement, useContext} from 'react';
import {useTranslation} from 'react-i18next';
import OrderScanningContext from '../../../screens/Orders/Context/OrderScanningContext';

const CustomerOrderItemsTable = (): ReactElement => {
  const {product, orderItems} = useContext(OrderScanningContext);
  const {t} = useTranslation();
  const orderItemsToScan = orderItems.filter(
    (item) => item.orderItemId !== product?.orderItemId && item.quantity !== item.quantityScanned,
  );

  return product && orderItemsToScan?.length ? (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>{t('scanner.productId')}</TableCell>
          <TableCell>EAN</TableCell>
          <TableCell>{t('scanner.product')}</TableCell>
          <TableCell>{t('scanner.quantity')}</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {orderItemsToScan.map((scannedProduct) => (
          <React.Fragment key={scannedProduct.productId}>
            <TableRow>
              <TableCell>{scannedProduct.id}</TableCell>
              <TableCell>{scannedProduct.ean}</TableCell>
              <TableCell>{scannedProduct.productTitle}</TableCell>
              <TableCell>{scannedProduct.quantity}</TableCell>
            </TableRow>
          </React.Fragment>
        ))}
      </TableBody>
    </Table>
  ) : (
    <div style={{marginTop: '70px'}}>
      <hr />
    </div>
  );
};

export default CustomerOrderItemsTable;
