import {Typography} from '@material-ui/core';
import React, {useEffect} from 'react';
import Form from '../../Form/Form';
import * as Yup from 'yup';
import {useFetch} from '../../../hooks/fetch';
import {useTranslation} from 'react-i18next';

export interface IEditOrderProblemType {
  title: string;
  onSubmit: (values: any) => void;
  onCancel: () => void;
  orderProblemTypeObject?: any;
  id?: any;
}

const generateInitialValues = (fields: any[], orderObject: any) => {
  if (orderObject) {
    return orderObject;
  } else {
    let defaults = {};
    fields.forEach((field) => {
      defaults = {...defaults, [field.fieldKey]: ''};
    });
    return defaults;
  }
};

const orderSchema = Yup.object().shape({
  name: Yup.string().required('Required').nullable(),
  description: Yup.string().optional(),
});

const EditOrderProblemType = (props: IEditOrderProblemType) => {
  const {title, onSubmit, onCancel, id} = props;
  const [orderProblemTypeDetails, doOrderProblemTypeDetails] = useFetch('order_problem_type_details');
  const {t} = useTranslation();

  const fields = [
    {
      label: t('orderProblemType.name'),
      placeholder: t('orderProblemType.name'),
      fieldKey: 'name',
      type: 'input',
    },
    {
      label: t('orderProblemType.description'),
      placeholder: t('orderProblemType.description'),
      fieldKey: 'description',
      type: 'input',
    },
  ];
  let initialValues = generateInitialValues(fields, id ? orderProblemTypeDetails?.data : null);

  useEffect(() => {
    if (id) {
      doOrderProblemTypeDetails({id});
    }
  }, [id]);

  useEffect(() => {
    initialValues = generateInitialValues(fields, id ? orderProblemTypeDetails?.data : null);
  }, [orderProblemTypeDetails.loaded, id]);

  return (
    <>
      <Typography className="modal-title">{title}</Typography>
      {orderProblemTypeDetails.loaded || !id ? (
        <Form
          initialValues={initialValues}
          fields={fields}
          submitButtonText={t('general.submit')}
          cancelButtonText={t('general.cancel')}
          onSubmit={onSubmit}
          onCancel={onCancel}
          validationSchema={orderSchema}
        ></Form>
      ) : null}
    </>
  );
};

export default EditOrderProblemType;
