import React, {useEffect} from 'react';
import Form, {IField} from '../Form/Form';
import _ from 'lodash';
import {useTranslation} from 'react-i18next';
import {IOrder} from '../../types/Orders/IOrder';
import {ShippingProviderType} from '../../types/Shipment/ShippingProviderType';
import {EventsName, socketFactory} from '../../lib/socketFactory';
import {useDispatch} from 'react-redux';
import {modalActions} from '../../modules/modal/actions';
import {MODALS} from '../Modal/ModalContents';
import {EasyBillVatOption} from '../../types/Orders/EasyBillVatOption';
import {ICustomField, ICustomFieldType} from '../../types/CustomFields/CustomField';
import {getType} from '../../lib/customFields';
import {DataTableItemType} from '../Table/interfaces/IHeaderAccessor';
import {OrderStatusType} from '../../types/Orders/OrderStatusType';

export interface IEditOrder {
  title: string;
  onSubmit: (values: any) => void;
  onCancel: () => void;
  orderObject?: IOrder;
  customFields?: ICustomField[];
  editingAfterValidationFailed?: boolean;
}

const generateInitialValues = (fields: any[], orderObject: any) => {
  if (orderObject) {
    const defaults = _.omit(orderObject, ['_id', '__v']);
    return defaults;
  } else {
    let defaults = {};
    fields.forEach((field) => {
      defaults = {...defaults, [field.fieldKey]: ''};
    });
    return defaults;
  }
};

const EditOrder = (props: IEditOrder) => {
  const {title, onSubmit, onCancel, orderObject, customFields, editingAfterValidationFailed} = props;
  const {t} = useTranslation();
  const dispatch = useDispatch();

  const onOrderScanning = (orderNumber: string) => {
    if (orderNumber === orderObject?.orderNumber) {
      dispatch(
        modalActions.addChild(MODALS.CONFIRM_DIALOG, {
          title: t('general.warning'),
          content: t('general.orderIsBeingModified'),
          onOk: () => {
            dispatch(modalActions.closeModal());
          },
          hideDefaultCloseButton: true,
        }),
      );
    }
  };

  useEffect(() => {
    const orderNumber = editingAfterValidationFailed ? `VF-${orderObject?.orderNumber}` : orderObject?.orderNumber;
    socketFactory.listenOrderModifying(onOrderScanning);
    socketFactory.emitOrderModifying(orderNumber || '');

    return () => {
      socketFactory.emitStopOrderModifying(orderNumber || '');
      socketFactory.removeEventListener(EventsName.ORDER_MODIFYING);
    };
  }, []);

  const fields: IField[] = [
    {
      label: t('orderDetails.orderInfo'),
      type: 'section-title',
      fieldKey: '',
    },
    {
      label: t('orderDetails.trackingNumber'),
      placeholder: t('orderDetails.trackingNumber'),
      fieldKey: 'trackingNumber',
      type: 'input',
    },
    {
      label: t('orderDetails.secondTrackingNumber'),
      placeholder: t('orderDetails.secondTrackingNumber'),
      fieldKey: 'secondLabelInfo.trackingNumber',
      type: 'input',
    },
    {
      label: t('orderDetails.returnTrackingNumber'),
      placeholder: t('orderDetails.returnTrackingNumber'),
      fieldKey: 'returnLabelInfo.trackingNumber',
      type: 'input',
    },
    {
      label: t('orderDetails.shippingType'),
      placeholder: t('orderDetails.shippingType'),
      fieldKey: 'shippingType',
      type: 'select',
      options: Object.values(ShippingProviderType).map((item) => {
        return {value: item, label: item};
      }),
    },
    {
      label: t('orderDetails.vatOption'),
      placeholder: t('orderDetails.vatOption'),
      fieldKey: 'easyBillInfo.vatOption',
      type: 'select',
      options: Object.values(EasyBillVatOption).map((item) => {
        return {value: item, label: item};
      }),
    },
    {
      label: t('orderDetails.vatPercentage'),
      placeholder: t('orderDetails.vatPercentage'),
      fieldKey: 'vatPercentage',
      type: 'input',
      fieldKeyType: 'number',
    },
    {
      label: t('orderDetails.shippingCost'),
      placeholder: t('orderDetails.shippingCost'),
      fieldKey: 'shippingCost',
      type: 'input',
      fieldKeyType: 'number',
    },
    {
      label: t('orderDetails.manualShippingCost'),
      placeholder: t('orderDetails.manualShippingCost'),
      fieldKey: 'manualShippingCost',
      type: 'input',
      fieldKeyType: 'number',
    },
    {
      label: t('orderDetails.status'),
      placeholder: t('orderDetails.status'),
      fieldKey: 'status',
      type: DataTableItemType.SELECT,
      options: ([{value: 'all', label: 'All'}] as any).concat(
        Object.values(OrderStatusType).map((item) => {
          return {value: item, label: item};
        }),
      ),
    },
    {
      label: t('orderDetails.externalOrderNumber'),
      placeholder: t('orderDetails.externalOrderNumber'),
      fieldKey: 'externalOrderNumber',
      type: 'input',
    },
    {
      label: t('orderDetails.comment'),
      placeholder: t('orderDetails.comment'),
      fieldKey: 'comment',
      type: 'input',
    },
    {
      label: t('orderDetails.note'),
      placeholder: t('orderDetails.note'),
      fieldKey: 'note',
      type: 'input',
    },
    {
      label: t('orderDetails.shippingAddress'),
      type: 'section-title',
      fieldKey: '',
    },
    {
      label: t('shippingAddress.customerName'),
      placeholder: t('shippingAddress.customerName'),
      fieldKey: 'shippingAddress.customerName',
      type: 'input',
      itemStyle: {xs: 12, sm: 12, md: 12},
      maxChars: 35,
    },
    {
      label: t('shippingAddress.addressLine2'),
      placeholder: t('shippingAddress.addressLine2'),
      fieldKey: 'shippingAddress.addressLine2',
      type: 'input',
      itemStyle: {xs: 12, sm: 12, md: 12},
      maxChars: 35,
    },
    {
      label: t('shippingAddress.deliveryInstruction'),
      placeholder: t('shippingAddress.deliveryInstruction'),
      fieldKey: 'shippingAddress.deliveryInstruction',
      type: 'input',
      itemStyle: {xs: 12, sm: 12, md: 12},
      maxChars: 35,
    },
    {
      label: t('shippingAddress.addressLine1'),
      placeholder: t('shippingAddress.addressLine1'),
      fieldKey: 'shippingAddress.addressLine1',
      type: 'input',
      itemStyle: {xs: 12, sm: 12, md: 12},
      maxChars: 35,
    },
    {
      label: t('shippingAddress.zipCode'),
      placeholder: t('shippingAddress.zipCode'),
      fieldKey: 'shippingAddress.zip',
      type: 'input',
      itemStyle: {xs: 4, sm: 4, md: 4},
    },
    {
      label: t('shippingAddress.city'),
      placeholder: t('shippingAddress.city'),
      fieldKey: 'shippingAddress.city',
      type: 'input',
      itemStyle: {xs: 4, sm: 4, md: 4},
    },
    {
      label: t('shippingAddress.countryCode'),
      placeholder: t('shippingAddress.countryCode'),
      fieldKey: 'shippingAddress.countryCode',
      type: 'input',
      itemStyle: {xs: 4, sm: 4, md: 4},
    },
    {
      label: t('shippingAddress.postNumber'),
      placeholder: t('shippingAddress.postNumber'),
      fieldKey: 'shippingAddress.postNumber',
      type: 'input',
      itemStyle: {xs: 4, sm: 4, md: 4},
    },
    {
      label: t('shippingAddress.lockerId'),
      placeholder: t('shippingAddress.lockerId'),
      fieldKey: 'shippingAddress.lockerId',
      type: 'input',
      fieldKeyType: 'number',
      itemStyle: {xs: 4, sm: 4, md: 4},
    },
    {
      label: t('shippingAddress.retailId'),
      placeholder: t('shippingAddress.retailId'),
      fieldKey: 'shippingAddress.retailId',
      type: 'input',
      fieldKeyType: 'number',
      itemStyle: {xs: 4, sm: 4, md: 4},
    },
    {
      label: t('shippingAddress.phone'),
      placeholder: t('shippingAddress.phone'),
      fieldKey: 'shippingAddress.phone',
      type: 'input',
      itemStyle: {xs: 6, sm: 6, md: 6},
    },
    {
      label: t('shippingAddress.email'),
      placeholder: t('shippingAddress.email'),
      fieldKey: 'shippingAddress.email',
      type: 'input',
      itemStyle: {xs: 6, sm: 6, md: 6},
    },
    {
      label: t('orderDetails.billingAddress'),
      type: 'section-title',
      fieldKey: '',
    },
    {
      label: t('orderDetails.title'),
      placeholder: t('orderDetails.title'),
      fieldKey: 'billingAddress.title',
      type: 'input',
    },
    {
      label: t('orderDetails.salutation'),
      placeholder: t('orderDetails.salutation'),
      fieldKey: 'billingAddress.salutation',
      type: 'input',
    },
    {
      label: t('orderDetails.firstName'),
      placeholder: t('orderDetails.firstName'),
      fieldKey: 'billingAddress.firstName',
      type: 'input',
    },
    {
      label: t('orderDetails.lastName'),
      placeholder: t('orderDetails.lastName'),
      fieldKey: 'billingAddress.lastName',
      type: 'input',
    },
    {
      label: t('orderDetails.company'),
      placeholder: t('orderDetails.company'),
      fieldKey: 'billingAddress.company',
      type: 'input',
    },
    {
      label: t('orderDetails.addressLine1'),
      placeholder: t('orderDetails.addressLine1'),
      fieldKey: 'billingAddress.addressLine1',
      type: 'input',
    },
    {
      label: t('orderDetails.addressLine2'),
      placeholder: t('orderDetails.addressLine2'),
      fieldKey: 'billingAddress.addressLine2',
      type: 'input',
    },
    {
      label: t('orderDetails.zip'),
      placeholder: t('orderDetails.zip'),
      fieldKey: 'billingAddress.zip',
      type: 'input',
    },
    {
      label: t('orderDetails.city'),
      placeholder: t('orderDetails.city'),
      fieldKey: 'billingAddress.city',
      type: 'input',
    },
    {
      label: t('orderDetails.countryCode'),
      placeholder: t('orderDetails.countryCode'),
      fieldKey: 'billingAddress.countryCode',
      type: 'input',
    },
    {
      label: t('orderDetails.stateCode'),
      placeholder: t('orderDetails.stateCode'),
      fieldKey: 'billingAddress.stateCode',
      type: 'input',
    },
    {
      label: t('orderDetails.state'),
      placeholder: t('orderDetails.state'),
      fieldKey: 'billingAddress.state',
      type: 'input',
    },
    {
      label: t('orderDetails.phone'),
      placeholder: t('orderDetails.phone'),
      fieldKey: 'billingAddress.phone',
      type: 'input',
    },
    {
      label: t('orderDetails.fax'),
      placeholder: t('orderDetails.fax'),
      fieldKey: 'billingAddress.fax',
      type: 'input',
    },
    {
      label: t('orderDetails.email'),
      placeholder: t('orderDetails.email'),
      fieldKey: 'billingAddress.email',
      type: 'input',
    },
  ];

  if (customFields) {
    customFields.forEach((field: ICustomField) => {
      const type = getType(field.type);
      fields.push({
        label: field.fieldName,
        placeholder: field.fieldName,
        fieldKey: `customFields.${field.fieldName}`,
        type: type,
        ...(type === 'input' ? {fieldKeyType: field.type === ICustomFieldType.NUMBER ? 'number' : 'string'} : {}),
      });
    });
  }
  const initialValues = generateInitialValues(fields, orderObject);

  return (
    <Form
      title={title}
      initialValues={initialValues}
      fields={fields}
      submitButtonText={t('general.submit')}
      cancelButtonText={t('general.cancel')}
      onSubmit={onSubmit}
      onCancel={onCancel}
    ></Form>
  );
};

export default EditOrder;
