import _ from 'lodash';
import React from 'react';
import {useSelector} from 'react-redux';
import AppRouter from './AppRouter';
import AuthRouter from './AuthRouter';

const RouterSwitcher = () => {
  const loginState = useSelector((state) => _.get(state, 'app.loginState'));
  return <>{loginState === 1 ? <AuthRouter /> : loginState === 2 ? <AppRouter /> : null}</>;
};
export default RouterSwitcher;
