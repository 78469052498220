import {Button} from '@material-ui/core';
import React, {ReactElement, useContext, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useFetch} from '../../../hooks/fetch';
import {OfferTemplateType} from '../../../types/Orders/OfferTemplateType';
import ButtonListContext from '../../ButtonList/ButtonListContext';

const PdfOfferButton = (props: IPdfOfferButtonProps): ReactElement => {
  const [pdfOfferResponse, getPdfOffer] = useFetch<any>('orders_pdf_offer');
  const [openPdfOfferResponse, setOpenPdfOffer] = useState<boolean>(false);
  const {hideButtonList} = useContext(ButtonListContext);
  const {t} = useTranslation();
  const {orderId, type, save} = props;

  useEffect(() => {
    if (pdfOfferResponse.data && openPdfOfferResponse) {
      const blob = new Blob([new Uint8Array(pdfOfferResponse.data.data).buffer], {type: 'application/pdf'});
      const blobURL = URL.createObjectURL(blob);
      window.open(blobURL);
      hideButtonList && hideButtonList();
    }

    return () => {
      pdfOfferResponse.data = null;
      setOpenPdfOffer(false);
    };
  }, [pdfOfferResponse.data]);

  useEffect(() => {
    if (openPdfOfferResponse) {
      getPdfOffer({id: orderId, type: type});
    }
  }, [openPdfOfferResponse]);

  return (
    <Button
      variant={props.isListItem ? 'text' : 'contained'}
      className={props.isListItem ? 'button-list-item' : 'black-button'}
      onClick={() => {
        if (save) {
          save();
          setTimeout(() => {
            setOpenPdfOffer(true);
          }, 300);
        } else {
          setOpenPdfOffer(true);
        }
      }}
    >
      {type === OfferTemplateType.ORDER ? t('orderDetails.viewOrder') : t('createOrders.viewOffer')}
    </Button>
  );
};

interface IPdfOfferButtonProps {
  orderId: string;
  type: string;
  isListItem?: boolean;
  save?: () => void;
}

export default PdfOfferButton;
