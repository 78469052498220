import React from 'react';
import StatusButton from '../../../components/Product/ShopStatusIcon';

const ProductStatusButtons = ({product}: any) => {
  return (
    <div style={{minWidth: '210px'}}>
      <StatusButton shopProp={product.druckdichaus}></StatusButton>
      <StatusButton shopProp={product.suppliesPartner}></StatusButton>
      <StatusButton shopProp={product.ebay}></StatusButton>
      <StatusButton shopProp={product.kaufland}></StatusButton>
      <StatusButton shopProp={product.galaxus}></StatusButton>
      <StatusButton shopProp={product.metro}></StatusButton>
      <StatusButton shopProp={product.fnac}></StatusButton>
      <StatusButton shopProp={product.amazon}></StatusButton>
    </div>
  );
};

export default ProductStatusButtons;
