export enum CustomerReturnStatus {
  returnedToStock = 'Returned to stock',
  createdSupplierComplaint = 'Created supplier complaint',
}

export enum MultiBoxReturnStatus {
  returnedToStock = 'Returned to stock',
  createdSupplierComplaint = 'Created supplier complaint',
  notReturned = 'Not returned',
}
