import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Button, TextField} from '@material-ui/core';
import {appActions} from '../../modules/app/actions';
import {useDispatch} from 'react-redux';
import {useStorlessFetch} from '../../hooks/fetch';
import {modalActions} from '../../modules/modal/actions';
import {MODALS} from '../Modal/ModalContents';

type InventoryStockUpdateProps = {
  row: any;
  setActualStock: (value: number) => void;
  setTriggerRefreshTable: (value: string) => void;
};

export const InventoryStockUpdate = (props: InventoryStockUpdateProps) => {
  const [updateStock, setActualStock] = useStorlessFetch('product_set_inventory');
  const {setTriggerRefreshTable, row} = props;
  const {productId} = row;
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const [stock, setStock] = useState(row.stockDDA);

  useEffect(() => {
    setStock(row.stockDDA);
  }, [productId]);

  useEffect(() => {
    if (updateStock.loaded) {
      const triggerRefreshTableValue = `${Math.random()}-${productId}`;
      setTriggerRefreshTable(triggerRefreshTableValue);
    }

    return () => {
      updateStock.data = null;
      updateStock.error = null;
      updateStock.loaded = null;
      updateStock.loading = null;
    };
  }, [updateStock]);

  const handleKeyPress = (e: any) => {
    if (!(e.charCode >= 48 && e.charCode <= 57)) {
      e.preventDefault();
    }
  };

  const handleSettingActualStock = () => {
    setActualStock({productId: productId, actualQuantity: Number(stock)});
    dispatch(modalActions.closeModal());
    dispatch(appActions.showSnackBar({text: t('general.success'), options: {severity: 'success'}}));
  };

  const onFormSubmit = (e: any) => {
    e.preventDefault();

    dispatch(
      modalActions.addModal(MODALS.CONFIRM_DIALOG, {
        title: t('general.areYouSure'),
        content: t('general.areYouSure'),
        onYes: handleSettingActualStock,
        onNo: () => dispatch(modalActions.closeModal()),
      }),
    );
  };

  return (
    <>
      <TextField
        onClick={(e) => e.preventDefault()}
        id="outlined-number"
        label={t('inventoryProducts.countedStock')}
        type="number"
        InputLabelProps={{shrink: true}}
        style={{width: 150, marginRight: 20}}
        InputProps={{inputProps: {min: 0, step: 1}}}
        value={stock}
        onChange={(e) => setStock(e.target.value)}
        onKeyPress={handleKeyPress}
      />

      <Button variant="contained" className="blue-button" onClick={onFormSubmit}>
        {t('general.submit')}
      </Button>
    </>
  );
};
