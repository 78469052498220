import {Typography} from '@material-ui/core';
import React from 'react';
import Form from '../../Form/Form';
import {useTranslation} from 'react-i18next';

export interface IEditDhlConfig {
  title: string;
  onSubmit: (values: any) => void;
  onCancel: () => void;
  data?: any;
}

const generateInitialValues = (fields: any[], data: any) => {
  if (data) {
    return data;
  } else {
    let defaults = {};
    fields.forEach((field) => {
      defaults = {...defaults, [field.fieldKey]: ''};
    });
    return defaults;
  }
};

const EditDhlConfig = (props: IEditDhlConfig) => {
  const {title, onSubmit, onCancel, data} = props;
  const {t} = useTranslation();
  const fields = [
    {
      label: t('dhlConfiguration.shipperInfo'),
      type: 'section-title',
      fieldKey: '',
    },
    {
      label: t('dhlConfiguration.name'),
      placeholder: t('dhlConfiguration.name'),
      fieldKey: 'data.shipper.name',
      type: 'input',
    },
    {
      label: t('dhlConfiguration.phone'),
      placeholder: t('dhlConfiguration.phone'),
      fieldKey: 'data.shipper.phone',
      type: 'input',
    },
    {
      label: t('dhlConfiguration.address1'),
      placeholder: t('dhlConfiguration.address1'),
      fieldKey: 'data.shipper.address1',
      type: 'input',
    },
    {
      label: t('dhlConfiguration.city'),
      placeholder: t('dhlConfiguration.city'),
      fieldKey: 'data.shipper.city',
      type: 'input',
    },
    {
      label: t('dhlConfiguration.postalCode'),
      placeholder: t('dhlConfiguration.postalCode'),
      fieldKey: 'data.shipper.postalCode',
      type: 'input',
    },
    {
      label: t('dhlConfiguration.country'),
      placeholder: t('dhlConfiguration.country'),
      fieldKey: 'data.shipper.country',
      type: 'input',
    },
    {
      label: t('dhlConfiguration.product'),
      type: 'section-title',
      fieldKey: '',
    },
    {
      label: t('dhlConfiguration.product'),
      placeholder: t('dhlConfiguration.product'),
      fieldKey: 'data.product',
      type: 'input',
    },
    // {
    //   label: t('dhlConfiguration.europeProduct'),
    //   placeholder: t('dhlConfiguration.europeProduct'),
    //   fieldKey: 'data.europeProduct',
    //   type: 'input',
    // },
    {
      label: t('dhlConfiguration.internationalProduct'),
      placeholder: t('dhlConfiguration.internationalProduct'),
      fieldKey: 'data.internationalProduct',
      type: 'input',
    },
    {
      label: t('dhlConfiguration.dhlUnitOfMeasurement'),
      type: 'section-title',
      fieldKey: '',
    },
    {
      label: t('dhlConfiguration.unit'),
      placeholder: t('dhlConfiguration.unit'),
      fieldKey: 'data.details.weight.uom',
      type: 'input',
    },
    {
      label: t('dhlConfiguration.weight'),
      placeholder: t('dhlConfiguration.weight'),
      fieldKey: 'data.details.weight.value',
      type: 'input',
    },
    {
      label: t('dhlConfiguration.connection'),
      type: 'section-title',
      fieldKey: '',
    },
    {
      label: t('dhlConfiguration.url'),
      placeholder: t('dhlConfiguration.url'),
      fieldKey: 'data.connectionConfig.url',
      type: 'input',
    },
    {
      label: t('dhlConfiguration.url'),
      placeholder: t('dhlConfiguration.trackingUrl'),
      fieldKey: 'data.connectionConfig.trackingUrl',
      type: 'input',
    },
    {
      label: t('dhlConfiguration.username'),
      placeholder: t('dhlConfiguration.username'),
      fieldKey: 'data.connectionConfig.username',
      type: 'input',
    },
    {
      label: t('dhlConfiguration.password'),
      placeholder: t('dhlConfiguration.password'),
      fieldKey: 'data.connectionConfig.password',
      type: 'input',
    },
    {
      label: t('dhlConfiguration.consumerKey'),
      placeholder: t('dhlConfiguration.consumerKey'),
      fieldKey: 'data.connectionConfig.consumerKey',
      type: 'input',
    },
    {
      label: t('dhlConfiguration.consumerSecret'),
      placeholder: t('dhlConfiguration.consumerSecret'),
      fieldKey: 'data.connectionConfig.consumerSecret',
      type: 'input',
    },
    {
      label: t('dhlConfiguration.ekpNumber'),
      placeholder: t('dhlConfiguration.ekpNumber'),
      fieldKey: 'data.connectionConfig.ekpNumber',
      type: 'input',
    },
    {
      label: t('dhlConfiguration.billingNumber'),
      type: 'section-title',
      fieldKey: '',
    },
    {
      label: t('dhlConfiguration.billingNumber'),
      placeholder: t('dhlConfiguration.billingNumber'),
      fieldKey: 'data.connectionConfig.accountNumber',
      type: 'input',
    },
    {
      label: t('dhlConfiguration.retoureBillingNumber'),
      placeholder: t('dhlConfiguration.retoureBillingNumber'),
      fieldKey: 'data.connectionConfig.retoureBillingNumber',
      type: 'input',
    },
    // {
    //   label: t('dhlConfiguration.europeBillingNumber'),
    //   placeholder: t('dhlConfiguration.europeBillingNumber'),
    //   fieldKey: 'data.connectionConfig.europeBillingNumber',
    //   type: 'input',
    // },
    {
      label: t('dhlConfiguration.internationalBillingNumber'),
      placeholder: t('dhlConfiguration.internationalBillingNumber'),
      fieldKey: 'data.connectionConfig.internationalBillingNumber',
      type: 'input',
    },
    {
      label: t('customConfig.otherConfiguration'),
      type: 'section-title',
      fieldKey: '',
    },
    {
      label: t('dhlConfiguration.trackingDaysThreshold'),
      placeholder: t('dhlConfiguration.trackingDaysThreshold'),
      fieldKey: 'data.trackingDaysThreshold',
      type: 'input',
      fieldKeyType: 'number',
    },
  ];

  const initialValues = generateInitialValues(fields, data);

  return (
    <>
      <Typography className="modal-title">{title}</Typography>
      <Form
        initialValues={initialValues}
        fields={fields}
        submitButtonText={t('general.submit')}
        cancelButtonText={t('general.cancel')}
        onSubmit={onSubmit}
        onCancel={onCancel}
      ></Form>
    </>
  );
};

export default EditDhlConfig;
