import React, {useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {useFetch} from '../../hooks/fetch';
import {modalActions} from '../../modules/modal/actions';
import {appActions} from '../../modules/app/actions';
import {MODALS} from '../Modal/ModalContents';
import {ManualSupplierArticleModalType} from './ManualSupplierArticleModal';
import {Button, IconButton} from '@material-ui/core';
import {Clear} from '@material-ui/icons';

const UpdateManualSupplierArticleButton: React.FC<IActionsProps> = (props: any) => {
  const {setTriggerRefreshTable} = props;
  const dispatch = useDispatch();
  const {t} = useTranslation();
  const [updateSupplierArticle, doUpdateSupplierArticle] = useFetch<any>('update_manual_supplier_article');
  const [deleteSupplierArticle, doDeleteSupplierArticle] = useFetch<any>('supplier_articles_delete');

  const handleCancelModal = () => {
    dispatch(modalActions.closeModal());
  };

  useEffect(() => {
    if (deleteSupplierArticle?.error) {
      dispatch(appActions.showSnackBar({text: deleteSupplierArticle.error.name, options: {severity: 'error'}}));
    } else if (deleteSupplierArticle?.data) {
      dispatch(appActions.showSnackBar({text: t('general.success'), options: {severity: 'success'}}));
      setTriggerRefreshTable(Math.random());
    }

    return () => {
      deleteSupplierArticle.data = null;
      deleteSupplierArticle.error = null;
      deleteSupplierArticle.loaded = null;
    };
  }, [deleteSupplierArticle]);

  useEffect(() => {
    if (updateSupplierArticle?.error) {
      dispatch(appActions.showSnackBar({text: updateSupplierArticle.error.name, options: {severity: 'error'}}));
    } else if (updateSupplierArticle?.data) {
      dispatch(appActions.showSnackBar({text: t('general.success'), options: {severity: 'success'}}));
      setTriggerRefreshTable(Math.random());
    }

    return () => {
      updateSupplierArticle.data = null;
      updateSupplierArticle.error = null;
      updateSupplierArticle.loaded = null;
    };
  }, [updateSupplierArticle]);

  const handleSubmitModal = async (values: any) => {
    const data = {...values};
    await doUpdateSupplierArticle({...data, id: props.row?._id.toString()});
    setTriggerRefreshTable(Math.random());
    handleCancelModal();
  };

  const openEditModal = (e: any) => {
    e.preventDefault();
    dispatch(
      modalActions.addModal(MODALS.MANUAL_SUPPLIER_ARTICLE_MODAL, {
        onSubmit: handleSubmitModal,
        onCancel: handleCancelModal,
        type: ManualSupplierArticleModalType.UPDATE,
        defaultValues: {quantity: props.row?.quantity, price: props.row?.price},
      }),
    );
  };

  const handleDeleteArticle = () => {
    doDeleteSupplierArticle({id: props.id});
    dispatch(modalActions.closeModal());
  };

  const deleteArticle = (e: any) => {
    e.preventDefault();
    dispatch(
      modalActions.addModal(MODALS.CONFIRM_DIALOG, {
        title: t('general.areYouSure'),
        content: t('general.areYouSure'),
        onYes: handleDeleteArticle,
        onNo: () => dispatch(modalActions.closeModal()),
      }),
    );
  };

  return (
    <div>
      <Button variant="contained" className="blue-button" onClick={openEditModal}>
        {t('manualArticles.update')}
      </Button>
      <IconButton style={{marginLeft: '12px'}} onClick={deleteArticle} title={t('general.delete')}>
        <Clear fontSize="small" style={{fill: 'red'}}></Clear>
      </IconButton>
    </div>
  );
};

interface IActionsProps {
  id: string;
  setTriggerRefreshTable: (param: any) => void;
}

export default UpdateManualSupplierArticleButton;
