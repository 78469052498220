import {Button} from '@material-ui/core';
import React, {ReactElement, useContext, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useFetch} from '../../../hooks/fetch';
import OrderDetailsContext from '../../../screens/Orders/Context/OrderDetailsContext';
import {OrderStatusType} from '../../../types/Orders/OrderStatusType';

interface IProps {
  parcelIndex?: number;
}

const DeliveryBillButton = ({parcelIndex = -1}: IProps): ReactElement => {
  const [deliveryBillPdf, getDeliveryBillPdf] = useFetch('orders_delivery_bill');
  const [printDeliveryBill, setPrintDeliveryBill] = useState<boolean>(false);
  const {t} = useTranslation();
  const {order} = useContext(OrderDetailsContext);

  useEffect(() => {
    if (deliveryBillPdf.data && printDeliveryBill) {
      const blob = new Blob([new Uint8Array(deliveryBillPdf.data.data).buffer], {type: 'application/pdf'});
      const blobURL = URL.createObjectURL(blob);
      window.open(blobURL);
    }

    return () => {
      deliveryBillPdf.data = null;
      setPrintDeliveryBill(false);
    };
  }, [deliveryBillPdf.data]);

  useEffect(() => {
    if (printDeliveryBill) {
      getDeliveryBillPdf({id: order._id, parcelIndex});
    }
  }, [printDeliveryBill]);

  return order.status === OrderStatusType.Completed ? (
    <Button variant="contained" className="blue-button" onClick={() => setPrintDeliveryBill(true)}>
      {t('orderDetails.deliveryBill')}
    </Button>
  ) : (
    <></>
  );
};

export default DeliveryBillButton;
