import React, {useEffect, useState} from 'react';
import {Button, Grid, TextField, Typography} from '@material-ui/core';
import Cron from 'react-js-cron';
import {useTranslation} from 'react-i18next';
import {ISyncStatus} from '../../types/SyncStatus/SyncStatus';
import {useStorlessFetch} from '../../hooks/fetch';
import 'react-js-cron/dist/styles.css';
import cronstrue from 'cronstrue';
import {appActions} from '../../modules/app/actions';
import {useDispatch} from 'react-redux';
import {modalActions} from '../../modules/modal/actions';

interface IProps {
  syncStatus: ISyncStatus;
  callback: any;
}

const EditCronConfigModal: React.FC<IProps> = ({syncStatus, callback}) => {
  const {t} = useTranslation();
  const [cronConfigTime, setCronConfigTime] = useState(syncStatus.cronConfigTime);
  const [syncConfigRes, updateSyncConfig] = useStorlessFetch('sync_status_config_update');
  const [cronDescription, setCronDescription] = useState('');
  const [expectedDurationMinutes, setExpectedDurationInMinutes] = useState(syncStatus.expectedDurationMinutes || 0);
  const dispatch = useDispatch();

  const handleSave = () => {
    updateSyncConfig({syncName: syncStatus.syncName, cronConfigTime, cronDescription, expectedDurationMinutes});
  };

  useEffect(() => {
    try {
      const description = cronstrue.toString(cronConfigTime);
      setCronDescription(description);
    } catch (error) {
      setCronDescription('Invalid Cron Expression');
    }
  }, [cronConfigTime]);

  useEffect(() => {
    if (syncConfigRes.data) {
      dispatch(appActions.showSnackBar({text: t('general.success'), options: {severity: 'success'}}));
      callback && callback();
      dispatch(modalActions.closeModal());
    } else if (syncConfigRes.error) {
      dispatch(appActions.showSnackBar({text: t('general.error'), options: {severity: 'error'}}));
    }
  }, [syncConfigRes]);

  const onChangeExpectedDuration = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const value = parseInt(e.target.value, 10);
    if (!isNaN(value) && value >= 0) {
      setExpectedDurationInMinutes(value);
    }
  };

  return (
    <div style={{width: '600px'}}>
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <Typography className="modal-title" variant="h6">
            {syncStatus.aliasName}
          </Typography>
        </Grid>
        <Grid container className="modal-fields">
          <Grid item xs={12}>
            <Cron value={cronConfigTime} setValue={setCronConfigTime} />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Cron Description"
              value={cronDescription}
              fullWidth
              variant="outlined"
              InputProps={{
                readOnly: true,
              }}
            />
          </Grid>
          <Grid item xs={6} style={{marginTop: '10px'}}>
            <TextField
              label="Expected duration in minutes"
              value={expectedDurationMinutes}
              fullWidth
              variant="outlined"
              onChange={onChangeExpectedDuration}
              type="number"
            />
          </Grid>
        </Grid>

        <Grid
          container
          xs={12}
          alignItems="center"
          justifyContent="flex-end"
          className="action-buttons"
          style={{paddingRight: '10px'}}
        >
          <Button type="submit" variant="contained" className="blue-button" onClick={handleSave}>
            {t('general.save')}
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};

export default EditCronConfigModal;
