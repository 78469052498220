import {Grid, Typography, TextField, Checkbox} from '@material-ui/core';
import React, {ReactElement, useContext} from 'react';
import {modalActions} from '../../../modules/modal/actions';
import CloseIcon from '@material-ui/icons/Close';
import {useDispatch} from 'react-redux';
import {useTranslation} from 'react-i18next';
import ReturnOrderScanningContext from '../../../screens/Orders/Context/ReturnOrderScanningContext ';

const ScannedReturnedItemInfo = (): ReactElement => {
  const dispatch = useDispatch();
  const {t} = useTranslation();
  const {
    product,
    refundShippingCosts,
    skipCreationOfCreditNote,
    setBarcode,
    setSkipCreationOfCreditNote,
    setRefundShippingCosts,
    setDiscount,
  } = useContext(ReturnOrderScanningContext);
  const onChangeBarcodeHandler = (e: any) => setBarcode(e.target.value);
  const onChangeDiscount = (e: any) => setDiscount(+e.target.value);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12} alignItems="flex-end">
        <div style={{width: '100%'}}>
          <CloseIcon
            onClick={() => dispatch(modalActions.closeModal())}
            style={{float: 'right', cursor: 'pointer', margin: '10px'}}
          />
        </div>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Grid item xs={12} sm={12}>
          <Grid item xs={12} sm={12}>
            <h2 style={{textAlign: 'center'}}>{product?.productTitle || ''}</h2>
          </Grid>
          <Grid item xs={12} sm={12}>
            <div style={{textAlign: 'left'}}>
              {!product ? (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '200px',
                  }}
                >
                  <Typography
                    style={{
                      textAlign: 'center',
                      width: '100%',
                    }}
                  >
                    {t('scanner.barCodeInfo')}
                  </Typography>
                </div>
              ) : (
                <Grid container item xs={12} sm={12} spacing={1}>
                  <Grid item xs={12} sm={6} style={{alignSelf: 'center', textAlign: 'center'}}>
                    <img
                      src={product.productImage ?? '/images/no_image2.webp'}
                      style={{maxWidth: '100%', maxHeight: '100%'}}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <p style={{whiteSpace: 'pre-line'}}>
                      {t('productDetails.manufacturer')}: {product.productInformation?.manufacturer + '\n'}
                      {t('productDetails.compatibility')}: {product.productInformation?.compatibility + '\n'}
                      {t('productDetails.content')}: {product.productInformation?.content + '\n'}
                      {t('productDetails.colour')}: {product.productInformation?.colour + '\n'}
                      {t('productDetails.numberOfItems')}: {product.productInformation?.numberOfItems + '\n'}
                      {t('productDetails.pageYield')}: {product.productInformation?.pageYield + '\n'}
                      EAN: {product.ean + '\n'}
                    </p>
                  </Grid>
                </Grid>
              )}
            </div>
          </Grid>
        </Grid>
      </Grid>
      <Grid container item xs={12} sm={6} spacing={2} alignItems="center">
        <Grid container spacing={2} style={{marginTop: '10px'}}>
          <Grid item xs={12} sm={12}>
            <TextField
              onChange={onChangeBarcodeHandler}
              id="input-bar-code"
              placeholder={t('scanner.inputBarcodeLabel')}
              label={t('scanner.barCode')}
              variant="outlined"
              autoFocus
            />

            <Grid container spacing={2} style={{marginTop: '10px'}}>
              <Grid item xs={4} sm={4}>
                <TextField
                  onChange={onChangeDiscount}
                  type="number"
                  id="discount"
                  placeholder={t('scanner.discount')}
                  label={t('scanner.discount')}
                  variant="outlined"
                  InputProps={{inputProps: {min: 0, max: 100, step: 1}}}
                />
              </Grid>
              <Grid item xs={4} sm={4}>
                {t('scanner.refundShippingCosts')}:
                <Checkbox
                  color="primary"
                  checked={refundShippingCosts}
                  onChange={(e) => {
                    setRefundShippingCosts(e.target.checked);
                  }}
                ></Checkbox>
              </Grid>
              <Grid item xs={4} sm={4}>
                {t('scanner.skipCreationOfCreditNote')}:
                <Checkbox
                  color="primary"
                  checked={skipCreationOfCreditNote}
                  onChange={(e) => {
                    setSkipCreationOfCreditNote(e.target.checked);
                  }}
                ></Checkbox>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ScannedReturnedItemInfo;
