import {Typography} from '@material-ui/core';
import React, {ReactElement} from 'react';
import Form from '../Form/Form';
import {useTranslation} from 'react-i18next';
import * as Yup from 'yup';

const FinishOrderManuallyModal = (props: IFinishOrderManuallyModal): ReactElement => {
  const {onSubmit, onCancel, supplierId, defaultValues} = props;
  const {t} = useTranslation();

  const generateInitialValues = (fields: any[]) => {
    let defaults = {};
    fields.forEach((field) => {
      defaults = {...defaults, [field.fieldKey]: defaultValues ? defaultValues[field.fieldKey] ?? '' : ''};
    });
    return defaults;
  };

  const fields: any = [
    {
      label: t('supplierOrderDetails.supplier'),
      placeholder: t('supplierOrderDetails.supplier'),
      fieldKey: 'supplier',
      type: 'autocomplete',
      endpoint: 'supplier_autocomplete_list',
      options: [],
      required: true,
    },
  ];

  const initialValues = generateInitialValues(fields);

  const validationSchema = Yup.object().shape({
    supplier: !supplierId ? Yup.string().required('Required').nullable() : Yup.string().optional(),
  });

  return (
    <>
      <Typography className="modal-title">{t('orders.finishOrder')}</Typography>
      <Form
        initialValues={initialValues}
        fields={fields}
        submitButtonText={t('general.submit')}
        cancelButtonText={t('general.cancel')}
        onSubmit={onSubmit}
        onCancel={onCancel}
        validationSchema={validationSchema}
      ></Form>
    </>
  );
};

export interface IFinishOrderManuallyModal {
  onSubmit: (values: any) => void;
  onCancel: () => void;
  supplierId?: string;
  defaultValues?: any;
}

export default FinishOrderManuallyModal;
