import React, {ReactElement, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {ProductActionType} from '../../types/ActivityLog/ProductActionType';
import DataTable from '../Table/Table';
import {DataTableItemType} from '../Table/interfaces/IHeaderAccessor';
import AddCommentToProduct from './AddCommentToProduct';
import {Button} from '@material-ui/core';
import {useDispatch} from 'react-redux';
import {modalActions} from '../../modules/modal/actions';
import {MODALS} from '../Modal/ModalContents';

const ProductActivityLog = (props: any): ReactElement => {
  const [triggerRefreshTable, setTriggerRefreshTable] = useState<any>(null);
  const dispatch = useDispatch();
  const {t} = useTranslation();

  const onDetailsClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, item: any): void => {
    e.preventDefault();
    e.stopPropagation();

    const renderUpdatedFields = item.data?.updatedFields?.map((field: any, index: number) => (
      <div key={index}>
        <p>Field Name: {t(`productDetails.${field.field.replaceAll('productInformation.', '')}`)} </p>
        <p style={{color: 'red'}}>
          {t('activityLog.oldValue')}: {field.oldValue?.toString()}
        </p>
        <p style={{color: 'green'}}>
          {t('activityLog.newValue')}: {field.newValue?.toString()}
        </p>
        <br />
      </div>
    ));

    dispatch(
      modalActions.addChild(MODALS.CONFIRM_DIALOG, {
        title: t('general.details'),
        content: (
          <div>
            <p>
              {t('activityLog.user')} {item.data.user}
            </p>
            <br />
            {renderUpdatedFields}
          </div>
        ),
        width: '500px',
        onOk: () => dispatch(modalActions.closeModal()),
      }),
    );
  };

  return (
    <DataTable
      className="orders__table"
      headers={[
        {
          kind: 'accessor',
          name: t('activityLog.actionType'),
          accessor: 'actionType',
          sortable: true,
          type: DataTableItemType.SELECT,
          options: ([{value: 'all', label: 'All'}] as any).concat(
            Object.values(ProductActionType).map((item) => {
              return {value: item, label: t(`activityLog.${item}`)};
            }),
          ),
          translationPrefixKey: 'activityLog',
        },
        {
          kind: 'accessor',
          name: t('orderDetails.orderNumber'),
          accessor: 'data.orderNumber',
          sortable: true,
        },
        {
          kind: 'accessor',
          name: t('activityLog.date'),
          accessor: 'createdAt',
          sortable: true,
          type: DataTableItemType.DATE,
        },
        {
          kind: 'accessor',
          name: t('activityLog.quantity'),
          accessor: 'data.quantity',
          sortable: true,
        },
        {
          kind: 'accessor',
          name: t('activityLog.price'),
          accessor: 'data.price',
          sortable: true,
        },
        {
          kind: 'accessor',
          name: t('activityLog.comment'),
          accessor: 'data.comment',
          sortable: false,
        },
        {
          kind: 'accessor',
          name: '',
          accessor: '_id',
          type: DataTableItemType.CALLBACK,
          callback: (item: any) => {
            return item.data?.updatedFields?.length ? (
              <Button
                variant="contained"
                color="primary"
                className="blue-button"
                onClick={(e) => onDetailsClick(e, item)}
              >
                {t('general.details')}
              </Button>
            ) : (
              <></>
            );
          },
        },
      ]}
      endpoint={'activity_log_list'}
      options={{'data.product': props.productId}}
      defaultSort={{key: 'createdAt', value: -1}}
      refreshData={triggerRefreshTable}
      actionButtons={
        <>
          <AddCommentToProduct productId={props.productId} refresh={setTriggerRefreshTable} />
        </>
      }
    />
  );
};

export default ProductActivityLog;
