import React from 'react';
import {ShopType} from '../../types/Orders/ShopType';
import {ProductStatus} from '../../types/Product/ProductStatus.enum';

export default function StatusButton({shopProp}: IProps) {
  const {shopName, shopwareId, status} = shopProp;
  const getShopLabel = (shopName: string) => {
    switch (shopName) {
      case ShopType.DRUCKDICHAUS:
        return 'DDA';
      case ShopType.SUPPLIES_PARTNER:
        return 'SP';
      case ShopType.AMAZON:
        return 'AMZ';
      case ShopType.EBAY:
        return 'EBY';
      case ShopType.KAUFLAND:
        return 'KFL';
      case ShopType.GALAXUS:
        return 'GLX';
      case ShopType.METRO:
        return 'MTR';
      case ShopType.FNAC:
        return 'FNC';
    }
  };
  const shopLabel = getShopLabel(shopName);
  const backgroundColors: {[key: string]: string} = {
    [ProductStatus.ACTIVE]: '#10b981',
    [ProductStatus.END_OF_LIFE]: '#f59e0b',
    [ProductStatus.DELETED]: '#dc2626',
    [ProductStatus.DEACTIVATED]: '#dc2626',
    'not-on-shopware': '#4b5563',
    default: '#dc2626',
  };

  const getProductStatus = () => {
    if ([ShopType.DRUCKDICHAUS, ShopType.SUPPLIES_PARTNER].includes(shopName as any) && !shopwareId) {
      return 'not-on-shopware';
    }
    return status;
  };
  const productStatus = getProductStatus();
  const backgroundColor: string = backgroundColors[productStatus] || backgroundColors.default;

  return (
    <div style={{backgroundColor}} className="status-button" title={status}>
      {shopLabel}
    </div>
  );
}

interface IProps {
  shopProp: IShopStatusProps;
}

interface IShopStatusProps {
  shopName: string;
  excludePricing?: boolean;
  shopwareId?: string;
  status: string;
}
