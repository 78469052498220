import {useTranslation} from 'react-i18next';
import {ITodoScreen, ScreenType} from '../../types/Todo/ITodoScreen';

export const screens: ITodoScreen[] = [
  {
    id: 1,
    name: 'default',
    title: 'todos.title',
    showInHeader: true,
    type: ScreenType.LIST,
  },
  {
    id: 2,
    name: 'upcoming',
    title: 'todos.upcoming',
    showInHeader: true,
    type: ScreenType.UPCOMING,
  },
  {
    id: 3,
    name: 'serial',
    title: 'todos.serial',
    showInHeader: true,
    type: ScreenType.SERIAL,
  },
  {
    id: 4,
    name: 'completed',
    title: 'todos.completed',
    showInHeader: true,
    type: ScreenType.COMPLETED,
  },
  {
    id: 5,
    name: 'search',
    title: 'todos.search',
    showInHeader: false,
    type: ScreenType.SEARCH,
  },
];
