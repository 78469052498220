import {Button} from '@material-ui/core';
import {IHeader} from '../interfaces/IHeader';
import React from 'react';

interface IProps {
  headers: IHeader[];
  options: any;
  filter: any;
  refreshTable: any;
  queryButtons?: any[];
}

const QueryButtonsComponents = ({headers, options, filter, refreshTable, queryButtons}: IProps) => {
  const handleClickButton = (item: any) => {
    return item.callback({
      headers,
      options,
      filters: filter,
      refreshTable,
    });
  };

  // eslint-disable-next-line react/prop-types
  if (queryButtons && queryButtons.length > 0) {
    {
      return (
        <>
          {
            // eslint-disable-next-line react/prop-types
            queryButtons.map((item, index) => {
              return (
                <Button
                  disabled={item?.isLoading}
                  key={index}
                  onClick={() => handleClickButton(item)}
                  variant="contained"
                  className={item?.isLoading ? 'inactive-button' : 'blue-button'}
                >
                  {item.name}
                </Button>
              );
            })
          }
        </>
      );
    }
  }
  return <></>;
};

export default QueryButtonsComponents;
