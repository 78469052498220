import {Button} from '@material-ui/core';
import React, {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {MODALS} from '../../components/Modal/ModalContents';
import DataTable from '../../components/Table/Table';
import withRoleGuard from '../../guards/Role-Guard';
import {useFetch} from '../../hooks/fetch';
import {useSelection} from '../../hooks/selection';
import {appActions} from '../../modules/app/actions';
import {modalActions} from '../../modules/modal/actions';
import {ProductStatus} from '../../types/Product/ProductStatus.enum';
import {USER_ROLE} from '../../types/User/UserRole';

const EndOfLifeProduct = () => {
  const selectionKey = 'DEACTIVATED_PRODUCT';
  const [activatedResponse, activateProducts] = useFetch('update_product_status');
  const [selectedValues, setSelectedValues] = useSelection(selectionKey);
  const dispatch = useDispatch();
  const {t} = useTranslation();

  useEffect(() => {
    if (activatedResponse.error) {
      dispatch(appActions.showSnackBar({text: t('activatedArticles.failedSyncMessage'), options: {severity: 'error'}}));
    }

    if (activatedResponse.loaded && !activatedResponse.error) {
      dispatch(
        appActions.showSnackBar({text: t('activatedArticles.successSyncMessage'), options: {severity: 'success'}}),
      );
    }

    return () => {
      activatedResponse.data = null;
      activatedResponse.error = null;
      activatedResponse.loaded = null;
    };
  }, [activatedResponse]);

  const onActivateProducts = async (selectedShops: string[]) => {
    await activateProducts({status: ProductStatus.ACTIVE, product: selectedValues, shopIds: selectedShops});
    dispatch(modalActions.closeModal());
  };

  const onDeactivateProducts = async (selectedShops: string[]) => {
    await activateProducts({status: ProductStatus.DEACTIVATED, product: selectedValues, shopIds: selectedShops});
    dispatch(modalActions.closeModal());
  };

  const handleCancelModal = () => dispatch(modalActions.closeModal());

  const onActivateProductsHandler = () => {
    dispatch(
      modalActions.addModal(MODALS.ALERT_DIALOG, {
        title: t('products.confirmTitle') || '',
        description: t('products.confirmationMessage'),
        onSubmit: onActivateProducts,
        onClose: handleCancelModal,
        showShopSelection: true,
        onOpen: true,
      }),
    );
  };

  const onDeactivateProductsHandler = () => {
    dispatch(
      modalActions.addModal(MODALS.ALERT_DIALOG, {
        title: t('products.confirmTitle') || '',
        description: t('products.confirmationMessage'),
        onSubmit: onDeactivateProducts,
        onClose: handleCancelModal,
        showShopSelection: true,
        onOpen: true,
      }),
    );
  };

  const ActivateButton = () => (
    <Button variant="contained" className="blue-button" onClick={onActivateProductsHandler}>
      {t('endOfLifeProducts.activate')}
    </Button>
  );

  const DeactivateButton = () => (
    <Button variant="contained" className="blue-button" onClick={onActivateProductsHandler}>
      {t('endOfLifeProducts.deactivate')}
    </Button>
  );

  const ActivateButtonWithGuard = withRoleGuard([USER_ROLE.ADMIN, USER_ROLE.STANDARD], ActivateButton, false);

  const DeactivateButtonWithGuard = withRoleGuard([USER_ROLE.ADMIN, USER_ROLE.STANDARD], DeactivateButton, false);

  return (
    <div className="deactivated-products">
      <DataTable
        className="deactivated-products__table"
        headers={[
          {
            kind: 'accessor',
            name: t('deactivatedProducts.productId'),
            accessor: 'productId',
            sortable: true,
            type: 'number',
          },
          {
            kind: 'accessor',
            name: t('deactivatedProducts.title'),
            accessor: 'title',
            sortable: true,
          },
          {
            kind: 'accessor',
            name: t('deactivatedProducts.manufacturer'),
            accessor: 'productInformation.manufacturer',
            sortable: true,
          },
          {
            kind: 'accessor',
            name: t('deactivatedProducts.colour'),
            accessor: 'productInformation.colour',
            sortable: true,
          },
        ]}
        canShowCheckbox={true}
        selectionKey={selectionKey}
        refreshData={activatedResponse.loaded}
        target={'products'}
        endpoint={'products_list'}
        options={{'shopProps.status': ProductStatus.END_OF_LIFE}}
        defaultSort={{key: 'productId', value: 1}}
        title={t('endOfLifeProducts.screenTitle')}
        actionButtons={
          <>
            <ActivateButtonWithGuard />
            <DeactivateButtonWithGuard />
          </>
        }
      />
    </div>
  );
};

export default EndOfLifeProduct;
