import React, {useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {useFetch} from '../../hooks/fetch';
import {Link} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

import {AUTH_ROUTES} from '../../routers/routes';
import {appActions} from '../../modules/app/actions';
import {Button} from '@material-ui/core';

const Login = () => {
  const [loginValues, setLoginValues] = useState({email: '', password: '', token: ''});
  const {t} = useTranslation('translation');
  const dispatch = useDispatch();
  const [loginResponse, doLogin] = useFetch<any>('login');
  const [tokenResponse, verifyToken] = useFetch<any>('verify_token');
  const [isTokenSent, setIsTokenSent] = useState(false);

  const handleKeyDown = (e: any) => {
    if (e.key === 'Enter') {
      if (!isTokenSent) {
        doLogin(loginValues);
      } else {
        verifyToken(loginValues);
      }
    }
  };

  useEffect(() => {
    if (loginResponse.error) {
      dispatch(appActions.showSnackBar({text: t('login.error'), options: {severity: 'error'}}));
    }
    if (loginResponse.data === true) {
      setIsTokenSent(true);
    }
  }, [loginResponse]);

  useEffect(() => {
    if (tokenResponse.error || (tokenResponse.data?.status === 400 && !tokenResponse.data?.token)) {
      dispatch(appActions.showSnackBar({text: t('login.tokenError'), options: {severity: 'error'}}));
    }

    if (tokenResponse.data?.token) {
      dispatch(appActions.showSnackBar({text: t('login.successLogin'), options: {severity: 'success'}}));
    }

    return () => {
      loginResponse.data = null;
      loginResponse.error = null;
      tokenResponse.data = null;
      tokenResponse.error = null;
    };
  }, [tokenResponse]);

  return (
    <div className="login">
      <div className="login__wrapper">
        <div className="login__wrapper__left">
          <img src="/images/dda_logo.webp" />
          <h2>{t('login.title')}</h2>
          <p>{t('login.paragraph')}</p>
        </div>
        <div className="login__wrapper__right">
          {!isTokenSent ? (
            <>
              <input
                className="login__wrapper__right__input"
                name="email"
                placeholder={t('login.emailAddress')}
                value={loginValues.email}
                onChange={(e) => setLoginValues({...loginValues, email: e.target.value})}
                type="email"
                required
                onKeyDown={handleKeyDown}
              />
              <input
                name="password"
                type="password"
                placeholder={t('login.password')}
                value={loginValues.password}
                onChange={(e) => setLoginValues({...loginValues, password: e.target.value})}
                required
                onKeyDown={handleKeyDown}
              />
            </>
          ) : (
            <input
              name="token"
              placeholder="Token"
              value={loginValues.token}
              onChange={(e) => setLoginValues({...loginValues, token: e.target.value})}
              required
              onKeyDown={handleKeyDown}
            />
          )}
          <Button
            variant="outlined"
            className="blue-button"
            onClick={() => (!isTokenSent ? doLogin(loginValues) : verifyToken(loginValues))}
          >
            {t('login.signIn')}
          </Button>

          <Link className="login__wrapper__right__forgot__pass" to={AUTH_ROUTES.FORGOT_PASSWORD}>
            {t('login.forgotPassword')}
          </Link>
        </div>
      </div>
    </div>
  );
};
export default Login;
