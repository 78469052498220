import {ModalAction, MODAL} from './actions';
import _ from 'lodash';
import {MODALS, ModalProps} from '../../components/Modal/ModalContents';

export interface BaseModalState {
  modalKey: MODALS;
  modalTitle: string | null;
  modalStatus: 'submited' | 'canceled' | null;
  modalProps: Partial<ModalProps>;
  children?: BaseModalState[];
}
export type IModal = {
  modal: BaseModalState;
  leftDrawer: BaseModalState;
  rightDrawer: BaseModalState;
};
export type ModalState = IModal;
const INITIAL_STATE_MODAL: BaseModalState = {
  modalKey: 'NONE' as MODALS,
  modalTitle: null,
  modalStatus: null,
  modalProps: {},
  children: [],
};
export const INITIAL_STATE: ModalState = {
  modal: INITIAL_STATE_MODAL,
  leftDrawer: INITIAL_STATE_MODAL,
  rightDrawer: INITIAL_STATE_MODAL,
};

const modalReducer = (state = INITIAL_STATE, action: ModalAction) => {
  switch (action.type) {
    case MODAL.ADD:
      return {
        ...state,
        modal: {
          modalKey: action.payload.key,
          modalTitle: action.payload.title,
          props: action.payload.props,
        },
      };
    case MODAL.CLOSE:
      if (!action.payload.childKey)
        return {
          ...state,
          modal: {
            modalKey: MODALS.NONE,
            modalTitle: null,
            modalStatus: action.payload.status,
            props: {},
          },
        };
      return {
        ...state,
        modal: {
          ...state.modal,
          children: (state.modal.children || []).filter((el: any) => el.modalKey !== action.payload.childKey),
        },
      };
    case MODAL.ADD_CHILD:
      return {
        ...state,
        modal: {
          ...state.modal,
          children: [
            ...(state.modal.children || []),
            {
              modalKey: action.payload.key,
              modalTitle: action.payload.title,
              props: action.payload.props,
            },
          ],
        },
      };
    case MODAL.ADD_RIGHT_DRAWER:
      return {
        ...state,
        rightDrawer: {
          modalKey: action.payload.key,
          modalTitle: action.payload.title,
          props: action.payload.props,
        },
      };
    case MODAL.CLOSE_RIGHT_DRAWER:
      return {
        ...state,
        rightDrawer: {
          ...INITIAL_STATE_MODAL,
        },
      };
    case MODAL.ADD_LEFT_DRAWER:
      return {
        ...state,
        leftDrawer: {
          modalKey: action.payload.key,
          modalTitle: action.payload.title,
          props: action.payload.props,
        },
      };
    // case MODAL.CLOSE_LEFT_DRAWER:
    //   return {
    //     ...state,
    //     leftDrawer: {
    //       ...INITIAL_STATE_MODAL,
    //     },
    //   };
    default:
      return state;
  }
};

export {modalReducer};
