import {Chip, TextField} from '@material-ui/core';
import React, {useEffect, useState} from 'react';

interface IchipsInput {
  id?: string;
  placeholder?: string | undefined;
  label?: string;
  variant?: 'outlined';
  onChange: (value: string[]) => void;
  value: string[];
  disabled?: boolean;
}

const chipsInput = (props: IchipsInput) => {
  const {id, onChange, variant, placeholder, label, value, disabled} = props;
  const [input, setInput] = useState('');
  const [chipData, setChipData] = useState<string[]>([]);
  const [error, setError] = useState<string>('');

  const handleDelete = (chipToDelete: string) => () => {
    const temp = chipData.filter((chip) => chip !== chipToDelete);
    setChipData(temp);
    onChange(temp);
  };

  useEffect(() => {
    if (value) {
      setChipData(value);
    }
  }, [value]);

  const keyPress = async (e: any) => {
    if (e.keyCode === 13) {
      e.preventDefault();
      if (!e.target.value) {
        setError('Input cannot be empty!');
        return;
      }
      if (chipData.includes(e.target.value)) {
        setError('Item already exists!');
        return;
      } else {
        setError('');
      }
      const temp = chipData.concat(e.target.value);
      setInput('');
      setChipData(temp);
      onChange(temp);
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInput(e.target.value);
    if (error) {
      setError('');
    }
  };

  return (
    <div className="chips-input__container">
      <TextField
        size="small"
        value={input}
        id={`${id}Input`}
        name={`${id}Input`}
        variant={variant}
        placeholder={placeholder || ''}
        label={label}
        error={!!error}
        helperText={error}
        onKeyDown={keyPress}
        onChange={handleChange}
        disabled={disabled}
      ></TextField>
      <span>
        {' '}
        {chipData.map((data, index) => {
          return (
            <Chip
              className="chips-input__chip"
              id={`${id}Chip${index}`}
              key={`${id}Chip${index}`}
              label={data}
              onDelete={handleDelete(data)}
              disabled={disabled}
            />
          );
        })}
      </span>
    </div>
  );
};

export default chipsInput;
