import React, {ReactElement, useEffect, useState} from 'react';
import {useFetch} from '../../hooks/fetch';
import {useTranslation} from 'react-i18next';
import {Box, Button, Tab, Tabs} from '@material-ui/core';
import {ISupplierOrder} from '../../types/SupplierOrders/ISupplierOrder';
import {TabContext, TabPanel} from '@material-ui/lab';
import SupplierOrderItemsTable, {
  ISupplierOrderItemsState,
} from '../../components/SupplierOrder/SupplierOrderItemsTable';
import SupplierOrderProblems from '../../components/SupplierOrder/SupplierOrderProblems';
import SupplierOrderScanButton from '../../components/SupplierOrder/SupplierOrderActions/SupplierOrderScanButton';
import SupplierOrderPdfButton from '../../components/SupplierOrder/SupplierOrderActions/SupplierOrderPdfButton';
import SupplierOrderAddProblemButton from '../../components/SupplierOrder/SupplierOrderActions/SupplierOrderAddProblemButton';
import SupplierOrderCardInfo from '../../components/SupplierOrder/SupplierOrderCardInfo';
import SupplierOrderFinishButton from '../../components/SupplierOrder/SupplierOrderActions/SupplierOrderFinishButton';
import {SupplierOrderStatus} from '../../types/SupplierOrders/SupplierOrderStatus';
import {CreateSupplierOrderProvider} from './Context/CreateSupplierOrderContext';
import SupplierOrderSendEmailButton from '../../components/SupplierOrder/SupplierOrderActions/SupplierOrderSendEmailButton';
import SupplierOrderActivityLogTable from '../../components/SupplierOrder/SupplierOrderActivityLogTable';
import SupplierOrderEditButton from '../../components/SupplierOrder/SupplierOrderActions/SupplierOrderEditButton';
import SupplierOrderReopenButton from '../../components/SupplierOrder/SupplierOrderActions/SupplierOrderReopenButton';
import useDataFetcher from '../../hooks/dataFetcher';
import {EntityType} from '../../types/CustomFields/CustomField';
import ViewInvoiceButton from '../../components/UploadInvoiceComponent/ViewInvoiceButton';
import SupplierOrderCancelButton from '../../components/SupplierOrder/SupplierOrderActions/SupplierOrderCancelButton';

const SupplierOrderDetails = (props: any): ReactElement => {
  const {
    match: {
      params: {id},
    },
  } = props;
  const [supplierOrderDetails, getSupplierOrderDetails] = useFetch<any>('supplier_order_details');
  const [supplierOrderItem, postSupplierOrderItem] = useFetch<any>('supplier_order_item_create');
  const [supplierOrderItemDelete, doSupplierOrderItemDelete] = useFetch<any>('supplier_order_items_delete');
  const [moveToBacklogResponse, moveToBacklog] = useFetch<any>('supplier_order_items_move_to_backlog');
  const [csvResponse, getCSV] = useFetch<any>('supplier_order_winterholt_csv');
  const [supplierOrder, setSupplierOrder] = useState<ISupplierOrder>(null as any);
  const [orderProblemTable, refreshOrderProblemTable] = useState(false);
  const [tabValue, setTabValue] = useState<any>('0');
  const handleTabChange = (event: any, newValue: any) => setTabValue(newValue);
  const [state, setState] = useState<ISupplierOrderItemsState>({
    initialSupplierOrderItems: [],
    supplierOrderItems: [],
    supplierOrderItemsToUpdate: [],
    isEdited: false,
    edit: false,
    toggleRefreshTable: false,
  });
  const {t} = useTranslation();

  const {data: customFields} = useDataFetcher('get_custom_field_entity_fields', {
    entityType: EntityType.SUPPLIER_ORDER,
  });

  useEffect(() => {
    getSupplierOrderDetails({id});
  }, [id, moveToBacklogResponse]);

  useEffect(() => {
    if (supplierOrderDetails.data) {
      setSupplierOrder(supplierOrderDetails.data);
    }
  }, [supplierOrderDetails]);

  useEffect(() => {
    if (csvResponse.data) {
      downloadFile(csvResponse.data);
    }
    return () => {
      csvResponse.data = null;
      csvResponse.error = null;
      csvResponse.loaded = null;
    };
  }, [csvResponse]);

  const downloadFile = async (data: any) => {
    const url = window.URL.createObjectURL(new Blob([data]));
    const link = document.createElement('a');

    link.setAttribute('download', `${supplierOrder.orderNumber}.csv`);
    link.href = url;

    document.body.appendChild(link);

    link.click();
    link.remove();
  };

  return (
    supplierOrder && (
      <CreateSupplierOrderProvider
        value={{
          order: supplierOrder,
          orderId: id,
          postSupplierOrderItem,
          supplierOrderItem,
          state,
          setState,
          setSupplierOrder,
          supplierOrderDetails,
          supplierOrderItemDelete,
          doSupplierOrderItemDelete,
          moveToBacklogResponse,
          moveToBacklog,
          getSupplierOrderDetails,
          customFields,
        }}
      >
        <div className="order-details supplier__order__details">
          <div className="center-content">
            <div className="customer-details__header">
              <p>
                {t('supplierOrderDetails.screenTitle')} - {supplierOrder?.orderNumberText || supplierOrder?.orderNumber}
              </p>
              <div className="order-details__header__buttons">
                {![SupplierOrderStatus.delivered, SupplierOrderStatus.cancelled].includes(supplierOrder.status) && (
                  <SupplierOrderFinishButton supplierOrderId={id} />
                )}
                {[
                  SupplierOrderStatus.ordered,
                  SupplierOrderStatus.inbound,
                  SupplierOrderStatus.partially_inbound,
                ].includes(supplierOrder.status) && <SupplierOrderCancelButton supplierOrderId={id} />}
                {supplierOrder.status === SupplierOrderStatus.delivered && (
                  <SupplierOrderReopenButton supplierOrderId={id} />
                )}
                {supplierOrder.supplier.name === 'Winterholt' && (
                  <Button
                    onClick={() => {
                      getCSV({id});
                    }}
                    variant="contained"
                    className="blue-button"
                  >
                    CSV
                  </Button>
                )}
                <SupplierOrderScanButton
                  getSupplierOrderDetails={getSupplierOrderDetails}
                  supplierOrder={{...supplierOrder, _id: id}}
                />
                <SupplierOrderPdfButton supplierOrderId={id} getSupplierOrderPdf={getSupplierOrderDetails} />
                <ViewInvoiceButton path="supplierInvoices" fileName={supplierOrder.invoice?.fileName || ''} />

                <SupplierOrderSendEmailButton
                  email={supplierOrder?.supplier?.email}
                  supplierOrderId={id}
                  createOrder={false}
                />
                <SupplierOrderAddProblemButton
                  supplierOrderId={id}
                  refreshOrderProblemTable={refreshOrderProblemTable}
                />
                <SupplierOrderEditButton />
              </div>
            </div>

            <SupplierOrderCardInfo />
          </div>

          <TabContext value={tabValue}>
            <Box>
              <Tabs
                variant="scrollable"
                scrollButtons="auto"
                value={tabValue}
                onChange={handleTabChange}
                TabIndicatorProps={{style: {display: 'none'}}}
                className="tab-container"
              >
                <Tab
                  className={tabValue == 0 ? 'tab-item-active' : 'tab-item'}
                  label={t('supplierOrderDetails.supplierOrderItems')}
                  value="0"
                />
                <Tab
                  className={tabValue == 1 ? 'tab-item-active' : 'tab-item'}
                  label={t('supplierOrderDetails.orderProblems')}
                  value="1"
                />
                <Tab
                  className={tabValue == 2 ? 'tab-item-active' : 'tab-item'}
                  label={t('orderDetails.activityLog')}
                  value="2"
                />
              </Tabs>
            </Box>
            <TabPanel value="0">
              <SupplierOrderItemsTable />
            </TabPanel>
            <TabPanel value="1">
              <SupplierOrderProblems orderId={id} orderProblemTable={orderProblemTable} />
            </TabPanel>
            <TabPanel value="2">
              <SupplierOrderActivityLogTable />
            </TabPanel>
          </TabContext>
        </div>
      </CreateSupplierOrderProvider>
    )
  );
};

export default SupplierOrderDetails;
