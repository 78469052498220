import React, {useEffect, useState} from 'react';
import {Button, TextField} from '@material-ui/core';
import {IOrderItem} from '../../../types/OrderItems/IOrderItem';
import {useTranslation} from 'react-i18next';
import {useStorlessFetch} from '../../../hooks/fetch';
import {appActions} from '../../../modules/app/actions';
import {useDispatch} from 'react-redux';

type SetPurchasingPriceProps = {
  row: IOrderItem;
  setTriggerRefreshTable: (value: string) => void;
};

const SetPurchasingPrice = (props: SetPurchasingPriceProps) => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const [purchasePrice, setPurchasePrice] = useStorlessFetch('set_purchase_price');
  const {setTriggerRefreshTable, row} = props;
  const [price, setPrice] = useState(row?.purchasePrice);

  const onFormSubmit = (e: any) => {
    e.preventDefault();
    const id = row?._id;
    setPurchasePrice({id, purchasePrice: Number(price)});
    const triggerRefreshTableValue = `${Math.random()}-${row?.purchasePrice}`;
    setTriggerRefreshTable(triggerRefreshTableValue);
  };

  useEffect(() => {
    setPrice(row?.purchasePrice || 0);

    return () => {
      setPrice(0);
    };
  }, [row._id]);

  useEffect(() => {
    if (purchasePrice?.error) {
      dispatch(appActions.showSnackBar({text: purchasePrice.error?.name, options: {severity: 'error'}}));
    } else if (purchasePrice?.data) {
      dispatch(appActions.showSnackBar({text: t('general.success'), options: {severity: 'success'}}));
    }

    return () => {
      purchasePrice.data = null;
      purchasePrice.error = null;
      purchasePrice.loaded = null;
      purchasePrice.loading = null;
    };
  }, [purchasePrice]);

  return (
    <div className="flex">
      <TextField
        className="flex-1"
        onClick={(e) => e.preventDefault()}
        id="outlined-number"
        label={t('orderItems.purchasePrice')}
        type="number"
        InputLabelProps={{shrink: true}}
        style={{width: 110, marginRight: 20}}
        InputProps={{inputProps: {min: 0, step: 1}}}
        value={price}
        onChange={(e) => setPrice(+e.target.value)}
      />

      <Button variant="contained" className="blue-button" onClick={onFormSubmit}>
        {t('general.submit')}
      </Button>
    </div>
  );
};
export default SetPurchasingPrice;
