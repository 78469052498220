import {TableRow, TableCell, TextField, Select, MenuItem} from '@material-ui/core';
import React, {ReactElement, useContext} from 'react';
import {IScannedOrderItem} from '../../../types/OrderItems/IScannedOrderItem';
import {CustomerReturnStatus, MultiBoxReturnStatus} from '../../../types/Orders/CustomerReturnStatus';
import ReturnOrderScanningContext from '../../../screens/Orders/Context/ReturnOrderScanningContext ';
import {IMultiBoxItem} from '../../../types/Product/IEanInformation';

interface IProps {
  product: IScannedOrderItem;
}

const MultiBoxReturnOrderItemsTable = ({product}: IProps): ReactElement => {
  const {scannedProducts, setScannedProducts, handleBarcodeChange} = useContext(ReturnOrderScanningContext);

  const handleMultiBoxStatusChange = (e: any, multiBoxProduct: any) => {
    const newValue = e.target.value;
    multiBoxProduct.returnType = newValue;

    if (newValue === MultiBoxReturnStatus.notReturned) {
      multiBoxProduct.returnQuantityScanned = multiBoxProduct.numberOfProducts;
      handleBarcodeChange(true);
    }

    setScannedProducts([...scannedProducts]);
  };

  const getSupplierNames = (multiBoxProduct: IMultiBoxItem) => {
    const supplierNames = multiBoxProduct.supplierNames;
    return supplierNames
      ? Object.entries(supplierNames)
          .map(([key, value]) => `${key} (${value})`)
          .join(', ')
      : '';
  };

  return product?.scannedAsMultiBox ? (
    <>
      {product.barcodeArticleNumbers.multiBoxArticleNumbers.map((multiBoxProduct: IMultiBoxItem) => (
        <TableRow key={multiBoxProduct.productId}>
          <TableCell>- MultiBox</TableCell>
          <TableCell>{multiBoxProduct.barcodeArticleNumbers[0]}</TableCell>
          <TableCell>{multiBoxProduct.productTitle}</TableCell>
          <TableCell>
            <Select
              id="returnStatus"
              value={multiBoxProduct.returnType}
              onChange={(e) => handleMultiBoxStatusChange(e, multiBoxProduct)}
              defaultValue={CustomerReturnStatus.returnedToStock}
            >
              {Object.values(MultiBoxReturnStatus)?.map((item: any) => (
                <MenuItem key={item} value={item}>
                  {item}
                </MenuItem>
              ))}
            </Select>
          </TableCell>
          <TableCell>
            {multiBoxProduct.returnType === CustomerReturnStatus.createdSupplierComplaint && (
              <TextField
                type="text"
                variant="outlined"
                value={multiBoxProduct.complaintMessage}
                onChange={(e) => {
                  multiBoxProduct.complaintMessage = e.target.value;
                  setScannedProducts([...scannedProducts]);
                }}
              ></TextField>
            )}
          </TableCell>
          <TableCell>{getSupplierNames(multiBoxProduct)}</TableCell>
          <TableCell>{multiBoxProduct.numberOfProducts}</TableCell>
          <TableCell>
            <TextField
              type="number"
              variant="outlined"
              value={multiBoxProduct.returnQuantityScanned}
              InputProps={{
                inputProps: {
                  min: 0,
                  max: multiBoxProduct.numberOfProducts * product.quantity,
                },
              }}
              onChange={(e) => {
                const quantity = +e.target.value;
                if (quantity >= 0 && quantity <= multiBoxProduct.numberOfProducts * product.quantity) {
                  multiBoxProduct.returnQuantityScanned = quantity;
                  setScannedProducts([...scannedProducts]);
                }
              }}
            ></TextField>
          </TableCell>
        </TableRow>
      ))}
    </>
  ) : (
    <></>
  );
};

export default MultiBoxReturnOrderItemsTable;
