import {Box, Drawer, Grid, IconButton} from '@material-ui/core';
import {ChevronRight} from '@material-ui/icons';
import React, {useEffect, useState} from 'react';
import eventBus from '../../../lib/eventBus';
import {IDetailedOrder, IDetailedOrderItem} from '../../../types/Orders/IDetailedOrder';
import SupplierArticlesTable from '../../Product/SupplierArticlesTable';
import {IProduct} from '../../../types/Product/IProduct';
import {useFetch} from '../../../hooks/fetch';
import SectionProductOrdersTable from './Sections/SectionProductOrdersTable';
import SectionSupplierOrdersTable from './Sections/SectionSupplierOrdersTable';

export interface IProductInformationDrawerProps {
  order: IDetailedOrder;
}

const ProductInformationDrawer = (props: IProductInformationDrawerProps): React.ReactElement => {
  const {order} = props;
  const [product, setProduct] = useState<IProduct>({} as IProduct);
  const [getProduct, doGetProduct] = useFetch<any>('product_details');
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [orderItem, setOrderItem] = useState<IDetailedOrderItem>({} as IDetailedOrderItem);

  useEffect(() => {
    eventBus.on('openDrawer', (data: any) => {
      setDrawerOpen(true);
      setOrderItem(data.orderItem);
      doGetProduct({id: data.orderItem.productId});
    });

    return () => {
      eventBus.remove('openDrawer');
    };
  }, []);

  useEffect(() => {
    if (getProduct.data) {
      setProduct(getProduct.data);
    }
  }, [getProduct]);

  return (
    <Drawer anchor="right" open={drawerOpen} variant="persistent">
      <Box style={{maxWidth: 1300, padding: '20px'}} role="presentation">
        <div style={{padding: 10, display: 'flex', alignItems: 'center'}}>
          <IconButton
            style={{marginBottom: 0}}
            onClick={() => {
              setDrawerOpen(false);
            }}
          >
            <ChevronRight />
          </IconButton>
          <span className="big-title">{orderItem.title}</span>
        </div>

        <Grid container spacing={3}>
          <Grid item xs={6}>
            {product._id && (
              <SectionProductOrdersTable
                title="Customer orders"
                productId={product._id ?? ''}
                customerId={order.customerId}
              />
            )}
          </Grid>
          <Grid item xs={6}>
            {product._id && (
              <SectionProductOrdersTable title="General orders" productId={product._id ?? ''} customerId="" />
            )}
          </Grid>
        </Grid>

        <Grid container spacing={3} style={{marginTop: '15px'}}>
          <Grid item xs={6}>
            {product._id && <SupplierArticlesTable title="Supplier articles" product={product} tableHeight="418px" />}
          </Grid>
          <Grid item xs={6}>
            {product._id && <SectionSupplierOrdersTable title="Latest supplier orders" productId={product._id ?? ''} />}
          </Grid>
        </Grid>
      </Box>
    </Drawer>
  );
};
export default ProductInformationDrawer;
