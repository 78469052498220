import {
  Grid,
  TextField,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button,
  Typography,
} from '@material-ui/core';
import React, {ReactElement, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {IOrder} from '../../types/Orders/IOrder';
import {modalActions} from '../../modules/modal/actions';
import {useDispatch} from 'react-redux';
import {useStorlessFetch} from '../../hooks/fetch';
import {appActions} from '../../modules/app/actions';

export interface ICreateCreditNoteFormProps {
  getOrderDetails: (data: any) => void;
  onSubmit?: (values: any) => void;
  orderObject: IOrder;
}

const CreateCreditNoteModal = ({orderObject, getOrderDetails}: ICreateCreditNoteFormProps): ReactElement => {
  const [createCreditNoteRes, createCreditNote] = useStorlessFetch('orders_create_credit_note');
  const [refundShippingCosts, setRefundShippingCosts] = useState(false);
  const [discount, setDiscount] = useState(0);
  const {t} = useTranslation();
  const onChangeDiscount = (e: any) => {
    setDiscount(+e.target.value);
  };
  const [orderItems, setOrderItems] = useState<any[]>([]);
  const dispatch = useDispatch();
  const onSubmit = (values: any) => {
    const {orderItems, orderId, refundShippingCosts, discount} = values;

    if (createCreditNoteRes?.loading) {
      return;
    }

    createCreditNote({
      orderItems,
      id: orderId,
      refundShippingCosts,
      discount,
    });
  };

  const onChangeQuantity = (itemId: string, value: number) => {
    setOrderItems((prevState: any) => {
      const updatedOrderItems = prevState.map((item: any) => {
        if (item.orderItemId === itemId) {
          return {
            ...item,
            quantity: value,
          };
        }
        return item;
      });

      return updatedOrderItems;
    });
  };

  const handleCancelModal = () => {
    dispatch(modalActions.closeModal());
  };

  useEffect(() => {
    if (orderObject && orderObject.orderItems) {
      const updatedOrderItems = orderObject.orderItems.map((item) => ({
        orderItemId: item._id,
        productId: (item.product as any)?._id,
        title: item.name,
        quantity: 0,
        maxQuantity: item.quantity,
      }));

      setOrderItems(updatedOrderItems);
    }
  }, [orderObject]);

  useEffect(() => {
    if (createCreditNoteRes?.data) {
      getOrderDetails({id: orderObject._id});
      dispatch(appActions.showSnackBar({text: t('general.success'), options: {severity: 'success'}}));
      handleCancelModal();
    }

    if (createCreditNoteRes?.error) {
      const errorMessage = createCreditNoteRes.error?.name || 'Error';
      dispatch(appActions.showSnackBar({text: errorMessage, options: {severity: 'error'}}));
      handleCancelModal();
    }

    return () => {
      createCreditNoteRes.data = null;
      createCreditNoteRes.error = null;
      createCreditNoteRes.loading = null;
    };
  }, [createCreditNoteRes.data]);

  const handleSubmit = () => onSubmit({orderItems, orderId: orderObject._id, refundShippingCosts, discount});

  return orderItems?.length ? (
    <div className="orders">
      <Typography variant="h2">{t('orderDetails.createCreditNote')}</Typography>
      <Grid container spacing={2} style={{marginTop: '10px'}}>
        <Grid item xs={6} sm={6}>
          <TextField
            onChange={onChangeDiscount}
            type="number"
            id="discount"
            placeholder={t('scanner.discount')}
            label={t('scanner.discount')}
            variant="outlined"
            InputProps={{inputProps: {min: 0, max: 100, step: 1}}}
          />
        </Grid>
        <Grid item xs={6} sm={6}>
          {t('scanner.refundShippingCosts')}:
          <Checkbox
            color="primary"
            checked={refundShippingCosts}
            onChange={(e) => {
              setRefundShippingCosts(e.target.checked);
            }}
          ></Checkbox>
        </Grid>

        <Grid item xs={12} sm={12}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>{t('scanner.product')}</TableCell>
                <TableCell>{t('scanner.quantity')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {orderItems?.map((orderItem) => {
                return (
                  <React.Fragment key={orderItem.orderItemId}>
                    <>
                      <TableRow>
                        <TableCell>{orderItem.title}</TableCell>
                        <TableCell>
                          <TextField
                            type="number"
                            id={orderItem.orderItemId}
                            variant="outlined"
                            value={orderItem.quantity || 0}
                            InputProps={{
                              inputProps: {
                                min: 0,
                                max: orderItem.maxQuantity,
                              },
                            }}
                            onChange={(e) => {
                              const value = +e.target.value;
                              onChangeQuantity(orderItem.orderItemId, value);
                            }}
                          ></TextField>
                        </TableCell>
                      </TableRow>
                    </>
                  </React.Fragment>
                );
              })}
            </TableBody>
          </Table>
          <Button
            disabled={!orderItems.some((item) => item.quantity > 0)}
            onClick={handleSubmit}
            variant="contained"
            className={`${orderItems.some((item) => item.quantity > 0) ? 'blue-button' : 'inactive-button'}`}
            style={{float: 'right'}}
          >
            {t('general.submit')}
          </Button>
        </Grid>
      </Grid>
    </div>
  ) : (
    <></>
  );
};

export default CreateCreditNoteModal;
