import {Table, TableHead, TableRow, TableCell, TableBody, TextField} from '@material-ui/core';
import React, {ReactElement, useContext} from 'react';
import {useTranslation} from 'react-i18next';
import OrderScanningContext from '../../../screens/Orders/Context/OrderScanningContext';

const MultiBoxOrderItemsTable = (): ReactElement => {
  const {product, orderItems, setOrderItems, multiBoxInput} = useContext(OrderScanningContext);
  const {t} = useTranslation();

  return product && multiBoxInput ? (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>{t('scanner.product')}</TableCell>
          <TableCell>{t('scanner.productId')}</TableCell>
          <TableCell>EAN</TableCell>
          <TableCell>{t('scanner.quantity')}</TableCell>
          <TableCell>{t('scanner.quantityScanned')}</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {product.barcodeArticleNumbers.multiBoxArticleNumbers.map((multiBoxProduct) => (
          <TableRow key={multiBoxProduct.productId}>
            <TableCell>{multiBoxProduct.productTitle}</TableCell>
            <TableCell>{multiBoxProduct.id}</TableCell>
            <TableCell>{multiBoxProduct.barcodeArticleNumbers[0]}</TableCell>
            <TableCell>
              <TextField type="number" variant="outlined" value={multiBoxProduct.numberOfProducts}></TextField>
            </TableCell>
            <TableCell>
              <TextField
                type="number"
                variant="outlined"
                value={multiBoxProduct.quantityScanned}
                InputProps={{
                  inputProps: {
                    min: 0,
                    max: multiBoxProduct.numberOfProducts * product.quantity,
                  },
                }}
                onChange={(e) => {
                  const quantity = +e.target.value;
                  if (quantity >= 0 && quantity <= multiBoxProduct.numberOfProducts * product.quantity) {
                    multiBoxProduct.quantityScanned = quantity;
                    setOrderItems([...orderItems]);
                  }
                }}
              ></TextField>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  ) : (
    <></>
  );
};

export default MultiBoxOrderItemsTable;
