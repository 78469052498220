import {Button, Grid} from '@material-ui/core';
import {Check, Error, Sync} from '@material-ui/icons';
import React, {ReactElement} from 'react';
import {useContext} from 'react';
import {useTranslation} from 'react-i18next';
import OrderDetailsContext from '../../screens/Orders/Context/OrderDetailsContext';
import moment from 'moment';

const EasyBillInfo = (): ReactElement => {
  const {order, easyBillRetryResponse, retryEasyBillInvoiceCreation} = useContext(OrderDetailsContext);
  const onButtonClick = () => retryEasyBillInvoiceCreation({id: order._id});
  const invoiceCreatedAt = order?.easyBillInfo?.invoiceCreatedAt || order?.trackingInformation?.dateSynced;
  const {t} = useTranslation();

  return (
    <Grid container spacing={0} alignItems="center">
      <Grid item xs={12} sm={6} className="order-info-item">
        <div className="order-side-label">{t('orderDetails.easyBillCustomerCreated')}</div>
        <div className="order-side-text">{order?.easyBillInfo?.customerCreated ? <Check /> : <Error />}</div>
      </Grid>
      <Grid item xs={12} sm={6} className="order-info-item">
        <div className="order-side-label">{t('orderDetails.invoiceCreated')}</div>
        <div className="order-side-text">{order?.easyBillInfo?.invoiceCreated ? <Check /> : <Error />}</div>
      </Grid>
      <Grid item xs={12} sm={6} className="order-info-item">
        <div className="order-side-label">{t('orderDetails.pdfFetchedFromEasyBill')}</div>
        <div className="order-side-text">{order?.easyBillInfo?.pdfFetchedFromEasyBill ? <Check /> : <Error />}</div>
      </Grid>
      <Grid item xs={12} sm={6} className="order-info-item">
        <div className="order-side-label">{t('orderDetails.invoiceMarkedAsCompleted')}</div>
        <div className="order-side-text">{order?.easyBillInfo?.invoiceMarkedAsCompleted ? <Check /> : <Error />}</div>
      </Grid>
      <Grid item xs={12} sm={6} className="order-info-item">
        <div className="order-side-label">{t('orderDetails.paymentCreated')}</div>
        <div className="order-side-text">{order?.easyBillInfo?.paymentCreated ? <Check /> : <Error />}</div>
      </Grid>
      <Grid item xs={12} sm={6} className="order-info-item">
        <div className="order-side-label">{t('orderDetails.invoiceSentToCustomer')}</div>
        <div className="order-side-text">{order?.easyBillInfo?.invoiceSentToCustomer ? <Check /> : <Error />}</div>
      </Grid>
      <Grid item xs={12} sm={6} className="order-info-item">
        <div className="order-side-label">{t('orderDetails.invoiceNumber')}</div>
        <div className="order-side-text">{order?.easyBillInfo?.invoiceNumber || '-'}</div>
      </Grid>
      <Grid item xs={12} sm={6} className="order-info-item">
        <div className="order-side-label">{t('orderDetails.invoiceCreatedAt')}</div>
        <div className="order-side-text">
          {invoiceCreatedAt ? moment(invoiceCreatedAt).format('DD. MMM YYYY HH:mm:ss') : '-'}
        </div>
      </Grid>
      {!order?.easyBillInfo?.invoiceSentToCustomer ? (
        <Grid item xs={12} sm={6} className="order-info-item">
          <div className="order-side-label">{t('general.retry')}</div>
          <div className="order-side-text">
            <Button
              onClick={onButtonClick}
              title="Retry"
              className="blue-button"
              variant="contained"
              disabled={easyBillRetryResponse.loading}
            >
              <Sync></Sync>
            </Button>
          </div>
        </Grid>
      ) : null}
    </Grid>
  );
};

export default EasyBillInfo;
