import {
  Button,
  Checkbox,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from '@material-ui/core';
import {useTranslation} from 'react-i18next';
import React, {ReactElement, useEffect, useState} from 'react';
import {IProduct} from '../../types/Product/IProduct';
import {useStorlessFetch} from '../../hooks/fetch';
import {appActions} from '../../modules/app/actions';
import {useDispatch} from 'react-redux';
import {ProductStatus} from '../../types/Product/ProductStatus.enum';
import {ShopType} from '../../types/Orders/ShopType';

interface IProps {
  product: IProduct;
  getProductDetails: (data: any) => void;
}

interface ProductShopInfo {
  shopName: string;
  excludePricing: boolean;
  status: ProductStatus;
  hasChanges: boolean;
  fixedPrice: number;
}

export default function ProductStatusTable(props: IProps): ReactElement {
  const [productStatusesRes, getProductStatuses] = useStorlessFetch('product_statuses');
  const [updateProductStatusRes, updateProductStatus] = useStorlessFetch('product_status_update');
  const shopsWithFixedPriceField = [ShopType.DRUCKDICHAUS, ShopType.EBAY, 'idealo'];
  const [productStatuses, setProductStatuses] = useState([]);
  const {product, getProductDetails} = props;
  const dispatch = useDispatch();
  const {t} = useTranslation();

  useEffect(() => {
    getProductStatuses({id: product?._id});
  }, []);

  useEffect(() => {
    if (productStatusesRes?.data) {
      setProductStatuses(productStatusesRes?.data.map((item: any) => ({...item, hasChanges: false})));
    }
  }, [productStatusesRes]);

  useEffect(() => {
    if (updateProductStatusRes.error) {
      dispatch(appActions.showSnackBar({text: updateProductStatusRes.error.name, options: {severity: 'error'}}));
    }

    if (updateProductStatusRes.data || updateProductStatusRes.data === '') {
      getProductDetails({id: product._id});
      dispatch(appActions.showSnackBar({text: t('general.success'), options: {severity: 'success'}}));
    }

    return () => {
      updateProductStatusRes.data = null;
      updateProductStatusRes.error = null;
    };
  }, [updateProductStatusRes]);

  const onStatusChange = (e: any, shopName: string) => {
    const newStatus = e.target.value as ProductStatus;

    setProductStatuses((prevStatuses: any) => {
      const updatedStatuses = prevStatuses.map((item: ProductShopInfo) => {
        if (item.shopName === shopName) {
          return {...item, status: newStatus, hasChanges: true};
        }
        return item;
      });

      return updatedStatuses;
    });
  };

  const onCheckboxChange = (e: any, shopName: string) => {
    const newExcludePricing = e.target.checked;

    setProductStatuses((prevStatuses: any) => {
      const updatedStatuses = prevStatuses.map((item: ProductShopInfo) => {
        if (item.shopName === shopName) {
          return {...item, excludePricing: newExcludePricing, hasChanges: true};
        }
        return item;
      });

      return updatedStatuses;
    });
  };

  const onFixedPriceChange = (e: any, shopName: string) => {
    const newFixedPrice = +e.target.value;

    setProductStatuses((prevStatuses: any) => {
      const updatedStatuses = prevStatuses.map((item: ProductShopInfo) => {
        if (item.shopName === shopName) {
          return {...item, fixedPrice: newFixedPrice, hasChanges: true};
        }
        return item;
      });

      return updatedStatuses;
    });
  };

  return (
    <div className="data-table-wrapper" style={{height: 'auto', maxHeight: '500px', marginBottom: '20px'}}>
      <TableContainer className="table-scroll">
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{t('productDetails.shopName')}</TableCell>
              <TableCell>{t('productDetails.status')}</TableCell>
              <TableCell>{t('productDetails.excludePricing')}</TableCell>
              <TableCell>{t('productDetails.fixedPrice')}</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {productStatuses.map((item: ProductShopInfo) => (
              <TableRow key={item.shopName}>
                <TableCell>{item.shopName}</TableCell>
                {item.shopName !== 'idealo' ? (
                  <TableCell>
                    {item.status && (
                      <Select
                        id="status"
                        value={item.status}
                        onChange={(e) => onStatusChange(e, item.shopName)}
                        style={{marginRight: '5px'}}
                      >
                        {Object.values(ProductStatus)
                          .filter((x) => x !== ProductStatus.DELETED)
                          .map((x: any) => (
                            <MenuItem key={x} value={x}>
                              {x}
                            </MenuItem>
                          ))}
                      </Select>
                    )}
                  </TableCell>
                ) : (
                  <TableCell></TableCell>
                )}
                <TableCell>
                  {item.shopName !== 'idealo' && (
                    <Checkbox
                      checked={item.excludePricing}
                      value={item.excludePricing}
                      onChange={(e: any) => onCheckboxChange(e, item.shopName)}
                    />
                  )}
                </TableCell>
                <TableCell>
                  {shopsWithFixedPriceField.includes(item.shopName) && (
                    <TextField
                      type="number"
                      value={item.fixedPrice}
                      onChange={(e) => onFixedPriceChange(e, item.shopName)}
                    />
                  )}
                </TableCell>
                <TableCell>
                  {item.hasChanges && (
                    <Button
                      variant="contained"
                      className="blue-button"
                      onClick={() => {
                        updateProductStatus({id: product._id, ...item});
                      }}
                    >
                      {t('general.submit')}
                    </Button>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
