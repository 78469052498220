import React, {useContext} from 'react';
import {IconButton, TableCell, TableRow, TextField} from '@material-ui/core';
import {IDetailedOrderItem} from '../../../types/Orders/IDetailedOrder';
import {DeleteForever, Info, NotInterested} from '@material-ui/icons';
import CreateOrderContext from '../../../screens/Orders/Context/CreateOrderContext';

interface EditableOrderRowProps {
  index: number;
  orderItemId: string;
  removeOrderItem: any;
  showDetailsForOrderItem: any;
  editingMode: boolean;
}

export interface IEditableOrderRowState {
  quantity: number;
  priceGross: number;
  priceNet: number;
  discount: number;
  vat: number;
  totalNet: number;
  totalGross: number;
  orderItemId: string;
  orderId: string;
}

const EditableOrderRow = (props: EditableOrderRowProps) => {
  const {index, orderItemId, removeOrderItem, showDetailsForOrderItem} = props;
  const {order, setOrder} = useContext(CreateOrderContext);
  const orderItem: any = order.orderItems.find((item: IDetailedOrderItem) => item.orderItemId === orderItemId);

  const updateField = (field: string, value: any) => {
    updateOrderItemFields(orderItem, field, value);
    setOrder((prevState: any) => ({...prevState, orderItems: order.orderItems}));
  };

  const updateOrderItemFields = (orderItem: any, field: string, value: any) => {
    let quantity = orderItem.quantity;
    let priceGross = orderItem.priceGross;
    let priceNet = orderItem.priceNet;
    let discount = orderItem.discount;
    const vat = order.vatPercentage;

    if (field === 'quantity') {
      quantity = +value;
    }

    if (field === 'priceGross') {
      priceGross = +value;
      priceNet = +(priceGross / (1 + vat / 100)).toFixed(2);
    }

    if (field === 'priceNet') {
      priceNet = +value;
      priceGross = +(priceNet * (1 + vat / 100)).toFixed(2);
    }

    if (field === 'vat') {
      priceGross = +(priceNet * (1 + vat / 100)).toFixed(2);
    }

    if (field === 'discount') {
      discount = +value;
    }

    if (field === 'ignoreForSuggestions') {
      orderItem.ignoreForSuggestions = value;
    }

    const totalNet = +(priceNet * quantity * (1 - discount / 100)).toFixed(2);
    const totalGross = +(totalNet * (1 + vat / 100)).toFixed(2);

    orderItem.quantity = quantity;
    orderItem.priceGross = priceGross;
    orderItem.priceNet = priceNet;
    orderItem.discount = discount;
    orderItem.totalNet = totalNet;
    orderItem.totalGross = totalGross;
    orderItem.vat = vat;
  };

  return orderItem ? (
    <TableRow>
      <TableCell className="create-orders-cell-padding">{index + 1}.</TableCell>
      <TableCell className="create-orders-cell-padding">{orderItem.shopwareId}</TableCell>
      <TableCell className="create-orders-cell-padding">
        <TextField
          type="number"
          value={orderItem.quantity}
          InputProps={{inputProps: {min: 1}}}
          onChange={(e) => updateField('quantity', +e.target.value)}
        />
      </TableCell>
      <TableCell className="create-orders-cell-padding">{orderItem.title}</TableCell>
      <TableCell className="create-orders-cell-padding">
        <TextField
          type="number"
          value={orderItem.priceNet}
          InputProps={{inputProps: {min: 0, step: 0.01}}}
          onChange={(e) => updateField('priceNet', +e.target.value)}
        />
      </TableCell>
      <TableCell className="create-orders-cell-padding">
        <TextField
          type="number"
          value={orderItem.priceGross}
          InputProps={{inputProps: {min: 0, step: 0.01}}}
          onChange={(e) => updateField('priceGross', +e.target.value)}
        />
      </TableCell>
      <TableCell className="create-orders-cell-padding">
        <TextField
          type="number"
          value={orderItem.discount}
          InputProps={{inputProps: {min: 0, step: 0.01}}}
          onChange={(e) => updateField('discount', +e.target.value)}
        />
      </TableCell>
      <TableCell className="create-orders-cell-padding">{orderItem.totalNet}</TableCell>
      <TableCell className="create-orders-cell-padding">
        <IconButton
          style={{padding: '5px', color: orderItem.ignoreForSuggestions ? 'red' : 'grey'}}
          onClick={() => updateField('ignoreForSuggestions', !orderItem.ignoreForSuggestions)}
        >
          <NotInterested />
        </IconButton>
        <IconButton style={{padding: '5px'}} onClick={() => removeOrderItem(orderItem.orderItemId)}>
          <DeleteForever className="red" />
        </IconButton>
        {!props.editingMode && (
          <IconButton style={{padding: '5px'}} onClick={() => showDetailsForOrderItem(orderItem)}>
            <Info className="blue-700" />
          </IconButton>
        )}
      </TableCell>
    </TableRow>
  ) : (
    <></>
  );
};
export default EditableOrderRow;
