import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import DataTable from '../../components/Table/Table';
import {useStorlessFetch} from '../../hooks/fetch';
import {ISyncStatus} from '../../types/SyncStatus/SyncStatus';
import {isEnabled, RunSync, SyncDate, SyncStatus} from './SyncStatus';
import {Button, IconButton, MenuItem, Select} from '@material-ui/core';
import {appActions} from '../../modules/app/actions';
import {useDispatch} from 'react-redux';
import {modalActions} from '../../modules/modal/actions';
import {MODALS} from '../../components/Modal/ModalContents';
import {DataTableItemType} from '../../components/Table/interfaces/IHeaderAccessor';
import EditIcon from '@material-ui/icons/Edit';

const SyncStatuses = () => {
  const [updateSyncRes, updateSync] = useStorlessFetch('sync_status_post');
  const [turnOnAllRes, turnOnAll] = useStorlessFetch('sync_statuses_turn_on_all');
  const [turnOffAllRes, turnOffAll] = useStorlessFetch('sync_statuses_turn_off_all');
  const [deactivateSyncRes, deactivateSync] = useStorlessFetch('sync_deactivate');
  const [stopSyncStatusRes, stopSyncStatus] = useStorlessFetch('sync_stop');
  const [activateSyncRes, activateSync] = useStorlessFetch('sync_activate');

  const [isDeactivated, setIsDeactivated] = useState(false);
  const [refreshData, setRefreshData] = useState(false);
  const dispatch = useDispatch();
  const {t} = useTranslation();

  const onTurnOnSyncStatuses = () => {
    dispatch(
      modalActions.addModal(MODALS.CONFIRM_DIALOG, {
        title: t('general.areYouSure'),
        content: t('general.areYouSure'),
        onYes: () => {
          turnOnAll();
          dispatch(modalActions.closeModal());
        },
        onNo: () => dispatch(modalActions.closeModal()),
      }),
    );
  };

  const onTurnOffSyncStatuses = () => {
    dispatch(
      modalActions.addModal(MODALS.CONFIRM_DIALOG, {
        title: t('general.areYouSure'),
        content: t('general.areYouSure'),
        onYes: () => {
          turnOffAll();
          dispatch(modalActions.closeModal());
        },
        onNo: () => dispatch(modalActions.closeModal()),
      }),
    );
  };

  const onDeactivate = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, item: ISyncStatus) => {
    deactivateSync({syncName: item.syncName});
  };

  const onActivate = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, item: ISyncStatus) => {
    activateSync({syncName: item.syncName});
  };

  const onStop = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, item: ISyncStatus) => {
    stopSyncStatus({syncName: item.syncName});
  };

  useEffect(() => {
    if (turnOnAllRes?.data || turnOffAllRes?.data || updateSyncRes?.data !== null || activateSyncRes?.data !== null) {
      setRefreshData((prevState) => !prevState);
      dispatch(appActions.showSnackBar({text: t('general.success'), options: {severity: 'success'}}));
    }

    if (turnOnAllRes?.error || turnOffAllRes?.error || deactivateSync?.error || activateSyncRes?.error) {
      dispatch(appActions.showSnackBar({text: t('general.error'), options: {severity: 'error'}}));
    }

    return () => {
      turnOnAllRes.data = null;
      turnOnAllRes.error = null;
      turnOffAllRes.data = null;
      turnOffAllRes.error = null;
      updateSyncRes.data = null;
      updateSyncRes.error = null;
      activateSyncRes.data = null;
      activateSyncRes.error = null;
    };
  }, [turnOnAllRes, turnOffAllRes, updateSyncRes, activateSyncRes]);

  useEffect(() => {
    if (deactivateSyncRes?.data !== null || stopSyncStatusRes?.data !== null) {
      setRefreshData((prevState) => !prevState);
      dispatch(appActions.showSnackBar({text: t('general.success'), options: {severity: 'success'}}));
    }

    if (deactivateSyncRes?.error || stopSyncStatusRes?.error) {
      dispatch(appActions.showSnackBar({text: t('general.error'), options: {severity: 'error'}}));
    }

    return () => {
      deactivateSyncRes.data = null;
      deactivateSyncRes.error = null;
      stopSyncStatusRes.data = null;
      stopSyncStatusRes.error = null;
    };
  }, [deactivateSyncRes, stopSyncStatusRes]);

  const onIsDeactivatedFilterChange = (e: any) => {
    const newValue = e.target.value;
    setIsDeactivated(newValue);
  };

  const onEdit = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, syncStatus: ISyncStatus): void => {
    dispatch(
      modalActions.addModal(MODALS.EDIT_CRON_CONFIG_MODAL, {
        hideDefaultCloseButton: false,
        syncStatus,
        callback: () => setRefreshData(!refreshData),
      }),
    );
  };

  return (
    <div className="sync-statuses">
      <DataTable
        className="sync-statuses-table"
        defaultParams={{isSupplier: false}}
        headers={[
          {
            kind: 'accessor',
            name: t('syncStatus.syncName'),
            accessor: 'aliasName',
            sortable: true,
          },
          {
            kind: 'accessor',
            name: t('syncStatus.cronConfig'),
            accessor: 'cronConfigTime',
            sortable: true,
            callback: (row: ISyncStatus) => {
              return row.cronDescription || t(`cronConfigs.${row.cronConfigTime}`);
            },
          },
          {
            kind: 'component',
            name: t('syncStatus.status'),
            component: SyncStatus,
            props: {},
            onFetchProps: {},
          },
          {
            kind: 'accessor',
            name: t('syncStatus.inQueue'),
            accessor: 'inQueue',
            sortable: false,
            type: DataTableItemType.BOOLEAN,
          },
          {
            kind: 'component',
            name: t('syncStatus.nextRun'),
            component: SyncDate,
            props: {accessor: 'nextRun'},
            onFetchProps: {},
          },
          {
            kind: 'component',
            name: t('syncStatus.startedAt'),
            component: SyncDate,
            props: {accessor: 'startedAt'},
            onFetchProps: {},
          },
          {
            kind: 'component',
            name: t('syncStatus.finishedAt'),
            component: SyncDate,
            props: {accessor: 'finishedAt'},
            onFetchProps: {},
          },
          {
            kind: 'component',
            name: t('syncStatus.run'),
            component: RunSync,
            props: {},
            onFetchProps: {},
          },
          {
            kind: 'component',
            name: t('syncStatus.enabled'),
            component: isEnabled,
            props: {updateSync},
            onFetchProps: {},
          },
          {
            kind: 'accessor',
            name: '',
            accessor: '_id',
            type: DataTableItemType.CALLBACK,
            callback: (item: ISyncStatus) => {
              return !item.isDeactivated ? (
                <Button
                  variant="contained"
                  color="primary"
                  className="blue-button"
                  onClick={(e) => onDeactivate(e, item)}
                >
                  {t('general.deactivate')}
                </Button>
              ) : (
                <Button
                  variant="contained"
                  color="primary"
                  className="yellow-button"
                  onClick={(e) => onActivate(e, item)}
                >
                  {t('general.activate')}
                </Button>
              );
            },
          },
          {
            kind: 'accessor',
            name: '',
            accessor: '_id',
            type: DataTableItemType.CALLBACK,
            callback: (item: ISyncStatus) => {
              return item.enabled && !item.finishedAt ? (
                <Button variant="contained" color="primary" className="red-button" onClick={(e) => onStop(e, item)}>
                  {t('general.stop')}
                </Button>
              ) : (
                <></>
              );
            },
          },
          {
            kind: 'accessor',
            name: '',
            accessor: '_id',
            type: DataTableItemType.CALLBACK,
            callback: (item: ISyncStatus) => {
              return (
                <IconButton onClick={(e) => onEdit(e, item)}>
                  <EditIcon style={{fontSize: 20}} />
                </IconButton>
              );
            },
          },
        ]}
        refreshData={refreshData}
        endpoint={'sync_statuses'}
        options={{isDeactivated}}
        numberOfItemsPerPage={100}
        title={t('syncStatus.title')}
        actionButtons={
          <>
            <Select
              id="shopName"
              value={isDeactivated}
              onChange={(e) => onIsDeactivatedFilterChange(e)}
              style={{marginLeft: '10px', padding: '5px'}}
              variant="standard"
              className="yellow-button"
              defaultValue="All"
            >
              {[true, false].map((x: any) => (
                <MenuItem key={x} value={x}>
                  {x ? t('syncStatus.deactivatedSyncs') : t('syncStatus.activatedSyncs')}
                </MenuItem>
              ))}
            </Select>
            <Button variant="contained" className="blue-button" onClick={onTurnOnSyncStatuses}>
              {t('syncStatus.turnOnAll')}
            </Button>
            <Button variant="contained" className="yellow-button" onClick={onTurnOffSyncStatuses}>
              {t('syncStatus.turnOffAll')}
            </Button>
          </>
        }
      />
    </div>
  );
};

export enum SyncTypes {
  SUPPLIER_ARTICLES = 'SUPPLIER_ARTICLES',
  JTL_ORDERS_SHOP1 = 'JTL_ORDERS_SHOP1',
  JTL_ORDERS_SHOP2 = 'JTL_ORDERS_SHOP2',
  AMAZON_ORDERS = 'AMAZON_ORDERS',
  BECHLEM = 'BECHLEM',
}

export default SyncStatuses;
