import {IconButton} from '@material-ui/core';
import React, {useEffect, useState} from 'react';
import DataTable from '../../components/Table/Table';
import {Check, Clear} from '@material-ui/icons';
import {useFetch, useStorlessUpload} from '../../hooks/fetch';
import {useDispatch} from 'react-redux';
import {modalActions} from '../../modules/modal/actions';
import {MODALS} from '../../components/Modal/ModalContents';
import {appActions} from '../../modules/app/actions';
import {useTranslation} from 'react-i18next';
import {IProduct} from '../../types/Product/IProduct';

const PRODUCT_FORM_TITLE = 'Select a product';
const MODAL_TEXT_IGNORE = 'Are you sure you want to ignore this item?';
const MODAL_TEXT_IGNORE_FOREVER = 'Are you sure you want to ignore this item forever?';
const MODAL_TITLE = 'Confirm';

interface IActionsProps {
  doSupplierArticlePut: (param: any) => void;
  doSupplierArticleDelete: (param: any) => void;
  doApproveSupplierArticle: (param: any) => void;
  fetchData: () => void;
  id: string;
}

const Actions: React.FC<IActionsProps> = (props: IActionsProps) => {
  const {doSupplierArticlePut, doSupplierArticleDelete, doApproveSupplierArticle, fetchData, id} = props;
  const dispatch = useDispatch();
  const {t} = useTranslation();
  const [uploadFile, doUploadFile] = useStorlessUpload('upload_optimized');
  const [imageUrl, setImageUrl] = useState(null as any);
  const [product, setProduct] = useState<IProduct>(null as any);

  const handleCancelModal = () => {
    dispatch(modalActions.closeModal());
  };

  const handleCreateProductModal = () => {
    dispatch(
      modalActions.addModal(MODALS.CREATE_PRODUCT, {
        title: t('addNewArticle.screenTitle') || '',
        onSubmit: handleApproveArticle,
        onCancel: handleCancelModal,
      }),
    );
  };

  const handleApproved = async () => {
    dispatch(
      modalActions.addModal(MODALS.SELECT_PRODUCT, {
        title: PRODUCT_FORM_TITLE,
        onSubmit: handleApproveArticle,
        onCancel: handleCancelModal,
        onCreateProduct: handleCreateProductModal,
      }),
    );
  };

  const handleIgnore = async () => {
    dispatch(
      modalActions.addModal(MODALS.CONFIRM_DIALOG, {
        title: MODAL_TITLE,
        content: MODAL_TEXT_IGNORE,
        onYes: handleIgnoreArticle,
        onNo: handleCancelModal,
      }),
    );
  };

  const handleIgnoreForever = async () => {
    dispatch(
      modalActions.addModal(MODALS.CONFIRM_DIALOG, {
        title: MODAL_TITLE,
        content: MODAL_TEXT_IGNORE_FOREVER,
        onYes: handleIgnoreArticleForever,
        onNo: handleCancelModal,
      }),
    );
  };

  const handleApproveArticle = async (values: any) => {
    const data = {...values, supplierArticle: [id]};
    setProduct(data);

    if (data.productInformation?.image) {
      doUploadFile({file: data.productInformation.image});
    } else {
      setImageUrl('');
    }
  };

  const handleIgnoreArticleForever = async () => {
    doSupplierArticlePut({status: 'ignored', id});
    handleCancelModal();
    fetchData();
  };

  const handleIgnoreArticle = async () => {
    doSupplierArticleDelete({id});
    handleCancelModal();
    fetchData();
  };

  useEffect(() => {
    if (uploadFile.data) {
      setImageUrl(uploadFile.data);
    }

    return () => {
      uploadFile.data = null;
      uploadFile.loaded = null;
      uploadFile.error = null;
    };
  }, [uploadFile]);

  useEffect(() => {
    if (product) {
      doApproveSupplierArticle({
        ...product,
        productInformation: {...product.productInformation, image: imageUrl},
      });
      fetchData();
      handleCancelModal();
    }

    return () => {
      setImageUrl(null as any);
      setProduct(null as any);
    };
  }, [imageUrl]);

  return (
    <div>
      <IconButton onClick={handleApproved} title="Approve">
        <Check fontSize="small" style={{fill: 'green'}}></Check>
      </IconButton>
      <IconButton onClick={handleIgnore} title="Ignore">
        <Clear fontSize="small"></Clear>
      </IconButton>
      <IconButton onClick={handleIgnoreForever} title="Ignore forever">
        <Clear fontSize="small" style={{fill: 'red'}}></Clear>
      </IconButton>
    </div>
  );
};

const NewSupplierArticle = () => {
  const [putResponse, doSupplierArticlePut] = useFetch<any>('supplier_articles_put');
  const [deleteResponse, doSupplierArticleDelete] = useFetch<any>('supplier_articles_delete');
  const [productPostResponse, doApproveSupplierArticle] = useFetch<any>('supplier_article_approve');
  const [batchIgnore, doBatchIgnore] = useFetch<any>('supplier_article_batch_ignore');
  const [triggerRefreshTable, setTriggerRefreshTable] = useState<any>(null);
  const dispatch = useDispatch();
  const {t} = useTranslation();

  const handleBatchRemove = (data: any) => {
    doBatchIgnore(data.filters);
    dispatch(modalActions.closeModal());
  };

  const bulkRemove = (data: any) => {
    dispatch(
      modalActions.addModal(MODALS.CONFIRM_DIALOG, {
        title: t('general.areYouSure'),
        content: t('general.areYouSure'),
        onYes: () => handleBatchRemove(data),
        onNo: () => dispatch(modalActions.closeModal()),
      }),
    );
  };

  useEffect(() => {
    setTriggerRefreshTable(Math.random());
    if (batchIgnore?.error) {
      dispatch(appActions.showSnackBar({text: batchIgnore.error.name, options: {severity: 'error'}}));
    } else if (batchIgnore?.data) {
      dispatch(appActions.showSnackBar({text: t('general.success'), options: {severity: 'success'}}));
    }
  }, [batchIgnore]);

  useEffect(() => {
    if (putResponse?.error) {
      dispatch(appActions.showSnackBar({text: putResponse.error.name, options: {severity: 'error'}}));
    } else if (deleteResponse?.error) {
      dispatch(appActions.showSnackBar({text: deleteResponse.error.name, options: {severity: 'error'}}));
    } else if (productPostResponse?.error) {
      dispatch(appActions.showSnackBar({text: productPostResponse.error.name, options: {severity: 'error'}}));
    }
  }, [putResponse, deleteResponse, productPostResponse]);

  const options = {status: 'new'};
  return (
    <div className="supplier-articles">
      <DataTable
        className="new-supplier__table"
        queryButtons={[
          {
            name: t('newArticles.ignoreMany'),
            callback: (data: any) => bulkRemove(data),
          },
        ]}
        headers={[
          {
            kind: 'accessor',
            name: t('newArticles.supplierName'),
            accessor: 'supplierName',
            sortable: true,
          },
          {
            kind: 'accessor',
            name: t('newArticles.articleTitle'),
            accessor: 'title',
            sortable: true,
          },
          {
            kind: 'accessor',
            name: t('newArticles.externalArticleId'),
            accessor: 'externalArticleId',
            sortable: true,
          },
          {
            kind: 'accessor',
            name: t('activatedArticles.price'),
            accessor: 'price',
            sortable: true,
            format: 'price',
            type: 'number',
          },
          {
            kind: 'accessor',
            name: t('activatedArticles.quantity'),
            accessor: 'quantity',
            sortable: true,
            type: 'number',
          },
          {
            kind: 'component',
            name: t('newArticles.actions'),
            component: Actions,
            props: {doSupplierArticlePut, doSupplierArticleDelete, doApproveSupplierArticle},
            onFetchProps: {conditions: {status: 'new'}},
          },
        ]}
        endpoint={'supplier_articles'}
        options={options}
        refreshData={triggerRefreshTable}
        title={t('newArticles.screenTitle')}
      />
    </div>
  );
};

export default NewSupplierArticle;
