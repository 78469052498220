import {Typography} from '@material-ui/core';
import React, {useEffect, useState} from 'react';
import Form, {IField} from '../Form/Form';
import {useTranslation} from 'react-i18next';
import * as Yup from 'yup';
import {useFetch} from '../../hooks/fetch';

const PreviousComplaintExportsModal = (props: IPreviousComplaintExportsModalProps) => {
  const {supplierName, isLoading, onSubmit, onCancel} = props;
  const {t} = useTranslation();
  const [getPreviousComplaintExportsResponse, getPreviousComplaintExports] = useFetch<any>('customer_return_previous');
  const [previousComplaints, setPreviousComplaints] = useState<any[]>([]);
  const [getSingleComplaintResponse, getSingleComplaint] = useFetch<any>('customer_return_single');

  useEffect(() => {
    if (getSingleComplaintResponse.data) {
      downloadFile(getSingleComplaintResponse.data);
    }
    return () => {
      getSingleComplaintResponse.data = null;
      getSingleComplaintResponse.error = null;
      getSingleComplaintResponse.loaded = null;
    };
  }, [getSingleComplaintResponse]);

  const downloadFile = async (data: any) => {
    const url = window.URL.createObjectURL(new Blob([data]));
    const link = document.createElement('a');

    link.setAttribute('download', `${supplierName}.csv`);
    link.href = url;

    document.body.appendChild(link);

    link.click();
    link.remove();
  };

  useEffect(() => {
    getPreviousComplaintExports({supplierName});
  }, [supplierName]);

  useEffect(() => {
    if (getPreviousComplaintExportsResponse.data) {
      setPreviousComplaints(getPreviousComplaintExportsResponse.data);
    }
  }, [getPreviousComplaintExportsResponse.data]);

  return (
    <>
      <Typography className="modal-title">{t('supplierComplaints.previousComplaints')}</Typography>
      <div
        style={{
          maxWidth: '100%',
          width: '500px',
          maxHeight: '500px',
          overflowY: 'auto',
          minHeight: '200px',
          padding: '10px',
        }}
      >
        {previousComplaints.map((previousComplaint) => (
          <div key={previousComplaint.value} style={{marginBottom: '10px'}}>
            <button
              style={{background: 'none', border: 'none', cursor: 'pointer'}}
              onClick={() => getSingleComplaint({supplierName, exportedAt: previousComplaint.value})}
            >
              <Typography variant="body1">{previousComplaint.label}</Typography>
            </button>
          </div>
        ))}
      </div>
    </>
  );
};

export interface IPreviousComplaintExportsModalProps {
  onSubmit: (values: any) => void;
  onCancel: () => void;
  isLoading?: boolean;
  supplierName?: string;
}

export default PreviousComplaintExportsModal;
