import {ISupplierArticle} from '../../types/SupplierArticle/ISupplierArticle';
import React, {ReactElement} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {push} from 'connected-react-router';
import {IProduct} from '../../types/Product/IProduct';
import {TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody} from '@material-ui/core';

const SupplierArticlesTable = ({product, title, tableHeight}: IProps): ReactElement => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const onClickTableRow = (supplierId: any) => dispatch(push(`/articles/${supplierId}`));
  const parseFormattedNumberToPlainNumber = (item: string) => {
    return parseFloat(item.replace(/,/g, '.').replace(/ €/g, ''));
  };

  const sortTable = (n: any, isNumber = false) => {
    let rows: any,
      switching: any,
      i,
      x,
      y,
      shouldSwitch,
      dir,
      switchcount = 0;
    const table: any = document.getElementById('product-details-table');
    switching = true;
    dir = 'asc';
    while (switching) {
      switching = false;
      rows = table?.rows;
      for (i = 1; i < rows.length - 1; i++) {
        shouldSwitch = false;
        x = rows[i].getElementsByTagName('TD')[n];
        y = rows[i + 1].getElementsByTagName('TD')[n];

        const cellContent1 = isNumber ? parseFormattedNumberToPlainNumber(x.innerText) : x.innerHTML.toLowerCase();
        const cellContent2 = isNumber ? parseFormattedNumberToPlainNumber(y.innerText) : y.innerHTML.toLowerCase();

        if (dir == 'asc') {
          if (cellContent1 > cellContent2) {
            shouldSwitch = true;
            break;
          }
        } else if (dir == 'desc') {
          if (cellContent1 < cellContent2) {
            shouldSwitch = true;
            break;
          }
        }
      }
      if (shouldSwitch) {
        rows[i].parentNode.insertBefore(rows[i + 1], rows[i]);
        switching = true;
        switchcount++;
      } else {
        if (switchcount == 0 && dir == 'asc') {
          dir = 'desc';
          switching = true;
        }
      }
    }
  };

  const supplierArticlesTable = product?.supplierArticle
    ?.sort((x, y) => {
      if (x.price === 0 && x.quantity === 0 && (y.price !== 0 || y.quantity !== 0)) {
        return 1;
      } else if (y.price === 0 && y.quantity === 0 && (x.price !== 0 || x.quantity !== 0)) {
        return -1;
      } else {
        return x.price > y.price ? 1 : y.price > x.price ? -1 : 0;
      }
    })
    ?.map((item: ISupplierArticle) => {
      return (
        <TableRow
          key={item._id}
          onClick={() => {
            onClickTableRow(item._id);
          }}
        >
          <TableCell>{item.supplierName}</TableCell>
          <TableCell>{item.externalArticleId}</TableCell>
          <TableCell>{item.quantity}</TableCell>
          <TableCell>
            {item.price?.toLocaleString('de-DE', {
              style: 'currency',
              currency: 'EUR',
              minimumFractionDigits: 2,
            })}
          </TableCell>
        </TableRow>
      );
    });

  return (
    <div className="data-table-wrapper" style={{height: tableHeight || 'calc(100% - 8px)'}}>
      {title && (
        <div className="data-table-wrapper__action-buttons">
          <div className="data-table-wrapper__title">{title}</div>
        </div>
      )}
      <TableContainer component={Paper} className="table-scroll">
        <Table id="product-details-table">
          <TableHead>
            <TableRow>
              <TableCell className="product-details__table__click" onClick={() => sortTable(0)}>
                {t('productDetails.supplierName')}
              </TableCell>
              <TableCell className="product-details__table__click" onClick={() => sortTable(1)}>
                {t('productDetails.externalArticleId')}
              </TableCell>
              <TableCell className="product-details__table__click" onClick={() => sortTable(2, true)}>
                {t('productDetails.quantity')}
              </TableCell>
              <TableCell className="product-details__table__click" onClick={() => sortTable(3, true)}>
                {t('productDetails.price')}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>{supplierArticlesTable}</TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

interface IProps {
  product: IProduct;
  title?: string;
  tableHeight?: string;
}

export default SupplierArticlesTable;
