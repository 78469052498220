import {makeStyles, createStyles} from '@material-ui/core';

export const ShopDescriptionsStyle = makeStyles(() =>
  createStyles({
    root: {
      display: 'block',
      flexDirection: 'row',
      backgroundColor: 'inherit',
      padding: 20,
      minHeight: 100,
      margin: 10,
    },
    textArea: {
      flex: 1,
      minWidth: 300,
      minHeight: 50,
    },
    button: {
      marginLeft: 20,
      marginTop: 5,
      backgroundColor: '#3f51b5',
      height: 40,
      float: 'right',
      color: 'white',
      '&:hover': {
        backgroundColor: '#1a237e',
      },
    },
  }),
);
