const formatCurrency = (value: number | string, locale = 'de-DE', currency = 'EUR'): string => {
  const finalValue = typeof value === 'string' ? parseFloat(value) : value;

  return new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: currency,
  }).format(finalValue);
};

const isDarkMode = (): boolean => {
  return localStorage.getItem('theme') === 'dark';
};

export {formatCurrency, isDarkMode};
