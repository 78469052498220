import {Button} from '@material-ui/core';
import React, {ReactElement, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {modalActions} from '../../../modules/modal/actions';
import {MODALS} from '../../Modal/ModalContents';
import {useDispatch} from 'react-redux';
import {useFetch} from '../../../hooks/fetch';

interface IProps {
  dueDate?: any;
  orderId: string;
  isListItem?: boolean;
  getOrderDetails?: any;
}

const EditDueDateButton = ({dueDate, orderId, isListItem, getOrderDetails}: IProps): ReactElement => {
  const {t} = useTranslation();
  const [patchOrderResponse, patchOrder] = useFetch<any>('order_due_date_patch');

  const dispatch = useDispatch();

  const handleCancelModal = () => {
    dispatch(modalActions.closeModal());
  };

  const handleSubmitModal = async (values: any) => {
    patchOrder({id: orderId, dueDate: values.dueDate});
    handleCancelModal();
  };

  useEffect(() => {
    if (patchOrderResponse?.data) {
      getOrderDetails({id: orderId});
    }
    return () => {
      patchOrderResponse.data = null;
      patchOrderResponse.error = null;
      patchOrderResponse.loaded = null;
    };
  }, [patchOrderResponse]);

  const handleClick = () => {
    dispatch(
      modalActions.addModal(MODALS.EDIT_ORDER_DUE_DATE, {
        title: t('productDetails.productFormTitle') || '',
        onSubmit: handleSubmitModal,
        onCancel: handleCancelModal,
        dueDate,
      }),
    );
  };

  return (
    dueDate && (
      <Button
        variant={isListItem ? 'text' : 'contained'}
        className={isListItem ? 'button-list-item' : 'blue-button'}
        onClick={handleClick}
      >
        {t('orderDetails.editDueDate')}
      </Button>
    )
  );
};

export default EditDueDateButton;
