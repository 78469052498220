import {Button} from '@material-ui/core';
import React, {ReactElement, useContext, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {IOrder} from '../../../types/Orders/IOrder';
import {OrderStatusType} from '../../../types/Orders/OrderStatusType';
import {useFetch} from '../../../hooks/fetch';
import {ShopType} from '../../../types/Orders/ShopType';
import {appActions} from '../../../modules/app/actions';
import {useDispatch} from 'react-redux';
import OrderDetailsContext from '../../../screens/Orders/Context/OrderDetailsContext';
import ButtonListContext from '../../ButtonList/ButtonListContext';

interface IProps {
  isListItem?: boolean;
}

const EmptyTrackingOrderButton = ({isListItem}: IProps): ReactElement => {
  const [showEmptyTrackingButton, setShowEmptyTrackingButton] = useState(false);
  const [trackingNumber, sendTrackingNumber] = useFetch<any>('set_empty_tracking');
  const {hideButtonList} = useContext(ButtonListContext);
  const {order} = useContext(OrderDetailsContext);
  const dispatch = useDispatch();
  const {t} = useTranslation();

  const handleEmptyTrackingButton = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    event.preventDefault();
    sendTrackingNumber({id: order._id});
  };

  const shouldShowEmptyTrackingButton = (order: IOrder) => {
    if (order.status !== OrderStatusType.New) {
      return false;
    }

    if (
      ![ShopType.DRUCKDICHAUS, ShopType.SUPPLIES_PARTNER, ShopType.AMAZON, ShopType.EBAY, ShopType.DDA_PORTAL].includes(
        order.shopName as any,
      )
    ) {
      return false;
    }

    if (order.isTrackingNumberScanned) {
      return false;
    }

    if (order.trackingNumberPushedBeforeScanning) {
      return false;
    }

    return true;
  };

  useEffect(() => {
    setShowEmptyTrackingButton(shouldShowEmptyTrackingButton(order));
  }, [order]);

  useEffect(() => {
    if (trackingNumber?.error) {
      dispatch(appActions.showSnackBar({text: trackingNumber.error.name, options: {severity: 'error'}}));
      hideButtonList && hideButtonList();
    } else if (trackingNumber?.data) {
      dispatch(appActions.showSnackBar({text: t('general.success'), options: {severity: 'success'}}));
      hideButtonList && hideButtonList();
    }

    return () => {
      trackingNumber.data = null;
      trackingNumber.error = null;
      trackingNumber.loaded = null;
    };
  }, [trackingNumber]);

  return showEmptyTrackingButton ? (
    <Button
      variant={isListItem ? 'text' : 'contained'}
      className={isListItem ? 'button-list-item' : 'blue-button'}
      onClick={handleEmptyTrackingButton}
    >
      {t('orderDetails.emptyTrackingOrderButton')}
    </Button>
  ) : (
    <></>
  );
};

export default EmptyTrackingOrderButton;
