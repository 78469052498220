import Button from '@material-ui/core/Button/Button';
import React, {useEffect, useMemo, useRef, useState} from 'react';
import {useFetch, useStorlessFetch, useStorlessUpload} from '../../hooks/fetch';
import {useDispatch} from 'react-redux';
import {modalActions} from '../../modules/modal/actions';
import {MODALS} from '../../components/Modal/ModalContents';
import DataTable from '../../components/Table/Table';
import {useTranslation} from 'react-i18next';
import {ICustomer} from '../../types/Customer/ICustomer';
import {appActions} from '../../modules/app/actions';
import {IOrder} from '../../types/Orders/IOrder';
import {ShopType} from '../../types/Orders/ShopType';
import {OrderStatusType} from '../../types/Orders/OrderStatusType';
import {Grid} from '@material-ui/core';
import CustomerSideInfo from '../../components/Customer/CustomerSideInfo';
import {DataTableItemType} from '../../components/Table/interfaces/IHeaderAccessor';
import {history} from '../../lib/history';
import useDataFetcher from '../../hooks/dataFetcher';
import {EntityType} from '../../types/CustomFields/CustomField';

const CustomerDetails = (props: any) => {
  const {
    match: {
      params: {id},
    },
  } = props;

  const {data: customFields} = useDataFetcher('get_custom_field_entity_fields', {
    entityType: EntityType.CUSTOMER,
  });

  const [customerDetails, getCustomerDetails] = useFetch<any>('customer_details');
  const [customerPut, doCustomerPut] = useFetch<any>('customer_put');
  const [customer, setCustomer] = useState<ICustomer>({} as any);
  const [uploadedFileResponse, uploadFile] = useStorlessUpload('upload');
  const [customerOrder, createCustomerOrder] = useStorlessFetch('create_customer_order');

  const dispatch = useDispatch();
  const {t} = useTranslation();
  const orderType = useRef('Order');

  const createOrder = async (type: string) => {
    orderType.current = type;
    createCustomerOrder({
      type: type,
      customerId: customer.id,
    });
    dispatch(modalActions.closeModal());
  };

  const handleCreateOrder = (type = 'Order') => {
    if (customer.hasOpenInvoices) {
      if (!confirm(t('customerDetails.openInvoicesWarning'))) {
        return;
      }
    }

    dispatch(
      modalActions.addModal(MODALS.CONFIRM_DIALOG, {
        title: t('general.areYouSure'),
        content: type === 'Order' ? t('customerDetails.questionOrder') : t('customerDetails.questionOffer'),
        onYes: () => createOrder(type),
        onNo: () => dispatch(modalActions.closeModal()),
      }),
    );
  };

  useEffect(() => {
    getCustomerDetails({id});
  }, [id]);

  useEffect(() => {
    if (customerOrder.data) {
      const order: IOrder = customerOrder.data as any;
      const link =
        orderType.current === 'Offer' ? `/orders/create/${order._id}?isOffer=1` : `/orders/create/${order._id}`;
      customerOrder.data = null;
      history.push(link);
    }

    return () => {
      customerOrder.data = null;
      customerOrder.error = null;
      customerOrder.loaded = null;
      customerOrder.loading = null;
    };
  }, [customerOrder]);

  useEffect(() => {
    if (customerPut?.data && !customerPut.error) {
      dispatch(
        appActions.showSnackBar({
          text: t('general.submitFormText'),
          options: {severity: 'success'},
        }),
      );
      getCustomerDetails({id});
    } else if (customerPut.error) {
      dispatch(
        appActions.showSnackBar({
          text: customerPut.error.name,
          options: {severity: 'error'},
        }),
      );
    }

    return () => {
      customerPut.data = null;
      customerPut.error = null;
    };
  }, [customerPut]);

  useEffect(() => {
    if (customerDetails.data) {
      setCustomer(customerDetails.data);
    }
  }, [customerDetails]);

  useEffect(() => {
    if (uploadedFileResponse.loaded) {
      const data = {...customer, logo: uploadedFileResponse.data};
      setCustomer(data);
      doCustomerPut({...data, id});
    }
  }, [uploadedFileResponse]);

  const handleCancelModal = () => {
    dispatch(modalActions.closeModal());
  };

  const handleSubmitModal = async (values: any) => {
    const data = {...values, id};
    const isFileSelected = typeof data['logo']?.name === 'string';

    if (isFileSelected && data.logo) {
      const image = renameFile(data.logo);
      uploadFile({file: image});
    } else {
      doCustomerPut(data);
    }

    handleCancelModal();
  };

  const renameFile = (originalFile: File) => {
    const extension = originalFile.name.split('.').pop();
    const newName = customer.customerName ?? Date.now().toString();
    return new File([originalFile], `${newName}.${extension}`, {
      type: originalFile.type,
      lastModified: originalFile.lastModified,
    });
  };

  const handleClick = async () => {
    dispatch(
      modalActions.addModal(MODALS.EDIT_CUSTOMER, {
        title: t('customerDetails.editTitle') || '',
        onSubmit: handleSubmitModal,
        onCancel: handleCancelModal,
        customerObject: customer,
        customFields: customFields,
      }),
    );
  };

  const customerOrders = useMemo(() => {
    return customer ? (
      <DataTable
        tableHeight="100%"
        headers={[
          {
            kind: 'accessor',
            name: t('orders.orderNumber'),
            accessor: 'orderNumber',
            sortable: true,
          },
          {
            kind: 'accessor',
            name: t('orderDetails.externalOrderNumber'),
            accessor: 'externalOrderNumber',
            sortable: true,
          },
          {
            kind: 'accessor',
            name: t('orders.status'),
            accessor: 'status',
            sortable: true,
          },
          {
            kind: 'accessor',
            name: t('orders.shippingType'),
            accessor: 'shippingType',
            sortable: true,
          },
          {
            kind: 'accessor',
            name: t('orders.shopName'),
            accessor: 'shopName',
            sortable: true,
            type: DataTableItemType.SELECT,
            options: ([{value: 'all', label: 'All'}] as any).concat(
              Object.values(ShopType).map((item) => {
                return {value: item, label: item};
              }),
            ),
          },
          {
            kind: 'accessor',
            name: t('orders.date'),
            accessor: 'date',
            sortable: true,
            type: DataTableItemType.DATE,
          },
          {
            kind: 'accessor',
            name: t('orderDetails.paymentMethodName'),
            accessor: 'paymentMethodName',
            sortable: true,
          },
          {
            kind: 'accessor',
            name: t('orderDetails.orderTotalSum'),
            accessor: 'orderTotalSum',
            sortable: true,
            type: 'number',
          },
          {
            kind: 'accessor',
            name: t('orders.customerNumber'),
            accessor: 'customerNumber',
            sortable: true,
          },
          {
            kind: 'accessor',
            name: t('orderDetails.customerName'),
            accessor: 'billingAddress.customerName',
            optionalAccessor: 'shippingAddress.customerName',
            sortable: true,
          },
          {
            kind: 'accessor',
            name: t('orders.email'),
            accessor: 'billingAddress.email',
            optionalAccessor: 'shippingAddress.email',
            sortable: true,
          },
        ]}
        endpoint={`orders`}
        target={`orders`}
        options={{customer: id, status: {$nin: [OrderStatusType.Offer, OrderStatusType.PendingOffer]}}}
        defaultSort={{key: 'createdAt', value: -1}}
        title={t('customerDetails.customerOrders')}
      />
    ) : null;
  }, [customerDetails]);

  const customerReport = useMemo(() => {
    return customer ? (
      <DataTable
        tableHeight="100%"
        headers={[
          {
            kind: 'accessor',
            name: t('products.productId'),
            accessor: 'productId',
            sortable: true,
          },
          {
            kind: 'accessor',
            name: t('products.shopwareId'),
            accessor: 'shopwareId',
            sortable: true,
          },
          {
            kind: 'accessor',
            name: t('products.title'),
            accessor: 'name',
            sortable: true,
          },
          {
            kind: 'accessor',
            name: t('orderDetails.latestBuyDate'),
            accessor: 'lastOrderDate',
            type: DataTableItemType.DATE,
            sortable: true,
          },
          {
            kind: 'accessor',
            name: t('orderDetails.latestNetPrice'),
            accessor: 'lastOrderPrice',
            format: 'price',
            sortable: true,
          },
          {
            kind: 'accessor',
            name: t('productDetails.average-price'),
            accessor: 'averagePrice',
            format: 'price',
            sortable: true,
          },
          {
            kind: 'accessor',
            name: t('orderDetails.totalUnits'),
            accessor: 'totalQuantity',
            sortable: true,
          },
        ]}
        endpoint={`customer_report`}
        target={`products`}
        additionalFields={{customerId: id}}
        title={t('general.products')}
      />
    ) : null;
  }, [customerDetails]);

  return (
    <div className="order-details">
      <div className="order-header">
        <div className="order-header-title">
          <span className="left-title">
            {t('general.customers')} / <span className="right-title">{customer?.customerName}</span>
          </span>
        </div>

        <div className="order-header-buttons">
          <Button
            startIcon={<img src="/icons/percentage.webp" />}
            variant="contained"
            className="blue-button"
            onClick={() => handleCreateOrder('Offer')}
          >
            {t('customerDetails.createOffer')}
          </Button>
          <Button
            startIcon={<img src="/icons/plus.webp" />}
            variant="contained"
            className="yellow-button"
            onClick={() => handleCreateOrder()}
          >
            {t('customerDetails.createOrder')}
          </Button>
          <Button
            startIcon={<img src="/icons/edit (1).webp" />}
            variant="contained"
            className="black-button"
            onClick={handleClick}
          >
            {t('customerDetails.editButton')}
          </Button>
        </div>
      </div>

      <Grid container>
        <Grid item xs={12} sm={8} md={8}>
          <div className="customer-details__table-wrapper">{customerOrders}</div>
          <div className="customer-details__table-wrapper">{customerReport}</div>
        </Grid>
        <Grid item xs={12} sm={4} md={4}>
          <CustomerSideInfo customer={customer} customFields={customFields} />
        </Grid>
      </Grid>
    </div>
  );
};

export default CustomerDetails;
