import {Button} from '@material-ui/core';
import React, {ReactElement, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useFetch} from '../../../hooks/fetch';

const SupplierOrderPdfButton = (props: ISupplierOrderPdfButtonProps): ReactElement => {
  const [supplierOrderPdf, getSupplierOrderPdf] = useFetch('supplier_orders_pdf');
  const [printSupplierOrderPdf, setPrintSupplierOrderPdf] = useState<boolean>(false);
  const {t} = useTranslation();
  const {supplierOrderId} = props;

  const handlePrintDeliveryBill = async () => setPrintSupplierOrderPdf(true);

  useEffect(() => {
    if (supplierOrderPdf.data && printSupplierOrderPdf) {
      const blob = new Blob([new Uint8Array(supplierOrderPdf.data.data).buffer], {type: 'application/pdf'});
      const blobURL = URL.createObjectURL(blob);
      window.open(blobURL);
    }

    return () => {
      supplierOrderPdf.data = null;
      setPrintSupplierOrderPdf(false);
    };
  }, [supplierOrderPdf.data]);

  useEffect(() => {
    if (printSupplierOrderPdf) {
      getSupplierOrderPdf({id: supplierOrderId});
    }
  }, [printSupplierOrderPdf]);

  return (
    <Button variant="contained" className="blue-button" onClick={handlePrintDeliveryBill}>
      {t('supplierOrderDetails.supplierOrderPdf')}
    </Button>
  );
};

interface ISupplierOrderPdfButtonProps {
  supplierOrderId: any;
  getSupplierOrderPdf: (data: any) => void;
}

export default SupplierOrderPdfButton;
