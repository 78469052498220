import React, {ReactElement} from 'react';
import {useTranslation} from 'react-i18next';
import {IOrder} from '../../types/Orders/IOrder';
import {IExchangeOrder} from '../../types/Orders/IExchangeOrder';

const ExchangeOrders = ({order}: IProps): ReactElement => {
  const {t} = useTranslation();

  return (
    <div>
      {order?.exchangeOrder && (
        <div style={{marginBottom: '10px'}}>
          {t('orderDetails.originalOrder')}:{' '}
          <a href={`/orders/${(order?.exchangeOrderParent?.id as any)?._id}`}>
            {order?.exchangeOrderParent?.orderNumber}
          </a>
        </div>
      )}
      {(order?.exchangeOrders?.length || 0) > 0 && (
        <div>
          <div style={{marginBottom: '10px'}}>{t('orderDetails.exchangeOrders')}</div>
          {order?.exchangeOrders?.map((exchangeOrder: IExchangeOrder) => (
            <a key={exchangeOrder.id} href={`/orders/${(exchangeOrder?.id as any)?._id}`} style={{marginBottom: '6px'}}>
              {exchangeOrder.orderNumber}
            </a>
          ))}
        </div>
      )}
    </div>
  );
};

interface IProps {
  order: IOrder;
}

export default ExchangeOrders;
