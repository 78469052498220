import {Button} from '@material-ui/core';
import React, {ReactElement, useContext} from 'react';
import {useTranslation} from 'react-i18next';
import OrderDetailsContext from '../../../screens/Orders/Context/OrderDetailsContext';
import ButtonListContext from '../../ButtonList/ButtonListContext';
import {useDispatch} from 'react-redux';
import {modalActions} from '../../../modules/modal/actions';
import {MODALS} from '../../Modal/ModalContents';

interface IProps {
  isListItem?: boolean;
}

const CreateCreditNoteButton = ({isListItem}: IProps): ReactElement => {
  const {hideButtonList} = useContext(ButtonListContext);
  const {order, getOrderDetails} = useContext(OrderDetailsContext);
  const dispatch = useDispatch();
  const {t} = useTranslation();

  const onButtonClick = async () => {
    hideButtonList();

    dispatch(modalActions.addModal(MODALS.CREATE_CREDIT_NOTE_MODAL, {orderObject: order, getOrderDetails}));
  };

  return order?.easyBillInfo?.invoiceNumber ? (
    <Button
      variant={isListItem ? 'text' : 'contained'}
      className={isListItem ? 'button-list-item' : 'blue-button'}
      onClick={onButtonClick}
    >
      {t('orderDetails.createCreditNote')}
    </Button>
  ) : (
    <></>
  );
};

export default CreateCreditNoteButton;
