import React, {ReactElement} from 'react';
import {useTranslation} from 'react-i18next';
import {IProduct} from '../../types/Product/IProduct';
import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from '@material-ui/core';
import {Link} from 'react-router-dom';

const MultiBoxParentsTable = (props: IMultiBoxParentsTable): ReactElement => {
  const products = props.products;
  const {t} = useTranslation();

  return (products?.length || 0) > 0 ? (
    <div className="data-table-wrapper" style={{height: 'auto', maxHeight: '400px', marginBottom: '20px'}}>
      <div className="data-table-wrapper__action-buttons">
        <div className="data-table-wrapper__title">{t('products.partOfMultiBoxProducts')}</div>
      </div>
      <TableContainer className="table-scroll">
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{t('productDetails.product')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {products?.map((product: any) => {
              return (
                <>
                  <TableRow
                    style={{cursor: 'pointer', textDecoration: 'none'}}
                    component={Link}
                    to={`/products/${product._id}`}
                  >
                    <td>{product.manualTitle || product.title}</td>
                  </TableRow>
                </>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  ) : (
    <></>
  );
};

interface IMultiBoxParentsTable {
  products?: IProduct[];
}

export default MultiBoxParentsTable;
