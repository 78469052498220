import {ModalProps} from '../../components/Modal/ModalContents';
import {ActionsUnion, createAction} from '../../lib/actions';

enum MODAL {
  ADD = 'MODAL_ADD',
  CLOSE = 'MODAL_CLOSE',
  ADD_CHILD = 'MODAL_ADD_CHILD',
  ADD_RIGHT_DRAWER = 'ADD_RIGHT_DRAWER',
  ADD_LEFT_DRAWER = 'ADD_LEFT_DRAWER',
  CLOSE_RIGHT_DRAWER = 'CLOSE_RIGHT_DRAWER',
  CLOSE_LEFT_DRAWER = 'CLOSE_LEFT_DRAWER',
}
const modalActions = {
  addModal: <K extends keyof ModalProps>(key: K, props: ModalProps[K], title?: string) =>
    createAction(MODAL.ADD, {key, props, title}),
  closeModal: (key?: string, status?: string) => createAction(MODAL.CLOSE, {childKey: key, status}),
  addChild: <K extends keyof ModalProps>(key: K, props: ModalProps[K], title?: string) =>
    createAction(MODAL.ADD_CHILD, {key, props, title}),
  addRightDrawer: <K extends keyof ModalProps>(key: K, props: ModalProps[K], title?: string) =>
    createAction(MODAL.ADD_RIGHT_DRAWER, {key, props, title}),
  addLeftDrawer: <K extends keyof ModalProps>(key: K, props: ModalProps[K], title?: string) => {
    return createAction(MODAL.ADD_LEFT_DRAWER, {key, props, title});
  },
  // closeLeftDrawer: () => createAction(MODAL.CLOSE_LEFT_DRAWER),
  closeRightDrawer: () => createAction(MODAL.CLOSE_RIGHT_DRAWER),
};

export type ModalAction = ActionsUnion<typeof modalActions>;
export {MODAL, modalActions};
